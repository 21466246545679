import React, { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import theme from 'assets/theme'

export default function elementoSpinner() {
    const [aperto, setAperto] = useState(false)


    useEffect(() => {
        window.addEventListener('statoSpinner', (e) => {
            setAperto(e.detail)
        })
    }, [])

    return (
        <React.Fragment>
            <Backdrop open={aperto} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} />
            {aperto ? <CircularProgress color='dark' size='4rem' sx={{ zIndex: (theme) => theme.zIndex.modal + 1 , position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%)' }} /> : ''}
        </React.Fragment>
    )
}
