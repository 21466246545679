import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import MDTypography from 'components/MDTypography'
import { isClienteAifi } from 'getter/getterDipendenti'
import { isNuovoDottore } from 'getter/getterDipendenti'
import MDButton from 'components/MDButton'
import { isScaduto } from 'getter/getterDipendenti'
import { isSospeso } from 'getter/getterDipendenti'
import { isPagamentoFallito } from 'getter/getterDipendenti'
import { isProvaGratuita } from 'getter/getterDipendenti'
import { getFineAbbonamento } from 'getter/getterDipendenti'
import { ATTIVA_ABBONAMENTO } from 'data/azioni/ATTIVA_ABBONAMENTO'
import { MODIFICA_RINNOVO_SOTTOSCRIZIONE } from 'data/azioni/MODIFICA_RINNOVO_SOTTOSCRIZIONE'
import { RISOLVI_PAGAMENTO_FALLITO } from 'data/azioni/RISOLVI_PAGAMENTO_FALLITO'
import MDBox from 'components/MDBox'

export default function elementoMostraDipendente(props) {
    const test = () => {
        props.navigate(`/paginaVediDipendente/${props.dipendente.id}`)
    }

    const getTesto = () => {
        if (!props.dipendente.contenuto.pagato) {
            return (
                <MDTypography mt={0.5} variant='caption' color='error'>
                    NON PAGATO
                </MDTypography>
            )
        } else {
            if (props.dipendente.contenuto.attivo) {
                return (
                    <MDTypography mt={0.5} variant='caption' color='success'>
                        ATTIVO
                    </MDTypography>
                )
            } else {
                return (
                    <MDTypography mt={0.5} variant='caption' color='error'>
                        NON ATTIVO
                    </MDTypography>
                )
            }
        }
    }

    const creaAbbonamento = () => {
        ATTIVA_ABBONAMENTO(props.dipendente.id)
    }

    const renderInfoAbbonamento = () => {
        if (isNuovoDottore(props.dipendente.id)) {
            return (
                <React.Fragment>
                    <MDTypography variant='caption' color='error'>
                        Utente non pagato
                    </MDTypography>
                    <MDButton size='small' onClick={() => creaAbbonamento()}>
                        crea abbonamento {/* inizia prova gratuita */}
                    </MDButton>
                </React.Fragment>
            )
        }
        if (isScaduto(props.dipendente.id)) {
            return (
                <React.Fragment>
                    <MDTypography variant='caption' color='error'>
                        Abbonamento scaduto
                    </MDTypography>
                    <MDButton size='small' onClick={() => creaAbbonamento()}>
                        riattiva abbonamento
                    </MDButton>
                </React.Fragment>
            )
        }
        if (isSospeso(props.dipendente.id)) {
            return (
                <React.Fragment>
                    <MDTypography variant='caption' color='error'>
                        Rinnovo automatico disattivato
                    </MDTypography>
                    <MDButton size='small' onClick={() => MODIFICA_RINNOVO_SOTTOSCRIZIONE(props.dipendente.id)}>
                        riattiva
                    </MDButton>
                </React.Fragment>
            )
        }
        if (isPagamentoFallito(props.dipendente.id)) {
            return (
                <React.Fragment>
                    <MDTypography variant='caption' color='error'>
                        Errore nel pagamento
                    </MDTypography>
                    <MDButton size='small' onClick={() => RISOLVI_PAGAMENTO_FALLITO()}>
                        paga ora
                    </MDButton>
                </React.Fragment>
            )
        }

        if (isProvaGratuita(props.dipendente.id)) {
            return (
                <MDTypography variant='caption' color='dark'>
                    Prova gratuita in corso, l'abbonamento si rinnoverà il : {getFineAbbonamento(props.dipendente.id)}
                </MDTypography>
            )
        }
        return (
            <MDTypography variant='caption' color='dark'>
                Prossimo rinnovo il : {getFineAbbonamento(props.dipendente.id)}
            </MDTypography>
        )
    }

    return (
        <React.Fragment>
            <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
                <ListItemIcon>
                    <AccountCircleRoundedIcon color='primary' />
                </ListItemIcon>
                <ListItemText primary={`${props.dipendente.contenuto.cognome} ${props.dipendente.contenuto.nome}`} secondary={isClienteAifi(props.dipendente.id) ? 'AIFI' : ''} />

                <ListItemIcon>
                    <MDBox height='100%' display='grid' gridTemplateColumns='max-content max-content' justifyContent='center' alignItems='center' gap={0}>
                        <MDBox className='centrato' height='100%'>
                            {getTesto()}
                        </MDBox>
                        <ArrowForwardIosRoundedIcon color='dark' />
                    </MDBox>
                </ListItemIcon>
            </ListItem>
            {renderInfoAbbonamento()}
        </React.Fragment>
    )
}
