import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { formContatti } from 'formModel/formContatti'
import { INVIA_FEEDBACK } from 'data/azioni/INVIA_FEEDBACK'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import VediForm from 'formMaker/vediForm'

export default function popUpContattiDateTutorial(props) {
    const handleClose = () => {
        props.chiudi()
    }

    let form = formContatti()
    form.parts[3].details = { placeHolder: 'Indica qui la tua disponibilità' }

    const salvaRichiesta = async (e) => {
        e.isTutorial = true
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await INVIA_FEEDBACK(e)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Richiesta inviata' } })
        return
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>CONTATTACI</DialogTitle>
            <DialogContent dividers={true}>
                <VediForm form={form} dati={{}} soloInput={true} vista='input' annulla={() => handleClose()} salva={(e) => salvaRichiesta(e)} />
            </DialogContent>
        </Dialog>
    )
}
