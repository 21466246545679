import { rimuoviOggettiDaUnArrayDiOggetti } from './../data/strumenti'
import cloneDeep from 'lodash-es/cloneDeep'
import { getProfessioneCreaDipendente } from 'getter/getterAppUtilizzata'

let provenienza = {
    id: 'provenienza',
    tipo: 'fieldText',
    associazione: 'provenienza',
    label: 'Provenienza:',
    value: '',
    details: {
        type: 'text',
        costumCssOutput: ['labelBold'],
    },
}

let dottore = [
    {
        id: 'numeroAlbo',
        tipo: 'fieldText',
        associazione: 'numeroAlbo',
        label: 'Numero albo:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'testoRichiesto',
    },
    {
        id: 'laurea',
        tipo: 'fieldText',
        associazione: 'laurea',
        label: 'Laurea:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'testoRichiesto',
    },
]

let amministrazioneClinica = [
    {
        id: 'CAP',
        tipo: 'fieldText',
        associazione: 'CAP',
        label: 'CAP:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'testoRichiesto',
    },
    {
        id: 'iban',
        tipo: 'fieldText',
        associazione: 'iban',
        label: 'IBAN:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'testoRichiesto',
    },
    {
        id: 'partiaIva',
        tipo: 'fieldText',
        associazione: 'partiaIva',
        label: 'Partita IVA:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'testoRichiesto',
    },
    {
        id: 'telefonoCellulare',
        tipo: 'fieldText',
        associazione: 'telefonoCellulare',
        label: 'Telefono cellulare:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'testoRichiesto',
    },
    {
        id: 'telefonoFisso',
        tipo: 'fieldText',
        associazione: 'telefonoFisso',
        label: 'Telefono fisso:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
    },
    {
        id: 'web',
        tipo: 'fieldText',
        associazione: 'web',
        label: 'WEB:',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
    },
]

let terminiECondizioni = [
    {
        id: 'accettazioneTerminiECondizioni',
        tipo: 'fieldAccettaTerminiECondizioni',
        associazione: 'accettazioneTerminiECondizioni',
        label: 'Accetto termini e condizioni:',
        value: '',
        details: {
            testoLink: 'scopri di piu',
            link: 'terminiECondizioni.html',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'controllaSeVero',
    },
]

let mailNonRichiesta = {
    id: 'email',
    tipo: 'fieldText',
    associazione: 'email',
    label: 'Email:',
    value: '',
    details: {
        type: 'text',
        costumCssOutput: ['labelBold'],
    },
    //validazione: 'emailGiaPresente',
}

let form = {
    classeForm: 'test-form',
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'cognome',
            tipo: 'fieldText',
            associazione: 'cognome',
            label: 'Cognome:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'genere',
            tipo: 'fieldSelect',
            associazione: 'genere',
            label: 'Sesso:',
            value: '',
            details: {
                scelte: ['Maschio', 'Femmina'],
                complex: false,
                costumCssOutput: ['labelBold'],
                costumCss: ['altezzaText'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'indirizzo',
            tipo: 'fieldText',
            associazione: 'indirizzo',
            label: 'Indirizzo:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'comune',
            tipo: 'fieldText',
            associazione: 'comune',
            label: 'Comune:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'provincia',
            tipo: 'fieldText',
            associazione: 'provincia',
            label: 'Provincia:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'cap',
            tipo: 'fieldText',
            associazione: 'cap',
            label: 'CAP:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'dataNascita',
            tipo: 'fieldData',
            associazione: 'dataNascita',
            label: 'Data di nascita:',
            value: '',
            details: {
                tornaStringa: true,
                type: 'data',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'luogoNascita',
            tipo: 'fieldText',
            associazione: 'luogoNascita',
            label: 'Luogo di nascita:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
        },
        {
            id: 'cf',
            tipo: 'fieldText',
            associazione: 'cf',
            label: 'Codice fiscale:',
            value: '',
            details: {
                type: 'text',
                maiuscolo: true,
                costumCssOutput: ['maiuscolo', 'labelBold'],
            },
            validazione: 'formattazioneCf',
        },
        {
            id: 'telefono',
            tipo: 'fieldText',
            associazione: 'telefono',
            label: 'Telefono',
            value: '',
            details: {
                type: 'tel',
                costumCssOutput: ['labelBold'],
            },
        },
        {
            id: 'email',
            tipo: 'fieldText',
            associazione: 'email',
            label: 'Email:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
    ],
}

let subInputCovidVaccino = {
    costumCssInput: ['sinistraPiccolo', 'paddinSopra'],

    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Vaccino covid :',
            value: '',
            details: {
                scelte: ['si', 'no'],
                grid: { lg: { input: 1.5, output: 1 } },
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Descrizione:',
            value: '',
            details: {
                grid: { lg: { input: 2.5, output: 3 } },
            },
        },
    ],
}
let subInputCovidFatto = {
    costumCssInput: ['sinistraPiccolo', 'paddinSopra'],
    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Covid fatto',
            value: '',
            details: {
                grid: { lg: { input: 1.5, output: 1 } },
                scelte: ['si', 'no'],
                complex: false,
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Descrizione:',
            value: '',
            details: {
                grid: { lg: { input: 2.5, output: 3 } },
            },
        },
    ],
}

let covid = {
    id: 'covid',
    tipo: 'fieldSubInput',
    associazione: 'covid',
    label: 'Covid:',
    value: {},
    details: {
        grid: { lg: { input: 4, output: 4 } },
        nascondiLabel: true,
        subModel: {
            costumCssInput: ['inColonna', 'spostaSopra'],
            parts: [
                {
                    id: 'fatto',
                    tipo: 'fieldSubInput',
                    associazione: 'fatto',
                    label: 'Covid fatto:',
                    details: {
                        subModel: subInputCovidFatto,
                        costumCssOutput: ['labelBold'],
                        costumCss: ['subInputNoSopra'],
                    },
                    value: {},
                },
                {
                    id: 'vaccinato',
                    tipo: 'fieldSubInput',
                    associazione: 'vaccinato',
                    label: 'Vaccino covid:',
                    details: {
                        subModel: subInputCovidVaccino,
                        costumCssOutput: ['labelBold'],
                        costumCss: ['subInputNoSopra'],
                    },
                    value: {},
                },
                {
                    id: 'note',
                    tipo: 'fieldTextArea',
                    associazione: 'note',
                    label: 'Note generali covid :',
                    details: {
                        costumCssOutput: ['labelBold'],
                        costumCss: ['padding'],
                        grid: { lg: { input: 4, output: 4 } },
                    },
                    value: '',
                },
            ],
        },
        costumCssOutput: ['labelBold'],
    },
}

export const formUtente = (utente, professione) => {
    let daRestituire = cloneDeep(form)
    let arrayObject = []
    switch (utente) {
        case 'profilo-dottore':
            let nuovo = []
            switch (professione) {
                case 'fisioterapista':
                    nuovo = dottore
                    break
                case 'osteopata':
                    nuovo = []
                    break

                default:
                    break
            }
            daRestituire.parts = daRestituire.parts.concat(nuovo)
            return daRestituire
        case 'profilo-segreteria':
            return daRestituire
        case 'profilo-paziente':
            arrayObject = rimuoviOggettiDaUnArrayDiOggetti(daRestituire.parts, ['email'], 'id')
            arrayObject.push(mailNonRichiesta)
            arrayObject.push(covid)
            arrayObject.push(provenienza)
            daRestituire.parts = arrayObject
            return daRestituire
        case 'profilo-amministrazione-clinica':
            arrayObject = rimuoviOggettiDaUnArrayDiOggetti(daRestituire.parts, ['cognome', 'genere', 'dataNascita', 'luogoNascita', 'telefono', 'cap'], 'id')
            daRestituire.parts = arrayObject.concat(amministrazioneClinica)
            return daRestituire
        case 'creaDottore':
            //daRestituire.parts = daRestituire.parts.concat(dottore.concat(terminiECondizioni))
            let arraydaUnire = []
            if (professione === undefined || professione === '') {
                professione = getProfessioneCreaDipendente()
            }
            switch (professione) {
                case 'fisioterapista':
                    arraydaUnire = dottore
                    break
                case 'osteopata':
                    arraydaUnire = []
                    break

                default:
                    break
            }
            daRestituire.parts = daRestituire.parts.concat(arraydaUnire)
            return daRestituire
        case 'creaSegreteria':
            //daRestituire.parts = daRestituire.parts.concat(terminiECondizioni)
            return daRestituire
        default:
            return { parts: [] }
            break
    }
}
