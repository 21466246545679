import React from 'react'
import { connect } from 'react-redux'
import { Divider} from '@mui/material'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ElementoMostraUtenzaTs from 'componenti/elementoMostraUtenzaTs'

export const paginaUtenzeTesseraSanitaria = (props) => {
    const renderFab = () => {
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => APRI_POP_UP({ id: 'utenza-ts', parametro: '' })}>
                <AddIcon />
            </Fab>
        )
    }

    const renderLista = () => {
        if (props.utenze.length === 0) {
            return (
                
                    <MDBox  width='100%' borderRadius='lg' bgColor='white' p={2} display='flex' justifyContent='center' alignItems='center'>
                        <MDTypography variant='h4' color='error'>
                            Nessuna utenza inserita
                        </MDTypography>
                    </MDBox>
                
            )
        }
        return props.utenze.map((utenza, index) => {
            return (
                <React.Fragment key={`utenza-${index}`}>
                        <ElementoMostraUtenzaTs utenza={utenza} />
                    
                    {index !== props.utenze.length - 1 ? <Divider /> : ''}
                </React.Fragment>
            )
        })
    }


    return (
        <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h4' mr={1}>
                    TS
                </MDTypography>
                <MDTypography color={'white'} variant='h5'>
                    Lista utenze
                </MDTypography>
            </MDBox>
            
                {renderFab()}

                {renderLista()}
            
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        utenze: state.reducerUtenzeTs || [],
    }
}

export default connect(mapStateToProps, null)(paginaUtenzeTesseraSanitaria)
