import { collection, doc, Timestamp } from 'firebase/firestore'
import { store } from './store'

export const trasformaOggettoInArray = (oggetto) => {
    if (oggetto === undefined || oggetto === null) {
        return []
    }

    let array = Object.keys(oggetto).map((key) => {
        return {
            id: key,
            contenuto: oggetto[key],
        }
    })
    return array
}

export const trasformaArrayInOggetto = (array) => {
    var daRitornare = {}
    array.forEach((oggetto) => {
        daRitornare[oggetto.id] = oggetto.contenuto
    })
    return daRitornare
}

export const copiaOggetto = (oggetto, chiavi) => {
    return chiavi.reduce((acc, chiave) => {
        acc[chiave] = oggetto[chiave]
        return acc
    }, {})
}

export const creaEtichettaUniversale = (presidio) => {
    let dataCreazione = Timestamp.fromDate(new Date())
    let user = store.getState().statoUtente.id

    let etichetta = {
        dataCreazione: dataCreazione,
        presidio: presidio || '',
        user: user === null ? '' : user,
    }

    return etichetta
}

export const creaPasswordRandom = () => {
    var password = ''
    for (var i = 0; i < 6; i++) {
        let c = Math.floor(Math.random() * 9)
        password += c.toString()
    }
    return password
}

export const newId = () => {
    let ref = doc(collection(db, 'users'))
    return ref.id
}

export const differenzaDate = (dateMaggiore, dateMinore) => {
    let dt2 = new Date(dateMaggiore)
    let dt1 = new Date(dateMinore)
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24))
}

export const formattaDataDaFirebase = (data, vogliamoLora, soloOra) => {
    let dataReturn = ''
    let milliSecondi = data.seconds * 1000
    let date = new Date(milliSecondi)
    let minuti = ''
    if (date.getMinutes() < 10) {
        minuti = `0${date.getMinutes()}`
    } else {
        minuti = date.getMinutes()
    }
    let ore = ''
    if (date.getHours() < 10) {
        ore = `0${date.getHours()}`
    } else {
        ore = date.getHours()
    }
    if (vogliamoLora === undefined || vogliamoLora === false) {
        dataReturn = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    } else {
        dataReturn = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}   ${date.getHours()}:${minuti}`
    }

    if (soloOra) {
        return `${ore}:${minuti}`
    }

    return dataReturn
}

export const getOggettoData = (iniziale) => {
    if (iniziale === undefined || iniziale === null || iniziale === '') return ''
    if (typeof iniziale === 'string') return convertiStringaInData(iniziale)
    if (iniziale.seconds !== undefined) return iniziale.toDate()
    return iniziale
}

export const formattaAnnoDaFirebase = (data) => {
    let dataReturn = ''
    let milliSecondi = data.seconds * 1000
    let date = new Date(milliSecondi)
    let minuti = ''
    if (date.getMinutes() < 10) {
        minuti = `0${date.getMinutes()}`
    } else {
        minuti = date.getMinutes()
    }

    dataReturn = date.getFullYear()

    return dataReturn
}
export const formattaMeseDaFirebase = (data) => {
    let dataReturn = ''
    let milliSecondi = data.seconds * 1000
    let date = new Date(milliSecondi)
    let minuti = ''
    if (date.getMinutes() < 10) {
        minuti = `0${date.getMinutes()}`
    } else {
        minuti = date.getMinutes()
    }

    dataReturn = date.getMonth() + 1

    return dataReturn
}

export const formattaGiornoDaFirebase = (data) => {
    let dataReturn = ''
    let milliSecondi = data.seconds * 1000
    let date = new Date(milliSecondi)
    let minuti = ''
    if (date.getMinutes() < 10) {
        minuti = `0${date.getMinutes()}`
    } else {
        minuti = date.getMinutes()
    }

    dataReturn = date.getDate()

    return dataReturn
}

export const formattaDataUnix = (data) => {
    let dataReturn = ''
    let milliSecondi = data
    let date = new Date(milliSecondi)
    let minuti = ''
    if (date.getMinutes() < 10) {
        minuti = `0${date.getMinutes()}`
    } else {
        minuti = date.getMinutes()
    }
    let anno = `${date.getFullYear()}`
    dataReturn = `${date.getDate()}/${date.getMonth() + 1}/${anno}`

    return dataReturn
}

export const formattaData = (data) => {
    let dataReturn = ''
    let milliSecondi = data.seconds * 1000
    let date = new Date(milliSecondi)
    let minuti = ''
    if (date.getMinutes() < 10) {
        minuti = `0${date.getMinutes()}`
    } else {
        minuti = date.getMinutes()
    }
    let anno = `${date.getFullYear()}`
    dataReturn = `${date.getDate()}/${date.getMonth() + 1}/${anno.substr(-2)}`

    return dataReturn
}

export const dataDiOggi = () => {
    let dataReturn = ''
    let date = new Date()
    let minuti = ''
    if (date.getMinutes() < 10) {
        minuti = `0${date.getMinutes()}`
    } else {
        minuti = date.getMinutes()
    }
    dataReturn = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

    return dataReturn
}

export const convertiStringaInData = (stringa) => {
    let giorno = parseInt(`${stringa.split('/')[0]}`)
    let mese = parseInt(`${stringa.split('/')[1]}`) - 1
    let anno = parseInt(`${stringa.split('/')[2]}`)
    return new Date(anno, mese, giorno)
}

export const convertiDataInStringa = (date, vediOra) => {
    if (date.seconds !== undefined) {
        return formattaDataDaFirebase(date)
    }
    if (vediOra) {
        const ora = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        const minuti = date.getMinutes() === 0 ? '00' : date.getMinutes()
        return `${ora}:${minuti}`
    }
    let anno = `${date.getFullYear()}`
    let dataReturn = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

    return dataReturn
}

export const getPresidio = (utente) => {
    if (utente === undefined) {
        return ''
    }
    if (utente.etichettaUniversale !== undefined) {
        return utente.etichettaUniversale.presidio
    } else {
        return utente.presidio || ''
    }
}

export const rimuoviOggettiDaUnArrayDiOggetti = (arrayDaCuiRimuovere, elementiDaRimuovere, chiave) => {
    let arrayConElementiRimossi = []

    arrayDaCuiRimuovere.forEach((elemento) => {
        if (!elementiDaRimuovere.includes(elemento[chiave])) {
            arrayConElementiRimossi.push(elemento)
        }
    })

    return arrayConElementiRimossi
}

export const statisticheUtilizzo = () => {
    {
        var unknown = 'Unknown'
        // screen
        var screenSize = ''
        var realScreenSize = ''
        if (screen.width) {
            let width = screen.width ? screen.width : ''
            let height = screen.height ? screen.height : ''
            screenSize += '' + width + ' x ' + height
            realScreenSize += '' + window.innerWidth + ' x ' + window.innerHeight
        }
        //browser
        var nVer = navigator.appVersion
        var nAgt = navigator.userAgent
        var browser = navigator.appName
        var version = '' + parseFloat(navigator.appVersion)
        var majorVersion = parseInt(navigator.appVersion, 10)
        var nameOffset, verOffset, ix
        // Opera
        if ((verOffset = nAgt.indexOf('Opera')) != -1) {
            browser = 'Opera'
            version = nAgt.substring(verOffset + 6)
            if ((verOffset = nAgt.indexOf('Version')) != -1) {
                version = nAgt.substring(verOffset + 8)
            }
        }
        // MSIE
        else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
            browser = 'Microsoft Internet Explorer'
            version = nAgt.substring(verOffset + 5)
        }
        //IE 11 no longer identifies itself as MS IE, so trap it
        //http://stackoverflow.com/questions/17907445/how-to-detect-ie11
        else if (browser == 'Netscape' && nAgt.indexOf('Trident/') != -1) {
            browser = 'Microsoft Internet Explorer'
            version = nAgt.substring(verOffset + 5)
            if ((verOffset = nAgt.indexOf('rv:')) != -1) {
                version = nAgt.substring(verOffset + 3)
            }
        }
        // Chrome
        else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
            browser = 'Chrome'
            version = nAgt.substring(verOffset + 7)
        }
        // Safari
        else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
            browser = 'Safari'
            version = nAgt.substring(verOffset + 7)
            if ((verOffset = nAgt.indexOf('Version')) != -1) {
                version = nAgt.substring(verOffset + 8)
            }
            // Chrome on iPad identifies itself as Safari. Actual results do not match what Google claims
            //  at: https://developers.google.com/chrome/mobile/docs/user-agent?hl=ja
            //  No mention of chrome in the user agent string. However it does mention CriOS, which presumably
            //  can be keyed on to detect it.
            if (nAgt.indexOf('CriOS') != -1) {
                //Chrome on iPad spoofing Safari...correct it.
                browser = 'Chrome'
                //Don't believe there is a way to grab the accurate version number, so leaving that for now.
            }
        }
        // Firefox
        else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
            browser = 'Firefox'
            version = nAgt.substring(verOffset + 8)
        }
        // Other browsers
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
            browser = nAgt.substring(nameOffset, verOffset)
            version = nAgt.substring(verOffset + 1)
            if (browser.toLowerCase() == browser.toUpperCase()) {
                browser = navigator.appName
            }
        }
        // trim the version string
        if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix)
        if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix)
        if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix)
        majorVersion = parseInt('' + version, 10)
        if (isNaN(majorVersion)) {
            version = '' + parseFloat(navigator.appVersion)
            majorVersion = parseInt(navigator.appVersion, 10)
        }
        // mobile version
        var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer)

        // cookie
        var cookieEnabled = navigator.cookieEnabled ? true : false

        if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
            document.cookie = 'testcookie'
            cookieEnabled = document.cookie.indexOf('testcookie') != -1 ? true : false
        }
        // system
        var os = unknown
        var clientStrings = [
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows ME', r: /Windows ME/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Linux', r: /(Linux|X11)/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
        ]
        for (var id in clientStrings) {
            var cs = clientStrings[id]
            if (cs.r.test(nAgt)) {
                os = cs.s
                break
            }
        }
        var osVersion = unknown

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1]
            os = 'Windows'
        }
        switch (os) {
            case 'Mac OS X':
                osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1]
                break

            case 'Android':
                osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1]
                break

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0)
                break
        }
    }

    let browserInfo = {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
        realScreenSize: realScreenSize,
        idUtilizzatore: store.getState().statoUtente.id,
        data: new Date(),
        profilo: store.getState().statoUtente.utente.profilo,
        presidio: getPresidio(store.getState().statoUtente.utente),
    }

    return browserInfo
}

export const isCacheDaAggiornare = (dati, profilo, datiDaCofrontare) => {
    let nuoviDatiUtente = Object.assign(dati, {})
    let getDati = () => {
        switch (profilo) {
            case 'profilo-paziente':
                let data = dati.dataNascita

                nuoviDatiUtente = Object.assign(dati, { anno: data.split('/')[2] })
                let paziente = {
                    nome: datiDaCofrontare.nome,
                    cognome: datiDaCofrontare.cognome,
                    anno: datiDaCofrontare.dataNascita.split('/')[2],
                }
                if (dati.immagineProfilo) {
                    if (dati.immagineProfilo.urlThumbNail) {
                        paziente.immagine = dati.immagineProfilo.urlThumbNail
                    }
                }
                return paziente
            case 'profilo-segreteria':
                return {
                    nome: datiDaCofrontare.nome,
                    cognome: datiDaCofrontare.cognome,
                    profilo: 'profilo-segreteria',
                }
            case 'profilo-dottore':
                return {
                    nome: datiDaCofrontare.nome,
                    genere: datiDaCofrontare.genere,
                    cognome: datiDaCofrontare.cognome,
                    albo: datiDaCofrontare.albo,
                    laurea: datiDaCofrontare.laurea,
                    profilo: 'profilo-dottore',
                }
            case 'profilo-amministrazione-clinica':
                return {
                    nome: datiDaCofrontare.nome,
                    email: datiDaCofrontare.email,
                    web: datiDaCofrontare.web,
                    telefonoCellulare: datiDaCofrontare.telefonoCellulare,
                    telefonoFisso: datiDaCofrontare.telefonoFisso,
                }

            default:
                return false
        }
    }

    let datiCache = getDati()

    console.log('dati : ', datiCache)

    if (!datiCache) {
        return false
    }

    let isModificato = false
    let datiDaRitornare = {}

    Object.keys(datiCache).forEach((chiave) => {
        console.log('chiave : ', chiave)
        console.log(datiCache[chiave])
        console.log(nuoviDatiUtente[chiave])
        datiDaRitornare[chiave] = nuoviDatiUtente[chiave]
        if (datiCache[chiave] !== nuoviDatiUtente[chiave]) {
            isModificato = true
        }
    })
    return isModificato ? datiDaRitornare : false
}

export const convertiBlobInDataUrl = (blob) => {
    var a = new FileReader()
    console.log(a.readAsDataURL(blob))
    return a.readAsDataURL(blob)
}
