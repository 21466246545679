import { Grid } from '@mui/material'
import { AGGIORNA_FILTRI_ESERCIZIO } from 'data/azioni/AGGIORNA_FILTRI_ESERCIZIO'
import { InputText } from 'fields/fieldText'
import { InputTags } from 'fields/fieldTags'
import React, { useState } from 'react'
import { connect } from 'react-redux'

export function ElementoCampiCercaPaziente(props) {
    let modelNomeEsercizio = {
        id: 'titolo',
        tipo: 'fieldText',
        associazione: 'titolo',
        label: 'Nome esercizio :',
        value: '',
        details: {
            variant: 'outlined',
            type: 'text',
        },
    }
    let modelTagEsercizio = {
        id: 'tags',
        tipo: 'fieldTags',
        associazione: 'tags',
        label: 'Tag esercizio :',
        value: '',
        details: {
            nascondiBottone: true,
            variant: 'outlined',
            type: 'text',
        },
    }

    if (props.testoBianco) {
        modelNomeEsercizio.details.testoBianco = true
        modelTagEsercizio.details.testoBianco = true
        modelNomeEsercizio.details.color = 'white'
        modelTagEsercizio.details.color = 'white'
        modelNomeEsercizio.details.InputLabelProps = { style: { color: '#fff' } }
        modelTagEsercizio.details.InputLabelProps = { style: { color: '#fff' } }
        modelNomeEsercizio.details.sx = {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white !important',
            },
        }
        modelTagEsercizio.details.sx = {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white !important',
            },
        }
    }

    const aggiornaFiltri = (campo, valore) => {
        AGGIORNA_FILTRI_ESERCIZIO({ campo: campo, valore: valore })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <InputText model={modelNomeEsercizio} value={props.nomeEsercizio} azione={(a, b) => aggiornaFiltri(a, b)} errore='' />
            </Grid>
            <Grid item xs={12} lg={6}>
                <InputTags model={modelTagEsercizio} value={props.tagEsercizio} azione={(a, b) => aggiornaFiltri(a, b)} errore='' />
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {
        tagEsercizio: state.reducerFiltriEsercizio?.tags || [],
        nomeEsercizio: state.reducerFiltriEsercizio?.titolo || '',
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ElementoCampiCercaPaziente)
