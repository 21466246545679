const initialState = {
    anno: `${new Date().getFullYear()}`,
    mesi: new Date().getMonth() + 1,
    //idDottore: '',
    stato: '',
}

export const reducerFiltriFatture = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'AGGIORNA_FILTRI_FATTURE':
            return action.payload
        case 'SVUOTA_FILTRI_FATTURE':
            return {
                anno: `${new Date().getFullYear()}`,
                mesi: new Date().getMonth() + 1,
                //idDottore: '',
                stato: '',
            }
        /* case "CAMBIA_URL":
           break */
        default:
            return state
    }
}
