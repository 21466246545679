import { getIdPaziente } from '../../getter/getterPaziente'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { AGGIORNA_CACHE } from './AGGIORNA_CACHE'
import { writeBatch, doc, Timestamp } from 'firebase/firestore'

import { newId, creaEtichettaUniversale, getPresidio } from './../strumenti'

export const AGGIUNGI_ESERCIZIO_DA_LIBRERIA = (esercizio, commento) => {
    return new Promise((resolve, reject) => {
        let presidio = getPresidio(getUtenteLoggato())
        let newIdUtilizzo = newId()
        let etichettaUniversale = creaEtichettaUniversale(presidio)
        let idPaziente = getIdPaziente()

        const batch = writeBatch(db)

        let esercizioDaAggiungere = {
            dataCreazione: Timestamp.fromDate(new Date()),
            idPaziente: idPaziente,
        }
        var creaEsercizio = doc(db, 'esercizi', esercizio.id)
        batch.update(creaEsercizio, { [`utilizzi.${newIdUtilizzo}`]: esercizioDaAggiungere })

        var datiPerUtente = {
            commenti: commento,
            attivo: true,
            etichettaUniversale: etichettaUniversale,
            idEsercizio: esercizio.id,
            idUtilizzo: newIdUtilizzo,
        }
        var paziente = doc(db, 'utenti', idPaziente)
        batch.update(paziente, { [`esercizi.${newIdUtilizzo}`]: datiPerUtente })

        batch.commit().then(() => {
            var datiPerCache = { [`${esercizio.id}.utilizzi.${newIdUtilizzo}`]: esercizioDaAggiungere }
            AGGIORNA_CACHE(datiPerCache, 'esercizi', presidio).then(() => {
                resolve()
            })
        })
    })
}
