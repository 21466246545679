import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const AGGIORNA_AUTENTICAZIONE_UTENTE = (id, pass, attributo) => {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

        const getCredenziali = () => {
            var toReturn = { idUtente: id }
            if (attributo !== undefined) {
                toReturn[attributo] = pass
                return toReturn
            }
            toReturn['password'] = pass.password
            toReturn['mail'] = pass.email
            return toReturn
        }

        CHIAMA_SERVER(`aggiornaCredenzialiUtente`, getCredenziali()).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
