import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { InputSelect } from 'fields/fieldSelect'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import VediForm from 'formMaker/vediForm'
import { formPrivacyDatiTutore } from 'formModel/formPrivacyDatiTutore'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getPaziente } from 'getter/getterPaziente'
import { getIdPaziente } from 'getter/getterPaziente'
import { copiaOggetto } from 'data/strumenti'
import { getDatiPresidio } from 'getter/getterPresidio'
import { getPresidio } from 'data/strumenti'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getIdLesioneSelezionata } from 'getter/getterLesioni'
import ElementoFirmaDocumento from './elementoFirmaDocumento'

export function elementoGeneraPdf(props) {
    const stepsTutore = ['Chi Firma', 'Dati tutore', 'Firma']
    const stepsNoTutore = ['Chi Firma', 'Firma']
    const [step, setStep] = useState(0)
    const [steps, setSteps] = useState(stepsNoTutore)
    const [chiFirma, setChiFirma] = useState('Paziente')
    const [datiTutore, setDatiTutore] = useState({})

    useEffect(() => {
        if (chiFirma === 'Paziente') {
            setSteps(stepsNoTutore)
        } else {
            setSteps(stepsTutore)
        }
    }, [chiFirma])

    const modelSelect = {
        id: 'chiFirma',
        tipo: 'fieldSelect',
        associazione: 'chiFirma',
        label: 'Chi firma:',
        value: '',
        details: {
            scelte: ['Paziente', 'Tutore'],
            complex: false,
        },
    }

    const salvaTutore = (dati, nuovoStep) => {
        setDatiTutore(dati)
        setStep(nuovoStep)
    }

    const renderDatiTutore = () => {
        return <VediForm form={formPrivacyDatiTutore()} soloInput={true} dati={datiTutore} vista='input' isStepCentrale={true} annulla={() => props.annulla()} salva={(e) => salvaTutore(e, step + 1)} indietro={(e) => salvaTutore(e, step - 1)} />
    }

    const renderFirma = () => {
        let daPassare = Object.assign({}, props.data)
        daPassare.isTutore = chiFirma === 'Tutore'
        if (chiFirma === 'Tutore') {
            daPassare.datiTutore = datiTutore
        }
        return <ElementoFirmaDocumento tipoPdf={props.tipoPdf} data={daPassare} annulla={() => props.annulla()} indietro={() => setStep(step - 1)} />
    }

    const renderChiFirma = () => {
        return (
            <MDBox display='flex' flexDirection='column'>
                <InputSelect model={modelSelect} value={chiFirma} errore='' azione={(a, b) => setChiFirma(b)} />
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='error' onClick={() => props.annulla()}>
                        annulla
                    </MDButton>
                    <MDButton variant='contained' color='primary' onClick={() => setStep(1)}>
                        avanti
                    </MDButton>
                </div>
            </MDBox>
        )
    }

    const cosaVedere = () => {
        switch (step) {
            case 0:
                return renderChiFirma()
            case 1:
                return steps.length === 2 ? renderFirma() : renderDatiTutore()
            case 2:
                return renderFirma()

            default:
                break
        }
    }
    return (
        <React.Fragment>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label} completed={index < step}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <MDBox mt={2}>{cosaVedere()}</MDBox>
        </React.Fragment>
    )
}

const getDatiPaziente = (ownProps) => {
    let datiPaziente = {}
    let idPaziente = ''
    if (getProfiloUtenteLoggato() === 'profilo-chiosco') {
        datiPaziente = ownProps.datiPaziente
        idPaziente = ownProps.idPaziente
    } else {
        datiPaziente = getPaziente()
        idPaziente = getIdPaziente()
    }
    return {
        dati: copiaOggetto(datiPaziente, ['nome', 'cognome', 'luogoNascita', 'dataNascita', 'indirizzo', 'cap', 'comune', 'provincia']),
        id: idPaziente,
    }
}

function mapStateToProps(state, ownProps) {
    const datiPaziente = getDatiPaziente(ownProps)
    const data = {
        data: new Date(),
        datiPaziente: datiPaziente.dati,
        presidio: copiaOggetto(getDatiPresidio(), ['nome', 'mail', 'comune']),
        idNecessari: { idPaziente: datiPaziente.id, idPresidio: getPresidio(getUtenteLoggato()) },
    }
    if (ownProps.isStrategia) {
        let strategia = Object.assign({}, ownProps.strategia.info)
        strategia.sintomi = ownProps.strategia.info.sintomi.toString()
        data.strategia = strategia
        data.idNecessari = Object.assign(data.idNecessari, ownProps.strategia.id, { idLesione: getIdLesioneSelezionata() })
    }
    return { data }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoGeneraPdf)
