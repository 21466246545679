import { getIdUtente, getPresidio, getUtente, isCacheDaAggiornare } from '../../getter/getterUtente'
import { getVeroUtenteLoggato } from '../..//getter/getterUtenteLoggato'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { APRI_POP_UP } from './APRI_POP_UP'

export const AGGIORNA_DATI_UTENTE = async (dati) => {
    
    let profilo = dati.profilo || 'profilo-amministrazione-clinica'
    let presidio = getPresidio(dati)
    if (profilo === 'profilo-amministrazione-clinica') {
        presidio = getPresidio(getVeroUtenteLoggato())
    }
    let idUtente = getIdUtente()

    let daPassare = {
        profilo: profilo,
        datiUtente: dati,
        inserisciCredenziali : false
    }

    if (profilo === 'profilo-amministrazione-clinica') {
        daPassare.idPresidio = presidio
    }
    if (profilo !== 'profilo-amministrazione-clinica') {
        daPassare.idUtente = idUtente
        daPassare.idPresidio = presidio
    }

    await CHIAMA_SERVER(`aggiornaUtente`, daPassare)
    
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata' } })
    return
}
