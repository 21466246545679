import { listaTipoSpesa } from 'data/configurazioneVoceFattura'
import { getIdUtenteLoggato, getProfiloUtenteLoggato, getUtenteLoggato } from './../getter/getterUtenteLoggato'

let getDottore = () => {
    if (getUtenteLoggato().profilo === 'profilo-dottore') {
        return getIdUtenteLoggato()
    }
    let idDottore = ''
    let dottori = window.listaDipendenti.reduce((acc, dip) => {
        if (dip.contenuto.profilo === 'profilo-dottore') {
            acc += 1
            idDottore = dip.id
        }
        return acc
    }, 0)
    if (dottori === 1) {
        return idDottore
    }
    return ''
}

const subInputPaziente = (paziente) => {
    return {
        parts: [
            {
                id: 'nome',
                tipo: 'fieldText',
                associazione: 'nome',
                label: 'Nome:',
                value: paziente.nome,
            },
            {
                id: 'cognome',
                tipo: 'fieldText',
                associazione: 'cognome',
                label: 'Cognome:',
                value: paziente.cognome,
            },
            {
                id: 'residenza',
                tipo: 'fieldText',
                associazione: 'residenza',
                label: 'Indirizzo:',
                value: paziente.indirizzo,
            },
            {
                id: 'cf',
                tipo: 'fieldText',
                associazione: 'cf',
                label: 'Codice fiscale:',
                value: paziente.cf,
                details: {
                    maiuscolo: true,
                },
            },
        ],
    }
}

const subInputFattura = (numFattura) => {
    return {
        parts: [
            {
                id: 'numFattura',
                tipo: 'fieldNumeroDocumento',
                associazione: 'numFattura',
                label: 'Numero fattura',
                value: `${numFattura}-${new Date().getFullYear()}`,
                validazione: 'validazioneNumeroDocumento',
            },
            {
                id: 'date',
                tipo: 'fieldData',
                associazione: 'date',
                label: 'Data',
                value: new Date(),
                details: {
                    tornaStringa: true,
                },
            },
        ],
    }
}

const subInputVoci = (defaultFatture) => {
    return {
        parts: [
            {
                id: 'descrizione',
                tipo: 'fieldText',
                associazione: 'descrizione',
                label: 'Descrizione',
                value: '',
                validazione: 'testoRichiesto',
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                },
            },
            {
                id: 'importo',
                tipo: 'fieldText',
                associazione: 'importo',
                label: 'Importo',
                value: 0,
                validazione: 'numeroMaggioreDi0',
                details: {
                    type: 'number',
                },
            },
            {
                id: 'tipoSpesa',
                tipo: 'fieldSelect',
                associazione: 'tipoSpesa',
                label: 'Tipo spesa :',
                value: defaultFatture.defaultVoci?.tipoSpesa || '',
                details: {
                    scelte: listaTipoSpesa,
                    toShow: 'testo',
                    toSave: 'id',
                    complex: true,
                },
                validazione: 'testoRichiestoSelect',
            },
            {
                id: 'iva',
                tipo: 'fieldIvaVoceFattura',
                associazione: 'iva',
                label: 'Iva',
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                },
                value: defaultFatture.defaultVoci?.iva || { tipo: 'naturaIVA', valore: '' },
                validazione: 'validazioneIvaFattura',
            },
        ],
    }
}

const subInputExtraModifica = {
    costumCssInput: ['inColonna'],
    parts: [
        {
            id: 'marca',
            tipo: 'fieldBoolean',
            associazione: 'marca',
            label: 'Marca da bollo',
            value: false,
            details: {
                grid: { lg: { input: 4, output: 4 } },
            },
        },
        {
            id: 'forfetario',
            tipo: 'fieldBoolean',
            associazione: 'forfetario',
            label: 'Regime forfetario',
            value: false,
            details: { grid: { lg: { input: 4, output: 4 } } },
        },
        /* {
            id: 'pagamentoTracciato',
            tipo: 'fieldBoolean',
            associazione: 'pagamentoTracciato',
            label: 'Pagamento tracciato',
            value: false,
            details: {
                costumCss: ['lineaSeServe'],
            },
        }, */
        {
            id: 'flagOpposizione',
            tipo: 'fieldBoolean',
            associazione: 'flagOpposizione',
            label: 'opposizione',
            value: false,
            details: {
                grid: { lg: { input: 4, output: 4 } },
            },
        },
        {
            id: 'pagamento',
            tipo: 'fieldDataPagamentoFatture',
            associazione: 'pagamento',
            label: 'Pagamento :',
            value: { pagamentoAnticipato: false, data: 0 },
            details: {
                grid: { lg: { input: 4, output: 4 } },
            },
        },
        {
            id: 'pagamentoTracciato',
            tipo: 'fieldSelect',
            associazione: 'pagamentoTracciato',
            label: 'Metodo di pagamento :',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 } },
                scelte: ['bancomat', 'satispay', 'contanti', 'assegno', 'carta di credito', 'bonifico', 'assicurazione'],
            },
            validazione: 'testoRichiestoSelect',
        },

        {
            id: 'tipoDocumento',
            tipo: 'fieldSelect',
            associazione: 'tipoDocumento',
            label: 'Tipo documento',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 } },
                scelte: [
                    { id: 'F', testo: 'Fattura' },
                    { id: 'D', testo: 'Documento fiscale' },
                ],
                complex: true,
                toShow: 'testo',
                toSave: 'id',
            },
            validazione: 'testoRichiestoSelect',
        },
    ],
}
const subInputExtra = {
    parts: [
        {
            id: 'marca',
            tipo: 'fieldBoolean',
            associazione: 'marca',
            label: 'Marca da bollo',
            value: false,
            details: { grid: { lg: { input: 4, output: 4 } } },
        },
        {
            id: 'forfetario',
            tipo: 'fieldBoolean',
            associazione: 'forfetario',
            label: 'Regime forfetario',
            value: false,
            details: { grid: { lg: { input: 4, output: 4 } } },
        },
        /* {
            id: 'pagamentoTracciato',
            tipo: 'fieldBoolean',
            associazione: 'pagamentoTracciato',
            label: 'Pagamento tracciato',
            value: false,
            details: {
                costumCss: ['lineaSeServe'],
            },
        }, */
        {
            id: 'flagOpposizione',
            tipo: 'fieldBoolean',
            associazione: 'flagOpposizione',
            label: 'opposizione',
            value: false,
            details: { grid: { lg: { input: 4, output: 4 } } },
        },
        {
            id: 'pagamento',
            tipo: 'fieldDataPagamentoFatture',
            associazione: 'pagamento',
            label: 'Pagamento :',
            value: { pagamentoAnticipato: false, data: 0 },
            details: {
                grid: { lg: { input: 4, output: 4 } },
            },
        },
        {
            id: 'pagamentoTracciato',
            tipo: 'fieldSelect',
            associazione: 'pagamentoTracciato',
            label: 'Metodo di pagamento :',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 } },
                scelte: ['bancomat', 'satispay', 'contanti', 'assegno', 'carta di credito', 'bonifico', 'assicurazione'],
            },
            validazione: 'testoRichiestoSelect',
        },

        {
            id: 'tipoDocumento',
            tipo: 'fieldSelect',
            associazione: 'tipoDocumento',
            label: 'Tipo documento',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 } },
                scelte: [
                    { id: 'F', testo: 'Fattura' },
                    { id: 'D', testo: 'Documento fiscale' },
                ],
                complex: true,
                toShow: 'testo',
                toSave: 'id',
            },
            validazione: 'testoRichiestoSelect',
        },
        {
            id: 'dispositivo',
            tipo: 'fieldText',
            associazione: 'dispositivo',
            label: 'Dispositivo :',
            value: 1,
            details: {
                grid: { lg: { input: 4, output: 4 } },
                type: 'number',
                costumCss: ['lineaSeServe'],
            },
        },
    ],
}

export const formPaziente = (paziente) => {
    return {
        parts: [
            {
                id: 'paziente',
                tipo: 'fieldSubInput',
                associazione: 'paziente',
                label: 'Dati paziente:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputPaziente(paziente),
                },
            },
        ],
    }
}
export const formOutputModifica = (paziente, fattura) => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'paziente',
                tipo: 'fieldSubInput',
                associazione: 'paziente',
                label: 'Dati paziente:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputPaziente(paziente),
                },
            },
            {
                id: 'datiFattura',
                tipo: 'fieldSubInput',
                associazione: 'datiFattura',
                label: 'Dati fattura:',
                value: fattura.datiFattura,
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputFattura(0),
                },
            },
        ],
    }
}

export const formFatture = (numFattura, dottori, defaultFatture) => {
    return {
        parts: [
            {
                id: 'datiFattura',
                tipo: 'fieldSubInput',
                associazione: 'datiFattura',
                label: 'Dati fattura:',
                value: { numFattura: `${numFattura}-${new Date().getFullYear()}`, date: new Date() },
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputFattura(numFattura),
                },
            },
            {
                id: 'voci',
                tipo: 'fieldList',
                associazione: 'voci',
                label: 'Voci:',
                value: [],
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputVoci(defaultFatture),
                },
                validazione: 'listaMaggioreDi0',
            },
            {
                id: 'idDottore',
                tipo: 'fieldSelect',
                associazione: 'idDottore',
                label: 'Dottore:',
                value: getDottore(),
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    toShow: 'nome',
                    toSave: 'id',
                    scelte: dottori,
                    complex: true,
                },
                validazione: 'testoRichiestoSelect',
            },
            {
                id: 'extra',
                tipo: 'fieldSubInput',
                associazione: 'extra',
                label: 'Extra:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputExtra,
                },
            },
        ],
    }
}
export const formModificaFattura = (dottori, defaultFatture) => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'voci',
                tipo: 'fieldList',
                associazione: 'voci',
                label: 'Voci:',
                value: [],
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputVoci(defaultFatture),
                },
                validazione: 'listaMaggioreDi0',
            },
            {
                id: 'idDottore',
                tipo: 'fieldSelect',
                associazione: 'idDottore',
                label: 'Dottore:',
                value: getDottore(),
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    toShow: 'nome',
                    toSave: 'id',
                    scelte: dottori,
                    complex: true,
                },
                validazione: 'testoRichiestoSelect',
            },
            {
                id: 'extra',
                tipo: 'fieldSubInput',
                associazione: 'extra',
                label: 'Extra:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputExtraModifica,
                },
            },
        ],
    }
}
