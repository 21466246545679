import React, { useEffect } from 'react'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import VediForm from 'formMaker/vediForm'
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded'
import { formRegistrazionePresidio } from 'formModel/formRegistrazionePresidio'
import { CREA_PRESIIDO } from './../data/azioni/CREA_PRESIIDO'
import { connect } from 'react-redux'
import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export function paginaInserimentoDati(props) {
    const salva = async (e) => {
        await CREA_PRESIIDO(e)
        props.navigate('/paginaVediDipendenti')
    }

    useEffect(() => {
        APRI_POP_UP({ id: 'guida', parametro: '' })
    }, [])

    return (
        <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MenuBookRoundedIcon color='white' sx={{ mr: 1 }} />
                <MDTypography color={'white'} variant='h5'>
                    Dati presidio
                </MDTypography>
            </MDBox>
            <VediForm form={formRegistrazionePresidio()} vista='input' dati={props.dati} noAnnulla={true} salva={(e) => salva(e)} />
        </MDBox>
    )
}

function mapStateToProps(state, ownProps) {
    const presidio = getPresidio(getVeroUtenteLoggato())
    if (presidio !== undefined && presidio !== '') {
        ownProps.navigate('/paginaVediDipendenti')
    }
    return {
        dati: getVeroUtenteLoggato(),
    }
}

export default connect(mapStateToProps, null)(paginaInserimentoDati)
