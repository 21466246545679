import { store } from "./../data/store"
import { trasformaOggettoInArray } from './../data/strumenti'

export const getVersioni = () => {
    let versioni = trasformaOggettoInArray(store.getState().reducerVersioni.versioni)
    versioni = versioni.sort((a, b) => {
        return b.contenuto.data.seconds - a.contenuto.data.seconds
    })
    return versioni
}
export const isVersioneObsoleta = () => {
    return false
    return store.getState().reducerVersioni.isObsoleta
}

export const getVersioneAttuale = () => {
    return store.getState().reducerVersioni.versioneAttuale
}
