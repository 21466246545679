export const listaBandiere = [
    {
        id: 'rosso',
        titolo: 'Bandiere rosse o “Red flags”',
        colore: 'red',
        descrizione: `Le bandiere rosse sono semplicemente qualsiasi sintomo e segno o un ‘insieme di essi che rilevati dal fisioterapista durante la visita fisioterapica suggerisce la presenza di una malattia più grave e quindi richiede l’ attenzione del medico.
        Ci sono pochi casi in cui si verificano bandiere rosse (circa l’1% dei pazienti visti in consultazione), ma è essenziale rilevarli in anticipo in modo che possano essere rapidamente indirizzati allo specialista medico`,
    },
    {
        id: 'arancione',
        titolo: 'Bandiere arancioni o “Orange flags”',
        colore: 'orange',
        descrizione: `Hanno iniziato ad essere utilizzati nel 2005 come equivalente delle bandiere rosse nel campo della salute mentale. Questa bandiera ha lo scopo di avvertire della possibile origine psichiatrica del problema del paziente (stress, gravi disturbi della personalità, abuso e/o tossicodipendenza, depressione grave ed altresì). Quando presente, o si sospetta la sua presenza, è opportuno che Il fisioterapista lo riferisca allo specialista appropriato`,
    },
    {
        id: 'giallo',
        titolo: 'Bandiere gialle o “Yellow flags”',
        colore: 'yellow',
        descrizione: `
            Sono indicatori riguardanti gli aspetti cognitivi e comportamentali del paziente, sono problemi biopsicosociali che dovrebbero suggerire ulteriori indagini e modificazioni dell’approccio riabilitativo. Al loro interno possiamo distinguere tre gruppi:

           
            Credenze e pregiudizi: Sul dolore, sul risultato del trattamento, sul congedo dal lavoro ed altresì.

            
            Risposte emotive: 
            Quando non sono gravi psichiatricamente come ad esempio preoccupazione, paura e ansia.

           
            Atteggiamenti nei confronti del dolore: 
            Presenti quando i pazienti evitano determinate attività per paura del dolore, difficoltà a gestire il dolore, preferenza per i trattamenti passivi ed altresì.

            
            Si deve tener conto del fatto che questi fattori non sono presenti solo nei pazienti, i fisioterapisti possono anche ostacolare l’evoluzione del paziente con le proprie bandiere gialle come ad esempio: convinzioni, preferenza per determinati test o trattamenti diagnostici, ignoranza di altre tecniche. Le bandiere gialle non impediscono il trattamento fisioterapico, ma devono essere prese in considerazione per determinare l’orientamento del trattamento, poiché sarà necessario un lavoro pedagogico per modificare i fattori identificati.
        `,
    },
    {
        id: 'blu',
        titolo: 'Bandiere blu o “Blue flags"',
        colore: 'blue',
        descrizione: `Con questo nome ci riferiamo agli aspetti che mettono in relazione la salute de paziente con il mondo del lavoro come ad esempi: non essere soddisfatto delle condizioni di lavoro, credere che il lavoro danneggi la propria salute, cattiva relazione con colleghi o superiori.`,
    },
    {
        id: 'nero',
        titolo: 'Bandiere nere o “Black flags”',
        colore: 'black',
        descrizione: `Sebbene in alcuni aspetti possano sovrapporsi alle bandiere blu, le bandiere nere indicano fattori che sfuggono al controllo del paziente, come problemi legati all’amministrazione o al contesto. Ad esempio: Problemi economici, ostacoli al ritorno al lavoro, problemi con l’assicurazione contro gli infortuni, familiari o professionisti della salute iperprotettivi, attività non ergonomicamente modificabile, problemi relativi alle dichiarazioni di disabilità. Questi fattori possono limitare o bloccare l’aiuto che il paziente riceve.`,
    },
]
