import { eventiCache } from 'mixing/eventiCache'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import VediForm from 'formMaker/vediForm'
import { formFIltraFatture } from 'formModel/formFiltraFatture'
import { isEqual } from 'lodash-es'
import { AGGIORNA_FILTRI_FATTURE } from 'data/azioni/AGGIORNA_FILTRI_FATTURE'
import { store } from 'data/store'
import { getFiltriFatture } from 'getter/getterFatture'

class elementoFiltraFatture extends eventiCache(Component) {
    constructor(props) {
        super(props)
        this.state.form = formFIltraFatture([])
        this.state.filtriIniziali = {}
    }

    cacheFattureAggiornate() {
        this.aggiornaForm()
    }

    componentDidMount() {
        super.componentDidMount()
            
       
        this.aggiornaForm()
        //this.setState({ filtriIniziali: Object.assign({}, this.props.filtri) })
    }

    aggiornaForm() {
        let anno = `${new Date().getFullYear()}`
        let lista = Object.keys(window.cacheFatture)
        if (!lista.includes(anno)) {
            lista.push(anno)
        }
        let nuovoForm = formFIltraFatture(lista)
        this.setState({ form: nuovoForm })
    }

    aggiornaFiltri(filtri) {
        let evento = !isEqual(filtri.anno, this.state.filtriIniziali.anno) || !isEqual(filtri.mesi, this.state.filtriIniziali.mesi)
        AGGIORNA_FILTRI_FATTURE(filtri)
        if (evento) {
            window.dispatchEvent(new CustomEvent('cercaFatture'))
            this.setState({ filtriIniziali: Object.assign({}, filtri) })
        }
        if (this.props.annulla !== undefined) {
            this.props.annulla()
        }
    }

    annulla() {
        this.props.annulla()
    }

    svuota() {
        store.dispatch({
            type: 'SVUOTA_FILTRI_FATTURE',
        })
        window.dispatchEvent(new CustomEvent('cercaFatture'))
        if (this.props.annulla !== undefined) {
            this.props.annulla()
        }
    }

    render() {
        return <VediForm soloInput={true} bottoniFiltri={true} form={this.state.form} dati={Object.assign(this.props.filtri)} vista='input' salva={(e) => this.aggiornaFiltri(e)} annulla={this.props.annulla} svuota={() => this.svuota()} />
    }
}

function mapStateToProps(state) {
    return {
        filtri: getFiltriFatture(),
    }
}

export default connect(mapStateToProps, null)(elementoFiltraFatture)
