import { getDipendenteDaId, isClienteAifi, isProvaGratuitaDisponibile } from '../../getter/getterDipendenti'
import { getDatiPresidio } from '../../getter/getterPresidio'
import { getIdUtenteLoggato, getUtenteLoggato, getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../strumenti'
import { store } from './../store'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const ATTIVA_ABBONAMENTO = (id) => {
    let dipendente = getDipendenteDaId(id)
    //console.log('test : ', dipendente.contenuto.profilo)
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        let daPassare = {
            idDottore: dipendente.id,
            //profilo: dipendente.contenuto.profilo,
            prova: isProvaGratuitaDisponibile(id),
            //nome: `${dipendente.contenuto.nome} ${dipendente.contenuto.cognome}`,
            /* presidio: {
                email: getDatiPresidio().email,
                nome: getDatiPresidio().nome,
                telefonoCellulare: getDatiPresidio().telefonoCellulare,
                id: getPresidio(getVeroUtenteLoggato()),
                aifi: isClienteAifi(id),
            }, */
            idPresidio : getPresidio(getVeroUtenteLoggato()),
            url: window.location.href,
        }

        /* if (getDatiPresidio().idStripe !== undefined) {
            daPassare.customer = getDatiPresidio().idStripe
        } */
        
        CHIAMA_SERVER(`creaAbbonamento`, daPassare).then((session) => {
            stripe.redirectToCheckout({
                sessionId: session.message,
            })
        })

        resolve()
    })
}
