import { getIdLesioneSelezionata } from '../../getter/getterLesioni'
import { getIdPaziente } from '../../getter/getterPaziente'
import { doc, updateDoc } from 'firebase/firestore'

export const AGGIORNA_TITOLO_LESIONE = async (nomeParametro) => {
    let idPaziente = getIdPaziente()
    let idLesione = getIdLesioneSelezionata()
    await updateDoc(doc(db, 'utenti', idPaziente, 'lesioni', idLesione), { titolo: nomeParametro })
    return
}
