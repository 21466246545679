import { getAuth, updatePassword, updateEmail } from 'firebase/auth'
import { getIdUtenteLoggato } from './../../getter/getterUtenteLoggato'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const AGGIORNA_CREDENZIALI_UTENTE = (pass, attributo) => {
    var utente = getAuth().currentUser

    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

        let aggiorna = () => {
            return new Promise((resolve, reject) => {
                if (attributo === 'password') {
                    updatePassword(utente, pass).then(() => {
                        resolve()
                    })
                }
                if (attributo !== 'password') {
                    updateEmail(utente, pass).then(() => {
                        resolve()
                    })
                }
            })
        }

        aggiorna().then(() => {
            CHIAMA_SERVER(`aggiornaCredenzialiUtente`, { idUtente: utente.uid,  [attributo]: pass }).then(() => {
                window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
                resolve()
            })
        })
    })
}
