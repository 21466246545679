import React, { useEffect, useState } from 'react'
import IllustrationLayout from 'layouts/authentication/components/IllustrationLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import bgImage from 'assets/images/svgLogoPhysioBianco.svg'
import { Link } from 'react-router-dom'
import { InputText } from 'fields/fieldText'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

export default function paginaLogin(props) {
    const [credenziali, setCredenziali] = useState({ mail: '', password: '' })
    const [errore, setErrore] = useState('')

    const formEmail = {
        id: 'mail',
        tipo: 'fieldText',
        associazione: 'mail',
        label: 'Email',
        value: '',
        details: {
            id: 'mail',
            type: 'email',
        },
    }
    const formPassword = {
        id: 'password',
        tipo: 'fieldText',
        associazione: 'password',
        label: 'Password',
        value: '',
        details: {
            id: 'password',
            type: 'password',
        },
    }

    const login = () => {
        setErrore('')
        const auth = getAuth()

        signInWithEmailAndPassword(auth, credenziali.mail, credenziali.password)
            .then((userCredential) => {})
            .catch((error) => {
                setErrore('Credenziali errate')
            })
    }

    const premutoInvio = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            document.getElementById('login').click()
        }
    }

    useEffect((e) => {
        setTimeout(() => {
            document.getElementById('password').addEventListener('keypress', (e) => {
                premutoInvio(e)
            })

            document.getElementById('mail').addEventListener('keypress', (e) => {
                premutoInvio(e)
            })
        }, 100)
    }, [])

    const aggiornaCredenziali = (campo, valore) => {
        let nuoveCredenziali = Object.keys(credenziali).reduce((acc, chiave) => {
            let daInserire = chiave === campo ? valore : credenziali[chiave]
            acc[chiave] = daInserire
            return acc
        }, {})
        setCredenziali(nuoveCredenziali)
    }

    return (
        <IllustrationLayout title='Accedi' description='Inserisci mail e password per accedere' illustration={bgImage}>
            <MDBox component='form' role='form'>
                <MDBox mb={2}>
                    <InputText model={formEmail} value={credenziali.mail} errore='' azione={(a, b) => aggiornaCredenziali(a, b)} />
                </MDBox>
                <MDBox mb={2}>
                    <InputText model={formPassword} value={credenziali.password} errore='' azione={(a, b) => aggiornaCredenziali(a, b)} />
                </MDBox>
                <MDTypography variant='caption' color='error' fontWeight='bold'>
                    {errore}
                </MDTypography>
                <MDBox display='flex' alignItems='center' ml={-1}></MDBox>
                <MDBox mt={4} mb={1}>
                    <MDButton id='login' color='primary' size='large' fullWidth onClick={() => login()}>
                        Accedi
                    </MDButton>
                    <MDButton color='error' size='large' fullWidth variant='text' onClick={() => props.navigate('/')}>
                        annulla
                    </MDButton>
                </MDBox>
                <MDBox mt={3} textAlign='center'>
                    <MDTypography color='text'>
                        Hai dimenticato la password?{' '}
                        <MDTypography component={Link} to='/passwordDimenticata' color='primary' fontWeight='medium'>
                            Clicca qui
                        </MDTypography>
                    </MDTypography>
                </MDBox>
            </MDBox>
        </IllustrationLayout>
    )
}
