import { getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../strumenti'
import { baseWatcher } from './baseWatcher'
import { LEGGI_UTENZE_TS } from './../azioni/LEGGI_UTENZE_TS'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherUtenzeTs extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', getPresidio(getVeroUtenteLoggato()), 'cache', 'utenzeTs'), (documento) => {

            if (!documento.exists()) {
                LEGGI_UTENZE_TS([])
            }
            if (documento.exists()) {
                LEGGI_UTENZE_TS(
                    Object.keys(documento.data()).map((el) => {
                        return { id: el, nome: documento.data()[el].nome }
                    }),
                )
            }
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
