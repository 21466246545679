import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import { InputText } from 'fields/fieldText'
import { AGGIORNA_ASSEGNAZIONE_ESERCIZIO } from 'data/azioni/AGGIORNA_ASSEGNAZIONE_ESERCIZIO'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export default function popUpModificaEsercizioAssegnato(props) {
    const [nome, setNome] = useState('')
    const [errore, setErrore] = useState('')

    useEffect(() => {
        if (props.aperto) {
            console.log("gguardaaaa : ", props.parametro.commento);
            setNome(props.parametro.commento)
            setErrore('')
        }
    }, [props.aperto])

    const handleClose = () => {
        props.chiudi()
    }

    const salva = async () => {
        if (nome === '') {
            setErrore('Inserire una posologia')
            return
        }

        if (props.parametro.azione !== undefined) {
            props.parametro.azione(nome)
            handleClose()
            return
        }

        await AGGIORNA_ASSEGNAZIONE_ESERCIZIO(props.parametro.idAssegnazione, nome)
        handleClose()
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata' } })
    }

    const model = {
        id: 'commento',
        tipo: 'fieldTextArea',
        associazione: 'commento',
        label: 'Posologia :',
        value: '',
    }

    return (
        <Dialog open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>POSOLOGIA ESERCIZIO</DialogTitle>
            <DialogContent dividers={true}>
                <InputText model={model} value={nome} errore={errore} azione={(a, b) => setNome(b)} />
            </DialogContent>
            <DialogActions>
                <MDButton color='error' variant='text' onClick={() => handleClose()}>
                    annulla
                </MDButton>

                <MDButton color='success' variant='text' onClick={() => salva()}>
                    salva
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
