import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import React, { useState } from 'react'
import * as validator from './../fields/validator'
import { InputText } from 'fields/fieldText'
import MDButton from 'components/MDButton'
import { AGGIORNA_CREDENZIALI_UTENTE } from 'data/azioni/AGGIORNA_CREDENZIALI_UTENTE'

export default function ElementoaAggiornaCredenziali(props) {
    const [nuovo, setNuovo] = useState('')
    const [conferma, setConferma] = useState('')
    const [erroreNuovo, setErroreNuovo] = useState('')
    const [erroreConferma, setErroreConferma] = useState('')

    let formNuovo = {
        id: 'nuovo',
        tipo: 'fieldText',
        associazione: 'nuovo',
        label: props.tipo === 'mail' ? 'Nuova email' : 'Nuova Password',
        value: '',
        details: {
            type: props.tipo === 'mail' ? 'text' : 'password',
        },
    }

    let formConferma = {
        id: 'conferma',
        tipo: 'fieldText',
        associazione: 'conferma',
        label: props.tipo === 'mail' ? 'Nuova email' : 'Nuova Password',
        value: '',
        details: {
            type: props.tipo === 'mail' ? 'text' : 'password',
        },
    }

    function aggiorna(campo, valore) {
        if (campo === 'nuovo') {
            setNuovo(valore)
        }
        if (campo !== 'nuovo') {
            setConferma(valore)
        }
    }

    async function salva() {
        if (nuovo !== conferma) {
            await APRI_POP_UP({ id: 'alert', parametro: { severity: 'error', testo: `Errore` } })
            setErroreConferma(`Le ${props.tipo} non coincidono`)
            return
        }
        console.log('problemaaaaa')

        if (props.tipo === 'mail') {
            try {
                await validator.emailGiaPresente(nuovo)
                await AGGIORNA_CREDENZIALI_UTENTE(nuovo, props.tipo)
                await APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: `${props.tipo} modificata con successo` } })
                props.fine()
            } catch (error) {
                await APRI_POP_UP({ id: 'alert', parametro: { severity: 'error', testo: `Errore` } })
                setErroreNuovo(error)
            }
        }
        if (props.tipo !== 'mail') {
            try {
                await validator.lunghezzaMinima(nuovo)
                await AGGIORNA_CREDENZIALI_UTENTE(nuovo, props.tipo)
                await APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: `${props.tipo} modificata con successo` } })
                props.fine()
            } catch (error) {
                await APRI_POP_UP({ id: 'alert', parametro: { severity: 'error', testo: `Errore` } })
                setErroreNuovo(error)
            }
        }
    }

    return (
        <React.Fragment>
            <form className='test-form'>
                <InputText model={formNuovo} value={nuovo} azione={(a, b) => aggiorna(a, b)} errore={erroreNuovo} />
                <InputText model={formConferma} value={conferma} azione={(a, b) => aggiorna(a, b)} errore={erroreConferma} />
            </form>
            <div className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='error' onClick={() => props.fine()}>
                    annulla
                </MDButton>
                <MDButton variant='contained' color='success' onClick={() => salva()}>
                    salva
                </MDButton>
            </div>
        </React.Fragment>
    )
}
