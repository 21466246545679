import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs
// funzioni generali
const styles = {
    titolo: { fontSize: 20, bold: true, margin: [0, 5] },
    paragrafo: { fontSize: 8, alignment: 'justify', margin: [0, 5] },
    parolaBold: { fontSize: 10, bold: true },
    testoDestra: {
        fontSize: 9,
        margin: [0, -100, 0, 7],
        alignment: 'right',
    },
    testoSinistra: {
        fontSize: 9,
        margin: [0, 0, 0, 7],
        alignment: 'left',
    },
}

const paragrafo = (testo) => {
    return { text: testo, style: 'paragrafo' }
}
const titolo = (testo) => {
    return { text: testo, style: 'titolo' }
}
const bold = (testo) => {
    return { text: testo, style: 'parolaBold' }
}
// funzioni privacy

const datiPaziente = (dati) => {
    return ['Il sottoscritto ', bold(dati.nome), ' ', bold(dati.cognome), ' nato a ', bold(dati.luogoNascita), ' il ', bold(dati.dataNascita), ' residente in ', bold(dati.indirizzo), ' ', bold(dati.cap), ' ', bold(dati.comune), ' ', bold(dati.provincia)]
}

const datiTutore = (tutore, dati) => {
    return ['Il sottoscritto ', bold(tutore.nomeTutore), ' ', bold(tutore.cognomeTutore), ' CF:', bold(tutore.cfTutore), ', tutore di ', bold(dati.nome), ' ', bold(dati.cognome), ' nato a ', bold(dati.luogoNascita), ' il ', bold(dati.dataNascita), ' residente in ', bold(dati.indirizzo), ' ', bold(dati.cap), ' ', bold(dati.comune), ' ', bold(dati.provincia)]
}

const premessa = () => {
    return [
        `In questo modulo vengono riassunti i concetti a Lei già oralmente espressi nel corso della visita, precisandoli e
defnendoli nelle loro linee essenziali, in modo da avere anche il Suo assenso all'esecuzione delle terapie prescritte e
concordate.`,
    ]
}

const descrizioneIntervento = (descrizione) => {
    return ["Descrizione dell'intervento ", bold(descrizione)]
}

const rischiAssoluti = (rischiAssoluti) => {
    return ['Rischi assoluti', 'Le terapie Fisiche Strumentali prevedono una Controindicazione Assoluta al trattamento per: ', bold(rischiAssoluti)]
}

const controindicazioni = () => {
    return [
        `In ragione di quanto precede ed al fne di evidenziare eventuali controindicazioni relative o assolute al trattamento
    fsioterapico, il paziente si obbliga ad informare preventivamente il professionista di qualsiasi stato morboso e/ o
    patologia acuta o cronica di cui è affetto, impegnandosi a rimettergli la documentazione comprovante le proprie
    condizioni di salute.`,
    ]
}

const rischiGenerici = (rischiGen) => {
    return ['I trattamenti fisioterapici sopra descritti possono determinare in alcuni casi e/o in alcuni pazienti i seguenti sintomi : ', bold(rischiGen)]
}

const rischiMancatoTrattamento = () => {
    return [
        `Rischi del mancato trattamento
    Il mancato trattamento può determinare un peggioramento della sintomatologia o della funzionalità lesa e/ o
    l'instaurarsi di patologie croniche.`,
    ]
}

const altreInformazioniSuRichiestaDelPaziente = (info) => {
    return ['Altre informazioni su richiesta del paziente, ', bold(info)]
}

const presaDiCoscienza = (paziente) => {
    return [
        'Io sottoscritto ',
        bold(paziente),
        ' dichiaro di essere stato informato e di aver compreso lo scopo e la natura ',
        "dell'intervento di riabilitazione indicato nel modulo in oggetto e di aver altresì consapevolezza dei rischi e delle ",
        'complicazioni che potrebbero derivare o essere connesse al predetto intervento. Sono inoltre consapevole che senza ',
        'la mia disponibilità nel seguire alcune regole comportamentali ed esercizi indicatemi dal fsioterapista, la terapia ',
        'non può ritenersi stabile a lungo termine e prendo atto che non vi possano essere garanzie di successo senza la mia ',
        "collaborazione. Presto pertanto il mio assenso all'intervento ed alle terapie che mi sono state illustrate e spiegate, ",
        'sottoponendomi al trattamento riabilitativo per il periodo di tempo necessario allo svolgimento del programma ',
        'terapeutico propostomi.',
    ]
}

const luogoEData = (luogo, data) => {
    return ['Luogo e data: ', bold(luogo), ' il ', bold(data)]
}

var externalDataRetrievedFromServer = [
    { name: 'Bartek', age: 34 },
    { name: 'John', age: 27 },
]

function buildTableBody(data, columns) {
    var body = []

    body.push(columns)

    data.forEach(function (row) {
        var dataRow = []

        columns.forEach(function (column) {
            dataRow.push(row[column].toString())
        })

        body.push(dataRow)
    })

    return body
}

function table(data, columns) {
    return {
        table: {
            headerRows: 1,
            body: buildTableBody(data, columns),
        },
    }
}

const pazienteOTutore = (data) => {
    if (data.isTutore) {
        return paragrafo(datiTutore(data.datiTutore, data.datiPaziente, data.presidio.nome))
    } else {
        return paragrafo(datiPaziente(data.datiPaziente, data.presidio.nome))
    }
}

const contentPrivacy = (data, firmaPaziente, firmaDottore) => {
    let paziente = data.datiPaziente
    return [
        titolo("Consenso informato all'esecuzione di trattamenti fisioterapici"),
        paragrafo(pazienteOTutore(data)),

        //paragrafo(datiPaziente(paziente.nomeCognome, paziente.luogoNascita, paziente.dataNascita, paziente.indirizzo, paziente.cap, paziente.comune, paziente.provincia)),
        paragrafo(premessa()),
        paragrafo(descrizioneIntervento(data.strategia.descrizioneIntervento)),
        paragrafo(rischiAssoluti(data.strategia.rischiAssoluti)),
        paragrafo(controindicazioni()),
        paragrafo(rischiGenerici(data.strategia.sintomi)),
        paragrafo(rischiMancatoTrattamento()),
        paragrafo(altreInformazioniSuRichiestaDelPaziente(data.strategia.testo)),
        paragrafo(presaDiCoscienza(`${paziente.nome} ${paziente.cognome}`)),
        paragrafo(luogoEData(data.presidio.comune, data.data)),

        [
            [
                {
                    text: 'Firma del Dottore',
                    style: 'testoSinistra',
                },
                {
                    image: firmaDottore,
                    width: 150,
                    alignment: 'left',
                },
            ],
            [
                {
                    text: 'Firma del paziente',
                    style: 'testoDestra',
                },
                {
                    image: firmaPaziente,
                    width: 150,
                    alignment: 'right',
                },
            ],
        ],

        // margin: [left, top, right, bottom]

        /*      paragrafo('Firma del paziente'),
            { image: firmaPaziente },
            paragrafo('Firma del dottore'),
            { image: firmaDottore }, */
    ]
}

export const makePdfConsensoInformato = (data, firmaPaziente, firmaDottore) => {
    var contenuto = {
        styles: styles,
        content: contentPrivacy(data, firmaPaziente, firmaDottore),
    }
    return new Promise((resolve, reject) => {
        pdfMake.createPdf(contenuto).getBlob((blob) => {
            resolve(blob)
        })
    })
}
