import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { InputText, OutputText } from './fieldText'
import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'

const modelCf = {
    id: 'cfProprietario',
    tipo: 'fieldText',
    associazione: 'cfProprietario',
    label: 'Codice fiscale proprietario:',
    value: '',
    details: {
        maiuscolo: true,
    },
}

const modelCodiceRegione = {
    id: 'codiceRegione',
    tipo: 'fieldText',
    associazione: 'codiceRegione',
    label: 'Codice regione :',
    value: '',
}

const modelCodiceAsl = {
    id: 'codiceAsl',
    tipo: 'fieldText',
    associazione: 'codiceAsl',
    label: 'Codice asl:',
    value: '',
}

const codiceModelSSa = {
    id: 'codiceSSA',
    tipo: 'fieldText',
    associazione: 'codiceSSA',
    label: 'Codice SSA:',
    value: '',
}

export function InputProprietarioUtenzaTs(props) {
    const getValoreTab = () => {
        return props.value.tipo === 'liberoProfessionista' ? 0 : 1
    }

    const valoreTabCambiato = (valore) => {
        props.azione(props.model.id, Object.assign(props.value, { tipo: valore === 0 ? 'liberoProfessionista' : 'struttura' }))
    }

    const renderCampi = () => {
        if (getValoreTab() === 0) {
            return (
                <Grid item xs={12} lg={12}>
                    <InputText model={modelCf} value={props.value.cfProprietario || ''} errore={props.errore} azione={(a, b) => aggiornaValore(a, b)} />
                </Grid>
            )
        }
        return (
            <React.Fragment>
                <Grid item xs={12} lg={12}>
                    <InputText model={modelCf} value={props.value.cfProprietario || ''} errore={props.value.cfProprietario === '' || props.value.cfProprietario === undefined ? props.errore : ''} azione={(a, b) => aggiornaValore(a, b)} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <InputText model={ modelCodiceRegione} value={props.value.codiceRegione || ''} errore={props.value.codiceRegione === '' || props.value.codiceRegione === undefined ? props.errore : ''} azione={(a, b) => aggiornaValore(a, b)} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <InputText model={ modelCodiceAsl} value={props.value.codiceAsl || ''} errore={props.value.codiceAsl === '' || props.value.codiceAsl === undefined ? props.errore : ''} azione={(a, b) => aggiornaValore(a, b)} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <InputText model={ codiceModelSSa} value={props.value.codiceSSA || ''} errore={props.value.codiceSSA === '' || props.value.codiceSSA === undefined ? props.errore : ''} azione={(a, b) => aggiornaValore(a, b)} />
                </Grid>
            </React.Fragment>
        )
    }

    const aggiornaValore = (campo, valore) => {
        if (getValoreTab() === 0) {
            props.azione(props.model.id, Object.assign({},{tipo : 'liberoProfessionista', [campo] : valore}))
        }
        props.azione(props.model.id, Object.assign(props.value, { [campo]: valore }))
    }

    return (
        <React.Fragment>
            <Tabs orientation='horizontal' value={getValoreTab()} onChange={(e, valore) => valoreTabCambiato(valore)}>
                <Tab label='Libero professionista' />
                <Tab label='struttura' />
            </Tabs>
            <MDBox mt={3}>
                <Grid container spacing={3}>
                    {renderCampi()}
                </Grid>
            </MDBox>
        </React.Fragment>
    )
}
export function OutputProprietarioUtenzaTs(props) {
    return <div>fieldProprietarioUtenzaTs</div>
}
