import React from 'react'
import FormOutput from 'formMaker/formOutput'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { cloneDeep } from 'lodash-es'
import { formDiagnosi } from 'formModel/formDiagnosi'

export default function elementoVediDiagnosi(props) {
    let form = cloneDeep(formDiagnosi())
    form.parts.splice(1, 1)

    let tag = props.value.tag.map((el) => {
        return el
    })
    if (props.value.tagLibero !== undefined && props.value.tagLibero !== null && props.value.tagLibero !== '') {
        tag.push(props.value.tagLibero)
    }
    let valore = Object.assign(props.value, { tag: tag })

    return (
        <React.Fragment>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h5'>
                    Diagnosi fisioterapica
                </MDTypography>
            </MDBox>
            <FormOutput form={form} dati={valore} />
        </React.Fragment>
    )
}
