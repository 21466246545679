import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import VediForm from 'formMaker/vediForm'
import { newId } from 'data/strumenti'
import { AGGIUNGI_DATA_TUTORIAL } from 'data/azioni/AGGIUNGI_DATA_TUTORIAL'

export default function popUpNuovaDataTutorial(props) {
    const handleClose = () => {
        props.chiudi()
    }

    const form = {
        parts: [
            {
                id: 'data',
                tipo: 'fieldData',
                associazione: 'data',
                label: 'Data :',
                value: '',
                validazione: 'testoRichiesto',
                details: { vediOra: true },
            },
            {
                id: 'link',
                tipo: 'fieldText',
                associazione: 'link',
                label: 'link :',
                value: '',
                validazione: 'testoRichiesto',
            },
        ],
    }

    const salvaRichiesta = async (e) => {
        const id = newId()
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await AGGIUNGI_DATA_TUTORIAL(id, e)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Data tutorial aggiunta' } })
        return
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>nuova data tutorial</DialogTitle>
            <DialogContent dividers={true}>
                <VediForm form={form} dati={{}} soloInput={true} vista='input' annulla={() => handleClose} salva={(e) => salvaRichiesta(e)} />
            </DialogContent>
        </Dialog>
    )
}
