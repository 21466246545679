import { trasformaOggettoInArray } from '../strumenti'

//inizioVersione
//fineVersione

const initialState = {
    versioneAttuale: window.versioneAttuale || '',
    versioni: {},
    isObsoleta: false,
}

export const reducerVersioni = (state = initialState, action) => {
    let nuovoStato = Object.assign({}, state)
    switch (action.type) {
        /* case 'LOG_OUT':
            return initialState */
        case 'AGGIORNA_VERSIONI':
            nuovoStato.isObsoleta = isVersioneObsoleta(state.versioneAttuale, action.payload)
            nuovoStato.versioni = action.payload
            return nuovoStato
        case 'AGGIORNA_VERSIONE_ATTUALE':
            nuovoStato.versioneAttuale = window.versioneAttuale
            nuovoStato.isObsoleta = isVersioneObsoleta(window.versioneAttuale, state.versioni)
            return nuovoStato
        default:
            return state
    }
}

let isVersioneObsoleta = (versioneAttuale, versioni) => {
    let array = trasformaOggettoInArray(versioni)
    array = array.sort((a, b) => {
        return b.contenuto.data.seconds - a.contenuto.data.seconds
    })
    if (array.length === 0) {
        return true
    }
    return versioneAttuale !== array[0].contenuto.versione
}
