import { getIdLesioneSelezionata } from '../../getter/getterLesioni'
import { getIdPaziente } from '../../getter/getterPaziente'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { newId, creaEtichettaUniversale, getPresidio } from './../strumenti'
import { APRI_POP_UP } from './APRI_POP_UP'
import { ref, uploadBytes } from 'firebase/storage'
import { doc, updateDoc, setDoc } from 'firebase/firestore'

export const SALVA_FILE = async (file, thumbNail, nome) => {
    
    const isPdf = file.type === 'application/pdf'

    let idPaziente = getIdPaziente()
    let idLesione = getIdLesioneSelezionata()
    if (idLesione === '') {
        idLesione = 'seduteNoLesione'
    }

    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

    const aggiungiACollecion = async (idImmagine, fileDaAggiungere) => {
        try {
            await updateDoc(doc(db, 'utenti', idPaziente, 'lesioni', idLesione), { [`documentazionePregressa.immagini.${idImmagine}`]: fileDaAggiungere })
            return
        } catch (error) {
            await setDoc(doc(db, 'utenti', idPaziente, 'lesioni', idLesione), { documentazionePregressa: { immagini: { [idImmagine]: fileDaAggiungere } }, seduteNoLesione: true, titolo: 'nessuna disfunzione', etichettaUniversale: creaEtichettaUniversale(getPresidio(getUtenteLoggato())) })
            return
        }
    }

    const aggiornaCollection = (nome) => {
        return new Promise((resolve, reject) => {
            updateDoc(doc(db, 'utenti', idPaziente, 'lesioni', idLesione), { [`documentazionePregressa.immagini.${idImmagine}.${nome}`]: true }).then(() => {
                resolve()
            })
        })
    }
    const aggiungiAStorage = (path, file, nome) => {
        return new Promise((resolve, reject) => {
            uploadBytes(ref(storage, path), file).then((snapshot) => {
                resolve()
            })
        })
    }
    let idImmagine = newId()
    /* let etichetta = {
            [`documentazionePregressa.immagini.${idImmagine}.etichettaUniversale`]: creaEtichettaUniversale(getPresidio(getUtenteLoggato())),
        } */

    const getPath = () => {
        if (isPdf) {
            return `immagini/${idImmagine}/${file.name}`
        }
        return `immagini/${idImmagine}/originale.${file.name.split('.')[file.name.split('.').length - 1]}`
    }

    let path = getPath()
    let path2 = `immagini/${idImmagine}/thumbNail.png`
    let fileDaAggiungere = { nome: file.name, originale: false, thumbNail: false, nomeFile: nome, tipo: isPdf ? 'pdf' : 'image' }

    const getArrayPromesse = () => {
        if (isPdf) {
            return [aggiungiACollecion(idImmagine, fileDaAggiungere), aggiungiAStorage(path, file, 'originale')]
        }
        return [aggiungiACollecion(idImmagine, fileDaAggiungere), aggiungiAStorage(path2, thumbNail, 'thumbNail'), aggiungiAStorage(path, file, 'originale')]
    }

    const etichetta = creaEtichettaUniversale(getPresidio(getUtenteLoggato()))

    let arrayPromesse = getArrayPromesse()
    await Promise.all(arrayPromesse)
    await CHIAMA_SERVER(`inserisciEtichettaLesioni`, { idPaziente: idPaziente, idLesione: idLesione, etichetta: etichetta, idImmagine: idImmagine })
    APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'File caricato' } })
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return 'finito'
}
