import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import React, { useState, useEffect } from 'react'
import SignaturePad from 'signature_pad'

export default function elementoFirma(props) {
    const [pad, setPad] = useState({})

    const aggiornaFirma = (nuovoPad) => {
        props.aggiorna({ chiFirma: props.chiFirma, firma: nuovoPad.toDataURL(), isEmpty: false })
    }

    const creaPad = () => {
        let nuovoPad = new SignaturePad(document.getElementById(props.chiFirma), {
            onEnd: () => aggiornaFirma(nuovoPad),
        })
        document.getElementById(props.chiFirma).addEventListener('handleMouseUp', (e) => {})

        setPad(nuovoPad)
    }

    const svuota = () => {
        pad.clear()
        props.aggiorna({ chiFirma: props.chiFirma, firma: pad.toDataURL(), isEmpty: true })
    }

    useEffect(() => {
        creaPad()
    }, [])


    return (
        <React.Fragment>
            <MDTypography variant='string' color='text'>
                {props.chiFirma}
            </MDTypography>
            <div className='canvas-cont centrato'>
                <canvas id={props.chiFirma} width='300px' height='170px'></canvas>
            </div>
            <MDButton variant='text' color='primary' size='small' onClick={() => svuota()}>
                cancella
            </MDButton>
        </React.Fragment>
    )
}
