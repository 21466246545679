import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { creaEtichettaUniversale, newId } from '../strumenti'

export const CREA_AMMINISTRAZIONE_CLINICA = (form) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    return new Promise((resolve, reject) => {
        let credenziali = {
            email: form.email,
            password: form.password,
        }

        var datiAmministrazione = {
            etichettaUniversale: creaEtichettaUniversale(),
            profilo: 'profilo-amministrazione-clinica',
            profiloOriginale: 'profilo-amministrazione-clinica',
            isPaziente: false,
            nome: form.nome,
            indirizzo: form.indirizzo,
            partiaIva: form.partiaIva,
            email: form.email,
        }
        let idUtente = newId()
        let datiCache = { [form.partiaIva]: { idUtente: idUtente } }
        let daPassare = {
            idUtente: idUtente,
            datiUtente: datiAmministrazione,
            cache: datiCache,
            credenziali: credenziali,
        }

        CHIAMA_SERVER(`creaAmministrazioneClinica`, daPassare).then(() => {
            
            //var callSendMail = firebase.functions().httpsCallable('callSendMailCredenzialiProfiloAmministrazioneClinica')
            /* CHIAMA_SERVER('inviaMail', { id: idUtente, tipo: 'mailBenvenuto' }) */
            //callSendMail({ id: idUtente })
            resolve()
        })
    })
}
