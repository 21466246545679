import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import { InputSelect } from 'fields/fieldSelect'
import { connect } from 'react-redux'
import { getLesioniMenu } from 'getter/getterLesioni'
import { CREA_SEDUTA } from 'data/azioni/CREA_SEDUTA'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { getIdPaziente } from 'getter/getterPaziente'
import MDTypography from 'components/MDTypography'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

export function popUpNuovaSeduta(props) {
    const [lesione, setLesione] = useState('')
    const [errore, setErrore] = useState('')

    useEffect(() => {
        setLesione('seduteNoLesione')
        setErrore('')
    }, [props.aperto])

    const salva = () => {
        setErrore('')
        if (lesione === '') {
            setErrore('Seleziona una disfunzione')
            return
        }
        creaSeduta(lesione)
    }

    const creaSeduta = async (idLesione) => {
        let idSeduta = await CREA_SEDUTA(idLesione)
        handleClose()
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Nuova seduta creata' } })
        props.parametro.navigate(`paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${idLesione}/paginaVediSeduta/${idSeduta}`)
    }

    const handleClose = () => {
        props.chiudi()
    }

    const renderMessaggioErrore = () => {
        return (
            <MDTypography color='text'>
                Per creare una seduta deve esserci almeno una disfunzione.{' '}
                <MDTypography onClick={() => handleClose()} component={Link} to={`/paginaVediPaziente/${getIdPaziente()}/disfunzioniPaziente`} color='primary' fontWeight='medium'>
                    Vai a pagina disfunzioni
                </MDTypography>
            </MDTypography>
        )
    }

    const renderBottoniAzioni = () => {
        if (props.model.details.scelte.length < 2) {
            return (
                <React.Fragment>
                    <MDButton color='error' variant='text' onClick={() => handleClose()}>
                        annulla
                    </MDButton>

                    {/* <NavLink to={`/paginaVediPaziente/${getIdPaziente()}/disfunzioniPaziente`} sx={{ textDecoration: 'none' }}>
                        <MDButton variant='text' color='primary' onClick={() => handleClose(true)}>
                            crea disfunzione
                        </MDButton>
                    </NavLink> */}

                    <MDButton color='primary' variant='text' onClick={() => creaSeduta('seduteNoLesione')}>
                        continua senza disfunzione
                    </MDButton>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <MDButton color='error' variant='text' onClick={() => handleClose()}>
                    annulla
                </MDButton>

                <MDButton color='success' variant='text' onClick={() => salva()}>
                    salva
                </MDButton>
            </React.Fragment>
        )
    }

    const renderContenuto = () => {
        if (props.model.details.scelte.length < 2) {
            return (
                <MDTypography color='text'>
                    Nessuna disfunzione creata.{' '}
                    <MDTypography onClick={() => handleClose()} component={Link} to={`/paginaVediPaziente/${getIdPaziente()}/disfunzioniPaziente`} color='primary' fontWeight='medium'>
                        Vai a pagina disfunzioni
                    </MDTypography>
                </MDTypography>
            )
        }
        return <InputSelect model={props.model} value={lesione} errore={errore} azione={(a, b) => setLesione(b)} />
    }

    return (
        <Dialog open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Nuova seduta</DialogTitle>
            <DialogContent dividers={true}>{renderContenuto()}</DialogContent>
            <DialogActions>{renderBottoniAzioni()}</DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    let lesioni = [...getLesioniMenu()]
    lesioni.unshift({ nome: 'Nessuna disfunzione', id: 'seduteNoLesione' })

    const model = {
        id: 'lesione',
        tipo: 'fieldSelect',
        associazione: 'lesione',
        label: 'Associa disfunzione',
        value: '',
        details: {
            scelte: lesioni,
            complex: true,
            toShow: 'nome',
            toSave: 'id',
        },
    }
    return { model }
}

export default connect(mapStateToProps)(popUpNuovaSeduta)
