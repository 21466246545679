import { doc, deleteDoc } from 'firebase/firestore'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const ELIMINA_APPUNTAMENTO = async (id) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    await CHIAMA_SERVER('cancellaAppuntamento', { id: id })
    //await deleteDoc(doc(db, 'appuntamenti', id))
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
