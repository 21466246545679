import { SALVA_FILE } from 'data/azioni/SALVA_FILE'
import MDBox from 'components/MDBox'
import React, { Component } from 'react'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import MDButton from 'components/MDButton'
import DocumentScannerRoundedIcon from '@mui/icons-material/DocumentScannerRounded'

export default class elementoAggiungiFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            altezza: 200,
            larghezza: 200,
        }
    }

    renderBottone() {
        if (this.props.isScanFattura && this.props.isPopUp) {
            return (
                <MDButton variant='text' color='warning' onClick={() => this.carica()}>
                    si
                </MDButton>
            )
        }
        if (this.props.isScanFattura) {
            return (
                <MDButton size='small' variant='text' color='primary' onClick={() => this.carica()}>
                    <DocumentScannerRoundedIcon sx={{ mr: 1 }} />
                    scannerizza fattura
                </MDButton>
            )
        }
        if (this.props.isFileDisfunzione && this.props.isDashboard) {
            return (
                <MDBox className='contenitore-bottoni-destra-verticale'>
                    <MDButton variant='contained' color='primary' onClick={() => this.carica()}>
                        nuovo file
                    </MDButton>
                </MDBox>
            )
        }
        if (this.props.isFileDisfunzione && !this.props.isDashboard) {
            return (
                <Fab sx={{ position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => this.carica()}>
                    <AddIcon />
                </Fab>
            )
        }
        if (this.props.isEsercizio) {
            return (
                <MDButton size='small' variant='text' color='primary' onClick={() => this.carica()}>
                    aggiungi
                </MDButton>
            )
        }

        return ''
    }

    getZIndex() {
        //return this.props.isFileDisfunzione ? 0 : 2000
        return this.props.isFileDisfunzione || this.props.isScanFattura ? 0 : 2000
    }

    getIdInput() {
        if (this.props.index === undefined) {
            return 'file-input'
        }
        return `file-input-${this.props.index}`
    }

    getFileAccettati(){
        if (this.props.isFileDisfunzione) {
            return "application/pdf, image/*"
        }
        return "image/*"
    }

    render() {
        return (
            <React.Fragment>
                <MDBox sx={{ cursor: 'pointer', zIndex: this.getZIndex() }}>{this.renderBottone()}</MDBox>
                <input type='file' accept={this.getFileAccettati()} name={this.getIdInput()} id={this.getIdInput()} style={{ display: 'none' }} onChange={() => this.nuovoFile()} />
                <canvas id='canvassino' width={this.state.larghezza} height={this.state.altezza} style={{ display: 'none' }}></canvas>
            </React.Fragment>
        )
    }

    carica(e) {
        console.log('guardaaaaa : ', this.getIdInput())
        document.getElementById(this.getIdInput()).click()
    }

    async cosaFare(thumbNail, originale) {
        if (this.props.azione !== undefined) {
            return this.props.azione(thumbNail.blob, originale)
        }
        if (this.props.isFileDisfunzione) {
            await SALVA_FILE(originale, thumbNail.blob, '')
            return
        }
        if (this.props.isEsercizio) {
            this.props.salva(originale, thumbNail.dataUrl)
            //await SALVA_FILE_ESERCIZIO(originale)
            return
        }
        return
    }

    async nuovoFile() {
        let fileInput = document.getElementById(this.getIdInput()).files[0]

        console.log('Guarda file : ', fileInput)

        if (fileInput.type === "application/pdf") {
            return await this.cosaFare("", fileInput)
        }
        

        const dataUrl = await this.leggiFile(fileInput)

        const image = await this.ottieniImmagine(dataUrl)

        const thumbNail = await this.caricaCanvas(image)

        console.log(thumbNail)
        document.getElementById(this.getIdInput()).value = ''
        await this.cosaFare(thumbNail, fileInput)
        //this.dispatchEvent(new CustomEvent('immaginecaricata', { detail: { thumbNail: thumbNail, originale: fileInput } }))
    }

    leggiFile(fileInput) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            // Cosa deve fare il reader al moment del load, va definito prima per richiamarlo dopo
            reader.onload = (e) => {
                // ritorno il file base32
                resolve(e.target.result)
            }
            // Interpretiamo il file come data URL cioÃ¨ in base64
            reader.readAsDataURL(fileInput)
        })
    }

    ottieniImmagine(dataUrl) {
        return new Promise((resolve, reject) => {
            // Si crea un'immagine
            let image = new Image()
            // si risolve con l'immagine
            image.onload = () => {
                resolve(image)
            }
            // Il source dell'immagine e' uguale al link (base64/URL)
            image.src = dataUrl
        })
    }

    caricaCanvas(image) {
        return new Promise((resolve, reject) => {
            let canvas = document.getElementById('canvassino')
            // Le dimensioni del'immagine variano in base all'input
            let misure = this.computeSize(image.height, image.width, this.state.altezza, this.state.larghezza)
            // Il canvas si dimensiona in base all'immagine

            canvas.width = misure.width
            canvas.height = misure.height
            let ctx = canvas.getContext('2d')
            // Disegniamo l'immagine
            ctx.drawImage(image, 0, 0, misure.width, misure.height)
            // Richiamiamo download per ottenere il link e rimpire il parametro href per il downlaod
            canvas.toBlob((blob) => {
                const dataUrl = canvas.toDataURL()
                let myBlob = blob
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                resolve({ blob, myBlob, dataUrl: dataUrl })
            })
        })
    }

    computeSize(height, width, maxHeight, maxWidth) {
        var defaultRatio = maxHeight / maxWidth
        var imageRatio = height / width
        if (defaultRatio <= imageRatio) {
            var conversionRatio = maxHeight / height
        } else {
            var conversionRatio = maxWidth / width
        }
        var result = {
            height: height * conversionRatio,
            width: width * conversionRatio,
        }
        return result
    }
}
