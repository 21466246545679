import './firebaseConfig'
import { connect } from 'react-redux'
import React, { Component, Suspense } from 'react'
/* import LayoutApp from './layoutApp' */
import './App.css'
import { userWatcher } from './data/watchers/userWatcher'
import { watcherVersioni } from './data/watchers/watcherVersioni'
import { watcherDateTutorial } from './data/watchers/watcherDateTutorial'
import { inizializzatore } from 'inizializzatori/inizializzatore'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import themeRTL from 'assets/theme/theme-rtl'
import ElementoSpinner from './componenti/elementoSpinner'
import { getVeroUtenteLoggato } from './getter/getterUtenteLoggato'
import { isEqual } from 'lodash-es'
import ElementoCaricamento from 'componenti/elementoCaricamento'
import RoutingLanding from 'landing/routingLanding'
import ElementoMostraPopUp from 'componenti/elementoMostraPopUp'
import { newId } from 'data/strumenti'
import LayoutApp from './layoutApp'



class App extends Component {
    componentDidMount() {
        

        new userWatcher()
        new watcherVersioni()
        new watcherDateTutorial()
        this.inizializzatore = new inizializzatore()
    }

    componentDidUpdate(vecchieProps) {
        if (this.props.logout) {
            this.inizializzatore.logOut()
        }
        if (!isEqual(vecchieProps.utente, this.props.utente)) {
            this.inizializzatore.aggiorna(this.props.utente)
        }
    }

    cosaMostrare() {
        switch (this.props.stato) {
            case 1:
                return <ElementoCaricamento />
            case 2:
                return (
                    <ThemeProvider theme={themeRTL}>
                        <CssBaseline />
                        <ElementoMostraPopUp />
                        <ElementoSpinner />
                        <RoutingLanding />
                    </ThemeProvider>
                )

            case 3:
                return <ElementoCaricamento />
            case 4:
            
                return (
                    
                        <LayoutApp />
                   
                )
            case 5:
                const ElementoUtenteNonAbilitato = React.lazy(() => import('componenti/elementoUtenteNonAbilitato'))
                return (
                    <Suspense fallback={<ElementoCaricamento />}>
                        <ElementoUtenteNonAbilitato />
                    </Suspense>
                )
        }
    }

    render() {
        return this.cosaMostrare()
    }
}

function stateChanged(state) {
    let utente = {}
    if (state.statoUtente.stato === 4) {
        utente = getVeroUtenteLoggato()
    }

    return {
        stato: state.statoUtente.stato,
        utente: utente,
        logout: state.storico[0] === 'LOG_OUT',
    }
}

export default connect(stateChanged, null)(App)
