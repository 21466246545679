import React, { Component } from 'react'
import { connect } from 'react-redux'
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart'
import { eventiCache } from 'mixing/eventiCache'
import { Divider, Grid } from '@mui/material'
import { filtraFatture } from 'getter/getterFatture'
import ElementoMostraFattura from 'componenti/elementoMostraFattura'
import MDBox from 'components/MDBox'
import ElementoFiltraFatture from 'componenti/elementoFiltraFatture'
import MDTypography from 'components/MDTypography'
import Fab from '@mui/material/Fab'
import TuneIcon from '@mui/icons-material/Tune'
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { OTTIENI_ZIP_FATTURE } from 'data/azioni/OTTIENI_ZIP_FATTURE'

class paginaFatturePresidio extends eventiCache(Component) {
    constructor(props) {
        super(props)
        this.state.datiGrafico = {}
        this.state.fatture = []
        this.state.errore = false
    }

    cacheFattureAggiornate() {
        this.getDatiGrafico()
    }

    getDatiGrafico() {
        let mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']

        let valori = []
        
        
        if (this.state.cacheFatureWindow[this.props.anno] !== undefined) {
            valori = mesi.map((mese, index) => {
                let datiCache = this.state.cacheFatureWindow[this.props.anno][index + 1]
                return datiCache !== undefined ? datiCache.totale : 0
            })
        }
        this.setState({
            datiGrafico: {
                labels: mesi,
                datasets: [
                    {
                        label: 'Totale fatture',
                        color: 'dark',
                        data: valori,
                    },
                ],
            },
        })
    }

    componentDidMount() {
        super.componentDidMount()
        this.getDatiGrafico()
        this.getFatture()
    }

    componentDidUpdate(vecchieProps) {
        if (vecchieProps.anno !== this.props.anno) {
            this.getDatiGrafico()
        }
    }

    fattureAggiornate() {
        this.getFatture()
    }

    getFatture() {
        let ricerca = filtraFatture()
        let fatture = ricerca.lista
        fatture = fatture.sort((a, b) => {
            let numeroA = Number(a.contenuto.numFattura.split('-')[0])
            let numeroB = Number(b.contenuto.numFattura.split('-')[0])
            return numeroB - numeroA
        })
        let errore = ricerca.errore
        this.setState({ fatture: fatture, errore: errore })
    }

    renderFatture() {
        if (this.state.errore) {
            return (
                <MDBox shadow='lg' borderRadius='lg' bgColor='white' ml='auto' width='100%' p={3} mt={3} display='flex' justifyContent='center' alignItems='center'>
                    <MDTypography variant='h4' color='error'>
                        Nessun risultato
                    </MDTypography>
                </MDBox>
            )
        }
        return (
            <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto' mt={3}>
                {this.state.fatture.map((fattura, index) => {
                    return (
                        <Grid key={fattura.id} item xs={12} lg={12}>
                            <React.Fragment>
                                <Grid key={fattura.id} item>
                                    <ElementoMostraFattura fattura={fattura} />
                                </Grid>
                                {index !== this.state.fatture.length - 1 ? <Divider /> : ''}
                            </React.Fragment>
                        </Grid>
                    )
                })}
            </MDBox>
        )
    }

    async scaricaZip(){
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await OTTIENI_ZIP_FATTURE()
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        return
    }

    renderFab() {
        const display = this.state.errore ? 'none' : 'flex'
        return (
            <MDBox sx={{ zIndex: 100, position: 'fixed', bottom: 16, left: '50%', transform: 'translateX(-50%)' }} display='grid' gap='10px' gridAutoRows='max=content'>
                <Fab onClick={() => APRI_POP_UP({ id: 'filtra-fatture', parametro: '' })} sx={{ display: { xs: 'flex', md: 'none' } }} variant='extended' color='primary'>
                    <TuneIcon sx={{ mr: 1 }} />
                    Filtra
                </Fab>
                <Fab onClick={() => this.scaricaZip()} sx={{ display: display }} variant='extended' color='primary'>
                    <FolderZipIcon sx={{ mr: 1 }} />
                    scarica fatture
                </Fab>
            </MDBox>
        )
    }

    render() {

        
        

        return (
            <Grid container spacing={3}>
                {this.renderFab()}
                <Grid item container xs={12} lg={12} spacing={3}>
                    <Grid item xs={12} md={7} lg={9}>
                        <VerticalBarChart title='Fatturazione' description='I valori fanno riferimento solo alle fatture emesse.' chart={this.state.datiGrafico} />
                    </Grid>
                    <Grid item md={5} lg={3} display={{ xs: 'none', md: 'grid' }}>
                        <MDBox bgColor='white' height='100%' width='100' shadow='lg' borderRadius='lg' p={2}>
                            <ElementoFiltraFatture />
                        </MDBox>
                    </Grid>
                </Grid>
                <Grid item container xs={12} lg={12} spacing={3}>
                    {this.renderFatture()}
                </Grid>
            </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        anno: Number(state.reducerFiltriFatture?.anno) || `${new Date().getFullYear()}`,
    }
}

export default connect(mapStateToProps, null)(paginaFatturePresidio)
