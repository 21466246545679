const initialState = {
    errore: false,
    verificato: false,
    id: '',
}

export const reducerControlloAifi = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_CONTROLLO_AIFI':
            let nuovoStato = {
                verificato: action.payload.valido,
                errore: !action.payload.valido,
                id: action.payload.id,
            }
            return nuovoStato
        /* case "CAMBIA_URL":
           break */
        default:
            return state
    }
}
