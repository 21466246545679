export const formUtenzaTs = () => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'proprietario',
                tipo: 'fieldProprietarioUtenzaTs',
                associazione: 'proprietario',
                label: 'Proprietario:',
                value: { tipo: 'liberoProfessionista' },
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                },
                validazione: 'validazioneProprietarioUtenzaTS',
            },
            {
                id: 'pIva',
                tipo: 'fieldText',
                associazione: 'pIva',
                label: 'Partita iva:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'pinCode',
                tipo: 'fieldText',
                associazione: 'pinCode',
                label: 'pincode:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'username',
                tipo: 'fieldText',
                associazione: 'username',
                label: 'Username:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'password',
                tipo: 'fieldText',
                associazione: 'password',
                label: 'Password:',
                value: '',
                details: {
                    type: 'password',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'nome',
                tipo: 'fieldText',
                associazione: 'nome',
                label: 'Nome:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
        ],
    }
}
