const subInputAlcol = {
    costumCssInput: ['sinistraPiccolo', 'spostaSopra'],
    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Alcol:',
            value: '',
            details: {
                grid: { lg: { input: 1, output: 1 } },
                scelte: ['si', 'no'],
                complex: false,
                costumCssOutput: ['subInputNoLabel'],
                costumCss: ['paddinSopra'],
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Quanto:',
            value: '',
            details: {
                grid: { lg: { input: 3, output: 3 } },
                type: 'text',
                costumCssOutput: ['subInputNoLabel'],
            },
        },
    ],
}

const subInputFumo = {
    costumCssInput: ['sinistraPiccolo', 'spostaSopra'],
    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Fumo:',
            value: '',
            details: {
                grid: { lg: { input: 1, output: 1 } },
                scelte: ['si', 'no'],
                complex: false,
                costumCss: ['paddinSopra'],
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Descrizione:',
            value: '',
            details: {
                grid: { lg: { input: 3, output: 3 } },
                type: 'text',
                costumCssOutput: ['subInputNoLabel'],
            },
        },
    ],
}

const subInputFamigliaFigli = {
    costumCssInput: ['sinistraPiccolo', 'spostaSopra'],
    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Famiglia:',
            value: '',
            details: {
                grid: { lg: { input: 1, output: 1 } },
                scelte: ['si', 'no'],
                complex: false,
                costumCss: ['paddinSopra'],
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Numero Figli:',
            value: '',
            details: {
                grid: { lg: { input: 3, output: 3 } },
                type: 'text',
                costumCssOutput: ['subInputNoLabel'],
            },
        },
    ],
}

const subInputAssicurazioneTipo = {
    costumCssInput: ['sinistraPiccolo', 'spostaSopra'],
    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Assicurazione:',
            value: '',
            details: {
                grid: { lg: { input: 1, output: 1 } },
                scelte: ['si', 'no'],
                complex: false,
                costumCss: ['paddinSopra'],
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Compagnia Assicurativa:',
            value: '',
            details: {
                grid: { lg: { input: 3, output: 3 } },
                type: 'text',
                costumCssOutput: ['subInputNoLabel'],
            },
        },
    ],
}

export const formAbitudiniPaziente = () => {
    return {
        costumCssOutput: ['griglia'],
        parts: [
            {
                id: 'alimentazione',
                tipo: 'fieldTextArea',
                associazione: 'alimentazione',
                label: 'Alimentazione:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'sedentarieta',
                tipo: 'fieldTextArea',
                associazione: 'sedentarieta',
                label: 'Livello di Sedentarieta:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'sport',
                tipo: 'fieldListaSemplice',
                associazione: 'sport',
                label: 'Sport:',
                value: [],
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'hobbies',
                tipo: 'fieldListaSemplice',
                associazione: 'hobbies',
                label: 'Hobby:',
                value: [],
                details: {
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                },
            },
            {
                id: 'vitaSociale',
                tipo: 'fieldTextArea',
                associazione: 'vitaSociale',
                label: 'Vita sociale:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'stileVita',
                tipo: 'fieldTextArea',
                associazione: 'stileVita',
                label: 'Stile di vita:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'lavoro',
                tipo: 'fieldTextArea',
                associazione: 'lavoro',
                label: 'Tipo di Lavoro:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'alcol',
                tipo: 'fieldSubInput',
                associazione: 'alcol',
                label: 'Alcol:',
                details: {
                    subModel: subInputAlcol,
                    costumCssOutput: ['labelBold'],
                },
                value: {},
            },
            {
                id: 'fumo',
                tipo: 'fieldSubInput',
                associazione: 'fumo',
                label: 'Fumo:',
                details: {
                    subModel: subInputFumo,
                    costumCssOutput: ['labelBold'],
                },
                value: {},
            },
            {
                id: 'famigliaFigli',
                tipo: 'fieldSubInput',
                associazione: 'famigliaFigli',
                label: 'Famiglia:',
                details: {
                    subModel: subInputFamigliaFigli,
                    costumCssOutput: ['labelBold'],
                },
                value: {},
            },
            {
                id: 'assicurazioneTipo',
                tipo: 'fieldSubInput',
                associazione: 'assicurazioneTipo',
                label: 'Assicurazione:',
                details: {
                    subModel: subInputAssicurazioneTipo,
                    costumCssOutput: ['labelBold'],
                },
                value: {},
            },
        ],
    }
}
