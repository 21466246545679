import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { getPresidio } from './../strumenti'
import { doc, onSnapshot } from 'firebase/firestore'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'

export class watcherCercaTags extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione(nuovoId) {
        var listaTags = []

        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', getPresidio(getUtenteLoggato()), 'cache', 'tags'), (querySnapshot) => {
            listaTags = querySnapshot.data()
            if (listaTags !== undefined) {
                window.listaTags = listaTags.tags
            }
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'tags' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
