import AccessibilityRoundedIcon from '@mui/icons-material/AccessibilityRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded';

 const getTipologia = () => {
    const misurazioni = {
        titolo: 'Misurazioni',
        testo: 'Prendi le misure del tuo corpo e innviale al tuo nutrizionista',
        form: { parts: [] },
        icona: <AccessibilityRoundedIcon />,
    }
    const dieta = {
        titolo: 'Dieta',
        testo: 'Fai sapere al tuo butrizionista cosa hai mangiato',
        form: { parts: [] },
        icona: <RestaurantRoundedIcon />,
    }
    const altro = {
        titolo: 'Altro',
        testo: 'Fai sapere al tuo butrizionista cosa hai mangiato',
        form: { parts: [] },
        icona: <RestaurantRoundedIcon />,
    }

    return [misurazioni, dieta, altro]
}

export const listaTipologieFormDaCompilare = getTipologia()