import React from 'react'
import { formContatti } from 'formModel/formContatti'
import MDBox from 'components/MDBox'
import VediForm from 'formMaker/vediForm'
import MDTypography from 'components/MDTypography'
import { INVIA_FEEDBACK } from 'data/azioni/INVIA_FEEDBACK'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export default function paginaFeedback() {
    const salvaRichiesta = async (e) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await INVIA_FEEDBACK(e)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Messaggio inviato' } })
        return
    }

    return (
        <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h5'>
                    Contattaci
                </MDTypography>
            </MDBox>
            <VediForm form={formContatti()} dati={{}} soloInput={true} vista='input' annulla={() => window.history.back()} reset={true} salva={(e) => salvaRichiesta(e)} />
        </MDBox>
    )
}
