const initialState = {
    nome: '',
    cognome: '',
    anno: '',
}

export const reducerFiltriCercaPaziente = (state = initialState, action) => {
    let nuovoStato = Object.assign({}, state)
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'SVUOTA_FILTRI_CERCA_PAZIENTE':
            return {
                nome: '',
                cognome: '',
                anno: '',
            }
        case 'AGGIORNA_FILTRI_CERCA_PAZIENTE':
            nuovoStato[action.payload.campo] = action.payload.valore
            return nuovoStato
        case 'CAMBIA_URL':
            return {
                nome: '',
                cognome: '',
                anno: '',
            }
        default:
            return state
    }
}
