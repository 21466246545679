import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'

export default function elementoMostraAmministrazione(props) {

    const test = () => {
        props.navigate(`/paginaInfoAbbonamentiClinica/${props.amministrazione.contenuto.etichettaUniversale.presidio}`)
    }

    const getNome = () => {
        if(!props.presidio){
            return 'Presidio non creato'
        }
        return props.presidio.contenuto.nome
    }

    const getMail = () => {
        //return props.amministrazione.contenuto.etichettaUniversale.user
        if(!props.presidio){
            return props.amministrazione.contenuto.email
        }
        return props.presidio.contenuto.email
    }

    return (
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
            <ListItemIcon>
                <AccountCircleRoundedIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={getNome()} secondary={getMail()} />
            <ListItemIcon>
                <ArrowForwardIosRoundedIcon color='dark' />
            </ListItemIcon>
        </ListItem>
    )
}
