import { Divider, List } from '@mui/material'
import { eventiCache } from 'mixing/eventiCache'
import React, { Component } from 'react'
import ElementoMostraAmministrazione from 'componenti/elementoMostraAmministrazione'
import MDBox from 'components/MDBox'
import { getListaAmministrazioni } from 'getter/getterPresidio'

export default class paginaPresidiSuperAmministratore extends eventiCache(Component) {

    constructor(props){
        super(props)
        this.state.listaOrdinata = []
    }

    amministazioniAggiornati() {
        this.aggiorna()
    }
    componentDidMount() {
        super.componentDidMount()
        this.aggiorna()
    }

    aggiorna(){
        this.setState({
            listaOrdinata : getListaAmministrazioni()
        })
    }

    render() {
        return (
            <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                <List>
                    {this.state.listaOrdinata.map((amministrazione, index) => {
                        const indexPresidio = this.state.listaPresidiWindow.findIndex((el) => {
                            return el.id === amministrazione.contenuto?.etichettaUniversale?.presidio
                        })
                        let presidio = false
                        if (indexPresidio !== -1) {
                            presidio = this.state.listaPresidiWindow[indexPresidio]
                        }
                        return (
                            <React.Fragment key={index}>
                                <ElementoMostraAmministrazione presidio={presidio} amministrazione={amministrazione} navigate={this.props.navigate} />
                                {index < this.state.listaOrdinata.length - 1 ? <Divider /> : ''}
                            </React.Fragment>
                        )
                    })}
                </List>
            </MDBox>
        )
    }
}
