import { getIdPaziente } from '../../getter/getterPaziente'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { getPresidio } from '../strumenti'
import { writeBatch, doc, deleteField } from 'firebase/firestore'

export const ELIMINA_ASSEGNAZIONE_ESERCIZIO = (esercizio) => {
    return new Promise((resolve, reject) => {
        const batch = writeBatch(db)
        var esercizioCollection = doc(db, 'esercizi', esercizio.idEsercizio)
        let idPaziente = getIdPaziente()
        var paziente = doc(db, 'utenti', idPaziente)

        batch.update(esercizioCollection, { [`utilizzi.${esercizio.idAssegnazione}`]: deleteField() })
        batch.update(paziente, { [`esercizi.${esercizio.idAssegnazione}`]: deleteField() })

        batch.commit().then(() => {
            console.log('dovrebbe andare')
            CHIAMA_SERVER(`cancellaAssegnazioneEsercizio`, { presidio: getPresidio(getUtenteLoggato()), idEsercizio: esercizio.idEsercizio, idAssegnazione: esercizio.idAssegnazione })
            resolve()
        })
    })
}
