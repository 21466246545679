import { getFIles } from 'getter/getterLesioni'
import React from 'react'
import { connect } from 'react-redux'
import ElementoVediListaFiles from 'componenti/elementoVediListaFiles'
import { Divider, Grid, List } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import ElementoMostraConsensoInformato from 'componenti/elementoMostraConsensoInformato'
import { getSkeletonLesioni } from 'getter/getterLesioni'
import { getPermessiUtente } from 'getter/getterPermessi'
import ElementoAggiungiFile from 'componenti/elementoAggiungiFile'

export const paginaFilesPaziente = (props) => {
    const renderFab = () => {
        if (!props.permessi) {
            return ''
        }
        return <ElementoAggiungiFile isFileDisfunzione={true} />
    }

    if (props.skeleton) {
        return (
            <MDBox sx={{ margin: 'auto', maxWidth: '1000px' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                <MDTypography color='info'>Caricamento in corso...</MDTypography>
            </MDBox>
        )
    }

    return (
        <Grid container direction='column' spacing={3}>
            {renderFab()}
            <Grid item>
                <MDBox sx={{ maxWidth: '1000px', mx: 'auto' }} variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' mt={4}>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={2} mr={'auto'}>
                        <MDTypography color={'white'} variant='h5'>
                            Consensi informati
                        </MDTypography>
                    </MDBox>
                    <List>
                        {props.documenti.map((documento, index) => {
                            return (
                                <React.Fragment key={documento.id}>
                                    <ElementoMostraConsensoInformato documento={documento} />
                                    {index !== props.documenti.length - 1 ? <Divider /> : ''}
                                </React.Fragment>
                            )
                        })}
                    </List>
                </MDBox>
            </Grid>
            <ElementoVediListaFiles immagini={props.immagini} vediNomeDisfunzione={true} />
        </Grid>
    )
}

function stateChanged(state) {
    const dati = getFIles()
    return {
        immagini: dati.listaFiles,
        documenti: dati.documenti,
        permessi: getPermessiUtente().lesioniPaziente.input,
        skeleton: getSkeletonLesioni(),
    }
}

export default connect(stateChanged, null)(paginaFilesPaziente)
