export const formPrivacyDatiTutore = () => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'nomeTutore',
                tipo: 'fieldText',
                associazione: 'nomeTutore',
                label: 'Nome tutore:',
                value: '',
                details: {
                    grid: { lg: { input: 4 } },
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'cognomeTutore',
                tipo: 'fieldText',
                associazione: 'cognomeTutore',
                label: 'Cognome tutore:',
                value: '',
                details: {
                    grid: { lg: { input: 4 } },
                },

                validazione: 'testoRichiesto',
            },
            {
                id: 'cfTutore',
                tipo: 'fieldText',
                associazione: 'cfTutore',
                label: 'Codice fiscale tutore:',
                value: '',
                details: {
                    grid: { lg: { input: 4 } },
                    maiuscolo: true,
                },

                validazione: 'testoRichiesto',
            },
        ],
    }
}
