import React from 'react'
import { Icon } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export default function elementoMostraUtenzaTs(props) {
    return (
        <MDBox width='100%' borderRadius='lg' bgColor='white' p={2} display='grid' gridTemplateColumns='50% 50%' gap={2} >
            <MDTypography variant='h4' color='text'>
                {props.utenza.nome}
            </MDTypography>
            <MDBox display="flex" justifyContent="end">
                <MDButton size='small' variant='text' color='primary' onClick={()=>APRI_POP_UP({id : 'utenza-ts', parametro : props.utenza.id})}>
                    <Icon sx={{ mr: 1 }}>edit</Icon>
                    modifica
                </MDButton>
            </MDBox>
        </MDBox>
    )
}
