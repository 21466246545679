import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import { Divider, List } from '@mui/material'
import MDButton from 'components/MDButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { formattaDataDaFirebase } from 'data/strumenti'
import { getDateTutorial } from 'getter/getterDateTutorial'

export const elementoVediDateTutorial = (props) => {
    const getGiorno = (data) => {
        const dataTutorial = data.toDate()
        switch (dataTutorial.getDay()) {
            case 0:
                return 'Domenica'
            case 1:
                return 'Lunedì'
            case 2:
                return 'Martedì'
            case 3:
                return 'Mercoledì'
            case 4:
                return 'Giovedì'
            case 5:
                return 'Venerdì'
            case 6:
                return 'Sabato'
            default:
                return ''
        }
    }
    const getData = (data) => {
        const dataTutorial = data.toDate()
        return dataTutorial.getDate()
    }
    const getMese = (data) => {
        const dataTutorial = data.toDate()
        switch (dataTutorial.getMonth()) {
            case 0:
                return 'gennaio'
            case 1:
                return 'febbraio'
            case 2:
                return 'marzo'
            case 3:
                return 'aprile'
            case 4:
                return 'maggio'
            case 5:
                return 'giugno'
            case 6:
                return 'luglio'
            case 7:
                return 'agosto'
            case 8:
                return 'settembre'
            case 9:
                return 'ottobre'
            case 10:
                return 'novembre'
            case 11:
                return 'dicembre'
            default:
                return ''
        }
    }

    const renderData = (tutorial) => {
        return (
            <React.Fragment key={tutorial.id}>
                <ListItem>
                    <ListItemText primary={`${getGiorno(tutorial.contenuto.data)} ${formattaDataDaFirebase(tutorial.contenuto.data, true)}`} />
                    <ListItemIcon>
                        <MDButton size='small' variant='text' color='primary' onClick={() => window.open(tutorial.contenuto.link)}>
                            partecipa
                        </MDButton>
                    </ListItemIcon>
                </ListItem>
                <Divider />
            </React.Fragment>
        )
    }

    const renderDate = () => {
        if (props.lista.length === 0) {
            return (
                <MDTypography variant='body2' color='text'>
                    Non sono previste formazioni al momento.
                </MDTypography>
            )
        }
        return (
            <List>
                {props.lista.map((tutorial) => {
                    return renderData(tutorial)
                })}
            </List>
        )
    }

    const getTestoSingolaData = (el) => {
        const giorno = getGiorno(el.contenuto.data)
        const mese = getMese(el.contenuto.data)
        const data = getData(el.contenuto.data)
        const ora = formattaDataDaFirebase(el.contenuto.data, true, true)
        return `${giorno} ${data} ${mese} alle ${ora}`
    }

    const getTestoDate = () => {
        return props.lista.reduce((acc, el, index) => {
            const testo = getTestoSingolaData(el)
            if (index === props.lista.length - 1) acc += `${testo} `
            if (index === props.lista.length - 2) acc += `${testo} e `
            if (index !== props.lista.length - 1 && index !== props.lista.length - 2) acc += `${testo}, `
            return acc
        }, '')
    }

    return (
        <MDBox bgColor='white' width='100%' p={2}>
            <MDBox sx={{ maxWidth: '1000px', mx: 'auto' }}>
                <Grid container item xs={12} /* justifyContent='center' */ md={12} mx='auto' /* textAlign='center' */>
                    <MDBox sx={{ width: '100%', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        <MDTypography variant='h2' mb={1} color='info'>
                            A tu per tu con {appUtilizzata} Archive
                        </MDTypography>
                    </MDBox>
                    <MDTypography variant='body2' color='text'>
                        {getTestoDate()} ci vediamo in diretta streaming per esplorare insieme il gestionale {appUtilizzata} Archive insieme al nostro CEO Stefano Marchesi.
                    </MDTypography>
                    <MDTypography variant='body2' color='text'>
                        Inizia a segnarti le date e tutte le domande e curiosità che ti vengono in mente: ti aspettiamo!
                    </MDTypography>

                    <MDTypography variant='body2' color='text' sx={{ mt: 2 }}>
                        Quanto tempo dedichi ogni giorno alla gestione burocratica del paziente, alle pratiche amministrative e agli aspetti legali della tua clinica fisioterapica?
                    </MDTypography>

                    <MDTypography variant='body2' color='text' sx={{ mt: 1 }}>
                        Se la risposta è “TROPPO”, il gestionale {appUtilizzata} Archive convenzionato AIFI è la soluzione pensata apposta per te.
                    </MDTypography>

                    <MDTypography variant='body2' color='text' sx={{ mt: 1 }}>
                        Per conoscerci da vicino e per essere sicuro che {appUtilizzata} Archive sia lo strumento che fa al caso tuo, ti aspettiamo online in diretta streaming con il nostro CEO Stefano Marchesi, pronto a rispondere a ogni tua domanda e curiosità!
                    </MDTypography>

                    <MDTypography variant='body2' color='text' sx={{ mt: 1 }}>
                        Segnati la data che ti è più comoda:
                    </MDTypography>
                </Grid>
                <Divider />
                {renderDate()}
                <Divider />
                <MDTypography variant='body2' color='text'>
                    L’ingresso è libero, fino a esaurimento posti dell’aula virtuale.
                </MDTypography>
                <MDTypography variant='body2' color='text'>
                    Ti aspettiamo!
                </MDTypography>
                {/*  <MDBox className='flex-responsivo' mt={2} >
                    <MDTypography color='text'>Necessiti di un'altra data per un tutorial?</MDTypography>
                    <MDButton sx={{ml : 1}} color='primary' variant='contained' onClick={() => APRI_POP_UP({ id: 'date-tutorial', parametro: '' })}>
                        Contattaci
                    </MDButton>
                </MDBox> */}
            </MDBox>
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        lista: getDateTutorial()
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoVediDateTutorial)
