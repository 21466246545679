import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import VediForm from 'formMaker/vediForm'
import { formUtente } from 'formModel/formUtente'
import { formCredenzialiPaziente } from 'formModel/formCredenzialiPaziente'
import { newId } from 'data/strumenti'
import { CREA_DIPENDENTE } from 'data/azioni/CREA_DIPENDENTE'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { checkCodiceAifi } from 'getter/getterDipendenti'
import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
import { formPermessiDipendente } from 'formModel/formPermessiDipendente'
import { doc, setDoc } from 'firebase/firestore'
import { getAppUtilizzata } from 'getter/getterAppUtilizzata'
import { InputBoolean } from 'fields/fieldBoolean'
import { InputText } from 'fields/fieldText'

const arraySconti = ['2023BERGAMO', '2023SICILIACENTRALE',]

const modelAifi = {
    id: 'isCliente',
    tipo: 'fieldBoolean',
    associazione: 'isCliente',
    label: 'Sono cliente AIFI',
    value: false,
    details: {},
}
const modelHasCodice = {
    id: 'hasCodice',
    tipo: 'fieldBoolean',
    associazione: 'hasCodice',
    label: 'Ho un codice sconto',
    value: false,
    details: {},
}
const modelCodice = {
    id: 'codice',
    tipo: 'fieldText',
    associazione: 'codice',
    label: 'Codice :',
    value: '',
    details: {},
}

export const paginaCreaDipendente = (props) => {
    const [datiUtente, setDatiUtente] = useState({
       /*  nome : 'nome',
        cognome : 'test',
        genere : 'Maschio',
        indirizzo : 'indirizzo',
        comune : 'comune',
        provincia : 'provincia',
        cap : '11111',
        dataNascita : new Date(),
        cf : 'aaaaaa00a00a000a',
        email : 'a@m.com',
        numeroAlbo : 'albo',
        laurea : 'laurea' */
    })
    const [permessi, setPermessi] = useState({})
    const [credenziali, setCredenziali] = useState({})
    const [step, setStep] = useState(0)
    const [isCliente, setIsCliente] = useState(false)
    const [hasCodice, sethasCodice] = useState(false)
    const [codice, setCodice] = useState('')
    const [errore, setErrore] = useState('')

    const handleAifi = (campo, valore) => {
        if (valore) sethasCodice(!valore)
        setIsCliente(valore)
    }
    const handleHasCodice = (campo, valore) => {
        sethasCodice(valore)
        if (valore) setIsCliente(!valore)
    }
    const handleCodice = (campo, valore) => setCodice(valore)

    useEffect(() => {
        setErrore(props.erroreAifi)
    }, [props.erroreAifi])

    useEffect(() => {
        if (props.puoCreare) {
            console.log('iddddddddd : ', props.id)
            crea(props.id)
        }
    }, [props.puoCreare])

    const crea = async (id) => {
        var dati = Object.assign({}, datiUtente)
        const isCodiceOk = hasCodice && arraySconti.includes(codice)
        if (isCodiceOk) dati.codiceSconto = codice
        await CREA_DIPENDENTE(id, credenziali, dati, permessi)
        props.navigate('/paginaVediDipendenti')
    }

    const cosaFare = async () => {
        setErrore("")
        const cf = datiUtente.cf
        const id = newId()
        const isCodiceOk = hasCodice && arraySconti.includes(codice)
        if ((!isCliente && !hasCodice) || isCodiceOk) {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
            await crea(id)
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            return
        }
        if(hasCodice && !isCodiceOk){
            setErrore("Codice sconto inserito non valido")
            return
        } 
        let erroreAifi = await checkCodiceAifi(cf)
        if (erroreAifi !== '') {
            setErrore(erroreAifi)
            return
        }
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        window.dispatchEvent(new CustomEvent('osserveRichiestaAifi', { detail: id }))
        await setDoc(doc(db, 'richiesteAifi', id), { cf: cf, inAttesa: true, isValido: false })
    }

    const aggiornaDatiUtente = (e) => {
        if (e.laurea === undefined) {
            e.numeroAlbo = ''
            e.laurea = ''
        }
        setDatiUtente(e)
        setStep(step + 1)
    }

    const aggiornaPermessi = (e) => {
        setPermessi(e)

        setStep(step + 1)
    }
    const aggiornaCredenziali = (e) => {
        setCredenziali(e)
        const id = newId()
        if (props.steps.length === 3) {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
            crea(id)
        }
        if (props.steps.length !== 3) {
            setStep(step + 1)
        }
    }
    const tornaInizio = (e) => {
        setPermessi(e)

        setStep(step - 1)
    }
    const tornaPermessi = (e) => {
        setCredenziali(e)

        setStep(step - 1)
    }

    const renderAifi = () => {
        return (
            <React.Fragment>
                <MDBox flexDirection='column'>
                    <MDTypography align='center' variant='h4' mb={1}>
                        Hai un codice sconto?
                    </MDTypography>
                    <MDBox className='centrato'>
                        <MDTypography align='center' variant='caption' mb={3}>
                            Se sei cliente aifi, o di un ente convenzionato con Physio archive abbiamo un prezzo speciale per te!
                        </MDTypography>
                    </MDBox>
                    <InputBoolean model={modelAifi} value={isCliente} errore={''} azione={handleAifi} />
                    <InputBoolean model={modelHasCodice} value={hasCodice} errore={''} azione={handleHasCodice} />
                    {hasCodice ? <InputText model={modelCodice} value={codice} errore={''} azione={handleCodice} /> : null}
                    <MDTypography color='error'>{errore}</MDTypography>
                </MDBox>
                <MDBox className='contenitore-bottoni-destra'>
                    <MDButton color='error' variant='contained' onClick={() => props.navigate('')}>
                        annulla
                    </MDButton>
                    <MDButton color='primary' variant='contained' onClick={() => setStep(step - 1)}>
                        indietro
                    </MDButton>
                    <MDButton color='success' variant='contained' onClick={() => cosaFare()}>
                        salva
                    </MDButton>
                </MDBox>
            </React.Fragment>
        )
    }

    const renderSteps = () => {
        switch (step) {
            case 0:
                return <VediForm salva={(e) => aggiornaDatiUtente(e)} form={formUtente(props.form)} dati={datiUtente} vista='input' isPrimoStep={true} soloInput={true} annulla={() => props.navigate('')} />
            case 1:
                return <VediForm indietro={(e) => tornaInizio(e)} salva={(e) => aggiornaPermessi(e)} form={formPermessiDipendente(props.isDottore ? 'profilo-dottore' : 'profilo-segreteria')} dati={permessi} vista='input' isStepCentrale={true} soloInput={true} annulla={() => props.navigate('')} />
            case 2:
                return <VediForm indietro={(e) => tornaPermessi(e)} salva={(e) => aggiornaCredenziali(e)} form={formCredenzialiPaziente()} dati={credenziali} vista='input' isUltimoStep={props.steps.length === 2} isStepCentrale={props.steps.length > 3} soloInput={true} annulla={() => props.navigate('')} />
            case 3:
                return renderAifi()
            default:
                break
        }
    }

    return (
        <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%', mt: 5 }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
            <MDBox mx={2} mt={-7}>
                <Stepper activeStep={step} alternativeLabel>
                    {props.steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </MDBox>
            <MDBox mt={3}>{renderSteps()}</MDBox>
        </MDBox>
    )
}

function mapStateToProps(state, ownProps) {
    const presidio = getPresidio(getVeroUtenteLoggato())
    if (presidio === undefined || presidio === '') {
        ownProps.navigate('/paginaInserimentoDati')
    }
    let erroreAifi = ''
    let puoCreare = false
    let id = ''
    if (state.storico[0] === 'LEGGI_CONTROLLO_AIFI') {
        let datiReducer = state.reducerControlloAifi
        if (datiReducer.errore) {
            erroreAifi = 'Il codice fiscale inserito non risulta iscritto ad aifi'
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        }
        if (!datiReducer.errore) {
            puoCreare = true
            id = datiReducer.id
        }
    }
    let steps = ['Inserimento dati', 'Permessi', 'Credenziali', 'Aifi']
    if (state.url[4] !== 'creaDottore') {
        steps.pop()
    }
    if (state.url[4] === 'creaDottore' && getAppUtilizzata() === 'osteo') {
        steps.pop()
    }
    return {
        puoCreare: puoCreare,
        erroreAifi: erroreAifi,
        form: state.url[4],
        steps: steps,
        id: id,
        isDottore: state.url[4] === 'creaDottore',
    }
}

export default connect(mapStateToProps, null)(paginaCreaDipendente)
