import React from 'react'
import { connect } from 'react-redux'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import { getPermessiUtente } from 'getter/getterPermessi'

export function elementoMostraDisfunzione(props) {

    const renderIcona = () => {
        if (!props.permessi) {
            return ''
        }
        if (props.disfunzione.bandiera) {
            return (
                <ListItemIcon>
                    <FlagRoundedIcon color='error' />
                </ListItemIcon>
            )
        }
        if (props.disfunzione.documentoNonFirmato) {
            return (
                <ListItemIcon>
                    <PriorityHighRoundedIcon color='error' />
                </ListItemIcon>
            )
        }
        return ''
    }

    const test = () => {
        props.navigate(props.disfunzione.link)
    }

    return (
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
            <ListItemText primary={props.disfunzione.nome} secondary={props.disfunzione.data} />
            {renderIcona()}
            <ListItemIcon>
                <ArrowForwardIosRoundedIcon color='dark' />
            </ListItemIcon>
        </ListItem>
    )
}

function mapStateToProps(state) {
    return {
        permessi: getPermessiUtente().lesioniPaziente.input,
    }
}

export default connect(mapStateToProps, null)(elementoMostraDisfunzione)
