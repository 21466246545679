import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

export default function popUpTerminiCondizioni(props) {
    const handleClose = () => {
        props.chiudi()
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='scroll-dialog-title' aria-describedby='scroll-dialog-description'>
            <DialogTitle id='scroll-dialog-title'>Condizioni generali di fornitura di servizi</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
                    <MDTypography variant='h4'>1. DEFINIZIONI </MDTypography>
                    <MDTypography variant='string'>
                        Nelle presenti Condizioni Generali di Fornitura di servizi, i seguenti termini avranno esclusivamente il significato di seguito loro attribuito: • Condizioni Generali: la disciplina contenente le condizioni e i termini di fornitura del servizio • Contratto: il contratto di fornitura del Servizio di volta in volta concluso fra il Fornitore e il Cliente con l’invio del Modulo d’Ordine • Physio Archive: piattaforma che consente l’archiviazione, la consultazione inserimento e gestione informatica della cartella clinica e di tutti i dati riferibili a pazienti in cura presso una determinata struttura ovvero presso singoli professionisti • Servizio/i: fornitura della piattaforma Physio Archive • Fornitore: Stefano Marchesi, codice fiscale MRCSFN84A18L219K, partita iva
                        01780060099 • Cliente: Clinica o Professionista Individuale destinatari della fornitura del Servizio • Professionista/i: fisioterapista/i operanti all’interno della Clinica • Modulo d’Ordine: il modulo d’ordine predefinito del Fornitore • Modulo di Attivazione Professionista: il modulo con cui il Cliente richiede l’attivazione di un profilo per il Professionista/i • Corrispettivo: prezzo per la fornitura del Servizio • Interfaccia Clinica: interfaccia attraverso cui la Clinica può richiedere l’attivazione del Servizio per il proprio Professionista/i • Interfaccia Segreteria: interfaccia di consultazione e gestione delle anagrafiche dei pazienti del Cliente • Interfaccia Chiosco: interfaccia di inserimento anagrafiche per i pazienti della Clinica • Interfaccia
                        Fisioterapista: interfaccia operativa riservata al Professionista
                    </MDTypography>
                    <MDTypography variant='h4'>2. OGGETTO </MDTypography>
                    <MDTypography variant='string'>2.1 Le Condizioni Generali disciplinano le modalità ed i termini con cui il Fornitore mette a disposizione e fornisce il Servizio al Cliente. 2.2 Con l’adesione alle Condizioni Generali il Cliente accetta incondizionatamente e senza alcuna riserva i termini e le condizioni di seguito riportate.</MDTypography>
                    <MDTypography variant='h4'>3. STRUTTURA DEL CONTRATTO DI SERVIZIO</MDTypography>
                    <MDTypography variant='string'>3.1 Il contratto di fornitura del Servizio si compone dei seguenti documenti: a. Condizioni Generali; b. Modulo d’Ordine: c. Modulo di Attivazione Professionista.</MDTypography>
                    <MDTypography variant='h4'>4. PERFEZIONAMENTO DEL CONTRATTO</MDTypography>
                    <MDTypography variant='string'>
                        4.1 Il contratto di fornitura del Servizio con il Cliente si perfeziona alla data del corretto e puntuale ricevimento da parte del Cliente del Modulo di attivazione Professionista debitamente compilato in ogni sua parte. 4.2 L'invio del Modulo di Attivazione Professionista da parte del Cliente comporta l'integrale accettazione da parte del Cliente delle Condizioni Generali e di tutti gli altri documenti menzionati nelle Condizioni Generali. 4.3 Entro le 24 ore successive al perfezionamento del Contratto il Fornitore procederà all’attivazione del Servizio a favore del Cliente comunicando a mezzo e-mail tante credenziali di accesso all’Interfaccia Professionista, all’Interfaccia Clinica, all’Interfaccia Segreteria e all’Interfaccia Chiosco quante saranno le posizioni
                        attivate dal Cliente
                    </MDTypography>
                    <MDTypography variant='h4'>5. CORRISPETTIVI E MODALITA’ DI PAGAMENTO </MDTypography>
                    <MDTypography variant='string'>
                        5.1 Il Cliente corrisponderà al Fornitore il Corrispettivo quale risultante dal Modulo di Attivazione Professionista e gli eventuali costi accessori necessari per l’erogazione del medesimo, contestualmente alla trasmissione del Modulo di Attivazione Professionista e comunque anticipatamente rispetto all’erogazione del Servizio. 5.2 Il Cliente non potrà far valere diritti o sollevare eccezioni di alcun tipo se prima non avrà provveduto ad eseguire i pagamenti previsti dal contratto. 5.3 Il Fornitore si riserva la facoltà di interrompere o disattivare il Servizio richiesto con effetto immediato, nel caso in cui il pagamento del prezzo sia per qualsiasi motivo revocato o annullato dal Cliente, oppure non sia eseguito, confermato o accreditato a beneficio del Fornitore.
                        5.4 Il Cliente prende atto che il pagamento del Servizio potrà essere esclusivamente effettuato con una delle modalità indicate nel Modulo di Attivazione Professionista. Nessuna modalità di pagamento diversa da quelle sopra indicate viene accettata dal Fornitore.
                    </MDTypography>
                    <MDTypography variant='h4'>6. DURATA E RINNOVO</MDTypography>
                    <MDTypography variant='string'>6.1 Il Contratto regola la fornitura dei Sevizi al Cliente con decorrenza dalla data del suo effettivo perfezionamento. 6.2 Il Contratto ha la durata di un anno ed è tacitamente rinnovato di anno in anno salvo disdetta da parte del Cliente da comunicarsi entro il termine di 30 giorni prima della naturale scadenza e/o sue successive proroghe. 6.3 Il rinnovo del Contratto sarà effettivo solo con la ricezione da parte del Fornitore del Corrispettivo ovvero del diverso prezzo previsto al momento del rinnovo.</MDTypography>
                    <MDTypography variant='h4'>7. CREDENZIALI DI AUTENTICAZIONE</MDTypography>
                    <MDTypography variant='string'>
                        7.1 L’accesso al Servizio avviene tramite l’utilizzo di un codice di identificazione (login) e di una parola chiave (password) attribuiti al Cliente dal Fornitore e comunicate allo stesso tramite e-mail di conferma. Con la medesima comunicazione il Cliente riceverà altresì credenziali alternative da utilizzarsi in caso di blocco del Servizio conseguente a smarrimento e/o furto del terminale 7.2 Il Cliente prende atto ed accetta che la conoscenza da parte di terzi delle sue credenziali di autenticazione potrebbe consentire a questi ultimi l’indebito utilizzo dei Servizi, pertanto, si impegna a conservare ed utilizzare tali credenziali con la massima riservatezza e diligenza. 7.3 Egli sarà ritenuto responsabile di qualsiasi danno arrecato al Fornitore e/o a terzi in
                        dipendenza della mancata osservanza di quanto sopra prescritto. Il Cliente si impegna, altresì, a comunicare tempestivamente per iscritto al Fornitore l’eventuale furto, smarrimento o perdita delle credenziali di accesso.
                    </MDTypography>
                    <MDTypography variant='h4'>8. DATI DEL CLIENTE</MDTypography>
                    <MDTypography variant='string'>
                        8.1 Il Cliente garantisce che i propri dati e i dati del/i Professionista/i forniti a Fornitore per l'integrale esecuzione del contratto sono corretti, aggiornati e veritieri e permettono di individuare la sua vera identità. Il Cliente si impegna a comunicare tempestivamente al Fornitore ogni variazione ai propri dati personali indicati nel Modulo di Attivazione Professionista ed a fornire in qualsiasi momento, previa richiesta di Fornitore, prova adeguata della propria identità, del proprio domicilio o residenza e, se del caso, della propria professione e di quella del/i Professionista/i. Il Cliente prende atto ed accetta che, qualora abbia fornito al Fornitore i dati falsi, non attuali o incompleti, quest’ultimo si riserva il diritto di sospendere il Servizio e/o
                        di risolvere il contratto, trattenendo le somme pagate dal Cliente e riservandosi il diritto di chiedere il risarcimento del maggior danno; resta inteso che il Cliente non potrà avanzare al Fornitore alcuna richiesta di rimborso o indennizzo.
                    </MDTypography>
                    <MDTypography variant='h4'>9. OBBLIGHI DEL CLIENTE</MDTypography>
                    <MDTypography variant='string'>
                        9.1 Il Cliente si impegna ad utilizzare il Servizio in conformità a quanto indicato nel Contratto nel rispetto della legge, della normativa vigente della morale e dell’ordine pubblico. A titolo esemplificativo ma non esaustivo, il Cliente si impegna a: a. astenersi dal compiere ogni violazione dei sistemi e della sicurezza delle reti che possano dar luogo a responsabilità civile e/o penale; b. a non usare il Servizio in maniera tale da recare danno a sè stesso o a terzi; c. non consentire o cedere a terzi l’utilizzo del Servizio, anche parzialmente, né a pagamento né a titolo gratuito, non rivenderlo a terzi senza espressa autorizzazione scritta del Fornitore, in ogni caso non utilizzare o far utilizzare a terzi, direttamente o indirettamente, il Servizio contro la
                        morale e l’ordine pubblico e/o comunque al fine di violare o contravvenire, in qualsiasi forma e modo, alle vigenti disposizioni di legge; d. trattare come riservato ogni dato od informazione ricevuta, conosciuta o gestita in esecuzione della fornitura del Servizio. e. Provvedere al blocco del servizio in caso di segnalazione di furto o smarrimento del termine in uso da parte del Professionista/i. 9.2 Il Cliente dichiara di ottemperare alle vigenti normative in materia di protezione dei dati personali quali prescritte dal D.lgs. 193/2003 e dal Reg. CEE 679/2016 smi e si impegna ed obbliga ad adeguarsi alle successive prescrizioni normative in materia. 9.3 Il Cliente si impegna e obbliga a verificare la conformità al vero di tutti i dati personali relativi al
                        Professionista/i quali risultanti da Modulo di Attivazione Professionista e a comunicare tempestivamente al Fornitore eventuali modifiche relative ai medesimi. 9.4 In caso di violazione anche di uno soltanto dei suddetti obblighi/impegni, Fornitori avrà facoltà di intervenire nelle forme e nei modi ritenuti opportuni per eliminare, ove possibile, la violazione ed i suoi effetti, e comunque di sospendere immediatamente e senza alcun preavviso il Servizio, riservandosi inoltre il diritto di risolvere il contratto ai sensi del successivo art. 13 Il Fornitore tratterrà le somme pagate dal Cliente a titolo di risarcimento, fatto salvo in ogni caso il risarcimento del maggior danno. Il Cliente prende atto ed accetta che nulla avrà da pretendere dal Fornitore a titolo di
                        rimborso, indennizzo o risarcimento danni per i provvedimenti che lo stesso avrà ritenuto opportuno adottare. In ogni caso, il Cliente si assume, ora per allora, ogni responsabilità in merito alle violazioni di cui sopra e si impegna a manlevare e tenere indenne il Fornitore da qualunque conseguenza pregiudizievole.
                    </MDTypography>
                    <MDTypography variant='h4'>10. LIMITAZIONE DI RESPONSABILITA’ DEL FORNITORE</MDTypography>
                    <MDTypography variant='string'>
                        10.1 Il Fornitore declina qualsiasi responsabilità sia verso i propri Clienti sia verso terzi per ritardi, cattivo funzionamento, sospensione e/o interruzione nell'erogazione del Servizio causati da: a. caso fortuito e forza maggiore; b. malfunzionamento o non conformità degli apparecchi di connessione o del terminale di cui il Cliente si è dotato o comunque di quelli dal medesimo utilizzati; c. malfunzionamenti o non conformità della rete internet di cui il Cliente è dotato; d. manomissione o interventi sui servizi o sulle apparecchiature eseguiti dal Cliente o da parte di soggetti terzi non autorizzati dal Fornitore; e. errata utilizzazione del Servizio o utilizzazione del Servizio non conforme alle presenti Condizioni Generali o al Contratto. f. furto o
                        smarrimento del terminale di cui il Cliente o il Professionista si è dotato. 10.2 Il Cliente prende atto ed accetta che il Fornitore possa interrompere la fornitura del Servizio per garantire gli interventi di manutenzione straordinaria legati a fatti eccezionali e non prevedibili. Il Fornitore altresì informerà il Cliente in anticipo ed in modo adeguato di eventuali sospensioni e/o limitazioni del Servizio dovute ad interventi di manutenzione ordinaria. In tali casi il Fornitore non sarà in alcun modo responsabile nei confronti del Cliente per la mancata disponibilità del Servizio. Il Fornitore non sarà ritenuto responsabile per interruzioni parziali o totali del Servizio dovute ad attività e/o inerzia di altro fornitore. 10.3 Il Fornitore dichiara fin da ora di
                        non svolgere alcuna attività di controllo, mediazione e vigilanza sui contenuti immessi dal Cliente e/o dai Professionisti nella piattaforma. Per questo motivo, non potendo esercitare alcun controllo preventivo, il Cliente si assume la piena responsabilità dei contenuti inseriti ed esenta il Fornitore dall’obbligo di provvedere alla verifica e alla cancellazione dei contenuti ritenuti offensivi, discutibili o per qualsiasi ragione illeciti, pur riservandosi tale facoltà. 10.4 Il Fornitore non risponde infine di eventuali perdite di dati che possano derivare per effetto di un utilizzo improprio del Servizio, della cancellazione anche involontaria dei medesimi operata dal Cliente o dal Professionista, del furto o dello smarrimento dei terminali utilizzati dal Cliente
                        e/o dal Professionista. 10.5 In caso di malfunzionamento del Servizio imputabile al Fornitore, il Fornitore si impegna a ripristinare il Servizio entro un termine ragionevole decorrente dalla segnalazione effettuata dal Fornitore al Cliente e a prestare al Cliente comunque tutta la necessaria assistenza. Nessuna forma di risarcimento o indennizzo sarà dovuta al Cliente per l’inutilizzabilità del Servizio salvo il caso in cui la stessa si protragga per un periodo superiore a 10 giorni lavorativi.
                    </MDTypography>
                    <MDTypography variant='h4'>11. SCIOGLIMENTO DEL CONTRATTO</MDTypography>
                    <MDTypography variant='string'>
                        11.1 Il Cliente prende atto ed accetta che alla data di cessazione degli effetti del Contratto a qualsiasi causa dovuto, le Parti saranno automaticamente libere dalle rispettive obbligazioni. 11.2 Il Cliente prende atto ed accetta che costituisce suo esclusivo onere procurarsi o estrarre una copia dei dati e/o informazioni e/o contenuti trattati mediante il/i Servizio/i, restando inteso che una volta terminato il Contratto o scaduto il Servizio tali dati, informazioni e/o contenuti saranno conservati dal Fornitore per un periodo non superiore a 2 anni. 11.3 Il Fornitore si riserva la facoltà di procedere alla cancellazione dei dati informazioni e/o contenuti inserita dal Professionista nell’Interfaccia Professionista e dal Cliente nel caso in cui il Cliente non
                        attivi alcuna Interfaccia Professionista per un periodo di 24 mesi. 11.4 In ogni caso il Cliente solleva, ora per allora, il Fornitore da ogni e qualsiasi responsabilità per l'eventuale perdita o il danneggiamento totale o parziale di dati e/o informazioni e/ o contenuti immessi e/o trattati dal Cliente stesso mediante il/i Servizio/i.
                    </MDTypography>
                    <MDTypography variant='h4'>12. CLAUSOLA RISOLUTIVA ESPRESSA</MDTypography>
                    <MDTypography variant='string'>
                        12.1 Il Contratto si intenderà risolto di diritto, ai sensi e per gli effetti di cui all’art. 1456 c.c, qualora il Cliente: a. ceda tutto o parte del contratto a terzi, senza il preventivo consenso scritto del Fornitore; b. non provveda al pagamento del Corrispettivo; c. utilizzi i servizi in modo diverso rispetto a quanto comunicato al Fornitore ovvero in maniera diversa da come autorizzato da quest'ultimo; d. violi le disposizioni contenute nelle presenti Condizioni Generali, nel Modulo d’Ordine e nel Modulo di Attivazione Professionista; e. violi le prescrizioni di cui all’art. 9 e 10. f. renda dichiarazioni non veritiere in merito alla propria identità, professione, e all’identità o professione del/i Professionista/i. 12.2 Nelle ipotesi sopra indicate, la
                        risoluzione si verifica di diritto mediante dichiarazione unilaterale del Fornitore da trasmettersi al Cliente a mezzo posta certificata o raccomandata A/R in conseguenza della quale il Fornitore sarà autorizzato ad interrompere la fornitura del Servizio senza alcun preavviso. 12.3 In tali ipotesi, il Cliente prende atto ed accetta che le somme pagate dal medesimo saranno trattenute dal Fornitore a titolo di penale fatto salvo in ogni caso il risarcimento del maggior danno, senza che lo stesso possa avanzare alcuna richiesta di rimborso, indennizzo e/o risarcimento danni per il periodo in cui non ha usufruito del Servizio. 12.4 Resta inteso che la risoluzione di diritto sopra indicata opera senza pregiudizio per le altre ipotesi di risoluzione previste dalla legge.
                    </MDTypography>
                    <MDTypography variant='h4'>13. DIRITTI ESCLUSIVI DI PROPRIETÀ</MDTypography>
                    <MDTypography variant='string'>
                        13.1 I Servizi saranno utilizzati dal Cliente nel rispetto dei diritti di proprietà intellettuale e/o industriale del Fornitore o di terzi. 13.2 Il Fornitore è titolare esclusivo e dispone del software, della relativa documentazione e di ogni altra informazione fornita al Cliente in esecuzione del contratto. Il Cliente, pertanto, non è autorizzato a riprodurre, elaborare, pubblicare, diffondere e cedere a terzi con qualunque mezzo il materiale sopra indicato, se non nei limiti di quanto sia necessario per usufruire del Servizio. 13.3 Tutto il materiale e i documenti provenienti dal Fornitore devono essere trattati come riservati e confidenziali con espresso divieto di pubblicarli, trasmetterli o diffonderli presso terzi in qualsiasi modo e forma.
                    </MDTypography>
                    <MDTypography variant='h4'>14. PROCEDURA DI CONCILIAZIONE - FORO COMPETENTE</MDTypography>
                    <MDTypography variant='string'>14.1 Il Cliente che intende proporre reclamo innanzi all'Autorità Giudiziaria è tenuto ad esperire tentativo di conciliazione obbligatorio presso gli organi di composizione extragiudiziale delle controversie operativi presso il Tribunale di Torino. 14.2 Per ogni e qualsiasi controversia relativa all'interpretazione, esecuzione e risoluzione del presente contratto sarà competente il Foro di Torino con l’esclusione di diversi fori alternativi o concorrenti.</MDTypography>
                    <MDTypography variant='h4'>15. DISPOSIZIONI FINALI E COMUNICAZIONI</MDTypography>
                    <MDTypography variant='string'>
                        15.1 Nessuna modifica, postilla o clausola comunque aggiunta al presente contratto sarà valida se non specificatamente approvata per iscritto dalle parti. 15.2 Tutte le comunicazioni al Cliente relative al presente rapporto contrattuale potranno essere effettuate dal Fornitore a mano, tramite e-mail, a mezzo di lettera raccomandata A.R., posta ordinaria oppure a mezzo telefax agli indirizzi comunicati dal Cliente e, in conseguenza, le medesime si considereranno da questi conosciute. Eventuali variazioni degli indirizzi del Cliente non comunicate al Fornitore non saranno ad esso opponibili. 15.3 L’eventuale inefficacia e/o invalidità totale o parziale di uno o più articoli del contratto non comporterà l’invalidità degli altri articoli i quali dovranno ritenersi
                        validi ed efficaci; 15.4 Per quanto non espressamente previsto nelle presenti Condizioni Generali si rinvia, nei limiti in cui ciò sia compatibile alle norme di legge vigenti al momento della conclusione del contratto.
                    </MDTypography>
                    <MDTypography variant='h4'>16. INFORMATIVA EX ART. 13 REGOLAMENTO EUROPEO 679/2016</MDTypography>
                    <MDTypography variant='string'>
                        16.1 Il trattamento dei dati personali forniti dal Cliente sarà effettuato in ottemperanza alle disposizioni previste dal Regolamento CEE n. 679/2016 e del D.lgs. 196/2003 smi. Il trattamento dei dati sarà improntato ai principi di correttezza, liceità e trasparenza tutelando la riservatezza e i diritti del Cliente e di terzi. 16.2. I dati personali del Cliente saranno trattati per: (1) esecuzione obblighi contrattuali: la base giuridica è il legittimo interesse del Fornitore alla corretta gestione del rapporto contrattuale in essere. Il conferimento dei dati è necessario e in mancanza non sarà possibile stipulare o erogare correttamente il Servizio. I dati saranno conservati nei tempi previsti dalle disposizioni in materia di prescrizione. (2) Adempimento degli
                        obblighi di legge e regolamenti. La base giuridica sono gli obblighi di legge a cui è soggetto il Fornitore. Il conferimento dei dati è obbligatorio e il mancato conferimento comporterà l’impossibilità di stipulare o eseguire correttamente il contratto. I dati saranno conservati nei limiti imposti dalle disposizioni di legge. (3) Accertamento, esercizio o difesa di un diritto del Titolare o di terzi. La base giuridica è rappresentata dal legittimo interesse del Fornitore e/o di terzi. I dati saranno conservati nei tempi previsti dalle disposizioni in materia di prescrizione e per un tempo superiore nel caso di contenziosi in corso. 16.3 Le successive manifestazioni di volontà espresse dal Cliente in occasione della sottoscrizione di ulteriori contratti sono da
                        intendersi come aggiornamenti delle manifestazioni di volontà precedentemente rese dal Cliente medesimo. Il Cliente può in qualsiasi momento revocare o modificare i consensi espressi. 16.4 I dati saranno conservati sino all’opposizione/revoca del consenso e comunque non oltre i 24 mesi dalla cessazione del rapporto contrattuale, salvo refresh dello stesso da parte del Cliente. 16.5 I dati personali potranno essere comunicati per le finalità illustrate a soggetti che svolgono servizi per conto del Fornitore, quali Responsabili del Trattamento; ovvero a soggetti pubblici o privati che li tratteranno in qualità di autonomi titolari del trattamento. 16.6 I dati personali non saranno oggetto di trasferimento a società terze in Paesi non appartenenti all’Unione Europea.
                        Laddove tale trasferimento si rendesse necessario per taluna delle finalità sopra menzionate, il Titolare adotterà ogni misura necessaria a garantire un adeguato livello di protezione dei dati personali. 16.7 Ai sensi degli articoli da 15 a 22 del GDPR il Cliente ha diritto di ottenere l’accesso ai dati personali, la rettifica, l’integrazione o la cancellazione dei suoi dati personali; il diritto di ottenere la limitazione del trattamento e il diritto alla portabilità dei dati, il diritto di opposizione al trattamento dei dati personali, nei casi previsti dal GDPR. 16.8 Il Titolare del Trattamento è Stefano Marchesi al quale può rivolgersi per far valere i Suoi diritti così come previsto dagli artt. dal 15 al 22 del Regolamento europeo n. 679/2016 utilizzando il
                        seguente indirizzo e-mail stefano.marchesi1984@gmail.com . 16.9 Il Cliente dichiara, a propria volta, che il trattamento dei dati personali viene effettuato in ottemperanza alle dettate dal Regolamento CEE 2016/679 ("GDPR") e del D.lgs.196/2003 e smi (“Codice Privacy”), di aver provveduto alla nomina del Fornitore quale Responsabile del Trattamento e di essere espressamente autorizzato da propri dipendenti, collaboratori e pazienti a comunicare al Fornitore, i loro dati personali e comunque tutti i dati che il Cliente provvederà a comunicare usufruendo del Servizio.
                    </MDTypography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MDButton color='primary' variant='text' onClick={handleClose}>
                    Chiudi
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
