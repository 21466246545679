import { store } from './../data/store'
import { trasformaOggettoInArray, formattaDataDaFirebase } from './../data/strumenti'
import { getIdPaziente } from './getterPaziente'
import cloneDeep from 'lodash-es/cloneDeep'
import { getNomeDipendente } from './getterDipendenti'
import { getListaSeduteGeneriche } from './getterSedute'

const getLesione = () => {
    if (store.getState().reducerLesioni === undefined) {
        return {}
    }
    return store.getState().reducerLesioni.lesioneDaVedere.contenuto
}

export const getListaLesioni = () => {
    if (store.getState().reducerLesioni === undefined) {
        return []
    }
    return trasformaOggettoInArray(store.getState().reducerLesioni.listaLesioni)
}

export const getSkeletonLesioni = () => {
    const lesione = store.getState().reducerLesioni || {}
    if (lesione.skeleton === undefined) {
        return true
    }
    return lesione.skeleton
}

export const getDatiInformazioniLesione = (lesione) => {
    var informazioni = lesione.informazioni
    if (informazioni === undefined) {
        return informazioni
    }
    if (informazioni.FarmaciUtilizzati === undefined) return Object.assign(informazioni, { FarmaciUtilizzati: [] })
    informazioni.FarmaciUtilizzati = informazioni.FarmaciUtilizzati.map((farmaco) => {
        if (typeof farmaco !== 'string') return farmaco
        return { nome: farmaco, dataFine: '' }
    })
    return informazioni
}

export const getInformazioniLesione = () => {
    return getDatiInformazioniLesione(getLesione())
}

export const getBandieraLesione = (lesione) => {
    if (lesione.informazioni === undefined) {
        return []
    }
    if (lesione.informazioni.bandierina === undefined) {
        return []
    }
    if (lesione.informazioni.bandierina.id === undefined) {
        return []
    }
    if (typeof lesione.informazioni.bandierina.id === 'string') {
        return lesione.informazioni.bandierina.id === '' ? [] : [lesione.informazioni.bandierina.id]
    }
    return lesione.informazioni.bandierina.id
}

export const isDocumentoLesioneFIrmato = (lesione) => {
    if (lesione.sedute === undefined) {
        return false
    }
    var strategie = []
    trasformaOggettoInArray(lesione.sedute).forEach((seduta) => {
        trasformaOggettoInArray(seduta.contenuto.elementi).forEach((elemento) => {
            if (elemento.contenuto.tipo === 'strategia' || elemento.contenuto.tipo === 'fieldStrategia') {
                strategie.push(elemento)
            }
        })
    })
    if (strategie.length === 0) {
        return false
    }
    let firmato = strategie.map((strategia) => {
        return strategia.contenuto.datiDocConsensoInformato === undefined
    })
    return firmato.includes(false)
}

export const getRiassuntoLesione = () => {
    let lesione = getLesione()
    return {
        titolo: lesione.titolo || '',
        bandiera: getBandieraLesione(lesione).includes('rosso'),
        documento: !isDocumentoLesioneFIrmato(lesione),
    }
}

export const getLesioniMenu = () => {
    let lista = [...trasformaOggettoInArray(store.getState().reducerLesioni?.listaLesioni || {})]
    lista.sort((a, b) => {
        let dataA = a.contenuto?.etichettaUniversale?.dataCreazione?.seconds
        let dataB = b.contenuto?.etichettaUniversale?.dataCreazione?.seconds
        return dataB - dataA
    })
    let index = lista.findIndex((elemento) => {
        return elemento.id === 'seduteNoLesione'
    })
    if (index !== -1) {
        lista.splice(index, 1)
    }
    return lista.map((lesione) => {
        return {
            id: lesione.id,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${lesione.id}/paginaHomeDisfunzione`,
            nome: lesione.contenuto.titolo,
            bandiera: getBandieraLesione(lesione.contenuto).includes('rosso'),
            documentoNonFirmato: !isDocumentoLesioneFIrmato(lesione.contenuto),
            parteUrl: 6,
            data: lesione.contenuto.etichettaUniversale === undefined ? '' : formattaDataDaFirebase(lesione.contenuto.etichettaUniversale.dataCreazione),
        }
    })
}
export const getElementoSeduta = (tipo) => {
    let array = trasformaOggettoInArray(store.getState().reducerSedute.seduteLesione.listaSedute)
    array.sort((a, b) => {
        let dataA = a.contenuto.etichettaUniversale.dataCreazione.seconds
        let dataB = b.contenuto.etichettaUniversale.dataCreazione.seconds
        return dataB - dataA
    })
    let tipi = []
    if (tipo === 'fieldStrategia') {
        tipi = ['fieldStrategia', 'strategia']
    }

    if (tipo === 'fieldDiagnosi') {
        tipi = ['fieldDiagnosi', 'diagnosi']
    }
    let trovato = false
    let elemento = {}
    for (let i = 0; i < array.length && trovato === false; i++) {
        if (array[i].contenuto.elementi === undefined) {
            return
        }
        let elementi = Object.keys(array[i].contenuto.elementi)
        for (let index = 0; index < elementi.length && trovato === false; index++) {
            if (tipi.includes(array[i].contenuto.elementi[elementi[index]].tipo)) {
                elemento = cloneDeep(array[i].contenuto.elementi[elementi[index]])
                trovato = true
                elemento.dataSeduta = array[i].contenuto.etichettaUniversale.dataCreazione
                elemento.dottore = array[i].contenuto.etichettaUniversale.user
                elemento.idSeduta = array[i].id
                elemento.id = elementi[index]
            }
        }
    }
    return elemento
}

export const getImmagini = () => {
    let lesioni = []
    let immagini = []
    if (getIdLesioneSelezionata() === '') {
        lesioni = getListaIdLesioni().map((idLesione) => {
            return getLesioneDaId(idLesione)
        })
    }
    if (getIdLesioneSelezionata() !== '') {
        lesioni = [getLesione()]
    }
    lesioni.forEach((lesione) => {
        if (lesione.documentazionePregressa !== undefined) {
            let nuoveImmagini = trasformaOggettoInArray(lesione.documentazionePregressa.immagini)
            nuoveImmagini.forEach((immagine) => {
                immagine.contenuto.disfunzione = lesione.titolo
            })
            immagini = immagini.concat(nuoveImmagini)
        }
    })
    return immagini
}

export const getPrimeTreImmagini = () => {
    let immagini = getFIles().listaFiles
    let nuoveImmagini = []
    for (let index = 0; index < immagini.length && nuoveImmagini.length < 3; index++) {
        immagini[index].contenuto.immagini.forEach((immagine) => {
            if (nuoveImmagini.length < 3) {
                nuoveImmagini.push(immagine)
            }
        })
    }
    return nuoveImmagini
}

export const getLesioneSelezionata = () => {
    let lesione = getLesione()
    const elementoNonPresente = (testo) => {
        return `<div class="grassetto">${testo}</div>`
    }
    const elementoPresente = (elemento) => {
        return `<elemento-vedi-field-seduta .elemento="${elemento}"></elemento-vedi-field-seduta>`
    }

    const vediFiles = (immagini) => {
        return `
            <elemento-srotolabile>
                ${immagini.map((immagine) => {
                    return html`<elemento-visualizza-immagine .immagine="${immagine}" .isSelezionata="${false}"></elemento-visualizza-immagine>`
                })}
            </elemento-srotolabile>
        `
    }
    if (Object.keys(lesione).length === 0) {
        return {
            data: '',
            immagini: [],
            bandiera: {},
            ultimaDiagnosi: {},
            ultimaStrategia: {},
        }
    }
    let immagini = getPrimeTreImmagini()
    return {
        titolo: lesione.titolo || '',
        documento: isDocumentoLesioneFIrmato(lesione),
        data: formattaDataDaFirebase(lesione.etichettaUniversale.dataCreazione),
        immagini: immagini,
        bandiera: getBandieraLesione(lesione).includes('rosso'),
        ultimaDiagnosi: getElementoSeduta('fieldDiagnosi'),
        ultimaStrategia: getElementoSeduta('fieldStrategia'),
        dottore: getNomeDipendente(lesione.etichettaUniversale.user),
    }
}

export const getLinkSingolaLesione = (id) => {
    let link = [
        {
            testo: 'Home',
            parteUrl: 7,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${id}/paginaHomeDisfunzione`,
        },
        {
            testo: 'Dolori',
            parteUrl: 7,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${id}/paginaDoloriDisfunzione`,
        },
        {
            testo: 'Informazioni',
            parteUrl: 7,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${id}/paginaInformazioniDisfunzione`,
        },
        {
            testo: 'Files',
            parteUrl: 7,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${id}/paginaFilesDisfunzione`,
        },
        {
            testo: 'Sedute',
            parteUrl: 7,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${id}/paginaSeduteDisfunzione`,
        },
    ]
    if (getIdSedutaSelezionata() !== '') {
        link.push({
            testo: 'Seduta selezionata',
            parteUrl: 7,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${id}/paginaVediSeduta/${getIdSedutaSelezionata()}`,
        })
    }
    return link
}

export const getIdLesioneSelezionata = () => {
    if (store.getState().reducerLesioni === undefined) {
        return ''
    }
    return store.getState().reducerLesioni.lesioneDaVedere.id
}

export const getIdSedutaSelezionata = () => {
    if (store.getState().reducerSedute === undefined) {
        return ''
    }
    return store.getState().reducerSedute.sedutaSelezionata.idSeduta
}

export const getFIles = () => {
    let immagini = getImmagini()
    let files = {}
    let documenti = []
    immagini.forEach((immagine) => {
        if (immagine.contenuto.etichettaUniversale !== undefined) {
            if (files[formattaDataDaFirebase(immagine.contenuto.etichettaUniversale.dataCreazione)] === undefined) {
                files[formattaDataDaFirebase(immagine.contenuto.etichettaUniversale.dataCreazione)] = { data: immagine.contenuto.etichettaUniversale.dataCreazione, immagini: [] }
            }
            files[formattaDataDaFirebase(immagine.contenuto.etichettaUniversale.dataCreazione)].immagini.push(immagine)
        }
    })
    let sedute = getListaSeduteGeneriche()
    sedute.forEach((seduta) => {
        trasformaOggettoInArray(seduta.contenuto.elementi).forEach((elemento) => {
            if (['fieldStrategia', 'strategia'].includes(elemento.contenuto.tipo)) {
                if (elemento.contenuto.datiDocConsensoInformato !== undefined) {
                    documenti.push({ nomeLesione: getLesioneDaId(seduta.contenuto.idLesione).titolo, data: elemento.contenuto.etichettaUniversale !== undefined ? elemento.contenuto.etichettaUniversale.dataCreazione : seduta.contenuto.etichettaUniversale.dataCreazione, id: elemento.contenuto.datiDocConsensoInformato.docId })
                }
            }
        })
    })
    let listaFiles = trasformaOggettoInArray(files)
    listaFiles.sort((a, b) => {
        let dataA = a.contenuto.data.seconds
        let dataB = b.contenuto.data.seconds
        return dataB - dataA
    })
    return { listaFiles, documenti }
}

export const getTabDisfunzioneSelezionata = () => {
    let url = store.getState().url[7]
    switch (url) {
        case 'paginaHomeDisfunzione':
            return 0
            break
        case 'paginaFilesDisfunzione':
            return 3
            break
        case 'paginaInformazioniDisfunzione':
            return 2
            break
        case 'paginaSeduteDisfunzione':
            return 4
            break
        case 'paginaVediSeduta':
            return 5
        case 'paginaDoloriDisfunzione':
            return 1
            break

        default:
            return 0
    }
}

export const isNavigazioneDisunzioneDaVedere = () => {
    return getIdLesioneSelezionata() !== 'seduteNoLesione'
}

export const getLesioneDaId = (id) => {
    return store.getState().reducerLesioni.listaLesioni[id]
}

export const getListaIdLesioni = () => {
    let lista = Object.keys(store.getState().reducerLesioni?.listaLesioni || {})
    /* const index = lista.indexOf('seduteNoLesione')
    if (index !== -1) {
        lista.splice(index, 1)
    } */
    return lista
}

export const getLesioniDashboard = () => {
    return getLesioniMenu().filter((lesione, index) => {
        return index < 5
    })
}
