import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

// Material Kit 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Kit 2 PRO React examples
import SimplePricingCard from 'examples/Cards/SimplePricingCard'
import { getDatiPricing } from 'getter/getterAppUtilizzata'

export default function pricing() {
    const permessi = {
        provaGratuita: '30 giorni di prova gratuiti per i primi due fisioterapisti della clinica',
        pazienti: 'Vedi e crea i nuovi pazienti della tua clinica',
        datiPaziente: 'Vedi e modifica anagrafica, storia clinica e abitudini dei pazienti',
        datiPazienteOutput: 'Vedi e modifica anagrafica, storia clinica e abitudini',
        anagrafica: `Vedi e modifica l'anagrafica dei pazienti`,
        esercizi: 'Vedi e assegna esercizi ai pazienti',
        eserciziOutput: 'Vedi gli esercizi assegnati',
        fatture: 'Vedi e genera fatture per i tuoi clienti',
        fattureAutorizzato: `Vedi e genera fatture per i tuoi clienti (se autorizzato dall'amministrazione)`,
        fattureOutput: 'Vedi e scarica le tue fatture',
        lesioni: 'Vedi modifica e crea disfunzioni ai pazienti',
        lesioniOutput: 'Vedi le tue disfunzioni e le relative informazioni',
        dipendenti: `Vedi e aggiungi dipendenti alla tua clinica e gestisci i suoi abbonamenti`,
        dipendentiAutorizzato: `Vedi e aggiungi dipendenti alla tua clinica e gestisci i suoi abbonamenti (se autorizzato dall'amministrazione)`,
        creaPaziente: 'Crea i pazienti per la tua clinica',
        sistemaTs: 'Configura i tuoi dati per inviare le fatture al sistema TS',
        sistemaTsAutorizzato: `Configura i tuoi dati per inviare le fatture al sistema TS (se autorizzato dall'amministrazione)`,
        documenti: 'Fai firmare e scarica velocemente i documenti necessari dei pazienti',
        documentiOutput: 'Scarica i documenti che hai firmato',
    }

    const permessiDottore = [permessi.provaGratuita, permessi.pazienti, permessi.datiPaziente, permessi.esercizi, permessi.fattureAutorizzato, permessi.lesioni, permessi.dipendentiAutorizzato, permessi.sistemaTsAutorizzato, permessi.documenti]
    const permessiPaziente = [permessi.datiPazienteOutput, permessi.eserciziOutput, permessi.fattureOutput, permessi.lesioniOutput, permessi.documentiOutput]
    const permessiSegreteria = [permessi.pazienti, permessi.anagrafica, permessi.dipendentiAutorizzato, permessi.sistemaTs, permessi.documenti]
    const permessiChiosco = [permessi.creaPaziente]
    const permessiAmministrazione = [permessi.dipendenti, permessi.sistemaTs]

    const dati = getDatiPricing()

    const getProps = (profilo) => {
        let props = {
            description: profilo.descrizione,
            color: profilo.color,
            title: profilo.titolo,
            price: { value: `${profilo.prezzo} €`, type: profilo.descrizionePrezzo },
            specifications: profilo.permessi,
        }
        if (profilo.variant !== undefined && profilo.variant !== '') {
            props.variant = profilo.variant
        }
        return props
    }

    return (
        <MDBox component='section' py={{ xs: 0, md: 12 }}>
            <Container>
                <Grid container item xs={12} justifyContent='center' md={8} mx='auto' textAlign='center'>
                    <MDTypography variant='h3' color="info">PRICING</MDTypography>
                    <MDTypography variant='body2' color='text'>
                        {dati.testo}
                    </MDTypography>
                </Grid>
                <Grid container item xs={12} md={10} lg={8} justifyContent='center' textAlign='center' mx='auto' mt={6}></Grid>
                <Grid container spacing={3} mt={6}>
                    {dati.profili.map((profilo) => {
                        return (
                            <Grid item xs={12} sm={6} lg={4} key={profilo.titolo}>
                                <SimplePricingCard {...getProps(profilo)} />
                            </Grid>
                        )
                    })}

                    
                </Grid>
            </Container>
        </MDBox>
    )
}
