const initialState = {}

export const reducerListaAgenti = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'AGGIORNA_LISTA_AGENTI':
            return action.payload
        default:
            return state
    }
}
