import { getIdUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { creaPasswordRandom, newId, creaEtichettaUniversale } from '../strumenti'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const CREA_PRESIIDO = (form) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    return new Promise((resolve, reject) => {
        let idPresidio = newId()
        let nome = form.nome.replace(/\s/g, '')
        let datiChiosco = {
            nome: 'chiosco',
            etichettaUniversale: creaEtichettaUniversale(idPresidio),
            profilo: 'profilo-chiosco',
            profiloOriginale: 'profilo-chiosco',
            isPaziente: false,
            cognome: 'chiosco',
            attivo : true,
        }
        form.numNoteCredito = 1
        form.numFattura = 1
        form.popUpGuida = {
            stato: 2,
            daAprire: true,
        }

        let daPassare = {
            presidio: idPresidio,
            datiUtente: form,
            profilo: 'profilo-amministrazione-clinica',
            idAmministrazione: getIdUtenteLoggato(),
            chiosco: {
                idUtente: newId(),
                datiUtente: datiChiosco,
                profilo: 'profilo-chiosco',
                idPresidio: idPresidio,
                inserisciCredenziali : true,
                credenziali: {
                    email: `chiosco@${nome.toLowerCase()}.com`,
                    password: creaPasswordRandom(),
                },
            },
        }

        CHIAMA_SERVER(`creaPresidio`, daPassare).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
