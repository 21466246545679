import { baseWatcher } from './baseWatcher'
import { trasformaOggettoInArray } from './../strumenti'
import { collection, query, where, onSnapshot } from 'firebase/firestore'

export class watcherListaAmministrazioni extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione() {
        const q = query(collection(db, 'utenti'), where('profilo', '==', 'profilo-amministrazione-clinica'))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            var nomeCollezioneDB = {}
            querySnapshot.forEach((doc) => {
                nomeCollezioneDB[doc.id] = doc.data()
            })

            window.listaAmministrazioni = trasformaOggettoInArray(nomeCollezioneDB)
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'amministrazioni' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
