import { convertiDataInStringa } from '../strumenti'
import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { LEGGI_FATTURA } from './../azioni/LEGGI_FATTURA'
import { getDatiPresidio } from 'getter/getterPresidio'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherFattura extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        window.addEventListener('apriPopUp', (e) => {
            if (e.detail.id === 'nuova-nota') {
                this.update(e.detail.parametro)
            }
            if (e.detail.id === 'fattura') {
                if (e.detail.parametro.idFattura === '') {
                    this.getDeafult()
                }
                if (e.detail.parametro.idFattura !== '') {
                    this.update(e.detail.parametro.idFattura)
                }
            }
        })
    }

    getDeafult() {
        setTimeout(() => {
            if (Object.keys(store.getState().reducerConfigurazioneBaseFatture).length === 0) {
                LEGGI_FATTURA({ datiFattura: {numFattura : `${getDatiPresidio().numFattura}-${new Date().getFullYear()}`, date: convertiDataInStringa(new Date()) } })
            } else {
                LEGGI_FATTURA({
                    datiFattura: { date: convertiDataInStringa(new Date()), numFattura: `${getDatiPresidio().numFattura}-${new Date().getFullYear()}` },
                    extra: Object.keys(store.getState().reducerConfigurazioneBaseFatture.extra).reduce((acc, el) => {
                        if (el !== 'defaultPagamento') {
                            acc[el] = store.getState().reducerConfigurazioneBaseFatture.extra[el]
                        }
                        return acc
                    }, {}),
                })
            }
        },100)
    }

    update(nuovoId) {
        this.apriConnessione(nuovoId)
    }

    apriConnessione(nuovoId) {

        this.funzioneConnessione = onSnapshot(doc(db, 'fatture', nuovoId, ), (documento) => {
            LEGGI_FATTURA(documento.data())
        })


    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
