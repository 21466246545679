import React from 'react'
import { connect } from 'react-redux'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { getPaziente, getSkeleton } from 'getter/getterPaziente'
import VediForm from 'formMaker/vediForm'
import { formAbitudiniPaziente } from 'formModel/formAbitudiniPaziente'
import { AGGIORNA_DATI_PAZIENTE } from 'data/azioni/AGGIORNA_DATI_PAZIENTE'
import ChairRoundedIcon from '@mui/icons-material/ChairRounded'
import Skeleton from '@mui/material/Skeleton';

export const paginaVediAbitudini = (props) => {
    let form = formAbitudiniPaziente()

    const aggiorna = async (e) => {
        await AGGIORNA_DATI_PAZIENTE(e)
        return
    }

    return (
        <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <ChairRoundedIcon color='white' sx={{ mr: 1 }} />
                <MDTypography color={'white'} variant='h5'>
                    Abitudini paziente
                </MDTypography>
            </MDBox>
            <VediForm skeleton={props.skeleton} form={form} dati={props.dati} dialer={true} salva={(e) => aggiorna(e)} />
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        dati: getPaziente(),
        skeleton:  getSkeleton(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediAbitudini)
