import { store } from './../store'
import axios from 'axios'
import { APRI_POP_UP } from './APRI_POP_UP'
import { getAuth } from 'firebase/auth'

export const CHIAMA_SERVER = async (chiamata, parametro) => {
    //console.log(JSON.stringify(parametro))
    let headers = {
        'content-type': 'application/json',
        //inizio server
progetto : "produzione"
//fine server
    }
    if (getAuth().currentUser !== null) {
        let idToken = await getAuth().currentUser.getIdToken(/* forceRefresh */ false)

        headers['token'] = idToken
    }

    let link = `https://server.physio-archive.com:8009/${chiamata}`
    //let link = `http://localhost:8009/${chiamata}`



    try {
        console.log('parteeeee ')
        console.log(parametro, link)
        let result = await axios.post(link, parametro, { headers: headers })
        console.log('finisceeeeeee')
        console.log(result)
        return result.data
    } catch (error) {
        console.log('errore : ', error)

        const testoErrore = error.response?.data?.message || "Errore di connessione, riprova piu' tardi" 
        console.log('testo :', testoErrore);

        APRI_POP_UP({ id: 'alert', parametro: { severity: 'error', testo: testoErrore /* 'Si é verificato un errore...'  */ } })
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        throw new Error(error)
        // let errore = error.response.data
        //console.log(errore)
    }
}
