import { store } from "data/store"

export const APRI_POP_UP_FIELD_LIST = (oggettoEvento) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'APRI_POP_UP_FIELD_LIST',
            payload: oggettoEvento,
        })
        window.dispatchEvent(new CustomEvent('apriPopUp', { detail: oggettoEvento }))
        resolve()
    })
}
export const CHIUDI_POP_UP_FIELD_LIST = () => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'CHIUDI_POP_UP_FIELD_LIST',
            payload: {},
        })
        resolve()
    })
}
