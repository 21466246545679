export const formDettagliEsercizi = () => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'nome',
                tipo: 'fieldTextArea',
                associazione: 'nome',
                label: 'Titolo:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                    nascondiLabel: true,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'testo',
                tipo: 'fieldTextArea',
                associazione: 'testo',
                label: 'Descrizione:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                    nascondiLabel: true,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'tags',
                tipo: 'fieldTags',
                associazione: 'tags',
                label: 'Tags:',
                value: [],
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                    nascondiLabel: true,
                },
            },
        ],
    }
}
