import { useRef } from 'react'

import SwiperCore, { Autoplay, Navigation, Virtual } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Material Kit 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React, { useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Grid, Tabs, Tab } from '@mui/material'
import { getProfiliCarosello } from 'getter/getterAppUtilizzata'

function carosello() {
    // install SwiperJS modules
    SwiperCore.use([Autoplay, Navigation])

    const [slide, setSlide] = useState(0)
    const [swiper, setSwiper] = useState(null)

    // Swiper navigation buttons styles
    const navigationStyles = {
        position: 'absolute',
        top: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '15%',
        height: '100%',
        textAlign: 'center',
        opacity: 0.5,
        cursor: 'pointer',
        transition: 'opacity 0.15s ease',

        '&:hover, &:focus': {
            opacity: 1,
        },
    }
    const profili = getProfiliCarosello()

    // SwiperJS navigation buttons ref
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const cambiaSlide = (index) => {
        swiper.slideTo(index + 1)
    }

    const renderImmagine = (immagine) =>{
        
    }

    const renderSlide = (profilo, index) => {
        return (
            <SwiperSlide key={`slide-carosello-${index}`}>
                <Grid container maxWidth={'1440px'} marginLeft={'auto'} marginRight={'auto'}>
                    <Grid item xs={12} lg={4}>
                        <MDBox display='flex' alt={profilo.titolo} component='img' bgColor='white' src={profilo.immagine} shadow='xxl' borderRadius='xl' height='300px' ml='auto' mr='auto'></MDBox>
                    </Grid>

                    <Grid item xs={12} lg={8} paddingLeft={'20px'} paddingRight={'20px'}>
                        <MDTypography variant='h4' mb={10} mt={5} textAlign={'center'}>
                            {profilo.titolo}
                        </MDTypography>
                        <MDTypography variant='string'>{profilo.testo}</MDTypography>
                    </Grid>
                </Grid>
            </SwiperSlide>
        )
    }

    return (
        <MDBox component='section' py={12}>
            <MDBox variant='gradient' bgColor='white' borderRadius='xxl' sx={{ borderBottom: 1, borderColor: 'divider' }} p={2} mb={3} ml='auto' mr='auto' width='max-content' display={{ xs: 'none', lg: 'flex' }}>
                <Tabs value={slide} aria-label='basic tabs example' onChange={(e, valore) => cambiaSlide(valore)}>
                    {profili.map((profilo) => {
                        return <Tab key={profilo.titolo} label={profilo.titolo} />
                    })}
                </Tabs>
            </MDBox>
            <Swiper
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                onSwiper={setSwiper}
                onInit={({ params, navigation }) => {
                    const { navigation: nav } = params

                    nav.prevEl = navigationPrevRef.current
                    nav.nextEl = navigationNextRef.current
                    navigation.init()
                    navigation.update()
                }}
                autoplay={{ delay: 5000 }}
                speed={800}
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={(e) => {
                    let valore = e.activeIndex - 1
                    if (e.activeIndex > 5) {
                        valore = 0
                    }
                    if (e.activeIndex < 1) {
                        valore = 4
                    }
                    setSlide(valore)
                }}
                loop>
                {profili.map((profilo, index) => {
                    return renderSlide(profilo, index)
                })}
            </Swiper>
        </MDBox>
    )
}

export default carosello
