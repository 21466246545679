import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { aggiungiPazienteSuUltimeRicerche } from 'getter/getterPaziente'
import { connect } from 'react-redux'
import { getPermessiUtente } from 'getter/getterPermessi'

export function elementoMostraPaziente(props) {
    const test = () => {
        if(props.azione){
            props.azione(props.paziente)
            return
        }
        aggiungiPazienteSuUltimeRicerche(props.paziente.id)
        let link = `/paginaVediPaziente/${props.paziente.id}/dashboardPaziente`
        /* if(!props.permessi){
            link = `/paginaVediPaziente/${props.paziente.id}/anagraficaPaziente`
        } */
        props.navigate(link)
    }

    return (
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
            <ListItemIcon>
                <AccountCircleRoundedIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={`${props.paziente.contenuto.cognome} ${props.paziente.contenuto.nome}`} secondary={props.paziente.contenuto.anno} />
            <ListItemIcon>
                <ArrowForwardIosRoundedIcon color='dark' />
            </ListItemIcon>
        </ListItem>
    )
}

function mapStateToProps(state){
    return {
        //permessi : getPermessiUtente().datiSingoloPaziente.output
    }
}

export default connect(mapStateToProps, null)(elementoMostraPaziente)
