import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import colors from 'assets/theme/base/colors'
import MDBox from 'components/MDBox'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import Slide from '@mui/material/Slide'
import ElementoAggiungiFile from 'componenti/elementoAggiungiFile'
import { ThumbDownAltOutlined } from '@mui/icons-material'
import { SALVA_SCAN_FATTURA } from 'data/azioni/SALVA_SCAN_FATTURA'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})
export default function popUpScanFattura(props) {
    const handleClose = () => {
        props.chiudi()
    }

    const salvaScan = async (immagine) => {

        await SALVA_SCAN_FATTURA(props.parametro, immagine)
    }

    return (
        <Dialog TransitionComponent={Transition} open={props.aperto} onClose={() => handleClose(false)} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle sx={{ color: colors.warning.main }} id='titolo-pop-up-field-list'>
                ATTENZIONE
            </DialogTitle>
            <DialogContent sx={{ color: colors.warning.main }}>
                <MDBox sx={{ margin: 'auto' }} width='200px' height='200px' p={2}>
                    <ErrorOutlineRoundedIcon sx={{ height: '100%', width: '100%' }} color='warning' />
                </MDBox>
                Continuando sovrascriverai la scannerizzazione fatta in precedenza, e non sarà piu' possibile recuperarla. Sei sicuro di voler procedere?
            </DialogContent>
            <DialogActions>
                <ElementoAggiungiFile isScanFattura={true} isPopUp={true} azione={(thumbnail, immagine) => salvaScan(immagine)} />
                <MDButton color='warning' variant='contained' onClick={() => handleClose(false)}>
                    no
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
