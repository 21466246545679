import { newId } from 'data/strumenti'
import { ref, uploadBytes } from 'firebase/storage'
import { doc, updateDoc, setDoc } from 'firebase/firestore'
import { getPresidio } from 'data/strumenti'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { creaEtichettaUniversale } from 'data/strumenti'
import { APRI_POP_UP } from './APRI_POP_UP'

export const SALVA_FILE_ESERCIZIO = async (file, idEsercizio) => {
    
    const idFile = newId()
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

    const salvaImmagine = async () => {
        const path = `esercizi/${idEsercizio}/${idFile}.png`
        await uploadBytes(ref(storage, path), file)
        return
    }

    const aggiornaCollection = async () => {
        await updateDoc(doc(db, 'esercizi', idEsercizio), { [`file.${idFile}`]: { etichettaUniversale: creaEtichettaUniversale(getPresidio(getUtenteLoggato())), isVideo: false } })
        return
    }

    //await salvaImmagine()
    //await aggiornaCollection()
    await Promise.all([salvaImmagine(),aggiornaCollection()])
    return
}
