import { trasformaOggettoInArray } from './../data/strumenti'
import { store } from './../data/store'
import { getIdUtenteLoggato, getProfiloUtenteLoggato, getUtenteLoggato } from './getterUtenteLoggato'
import { getDatiInformazioniLesione, getListaIdLesioni, getListaLesioni } from './getterLesioni'

export const getFarmaciPaziente = () => {
    var listaFarmaci = []
    const lesioni = getListaLesioni()
    lesioni.forEach((lesione) => {
        const informazioniLesione = getDatiInformazioniLesione(lesione.contenuto)
        var farmaciLesione = informazioniLesione === undefined ? [] : informazioniLesione.FarmaciUtilizzati
        farmaciLesione = farmaciLesione.map((farmaco)=>{
            return Object.assign(farmaco, {disfunzione : lesione.contenuto.titolo})
        })
        listaFarmaci = listaFarmaci.concat(farmaciLesione)
    })
    return listaFarmaci
}

export const getPaziente = () => {
    var paziente = {}
    if (getProfiloUtenteLoggato() === 'profilo-paziente') paziente = getUtenteLoggato()
    else paziente = store.getState().reducerPaziente?.contenuto || {}
    return paziente
}

export const getSkeleton = () => {
    const skeleton = store.getState().reducerPaziente === undefined ? true : store.getState().reducerPaziente?.skeleton
    return skeleton
}

export const getAccessoPaziente = () => {
    return store.getState().reducerPaziente?.credenziali || false
}

export const getIdPaziente = () => {
    if (getProfiloUtenteLoggato() === 'profilo-paziente') {
        return getIdUtenteLoggato()
    }
    if (store.getState().reducerPaziente === undefined) {
        return ''
    }
    return store.getState().reducerPaziente.id
}

export const getListaIdEserciziAssegnati = () => {
    return getEserciziPaziente().map((esercizio) => {
        return esercizio.idEsercizio
    })
}

export const getEserciziPaziente = () => {
    let paziente = getPaziente()
    let esercizi = trasformaOggettoInArray(paziente.esercizi).map((esercizio) => {
        let index = window.listaEsercizi.findIndex((es) => {
            return es.id === esercizio.contenuto.idEsercizio
        })
        return {
            data: esercizio.contenuto.etichettaUniversale.dataCreazione,
            idAssegnazione: esercizio.id,
            idEsercizio: esercizio.contenuto.idEsercizio,
            commento: esercizio.contenuto.commenti,
            titolo: window.listaEsercizi[index] !== undefined ? window.listaEsercizi[index].contenuto.titolo || '' : '',
        }
    })
    esercizi.sort((a, b) => {
        let dataA = a.data.seconds
        let dataB = b.data.seconds
        return dataB - dataA
    })
    return esercizi
}

export const getEserciziDashboard = () => {
    return getEserciziPaziente().filter((es, index) => {
        return index < 3
    })
}

export const getPazienteDaId = (id) => {
    let index = window.listaPazienti.findIndex((el) => {
        return el.id === id
    })
    return window.listaPazienti[index]
}
export const getNomePazienteDaId = (id) => {
    let paziente = getPazienteDaId(id)
    return `${paziente.contenuto.nome} ${paziente.contenuto.cognome}`
}

export const getPazienteLista = (id) => {
    let index = window.listaPazienti.findIndex((el) => {
        return el.id === id
    })
    if(index === -1 ) return ''
    let paziente = window.listaPazienti[index].contenuto
    paziente.link = `paginaVediPaziente/${id}/paginaVediAnagrafica`
    return paziente
}

const prendiFatture = () => {
    let paziente = getPaziente()
    let fatture = trasformaOggettoInArray(paziente.fatture)
    return fatture.sort((a, b) => {
        let numeroA = Number(a.contenuto?.numFattura?.split('-')[1] || 1)
        let numeroB = Number(b.contenuto?.numFattura?.split('-')[1] || 1)
        if (numeroA === numeroB) {
            numeroA = Number(a.contenuto?.numFattura?.split('-')[0] || 1)
            numeroB = Number(b.contenuto?.numFattura?.split('-')[0] || 1)
        }
        return numeroB - numeroA
    })
}

export const getFatturePaziente = () => {
    let fatture = prendiFatture()
    if (getProfiloUtenteLoggato() !== 'profilo-paziente') {
        return fatture
    }
    return fatture.filter((fattura) => {
        return fattura.contenuto.stato !== 'creata'
    })
}

export const getFattureDashboard = () => {
    return getFatturePaziente().filter((fat, index) => {
        return index < 3
    })
}

const cercaLocalStorage = () => {
    try {
        if ('localStorage' in window && window.localStorage !== null) {
            localStorage.setItem('testmyStorage', 'testmyStorage')
            if (localStorage.getItem('testmyStorage') !== 'testmyStorage') {
                localStorage.removeItem('testmyStorage')
                return false
            } else {
                localStorage.removeItem('testmyStorage')
                return true
            }
        } else {
            return false
        }
    } catch (ex) {
        return false
    }
}

const getUltimeRicerche = () => {
    if (!cercaLocalStorage()) {
        return []
    }
    let ultimeRicerche = []
    if (JSON.parse(localStorage.getItem('pazienti')) !== undefined && JSON.parse(localStorage.getItem('pazienti')) !== null) {
        ultimeRicerche = JSON.parse(localStorage.getItem('pazienti'))
    }
    let pazienti = []
    ultimeRicerche.forEach((id) => {
        let index = window.listaPazienti.findIndex((paziente) => {
            return paziente.id === id
        })
        if (index !== -1) {
            pazienti.push(window.listaPazienti[index])
        }
    })
    return pazienti
}

export const getListaPazientiFiltrati = (noCache) => {
    let pazientiFiltrati = []
    let nome = ''
    let cognome = ''
    let anno = ''
    if (store.getState().reducerFiltriCercaPaziente !== undefined) {
        let campi = store.getState().reducerFiltriCercaPaziente
        nome = campi.nome
        cognome = campi.cognome
        anno = campi.anno
    }

    if (nome === '' && cognome === '' && anno === '') {
        return {
            ultimeRicerche: true,
            errore: false,
            lista: noCache ? [] : getUltimeRicerche(),
            //lista: [],
        }
    }
    pazientiFiltrati = window.listaPazienti.filter((paziente) => {
        let comparazioneNome = () => {
            if (nome === '') {
                return true
            }
            let nomeCacheLowerCase = paziente.contenuto.nome.toLowerCase()
            let nomeDaCercareLowerCase = nome.toLowerCase()
            return nomeCacheLowerCase.indexOf(nomeDaCercareLowerCase) !== -1
        }
        let comparazioneCognome = () => {
            if (cognome === '') {
                return true
            }
            let cognomeCacheLowerCase = paziente.contenuto.cognome.toLowerCase()
            let cognomeDaCercareLowerCase = cognome.toLowerCase()
            return cognomeCacheLowerCase.indexOf(cognomeDaCercareLowerCase) !== -1
        }
        let comparazioneAnno = () => {
            if (anno.toString() === '') {
                return true
            }
            let annoCacheLowerCase = paziente.contenuto.anno.toString()
            let annoDaCercareLowerCase = anno.toString()
            return annoCacheLowerCase.indexOf(annoDaCercareLowerCase) !== -1
        }

        return comparazioneNome() && comparazioneCognome() && comparazioneAnno()
    })
    return {
        ultimeRicerche: false,
        errore: pazientiFiltrati.length === 0,
        lista: pazientiFiltrati,
    }
}

export const aggiungiPazienteSuUltimeRicerche = (idPaziente) => {
    let IsLocalStorage = cercaLocalStorage()
    let ultimiPazienti = IsLocalStorage ? JSON.parse(localStorage.getItem('pazienti')) : []
    if (!ultimiPazienti.includes(idPaziente)) {
        if (ultimiPazienti.length === 10) {
            ultimiPazienti.pop()
            ultimiPazienti.unshift(idPaziente)
        }
        if (ultimiPazienti.length < 10) {
            ultimiPazienti.push(idPaziente)
        }
    }
    if (ultimiPazienti.includes(idPaziente)) {
        let index = ultimiPazienti.findIndex((paziente) => {
            return paziente === idPaziente
        })
        ultimiPazienti.splice(index, 1)
        ultimiPazienti.unshift(idPaziente)
    }
    if (IsLocalStorage) {
        localStorage.setItem('pazienti', JSON.stringify(ultimiPazienti))
    }
}
