import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { OutputText } from './fieldText'

export function InputSelect(props) {
    let inputProps = props.model.details?.inputProps || {}

    

    let prova = {}
    if (props.model.details?.testoBianco) {
        inputProps.sx = { color: 'white !important' }
        prova = {
            '& .MuiInputLabel-root': {
                color: 'white !important',
            },
        }
    }

    return (
        <FormControl fullWidth error={props.errore !== ''} sx={prova}>
            <InputLabel id='select-label'>{props.model.label}</InputLabel>
            <Select
                labelId='select-label'
                inputProps={inputProps}
                label={props.model.label}
                color={props.model.details.color || 'primary'}
                variant={props.model.details.variant || 'outlined'}
                id={props.model.id}
                value={props.value}
                classes={props.model.details.classi}
                onChange={(e, valore) => {
                    props.azione(props.model.id, e.target.value)
                }}
                sx={props.model.details?.sx}
                style={{ height: 44.13 }}>
                {props.model.details.scelte.map((elem, index) => {
                    return (
                        <MenuItem key={index} value={props.model.details.complex ? elem[props.model.details.toSave] : elem}>
                            {props.model.details.complex ? elem[props.model.details.toShow] : elem}
                        </MenuItem>
                    )
                })}
            </Select>

            <FormHelperText>{props.errore}</FormHelperText>
        </FormControl>
    )
}

export function OutputSelect(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    const getValore = () => {
        if (!props.model.details.complex) {
            return props.value
        }
        const index = props.model.details.scelte.findIndex((elem) => {
            return elem[props.model.details.toSave] === props.value
        })

        if (index === -1) {
            return ''
        }
        return props.model.details.scelte[index][props.model.details.toShow]
    }

    return <OutputText skeleton={props.skeleton} model={model} value={getValore()} />
}
