import React, { useEffect, useState } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { formattaDataDaFirebase } from 'data/strumenti'
import { ref, getDownloadURL } from 'firebase/storage'

export default function elementoMostraConsensoInformato(props) {
    const [url, setUrl] = useState('')

    const test = () => {
        window.open(url)
    }

    useEffect(() => {
        let path = `documentazione/consensoInformato/${props.documento.id}/consensoInformato.pdf`
        var pathReference = ref(storage, path)
        getDownloadURL(pathReference).then((nuovoUrl) => {
            setUrl(nuovoUrl)
        })
    }, [])

    return (
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
            <ListItemIcon>
                <PictureAsPdfRoundedIcon color='dark' />
            </ListItemIcon>
            <ListItemText primary={`${props.documento.nomeLesione}-${formattaDataDaFirebase(props.documento.data)}`} />
            <ListItemIcon>
                <DownloadRoundedIcon color='dark' />
            </ListItemIcon>
        </ListItem>
    )
}
