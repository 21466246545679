import React from 'react'
import { connect } from 'react-redux'
import ElementoNavigazioneDisfunzione from 'componenti/elementoNavigazioneDisfunzione'
import MDBox from 'components/MDBox'
import { getSkeletonLesioni } from 'getter/getterLesioni'
import { getPermessiUtente } from 'getter/getterPermessi'
import { InputGestioneDolori } from 'fields/fieldGestioneDolori'
import { getInformazioniLesione } from 'getter/getterLesioni'
import { AGGIORNA_INFORMAZIONI_DISFUNZIONE } from 'data/azioni/AGGIORNA_INFORMAZIONI_DISFUNZIONE'

export const paginaDoloriDisfunzione = (props) => {
    const modelDolori = {
        id: 'dolori',
        tipo: 'fieldGestioneDolori',
        associazione: 'dolori',
        label: 'Dolori:',
        value: [],
        details: {
            grid: { lg: { input: 4, output: 4 } },
            costumCssOutput: ['labelBold'],
        },
    }

    const aggiornaDolori = async (dolori) => {
        await AGGIORNA_INFORMAZIONI_DISFUNZIONE(Object.assign(props.informazioni, { dolori: dolori }))
        return
    }

    return (
        <React.Fragment>
            <ElementoNavigazioneDisfunzione navigate={props.navigate} />
            <MDBox bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                <InputGestioneDolori model={modelDolori} value={props.informazioni.dolori || []} errore={''} azione={(a, b) => aggiornaDolori([...b])} permessi={props.permessi} />
            </MDBox>
        </React.Fragment>
    )
}

function stateChanged(state) {
    return {
        skeleton: getSkeletonLesioni(),
        permessi: getPermessiUtente().lesioniPaziente.input,
        informazioni: getInformazioniLesione() || {},
    }
}

export default connect(stateChanged, null)(paginaDoloriDisfunzione)
