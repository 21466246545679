import React, { useEffect } from 'react'
import ElementoNavigazioneDisfunzione from 'componenti/elementoNavigazioneDisfunzione'
import { connect } from 'react-redux'
import { getFIles } from 'getter/getterLesioni'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'
import { formattaDataDaFirebase } from 'data/strumenti'
import { getPermessiUtente } from 'getter/getterPermessi'
import ElementoAggiungiFile from 'componenti/elementoAggiungiFile'
import { getSkeletonLesioni } from 'getter/getterLesioni'
import { ref, getDownloadURL } from 'firebase/storage'
import ElementoVediListaFiles from 'componenti/elementoVediListaFiles'

export function paginaFilesDisfunzione(props) {
    const getUrl = () => {
        props.documenti.map((documento, index) => {
            let path = `documentazione/consensoInformato/${documento.id}/consensoInformato.pdf`
            return new Promise((resolve, reject) => {
                var pathReference = ref(storage, path)
                getDownloadURL(pathReference).then((url) => {
                    document.getElementById(`documento-${index}`).href = url
                })
            })
        })
    }

    useEffect(() => {
        getUrl()
    }, [props.documenti])

    const renderListaDocumenti = () => {
        if (props.documenti.length === 0) {
            return (
                <MDTypography color='error' varian='string'>
                    Nessun documento firmato
                </MDTypography>
            )
        }
        return (
            <div className='flex-a-capo'>
                {props.documenti.map((documento, index) => {
                    const id = `documento-${index}`
                    return (
                        <React.Fragment key={id}>
                            <Grid width='max-content' id={id} container direction='column' spacing={2} alignItems='center' justifyContent='center' component='a' target='blank' download sx={{ cursor: 'pointer' }} p={1} display='flex'>
                                <Grid item>
                                    <PictureAsPdfRoundedIcon color='dark' />
                                </Grid>
                                <Grid item>
                                    <MDTypography color='text' variant='caption'>
                                        {formattaDataDaFirebase(documento.data)}
                                    </MDTypography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }

    const renderFab = () => {
        if (!props.permessi) {
            return ''
        }
        return <ElementoAggiungiFile isFileDisfunzione={true} />
    }

    if (props.skeleton) {
        return (
            <MDBox sx={{ margin: 'auto', maxWidth: '1000px' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                <MDTypography color='info'>Caricamento in corso...</MDTypography>
            </MDBox>
        )
    }

    return (
        <React.Fragment>
            {renderFab()}
            <ElementoNavigazioneDisfunzione navigate={props.navigate} />
            <Grid container direction='column' spacing={3}>
                <Grid item>
                    <MDBox variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' mt={4}>
                        <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={2} mr={'auto'}>
                            <MDTypography color={'white'} variant='h5'>
                                Consensi Informati firmati :
                            </MDTypography>
                        </MDBox>
                        {renderListaDocumenti()}
                    </MDBox>
                </Grid>
                <ElementoVediListaFiles immagini={props.immagini} />
            </Grid>
        </React.Fragment>
    )
}

function mapStateToProps(state) {


    const dati = getFIles()
    return {
        immagini: dati.listaFiles,
        documenti: dati.documenti,
        permessi: getPermessiUtente().lesioniPaziente.input,
        skeleton: getSkeletonLesioni(),
    }
}

export default connect(mapStateToProps, null)(paginaFilesDisfunzione)
