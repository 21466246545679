import { getIdUtenteLoggato, getUtenteLoggato, getProfiloUtenteLoggato, getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio, trasformaOggettoInArray } from '../strumenti'
import { baseWatcher } from './baseWatcher'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import { getPermessiUtente } from 'getter/getterPermessi'

export class watcherAppuntamenti extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = []
        window.addEventListener('cercaAppuntamenti', (e) => {
            this.apriConnessione(e.detail)
        })
    }

    getDatiDaVedere(profilo) {
        let oggetto = {
            dottore: { attributo: 'idDottore', valore: getIdUtenteLoggato() },
            paziente: { attributo: 'idPaziente', valore: getIdUtenteLoggato() },
            segreteria: { attributo: 'idPresidio', valore: getPresidio(getUtenteLoggato()) },
        }
        const permessi = getPermessiUtente(getVeroUtenteLoggato())
        if (permessi.calendarioDipendenti) {
            return oggetto.segreteria   
        }
        return oggetto[profilo.split('-')[1]]
    }

    nuovaConnessione(mese) {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        const dati = this.getDatiDaVedere(getProfiloUtenteLoggato())

        const q = query(collection(db, 'appuntamenti'), where('data', '==', mese), where(dati.attributo, '==', dati.valore))

        return onSnapshot(q, (documenti) => {
            var appuntamenti = {}
            documenti.forEach((documento) => {
                appuntamenti[documento.id] = documento.data()
            })
            window.appuntamenti[mese] = trasformaOggettoInArray(appuntamenti)
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'appuntamenti' }))
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        })
    }

    apriConnessione(mesi) {
        this.funzioneConnessione = []
        mesi.forEach((mese) => {
            if (!this.idDaGuardare.includes(mese)) {
                this.idDaGuardare.push(mese)
                this.funzioneConnessione.push(this.nuovaConnessione(mese))
            }
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione.forEach((funzione) => funzione(''))
        }
    }
}
