import MDBox from 'components/MDBox'
import React from 'react'
import MDTypography from 'components/MDTypography'

export default function elementoVediNota(props) {
    return (
        <React.Fragment>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h5'>
                    Nota
                </MDTypography>
            </MDBox>
            <MDTypography variant='string' fontWeight='medium' sx={{whiteSpace : 'pre-line'}}>
                {props.value}
            </MDTypography>
        </React.Fragment>
    )
}
