import { getDatiPresidio } from '../../getter/getterPresidio'
import {CHIAMA_SERVER} from './CHIAMA_SERVER'

export const RISOLVI_PAGAMENTO_FALLITO = (nomeParametro) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    let daPassare = { customer: getDatiPresidio().idStripe, url: window.location.href }

    CHIAMA_SERVER(`creaPortale`, daPassare).then((session) => {
        window.location.assign(`${session}`)
    })
}
