import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import MDButton from 'components/MDButton'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MDBox from 'components/MDBox'
import ElementoCampiCercaEsercizio from 'componenti/elementoCampiCercaEsercizio'
import ElementoMostraEsercizi from 'componenti/elementoMostraEsercizi'
import { getListaIdEserciziAssegnati } from 'getter/getterPaziente'
import { InputTextArea } from 'fields/fieldTextArea'
import MDTypography from 'components/MDTypography'
import { AGGIUNGI_ESERCIZIO_DA_LIBRERIA } from 'data/azioni/AGGIUNGI_ESERCIZIO_DA_LIBRERIA'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { DialogActions } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpAssegnaEsercizioDaLibreria(props) {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }
    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)
    const [step, setStep] = useState(0)
    const steps = ['Seleziona esercizio', 'Posologia esercizio']
    const [esercizio, setEsercizio] = useState({})
    const [posologia, setPosologia] = useState('')
    const [errore, setErrore] = useState('')

    const model = {
        id: 'commenti',
        tipo: 'fieldTextArea',
        associazione: 'commenti',
        label: 'Posologia:',
        value: '',
    }

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
            //window.innerWidth < breakpoints.values.sm ? setOpzioni(opzioniMobile) : setOpzioni(opzioniStandard)
        })
    }, [])

    useEffect(() => {
        //console.log('arriva quaaaaaaa');
        if (props.parametro.esercizio !== undefined) {
            const esercizioDaAssegnare = { contenuto: { titolo: props.parametro.esercizio.titolo }, id: props.parametro.esercizio.id }
            //console.log('entraaaaaaaaa : ', esercizioDaAssegnare);
            setEsercizio(esercizioDaAssegnare)
            setPosologia(props.parametro.esercizio.posologia)
            setStep(1)
            //console.log("appostooooo");
        }
    }, [props.aperto])

    const handleClose = () => {
        props.chiudi()
    }

    const salva = async () => {
        setErrore('')
        if (posologia === '') {
            setErrore('Inserire una posologia')
            return
        }
        if (props.parametro.azione === undefined) {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
            await AGGIUNGI_ESERCIZIO_DA_LIBRERIA(esercizio, posologia)
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            handleClose()
            APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Esercizio assegnato!' } })
        }
        if (props.parametro.azione !== undefined) {
            props.parametro.azione({ esercizio: esercizio, posologia: posologia })
            handleClose()
        }
        setPosologia('')
        setEsercizio({})
        setStep(0)
    }

    const selezionaEsercizio = (e) => {
        console.log('guardaaaaa : ', e)
        setEsercizio(e)
        setStep(step + 1)
    }

    const renderBottoni = () => {
        if (step === 0) {
            return (
                <MDButton color='error' variant='contained' onClick={() => handleClose()}>
                    annulla
                </MDButton>
            )
        }
        return (
            <React.Fragment>
                <MDButton color='error' variant='contained' onClick={() => handleClose()}>
                    annulla
                </MDButton>
                <MDButton color='primary' variant='contained' onClick={() => setStep(step - 1)}>
                    indietro
                </MDButton>
                <MDButton color={props.parametro.azione === undefined ? "success" : 'primary'} variant='contained' onClick={() => salva()}>
                    {props.parametro.azione === undefined ? "avanti" : "aggiungi"}
                </MDButton>
            </React.Fragment>
        )
    }

    const renderPosologia = () => {
        return (
            <React.Fragment>
                <MDBox mt={2} mb={2} lineHeight={0}>
                    <MDTypography variant='caption' color='text'>
                        Esercizio selezionato :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                            {esercizio.contenuto.titolo}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <InputTextArea model={model} value={posologia} errore={errore} azione={(a, b) => setPosologia(b)} />
            </React.Fragment>
        )
    }

    const renderSceltaEsercizio = () => {
        return (
            <React.Fragment>
                <MDBox mt={2}>
                    <ElementoCampiCercaEsercizio />
                </MDBox>
                <MDBox mt={3}>
                    <ElementoMostraEsercizi eserciziPaziente={/* getListaIdEserciziAssegnati() */ []} azione={(e) => selezionaEsercizio(e)} />
                </MDBox>
            </React.Fragment>
        )
    }

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Assegna esercizio</DialogTitle>
            <DialogContent dividers={true}>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MDBox mt={3} sx={{ margin: 'auto' }}>
                    {step === 0 ? renderSceltaEsercizio() : renderPosologia()}
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDBox className='contenitore-bottoni-destra'>{renderBottoni()}</MDBox>
            </DialogActions>
        </Dialog>
    )
}
