import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../strumenti'
import { store } from './../store' 
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const AGGIUNGI_TAG = (tag) => {
    console.log('sei entrato')
    return new Promise((resolve, reject) => {
        CHIAMA_SERVER(`creaTag`, { tag: tag, presidio: getPresidio(getUtenteLoggato()) })
        resolve()
    })
}
