import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { trasformaOggettoInArray, getPresidio } from './../strumenti'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherListaDipendenti extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.controllaProfilo()
    }

    update() {
        if (store.getState().url[3] === 'paginaVediDottori') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    controllaProfilo() {
        if (store.getState().statoUtente.utente.profilo === 'profilo-super-amministratore') {
            store.subscribe(() => {
                this.update()
            })
            this.update()
        } else {
            let presidio = getPresidio(store.getState().statoUtente.utente)

            this.apriConnessione(presidio)
        }
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', nuovoId, 'cache', 'dipendenti'), (querySnapshot) => {
            let listaDipendenti = querySnapshot.data()
            window.listaDipendenti = trasformaOggettoInArray(listaDipendenti)
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'dipendenti' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
