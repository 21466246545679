import { getIdPaziente } from './getterPaziente'
import { getProfiloUtenteLoggato, getVeroUtenteLoggato } from './getterUtenteLoggato'
import MDAvatar from 'components/MDAvatar'
import Icon from '@mui/material/Icon'
import profilePicture from 'assets/images/team-3.jpg'
import { getIdLesioneSelezionata } from './getterLesioni'
import { LOG_OUT } from 'data/azioni/LOG_OUT'
import { isVersioneObsoleta } from './getterVersioni'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import ChairRoundedIcon from '@mui/icons-material/ChairRounded'
import ClassRoundedIcon from '@mui/icons-material/ClassRounded'
import FitnessCenterRoundedIcon from '@mui/icons-material/FitnessCenterRounded'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import EuroRoundedIcon from '@mui/icons-material/EuroRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'
import MedicalServicesRoundedIcon from '@mui/icons-material/MedicalServicesRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import { getPermessiUtente } from './getterPermessi'
import { getPresidio } from 'data/strumenti'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarViewMonthRounded';
import BoyRoundedIcon from '@mui/icons-material/BoyRounded';

const rotteMioProfilo = (presidio) => {
    //const isObsoleta = isVersioneObsoleta()
    const isObsoleta = false

    let link = [
        {
            name: 'Dashboard',
            key: 'paginaMioProfilo',
            route: '/paginaMioProfilo',
        },
        {
            name: 'Contattaci',
            key: 'paginaFeedBack',
            route: '/paginaFeedBack',
        },
        /* {
            name: isObsoleta ? 'Versione obsoleta' : 'Versione',
            key: 'PaginaVediVersione',
            route: '/PaginaVediVersione',
            error: isObsoleta,
            icon: <MDAvatar src={profilePicture} alt='Brooklyn ' size='sm' />,
        }, */
        {
            name: 'Esci',
            key: 'logout',
            route: '',
            azione: () => LOG_OUT(),
        },
    ]

    if (presidio === '' || getProfiloUtenteLoggato() === 'profilo-chiosco') {
        link = [
            {
                name: 'Esci',
                key: 'logout',
                route: '',
                azione: () => LOG_OUT(),
            },
        ]
    }
    return [
        {
            type: 'collapse',
            name: 'Mio profilo',
            key: 'mio-profilo',
            error: isObsoleta,
            icon: isObsoleta ? <PriorityHighRoundedIcon /> : <AccountCircleRoundedIcon />,
            collapse: link,
        },
        { type: 'divider', key: 'divider-mio-profilo' },
    ]
}

const rotteFatturazione = () => {
    const permessi = getPermessiUtente()
    if (!permessi.impostazioniTs && !permessi.fatturePresidio) {
        return []
    }

    const impostazioniTs = [
        {
            name: 'Configurazione',
            key: 'paginaConfigurazioneTs',
            route: '/paginaConfigurazioneTs',
        },
        {
            name: 'Utenze',
            key: 'paginaUtenzeTs',
            route: '/paginaUtenzeTs',
        },
    ]

    const fatturePresidio = [
        {
            name: 'Fatture',
            key: 'paginaFatturePresidio',
            route: '/paginaFatturePresidio',
        },
    ]

    let pagine = []
    if (permessi.impostazioniTs) {
        pagine = pagine.concat(impostazioniTs)
    }
    if (permessi.fatturePresidio) {
        pagine = pagine.concat(fatturePresidio)
    }

    return [
        {
            type: 'collapse',
            name: 'Fatturazione',
            key: 'fatturazione-presidio',
            icon: <EuroRoundedIcon />,
            collapse: pagine,
        },
        { type: 'divider', key: 'divider-fatturazione' },
    ]
}

const diarioClinico = (idPaziente) => {
    const permessi = getPermessiUtente()
    if (!permessi.lesioniPaziente.output) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Diario Clinico',
            key: 'diarioClinico',
            route: `/paginaVediPaziente/${idPaziente}/diarioClinico`,
            noCollapse: true,
            icon: <ClassRoundedIcon />,
            parteUrl: 2,
        },
    ]
}
const eserciziPaziente = (idPaziente) => {
    const permessi = getPermessiUtente()
    if (!permessi.eserciziPaziente.output) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Schede esercizi',
            key: 'eserciziPaziente',
            route: `/paginaVediPaziente/${idPaziente}/eserciziPaziente`,
            noCollapse: true,
            icon: <FitnessCenterRoundedIcon />,
            parteUrl: 2,
        },
       /*  {
            type: 'collapse',
            name: 'Form da compilare',
            key: 'paginaFormDaCompilare',
            route: `/paginaVediPaziente/${idPaziente}/paginaFormDaCompilare`,
            noCollapse: true,
            icon: <FitnessCenterRoundedIcon />,
            parteUrl: 2,
        }, */
        
    ]
}
const fatturePaziente = (idPaziente) => {
    const permessi = getPermessiUtente()
    if (!permessi.fatturePaziente.output) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Fatture',
            key: 'fatturePaziente',
            route: `/paginaVediPaziente/${idPaziente}/fatturePaziente`,
            noCollapse: true,
            icon: <CreditCardRoundedIcon />,
            parteUrl: 2,
        },
    ]
}
const lesioniPaziente = (idPaziente) => {
    const permessi = getPermessiUtente()
    if (!permessi.lesioniPaziente.output) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Disfunzioni',
            key: 'disfunzioniPaziente',
            route: `/paginaVediPaziente/${idPaziente}/disfunzioniPaziente`,
            noCollapse: true,
            icon: <FormatListBulletedRoundedIcon />,
            parteUrl: 2,
        },
    ]
}
const rotteDatiPaziente = (idPaziente) => {
    const permessi = getPermessiUtente()
    if (!permessi.datiSingoloPaziente.output) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Storia Clinica',
            key: 'storiaClinica',
            route: `/paginaVediPaziente/${idPaziente}/storiaClinica`,
            noCollapse: true,
            icon: <HistoryRoundedIcon />,
            parteUrl: 2,
        },
        {
            type: 'collapse',
            name: 'Abitudini',
            key: 'abitudiniPaziente',
            route: `/paginaVediPaziente/${idPaziente}/abitudiniPaziente`,
            noCollapse: true,
            icon: <ChairRoundedIcon />,
            parteUrl: 2,
        },
        {
            type: 'collapse',
            name: 'Files',
            key: 'filesPaziente',
            route: `/paginaVediPaziente/${idPaziente}/filesPaziente`,
            noCollapse: true,
            icon: <PictureAsPdfRoundedIcon />,
            parteUrl: 2,
        },
    ]
}
const dashBoardPaziente = (idPaziente) => {
    const permessi = getPermessiUtente()
    if (!permessi.datiSingoloPaziente.output) {
        return []
    }
    return {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboardPaziente',
        route: `/paginaVediPaziente/${idPaziente}/dashboardPaziente`,
        noCollapse: true,
        icon: <DashboardRoundedIcon />,
        parteUrl: 2,
    }
}

const rottePaziente = () => {
    const idPaziente = getIdPaziente()
    if (idPaziente === '') {
        return []
    }
    const titoloAnagrafica = [
        { type: 'title', title: 'Paziente', key: 'titoloPaziente' },
        {
            type: 'collapse',
            name: 'Dashboard',
            key: 'dashboardPaziente',
            route: `/paginaVediPaziente/${idPaziente}/dashboardPaziente`,
            noCollapse: true,
            icon: <DashboardRoundedIcon />,
            parteUrl: 2,
        },
        {
            type: 'collapse',
            name: 'Anagrafica',
            key: 'anagraficaPaziente',
            noCollapse: true,
            route: `/paginaVediPaziente/${idPaziente}/anagraficaPaziente`,
            noCollapse: true,
            icon: <MenuBookRoundedIcon />,
            parteUrl: 2,
        },
    ]
    const divisore = [{ type: 'divider', key: 'divider-paziente' }]
    return titoloAnagrafica.concat(rotteDatiPaziente(idPaziente).concat(diarioClinico(idPaziente).concat(eserciziPaziente(idPaziente).concat(fatturePaziente(idPaziente).concat(lesioniPaziente(idPaziente).concat(divisore))))))
}

const rotteDisfunzione = () => {
    const idLesione = getIdLesioneSelezionata()
    if (idLesione === '') {
        return []
    }
    const permessi = getPermessiUtente()
    if (!permessi.lesioniPaziente.output) {
        return []
    }
    const idPaziente = getIdPaziente()
    return [
        { type: 'title', title: 'Disfunzione', key: 'titoloDisfunzione' },
        {
            type: 'collapse',
            name: 'Home',
            key: `paginaHomeDisfunzione`,
            route: `/paginaVediPaziente/${idPaziente}/paginaVediLesione/${idLesione}/paginaHomeDisfunzione`,
            noCollapse: true,
            icon: <Icon fontSize='medium'>dashboard</Icon>,
            parteUrl: 4,
        },
        {
            type: 'collapse',
            name: 'Dolori',
            key: `paginaDoloriDisfunzione`,
            route: `/paginaVediPaziente/${idPaziente}/paginaVediLesione/${idLesione}/paginaDoloriDisfunzione`,
            noCollapse: true,
            icon: <BoyRoundedIcon />,
            parteUrl: 4,
        },
        {
            type: 'collapse',
            name: 'Informazioni',
            key: `paginaInformazioniDisfunzione`,
            route: `/paginaVediPaziente/${idPaziente}/paginaVediLesione/${idLesione}/paginaInformazioniDisfunzione`,
            noCollapse: true,
            icon: <InfoRoundedIcon />,
            parteUrl: 4,
        },
        {
            type: 'collapse',
            name: 'Files',
            key: `paginaFilesDisfunzione`,
            route: `/paginaVediPaziente/${idPaziente}/paginaVediLesione/${idLesione}/paginaFilesDisfunzione`,
            noCollapse: true,
            icon: <PictureAsPdfRoundedIcon />,
            parteUrl: 4,
        },
        {
            type: 'collapse',
            name: 'Sedute',
            key: 'paginaSeduteDisfunzione',
            route: `/paginaVediPaziente/${idPaziente}/paginaVediLesione/${idLesione}/paginaSeduteDisfunzione`,
            noCollapse: true,
            icon: <MedicalServicesRoundedIcon />,
            parteUrl: 4,
        },
    ]
}

const cercaPaziente = () => {
    const permessi = getPermessiUtente()
    if (!permessi.listaPazienti.output) {
        return {}
    }
    return {
        type: 'collapse',
        name: 'Cerca Paziente',
        key: 'paginaCercaPaziente',
        route: '/paginaCercaPaziente',
        noCollapse: true,
        icon: <SearchRoundedIcon sx={{ color: 'error' }} />,
        parteUrl: 0,
    }
}

const libreriaEsercizi = () => {
    const permessi = getPermessiUtente()
    if (!permessi.libreriaEsercizi.output) {
        return {}
    }
    if (getProfiloUtenteLoggato() === 'profilo-paziente') {
        return {}
    }
    return { type: 'collapse', name: 'Libreria Esercizi', key: 'paginaLibreriaEsercizi', route: '/paginaLibreriaEsercizi', noCollapse: true, icon: <MenuBookRoundedIcon />, parteUrl: 0 }
}

const rotteAmministrazione = () => {
    const permessi = getPermessiUtente()
    if (!permessi.listaDipendenti.input) {
        return {}
    }
    return { type: 'collapse', name: 'Dipendenti', key: 'paginaVediDipendenti', route: '/paginaVediDipendenti', noCollapse: true, icon: <FormatListBulletedRoundedIcon />, parteUrl: 0 }
}
const rotteSuperAmministratore = () => {
    const permessi = getPermessiUtente()
    if (!permessi.superAmministratore) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Presidi',
            key: 'paginaListaPresidi',
            route: '/paginaListaPresidi',
            noCollapse: true,
            icon: <FormatListBulletedRoundedIcon />,
            parteUrl: 0,
        },
        {
            type: 'collapse',
            name: 'Agenti',
            key: 'paginaListaAgenti',
            route: '/paginaListaAgenti',
            noCollapse: true,
            icon: <FormatListBulletedRoundedIcon />,
            parteUrl: 0,
        },
        {
            type: 'collapse',
            name: 'Feedback',
            key: 'paginaVediFeedback',
            route: '/paginaVediFeedback',
            noCollapse: true,
            icon: <FormatListBulletedRoundedIcon />,
            parteUrl: 0,
        },
    ]
}
const rotteAgente = () => {
    const permessi = getPermessiUtente()
    if (!permessi.agente) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Presidi',
            key: 'paginaListaPresidi',
            route: '/paginaListaPresidi',
            noCollapse: true,
            icon: <FormatListBulletedRoundedIcon />,
            parteUrl: 0,
        },
    ]
}

const rottaTutorial = () => {
    return {
        type: 'collapse',
        name: 'Formazione',
        key: 'paginaTutorial',
        route: '/paginaTutorial',
        noCollapse: true,
        icon: <PlayCircleOutlineRoundedIcon />,
        parteUrl: 0,
    }
}
const rotteCalendario = () => {
    const permessi = getPermessiUtente()
    if (!permessi.calendario) {
        return []
    }
    return [
        {
            type: 'collapse',
            name: 'Calendario',
            key: 'paginaCalendario',
            route: '/paginaCalendario',
            noCollapse: true,
            icon: <CalendarMonthRoundedIcon />,
            parteUrl: 0,
        },
    ]
}

export const getRotte = () => {
    const presidio = getPresidio(getVeroUtenteLoggato())
    const profilo = getProfiloUtenteLoggato()
    if (presidio === '' && profilo !== 'profilo-agente' && profilo !== 'profilo-super-amministratore') {
        return rotteMioProfilo(presidio)
    }
    let pagineBase = [cercaPaziente(), libreriaEsercizi(), rotteAmministrazione()].concat(rotteSuperAmministratore().concat(rotteAgente().concat(rotteCalendario())))
    pagineBase.push(rottaTutorial())
    return rotteMioProfilo().concat(rotteFatturazione().concat(pagineBase.concat(rottePaziente().concat(rotteDisfunzione()))))
}
