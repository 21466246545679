import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import { formFatture, formPaziente, formOutputModifica, formModificaFattura } from 'formModel/formFatture'
import VediForm from 'formMaker/vediForm'
import { getListaDottori } from 'getter/getterDipendenti'
import { getPaziente } from 'getter/getterPaziente'
import { getDatiPresidio } from 'getter/getterPresidio'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import { newId } from './../data/strumenti'
import { SALVA_FATTURA } from './../data/azioni/SALVA_FATTURA'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export function popUpFattura(props) {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }
    const [modelOutput, setModelOutput] = useState({ parts: [] })
    const [modelInput, setModelInput] = useState({ parts: [] })
    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)
    const [datiOutput, setDatiOutput] = useState({})

    const isNotEmessa = () => {
        if (props.parametro.idFattura === '') {
            return true
        }
        if (props.fattura.errore) {
            return true
        }
        return props.fattura.stato === 'creata'
    }

    const aggiornaModel = () => {
        const nonEmessa = isNotEmessa()
        const dottori = getListaDottori()
        let nuovoFormInput = nonEmessa ? formFatture(props.numFattura, dottori, props.defaultFatture) : formModificaFattura(dottori, props.defaultFatture)
        let nuovoFormOutput = nonEmessa ? formPaziente(props.paziente) : formOutputModifica(props.paziente, props.fattura)
        setModelInput(nuovoFormInput)
        setModelOutput(nuovoFormOutput)
    }

    const salva = async (dati) => {
        console.log('datiiiii : ', dati)
        let id = props.parametro.idFattura === '' ? newId() : props.parametro.idFattura
        let modifica = props.parametro.idFattura !== ''
        await SALVA_FATTURA(dati, id, modifica, props.parametro.idPaziente)
        handleClose()
    }

    const handleClose = () => {
        props.chiudi()
    }
    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
        })
        window.addEventListener('cache-cambiata', (e) => {
            if (e.detail === 'dipendenti') {
                aggiornaModel()
            }
        })
    }, [])
    useEffect(() => {
        aggiornaModel()
    }, [props.parametro, props.fattura, props.paziente, props.defaultFatture])

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>FATTURA</DialogTitle>
            <DialogContent dividers={true}>
                <MDBox display='flex' justifyContent='center' alignItems='center' p={5}>
                    <Grid container direction='column' justifyContent='center' spacing={5} gap={3}>
                       
                            <VediForm form={modelOutput} dati={{}} noBottoni={true} />
                        
                       
                            <VediForm form={modelInput} dati={props.fattura} vista='input' annulla={() => handleClose()} salva={(e) => salva(e)} />
                        
                    </Grid>
                </MDBox>
            </DialogContent>
        </Dialog>
    )
}

function mapStateToProps(state) {
    return {
        defaultFatture: state.reducerConfigurazioneBaseFatture || {},
        fattura: state.reducerFattura || {},
        paziente: getPaziente(),
        numFattura: getDatiPresidio().numFattura,
    }
}

export default connect(mapStateToProps, null)(popUpFattura)
