import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import DialogActions from '@mui/material/DialogActions'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import { Divider, Grid } from '@mui/material'
import { InputNumeroDocumento } from 'fields/fieldNumeroDocumento'
import { getDatiPresidio } from 'getter/getterPresidio'
import MDTypography from 'components/MDTypography'
import { InputText } from 'fields/fieldText'
import { validazioneNumeroDocumento } from 'fields/validator'
import { convertiDataInStringa } from 'data/strumenti'
import { convertiStringaInData } from 'data/strumenti'
import { newId } from 'data/strumenti'
import { SALVA_PDF_FATTURA } from 'data/azioni/SALVA_PDF_FATTURA'
import { CHIAMA_SERVER } from 'data/azioni/CHIAMA_SERVER'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export const popUpNotaDiCredito = (props) => {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }
    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)
    const [numeroDocumento, setNumeroDocumento] = useState(`01-${new Date().getFullYear()}`)
    const [rimborsi, setRimborsi] = useState([])
    const [errori, setErrori] = useState([])
    const [erroreDocumento, setErroreDocumento] = useState('')
    const [errore, setErrore] = useState('')

    const modelNumeroDocumento = {
        id: 'numFattura',
        tipo: 'fieldNumeroDocumento',
        associazione: 'numFattura',
        label: '',
        value: '',
    }

    const erroreVoce = (voce, rimborso) => {
        if (rimborso < 0 || rimborso > voce.importo) {
            return 'Importo non valido'
        }
        return ''
    }

    const salva = async () => {
        setErrore('')
        setErroreDocumento('')
        let daFermare = false
        const isErrore =
            rimborsi.filter((rimborso) => {
                return rimborso !== 0
            }).length === 0
        if (isErrore) {
            setErrore('Effettuare un rimborso')
            daFermare = true
        }
        let nuoviErrori = [...errori]
        const voci = props.voci.map((voce, index) => {
            nuoviErrori[index] = erroreVoce(voce, rimborsi[index])
            return {
                importo: rimborsi[index],
                descrizione: voce.descrizione,
                iva: voce.iva,
                tipoSpesa: voce.tipoSpesa,
            }
        })
        setErrori(nuoviErrori)
        const erroreVoci =
            nuoviErrori.filter((errore) => {
                return errore !== ''
            }).length !== 0
        if (erroreVoci) {
            daFermare = true
        }
        try {
            await validazioneNumeroDocumento(numeroDocumento)
        } catch (error) {
            setErroreDocumento(error)
            daFermare = true
        }
        if (daFermare) {
            return
        }
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        const oggetto = {
            numero: numeroDocumento,
            data: convertiDataInStringa(new Date()),
            voci: voci,
        }
        const data = convertiStringaInData(props.fattura.datiFattura.date)
        const idRimborso = newId()
        const dati = {
            idPresidio: props.fattura.etichettaUniversale.presidio,
            idPaziente: props.fattura.idPaziente,
            dataFattura: `${data.getFullYear()}-${data.getMonth() + 1}`,
            idChiamata: newId(),
            idFattura: props.parametro,
            idRimborso: idRimborso,
        }
        await SALVA_PDF_FATTURA(props.parametro, props.fattura, Object.assign(oggetto, { id: idRimborso }))
        await CHIAMA_SERVER(`rimborsaFattura`, { dati: dati, rimborso: oggetto })
        APRI_POP_UP({ id: 'alert', parametro: {severity : 'info', testo : 'Invio nota di credito in corso'} })
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    }

    useEffect(() => {
        setNumeroDocumento(`${props.numeroDocumento}-${new Date().getFullYear()}`)
    }, [props.numeroDocumento])
    useEffect(() => {
        const nuoviRimborsi = props.voci.map(() => {
            return 0
        })
        const nuoviErrori = props.voci.map(() => {
            return ''
        })
        setRimborsi(nuoviRimborsi)
        setErrori(nuoviErrori)
    }, [props.voci])

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
            //window.innerWidth < breakpoints.values.sm ? setOpzioni(opzioniMobile) : setOpzioni(opzioniStandard)
        })
    }, [])

    const getModel = (index, importo) => {
        return {
            id: `voce-${index}`,
            tipo: 'fieldText',
            associazione: `voce-${index}`,
            label: 'Rimborso :',
            value: 0,
            details: {
                type: 'number',
                inputProps: { max: importo, min: 0 },
            },
        }
    }

    const aggiornaRimborsi = (campo, valore) => {
        let index = Number(campo.split('-')[1])
        let nuoviRimborsi = [...rimborsi]
        nuoviRimborsi[index] = valore === '' ? o : valore
        setRimborsi(nuoviRimborsi)
    }

    const handleClose = () => {
        props.chiudi()
    }
    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Nuova nota di credito</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <InputNumeroDocumento model={modelNumeroDocumento} value={numeroDocumento} errore={erroreDocumento} azione={(a, b) => setNumeroDocumento(b)} />
                    </Grid>
                    {props.voci.map((voce, index) => {
                        return (
                            <React.Fragment>
                                <Grid item container spacing={2} xs={true} lg={true}>
                                    <Grid item xs={6} lg={6}>
                                        <MDTypography variant='string' color='text'>{`Importo : ${voce.importo}`}</MDTypography>
                                    </Grid>
                                    <Grid item xs={6} lg={6}>
                                        <InputText model={getModel(index, voce.importo)} value={rimborsi[index]} errore={errori[index]} azione={(a, b) => aggiornaRimborsi(a, b)} />
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <MDTypography variant='string' color='text'>
                                            {voce.descrizione}
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                                {index !== props.voci.length - 1 ? <Divider /> : ''}
                            </React.Fragment>
                        )
                    })}
                    <Grid item>
                        <MDTypography variant='string' color='error'>
                            {errore}
                        </MDTypography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton color='error' variant='contained' onClick={() => handleClose()}>
                    annulla
                </MDButton>
                <MDButton color='success' variant='contained' onClick={() => salva()}>
                    salva
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    return {
        voci: state.reducerFattura?.voci || [],
        fattura: state.reducerFattura || {},
        numeroDocumento: getDatiPresidio().numNoteCredito > 9 ? `${getDatiPresidio().numNoteCredito}` : `0${getDatiPresidio().numNoteCredito}`,
    }
}

export default connect(mapStateToProps, null)(popUpNotaDiCredito)
