import React, {useState, useEffect} from 'react'
import ElementoNavigazioneDisfunzione from 'componenti/elementoNavigazioneDisfunzione'
import { connect } from 'react-redux'
import { Grid, Icon } from '@mui/material'
import { getLesioneSelezionata } from 'getter/getterLesioni'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import ElementoMostraImmagine from 'componenti/elementoMostraImmagine'
import ElementoVediDiagnosi from 'componenti/elementoVediDiagnosi'
import ElementoVediStrategia from 'componenti/elementoVediStrategia'
import { getSkeletonLesioni } from 'getter/getterLesioni'
import { getPermessiUtente } from 'getter/getterPermessi'
import { InputGestioneDolori } from 'fields/fieldGestioneDolori'
import { getInformazioniLesione } from 'getter/getterLesioni'

export function paginaHomeDisfunzione(props) {
    const [dolori, setDolori] = useState([])

    useEffect(()=>{
        setDolori([... props.dolori])
    },[props.dolori])

    const renderModifica = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <MDBox
                onClick={() => {
                    APRI_POP_UP({ id: 'disfunzione', parametro: { nome: props.lesione.titolo, modifica: true } })
                }}
                sx={{ cursor: 'pointer' }}
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='3rem'
                height='3rem'
                bgColor='primary'
                color='white'
                shadow='md'
                borderRadius='xl'
                ml='auto'
                mr={1}>
                <Icon fontSize='medium' color='inherit'>
                    edit
                </Icon>
            </MDBox>
        )
    }

    const modelDolori =  {
        id: 'dolori',
        tipo: 'fieldGestioneDolori',
        associazione: 'dolori',
        label: 'Dolori:',
        value: [],
        details: {
            grid: { lg: { input: 4, output: 4 } },
            costumCssOutput: ['labelBold'],
        },
    }

    const renderRiassunto = () => {
        return (
            <MDBox bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' coloredShadow='primary' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                <MDBox display='grid' gap={1} gridTemplateColumns='1fr max-content'>
                    <MDBox lineHeight={0} ml={2} mb={1}>
                        <MDTypography color={'dark'} variant='h4'>
                            {props.lesione.titolo}
                        </MDTypography>
                    </MDBox>
                    {renderModifica()}
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant='caption' color='text'>
                        Data :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                            {props.lesione.data}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant='caption' color='text'>
                        Dottore :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                            {props.lesione.dottore}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize' color={props.lesione.documento ? 'success' : 'error'}>
                        {props.lesione.documento ? 'Consenso informato firmato' : 'Consenso informato NON firmato'}
                    </MDTypography>
                </MDBox>
                {props.lesione.bandiera ? (
                    <MDBox mr={0} ml='auto' display='flex' justifyContent='end'>
                        <FlagRoundedIcon height='50px' width='50px' color='error' />
                    </MDBox>
                ) : (
                    ''
                )}
            </MDBox>
        )
    }

    const renderFiles = () => {
        if (props.lesione.immagini.length === 0) {
            return <MDTypography color='error'>Nessun file inserito</MDTypography>
        }
        return (
            <div className='flex-a-capo'>
                {props.lesione.immagini.map((immagine, index) => {
                    return <ElementoMostraImmagine key={`immagine-${index}`} immagine={immagine} />
                })}
            </div>
        )
    }

    const renderStrategia = () => {
        if (Object.keys(props.lesione.ultimaStrategia).length === 0) {
            return (
                <React.Fragment>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                        <MDTypography color={'white'} variant='h5'>
                            Strategia
                        </MDTypography>
                    </MDBox>
                    <MDTypography color='error'>Nessuna strategia inserita</MDTypography>
                </React.Fragment>
            )
        }
        return <ElementoVediStrategia value={props.lesione.ultimaStrategia} navigate={props.navigate} />
    }
    const renderDiagnosi = () => {
        if (Object.keys(props.lesione.ultimaDiagnosi).length === 0) {
            return (
                <React.Fragment>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                        <MDTypography color={'white'} variant='h5'>
                            Diagnosi fisioterapica
                        </MDTypography>
                    </MDBox>
                    <MDTypography color='error'>Nessuna diagnosi fisioterapica inserita</MDTypography>
                </React.Fragment>
            )
        }
        return <ElementoVediDiagnosi value={props.lesione.ultimaDiagnosi} navigate={props.navigate} />
    }

    if (props.skeleton) {
        return (
            <MDBox sx={{ margin: 'auto', maxWidth: '1000px' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                <MDTypography color='info'>Caricamento in corso...</MDTypography>
            </MDBox>
        )
    }

    return (
        <React.Fragment>
            <ElementoNavigazioneDisfunzione navigate={props.navigate} />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <MDBox display='flex' flexDirection='column'>
                        {renderRiassunto()}
                        <MDBox bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                            {renderStrategia()}
                        </MDBox>
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <MDBox display='flex' flexDirection='column'>
                        <MDBox bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                            {renderDiagnosi()}
                        </MDBox>
                        <MDBox bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                                <MDTypography color={'white'} variant='h5'>
                                    Ultimi files inseriti
                                </MDTypography>
                            </MDBox>
                            {renderFiles()}
                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
            {/* <MDBox bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                <InputGestioneDolori model={modelDolori} value={dolori} errore={""} azione={(a,b)=>setDolori([... b])} />
            </MDBox> */}
        </React.Fragment>
    )
}
function mapStateToProps(state) {
    return {
        lesione: getLesioneSelezionata(),
        skeleton: getSkeletonLesioni(),
        permessi: getPermessiUtente().lesioniPaziente.input,
        dolori : getInformazioniLesione()?.dolori || []
    }
}

export default connect(mapStateToProps, null)(paginaHomeDisfunzione)
