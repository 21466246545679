import React, { useState, useEffect } from 'react'
import SignaturePad from 'signature_pad'
import Icon from '@mui/material/Icon'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import TuneIcon from '@mui/icons-material/Tune'
import Menu from '@mui/material/Menu'
import NotificationItem from 'examples/Items/NotificationItem'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { newId } from 'data/strumenti'

export default function elementoAssegnaBodyChart(props) {
    const [sfondo, setSfondo] = useState('frontale')
    const [elementiEliminati, setElementiEliminati] = useState([])
    const [altezza, setAltezza] = useState(400)
    const [pad, setPad] = useState({})
    const colori = ['rgb(204, 0, 0)', 'rgb(255, 148, 77)', 'rgb(255, 255, 0)', 'rgb(0, 230, 0)', 'rgb(0, 102, 255)', 'rgb(0, 0, 0)']
    const [coloreAttivo, setColoreAttivo] = useState(5)
    const [menuSfondo, setMenuSfondo] = useState(false)
    const [menuSvuota, setMenuSvuota] = useState(false)
    const apriMenuSfondo = (event) => setMenuSfondo(event.currentTarget)
    const chiudiMenuSfondo = () => setMenuSfondo(false)
    const apriMenuSvuota = (event) => setMenuSvuota(event.currentTarget)
    const chiudiMenuSvuota = () => setMenuSvuota(false)

    const creaPad = () => {
        let nuovoPad = new SignaturePad(document.getElementById('pad-body-chart'), {
            onEnd: () => {
                setElementiEliminati([])
            },
        })
        document.getElementById('pad-body-chart').addEventListener('handleMouseUp', (e) => {})
        console.log(nuovoPad)
        setPad(nuovoPad)
    }

    useEffect(() => {
        function getAltezza() {
            let nuovaLtezza = document.getElementById('contenitore-body-chart').clientWidth
            setAltezza(nuovaLtezza)
            return
        }
        window.addEventListener('resize', getAltezza)
        getAltezza()
        return () => window.removeEventListener('resize', getAltezza)
    }, [altezza])

    useEffect(() => {
        creaPad()
    }, [])
    const cambiaColore = (colore, index) => {
        setColoreAttivo(index)
        pad.penColor = colore
    }

    const undo = () => {
        var data = pad.toData()
        if (data.length > 0) {
            let nuovaLista = [...elementiEliminati]
            nuovaLista.unshift(data[data.length - 1])
            setElementiEliminati(nuovaLista)
            data.pop() // remove the last dot or line
            pad.fromData(data)
        }
    }

    const redo = () => {
        if (elementiEliminati.length === 0) {
            return
        }
        var data = pad.toData()
        if (data) {
            let nuovaLista = [...elementiEliminati]
            data.push(nuovaLista[0])
            nuovaLista.shift()
            setElementiEliminati(nuovaLista)
            pad.fromData(data)
        }
    }

    const cambiaSfondo = (sfondo) => {
        setSfondo(sfondo)
        chiudiMenuSfondo()
    }

    const svuotaBodyChart = (svuota) => {
        if (svuota) {
            setElementiEliminati([])
            pad.clear()
        }
        chiudiMenuSvuota()
    }

    const salva = () => {
        let valoreDaRitornare = {
            idNota : newId(),
            sfondo : sfondo,
            tipo: 'fieldNotaManuale',
            disegno: pad.toDataURL(),
        }
        props.salva(valoreDaRitornare)
    } 

    const renderSfondo = () => {
        if (sfondo === 'bianco') {
            return ''
        }
        if (sfondo === 'fronte') {
            return <img className='img-body-chart-output' src='/images/fronte.jpg' alt='Smiley face' />
        }
        if (sfondo === 'retro') {
            return <img className='img-body-chart-output' src='/images/retro.jpg' alt='Smiley face' />
        }
        if (sfondo === 'body') {
            return <img className='img-body-chart-output' src='/images/corpo.jpg' alt='Smiley face' />
        }
        if (sfondo === 'frontale') {
            return <img className='img-body-chart-output' src='/images/frontale.jpg' alt='Smiley face' />
        }
        if (sfondo === 'laterale') {
            return <img className='img-body-chart-output' src='/images/laterale.jpg' alt='Smiley face' />
        }
    }

    const renderColori = () => {
        return colori.map((colore, index) => {
            let selezionato = coloreAttivo === index
            return <div key={colore} class={`colore-body-chart ${selezionato ? 'colore-selezionato-body-chart' : ''}`} style={{ backgroundColor: colore }} onClick={(e) => cambiaColore(colore, index)}></div>
        })
    }

    const renderMenuSfondo = () => (
        <Menu
            anchorEl={menuSfondo}
            anchorReference={null}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(menuSfondo)}
            onClose={chiudiMenuSfondo}
            sx={{ mt: 2 }}>
            <MDTypography veriant='subTitle1' mb={1}>
                Cambia sfondo
            </MDTypography>
            <NotificationItem title='Sfondo Bianco' onClick={() => cambiaSfondo('bianco')} />
            <NotificationItem title='Frontale' onClick={() => cambiaSfondo('frontale')} />
            <NotificationItem title='Laterale' onClick={() => cambiaSfondo('laterale')} />
        </Menu>
    )
    const renderMenuSvuota = () => (
        <Menu
            anchorEl={menuSvuota}
            anchorReference={null}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(menuSvuota)}
            onClose={chiudiMenuSvuota}
            sx={{ mt: 2 }}>
            <MDTypography veriant='subTitle1' mb={1}>
                Vuoi svuotare la body chart?
            </MDTypography>
            <div className='contenitore-bottoni-destra'>
                <MDButton size='small' onClick={() => svuotaBodyChart(false)}>
                    No
                </MDButton>
                <MDButton size='small' onClick={() => svuotaBodyChart(true)}>
                    Si
                </MDButton>
            </div>
        </Menu>
    )

    return (
        <div className='fletti-verticalmente'>
            <div id='contenitore-body-chart'>
                {renderMenuSfondo()}
                {renderMenuSvuota()}
                <div id='parte-sopra-body-chart' className='comandi-body-chart'>
                    <Icon fontSize='large' sx={{ cursor: 'pointer' }} onClick={(e) => apriMenuSfondo(e)}>
                        <TuneIcon />
                    </Icon>
                    <div id='lista-colori-body-chart' className='centrato'>
                        {renderColori()}
                    </div>
                    <Icon fontSize='large' sx={{ cursor: 'pointer' }} color='error' onClick={(e) => apriMenuSvuota(e)}>
                        <CleaningServicesIcon />  
                    </Icon>
                </div>
                <div id='parte-centrale-body-chart' style={{ height: `${altezza}px` }}>
                    {renderSfondo()}
                    <canvas id='pad-body-chart' className='img-body-chart-output' width={`${altezza}px`} height={`${altezza}px`}></canvas>
                </div>
                <div id='parte-sotto-body-chart' className='comandi-body-chart'>
                    <Icon fontSize='large' sx={{ cursor: 'pointer' }} onClick={() => undo()}>
                        undo
                    </Icon>
                    <Icon fontSize='large' sx={{ cursor: 'pointer' }} color={elementiEliminati.length === 0 ? 'disabled' : 'inherit'} onClick={() => redo()}>
                        redo
                    </Icon>
                </div>
            </div>
            <div className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='error' onClick={() => props.chiudi()}>
                    annulla
                </MDButton>
                <MDButton variant='contained' color='success' onClick={() => salva()}>
                    salva
                </MDButton>
            </div>
        </div>
    )
}
