import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import Grid from '@mui/material/Grid'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import colors from 'assets/theme/base/colors'
import ElementoChartDolori from 'componenti/elementoChartDolori'
import DefaultCell from './../layouts/ecommerce/products/product-page/components/DefaultCell'
import DataTable from './../examples/Tables/DataTable'
import PopUpAggiungiDolore from 'popUp/popUpAggiungiDolore'
import RemoveIcon from '@mui/icons-material/Remove'
import { Skeleton } from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Checkbox from '@mui/material/Checkbox'
import isEqual from 'lodash-es/isEqual'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

const colonneTabella = [
    { Header: 'numero', accessor: 'numero', width: '20%' },
    { Header: 'distretto', accessor: 'distretto', width: '20%' },
    { Header: 'tipologia', accessor: 'tipologia', width: '20%' },
    { Header: 'intensita', accessor: 'intensita', width: '20%' },
]

const tabella = (dolori) => {
    const dati = {
        columns: colonneTabella,
        rows: dolori.map((dolore, index) => {
            return colonneTabella.reduce((acc, colonna, indiceColonna) => {
                let valore = ''
                if (colonna.accessor === 'numero') {
                    valore = index + 1
                }
                if (colonna.accessor !== 'numero') {
                    valore = dolore[colonna.accessor]
                }
                acc[colonna.Header] = (
                    <DefaultCell>
                        <MDTypography variant='caption' fontWeight='medium'>
                            {valore.toString()}
                        </MDTypography>
                    </DefaultCell>
                )

                return acc
            }, {})
        }),
    }

    return <DataTable table={dati} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} />
}

export function InputGestioneDolori(props) {
    const [focus, setFocus] = useState(false)
    const [popUpAperto, setPopUpAperto] = useState(false)
    const [distretto, setDistretto] = useState('')
    const [pointer, setPointer] = useState({ left: '0%', top: '0%' })
    const [indexSelezionato, setIndexSelezionato] = useState(-1)
    const [doloreSelezionato, setDoloreSelezionato] = useState({})
    const [numeroUpdateFatti, setNumeroUpdaeFatti] = useState(0)
    const [listaIndexDaVedere, setListaIndexDaVedere] = useState([])
    const [listaDolori, setListaDolori] = useState([])

    useEffect(
        (e) => {
            //if (numeroUpdateFatti < 2) {
            if (!isEqual(props.value, listaDolori)) {
                setListaIndexDaVedere(
                    props.value.map((dolore, index) => {
                        return index
                    }),
                )
                setListaDolori([...props.value])
                //setNumeroUpdaeFatti(numeroUpdateFatti + 1)
            }
        },
        [props.value],
    )

    const getColore = () => {
        if (focus) {
            return colors.primary.main
        }
        return props.errore !== '' ? colors.error : colors.inputBorderColor
    }

    const elimina = (index) => {
        let nuovaLista = [...props.value]
        nuovaLista.splice(index, 1)
        props.azione(props.model.id, nuovaLista)
    }

    const modifica = (index) => {
        setIndexSelezionato(index)
        setDoloreSelezionato(Object.assign(props.value[index]))
        setPopUpAperto(true)
    }

    const aggiungiDolore = (dolore) => {
        let nuovaLista = [...props.value]
        var index = -1
        if (indexSelezionato === -1) {
            nuovaLista.push(dolore)
            index = nuovaLista.length - 1
        } else {
            nuovaLista[indexSelezionato] = dolore
            index = indexSelezionato
        }
        props.azione(props.model.id, nuovaLista)
        setPopUpAperto(false)
        setIndexSelezionato(-1)
        setDoloreSelezionato({})
        if (!listaIndexDaVedere.includes(index)) {
            var nuovaListaIndex = [...listaIndexDaVedere]
            nuovaListaIndex.push(index)
            setListaIndexDaVedere(nuovaListaIndex)
        }
    }

    const checkBoxSchiacciato = (e, valore, index) => {
        var nuovaLista = [...listaIndexDaVedere]
        if (!valore) {
            const indice = nuovaLista.indexOf(index)
            nuovaLista.splice(indice, 1)
        }
        if (valore) {
            nuovaLista.push(index)
        }

        setListaIndexDaVedere(nuovaLista)
    }

    const creaTabella = () => {
        let colonne = [...colonneTabella]

        colonne.unshift({ Header: 'azioni', accessor: 'azioni' })

        const dati = {
            columns: colonne,
            rows: props.value.map((dolore, index) => {
                return colonne.reduce((acc, colonna, indiceColonna) => {
                    if (colonna.accessor === 'azioni') {
                        acc[colonna.Header] = (
                            <DefaultCell>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <Checkbox checked={listaIndexDaVedere.includes(index)} onChange={(e, valore) => checkBoxSchiacciato(e, valore, index)} />
                                    {props.permessi ? (
                                        <React.Fragment>
                                            <ClearRoundedIcon color='dark' sx={{ cursor: 'pointer' }} onClick={() => elimina(index)} />
                                            <EditRoundedIcon color='dark' sx={{ cursor: 'pointer' }} onClick={() => modifica(index)} />
                                        </React.Fragment>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </DefaultCell>
                        )
                    } else {
                        let valore = ''
                        if (colonna.accessor === 'numero') {
                            valore = index + 1
                        }
                        if (colonna.accessor !== 'numero') {
                            valore = dolore[colonna.accessor]
                        }
                        acc[colonna.Header] = (
                            <DefaultCell>
                                <MDTypography variant='caption' fontWeight='medium'>
                                    {valore.toString()}
                                </MDTypography>
                            </DefaultCell>
                        )
                    }
                    return acc
                }, {})
            }),
        }
        return <DataTable table={dati} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} />
    }

    const apriPopUpConPointer = (e) => {
        setPointer(e.pointer)
        setPopUpAperto(true)
        setDistretto(e.distretto)
    }

    const chiudiPopUp = () => {
        setPointer({ left: '0%', top: '0%' })
        setPopUpAperto(false)
        setDistretto('')
    }

    const renderBottone = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => setPopUpAperto(true)}>
                <AddIcon />
            </Fab>
        )
        return (
            <MDButton color='primary' variant='contained' onClick={() => setPopUpAperto(true)}>
                nuovo dolore
            </MDButton>
        )
    }

    return (
        <MDBox sx={{ border: 'solid 0.1px', color: getColore() }} p={2} borderRadius='lg' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
            <MDBox mt={-4.0} bgColor='white' p={0.5} width={'max-content'} sx={{ paddingTop: '0px' }}>
                <MDTypography gutterBottom sx={{ color: getColore() }} variant='caption'>
                    {props.model.label}
                </MDTypography>
            </MDBox>
            <PopUpAggiungiDolore aperto={popUpAperto} distretto={distretto} dolore={Object.assign(doloreSelezionato)} chiudi={() => chiudiPopUp()} salva={(e) => aggiungiDolore(e)} pointer={pointer} />
            <Grid container spacing={2} sx={{ position: 'relative' }}>
                <Grid item xs={12} lg={6}>
                    <MDBox sx={{ position: 'sticky' }}>
                        <ElementoChartDolori
                            input={false}
                            apriPopUp={(e) => apriPopUpConPointer(e)}
                            pointerGiaInseriti={props.value.map((dolore, index) => {
                                return { pointer: dolore.pointer, listaImmagini: dolore.listaDisegni || [], vediDisegno: listaIndexDaVedere.includes(index) }
                            })}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div className='fletti-verticalmente'>
                        <div className='fletti-orizzontalmente'>
                            <MDTypography variant='subtitle1' mr={3}>
                                Lista dolori :
                            </MDTypography>
                        </div>
                        {creaTabella(props.value)}
                        {renderBottone()}
                    </div>
                </Grid>
            </Grid>
        </MDBox>
    )
}

export function OutputGestioneDolori(props) {
    const [listaIndexDaVedere, setListaIndexDaVedere] = useState([])

    const checkBoxSchiacciato = (e, valore, index) => {
        var nuovaLista = [...listaIndexDaVedere]
        if (!valore) {
            const indice = nuovaLista.indexOf(index)
            nuovaLista.splice(indice, 1)
        }
        if (valore) {
            nuovaLista.push(index)
        }

        setListaIndexDaVedere(nuovaLista)
    }

    useEffect(() => {
        setListaIndexDaVedere(
            props.value.map((dolore, index) => {
                return index
            }),
        )
    }, [props.value])

    const tabellaOutput = (dolori) => {
        let colonne = [...colonneTabella]

        colonne.unshift({ Header: 'azioni', accessor: 'azioni' })
        const dati = {
            columns: colonne,
            rows: props.value.map((dolore, index) => {
                return colonne.reduce((acc, colonna, indiceColonna) => {
                    if (colonna.accessor === 'azioni') {
                        acc[colonna.Header] = (
                            <DefaultCell>
                                <Checkbox checked={listaIndexDaVedere.includes(index)} onChange={(e, valore) => checkBoxSchiacciato(e, valore, index)} />
                            </DefaultCell>
                        )
                    } else {
                        let valore = ''
                        if (colonna.accessor === 'numero') {
                            valore = index + 1
                        }
                        if (colonna.accessor !== 'numero') {
                            valore = dolore[colonna.accessor]
                        }
                        acc[colonna.Header] = (
                            <DefaultCell>
                                <MDTypography variant='caption' fontWeight='medium'>
                                    {valore.toString()}
                                </MDTypography>
                            </DefaultCell>
                        )
                    }
                    return acc
                }, {})
            }),
        }

        return <DataTable table={dati} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} />
    }

    const mostraDolori = () => {
        if (props.skeleton) {
            return <Skeleton sx={{ minHeight: '300px' }} animation='wave' width='100%' />
        }
        return tabellaOutput(props.value)
    }

    return (
        <React.Fragment>
            <MDTypography variant='string' fontWeight='medium'>
                {props.model.label}
            </MDTypography>

            <div className='wrapper-field-dolori'>
                {props.model.details?.nascondiCorpo ? (
                    ''
                ) : (
                    <ElementoChartDolori
                        input={false}
                        pointerGiaInseriti={props.value.map((dolore, index) => {
                            return { pointer: dolore.pointer, listaImmagini: dolore.listaDisegni || [], vediDisegno: listaIndexDaVedere.includes(index) }
                        })}
                    />
                )}
                <div className='fletti-verticalmente dolori'>
                    <MDTypography variant='subtitle1' mr={3}>
                        Lista dolori :
                    </MDTypography>
                    {mostraDolori()}
                </div>
            </div>
        </React.Fragment>
    )
}

/* 

    <div style={{width:"100%", background:"red",border:'solid green',overflow:'auto'}}> 
                    {this.renderForm()}
                    <div id="giovanni" style={{width:'800px',background:'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)'}}>  gio  </div>

                     </div>


*/
