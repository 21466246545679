import { store } from 'data/store'
import { getAuth, signOut } from 'firebase/auth'
import { watcherListaPazienti } from './../data/watchers/watcherListaPazienti'
import { watcherPaziente } from './../data/watchers/watcherPaziente'
import { reducerLesioni } from './../data/reducers/reducerLesioni'
import { reducerPaziente } from './../data/reducers/reducerPaziente'
import { reducerSedute } from './../data/reducers/reducerSedute'
import { reducerFiltriCercaPaziente } from './../data/reducers/reducerFiltriCercaPaziente'
import { reducerConfigurazioneBaseFatture } from './../data/reducers/reducerConfigurazioneBaseFatture'
import { reducerEsercizio } from './../data/reducers/reducerEsercizio'
import { reducerFattura } from './../data/reducers/reducerFattura'
import { reducerFiltriEsercizio } from './../data/reducers/reducerFiltriEsercizio'
import { reducerFiltriSedute } from './../data/reducers/reducerFiltriSedute'
import { reducerPresidio } from './../data/reducers/reducerPresidio'
import { reducerStatoModifica } from './../data/reducers/reducerStatoModifica'
import { reducerFiltriFatture } from './../data/reducers/reducerFiltriFatture'
import { reducerUtenzeTs } from './../data/reducers/reducerUtenzeTs'
import { reducerControlloAifi } from './../data/reducers/reducerControlloAifi'
import { reducerCredenzialiChiosco } from './../data/reducers/reducerCredenzialiChiosco'
import { reducerSingoloDottore } from './../data/reducers/reducerSingoloDottore'
import { reducerPopUpFieldList } from './../data/reducers/reducerPopUpFieldList'
import { reducerListaAgenti } from './../data/reducers/reducerListaAgenti'
import { reducerSchedePaziente } from './../data/reducers/reducerSchedePaziente'
import { reducerFeedback } from './../data/reducers/reducerFeedback'
import { reducerFormDaCompilarePaziente } from './../data/reducers/reducerFormDaCompilarePaziente'

import { watcherCachePresidi } from './../data/watchers/watcherCachePresidi'
import { watcherCacheFatturePresidio } from './../data/watchers/watcherCacheFatturePresidio'
import { watcherCercaTags } from './../data/watchers/watcherCercaTags'
import { watcherConfigurazioneBaseFatture } from './../data/watchers/watcherConfigurazioneBaseFatture'
import { watcherEsercizio } from './../data/watchers/watcherEsercizio'
import { watcherFattura } from './../data/watchers/watcherFattura'
import { watcherListaDipendenti } from './../data/watchers/watcherListaDipendenti'
import { watcherListaDipendentiPresidio } from './../data/watchers/watcherListaDipendentiPresidio'
import { watcherListaEsercizi } from './../data/watchers/watcherListaEsercizi'
import { watcherPresidio } from './../data/watchers/watcherPresidio'
import { watcherListaFatturePresidio } from './../data/watchers/watcherListaFatturePresidio'
import { watcherUtenzeTs } from './../data/watchers/watcherUtenzeTs'
import { watcherFattureDipendenti } from './../data/watchers/watcherFattureDipendenti'
import { watcherControlloAifi } from './../data/watchers/watcherControlloAifi'
import { watcherCredenzialiChiosco } from './../data/watchers/watcherCredenzialiChiosco'
import { watcherSingoloDottore } from './../data/watchers/watcherSingoloDottore'
import { watcherListaAmministrazioni } from './../data/watchers/watcherListaAmministrazioni'
import { watcherListaPresidiAgente } from './../data/watchers/watcherListaPresidiAgente'
import { watcherFeedback } from './../data/watchers/watcherFeedback'
import { watcherListaAgenti } from './../data/watchers/watcherListaAgenti'
import { watcherAppuntamenti } from './../data/watchers/watcheAppuntamenti'
import { getPresidio } from 'data/strumenti'
import { getPermessiUtente } from 'getter/getterPermessi'

export class inizializzatore {
    constructor() {
        this.utente = {}
        this.permessi = {}
        this.presidio = ''
        this.watchers - []
        this.daCaricare = true

        window.versioneAttuale = '2.3.4'
        store.dispatch({
            type: 'AGGIORNA_VERSIONE_ATTUALE',
        })

        store.addReducers({
            reducerStatoModifica,
            reducerPopUpFieldList,
        })
    }

    caricaModuli() {
        if (!this.daCaricare) {
            return
        }
        window.dispatchEvent(
            new CustomEvent('carica', {
                detail: 'node_modules/signature_pad/dist/signature_pad.min.js',
            }),
        )
        window.dispatchEvent(
            new CustomEvent('carica', {
                detail: 'node_modules/pdfmake/build/pdfmake.min.js',
            }),
        )

        setTimeout(() => {
            window.dispatchEvent(
                new CustomEvent('carica', {
                    detail: 'node_modules/pdfmake/build/vfs_fonts.js',
                }),
            )
        }, 1000)
        this.daCaricare = false
    }

    aggiorna(utente) {
        this.utente = utente
        this.presidio = getPresidio(utente) || ''
        this.permessi = getPermessiUtente(utente)
        this.watchers = this.getWatchers()
        this.caricaModuli()
    }

    getWatchers() {
        return this.permessiImpostazioniTs().concat(this.permessiFatturePresidio().concat(this.permessiListaPazienti().concat(this.permessiLibreriaEsercizi().concat(this.permessiDatiPresidio().concat(this.permessiDatiSingoloPaziente().concat(this.permessiLesioniPaziente().concat(this.permessiListaDipendenti().concat(this.permessiPresidi().concat(this.permessiTags().concat(this.permessiSuperAmministratore().concat(this.permessiAgente().concat(this.permessiCalendario()))))))))))))
    }

    permessiCalendario() {
        if (!this.permessi.calendario) {
            return []
        }
        return [new watcherAppuntamenti()]
    }

    permessiImpostazioniTs() {
        if (!this.permessi.impostazioniTs) {
            return []
        }
        if (this.presidio === '') {
            return []
        }
        store.addReducers({
            reducerConfigurazioneBaseFatture,
            reducerUtenzeTs,
        })
        return [new watcherUtenzeTs(), new watcherConfigurazioneBaseFatture()]
    }
    permessiFatturePresidio() {
        if (!this.permessi.fatturePresidio) {
            return []
        }
        store.addReducers({ reducerFattura, reducerFiltriFatture })
        return [new watcherListaFatturePresidio(), new watcherCacheFatturePresidio(), new watcherFattura()]
    }
    permessiListaPazienti() {
        if (!this.permessi.listaPazienti.output) {
            return []
        }
        store.addReducers({ reducerFiltriCercaPaziente })
        return [new watcherListaPazienti()]
    }
    permessiLibreriaEsercizi() {
        if (!this.permessi.libreriaEsercizi.output) {
            return []
        }
        if (!this.permessi.libreriaEsercizi.input) {
            store.addReducers({ reducerEsercizio})
            return [new watcherEsercizio(), new watcherListaEsercizi()]
        }
        store.addReducers({ reducerEsercizio, reducerFiltriEsercizio })
        return [new watcherEsercizio(), new watcherListaEsercizi()]
    }
    permessiDatiPresidio() {
        if (!this.permessi.datiPresidio.output) {
            return []
        }
        store.addReducers({ reducerPresidio })
        return [new watcherPresidio()]
    }
    permessiDatiSingoloPaziente() {
        if (!this.permessi.datiPresidio.output) {
            return []
        }
        store.addReducers({ reducerPaziente })
        return [new watcherPaziente()]
    }

    permessiLesioniPaziente() {
        if (this.permessi.lesioniPaziente.output) {
            store.addReducers({
                reducerLesioni,
                reducerSedute,
                reducerFiltriSedute,
                reducerFormDaCompilarePaziente,
                reducerSchedePaziente
            })
        }
        return []
    }

    permessiListaDipendenti() {
        if (this.presidio === '') {
            return []
        }
        if (this.permessi.listaDipendenti.input) {
            store.addReducers({ reducerControlloAifi, reducerCredenzialiChiosco, reducerSingoloDottore })
            return [new watcherListaDipendentiPresidio(), new watcherControlloAifi(), new watcherFattureDipendenti(), new watcherCredenzialiChiosco(), new watcherSingoloDottore()]
        }
        if (this.permessi.listaDipendenti.output) {
            return [new watcherListaDipendenti()]
        }
        return []
    }
    permessiPresidi() {
        if (this.permessi.listaPresidi) {
            return [new watcherCachePresidi()]
        }
        return []
    }
    permessiTags() {
        if (this.permessi.listaTags) {
            return [new watcherCercaTags()]
        }
        return []
    }

    permessiSuperAmministratore() {
        if (!this.permessi.superAmministratore) {
            return []
        }
        store.addReducers({ reducerListaAgenti, reducerFeedback })
        return [new watcherFeedback(), new watcherListaAmministrazioni(), new watcherListaDipendentiPresidio(), new watcherListaPazienti(), new watcherFattureDipendenti(), new watcherListaAgenti()]
    }
    permessiAgente() {
        if (!this.permessi.agente) {
            return []
        }
        //store.addReducers({  })
        return [new watcherListaPresidiAgente()]
    }

    logOut() {
        if (this.watchers === undefined) {
            this.watchers = []
            signOut(getAuth())
            return
        }
        Promise.all(
            this.watchers.map((classeWatchers) => {
                return classeWatchers.logOut()
            }),
        )
            .then(() => {
                this.watchers = []
                signOut(getAuth())
            })
            .catch((err) => {
                console.log(err)
            })
    }
}
