const initialState = {
    contenuto: {},
    skeleton: true,
}

export const reducerEsercizio = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_ESERCIZIO':
            return {
                contenuto: action.payload,
                skeleton: false,
            }
        case 'SVUOTA_REDUCER_ESERCIZIO':
            return { contenuto: {}, skeleton: true }
        /* case "CAMBIA_URL":
           break */
        default:
            return state
    }
}
