import ElementoAlert from 'popUp/elementoAlert'
import PopUpTerminiCondizioni from 'popUp/popUpTerminiCondizioni'
import PopUpDescrizioneBandiera from 'popUp/popUpDescrizioneBandiera'
import PopUpModificheNonSalvate from 'popUp/popUpModificheNonSalvate'
import PopUpAssegnaElementoSeduta from 'popUp/popUpAssegnaElementoSeduta'
import PopUpNuovoEsercizio from 'popUp/popUpNuovoEsercizio'
import PopUpFattura from 'popUp/popUpFattura'
import PopUpNuovaDisfunzione from 'popUp/popUpNuovaDisfunzione'
import PopUpVediDocumentiFattura from 'popUp/popUpVediDocumentiFattura'
import PopUpElimina from 'popUp/popUpElimina'
import PopUpModificaEsercizioAssegnato from 'popUp/popUpModificaEsercizioAssegnato'
import PopUpFiltraFatture from 'popUp/popUpFiltraFatture'
import PopUpUtenzaTs from 'popUp/popUpUtenzaTs'
import PopUpErrori from 'popUp/popUpErrori'
import PopUpInviaFattura from 'popUp/popUpInviaFattura'
import PopUpNotaDiCredito from 'popUp/popUpNotaDiCredito'
import PopUpFirmaDocumento from 'popUp/popUpFirmaDocumento'
import PopUpVediImmagine from 'popUp/popUpVediImmagine'
import PopUpAssegnaEsercizioDaLibreria from 'popUp/popUpAssegnaEsercizioDaLibreria'
import PopUpGestioneChiosco from 'popUp/popUpGestioneChiosco'
import PopUpGeneraCredenziali from 'popUp/popUpGeneraCredenziali'
import PopUpFieldList from 'popUp/popUpFieldList'
import PopUpNuovaSeduta from 'popUp/popUpNuovaSeduta'
import PopUpGuida from 'popUp/popUpGuida'
import PopUpPdfDisfunzione from 'popUp/popUpPdfDisfunzione'
import PopUpContattiDateTutorial from 'popUp/popUpContattiDateTutorial'
import PopUpNuovaDataTutorial from 'popUp/popUpNuovaDataTutorial'
import PopUpAppuntamento from 'popUp/popUpAppuntamento'
import PopUpScanFattura from 'popUp/popUpScanFattura'
import React from 'react'
import { connect } from 'react-redux'
import { CHIUDI_POP_UP } from './../data/azioni/CHIUDI_POP_UP'
import { getPermessiUtente } from 'getter/getterPermessi'

export const elementoMostraPopUp = (props) => {
    const renderPopUpLesioni = () => {
        if (props.permessi.lesioniPaziente.input) {
            return (
                <React.Fragment>
                    <PopUpDescrizioneBandiera aperto={props.popUpAperto === 'descrizioneBandiera'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpAssegnaElementoSeduta aperto={props.popUpAperto === 'elementoSeduta'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpNuovaDisfunzione aperto={props.popUpAperto === 'disfunzione'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpVediImmagine aperto={props.popUpAperto === 'vedi-file'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpNuovaSeduta aperto={props.popUpAperto === 'nuova-seduta'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpPdfDisfunzione aperto={props.popUpAperto === 'pdf-disfunzione'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                </React.Fragment>
            )
        }
        if (props.permessi.lesioniPaziente.output) {
            return (
                <React.Fragment>
                    <PopUpVediImmagine aperto={props.popUpAperto === 'vedi-file'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpDescrizioneBandiera aperto={props.popUpAperto === 'descrizioneBandiera'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                </React.Fragment>
            )
        }
        return ''
    }

    const renderPopUpFatture = () => {
        if (props.permessi.fatturePaziente.input) {
            return (
                <React.Fragment>
                    <PopUpFattura aperto={props.popUpAperto === 'fattura'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpVediDocumentiFattura aperto={props.popUpAperto === 'documenti-fattura'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpFiltraFatture aperto={props.popUpAperto === 'filtra-fatture'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpErrori aperto={props.popUpAperto === 'errori-fattura'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpInviaFattura aperto={props.popUpAperto === 'invia-fattura'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpNotaDiCredito aperto={props.popUpAperto === 'nuova-nota'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                    <PopUpScanFattura aperto={props.popUpAperto === 'scan-fattura'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                </React.Fragment>
            )
        }
        if (props.permessi.fatturePaziente.output) {
            return (
                <React.Fragment>
                    <PopUpVediDocumentiFattura aperto={props.popUpAperto === 'documenti-fattura'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                </React.Fragment>
            )
        }
        return ''
    }

    const renderPopUpEsercizi = () => {
        if (!props.permessi.eserciziPaziente.input) {
            return ''
        }
        return (
            <React.Fragment>
                <PopUpNuovoEsercizio aperto={props.popUpAperto === 'nuovoEsercizio'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                <PopUpModificaEsercizioAssegnato aperto={props.popUpAperto === 'posologia'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                <PopUpAssegnaEsercizioDaLibreria aperto={props.popUpAperto === 'assegna-esercizio'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            </React.Fragment>
        )
    }

    const renderPopUpImpostazioniTs = () => {
        if (!props.permessi.impostazioniTs) {
            return ''
        }
        return <PopUpUtenzaTs aperto={props.popUpAperto === 'utenza-ts'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
    }

    const renderPopUpAmministrazione = () => {
        if (!props.permessi.listaDipendenti.input) {
            return ''
        }
        return (
            <React.Fragment>
                <PopUpGestioneChiosco aperto={props.popUpAperto === 'chiosco'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            </React.Fragment>
        )
    }

    const renderPopUpDocumenti = () => {
        if (!props.permessi.documentiPaziente.input) {
            return ''
        }
        return (
            <React.Fragment>
                <PopUpFirmaDocumento aperto={props.popUpAperto === 'firma-documento'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
                <PopUpGeneraCredenziali aperto={props.popUpAperto === 'generaCredenziali'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            </React.Fragment>
        )
    }
    const renderSuperAmministratore = () => {
        if (!props.permessi.superAmministratore) {
            return ''
        }
        return (
            <React.Fragment>
                <PopUpNuovaDataTutorial aperto={props.popUpAperto === 'data-tutorial'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {renderPopUpLesioni()}
            {renderPopUpAmministrazione()}
            {renderPopUpFatture()}
            {renderPopUpEsercizi()}
            {renderPopUpImpostazioniTs()}
            {renderPopUpDocumenti()}
            {renderSuperAmministratore()}
            <ElementoAlert aperto={props.popUpAperto === 'alert'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            <PopUpTerminiCondizioni aperto={props.popUpAperto === 'accettaTerminiECondizioni'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            <PopUpElimina aperto={props.popUpAperto === 'elimina'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            <PopUpGuida aperto={props.popUpAperto === 'guida'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            <PopUpContattiDateTutorial aperto={props.popUpAperto === 'date-tutorial'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            <PopUpAppuntamento aperto={props.popUpAperto === 'appuntamento'} parametro={props.parametro} chiudi={() => CHIUDI_POP_UP()} />
            <PopUpFieldList />

            <PopUpModificheNonSalvate />
        </React.Fragment>
    )
}
function mapStateToProps(state) {
    return {
        popUpAperto: state.reducerPopUp.id,
        parametro: state.reducerPopUp.parametro,
        permessi: getPermessiUtente(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoMostraPopUp)
