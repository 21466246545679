import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import ElementoFiltraFatture from 'componenti/elementoFiltraFatture'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpFiltraFatture(props) {
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }

    
    const handleClose = () => {
        props.chiudi()
    }

    return (
        <Dialog sx={opzioniMobile} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>FILTRI FATTURE</DialogTitle>
            <DialogContent dividers={true}>
                <ElementoFiltraFatture annulla={() => handleClose()} />
            </DialogContent>
        </Dialog>
    )
}
