import { formUtente } from 'formModel/formUtente'
import { getDipendente } from 'getter/getterDipendenti'
import React from 'react'
import { connect } from 'react-redux'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import VediForm from 'formMaker/vediForm'
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded'
import { Grid } from '@mui/material'
import ElementoFattureDipendente from 'componenti/elementoFattureDipendente'
import ElementoGestioneAbbonamento from 'componenti/elementoGestioneAbbonamento'
import { AGGIORNA_DATI_UTENTE } from 'data/azioni/AGGIORNA_DATI_UTENTE'
import { formPermessiDipendente } from 'formModel/formPermessiDipendente'
import { getPermessiDipendente } from 'getter/getterDipendenti'
import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'

export const paginaVediDipendente = (props) => {
    const salva = async (e) => {
        return await AGGIORNA_DATI_UTENTE(e)
    }

    const salvaPermessi = (e) => {
        console.log('guardaaaaaaa : ', Object.assign(props.dati, { permessi: e }))
        salva(Object.assign(props.dati, { permessi: e }))
    }

    const renderAnagrafica = () => {
        const form = formUtente(props.dati.profilo, props.dati.tipoProfilo)
        return (
            <MDBox width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <MenuBookRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        Anagrafica Dipendente
                    </MDTypography>
                </MDBox>
                <VediForm form={form} dati={props.dati} salva={(e) => salva(e)} />
            </MDBox>
        )
    }

    const renderListaFatture = () => {
        return <ElementoFattureDipendente />
    }
    const renderAbbonamento = () => {
        return <ElementoGestioneAbbonamento />
    }
    const renderPermessi = () => {
        return (
            <MDBox id='roma' width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' display={'flex'} flexDirection={'column'}>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <MDTypography color={'white'} variant='h5'>
                        Permessi dipendente
                    </MDTypography>
                </MDBox>

                <MDBox flexGrow={1}>
                    <VediForm form={formPermessiDipendente(props.dati.profilo)} dati={props.permessiDipendente} salva={(e) => salvaPermessi(e)} />
                </MDBox>
            </MDBox>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
                {renderAnagrafica()}
            </Grid>
            <Grid item xs={12} lg={4}>
                {renderListaFatture()}
            </Grid>
            <Grid item xs={12} lg={6}>
                {renderAbbonamento()}
            </Grid>
            <Grid item xs={12} lg={6}>
                {renderPermessi()}
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state, ownProps) {
    const presidio = getPresidio(getVeroUtenteLoggato())
    if (presidio === undefined || presidio === '') {
        ownProps.navigate('/paginaInserimentoDati')
    }
    return {
        idDipendente: state.url[4],
        dati: getDipendente(),
        permessiDipendente: getPermessiDipendente(),
    }
}

export default connect(mapStateToProps, null)(paginaVediDipendente)
