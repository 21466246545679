import { getDoc, doc } from 'firebase/firestore'
import { store } from './../data/store'
import { formattaDataDaFirebase } from './../data/strumenti'

export const getNomeDipendente = (id) => {
    let listaDipendenti = window.listaDipendenti
    let index = listaDipendenti.findIndex((dot) => {
        return dot.id === id
    })
    return index !== -1 ? `${listaDipendenti[index].contenuto.nome} ${listaDipendenti[index].contenuto.cognome}` : ''
}

export const getListaDottori = () => {
    let dottori = []
    window.listaDipendenti.forEach((dipendente) => {
        if (dipendente.contenuto.profilo === 'profilo-dottore') {
            dottori.push({ id: dipendente.id, nome: `${dipendente.contenuto.nome} ${dipendente.contenuto.cognome}` })
        }
    })
    return dottori
}

export const getDipendente = () => {
    return store.getState().reducerSingoloDottore?.contenuto || {}
}

export const getProfiloDipendente = () => {
    return store.getState().reducerSingoloDottore.contenuto.profilo
}

export const getIdDipendente = () => {
    return store.getState().reducerSingoloDottore?.id || ''
}

export const getListaDipendenti = () => {
    let dottori = []
    let segreteria = []
    let chioschi = []
    window.listaDipendenti.forEach((dipendente) => {
        switch (dipendente.contenuto.profiloOriginale) {
            case 'profilo-dottore':
                dottori.push(dipendente)
                break
            case 'profilo-segreteria':
                segreteria.push(dipendente)
                break
            case 'profilo-chiosco':
                chioschi.push(dipendente)
                break
            default:
                break
        }
    })
    return { dottori, segreteria, chioschi }
}

export const getDipendenteDaId = (id) => {
    let index = window.listaDipendenti.findIndex((dip) => {
        return dip.id === id
    })
    if (index === -1) {
        return {}
    }
    return window.listaDipendenti[index]
}

export const isNuovoDottore = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    return dottore.contenuto?.sottoscrizione === undefined || false
}

export const isScaduto = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    return dottore.contenuto?.sottoscrizione?.scaduta || false
}

export const isSospeso = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    return dottore.contenuto?.sottoscrizione?.sospesa || false
}

export const isProvaGratuita = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    return dottore.contenuto?.sottoscrizione?.provaGratuita || false
}
export const isProvaGratuitaDisponibile = (idDipendente) => {
    if (getDipendenteDaId(idDipendente).contenuto.profilo === 'profilo-segreteria') {
        return false
    }
    if (!isNuovoDottore(idDipendente)) {
        return false
    }

    return (
        window.listaDipendenti.filter((dip) => {
            if (dip.contenuto.profilo === 'profilo-dottore') {
                return dip.contenuto.sottoscrizione !== undefined
            } else {
                return false
            }
        }).length < 2
    )
}
export const isAttivo = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    if (dottore === undefined) {
        return false
    }
    return dottore.contenuto?.attivo || false
}
export const isPagato = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    if (dottore === undefined) {
        return false
    }
    return dottore.contenuto?.pagato || false
}
export const isPagamentoFallito = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    if (dottore.contenuto.sottoscrizione?.errore === undefined) {
        return false
    }
    return dottore.contenuto.sottoscrizione.errore
}

export const getFineAbbonamento = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    return dottore.contenuto?.sottoscrizione === undefined ? '' : formattaDataDaFirebase(dottore.contenuto.sottoscrizione.fine)
}

export const getIdSottoscrizione = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    return dottore.contenuto?.sottoscrizione.id
}

export const isClienteAifi = (idDipendente) => {
    let dottore = getDipendenteDaId(idDipendente)
    return dottore.contenuto?.aifi || false
}

export const checkCodiceAifi = async (codice) => {
    let documentoCodiciAifi = await getDoc(doc(db, 'cache', 'codiciAifi'))
    let listaCodici = Object.keys(documentoCodiciAifi.data())
    if (listaCodici.includes(codice)) {
        return 'Il codice fiscale inserito risulta già utilizzato da un altro utente'
    }
    return ''
}

export const getListaFattureDipendente = (id) => {
    return window.listaFattureDipendenti.filter((fattura) => {
        if (id === '') {
            return true
        }
        return id === fattura.contenuto.idUtente
    })
}

export const getPermessiDipendente = () => {
    const permessi = getDipendente().permessi || {}
    return {
        impostazioniTs: permessi.impostazioniTs || false,
        fatturePresidio: permessi.fatturePresidio || false,
        amministrazione: permessi.amministrazione || false,
        fatturePaziente: permessi.fatturePaziente || false,
        calendarioDipendenti: permessi.calendarioDipendenti || false,
    }
}

export const getInformazioniAbbonamenti = () => {
    let totale = 0
    let dottori = 0
    let segreterie = 0
    let abbonamentiAttivi = 0
    let rinnoviSospesi = 0
    window.listaDipendenti.forEach((dipendente) => {
        if (dipendente.contenuto.profilo !== 'profilo-chiosco') {
            let profilo = dipendente.contenuto.profilo
            let prezzo = 0
            if (profilo === 'profilo-segreteria') {
                segreterie += 1
                prezzo = 150
            }
            if (profilo === 'profilo-dottore') {
                dottori += 1
                prezzo += isClienteAifi(dipendente.id) ? 250 : 350
            }
            if (!isNuovoDottore(dipendente.id) && !isScaduto(dipendente.id)) {
                abbonamentiAttivi += 1
                if (!isSospeso()) {
                    totale += prezzo
                }
                if (isSospeso()) {
                    rinnoviSospesi += 1
                }
            }
        }
    })
    return {
        dottori,
        segreterie,
        totale,
        abbonamentiAttivi,
        rinnoviSospesi,
    }
}
