import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ElementoSchedaPaziente from 'componenti/elementoSchedaPaziente'
import { getSchedaDaId } from 'getter/getterSchede'

export const paginaSchedaPaziente = (props) => {
    const [scheda, setScheda] = useState({})

    useEffect(() => {
        if (props.scheda !== undefined) {
            setScheda(Object.assign(props.scheda))
        }
    }, [props.scheda])

    return <ElementoSchedaPaziente output={false} scheda={scheda} navigate={props.navigate} />
}

function mapStateToProps(state, props) {
    if (state.storico[0] === 'CAMBIA_URL' || state.storico[0] === 'LEGGI_SCHEDE') {
        return {
            scheda: getSchedaDaId(state.url[6]),
        }
    }
}

export default connect(mapStateToProps, null)(paginaSchedaPaziente)
