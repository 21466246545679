const initialState = {
    titolo: '',
    tags: [],
}

export const reducerFiltriEsercizio = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'AGGIORNA_FILTRI_ESERCIZIO':
            let nuovoStato = Object.assign({}, state)
            nuovoStato[action.payload.campo] = action.payload.valore
            console.log('stato : ', nuovoStato)
            return nuovoStato
        case 'CAMBIA_URL':
            return  {
                titolo: '',
                tags: [],
            }
        default:
            return state
    }
}
