import { store } from 'data/store'
import { trasformaOggettoInArray } from 'data/strumenti'

export const getDateTutorial = () => {
    const state = store.getState()
    const lista = trasformaOggettoInArray(state.reducerDateTutorial || {})
    return lista.sort((a, b) => {
        let dataA = a.contenuto.data.seconds
        let dataB = b.contenuto.data.seconds
        return dataA - dataB
    })
}
