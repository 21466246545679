import { store } from './../data/store'
import { getDatiPresidio } from './getterPresidio'

export const getIdUtenteLoggato = () => {
    return store.getState().statoUtente?.id || ''
}

export const getProfiloUtenteLoggato = () => {
    return getUtenteLoggato().profilo
}
export const getTipoProfiloUtenteLoggato = () => {
    const utente = getUtenteLoggato()
    if (utente === undefined || utente.tipoProfilo === undefined || utente.tipoProfilo === '') {
        return 'fisioterapista'
    }
    return utente.tipoProfilo
}

export const getUtenteLoggato = () => {
    let utente = store.getState().statoUtente.utente || {}
    if (Object.keys(utente).length === 0) {
        return {}
    }
    if (utente.profilo === 'profilo-amministrazione-clinica') {
        return Object.assign(getDatiPresidio(), { profilo: 'profilo-amministrazione-clinica' })
    }
    return utente
}
export const getVeroUtenteLoggato = () => {
    let utente = store.getState().statoUtente.utente || {}
    if (Object.keys(utente).length === 0) {
        return {}
    }
    return utente
}

export const getLinkUtenteLoggato = () => {
    let paginePredefinite = [
        {
            testo: 'Mio Profilo',
            icona: 'svgNomeDottore',
            parametro: getUtenteLoggato().nome === undefined ? '' : `${getUtenteLoggato().nome[0]}`,
            parteUrl: 3,
            link: `paginaMioProfilo/paginaVediAnagrafica`,
            isPredefinita: false,
            vediVersione: true,
        },
    ]

    let daAggiungere = []
    switch (getProfiloUtenteLoggato()) {
        case 'profilo-dottore':
            daAggiungere = [
                {
                    testo: 'Cerca',
                    icona: 'svgLenteIngrandimento',
                    parteUrl: 3,
                    link: `paginaCercaPaziente`,
                    isPredefinita: true,
                },
                {
                    testo: 'Libreria',
                    icona: 'svgLibro',
                    parteUrl: 3,
                    link: `paginaLibreriaEsercizi`,
                    isPredefinita: false,
                },
            ]
            break
        case 'profilo-segreteria':
            daAggiungere = [
                {
                    testo: 'Cerca',
                    icona: 'svgLenteIngrandimento',
                    parteUrl: 3,
                    link: `paginaCercaPaziente`,
                    isPredefinita: true,
                },
            ]
            break
        case 'profilo-paziente':
            daAggiungere = [
                {
                    testo: 'Home',
                    icona: 'svgCasa',
                    parteUrl: 3,
                    link: ``,
                    isPredefinita: true,
                },
            ]
            break
        case 'profilo-amministrazione-clinica':
            paginePredefinite.shift()
            break
        default:
            break
    }
    /* if (getPermessiUtenteLoggato().length !== 0) {
        paginePredefinite.unshift({
            testo: 'Modalità',
            icona: 'svgFrecciaRicorsiva',
            parteUrl: 100,
            nonCambiarePagina: true,
        })
    } */
    return paginePredefinite.concat(daAggiungere)
}

export const getPermessiUtenteLoggato = () => {
    let utente = getVeroUtenteLoggato()
    if (utente.profiliAutorizzati === undefined || utente.profiliAutorizzati.length === 0) {
        return []
    }
    let profiloAttuale = getProfiloUtenteLoggato()
    if (profiloAttuale !== utente.profiloOriginale) {
        let profili = [...utente.profiliAutorizzati]
        profili.splice(profili.indexOf(profiloAttuale), 1)
        profili.push(utente.profiloOriginale)
        return profili
    }
    return utente.profiliAutorizzati
}
