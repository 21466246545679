import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import image from 'assets/images/logo_aifi.webp'
import MDButton from 'components/MDButton'

export default function convenzioneAifi() {
    //const image = 'https://images.unsplash.com/photo-1521668576204-57ae3afee860?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80'

    return (
        <MDBox component='section' position='relative' py={6}>
            <Container sx={{ maxWidth: '100% !important' }}>
                <Grid container item xs={10} sx={{ mx: 'auto' }}>
                    <MDBox variant='gradient' bgColor='dark' borderRadius='lg' width='100%' py={6}>
                        <Grid container>
                            <Grid item xs={12} md={6} xl={4} sx={{ position: 'relative', px: 6 }}>
                                <MDBox component='img' src={image} alt='image' borderRadius='md' maxWidth='300px' width='100%' position='relative' mt={-12} />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                xl={8}
                                sx={{
                                    position: 'relative',
                                    px: { xs: 6, md: 2 },
                                    mt: { xs: 3, md: 0 },
                                    my: { xs: 0, md: 'auto' },
                                    color: ({ palette: { white } }) => white.main,
                                }}>
                                <Icon fontSize='large'>format_quote</Icon>
                                <MDTypography variant='h4' color='white' fontWeight='light' mb={2} sx={{ fontWeight: 'bold' }}>
                                    Convenzionati con AIFI
                                </MDTypography>
                                <MDTypography variant='body2' color='white' fontWeight='light' mb={2} sx={{ fontSize: '1rem' }}>
                                    {appUtilizzata} Archive collabora con L’Associazione Italiana di Fisioterapia. I soci AIFI godono di una riduzione sul prezzo di listino.
                                </MDTypography>
                                <MDButton component='a' href={'https://aifi.net/'} target='_blank' rel='noreferrer' variant='gradient' color={'info'}>
                                    scopri aifi
                                </MDButton>
                            </Grid>
                            <Grid item xs={1} />
                        </Grid>
                    </MDBox>
                </Grid>
            </Container>
        </MDBox>
    )
}
