import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

export default function popUpErrori(props) {
    const handleClose = () => {
        props.chiudi()
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>ERRORE</DialogTitle>
            <DialogContent dividers={true}>
                <ul>
                    {props.parametro?.contenuto?.errori?.map((errore) => {
                        return (
                            <li>
                                <MDTypography color='text' variant='string'>
                                    {errore}
                                </MDTypography>
                            </li>
                        )
                    })}
                </ul>
            </DialogContent>
            <DialogActions>
                <MDButton color='primary' variant='text' onClick={handleClose}>
                    chiudi
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
