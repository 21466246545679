import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { store } from './../store'
import { collection, addDoc, Timestamp } from 'firebase/firestore'

export const INVIA_FEEDBACK = async (nomeParametro) => {
    nomeParametro.data = Timestamp.fromDate(new Date())
    nomeParametro.utente = getIdUtenteLoggato()
    nomeParametro.finito = false

    await addDoc(collection(db, 'feedBack'), nomeParametro)
    return
}
