const subInputFarmaciUtilizzati = {
    costumCssInput: ['formCambiata'],
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome farmaco',
            value: '',
            details: {
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'dataFine',
            tipo: 'fieldData',
            associazione: 'dataFine',
            label: 'Data fine',
            value: '',
            details: {},
        },
    ],
}
const subInputOspedalizzazione = {
    costumCssInput: ['formCambiata'],
    parts: [
        {
            id: 'tipo',
            tipo: 'fieldTextArea',
            associazione: 'tipo',
            label: 'Tipologia:',
            value: '',
            details: {
                costumCssOutput: ['labelBold'],
            },
        },
        {
            id: 'data',
            tipo: 'fieldData',
            associazione: 'data',
            label: 'Data:',
            value: '',
            details: {},
        },
        {
            id: 'luogo',
            tipo: 'fieldTextArea',
            associazione: 'luogo',
            label: 'Luogo:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
        },
        {
            id: 'durata',
            tipo: 'fieldTextArea',
            associazione: 'durata',
            label: 'Durata:',
            value: '',
            details: {
                type: 'text',
                costumCssOutput: ['labelBold'],
            },
        },
        {
            id: 'note',
            tipo: 'fieldTextArea',
            associazione: 'note',
            label: 'Note:',
            value: '',
            details: {
                costumCssOutput: ['labelBold'],
            },
        },
    ],
}
export const formInformazioniDisfunzioni = () => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            /*  {
                id: 'dolori',
                tipo: 'fieldGestioneDolori',
                associazione: 'dolori',
                label: 'Dolori:',
                value: [],
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    costumCssOutput: ['labelBold'],
                },
            }, */
            {
                id: 'note',
                tipo: 'fieldTextArea',
                associazione: 'note',
                label: 'Note:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },

            {
                id: 'aspettative',
                tipo: 'fieldText',
                associazione: 'aspettative',
                label: 'Aspettative:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'bandierina',
                tipo: 'fieldSceltaBandierina',
                associazione: 'bandierina',
                label: 'Bandiera:',
                value: { id: [], descrizione: '' },
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },

            {
                id: 'fattoriScatenanti',
                tipo: 'fieldListaSemplice',
                associazione: 'fattoriScatenanti',
                label: 'Fattori Scatenanti:',
                value: [],
                details: {
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                },
            },
            {
                id: 'fattoriAllevianti',
                tipo: 'fieldListaSemplice',
                associazione: 'fattoriAllevianti',
                label: 'Fattori Allevianti:',
                value: [],
                details: {
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                },
            },

            {
                id: 'TrattamentiPrecedenti',
                tipo: 'fieldListaSemplice',
                associazione: 'TrattamentiPrecedenti',
                label: 'Trattamenti Precedenti:',
                value: [],
                details: {
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                },
            },
            {
                id: 'trattamentiInEssere',
                tipo: 'fieldListaSemplice',
                associazione: 'trattamentiInEssere',
                label: 'Trattamenti in Essere:',
                value: [],
                details: {
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                },
            },
            {
                id: 'dataInsorgenza',
                tipo: 'fieldTextArea',
                associazione: 'dataInsorgenza',
                label: 'Insorgenza:',
                value: '',
                details: {
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                },
            },
            {
                id: 'FarmaciUtilizzati',
                tipo: 'fieldList',
                associazione: 'FarmaciUtilizzati',
                label: 'Farmaci Utilizzati:',
                value: [],
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                    subModel: subInputFarmaciUtilizzati,
                },
            },
            {
                id: 'ospedalizzazione',
                tipo: 'fieldList',
                associazione: 'ospedalizzazione',
                label: 'Ospedalizzazione:',
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputOspedalizzazione,
                    costumCssOutput: ['labelBold'],
                },
                value: [],
            },
        ],
    }
}
