import { getVersioni } from 'getter/getterVersioni'
import { isVersioneObsoleta } from 'getter/getterVersioni'
import React from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import TimelineList from 'examples/Timeline/TimelineList'
import TimelineItem from 'examples/Timeline/TimelineItem'
import { formattaDataDaFirebase } from 'data/strumenti'

export const paginaVediVersione = (props) => {
    const aggiorna = () => {
        navigator.serviceWorker.register('/serviceWorker.js').then((risposta) => {
            risposta.update().then(() => {
                window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
                setTimeout(() => {
                    location.reload()
                }, 3000)
            })
        })
    }

    const descrizione = (aggiornamenti) => {
        return (
            <MDBox mb={1} lineHeight={0}>
                <MDTypography variant='caption' color='text'>
                    Aggiornamenti :
                    <MDBox component='ul'>
                        {aggiornamenti.map((aggiornamento, index) => {
                            return (
                                <MDBox component='li' key={`aggiornamento-${index}`}>
                                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                                        {aggiornamento}
                                    </MDTypography>
                                </MDBox>
                            )
                        })}
                    </MDBox>
                </MDTypography>
            </MDBox>
        )
    }

    const renderVersioneObsoleta = () => {
        return (
            <React.Fragment>
                <MDTypography color='error' variant='h4'>
                    Versione obsoleta!
                </MDTypography>
                <MDButton color='primary' variant='contained' sx={{ mt: 2, width: '100%' }}>
                    aggiorna ora
                </MDButton>
            </React.Fragment>
        )
    }

    const getTitolo = (
        <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
            <MDTypography color={'white'} variant='h5'>
                Versioni
            </MDTypography>
        </MDBox>
    )

    const renderVersioni = () => {
        return (
            <React.Fragment>
                {getTitolo}
                <TimelineList title={''}>
                    {props.versioni.map((versione, index) => {
                        return <TimelineItem key={index} color='primary' icon='notifications' title={versione.contenuto.versione} dateTime={formattaDataDaFirebase(versione.contenuto.data)} description={descrizione(versione.contenuto.descrizione)} badges={['design']} />
                    })}
                </TimelineList>
            </React.Fragment>
        )
    }

    let opzioni = { variant: 'gradient', shadow: 'lg' }

    return (
        <MDBox {...opzioni} sx={{ maxWidth: '1000px' }} width='100%' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            {props.isObsoleta ? renderVersioneObsoleta() : renderVersioni()}
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        isObsoleta: isVersioneObsoleta(),
        versioni: getVersioni(),
    }
}

export default connect(mapStateToProps, null)(paginaVediVersione)
