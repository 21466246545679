export const formStrategia = () => {
    return {
        costumCssInput: ['inColonna'],
        parts: [
            {
                id: 'tag',
                tipo: 'fieldSceltaMultipla',
                associazione: 'tag',
                details: {
                    scelte: ['Neuromotoria', 'Terapia strumentale', 'Rinforzo muscolare', 'Mobilizzazione articolare', 'Miofasciale', 'Esercizi neurodinamici', 'Esercizi di controllo motorio', 'Trattamento della sensibilizzazione centrale', 'Trattamento del deficit di immagine corporeo'],
                    complex: false,
                    costumCss: ['tagModificatiSedute'],
                    costumCssOutput: ['labelBold'],
                },
                label: 'Strategia:',
                value: ['Miofasciale', 'Esercizi neurodinamici'],
                validazione: 'testoRichiesto',
            },
            {
                id: 'sintomi',
                tipo: 'fieldSceltaMultipla',
                associazione: 'sintomi',
                details: {
                    scelte: ['nausea', 'artralgia', 'mal di testa', 'febbre', 'dolori diffusi', 'dolori addominali', 'aumento stato infiammatorio', 'emicrania/cefalea', 'infezione', 'sonnolenza', 'aumento del dolore', 'sintomi neurovegetativi', 'mialgia', 'affaticamento'],
                    complex: false,
                    costumCssOutput: ['labelBold'],
                    costumCss: ['tagModificatiSedute'],
                },
                label: 'Rischi generici:',
                value: [],
                validazione: 'testoRichiesto',
            },
            {
                id: 'tagLibero',
                tipo: 'fieldTextArea',
                associazione: 'tagLibero',
                label: 'Tag Libero',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'descrizioneIntervento',
                tipo: 'fieldTextArea',
                associazione: 'descrizioneIntervento',
                label: 'Descrizione Intervento:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'rischiAssoluti',
                tipo: 'fieldTextArea',
                associazione: 'rischiAssoluti',
                label: 'Rischi Assoluti:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'testo',
                tipo: 'fieldTextArea',
                associazione: 'testo',
                label: 'Note:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
        ],
    }
}
