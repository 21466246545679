import React, { useEffect, useState } from 'react'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { getNomePazienteDaId } from 'getter/getterPaziente'
import { Grid, Icon } from '@mui/material'
import MDButton from 'components/MDButton'
import { getPresidio } from 'data/strumenti'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { convertiStringaInData } from 'data/strumenti'
import { SALVA_PDF_FATTURA } from 'data/azioni/SALVA_PDF_FATTURA'
import { EMETTI_FATTURA } from 'data/azioni/EMETTI_FATTURA'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import CircularProgress from '@mui/material/CircularProgress'
import { CHIAMA_SERVER } from 'data/azioni/CHIAMA_SERVER'
import { newId } from 'data/strumenti'
import { ref, getDownloadURL } from 'firebase/storage'
import ElementoAggiungiFile from './elementoAggiungiFile'
import { SALVA_SCAN_FATTURA } from 'data/azioni/SALVA_SCAN_FATTURA'
import { isScanFattuttura } from 'getter/getterFatture'
import DocumentScannerRoundedIcon from '@mui/icons-material/DocumentScannerRounded'

export default function elementoMostraFattura(props) {
    const [url, setUrl] = useState('')

    const getUrl = () => {
        let path = `fatture/${getPresidio(getUtenteLoggato())}/${props.fattura.id}/fattura_${props.fattura.contenuto.numFattura}.pdf`

        getDownloadURL(ref(storage, path)).then((url) => {
            setUrl(url)
        })
    }

    const scarica = () => {
        window.open(url)
    }

    useEffect(
        (e) => {
            if (url === '' && props.fattura.contenuto.stato !== undefined && props.fattura.contenuto.stato !== 'creata' && props.fattura.contenuto.rimborsi === undefined) {
                getUrl()
            }
        },
        [props.fattura],
    )

    const emetti = async () => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        let data = convertiStringaInData(props.fattura.contenuto.data)
        await SALVA_PDF_FATTURA(props.fattura.id)
        let totale = props.fattura.contenuto.marca ? props.fattura.contenuto.totale - 2 : props.fattura.contenuto.totale
        await EMETTI_FATTURA(props.fattura.id, props.fattura.contenuto.idPaziente, totale, `${data.getFullYear()}-${data.getMonth() + 1}`)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    }

    const vediDescrizione = (label, testo) => {
        return (
            <MDBox mb={1} lineHeight={0}>
                <MDTypography variant='caption' color='text'>
                    {`${label} :`}
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                        {testo}
                    </MDTypography>
                </MDTypography>
            </MDBox>
        )
    }

    const getColore = () => {
        if (props.fattura.contenuto.invioInCorso) {
            return '#fff500'
        }
        if (props.fattura.contenuto.errore) {
            return '#F44335'
        }
        switch (props.fattura.contenuto.stato) {
            case 'creata':
                return '#808080'
            case 'emessa':
                return '#ff9900'
            case 'inviata':
                return '#4CAF50'
            default:
                break
        }
    }

    const modificaFattura = () => {
        APRI_POP_UP({ id: 'fattura', parametro: { idFattura: props.fattura.id, idPaziente: props.fattura.contenuto.idPaziente } })
    }
    const cancellaDaTs = () => {
        APRI_POP_UP({ id: 'elimina', parametro: { azione: () => CHIAMA_SERVER('eliminaFattura', { idFattura: props.fattura.id, idChiamata: newId() }), testo: 'Sei sicuro di voler eliminare la fttura dal sistema tessera sanitaria?' } })
    }
    const nuovaNota = () => {
        APRI_POP_UP({ id: 'nuova-nota', parametro: props.fattura.id })
    }

    const inviaFattura = () => {
        APRI_POP_UP({ id: 'invia-fattura', parametro: props.fattura.id })
    }
    const vediErrori = () => {
        if (!props.fattura.contenuto.errore) {
            return
        }
        APRI_POP_UP({ id: 'errori-fattura', parametro: props.fattura })
    }

    const scannerizzaFattura = async (immagine) => {
        await SALVA_SCAN_FATTURA(props.fattura, immagine)
    }

    const apriPopUpScan = () => {
        APRI_POP_UP({ id: 'scan-fattura', parametro: props.fattura })
    }

    const renderStato = () => {
        let attributi = {
            backgroundColor: getColore(),
        }
        if (props.fattura.contenuto.errore) {
            attributi.cursor = 'pointer'
        }
        return (
            <MDBox onClick={() => vediErrori()} sx={attributi} display='flex' justifyContent='center' alignItems='center' width='2rem' height='2rem' color='white' borderRadius='xl'>
                {props.fattura.contenuto.invioInCorso ? <CircularProgress color='dark' size='1rem' /> : props.fattura.contenuto.errore ? <PriorityHighRoundedIcon /> : ''}
            </MDBox>
        )
    }

    const renderBottoneScan = () => {
        if (isScanFattuttura(props.fattura.contenuto)) {
            return (
                <MDButton size='small' variant='text' color='primary' onClick={() => apriPopUpScan()}>
                    <DocumentScannerRoundedIcon sx={{ mr: 1 }} />
                    scannerizza fattura
                </MDButton>
            )
        }

        return <ElementoAggiungiFile isScanFattura={true} index={props.index} azione={(thumbnail, immagine) => scannerizzaFattura(immagine)} />
    }

    const renderBottoniDownload = () => {
        if (isScanFattuttura(props.fattura.contenuto) || props.fattura.contenuto.rimborsi !== undefined) {
            return (
                <MDButton size='small' variant='text' color='primary' onClick={() => APRI_POP_UP({ id: 'documenti-fattura', parametro: props.fattura })}>
                    <VisibilityIcon sx={{ mr: 1 }} />
                    vedi documenti
                </MDButton>
            )
        }
        return (
            <MDButton size='small' variant='text' color='primary' onClick={() => scarica()}>
                <Icon sx={{ mr: 1 }}>download</Icon>
                scarica
            </MDButton>
        )
    }

    const renderBottoni = () => {
        if (props.fattura.contenuto.invioInCorso) {
            return renderBottoniDownload()
        }

        switch (props.fattura.contenuto.stato) {
            //switch (stato) {
            case 'creata':
                return (
                    <React.Fragment>
                        <MDButton size='small' variant='text' color='primary' onClick={() => modificaFattura()}>
                            <Icon sx={{ mr: 1 }}>edit</Icon>
                            modifica
                        </MDButton>
                        <MDButton size='small' variant='text' color='primary' onClick={() => emetti()}>
                            <Icon sx={{ mr: 1 }}>edit</Icon>
                            emetti
                        </MDButton>
                    </React.Fragment>
                )
            case 'emessa':
                if (props.fattura.contenuto.errore) {
                    return (
                        <React.Fragment>
                            {renderBottoniDownload()}

                            <MDButton size='small' variant='text' color='primary' onClick={() => modificaFattura()}>
                                <Icon sx={{ mr: 1 }}>edit</Icon>
                                modifica
                            </MDButton>
                            <MDButton size='small' variant='text' color='primary' onClick={() => inviaFattura()}>
                                <Icon sx={{ mr: 1 }}>send</Icon>
                                Invio TS
                            </MDButton>
                            {renderBottoneScan()}
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        {renderBottoniDownload()}

                        <MDButton size='small' variant='text' color='primary' onClick={() => inviaFattura()}>
                            <Icon sx={{ mr: 1 }}>send</Icon>
                            Invio TS
                        </MDButton>

                        {renderBottoneScan()}
                        {/* <MDButton size='small' variant='text' color='primary' onClick={() => modificaFattura()}>
                            <Icon sx={{ mr: 1 }}>edit</Icon>
                            modifica
                        </MDButton> */}
                    </React.Fragment>
                )

            case 'inviata':
                if (props.fattura.contenuto.rimborsi !== undefined) {
                    return (
                        <React.Fragment>
                            {renderBottoniDownload()}
                            <MDButton size='small' variant='text' color='primary' onClick={() => nuovaNota()}>
                                <Icon sx={{ mr: 1 }}>add</Icon>
                                Nota di credito
                            </MDButton>
                            {renderBottoneScan()}
                        </React.Fragment>
                    )
                }

                return (
                    <React.Fragment>
                        {renderBottoniDownload()}

                        <MDButton size='small' variant='text' color='error' onClick={() => cancellaDaTs()}>
                            <Icon sx={{ mr: 1 }}>delete</Icon>
                            elimina
                        </MDButton>
                        <MDButton size='small' variant='text' color='primary' onClick={() => modificaFattura()}>
                            <Icon sx={{ mr: 1 }}>edit</Icon>
                            modifica
                        </MDButton>
                        <MDButton size='small' variant='text' color='primary' onClick={() => nuovaNota()}>
                            <Icon sx={{ mr: 1 }}>add</Icon>
                            Nota di credito
                        </MDButton>
                        {renderBottoneScan()}
                    </React.Fragment>
                )

            default:
                return (
                    <React.Fragment>
                        {renderBottoniDownload()}

                        <MDButton size='small' variant='text' color='primary' onClick={() => inviaFattura()}>
                            <Icon sx={{ mr: 1 }}>send</Icon>
                            Invio TS
                        </MDButton>
                        {renderBottoneScan()}
                    </React.Fragment>
                )
        }
    }

    return (
        <MDBox bgColor='white' borderRadius='lg' p={2} height='100%' width='100%'>
            <Grid container spacing={1}>
                <Grid item container xs={8} lg={8}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={3} lg={3}>
                            {renderStato()}
                        </Grid>
                        <Grid item xs={9} lg={9}>
                            <MDTypography variant='h4' fontWeight='bold'>
                                {props.fattura.contenuto.numFattura}
                            </MDTypography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {vediDescrizione('Data', props.fattura.contenuto.data)}
                    </Grid>
                    <Grid item xs={12}>
                        {vediDescrizione('Totale', props.fattura.contenuto.totale)}
                    </Grid>
                    <Grid item xs={12}>
                        {vediDescrizione('Paziente', getNomePazienteDaId(props.fattura.contenuto.idPaziente))}
                    </Grid>
                </Grid>
                <Grid item container xs={4} lg={4} spacing={1} direction='column' justifyContent='center' alignItems='end'>
                    {renderBottoni()}
                </Grid>
            </Grid>
        </MDBox>
    )
}
