import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { LEGGI_PAZIENTE } from './../azioni/LEGGI_PAZIENTE'
import { LEGGI_CREDENZIALI } from './../azioni/LEGGI_CREDENZIALI'
import { LEGGI_LESIONI } from './../azioni/LEGGI_LESIONI'
import { LEGGI_FORM_DA_COMPILARE_PAZIENTE } from './../azioni/LEGGI_FORM_DA_COMPILARE_PAZIENTE'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { doc, onSnapshot, query, collection, where, Timestamp } from 'firebase/firestore'
import { LEGGI_SCHEDE } from 'data/azioni/LEGGI_SCHEDE'
import { trasformaOggettoInArray } from 'data/strumenti'


export class watcherPaziente extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.isPaziente = false
        this.controllaProfilo()
        window.addEventListener('getPaziente', (e) => {
            this.scegliAzioni(this.idDaGuardare, e.detail)
        })
    }

    controllaProfilo() {
        if (store.getState().statoUtente.utente.profilo === 'profilo-paziente') {
            this.isPaziente = true

            this.apriConnessione(store.getState().statoUtente.id)
        } else {
            this.isPaziente = false
            store.subscribe(() => {
                this.update()
            })
            this.update()
        }
    }

    update() {
        if (store.getState().url[3] === 'paginaVediPaziente') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    apriConnessione(nuovoId) {
        store.dispatch({
            type: 'SVUOTA_PAZIENTE',
        })
        if (!this.isPaziente) {
            this.funzioneConnessione = onSnapshot(doc(db, 'utenti', nuovoId), (documento) => {
                var paziente = {}
                paziente.id = documento.id
                paziente.contenuto = documento.data()

                LEGGI_PAZIENTE(paziente)
            })

            this.funzioneLeggiCredenzialiAccesso = onSnapshot(doc(db, 'utenti', nuovoId, 'datiLogIn', 'dati'), (doc) => {
                LEGGI_CREDENZIALI(doc.exists())
            })
        }

        if (getProfiloUtenteLoggato() !== 'profilo-segreteria') {            
            const q = query(collection(db, 'utenti', nuovoId, 'lesioni'))
            this.funzionePrendiLesioni = onSnapshot(q, (querySnapshot) => {
                var lesioni = {}
                querySnapshot.forEach((lesione) => {
                    lesioni[lesione.id] = lesione.data()
                })
                LEGGI_LESIONI(lesioni)
            })

            var oggi = new Date()
            /* oggi.setHours(0)
            oggi.setMinutes(0)
            oggi.setSeconds(0) */
            oggi.setDate(oggi.getDate() - 1)
            const qSchede = query(collection(db, 'utenti', nuovoId, 'schede'), where('datiGenerali.fine', '>=', oggi))
            this.funzionePrendiSchede = onSnapshot(qSchede, (querySnapshot) => {
                var schede = {}
                querySnapshot.forEach((scheda) => {
                    schede[scheda.id] = scheda.data()
                })
                LEGGI_SCHEDE(schede)
            })
           /*  this.funzionePrendiFormDaCompilare = onSnapshot(doc(db, 'utenti', nuovoId, 'formDaCompilare', 'form'), (querySnapshot) => {
                const listaForm = listaPazienti = trasformaOggettoInArray(querySnapshot.data())
                LEGGI_FORM_DA_COMPILARE_PAZIENTE(listaForm)
            }) */
        }
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            if (!this.isPaziente) {
                this.funzioneConnessione()
                this.funzioneLeggiCredenzialiAccesso()
            }
            if (this.funzionePrendiLesioni !== undefined) {
                this.funzionePrendiLesioni()
                this.funzionePrendiSchede()
            }
        }
    }
}
