const subInputParti = {
    costumCssOutput: ['subOutputParti'],
    parts: [
        {
            id: 'naturale',
            tipo: 'fieldText',
            associazione: 'naturale',
            label: 'Parti naturali:',
            value: '',
            details: {},
        },
        {
            id: 'cesareo',
            tipo: 'fieldText',
            associazione: 'cesareo',
            label: 'Parti cesarei:',
            value: '',
            details: {},
        },
    ],
}

const subInputMalDiTesta = {
    costumCssInput: ['sinistraPiccolo', 'spostaSopra'],
    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Mal di testa',
            value: '',
            details: {
                grid: { lg: { input: 1.5, output: 1 } },
                scelte: ['si', 'no'],
                complex: false,
                costumCssOutput: ['subInputNoPadding'],
                costumCss: ['paddinSopra'],
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Descrizione:',
            value: '',
            details: {
                grid: { lg: { input: 2.5, output: 3 } },
                type: 'text',
                costumCssOutput: ['subInputNoPadding'],
            },
        },
    ],
}
const subInputSvenimenti = {
    costumCssInput: ['sinistraPiccolo', 'spostaSopra'],
    parts: [
        {
            id: 'booleano',
            tipo: 'fieldSelect',
            associazione: 'booleano',
            label: 'Svenimenti :',
            value: '',
            details: {
                grid: { lg: { input: 1, output: 1 } },
                scelte: ['si', 'no'],
                complex: false,
                costumCssOutput: ['subInputNoPadding'],
                costumCss: ['paddinSopra'],
            },
        },
        {
            id: 'descrizione',
            tipo: 'fieldText',
            associazione: 'descrizione',
            label: 'Descrizione:',
            value: '',
            details: {
                grid: { lg: { input: 3, output: 3 } },
                type: 'text',
                costumCssOutput: ['subInputNoPadding'],
            },
        },
    ],
}

export const formStoriaClinicaPaziente = () => {
    return {
        costumCssOutput: ['griglia'],
        parts: [
            {
                id: 'qualitaSonno',
                tipo: 'fieldTextArea',
                associazione: 'qualitaSonno',
                label: 'Qualita del Sonno:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'oreSonno',
                tipo: 'fieldTextArea',
                associazione: 'oreSonno',
                label: 'Ore di sonno medie:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'cicatriciImportnanti',
                tipo: 'fieldListaSemplice',
                associazione: 'cicatriciImportnanti',
                label: 'Cicatrici Importanti:',
                value: [],
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'malDiTesta',
                tipo: 'fieldSubInput',
                associazione: 'malDiTesta',
                label: 'Mal di testa:',
                details: {
                    subModel: subInputMalDiTesta,
                    costumCssOutput: ['labelBold'],
                },
                value: {},
            },
            {
                id: 'svenimenti',
                tipo: 'fieldSubInput',
                associazione: 'svenimenti',
                label: 'Svenimenti :',
                details: {
                    subModel: subInputSvenimenti,
                    costumCssOutput: ['labelBold'],
                },
                value: {},
            },
            {
                id: 'fattoriPsicologici',
                tipo: 'fieldTextArea',
                associazione: 'fattoriPsicologici',
                label: 'Fattori Psicologici:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'incontinenza',
                tipo: 'fieldTextArea',
                associazione: 'incontinenza',
                label: 'Incontinenza:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'defecazione',
                tipo: 'fieldTextArea',
                associazione: 'defecazione',
                label: 'Minzione e Defecazione:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'problemiVisivi',
                tipo: 'fieldTextArea',
                associazione: 'problemiVisivi',
                label: 'Problemi Visivi:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'atm',
                tipo: 'fieldTextArea',
                associazione: 'atm',
                label: 'Problemi ATM:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'traumi',
                tipo: 'fieldListaSemplice',
                associazione: 'traumi',
                label: 'Traumi:',
                value: [],
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'interventi',
                tipo: 'fieldListaSemplice',
                associazione: 'interventi',
                label: 'Interventi Chirurgici:',
                value: [],
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'patologieSistemiche',
                tipo: 'fieldListaSemplice',
                associazione: 'patologieSistemiche',
                label: 'Patologie Sistemiche:',
                value: [],
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'pressione',
                tipo: 'fieldText',
                associazione: 'pressione',
                label: 'Pressione:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'saturazione',
                tipo: 'fieldText',
                associazione: 'saturazione',
                label: 'Saturazione:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'allergie',
                tipo: 'fieldListaSemplice',
                associazione: 'allergie',
                label: 'Allergie:',
                value: [],
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'parti',
                tipo: 'fieldSubInput',
                associazione: 'parti',
                label: 'Parti:',
                value: {},
                details: {
                    subModel: subInputParti,
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'familiarita',
                tipo: 'fieldTextArea',
                associazione: 'familiarita',
                label: 'Familiarità:',
                value: '',
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'medicoDF',
                tipo: 'fieldTextArea',
                associazione: 'medicoDF',
                label: 'Medico di Famiglia:',
                value: '',
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'esamiSangue',
                tipo: 'fieldTextArea',
                associazione: 'esamiSangue',
                label: 'Esami del sangue:',
                value: '',
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
            
            {
                id: 'udito',
                tipo: 'fieldTextArea',
                associazione: 'udito',
                label: 'Udito:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'farmaciUtilizzati',
                tipo: 'fieldFarmaciStoriaClinica',
                associazione: 'farmaciUtilizzati',
                label: 'Farmaci Utilizzati:',
                value: [],
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    costumCssOutput: ['formOrizzontale', 'labelBold'],
                },
            },
        ],
    }
}
