const initialState = []

export const reducerUtenzeTs = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_UTENZE_TS':
            return action.payload
        /* case "CAMBIA_URL":
           break */
        default:
            return state
    }
}
