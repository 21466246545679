import { getAppuntamenti } from 'getter/getterAppuntamenti'
import { getListaFatture } from 'getter/getterFatture'
import React, { Component } from 'react'

export const eventiCache = () =>
    class extends Component {
        constructor(props) {
            super(props)
            this.state = {
                listaPresidiWindow: [],
                listaAmministrazioniWindow: [],
                listaTagsWindow: [],
                listaDipendentiWindow: [],
                listaEserciziWindow: [],
                listaPazientiWindow: [],
                listaImmaginiWindow: {},
                cacheFatureWindow: {},
                listaFattureWindow: [],
                listaAppuntamentiWindow: [],
                listaFattureDipendentiWindow: [],
            }
        }

        componentDidMount() {
            window.addEventListener('cache-cambiata', (e) => {
                this.cacheCambiata(e.detail)
            })

            this.inizializzaListe()
        }

        inizializzaListe() {
            this.setState({
                listaPresidiWindow: window.listaPresidi,
                listaAmministrazioniWindow: window.listaAmministrazioni,
                listaTagsWindow: window.listaTags,
                listaDipendentiWindow: window.listaDipendenti,
                listaEserciziWindow: window.listaEsercizi,
                listaPazientiWindow: window.listaPazienti,
                listaImmaginiWindow: window.listaImmaginiProfilo,
                cacheFatureWindow: getListaFatture(),
                listaFattureWindow: window.cacheFatture,
                listaFattureDipendentiWindow: window.listaFattureDipendenti,
                listaAppuntamentiWindow: getAppuntamenti(),
            })
        }

        cacheCambiata(tipo) {
            switch (tipo) {
                case 'presidi':
                    this.setState({ listaPresidiWindow: window.listaPresidi })
                    this.presidiAggiornati()
                    break
                case 'amministrazioni':
                    this.setState({ listaAmministrazioniWindow: window.listaAmministrazioni })
                    this.amministazioniAggiornati()
                    break
                case 'appuntamenti':
                    this.setState({ listaAppuntamentiWindow: getAppuntamenti() })
                    this.appuntamentiAggiornati()
                    break
                case 'tags':
                    this.setState({ listaTagsWindow: window.listaTags })
                    this.tagsAggiornati()
                    break
                case 'dipendenti':
                    this.setState({ listaDipendentiWindow: window.listaDipendenti })
                    this.dipendentiAggiornati()
                    break
                case 'esercizi':
                    this.setState({ listaEserciziWindow: window.listaEsercizi })
                    this.eserciziAggiornati()
                    break
                case 'pazienti':
                    this.setState({ listaPazientiWindow: window.listaPazienti })
                    this.pazientiAggiornati()
                    break
                case 'immagini':
                    this.setState({ listaImmaginiWindow: window.listaImmaginiProfilo })
                    this.immaginiAggiornate()
                    break
                case 'fatture':
                    this.setState({ listaFattureWindow: window.listaFatture })
                    this.fattureAggiornate()
                case 'cacheFatture':
                    this.setState({ cacheFatureWindow: window.cacheFatture })
                    this.cacheFattureAggiornate()
                    break
                case 'fattureDipendenti':
                    this.setState({ listaFattureDipendentiWindow: window.listaFattureDipendenti })
                    this.listaFattureDipendentiAggiornata()
                    break
                default:
                    break
            }
        }

        presidiAggiornati() {}
        appuntamentiAggiornati() {}
        amministazioniAggiornati() {}
        tagsAggiornati() {}
        dipendentiAggiornati() {}
        eserciziAggiornati() {}
        pazientiAggiornati() {}
        immaginiAggiornate() {}
        fattureAggiornate() {}
        cacheFattureAggiornate() {}
        listaFattureDipendentiAggiornata() {}
    }
