import { store } from 'data/store'
import { baseWatcher } from './baseWatcher'
import { AGGIORNA_LISTA_AGENTI } from 'data/azioni/AGGIORNA_LISTA_AGENTI'
import { collection, query, where, onSnapshot } from 'firebase/firestore'

export class watcherListaAgenti extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione() {
        const q = query(collection(db, 'utenti'), where('profilo', '==', 'profilo-agente'))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            var listaAgenti = {}
            querySnapshot.forEach((doc) => {
                listaAgenti[doc.id] = doc.data()
            })

            AGGIORNA_LISTA_AGENTI(listaAgenti)
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
