import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { renderModuloPrivacy, renderModuloConsensoinformato } from './elementoModuliPdf'
import MDButton from 'components/MDButton'
import ElementoFirma from './elementoFirma'
import MDTypography from 'components/MDTypography'
import { SALVA_PDF_PRIVACY } from 'data/azioni/SALVA_PDF_PRIVACY'
import { SALVA_MODULO_CONSENSO_INFORMATO } from 'data/azioni/SALVA_MODULO_CONSENSO_INFORMATO'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { CHIUDI_POP_UP } from 'data/azioni/CHIUDI_POP_UP'

export default function elementoFirmaDocumento(props) {
    const [firmaDottore, setFirmaDottore] = useState({ chiFirma: 'Dottore', firma: {}, isEmpty: true })
    const [firmaPaziente, setFirmaPaziente] = useState({ chiFirma: 'Paziente', firma: {}, isEmpty: true })
    const [errore, setErrore] = useState('')

    const renderDocumento = () => {
        switch (props.tipoPdf) {
            case 'privacy':
                return renderModuloPrivacy(props.data)
            case 'moduloConsensoInformato':
                return renderModuloConsensoinformato(props.data)
            default:
                break
        }
    }

    const isErrore = () => {
        const errorePaziente = firmaPaziente.isEmpty
        let erroreDottore = false
        if (props.tipoPdf === 'moduloConsensoInformato') {
            erroreDottore = firmaDottore.isEmpty
        }
        return erroreDottore || errorePaziente
    }

    const scegliAzione = async () => {
        switch (props.tipoPdf) {
            case 'privacy':
                return await SALVA_PDF_PRIVACY(props.data, firmaPaziente.firma)
            case 'moduloConsensoInformato':
                return await SALVA_MODULO_CONSENSO_INFORMATO(props.data, firmaPaziente.firma, firmaDottore.firma)

            default:
                break
        }
    }

    const salva = async () => {
        setErrore('')
        if (isErrore()) {
            setErrore('Attenzione! Apporre le firme richieste prima di salvare')
            return
        }
        await scegliAzione()
        /* await CHIUDI_POP_UP() */
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Documento salvato' } })
    }

    return (
        <Grid container direction='column'>
            <Grid item xs={true} lg={true}>
                {renderDocumento()}
            </Grid>
            <Grid item container spacing={3} direction='column' justifyContent='center' alignItems='center'>
                <Grid item>
                    <ElementoFirma chiFirma={firmaPaziente.chiFirma} aggiorna={(e) => setFirmaPaziente(e)} />
                </Grid>
                {props.tipoPdf === 'moduloConsensoInformato' ? (
                    <Grid item>
                        <ElementoFirma chiFirma={firmaDottore.chiFirma} aggiorna={(e) => setFirmaDottore(e)} />
                    </Grid>
                ) : (
                    ''
                )}
            </Grid>
            <Grid item>
                <MDTypography variant='string' color='error'>
                    {errore}
                </MDTypography>
            </Grid>
            <Grid item>
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='error' onClick={() => props.annulla()}>
                        annulla
                    </MDButton>
                    <MDButton variant='contained' color='primary' onClick={() => props.indietro()}>
                        indietro
                    </MDButton>
                    <MDButton variant='contained' color='success' onClick={() => salva()}>
                        firma
                    </MDButton>
                </div>
            </Grid>
        </Grid>
    )
}
