import { getFiltriFatture } from '../../getter/getterFatture'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio, trasformaOggettoInArray } from '../strumenti'
import { baseWatcher } from './baseWatcher'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherListaFatturePresidio extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = []
        window.addEventListener('cercaFatture', (e) => {
            let filtri = getFiltriFatture()

            let id = `${filtri.anno}-${filtri.mesi}`
            this.apriConnessione(id)
        })
    }

    apriConnessione(nuovoId) {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', getPresidio(getUtenteLoggato()), 'fatture', nuovoId), (documento) => {
            if (!documento.exists()) {
                window.listaFatture = []
            }
            window.listaFatture = trasformaOggettoInArray(documento.data())
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'fatture' }))
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        }
    }
}
