import { trasformaOggettoInArray } from 'data/strumenti'
import React from 'react'
import { connect } from 'react-redux'
import ElementoMostraAgente from 'componenti/elementoMostraAgente'
import MDBox from 'components/MDBox'
import { Divider, List } from '@mui/material'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

export const paginaListaAgenti = (props) => {
    const renderFab = () => {
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={()=>props.navigate('/paginaCreaAgente')} >
                <AddIcon />
            </Fab>
        )
    }


    return (
        <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
            <List>
                {props.agenti.map((agente, index) => {
                    return (
                        <React.Fragment key={agente.id}>
                            <ElementoMostraAgente agente={agente} />
                            {index < props.agenti.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </List>
            {renderFab()}
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        agenti: trasformaOggettoInArray(state.reducerListaAgenti || {}),
    }
}

export default connect(mapStateToProps, null)(paginaListaAgenti)
