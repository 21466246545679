import React, { useEffect, useState } from 'react'
import Chip from '@mui/material/Chip'
import { InputText } from './fieldText'
import Grid from '@mui/material/Grid'
import { OutputText } from './fieldText'
import MDBox from 'components/MDBox'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export function InputListaSemplice(props) {
    const [valore, setValore] = useState('')
    const handleValore = (campo, value) => setValore(value)

    useEffect(()=>{
        props.handlePending(props.model.id, valore)
    },[valore])

    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: {
            type: 'text',
        },
    }

    function elimina(index) {
        let lista = [...props.value]
        lista.splice(index, 1)
        props.azione(props.model.id, lista)
    }
    function aggiungi() {
        if (valore === '') {
            return
        }
        let lista = [...props.value]
        lista.push(valore)
        props.azione(props.model.id, lista)
        setValore('')
    }

    let chips = (
        <div className='flex-a-capo'>
            {props.value.map((chip, index) => {
                return (
                    <MDBox mr={0.5} key={`${chip}-${index}`}>
                        <Chip key={index} label={chip} color='primary' onDelete={() => elimina(index)} />
                    </MDBox>
                )
            })}
        </div>
    )

    return (
        <Grid container spacing={1.5}>
            <Grid item xs={1} lg={1}>
                <MDBox height="44.13px" width='min-content' display="flex" sx={{cursor : 'pointer'}} justifyContent="center" alignItems="center" onClick={() => aggiungi()}>
                    <AddCircleOutlineIcon />
                </MDBox>
            </Grid>
            <Grid item container xs={10.5} lg={10.5} spacing={1}>
                <Grid item xs={12} lg={12}>
                    <InputText model={model} value={valore} azione={handleValore} errore={''} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    {chips}
                </Grid>
            </Grid>
        </Grid>
    )
}

export function OutputListaSemplice(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    let valore = props.value.reduce((acc, el, index) => {
        if (index === props.value.length - 1) {
            acc += el
        }
        if (index !== props.value.length - 1) {
            acc += `${el}, `
        }
        return acc
    }, '')

    return <OutputText skeleton={props.skeleton || false} model={model} value={valore} />
}
