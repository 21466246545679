import { eventiCache } from 'mixing/eventiCache'
import React, { Component } from 'react'
import { isEqual } from 'lodash-es'
import { InputAutocomplete } from './fieldAutocomplete'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import { OutputText } from './fieldText'
import MDBox from 'components/MDBox'
import { AGGIUNGI_TAG } from 'data/azioni/AGGIUNGI_TAG'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

export class InputTags extends eventiCache(Component) {
    constructor(props) {
        super(props)
    }

    componentDidUpdate(vecchieProps) {
        if (!isEqual(vecchieProps.value, this.props.value)) {
            this.aggiornaScelte()
        }
    }

    tagsAggiornati() {
        this.aggiornaScelte()
    }

    componentDidMount() {
        super.componentDidMount()
        this.setState({ valore: '' })
        this.aggiornaScelte()
    }

    componentDidUpdate(vecchieProps, vecchioState) {
        if (vecchioState.listaTagsWindow !== this.state.listaTagsWindow) {
            this.aggiornaScelte()
        }
        if (vecchieProps.value !== this.props.value) {
            this.aggiornaScelte()
        }
    }

    aggiornaScelte() {
        let model = {
            id: 'tags',
            tipo: 'fieldTags',
            associazione: 'tags',
            label: 'Seleziona un tag',
            value: '',
            details: this.props.model.details,
        }
        let nuovaLista = []
        this.state.listaTagsWindow.forEach((tag) => {
            if (!this.props.value.includes(tag)) {
                nuovaLista.push(tag)
            }
        })
        model.details.scelte = nuovaLista
        this.setState({ model: model })
        //console.log('statoooo : ', this.state)
    }

    renderChips() {
        return (
            <div className='flex-a-capo'>
                {this.props.value.map((chip, index) => {
                    return (
                        <MDBox key={index} mr={0.5}>
                            <Chip label={chip} color={this.props.model.details?.testoBianco ? 'secondary' : 'primary'} onDelete={() => this.elimina(index)} />
                        </MDBox>
                    )
                })}
            </div>
        )
    }

    aggiornaValore(valore, aggiungi) {
        if (aggiungi && valore !== '' && valore !== null) {
            this.aggiungiLista(valore)
            this.setState({ valore: '' })
            return
        }
        this.setState({ valore: valore })
    }

    aggiungiLista(valore) {
        let lista = [...this.props.value]
        lista.push(valore)
        this.props.azione(this.props.model.id, lista)
        this.aggiornaValore('')
    }

    async aggiungi() {
        if (this.state.valore === '') {
            return
        }
        await AGGIUNGI_TAG(this.state.valore)
        this.aggiungiLista(this.state.valore)
    }

    elimina(index) {
        let lista = [...this.props.value]
        lista.splice(index, 1)
        this.props.azione(this.props.model.id, lista)
    }

    renderAggiungi() {
        if (this.props.model.details.nascondiBottone) {
            return ''
        }
        
        return (
            <Grid item xs={1} lg={1}>
                <MDBox height='44.13px' display='flex' sx={{ cursor: 'pointer' }} justifyContent='center' alignItems='center' onClick={() => this.aggiungi()}>
                    <AddCircleOutlineIcon />
                </MDBox>
            </Grid>
        )
    }

    render() {
        if (this.state.model == undefined) {
            return ''
        }
        return (
            <MDBox width='100%' height='100%'>
                <Grid container spacing={1}>
                    {this.renderAggiungi()}

                    <Grid item container xs={11} lg={11} spacing={1}>
                        <Grid item xs={12} lg={12}>
                            <InputAutocomplete model={this.state.model} value={this.state.valore} azione={(a, b,c) => this.aggiornaValore(b,c)} errore={''} />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {this.renderChips()}
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        )
    }
}

export function OutputTags(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    let valore = props.value.reduce((acc, el, index) => {
        if (index === props.value.length - 1) {
            acc += el
        }
        if (index !== props.value.length - 1) {
            acc += `${el}, `
        }
        return acc
    }, '')

    return <OutputText skeleton={props.skeleton || false} model={model} value={props.value.toString()} />
}
