import { getUtenteLoggato, getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../strumenti'
import { baseWatcher } from './baseWatcher'
import { LEGGI_CONFIGURAZIONE_BASE_FATTURE } from './../azioni/LEGGI_CONFIGURAZIONE_BASE_FATTURE'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherConfigurazioneBaseFatture extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', getPresidio(getVeroUtenteLoggato()), 'cache', 'defaultFattura'), (documento) => {
            let daPassare = documento.exists() ? documento.data() : {}
            LEGGI_CONFIGURAZIONE_BASE_FATTURE(daPassare)
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
