import React from 'react'
import { useNavigate } from 'react-router-dom'
import MDBox from 'components/MDBox'
import ElementoCampiCercaEsercizio from 'componenti/elementoCampiCercaEsercizio'
import ElementoMostraEsercizi from 'componenti/elementoMostraEsercizi'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { CREA_ESERCIZIO } from 'data/azioni/CREA_ESERCIZIO'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

export default function paginaLibreriaEsercizi() {
    const navigate = useNavigate()

    const apri = () => {
        APRI_POP_UP({ id: 'nuovoEsercizio', parametro: { daAssegnare: false, azione: (e) => CREA_ESERCIZIO(e) } })
    }

    const renderFab = () => {
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => apri()}>
                <AddIcon />
            </Fab>
        )
    }

    return (
        <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%', mt: 5 }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
            <MDBox mt={-7} borderRadius='lg' shadow='lg' variant='gradient' bgColor='info' ml='auto' mr='auto' p={3} maxWidth={1230}>
                <ElementoCampiCercaEsercizio testoBianco={true} />
            </MDBox>
            <MDBox mt={3}>
                <ElementoMostraEsercizi navigate={navigate} />
            </MDBox>
            {renderFab()}
        </MDBox>
    )
}
