import MDButton from 'components/MDButton'
import React, { useEffect, useState } from 'react'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { getPresidio } from 'data/strumenti'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { Icon } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { ref, getDownloadURL } from 'firebase/storage'

export default function elementoMostraFatturaPaziente(props) {
    const [url, setUrl] = useState('')

    const getUrl = () => {
        let path = `fatture/${getPresidio(getUtenteLoggato())}/${props.fattura.id}/fattura_${props.fattura.contenuto.numFattura}.pdf`

        getDownloadURL(ref(storage, path)).then((url) => {
            setUrl(url)
        })
    }

    const scarica = () => {
        window.open(url)
    }

    useEffect(
        (e) => {
            if (url === '' && props.fattura.contenuto.stato !== undefined && props.fattura.contenuto.stato !== 'creata' && props.fattura.contenuto.rimborsi === undefined) {
                getUrl()
            }
        },
        [props.fattura],
    )

    const vediBottone = () => {
        if (props.fattura.contenuto.rimborsi !== undefined) {
            return (
                <MDButton size='small' variant='text' color='primary' onClick={() => APRI_POP_UP({ id: 'documenti-fattura', parametro: props.fattura })}>
                    <VisibilityIcon sx={{ mr: 1 }} />
                    vedi documenti
                </MDButton>
            )
        }
        return (
            <MDButton size='small' variant='text' color='primary' onClick={() => scarica()}>
                <Icon sx={{ mr: 1 }}>download</Icon>
                scarica
            </MDButton>
        )
    }

    return (
        <ListItem>
            <ListItemIcon>
                <CreditCardRoundedIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={props.fattura.contenuto.numFattura} secondary={props.fattura.contenuto.data} />
            <ListItemIcon>{vediBottone()}</ListItemIcon>
        </ListItem>
    )
}
