import React, { Component } from 'react'
import dizionarioFields from './../fields/dizionarioFields'
import { Grid } from '@mui/material'

export default function FormOutput(props) {
    let standard = 'test-form'
    if (props.isSubInput) {
        standard = 'test-form-no-padding'
    }
    const standardXs = 4
    const standardLg = 2

    return (
        <Grid component={props.isSubInput ? 'div' : 'form'} container spacing={3} maxWidth='100%' width='100%'>
            {props.form.parts.map((model, index) => {
                let valore = props.dati[model.id] || model.value
                let valoreXs = model.details?.grid?.xs?.output || standardXs
                let valoreLg = model.details?.grid?.lg?.output || standardLg
                return (
                    <Grid key={index} item xs={valoreXs * 3} sm={valoreLg * 3} maxWidth='100%' width='100%'>
                        {dizionarioFields[model.tipo].output(model, valore, props.skeleton || false)}
                    </Grid>
                )
            })}
        </Grid>
    )
}
