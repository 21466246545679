import { store } from './../data/store'
import { convertiDataInStringa } from './../data/strumenti'
import { getDipendente, getIdDipendente } from './getterDipendenti'
import { getAccessoPaziente, getIdPaziente, getPaziente } from './getterPaziente'
import { getDatiPresidio } from './getterPresidio'
import { getIdUtenteLoggato, getProfiloUtenteLoggato, getUtenteLoggato, getVeroUtenteLoggato } from './getterUtenteLoggato'
import { getVersioneAttuale } from './getterVersioni'

export const getUtente = () => {
    let utente = store.getState().url[3] === 'paginaVediPaziente' ? getPaziente() : getUtenteLoggato()
    if (store.getState().url[3] === 'paginaVediDipendente') {
        return getDipendente()
    }
    if (getProfiloUtenteLoggato() === 'profilo-amministrazione-clinica') {
        var utenteClinica = store.getState().url[3] !== 'paginaVediDipendente' ? getDatiPresidio() : getDipendente()
        const utenteLoggato = getVeroUtenteLoggato()
        utenteClinica = Object.assign(utente, { etichettaUniversale: utenteLoggato.etichettaUniversale, profilo: utenteLoggato.profilo })
        return utenteClinica
    }
    

    return utente
}

export const getProfiloUtente = () => {
    return getUtente().profilo
}

export const getPresidio = (utente) => {
    if (utente.etichettaUniversale !== undefined) {
        return utente.etichettaUniversale.presidio
    } else {
        return utente.presidio
    }
}

export const getIdUtente = () => {
    if (getProfiloUtenteLoggato() === 'profilo-amministrazione-clinica') {
        return store.getState().url[3] === 'paginaVediDipendente' ? getIdDipendente() : getIdUtenteLoggato()
    }
    if (store.getState().url[3] === 'paginaVediDipendente') {
        return getIdDipendente()
    }
    return store.getState().url[3] === 'paginaVediPaziente' ? getIdPaziente() : getIdUtenteLoggato()
}

const getImmagine = () => {
    let utente = getUtente()
    if (utente.profilo === 'profilo-amministrazione-clinica') {
        return utente.logo !== undefined ? utente.logo : false
    }
    return utente.immagineProfilo !== undefined && utente.immagineProfilo.urlThumbNail !== undefined && utente.immagineProfilo.urlThumbNail
}

export const getRiassuntoUtente = () => {
    let utente = getUtente()
    let isPaziente = utente.profilo === 'profilo-paziente'
    let pagine = ['paginaMioProfilo', 'paginaCreaPaziente']
    let isMioProfilo = pagine.includes(store.getState().url[3]) || getProfiloUtenteLoggato() === 'profilo-paziente'

    let daRitornare = {
        nome: utente.nome,
        cognome: utente.cognome,
        data: utente.dataNascita,
        immagine: getImmagine(),
        isPaziente: isPaziente,
        isMioProfilo: isMioProfilo,
        id: getIdUtente(),
    }

    if (isPaziente) {
        daRitornare.privacyFirmata = utente.datiDocPrivacy !== undefined
        daRitornare.accesso = getAccessoPaziente()
    }

    return daRitornare
}

export const getRiassuntoUtenteAmministrazioneClinica = () => {
    let utente = getUtente()
    let isMioProfilo = store.getState().url[3] !== 'paginaVediDipendente'
    let daRitornare = {
        nome: utente.nome,
        immagine: getImmagine(),
        isMioProfilo: isMioProfilo,
        id: getIdUtente(),
        profiliAutorizzati: utente.profiliAutorizzati || [],
        profiloOriginale: utente.profiloOriginale || 'profilo-amministrazione-clinica',
    }
    if (isMioProfilo) {
        daRitornare.cap = utente.CAP
        daRitornare.comune = utente.comune
        daRitornare.email = utente.email
        daRitornare.indirizzo = utente.indirizzo
        daRitornare.provincia = utente.provincia
        daRitornare.cellulare = utente.telefonoCellulare
    }

    if (!isMioProfilo) {
        daRitornare.cognome = utente.cognome
        daRitornare.pagato = utente.pagato
        daRitornare.attivo = utente.attivo
        daRitornare.sottoscrizione = utente.sottoscrizione
        daRitornare.data = utente.dataNascita
    }
    return daRitornare
}

export const linkAmministrazioneClinica = () => {
    return [
        {
            testo: 'Anagrafica',
            icona: 'svgLibro',
            parteUrl: 3,
            link: `paginaVediAnagrafica`,
        },
        {
            testo: 'Dipendenti',
            icona: 'svgLista',
            parteUrl: 3,
            link: `paginaVediDipendenti`,
        },
        {
            testo: 'Versione',
            icona: 'svgVersione',
            parteUrl: 3,
            parametro: getVersioneAttuale(),
            link: `paginaVediVersione`,
            vediVersione: true,
        },
        {
            testo: 'TS',
            icona: 'svgTs',
            parteUrl: 3,
            link: `paginaTesseraSanitaria/paginaConfigurazioneTesseraSanitaria`,
        },
        {
            testo: 'Feedback',
            icona: 'svgFeedback',
            parteUrl: 3,
            link: `paginaFeedback`,
        },
    ]
}

export const getLinkUtente = () => {
    let linkPaziente = [
        {
            testo: 'Anagrafica',
            icona: 'svgLibro',
            parteUrl: 5,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediAnagrafica`,
        },
        {
            testo: 'Storia',
            icona: 'svgOrologioFrecciaSinistra',
            parteUrl: 5,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaStoriaClinica`,
        },
        {
            testo: 'Abitudini',
            icona: 'svgDivano',
            parteUrl: 5,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediAbitudini`,
        },
        {
            testo: 'Diario',
            icona: 'svgLibroChiuso',
            parteUrl: 5,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaDiarioClinico`,
        },
    ]
    if (getProfiloUtenteLoggato() !== 'profilo-segreteria') {
        linkPaziente.push({
            testo: 'Esercizi',
            icona: 'svgPersonaBracciaAperte',
            parteUrl: 5,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaEserciziPaziente`,
        })
    }
    let profiliFatture = ['profilo-segreteria', 'profilo-paziente', 'profilo-dottore']
    if (profiliFatture.includes(getProfiloUtenteLoggato())) {
        linkPaziente.push({
            testo: 'Fatture',
            icona: 'svgCartaDiCredito',
            parteUrl: 5,
            link: `paginaVediPaziente/${getIdPaziente()}/paginaFatturePaziente`,
        })
    }

    let linkMioProfilo = [
        {
            testo: 'Anagrafica',
            icona: 'svgLibro',
            parteUrl: 4,
            link: `paginaMioProfilo/paginaVediAnagrafica`,
        },
        {
            testo: 'Versione',
            icona: 'svgVersione',
            parteUrl: 4,
            parametro: getVersioneAttuale(),
            link: `paginaMioProfilo/paginaVediVersione`,
            vediVersione: true,
        },
        {
            testo: 'Feedback',
            icona: 'svgFeedback',
            parteUrl: 4,
            link: `paginaMioProfilo/paginaFeedback`,
        },
    ]
    if (getProfiloUtenteLoggato() === 'profilo-segreteria') {
        linkMioProfilo.push({
            testo: 'TS',
            icona: 'svgTs',
            parteUrl: 4,
            link: `paginaMioProfilo/paginaTesseraSanitaria/paginaConfigurazioneTesseraSanitaria`,
        })
        linkMioProfilo.push({
            testo: 'Fatture',
            icona: 'svgCartaDiCredito',
            parteUrl: 4,
            link: `paginaMioProfilo/paginaFatturePresidio`,
        })
    }

    if (store.getState().url[3] !== 'paginaMioProfilo' && store.getState().url[3] !== 'paginaCreaPaziente') {
        return linkPaziente
    }

    return linkMioProfilo
}

export const isCacheDaAggiornare = (dati, profilo, datiDaCofrontare) => {
    let nuoviDatiUtente = Object.assign(dati, {})
    let getDati = () => {
        switch (profilo) {
            case 'profilo-paziente':
                let data = dati.dataNascita
                if (typeof data !== 'string') {
                    data = convertiDataInStringa(data)
                }
                nuoviDatiUtente = Object.assign(dati, { anno: data.split('/')[2] })
                let paziente = {
                    nome: datiDaCofrontare.nome,
                    cognome: datiDaCofrontare.cognome,
                    anno: '00',
                }
                return paziente
            case 'profilo-segreteria':
                return {
                    nome: datiDaCofrontare.nome,
                    cognome: datiDaCofrontare.cognome,
                    profilo: 'profilo-segreteria',
                }
            case 'profilo-dottore':
                return {
                    nome: datiDaCofrontare.nome,
                    cognome: datiDaCofrontare.cognome,
                    albo: datiDaCofrontare.albo,
                    laurea: datiDaCofrontare.laurea,
                    profilo: 'profilo-dottore',
                }
            case 'profilo-amministrazione-clinica':
                return {
                    nome: datiDaCofrontare.nome,
                    email: datiDaCofrontare.email,
                    web: datiDaCofrontare.web,
                    telefonoCellulare: datiDaCofrontare.telefonoCellulare,
                    telefonoFisso: datiDaCofrontare.telefonoFisso,
                }

            default:
                return false
        }
    }

    let datiCache = getDati()

    console.log('dati : ', datiCache)

    if (!datiCache) {
        return false
    }

    let isModificato = false
    let datiDaRitornare = {}

    Object.keys(datiCache).forEach((chiave) => {
        //console.log('chiave : ', chiave);
        //console.log(datiCache[chiave]);
        //console.log(nuoviDatiUtente[chiave]);
        datiDaRitornare[chiave] = nuoviDatiUtente[chiave]
        if (datiCache[chiave] !== nuoviDatiUtente[chiave]) {
            isModificato = true
        }
    })
    if (nuoviDatiUtente.profilo === 'profilo-paziente') {
        datiDaRitornare['immagine'] = dati.immagineProfilo !== undefined
    }
    return isModificato ? datiDaRitornare : false
}
