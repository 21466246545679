import { getIdLesioneSelezionata } from '../../getter/getterLesioni'
import { getIdPaziente } from '../../getter/getterPaziente'
import { doc, updateDoc } from 'firebase/firestore'

export const AGGIORNA_INFORMAZIONI_DISFUNZIONE = async (informazioni) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    
    
    await updateDoc(doc(db, 'utenti', getIdPaziente(), 'lesioni', getIdLesioneSelezionata()), { informazioni: informazioni })
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return 
}
