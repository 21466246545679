import { getIdPaziente } from '../../getter/getterPaziente'
import { getIdLesioneSelezionata } from '../../getter/getterLesioni'
import { creaEtichettaUniversale, newId } from './../strumenti'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../../getter/getterUtente'
import { doc, updateDoc, setDoc } from 'firebase/firestore'

export const CREA_SEDUTA = async (lesione) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    if (lesione === undefined || lesione === '') {
        lesione = getIdLesioneSelezionata()
    }

    let etichettaUniversaleCreata = creaEtichettaUniversale(getPresidio(getUtenteLoggato()))
    let nuovoId = newId()
    let seduta = {
        elementi: {},
        etichettaUniversale: etichettaUniversaleCreata,
    }
    try {
        await updateDoc(doc(db, 'utenti', getIdPaziente(), 'lesioni', lesione), { [`sedute.${nuovoId}`]: seduta })
    } catch (error) {
        await setDoc(doc(db, 'utenti', getIdPaziente(), 'lesioni', lesione), { sedute: { [nuovoId]: seduta }, seduteNoLesione: true, titolo : 'nessuna disfunzione', etichettaUniversale: creaEtichettaUniversale(getPresidio(getUtenteLoggato())) })
    }
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return nuovoId
}
