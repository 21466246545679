import React from 'react'
import VediForm from 'formMaker/vediForm'

export default function elementoAssegnaNota(props) {
    const annulla = () => {
        props.chiudi()
    }

    const salva = (dati) => {
        dati.tipo = 'fieldNote'
        props.salva(dati)
    }

    const model = {
        parts: [
            {
                id: 'testo',
                tipo: 'fieldTextArea',
                associazione: 'testo',
                label: 'Nota:',
                value: '',
                details: {
                    costumCssOutput: ['labelBold'],
                },
            },
        ],
    }

    return <VediForm form={model} dati={{}} annulla={() => annulla()} salva={(dati) => salva(dati)} vista='input' />
}
