import { store } from './../data/store'

export const getFiltriFatture = () => {
    return store.getState().reducerFiltriFatture || {anno: `${new Date().getFullYear()}`, mesi: new Date().getMonth() + 1, stato: ''}
}

export const getListaFatture = () => {
    let fatture = []
    Object.keys(window.listaFatture).forEach((chiave) => {
        fatture = fatture.concat(window.listaFatture[chiave])
    })
    return fatture
}

export const filtraFatture = () => {
    let fattureFiltrate = []
    let { stato } = getFiltriFatture()

    //if (idDottore === '' && stato === "") {
    if (stato === '') {
        return {
            errore: window.listaFatture.length === 0,
            lista: window.listaFatture,
        }
    }

    fattureFiltrate = window.listaFatture.filter((fattura) => {
        let comparazioneDottore = () => {
            if (idDottore === '') {
                return true
            }
            return fattura.contenuto.idDottore === idDottore
        }
        let comparazioneStato = () => {
            if (stato.length === 0) {
                return true
            }
            switch (stato) {
                case 'non inviata':
                    return fattura.contenuto.stato === 'creata' || fattura.contenuto.stato === 'emessa'
                case 'inviata':
                    return fattura.contenuto.stato === 'inviata'
                case 'errore':
                    return fattura.contenuto.errore !== undefined && fattura.contenuto.errore
                case 'nota di credito':
                    return fattura.contenuto.rimborsi !== undefined
                default:
                    return
            }
        }
        return comparazioneStato() //&& comparazioneDottore()
    })

    return {
        errore: fattureFiltrate.length === 0,
        lista: fattureFiltrate,
    }
}

export const isScanFattuttura = (fattura) => {
    if (fattura.scan === undefined) {
        return false
    }
    return fattura.scan
}