import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton';

export function InputText(props) {
    const [vediPassword, setVediPassword] = useState(false)

    let standardInput = () => {
        let inputProps = props.model.details?.inputProps || {}
        if (props.model.details?.maiuscolo) {
            inputProps.sx = { textTransform: 'uppercase !important' }
        }
        if (props.model.details?.testoBianco) {
            inputProps.sx = { color: 'white !important' }
        }
        return (
            <TextField
                id={props.model.details?.id || ''}
                fullWidth
                inputProps={inputProps}
                color={props.model.details?.color || 'primary'}
                variant={props.model.details?.variant || 'outlined'}
                value={props.value}
                error={props.errore !== ''}
                type={props.model.details?.type}
                classes={props.model.details?.classi}
                helperText={props.errore}
                placeholder={props.model.details?.placeHolder || ''}
                sx={props.model.details?.sx}
                InputLabelProps={props.model.details?.InputLabelProps}
                onChange={(e, valore) => {
                    let daTornare = e.target.value
                    if (props.model.details?.type === 'number') {
                        daTornare = Number(e.target.value)
                    }
                    if (props.model.details?.maiuscolo) {
                        daTornare = e.target.value.toUpperCase()
                    }

                    props.azione(props.model.id, daTornare)
                }}
                label={props.model.label}
                /* style={{ width: 300 }} */
            />
        )
    }

    let inputPassword = () => {
        return (
            <FormControl variant='outlined' error={props.errore !== ''} fullWidth>
                <InputLabel htmlFor={props.model.id}>{props.model.label}</InputLabel>
                <OutlinedInput
                    id={props.model.details?.id || ''}
                    fullWidth
                    color={props.model.details?.color || 'primary'}
                    type={vediPassword ? 'text' : 'password'}
                    value={props.value}
                    error={props.errore !== ''}
                    classes={props.model.details?.classi}
                    onChange={(e, valore) => {
                        props.azione(props.model.id, e.target.value)
                    }}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton aria-label='toggle password visibility' onClick={() => setVediPassword(!vediPassword)} edge='end'>
                                {vediPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={props.model.label}
                />
                <FormHelperText>{props.errore}</FormHelperText>
            </FormControl>
        )
    }

    return props.model.details?.type === 'password' ? inputPassword() : standardInput()
}

export function OutputText(props) {
    const [vediPassword, setVediPassword] = useState(false)

    const cosaVedere = () => {
        if (props.skeleton) {
            return <Skeleton animation='wave' height='32.5px' />
        }
        if (props.model.details?.type !== 'password') {
            return props.value
        }
        if (vediPassword) {
            return props.value
        }
        return '******'
    }

    const renderOcchio = () => {
        if (props.model.details?.type !== 'password') {
            return ''
        }
        if (vediPassword) {
            return <VisibilityOff sx={{ cursor: 'pointer' }} onClick={() => setVediPassword(false)} />
        }
        if (!vediPassword) {
            return <Visibility sx={{ cursor: 'pointer' }} onClick={() => setVediPassword(true)} />
        }
    }

    let siLabel = (
        <Grid container direction='column'>
            <MDTypography variant='caption' color='text'>
                {props.model.label}
            </MDTypography>
            <Grid item container direction='row' justifyContent={'space-between'} width='100%' >
                <Grid item width='100%'>
                    <MDTypography  width='100%' variant='string' fontWeight='medium' textTransform='capitalize' sx={{whiteSpace : 'pre-line'}}>
                        {cosaVedere()}
                    </MDTypography>
                </Grid>
                <Grid item>{renderOcchio()}</Grid>
            </Grid>
        </Grid>
    )

    let noLabel = (
        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize' sx={{whiteSpace : 'pre-line'}}>
            {cosaVedere()}
        </MDTypography>
    )

    return props.model.details?.noLabel ? noLabel : siLabel
}
