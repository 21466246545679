import React, { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import Icon from '@mui/material/Icon'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import colors from 'assets/theme/base/colors'

export default function elementoDialer(props) {
    const [open, setOpen] = useState(false)

    const click = () => {
        setOpen(!open)
        if (props.azione) {
            props.azione()
        }
    }

    useEffect((e) => {
        window.addEventListener('clicka', (e) => {
            let lista = []
            e.composedPath()[0].classList.forEach((el) => {
                console.log('elllll : ', el)
                lista.push(el)
            })
            //console.log('click windowwwww : ', e.composedPath()[0].classList)
            /* let lista = Object.keys(e.composedPath()[0].classList).map((chiave)=>{
                e.composedPath()[0].classList[chiave]
            }) */
            let test = (lista.includes('notranslate') || lista.includes('MuiSpeedDial-fab') || lista.includes('MuiSpeedDialIcon-icon')) && !lista.includes('MuiSpeedDialAction-fab')
            console.log('listaaaaaa : ', test, open)
            if (test) {
                open ? chiudi() : apri()
            }
        })
    }, [])

    const clickAzione = async (bottone) => {
        await bottone.azione()
        setOpen(false)
    }

    const isOpen = () => {
        if (props.aperto === undefined) {
            return open
        }
        return props.aperto
    }

    let testo = (testo) => <MDTypography color={props.vediModale ? 'dark' : 'white'} sx={{ width: 'max-content', cursor: 'pointer' }}>{testo}</MDTypography>

    const renderIcona = () => {
        if (isOpen()) {
            return <SpeedDialIcon />
        }
        if (props.icon === undefined || props.icon === '') {
            return <SpeedDialIcon />
        }
        return <Icon>{props.icon}</Icon>
    }


    return (
        <React.Fragment>
            {props.vediModale ? <Backdrop sx={{ zIndex: (theme) => theme.zIndex.modal - 3 }} onClick={() => setOpen(false)} open={isOpen()} /> : ''}
            <SpeedDial FabProps={{ color: isOpen() ? 'error' : 'primary' }} ariaLabel='SpeedDial tooltip example' sx={{zIndex: (theme) => theme.zIndex.modal - 2, position: 'fixed', bottom: 16, right: 16 }} icon={renderIcona()} open={isOpen()}>
                {props.azioni.map((bottone, index) => (
                    <SpeedDialAction FabProps={{ sx: {  backgroundColor: colors[bottone.color].main, "&:hover" : {backgroundColor: colors[bottone.color].main} } }} key={index} icon={<Icon sx={{color : colors.white.main}} >{bottone.icon}</Icon>} tooltipTitle={testo(bottone.testo)} open={isOpen()} tooltipOpen={props.vediModale} onClick={() => clickAzione(bottone)} />
                ))}
            </SpeedDial>
            <MDBox sx={{ cursor: 'pointer', borderRadius: '50%', zIndex: (theme) => theme.zIndex.modal - 1, position: 'fixed', bottom: 16, right: 16 }} height='56px' width='56px' onClick={(e) => click(e)}></MDBox>
        </React.Fragment>
    )
}

/* chiuso : {
    0: "material-icons"
    1: "notranslate"
    2: "MuiIcon-root"
    3: "MuiIcon-fontSizeMedium"
    4: "css-kp9ftd-MuiIcon-root"
}

aperto : {
    0: "MuiSvgIcon-root"
    1: "MuiSvgIcon-fontSizeMedium"
    2: "MuiSpeedDialIcon-icon"
    3: "MuiSpeedDialIcon-iconOpen"
    4: "css-i4bv87-MuiSvgIcon-root"
}

fab : {
    0: "MuiButtonBase-root"
    1: "MuiFab-root"
    2: "MuiFab-circular"
    3: "MuiFab-sizeSmall"
    4: "MuiFab-primary"
    5: "MuiSpeedDialAction-fab"
    6: "MuiSpeedDialAction-fabClosed"
    7: "css-18obvrs-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab"
} */
