import { store } from './../store'

export const LEGGI_LESIONI = (nomeParametro) => {

    return new Promise((resolve, reject) => {
        store.dispatch({
            type: "LEGGI_LESIONI",
            payload: nomeParametro
        })
        resolve()
    });

}
    