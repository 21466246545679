// @mui material components
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

// Material Kit 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Kit 2 PRO React examples
import SimpleInfoCard from 'examples/Cards/InfoCards/SimpleInfoCard'

function features() {
    return (
        <MDBox component='section' py={6} /* bgColor='white' */ /* variant='gradient' opacity={1} shadow='lg' */>
            <Container>
                <Grid container item xs={12} lg={6} justifyContent='center' mx='auto' textAlign='center' pb={6}>
                    <MDTypography variant='h2' mb={1} color="info">
                        Funzioni principali
                    </MDTypography>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                        <SimpleInfoCard  icon='person' title='Sicurezza' direction='center' description={`Lavorare su ${appUtilizzata} Archive è semplice quanto sicuro: l’accesso al gestionale è protetto e hai sempre il pieno controllo dei dati salvati garantendo la sicurezza di ogni informazione e la tutela della privacy dei tuoi pazienti. `} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SimpleInfoCard icon='support_agent' title='Precisione e comodità' direction='center' description={`Puoi archiviare i dati del tuo studio e dei tuoi pazienti con precisione e semplicità: è sufficiente farsi guidare da ${appUtilizzata} Archive, step by step, salvando e trovando ogni dato nel massimo della comodità e senza mai più perdere nulla. `} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SimpleInfoCard  icon='update' title='Accessibiltà illimitata' direction='center' description={`Grazie a ${appUtilizzata} Archive puoi modificare e consultare le informazioni dei tuoi pazienti e i dati amministrativi in qualsiasi momento e da qualsiasi luogo; allo stesso modo, i tuoi pazienti possono visionare la propria situazione ogni volta che lo desiderano. `} />
                    </Grid>
                </Grid>
            </Container>
        </MDBox>
    )
}

export default features
