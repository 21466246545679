import React, { useEffect, useState } from 'react'

import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it'
import 'moment-timezone'
import breakpoints from 'assets/theme/base/breakpoints'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { isEqual } from 'lodash-es'
import './../Calendario.css'
import MDBox from 'components/MDBox'

const localizer = momentLocalizer(moment)
moment.tz.setDefault('Europe/Rome')
moment.tz.guess(true)
moment.locale('it')

export default function elementoCalendario(props) {
    const opzioni = {
        standard: {
            defaultView: 'week',
            views: ['month', 'week', 'day', 'agenda'],
        },
        mobile: {
            defaultView: 'day',
            views: ['month', 'week', 'day', 'agenda'],
        },
    }

    const getPropCalendario = () => {
        if (window.innerWidth <= breakpoints.values.md) {
            return opzioni.mobile
        }
        return opzioni.standard
    }

    const [propCalendario, setPropCalendario] = useState(getPropCalendario())
    const [mesi, setMesi] = useState([])

    const inizializza = () => {
        const oggi = new Date()
        const mese = oggi.getMonth() === 11 ? 1 : oggi.getMonth() + 1
        const anno = oggi.getFullYear()

        let array = [`${anno}-${mese}`]
        if (mese === 1) {
            array.push(`${anno - 1}-12`)
        } else {
            array.push(`${anno}-${mese - 1}`)
        }
        if (mese === 12) {
            array.push(`${anno + 1}-1`)
        } else {
            array.push(`${anno}-${mese + 1}`)
        }
        setMesi(array)
        props.aggiorna(array)
    }

    const getMesi = (data) => {
        const mese = data.getMonth() === 11 ? 1 : data.getMonth() + 1
        const anno = data.getFullYear()
        let array = [`${anno}-${mese}`]
        if (mese === 12) {
            array.push(`${anno + 1}-1`)
        } else {
            array.push(`${anno}-${mese + 1}`)
        }

        if (!isEqual(array, mesi)) {
            setMesi(array)
            props.aggiorna(array)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setPropCalendario(getPropCalendario())
        })
        inizializza()
    }, [])


    return (
        <BigCalendar
            selectable
            localizer={localizer}
            events={props.eventi}
            defaultView={propCalendario.defaultView}
            startAccessor='start'
            endAccessor='end'
            views={propCalendario.views}
            messages={{ event: 'Paziente', time: 'Orario', date: 'Data', next: 'Prossimo', previous: 'Precedente', today: 'Oggi', month: 'Mese', day: 'Giorno', week: 'settimana' }}
            scrollToTime={new Date(1970, 1, 1, 6)}
            defaultDate={new Date()}
            onSelectEvent={(event) => APRI_POP_UP({ id: 'appuntamento', parametro: { idEvento: event.id } })}
            onSelectSlot={(slotInfo) => {
                APRI_POP_UP({ id: 'appuntamento', parametro: Object.assign(slotInfo, {dottore : props.fisioterapista}) })
            }}
            onRangeChange={(e) => {
                let data = e.start || e[0]
                getMesi(data)
            }}
            /* eventPropGetter={eventColors} */
        />
    )
}
