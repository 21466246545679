import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'

import { getPresidio } from './../strumenti'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const EMETTI_FATTURA = (idFattura, idPaziente, totale, dataFattura) => {
    let idPresidio = getPresidio(getUtenteLoggato())
    return new Promise((resolve, reject) => {
        CHIAMA_SERVER(`emettiFattura`, { idPresidio: idPresidio, idFattura: idFattura, idPaziente: idPaziente, dataFattura: dataFattura, totale: totale }).then(() => {
            resolve()
        })
    })
}
