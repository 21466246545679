import { getIdSottoscrizione, isSospeso } from '../../getter/getterDipendenti'
import { getDatiPresidio } from '../../getter/getterPresidio'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const MODIFICA_RINNOVO_SOTTOSCRIZIONE = (id) => {
    let ultimaParteUrl = isSospeso(id) ? 'reactivate' : 'cancel'

    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

    let daPassare = { customer: getDatiPresidio().idStripe, url: window.location.href }
    CHIAMA_SERVER(`creaPortale`, daPassare).then((session) => {
        window.location.assign(`${session.message}/subscriptions/${getIdSottoscrizione(id)}/${ultimaParteUrl}`)
    })
}
