import React from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import ElementoMostraTipologiaFormDaInserire from 'componenti/elementoMostraTipologiaFormDaInserire'
import { listaTipologieFormDaCompilare } from 'getter/getterFormDaCompilare'

export const paginaFormDaCompilare = (props) => {
    return (
        <Grid container spacing={2} mx='auto' maxWidth='1000px'>
            {listaTipologieFormDaCompilare.map((tipologia, index) => {
                return (
                    <Grid item key={`tipologia-${index}`} xs={12} lg={6}>
                        <ElementoMostraTipologiaFormDaInserire tipologia={tipologia} abilitato={false} isDottore={true}  />
                    </Grid>
                )
            })}
        </Grid>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaFormDaCompilare)
