import svgDottore from 'assets/images/fisioterapista.webp'
import svgSegreteria from 'assets/images/segreteria.webp'
import svgPaziente from 'assets/images/paziente.webp'
import svgAmministrazione from 'assets/images/amminstrazione.webp'
import svgSalaAttesa from 'assets/images/chiosco.webp'

export const getAppUtilizzata = () => {
    return appUtilizzata.toLowerCase()
}

export const getTipoProfessionista = () => {
    switch (getAppUtilizzata()) {
        case 'osteo':
            return 'osteopata'
        case 'physio':
            return 'fisioterapista'
        default:
            break
    }
}

export const getVideoLanding = () => {
    const listaVideo = [
        {
            label: 'Crea paziente',
            link: 'https://www.youtube.com/embed/gcwlNjDE3dQ',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Cerca paziente',
            link: 'https://www.youtube.com/embed/kcjS3xDJv-I',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Crea una disfunzione',
            link: 'https://www.youtube.com/embed/e-ND5J95L80',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Crea una seduta',
            link: 'https://www.youtube.com/embed/G790UKOfFHI',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Elementi di una seduta',
            link: 'https://www.youtube.com/embed/A2S41S-xM0A',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Firma consenso informato',
            link: 'https://www.youtube.com/embed/oOgT7ZPDJyU',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Aggiunta di un file',
            link: 'https://www.youtube.com/embed/OXrb4Tmd6CA',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Aggiunta dolore',
            link: 'https://www.youtube.com/embed/Qe2eIt12RXk',
            app: ['osteo', 'physio'],
        },
        {
            label: 'Fatturazione',
            link: 'https://www.youtube.com/embed/NOvNxLcfIkQ',
            app: ['physio'],
        },
        {
            label: 'Sistema TS',
            link: 'https://www.youtube.com/embed/Ho_9SySUBzM',
            app: ['physio'],
        },
    ]
    return listaVideo.filter((video) => {
        return video.app.includes(getAppUtilizzata())
    })
}

export const isLogoAifiDaVedere = () => {
    const daVedere = ['physio']
    return daVedere.includes(getAppUtilizzata())
}

export const getProfiliCarosello = () => {
    const profili = [
        { app: ['physio'], immagine: svgDottore, titolo: 'Profilo fisioterapista', testo: 'Ogni fisioterapista della tua Clinica ha il suo profilo fisioterapista attraverso cui creare i profili dei Pazienti da seguire per l’intero percorso riabilitativo: dalla registrazione ai pagamenti, dalla cartella clinica alla registrazione delle singole abitudini, annotando ogni dettaglio analitico delle sedute e degli esercizi.' },
        { app: ['osteo'], immagine: svgDottore, titolo: 'Profilo osteopata', testo: 'mettere testo osteopata' },
        { app: ['osteo', 'physio'], immagine: svgSegreteria, titolo: 'Profilo segreteria', testo: 'Attraverso il profilo di Segretaria puoi monitorare e aggiornare le anagrafiche e i pagamenti dei pazienti e i dettagli fiscali e amministrativi dei tuoi dipendenti; puoi inoltre leggere le cartelle cliniche, senza poterle modificare.' },
        { app: ['osteo', 'physio'], immagine: svgPaziente, titolo: 'Profilo paziente', testo: 'Accedendo al proprio profilo ogni tuo paziente può visionare e aggiornare i suoi dati anagrafici, leggere la propria cartella clinica e il diario delle sedute, ripercorrere la situazione pagamenti, firmare e caricare online i documenti legali e le istruzioni per gli esercizi da eseguire da casa.' },
        { app: ['osteo', 'physio'], immagine: svgAmministrazione, titolo: 'Profilo amministrazione', testo: 'Il profilo di Amministrazione è la cabina di comando della tua Clinica: da qua puoi creare i profili dei fisioterapisti e i profili della Segreteria, monitorare i pagamenti e aggiornare i dati amministrativi del tuo studio.' },
        { app: ['osteo', 'physio'], immagine: svgSalaAttesa, titolo: 'Profilo totem', testo: 'Per velocizzare la registrazione su Physio Archive dei tuoi pazienti, attraverso il profilo totem i tuoi nuovi clienti potranno registrare il proprio profilo in piena autonomia, nell’attesa di essere visitati; una soluzione per risparmiare il tuo tempo e quello dei tuoi pazienti, in tutta sicurezza.' },
    ]

    return profili.filter((profilo) => {
        return profilo.app.includes(getAppUtilizzata())
    })
}

const getTestoPricing = () => {
    switch (getAppUtilizzata()) {
        case 'osteo':
            return `Osteo Archive assicura servizi di qualità a prezzi accessibili: Il profilo osteopata è al prezzo di 360 euro all'anno. Il profilo "segreteria" é attivabile a 150 euro annui.`
        case 'physio':
            return `Physio Archive assicura servizi di qualità a prezzi accessibili: ai fisioterapisti iscritti all'Associazione Italiana di Fisioterapia - AIFI è riservata la possibilità di attivare un profilo "Fisioterapista" a 250 euro annui anziché 350. Il profilo "segreteria" é attivabile a 150 euro annui.`
        default:
            break
    }
}

export const getDatiPricing = () => {
    const testo = getTestoPricing()

    const permessi = {
        provaGratuita: '30 giorni di prova gratuiti per i primi due fisioterapisti della clinica',
        pazienti: 'Vedi e crea i nuovi pazienti della tua clinica',
        datiPaziente: 'Vedi e modifica anagrafica, storia clinica e abitudini dei pazienti',
        datiPazienteOutput: 'Vedi e modifica anagrafica, storia clinica e abitudini',
        anagrafica: `Vedi e modifica l'anagrafica dei pazienti`,
        esercizi: 'Vedi e assegna esercizi ai pazienti',
        eserciziOutput: 'Vedi gli esercizi assegnati',
        fatture: 'Vedi e genera fatture per i tuoi clienti',
        fattureAutorizzato: `Vedi e genera fatture per i tuoi clienti (se autorizzato dall'amministrazione)`,
        fattureOutput: 'Vedi e scarica le tue fatture',
        lesioni: 'Vedi modifica e crea disfunzioni ai pazienti',
        lesioniOutput: 'Vedi le tue disfunzioni e le relative informazioni',
        dipendenti: `Vedi e aggiungi dipendenti alla tua clinica e gestisci i suoi abbonamenti`,
        dipendentiAutorizzato: `Vedi e aggiungi dipendenti alla tua clinica e gestisci i suoi abbonamenti (se autorizzato dall'amministrazione)`,
        creaPaziente: 'Crea i pazienti per la tua clinica',
        sistemaTs: 'Configura i tuoi dati per inviare le fatture al sistema TS',
        sistemaTsAutorizzato: `Configura i tuoi dati per inviare le fatture al sistema TS (se autorizzato dall'amministrazione)`,
        documenti: 'Fai firmare e scarica velocemente i documenti necessari dei pazienti',
        documentiOutput: 'Scarica i documenti che hai firmato',
    }

    const profili = [
        {
            titolo: 'Fisioterapista',
            descrizione: '',
            color: 'info',
            variant: 'gradient',
            prezzo: 350,
            descrizionePrezzo: 'anno',
            permessi: [permessi.provaGratuita, permessi.pazienti, permessi.datiPaziente, permessi.esercizi, permessi.fattureAutorizzato, permessi.lesioni, permessi.dipendentiAutorizzato, permessi.sistemaTsAutorizzato, permessi.documenti],
            app: ['physio'],
        },
        {
            titolo: 'Fisioterapista AIFI',
            descrizione: 'Sconto speciale per i clienti AIFI',
            color: 'dark',
            variant: 'gradient',
            prezzo: 250,
            descrizionePrezzo: 'anno',
            permessi: [permessi.provaGratuita, permessi.pazienti, permessi.datiPaziente, permessi.esercizi, permessi.fattureAutorizzato, permessi.lesioni, permessi.dipendentiAutorizzato, permessi.sistemaTsAutorizzato, permessi.documenti],
            app: ['physio'],
        },
        {
            titolo: 'Osteopata',
            color: 'info',
            variant: 'gradient',
            prezzo: 350,
            descrizionePrezzo: 'anno',
            permessi: [permessi.provaGratuita, permessi.pazienti, permessi.datiPaziente, permessi.esercizi, permessi.fattureAutorizzato, permessi.lesioni, permessi.dipendentiAutorizzato, permessi.documenti],
            app: ['osteo'],
        },
        {
            titolo: 'Segreteria',
            color: 'info',
            variant: 'gradient',
            prezzo: 150,
            descrizionePrezzo: 'anno',
            permessi: [permessi.pazienti, permessi.anagrafica, permessi.dipendentiAutorizzato, permessi.sistemaTs, permessi.documenti],
            app: ['physio', 'osteo'],
        },
        {
            titolo: 'Paziente',
            color: 'info',
            variant: 'gradient',
            prezzo: 0,
            descrizionePrezzo: 'Sempre incluso',
            permessi: [permessi.datiPazienteOutput, permessi.eserciziOutput, permessi.fattureOutput, permessi.lesioniOutput, permessi.documentiOutput],
            app: ['physio', 'osteo'],
        },
        {
            titolo: 'Totem',
            color: 'info',
            variant: 'gradient',
            prezzo: 0,
            descrizionePrezzo: 'Sempre incluso',
            permessi: [permessi.creaPaziente],
            app: ['physio', 'osteo'],
        },
        {
            titolo: 'Amministrazione',
            color: 'info',
            variant: 'gradient',
            prezzo: 0,
            descrizionePrezzo: 'Sempre incluso',
            permessi: [permessi.dipendenti, permessi.sistemaTs],
            app: ['physio', 'osteo'],
        },
    ]

    return {
        testo: testo,
        profili: profili.filter((profilo) => {
            return profilo.app.includes(getAppUtilizzata())
        }),
    }
}

export const getTestiPaginaDipendenti = () => {
    switch (getAppUtilizzata()) {
        case 'osteo':
            return { label: 'Lista osteopati', bottone: 'crea nuovo osteopata' }
        case 'physio':
            return { label: 'Lista fisioterapisti', bottone: 'crea nuovo fisioterapista' }
        default:
            break
    }
}

export const getProfessioneCreaDipendente = () => {
    switch (getAppUtilizzata()) {
        case 'osteo':
            return 'osteopata'
        case 'physio':
            return 'fisioterapista'
        default:
            break
    }
}
