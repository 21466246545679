import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { LEGGI_DATE_TUTORIAL } from './../azioni/LEGGI_DATE_TUTORIAL'
import { collection, query, orderBy, onSnapshot, where } from 'firebase/firestore'

export class watcherDateTutorial extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione() {
        let date = {}
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() - 1)
        /*  const data = firebase.firestore.Timestamp.fromDate(new Date().setDate(new Date().getDate() + 1)) */

        const q = query(collection(db, 'dateTutorial'), where('data', '>', tomorrow), orderBy('data', 'desc'))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                date[doc.id] = doc.data()
            })
            LEGGI_DATE_TUTORIAL(date)
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            //this.funzioneConnessione();
        }
    }
}
