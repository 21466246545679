import React, { useEffect, useState } from 'react'
import FormInput from 'formMaker/formInput'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import valida from './../fields/validazione'
import VediForm from 'formMaker/vediForm'
import { CHIUDI_POP_UP_FIELD_LIST } from 'data/azioni/POP_UP_FIELD_LIST'
import { connect } from 'react-redux'

export function popUpFieldList(props) {
    const handleClose = () => {
        CHIUDI_POP_UP_FIELD_LIST()
    }
    const [stato, setStato] = useState({})

    useEffect(
        (e) => {
            if (props.aperto) {
                setStato(props.parametro.dati)
            }
            //setErrori(resetErrori())
        },
        [props.aperto],
    )

    const aggiungi = async (e) => {
        props.parametro.chiuso(e, props.parametro.index)
        handleClose()
    }

    const renderForm = () => {
        if (props.aperto) {
            return <VediForm vista='input' isSubInput={true} soloInput={true} form={props.parametro.model} dati={stato} annulla={() => handleClose()} salva={(e) => aggiungi(e)} />
        }
        return ''
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>{props.titolo}</DialogTitle>
            <DialogContent dividers={true}>{renderForm()}</DialogContent>
            {/*  <DialogActions>
                <MDButton color='error' variant='text' onClick={handleClose}>
                    annulla
                </MDButton>
                <MDButton color='success' variant='text' onClick={() => aggiungi()}>
                    salva
                </MDButton>
            </DialogActions> */}
        </Dialog>
    )
}


function mapStateToProps(state){
    return {
        aperto : state.reducerPopUpFieldList?.aperto || false,
    parametro : state.reducerPopUpFieldList?.parametro || {},
    }
}

export default connect(mapStateToProps, null)(popUpFieldList)
