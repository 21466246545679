import { store } from './../store'

export const LEGGI_CREDENZIALI = (nomeParametro) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'LEGGI_CREDENZIALI',
            payload: nomeParametro,
        })
        resolve()
    })
}
