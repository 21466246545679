import React from 'react'
import VediForm from 'formMaker/vediForm'
import { formDiagnosi } from 'formModel/formDiagnosi'

export default function elementoAssegnaDiagnosi(props) {
    const annulla = () => {
        props.chiudi()
    }

    const salva = (dati) => {
        dati.tipo = 'fieldDiagnosi'
        props.salva(dati)
    }

    return <VediForm form={formDiagnosi()} dati={{}} annulla={() => annulla()} salva={(dati) => salva(dati)} vista='input' />
}
