import React from 'react'
import { InputBoolean } from './fieldBoolean'
import { InputData } from './fieldData'
import { InputSelect } from './fieldSelect'
import MDBox from 'components/MDBox'

const modelBoolean = {
    id: 'pagamentoAnticipato',
    tipo: 'fieldBoolean',
    associazione: 'pagamentoAnticipato',
    label: 'Pagamento anticipato',
    value: false,
    details: {
        costumCss: ['lineaSeServe'],
    },
}

const modelSelect = {
    id: 'data',
    tipo: 'fieldSelect',
    associazione: 'data',
    label: 'Data pagamento :',
    value: 0,
    details: {
        toShow: 'testo',
        toSave: 'data',
        scelte: [
            { data: 0, testo: 'Data emissione' },
            /* { data: 3, testo: '3 giorni' },
            { data: 7, testo: 'Una settimana' },
            { data: 15, testo: '15 giorni' },
            { data: 30, testo: 'un mese' }, */
        ],
        complex: true,
    },
}

const modelData = {
    id: 'data',
    tipo: 'fieldData',
    associazione: 'data',
    label: 'Data pagamento :',
    value: new Date(),
    details: {
        tornaStringa: true,
        costumCss: ['lineaSeServe'],
    },
}

export function InputDataPagamentoFatture(props) {
    const aggiornaPagamentoAnticipato = (valore) => {
        props.azione(props.model.id, { pagamentoAnticipato: valore, data: valore ? new Date() : 0 })
    }

    const aggiornaData = (valore) => {
        props.azione(props.model.id, Object.assign(props.value, { data: valore }))
    }

    const renderSelect = <InputSelect model={modelSelect} value={props.value.data} errore={props.errore} azione={(a, b) => aggiornaData(b)} />
    const renderData = <InputData model={modelData} value={props.value.data} errore={props.errore} azione={(a, b) => aggiornaData(b)} />

    return (
        <MDBox width="100%">
            <InputBoolean model={modelBoolean} value={props.value.pagamentoAnticipato} errore='' azione={(a, b) => aggiornaPagamentoAnticipato(b)} />
            <MDBox mt={2}>{props.value.pagamentoAnticipato ? renderData : renderSelect}</MDBox>
        </MDBox>
    )
}

export function OutputDataPagamentoFatture() {
    return <div>fieldDataPagamentoFatture</div>
}
