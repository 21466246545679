import React from 'react'
import { connect } from 'react-redux'
import { getListaFeedback } from 'getter/getterFeedback'
import MDBox from 'components/MDBox'
import { formContatti } from 'formModel/formContatti'
import VediForm from 'formMaker/vediForm'
import { formattaDataDaFirebase } from 'data/strumenti'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { SEGNA_FEEDBACK_LETTO } from 'data/azioni/SEGNA_FEEDBACK_LETTO'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export const paginaVediFeedback = (props) => {
    let form = Object.assign(formContatti())
    form.parts.push({
        id: 'isTutorial',
        tipo: 'fieldBoolean',
        associazione: 'isTutorial',
        label: 'isTutorial :',
        value: false,
    })
    form.parts.push({
        id: 'utente',
        tipo: 'fieldText',
        associazione: 'utente',
        label: 'utente :',
        value: '',
    })

    const leggi = async (id) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await SEGNA_FEEDBACK_LETTO(id)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Feedback letto' } })
    }

    return (
        <MDBox className='flex-a-capo'>
            {props.lista.map((feedback) => {
                return (
                    <MDBox key={feedback.id} p={2} bgColor='white' height='max-content' width='max-content' shadow='lg' borderRadius='lg' variant='gradient' mb={2} mr={2}>
                        <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                            <MDTypography color={'white'} variant='h5'>
                                {formattaDataDaFirebase(feedback.contenuto.data)}
                            </MDTypography>
                        </MDBox>
                        <VediForm form={form} dati={feedback.contenuto} noBottoni={true} />
                        <MDBox className='contenitore-form-bottoni'>
                            <MDButton variant='contained' color='primary' onClick={()=>leggi(feedback.id)}>
                                segna come letto
                            </MDButton>
                        </MDBox>
                    </MDBox>
                )
            })}
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        lista: getListaFeedback(),
    }
}

export default connect(mapStateToProps, null)(paginaVediFeedback)
