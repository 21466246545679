import { store } from './../store' 
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const REVOCA_ACCESSO = (id) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    return new Promise((resolve, reject) => {
        CHIAMA_SERVER(`revocaCredenziali`, {idUtente : id}).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
