import { trasformaOggettoInArray } from 'data/strumenti'
import { formDettagliEsercizi } from 'formModel/formDettagliEsercizi'
import { formNuovoEsercizioDaAssegnare } from 'formModel/formNuovoEsercizioDaAssegnare'
import { store } from './../data/store'
import { getEserciziPaziente, getPazienteLista } from './getterPaziente'
import { getProfiloUtenteLoggato } from './getterUtenteLoggato'

export const getListaEserciziFiltrati = () => {
    let lista = window.listaEsercizi
    let eserciziFiltrati = []
    let filtri = store.getState().reducerFiltriEsercizio || {}
    const titolo = filtri.titolo || ''
    const tags = filtri.tags || []
    if (titolo === '' && tags.length === 0) {
        return {
            errore: false,
            lista: lista,
        }
    }
    eserciziFiltrati = lista.filter((esercizio) => {
        let comparazioneTitolo = () => {
            if (titolo === '') {
                return true
            }
            let titoloCacheLowerCase = esercizio.contenuto.titolo.toLowerCase()
            let titoloDaCercareLowerCase = titolo.toLowerCase()
            return titoloCacheLowerCase.indexOf(titoloDaCercareLowerCase) !== -1
        }

        let comparazioneTags = () => {
            if (tags.length === 0) {
                return true
            }
            if (esercizio.contenuto.tags === undefined) {
                return false
            }
            return tags.every((v) => esercizio.contenuto.tags.includes(v))
        }

        return comparazioneTitolo() && comparazioneTags()
    })
    return {
        errore: eserciziFiltrati.length === 0,
        lista: eserciziFiltrati,
    }
}

export const getIdEsercizioSelezionato = () => {
    return store.getState().url[4] || ''
}

export const getEsercizioReducer = () => {
    return store.getState().reducerEsercizio?.contenuto || {}
}

const getPosologiaEsercixio = () => {
    const idEsercizio = getIdEsercizioSelezionato()
    const lista = getEserciziPaziente()
    const index = lista.findIndex((esercizio) => {
        return esercizio.idEsercizio === idEsercizio
    })
    return index === -1 ? '' : lista[index].commento
}

export const getEsercizioSelezionato = () => {
    const esercizio = getEsercizioReducer()
    if (getProfiloUtenteLoggato() === 'profilo-paziente') {
        return Object.assign(esercizio, { commenti: getPosologiaEsercixio() })
    }
    return esercizio
}
export const getSkeletonEsercizioSelezionato = () => {
    if (store.getState().reducerEsercizio === undefined) {
        return true
    }
    return store.getState().reducerEsercizio.skeleton
}

export const isEsercizioDaEliminare = () => {
    const lista = getListaUtilizzi()
    return lista.length === 0
}

export const getListaUtilizzi = () => {
    const esercizio = getEsercizioSelezionato()
    var lista = trasformaOggettoInArray(esercizio.utilizzi).map((paziente) => {
        let datiPaziente = getPazienteLista(paziente.contenuto.idPaziente)
        return { id: paziente.contenuto.idPaziente, contenuto: { anno: datiPaziente.anno, nome: datiPaziente.nome, cognome: datiPaziente.cognome } }
    })

    trasformaOggettoInArray(esercizio.schede).forEach((paziente) => {
        let datiPaziente = getPazienteLista(paziente.contenuto.idPaziente)
        const elemento =  { id: paziente.contenuto.idPaziente, contenuto: { anno: datiPaziente.anno, nome: datiPaziente.nome, cognome: datiPaziente.cognome } }
        lista.push(elemento)
    })


    return lista
}

export const getListaFileEsercizio = () => {
    const esercizio = getEsercizioSelezionato()
    let lista = trasformaOggettoInArray(esercizio.file).map((file) => {
        return { id: file.id, data: file.contenuto.etichettaUniversale.dataCreazione, isVideo: file.contenuto.isVideo }
    })
    lista.sort((a, b) => {
        let dataA = a.data.seconds
        let dataB = b.data.seconds
        return dataB - dataA
    })
    return lista
}

export const getFormPaginaEsercizio = () => {
    if (getProfiloUtenteLoggato() === 'profilo-paziente') {
        return formNuovoEsercizioDaAssegnare()
    }
    return formDettagliEsercizi()
}

export const getTitoloEsercizio = (id) => {
    const index = window.listaEsercizi.findIndex((esercizio) => {
        return esercizio.id === id
    })
    if (index === -1) {
        return "Esercizio non trovato"
    }
    return window.listaEsercizi[index].contenuto.titolo
}
