import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'

export default function popUpDescrizioneBandiera(props) {
    const handleClose = () => {
        props.chiudi()
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>{props.parametro.titolo}</DialogTitle>
            <DialogContent dividers={true}>{props.parametro.descrizione}</DialogContent>
            <DialogActions>
                <MDButton color='primary' variant='text' onClick={handleClose}>
                    chiudi
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
