import React from 'react'
import ElementoVediSedute from 'componenti/elementoVediSedute'
import ClassRoundedIcon from '@mui/icons-material/ClassRounded'
import { connect } from 'react-redux'
import { getPermessiUtente } from 'getter/getterPermessi'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export function paginaDiarioClinico(props) {
    const nuovaSeduta = () => {
        APRI_POP_UP({ id: 'nuova-seduta', parametro: { navigate: props.navigate } })
    }

    const renderFab = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => nuovaSeduta()}>
                <AddIcon />
            </Fab>
        )
    }

    return (
        <React.Fragment>
            <ElementoVediSedute navigate={props.navigate} titolo='Diario clinico' icona={<ClassRoundedIcon color='white' sx={{ mr: 1 }} />} />
            {renderFab()}
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        permessi: getPermessiUtente().lesioniPaziente.input,
    }
}

export default connect(mapStateToProps, null)(paginaDiarioClinico)
