import MDBox from 'components/MDBox'
import React from 'react'
import { connect } from 'react-redux'
import MDTypography from 'components/MDTypography'
import VediForm from 'formMaker/vediForm'
import { formDefaultFatture } from 'formModel/formDefaultFatture'
import { SALVA_DEFAULT_FATTURE } from 'data/azioni/SALVA_DEFAULT_FATTURE'

export const paginaConfigurazioneTesseraSanitaria = (props) => {
    return (
        <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
            <MDBox lineHeight={0} ml={2}>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <MDTypography color={'white'} variant='h4' mr={1}>
                        TS
                    </MDTypography>
                    <MDTypography color={'white'} variant='h5'>
                        Configurazione base fatture
                    </MDTypography>
                </MDBox>
            </MDBox>
            <VediForm form={formDefaultFatture()} dati={props.dati} salva={(e) => SALVA_DEFAULT_FATTURE(e)} />
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        dati: state.reducerConfigurazioneBaseFatture,
    }
}

export default connect(mapStateToProps, null)(paginaConfigurazioneTesseraSanitaria)
