import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import logo from 'assets/images/svgLogoPhysioBlu.svg'

import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedIn from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Icon } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CallIcon from '@mui/icons-material/Call'

const socials = [
    {
        icon: <FacebookIcon />,
        link: 'https://www.facebook.com/physioarchive',
    },
    {
        icon: <InstagramIcon />,
        link: 'https://www.instagram.com/physioarchive/',
    },
    {
        icon: <LinkedIn />,
        link: 'https://www.linkedin.com/company/physio-archive/',
    },
    {
        icon: <YouTubeIcon />,
        link: 'https://www.youtube.com/channel/UCJ8gdi4kyPl2qgvMyz6ltbw',
    },
]


export default function footer(props) {
    return (
        <MDBox component='footer'>
            <Container>
                <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} md={4} sx={{ ml: 'auto', mb: 3 }}>
                        <MDBox>
                            {/*  <MDBox sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '10px' }}>
                                <Link to={''}>
                                    <MDBox component='img' src={logo} alt={'Physio Archive'} maxWidth='3rem' mb={2} />
                                </Link>
                                <MDBox component='img' src={logo} alt={'Physio Archive'} maxWidth='3rem' mb={2} />
                            </MDBox> */}
                            <MDTypography variant='h6'>{'Physio Archive'}</MDTypography>
                        </MDBox>
                        <MDBox display='flex' alignItems='center' mt={3}>
                            {socials.map(({ icon, link }, key) => (
                                <MDTypography key={link} component='a' href={link} target='_blank' rel='noreferrer' variant='h5' color='dark' opacity={0.8} mr={key === socials.length - 1 ? 0 : 2.5}>
                                    {icon}
                                </MDTypography>
                            ))}
                        </MDBox>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ ml: 'auto', mb: 3 }}>
                        <MDBox component='ul' p={0} m={0} sx={{ listStyle: 'none' }}>
                            {props.rotte.map(({ name, route }) => (
                                <MDBox key={name} component='li' p={0} m={0} lineHeight={1.25}>
                                    <MDTypography component={Link} to={route} variant='button' fontWeight='regular' textTransform='none' /* 'capitalize' */>
                                        {name}
                                    </MDTypography>
                                </MDBox>
                            ))}
                        </MDBox>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Grid item xs={6} md={2} sx={{ mb: 3 }}>
                            <MDTypography display='block' variant='button' fontWeight='bold' textTransform='none' /* 'capitalize' */ mb={1}>
                                CONTATTI
                            </MDTypography>
                            <MDBox component='ul' p={0} m={0} sx={{ listStyle: 'none' }}>
                                <MDBox key={name} component='li' p={0} m={0} lineHeight={1.25}>
                                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' mb={1}>
                                        <Icon sx={{ mr: 1 }} color='dark'>
                                            email
                                        </Icon>
                                        <MDTypography variant='button' fontWeight='regular'>
                                            physioarchive.info@gmail.com
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' mb={1}>
                                        <CallIcon sx={{ mr: 1 }} />
                                        <MDTypography variant='button' fontWeight='regular'>
                                            +39 3459253577
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' mb={1}>
                                        <LocationOnIcon sx={{ mr: 1 }} />
                                        <MDTypography variant='button' fontWeight='regular'>
                                            Strada Principi 13 Bardinetto (SV)
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' mb={1}>
                                        <MDTypography variant='button' fontWeight='regular' textTransform='none' /* 'capitalize' */>
                                            P.IVA : 01780060099
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MDBox>
    )
}
