import { eventiCache } from 'mixing/eventiCache'
import React, { Component } from 'react'
import ElementoCalendario from 'componenti/elementoCalendario'
import MDBox from 'components/MDBox'
import { InputSelect } from 'fields/fieldSelect'
import { getListaDottori } from 'getter/getterDipendenti'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getAppuntamentiFiltrati } from 'getter/getterAppuntamenti'
import { getPermessiUtente } from 'getter/getterPermessi'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'

export default class paginaCalendario extends eventiCache(Component) {
    constructor(props) {
        super(props)
        this.state.fisioterapista = ''
    }

    aggiorna(e) {
        window.dispatchEvent(new CustomEvent('cercaAppuntamenti', { detail: e }))
    }

    renderFiltro() {
        const permessi = getPermessiUtente(getVeroUtenteLoggato())
        if (!permessi.calendarioDipendenti) {
            return ''
        }
        const model = {
            id: 'idDottore',
            tipo: 'fieldSelect',
            associazione: 'idDottore',
            label: 'Fisioterapista :',
            value: '',
            details: {
                toShow: 'nome',
                toSave: 'id',
                scelte: getListaDottori(),
                complex: true,
                color: 'white',
                sx: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'white !important',
                    },
                },
                InputLabelProps: { style: { color: '#fff' } },
                testoBianco: true,
            },
            validazione: 'testoRichiestoSelect',
        }
        return (
            <MDBox sx={{ mt: -6, mx: 'auto', maxWidth: '1000px', width: '50%', minWidth : '200px' }} bgColor='info' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                <InputSelect
                    model={model}
                    value={this.state.fisioterapista}
                    errore={''}
                    azione={(a, b) => {
                        this.setState({ fisioterapista: b })
                    }}
                />
            </MDBox>
        )
    }

    getEventi(){
        const permessi = getPermessiUtente(getVeroUtenteLoggato())
        //if (getProfiloUtenteLoggato() !== 'profilo-segreteria') {
        if (!permessi.calendarioDipendenti) {
            return this.state.listaAppuntamentiWindow
        }
        return getAppuntamentiFiltrati(this.state.fisioterapista)
    }

    render() {
        return (
            <MDBox sx={{ mt: 3, mx: 'auto', maxWidth: '1000px', width: '100%' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                {this.renderFiltro()}
                <ElementoCalendario fisioterapista={this.state.fisioterapista} eventi={this.getEventi()} aggiorna={(e) => this.aggiorna(e)} />
            </MDBox>
        )
    }
}
