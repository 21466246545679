import { getPresidio } from '../../getter/getterUtente'
import {  getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { store } from './../store'
import { doc, setDoc } from 'firebase/firestore'

export const SALVA_DEFAULT_FATTURE = async (dati) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    await setDoc(doc(db, 'presidi', getPresidio(getVeroUtenteLoggato()), 'cache', 'defaultFattura'), dati)
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
