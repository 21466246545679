import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs
// funzioni generali
const styles = {
    titolo: { fontSize: 20, bold: true, margin: [0, 5] },
    paragrafo: { fontSize: 8, alignment: 'justify', margin: [0, 5] },
    parolaBold: { fontSize: 10, bold: true },
}

const paragrafo = (testo) => {
    return { text: testo, style: 'paragrafo' }
}
const titolo = (testo) => {
    return { text: testo, style: 'titolo' }
}
const bold = (testo) => {
    return { text: testo, style: 'parolaBold' }
}

const lista = (lista) => {
    return { ul: lista }
}

// funzioni privacy
const primoParagrafoPaziente = (dati, clinica) => {
    return ['Il sottoscritto ', bold(dati.nome), ' ', bold(dati.cognome), ' dichiara di essere stato preventivamente informato - ai sensi degli artt. 13 e 14 del Regolamento CE n. 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali acquisiti a trattati da ', bold(clinica), ' che i dati personali da Lei forniti saranno oggetto di trattamento nel rispetto della normativa prevista dal suddetto Regolamento e che:']
}

const primoParagrafoTutore = (tutore, dati, clinica) => {
    return ['Il sottoscritto ', bold(tutore.nomeTutore), ' ', bold(tutore.cognomeTutore), 'CF: ', bold(tutore.cfTutore), ', tutore di ', bold(dati.nome), ' ', bold(dati.cognome), ' dichiara di essere stato preventivamente informato - ai sensi degli artt. 13 e 14 del Regolamento CE n. 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali acquisiti a trattati da ', bold(clinica), ' che i dati personali da Lei forniti saranno oggetto di trattamento nel rispetto della normativa prevista dal suddetto Regolamento e che:']
}

const listaRegolamnento = (clinica) => {
    return [
        paragrafo([bold('Finalità  del trattamento'), ' Il trattamento dei dati è finalizzato unicamente al corretto svolgimento delle attività  necessarie per la prevenzione, la diagnosi fisioterapica, la cura, la riabilitazione o per le altre prestazioni da voi richieste, farmaceutiche e specialistiche.']),
        paragrafo([bold('Modalità  del trattamento dei dati personali'), " Il trattamento è realizzato attraverso operazioni, effettuate con o senza l'ausilio di strumenti elettronici e consiste nella raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione di tali dati. Il trattamento è svolto dal titolare e dagli incaricati espressamente autorizzati dal titolare."]),
        paragrafo([bold('Conferimento dei dati e rifiuto'), " Il conferimento dei dati personali comuni, sensibili, genetici, relativi alla salute è necessario ai fini dello svolgimento delle attività  necessarie per la prevenzione, la diagnosi fisioterapica, la cura, la riabilitazione o per le altre prestazioni da voi richieste, farmaceutiche e specialistiche. Il rifiuto dell'interessato di conferire i dati personali comporta l'impossibilità  di svolgere la prestazione medica richiesta."]),
        paragrafo([bold('Comunicazione dei dati'), ' I dati personali raccolti non saranno oggetto di diffusione salvo per le finalità  previste dalla legge ed esclusivamente per le finalità  del trattamento. I dati sensibili, genetici e quelli relativi alla salute saranno conosciuti solo ed esclusivamente da parte di personale sanitario della struttura.']),
        paragrafo([bold('Conservazione dei dati'), ' I dati personali raccolti dalla ', bold(clinica), " saranno conservati per il tempo strettamente necessario all'attività  medica e, in ogni caso, per un periodo non superiore a dieci anni, sulla piattaforma Pyisio archive"]),
    ]
}
const titolare = (clinica) => {
    return ['Titolare del trattamento è: ', bold(clinica)]
}

const listaDiritti = () => {
    return [
        paragrafo('chiedere al titolare del trattamento l&#39;accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità  dei dati;'),
        paragrafo('ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del trattamento cui li ha forniti;'),
        paragrafo('revocare il consenso in qualsiasi momento senza pregiudicare la liceità  del trattamento basata sul consenso prestato prima della revoca;'),
        paragrafo("Proporre reclamo all'Autorità  Garante."),
    ]
}

const esercizioDiritti = (mail) => {
    return ['I suddetti diritti possono essere esercitati altresà¬ mediante comunicazione scritta da inoltrare al seguente indirizzo e-mail ', bold(mail), ' ovvero tramite lettera raccomandata indirizzata al Titolare del Trattamento.']
}

const luogoEData = (luogo, data) => {
    return ['Luogo e data: ', bold(luogo), ' il ', bold(data)]
}

const pazienteOTutore = (data) => {
    if (data.isTutore) {
        return paragrafo(primoParagrafoTutore(data.datiTutore, data.datiPaziente, data.presidio.nome))
    } else {
        return paragrafo(primoParagrafoPaziente(data.datiPaziente, data.presidio.nome))
    }
}

const contentPrivacy = (data, firma) => {
    return [
        titolo('Informativa trattamento dati personali'),

        pazienteOTutore(data),
        //paragrafo(primoParagrafo(data.datiPaziente, data.presidio.nome)),

        lista(listaRegolamnento(data.presidio.nome)),
        paragrafo(titolare(data.presidio.nome)),
        paragrafo('Presto il pieno consenso al trattamento dei dati personali.'),
        titolo("Diritti dell'interessato."),
        paragrafo('In ottemperanza a quanto previsto dal Regolamento CEE 2016/679 Lei ha diritto di:'),
        lista(listaDiritti()),
        paragrafo(esercizioDiritti(data.presidio.mail)),
        paragrafo(luogoEData(data.presidio.comune, data.data)),
        paragrafo('Firma del paziente'),
        {
            image: firma,
            width: 150,
        },
    ]
}

export const makePdfPrivacy = (data, firma) => {
    var contenuto = {
        styles: styles,
        content: contentPrivacy(data, firma),
    }

    return new Promise((resolve, reject) => {
        pdfMake.createPdf(contenuto).getBlob((blob) => {
            resolve(blob)
        })
    })
}
