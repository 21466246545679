import React, { Component } from 'react'
import isEqual from 'lodash-es/isEqual'
import SignaturePad from 'signature_pad'

export default class elementoChartDolori extends Component {
    constructor(props) {
        super(props)
        this.state = { pad: {}, pointer: { left: '0%', top: '0%' }, id: props.input ? 'input-chart-dolori' : props.disegno ? 'disegno-chart-dolori' : 'output-chart-dolori' }
    }

    renderSvg() {
        let cursor = 'standard'
        if (this.props.input || this.props.apriPopUp !== undefined) {
            cursor = 'pointer'
        }
        return (
            <svg id='lavagna' viewBox='0 0 1500 1500' x>
                <image href='/images/corpoUmano.jpg' x='0' y='0' height='1500' width='1500' />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'cranio')
                    }}
                    points='379,22 338,36 317,74 314,117 323,165 329,194 362,225 392,224 434,184 449,119 445,66 420,35 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'spalla dx')
                    }}
                    points='284,263 246,248 220,251 202,264 190,289 185,328 185,334 200,339 216,344 232,349 244,352 257,330 271,307 280,280 284,271'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'spalla sx')
                    }}
                    points='530,251 489,267 492,288 501,317 522,340 549,348 572,343 589,330 584,306 570,277 554,260 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'braccio dx')
                    }}
                    points='252,367 231,357 207,350 187,354 186,368 182,404 179,437 180,470 227,474 252,473 257,449 260,415 259,387'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'braccio sx')
                    }}
                    points='589,344 554,357 522,367 521,398 521,445 529,480 601,463 600,425 596,392 589,352'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'avambraccio dx')
                    }}
                    points='252,542 216,540 184,535 165,532 166,555 165,579 162,622 160,654 175,662 192,665 212,670 217,659 229,625 241,590 249,560 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'avambraccio sx')
                    }}
                    points='616,527 575,543 540,549 541,573 551,607 564,631 579,656 589,676 637,665 631,628 629,581 621,537'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gomito dx')
                    }}
                    points='251,485 219,483 180,480 172,501 171,522 201,532 232,532 251,530 256,502 255,481 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gomito sx')
                    }}
                    points='604,471 569,478 530,486 535,515 536,532 572,534 611,518 605,490'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'polso dx')
                    }}
                    points='217,697 212,683 186,673 156,677 144,694 136,713 151,732 174,740 201,738 216,717 217,699 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'polso sx')
                    }}
                    points='640,674  592,685  586,708 591,731 621,742 650,727 664,714 649,694'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'dita dx')
                    }}
                    points='212,761 187,750 157,743 126,738 100,753 104,766 112,776 117,790 122,807 129,835 152,853 180,848 192,825 202,798 210,775'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'dita sx')
                    }}
                    points='592,761 636,748 675,742 690,762 684,793 680,829 672,844 652,859 620,838 606,808 596,781 591,767'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'coscia dx')
                    }}
                    points='355,730 254,729 240,814 249,914 265,979 366,984 382,895 387,794 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'coscia sx')
                    }}
                    points='527,726 536,812 505,971 401,982 397,799 426,732'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'ginocchio dx')
                    }}
                    points='371,993 269,994 265,1126 359,1124 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'ginocchio sx')
                    }}
                    points='502,982 405,997 406,1133 501,1130 499,1057'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gamba dx')
                    }}
                    points='360,1132 266,1134 265,1191 282,1316 344,1314 361,1192'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gamba sx')
                    }}
                    points='504,1140 407,1142 402,1199 406,1312 469,1314 502,1177 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'caviglia dx')
                    }}
                    points='346,1326 284,1327 281,1400 306,1404 352,1394'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'caviglia sx')
                    }}
                    points='467,1328 404,1327 392,1388 394,1399 426,1400 456,1397'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'piede dx')
                    }}
                    points='350,1407 282,1409 244,1446 244,1465 297,1472 352,1459 350,1416  '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'piede sx')
                    }}
                    points='395,1405 454,1404 475,1451 470,1467 400,1467 390,1447 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'cervicale')
                    }}
                    points='430,211 399,232 361,232 331,216 255,248 287,263 379,282 486,269 519,249 460,227 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'cranio')
                    }}
                    points='1071,24 1023,41 1000,86 1006,129 1014,161 1125,164 1133,91 1108,37 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'cervicale')
                    }}
                    points='1126,169 1011,167 1015,200 936,247 977,278 1145,279 1201,240 1115,210 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'dorsale')
                    }}
                    points='1149,290 977,284 931,297 935,447 960,535 1174,543 1194,450 1196,355 1192,292 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'lombare')
                    }}
                    points='1174,549 961,548 947,606 936,657 1056,662 1184,661 1181,592 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'bacino')
                    }}
                    points='1185,668 936,666 917,747 916,792 1057,798 1201,772 1190,693 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'spalla dx')
                    }}
                    points='1212,247 1160,284 1199,297 1202,342 1230,344 1262,338 1261,300 1242,260'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'spalla sx')
                    }}
                    points='966,287 921,247 885,267 866,305 864,335 895,348 930,343 934,302 964,284 927,250'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'braccio dx')
                    }}
                    points='1263,344 1230,349 1204,348 1201,372 1200,457 1200,492 1271,475 1269,418 1263,365 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'braccio sx')
                    }}
                    points='930,352 895,354 865,344 864,369 857,407 849,482 922,489 932,438 930,389'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'avambraccio dx')
                    }}
                    points='1285,537 1204,560 1241,674 1289,664 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'avambraccio sx')
                    }}
                    points='909,565 831,551 814,669 861,676 892,609 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gomito dx')
                    }}
                    points='1273,484 1200,501 1203,552 1283,529 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gomito sx')
                    }}
                    points='921,497 846,490 831,540 912,555'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'polso dx')
                    }}
                    points='1292,672 1242,682 1239,701 1246,717 1277,724 1310,703 1296,687 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'polso sx')
                    }}
                    points='862,684 806,674 796,692 795,711 815,726 856,717 864,699 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'dita dx')
                    }}
                    points='1317,717 1277,732 1237,738 1240,768 1262,827 1276,848 1292,855 1312,842 1346,755 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'dita sx')
                    }}
                    points='860,732 815,736 779,736 761,767 772,844 794,858 815,851 837,829 856,772 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'coscia dx')
                    }}
                    points='1202,785 1065,810 1065,859 1079,992 1180,989 1201,870'
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'coscia sx')
                    }}
                    points='1054,808 916,802 925,871 949,987 1049,987 1055,850 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'ginocchio dx')
                    }}
                    points='1178,997 1079,1000 1081,1049 1096,1096 1175,1096 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'ginocchio sx')
                    }}
                    points='1050,995 951,995 954,1087 1038,1090 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gamba dx')
                    }}
                    points='1179,1107 1097,1105 1091,1196 1111,1350 1159,1350 1186,1185 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0  )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'gamba sx')
                    }}
                    points='1040,1102 956,1101 952,1177 996,1354 1045,1350 1047,1171 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'caviglia dx')
                    }}
                    points='1161,1364 1111,1361 1105,1386 1107,1407 1161,1405 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'caviglia sx')
                    }}
                    points='1047,1359 995,1362 994,1387 999,1406 1032,1409 1054,1406 1054,1382 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'piede dx')
                    }}
                    points='1165,1412 1108,1412 1100,1442 1108,1466 1158,1466 1205,1466 1205,1450 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'piede sx')
                    }}
                    points='1054,1415 998,1415 974,1450 982,1466 1044,1464 1058,1434 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'epigastrio')
                    }}
                    points='341,422 405,419 431,479  318,478 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'ipocondrio dx')
                    }}
                    points='334,429 314,478 271,489 260,448 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'ipocondrio sx')
                    }}
                    points='412,424 509,456 497,493 435,480 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'mesogastrio')
                    }}
                    points='321,482 437,486 443,575 322,580 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'fossa iliaca dx')
                    }}
                    points='316,589 270,589 270,672 353,729 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'fossa iliaca sx')
                    }}
                    points='444,582 496,584 512,666 418,735 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'ipogastrio')
                    }}
                    points='323,586 443,584 404,751 369,750 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0    )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'fianco dx')
                    }}
                    points='316,481 317,580 269,584 270,493 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'fianco sx')
                    }}
                    points='440,487 495,501 496,578 444,572 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0 )' }}
                />
                <polygon
                    onClick={(e) => {
                        this.setAreaTag(e, 'dorsale')
                    }}
                    points='289,266 378,285 487,273 501,322 522,365 510,453 409,420 343,416 262,443 245,349 272,311 '
                    style={{ cursor: cursor, fill: 'rgba(0, 255, 0, 0.0   )' }}
                />
                '
            </svg>
        )
    }

    renderCanvas() {
        if (this.props.input) {
            return ''
        }
        setTimeout(() => {
            if (Object.keys(this.state.pad).length !== 0) {
                this.disegnaCanvas()
            }
        }, 10)
        return <canvas id='canvas-dolori-output' className='img-body-chart-output' style={{ borderTop: 'none !important' }} width={`${this.state.altezza}px`} height={`${this.state.altezza}px`}></canvas>
    }

    render() {
        let stylePointer = { left: this.state.pointer.left, top: this.state.pointer.top }
        if (this.props.input) {
            return (
                <div id='input-chart-dolori' className='container-chart-dolori'>
                    <div className='pointer' style={stylePointer}></div>
                    {this.renderSvg()}
                    {this.props.pointerGiaInseriti.map((singoloPointer, index) => {
                        let styleSingoloPointer = { left: singoloPointer.pointer.left, top: singoloPointer.pointer.top }
                        return (
                            <div key={index} className='pointer vecchio' style={styleSingoloPointer}>
                                {index + 1}
                            </div>
                        )
                    })}
                </div>
            )
        }
        if (this.props.disegno) {
            return (
                <div id='disegno-chart-dolori' style={{ boxSizing: 'border-box' }} className='container-chart-dolori'>
                    {this.renderSvg()}
                </div>
            )
        }
        return (
            <div id='output-chart-dolori' className='container-chart-dolori'>
                <div id='parte-centrale-body-chart' style={{ height: `${this.state.altezza}px` }}>
                    {this.renderSvg()}
                    {this.renderCanvas()}
                    {this.props.pointerGiaInseriti.map((singoloPointer, index) => {
                        let styleSingoloPointer = { left: singoloPointer.pointer.left, top: singoloPointer.pointer.top }
                        return (
                            <div key={index} className='pointer vecchio' style={styleSingoloPointer}>
                                {index + 1}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    disegnaCanvas() {
        this.state.pad.clear()
        let disegni = []
        this.props.pointerGiaInseriti.forEach((dolore) => {
            if (dolore.vediDisegno) {
                dolore.listaImmagini.forEach((singoloDisegno) => {
                    const valoreConversione = this.state.altezza / singoloDisegno.dimensione
                    var nuovoDisegno = { color: singoloDisegno.datiDisegno.color }
                    nuovoDisegno.points = singoloDisegno.datiDisegno.points.map((coordinate) => {
                        var nuove = { time: coordinate.time }
                        nuove.x = coordinate.x * valoreConversione
                        nuove.y = coordinate.y * valoreConversione
                        return nuove
                    })
                    disegni.push(nuovoDisegno)
                })
            }
        })
        this.state.pad.fromData(disegni)
    }

    creaPad() {
        let nuovoPad = new SignaturePad(document.getElementById('canvas-dolori-output'), {})
        document.getElementById('canvas-dolori-output').addEventListener('handleMouseUp', (e) => {})
        nuovoPad.off()
        let disegni = []
        /*  this.props.pointerGiaInseriti.forEach((dolore) => {
            
            const valoreConversione = this.state.altezza/dolore.immagine.dimensione
            disegni = disegni.concat(dolore.immagine.immagine.points.map((coordinate)=>{
                var nuove = {time : coordinate.time}
                nuove.x = coordinate.x * valoreConversione
                nuove.y = coordinate.y * valoreConversione
                return nuove
            }))
        })

        nuovoPad.fromData(disegni) */
        this.setState({ pad: nuovoPad })
    }

    /* componentDidUpdate(vecchieProps) {
        if (!isEqual(this.props.pointerGiaInseriti, vecchieProps.pointerGiaInseriti) && Object.keys(this.state.pad).length !== 0) {
            this.state.pad.clear()
            let disegni = []
            this.props.pointerGiaInseriti.forEach((dolore) => {
                const valoreConversione = this.state.altezza / dolore.immagine.dimensione
                console.log('guardaaaaa : ', dolore.immagine)

                dolore.immagine.immagine.forEach((singoloDisegno) => {
                    var nuovoDisegno = { color: singoloDisegno.color }

                    nuovoDisegno.points = singoloDisegno.points.map((coordinate) => {
                        var nuove = { time: coordinate.time }
                        nuove.x = coordinate.x * valoreConversione
                        nuove.y = coordinate.y * valoreConversione
                        return nuove
                    })

                    disegni.push(nuovoDisegno)
                })

              
            })
            this.state.pad.fromData(disegni)
        }
    }
 */
    getAltezza() {
        let nuovaLtezza = document.getElementById(this.state.id).clientWidth
        this.setState({ altezza: nuovaLtezza })
        return
    }

    componentDidMount() {
        if (!this.props.input) {
            this.creaPad()
        }
        window.addEventListener('resize', () => {
            if (this.props.input) {
                setTimeout(() => {
                    this.settaDimensioniContainer()
                }, 10)
            } else {
                this.getAltezza()
            }
        })
        if (this.props.pointer !== undefined) {
            this.setState({ pointer: this.props.pointer })
        }
        if (this.props.input) {
            this.settaDimensioniContainer()
        } else {
            this.getAltezza()
        }
    }

    setAreaTag(e, parteDelCorpoSelezionataa) {
        let rect = document.getElementById(this.state.id).getBoundingClientRect()
        let left = this.roundNumber(((e.clientX - rect.left) / rect.width) * 100)
        let top = this.roundNumber(((e.clientY - rect.top) / rect.height) * 100)
        let pointerPosition = {
            left: `${left}%`,
            top: `${top}%`,
        }
        this.setState({ pointer: pointerPosition })
        /* let areaArray = parteDelCorpoSelezionataa.split(' ')
        this.areaTag = areaArray */
        if (this.props.input) {
            this.props.aggiornaPointer(pointerPosition)
            this.props.aggiornaDistretto(parteDelCorpoSelezionataa)
            //this.dispatchEvent(new CustomEvent('new-area-tag', { bubbles: true, detail: parteDelCorpoSelezionataa }))
        }
        if (this.props.apriPopUp !== undefined) {
            this.props.apriPopUp({ pointer: pointerPosition, distretto: parteDelCorpoSelezionataa })
        }
    }

    getPosizioneClick(e) {
        let rect = document.getElementById(this.state.id).getBoundingClientRect()
        let left = this.roundNumber(((e.clientX - rect.left) / rect.width) * 100)
        let top = this.roundNumber(((e.clientY - rect.top) / rect.height) * 100)
        let pointerPosition = {
            left: `${left}%`,
            top: `${top}%`,
        }
        this.setState({ pointer: pointerPosition })
        if (this.props.input) {
            this.props.aggiornaPointer(pointerPosition)
            //this.dispatchEvent(new CustomEvent('new-pointer', { bubbles: true, detail: pointerPosition }))
        }
    }
    roundNumber(input) {
        return Math.round(input * 100) / 100
    }

    settaDimensioniContainer() {
        let larghezza = document.getElementById(this.state.id).clientWidth
        //document.getElementById('lavagna').style.width = `${larghezza}px`
        document.getElementById(this.state.id).style.height = `${larghezza}px`
    }
}
