import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formUtente } from 'formModel/formUtente'
import MDBox from 'components/MDBox'
import Grid from '@mui/material/Grid'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import ElementoInformazioniAbbonamento from 'componenti/elementoInformazioniAbbonamento'
import ElementoCambiaCredenziali from 'componenti/elementoCambiaCredenziali'
import VediForm from 'formMaker/vediForm'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { AGGIORNA_DATI_UTENTE } from 'data/azioni/AGGIORNA_DATI_UTENTE'
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded'
import { getTipoProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'

export class paginaMioProfilo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vista: 'anagrafica',
            vistaForm: 'output',
        }
    }

    render() {
        return (
            <Grid container spacing={6}>
                <Grid item xs={12} lg={12}>
                    <MDBox color='info' bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                        {this.renderAnagrafica()}
                    </MDBox>
                </Grid>

                <Grid item container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <MDBox color='info' bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                            {this.renderModifacaMail()}
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MDBox color='info' bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                            {this.renderModificaPassword()}
                        </MDBox>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12} lg={12}>
                    <MDBox color='info' bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                        {this.renderStatoAbbonamento()}
                    </MDBox>
                </Grid> */}
            </Grid>
        )
    }

    renderModifacaMail() {
        return <ElementoCambiaCredenziali tipo={'mail'} />
    }
    renderModificaPassword() {
        return <ElementoCambiaCredenziali tipo={'password'} />
    }

    async aggiornaAnagrafica(e) {
        return await AGGIORNA_DATI_UTENTE(e)
    }
    renderAnagrafica() {
        let form = formUtente(getProfiloUtenteLoggato(), getTipoProfiloUtenteLoggato())
        return (
            <React.Fragment>
                {/* <MDBox lineHeight={0} ml={2}>
                    <MDTypography color={'dark'} variant='h4'>
                        Anagrafica utente
                    </MDTypography>
                </MDBox> */}
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <MenuBookRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        Anagrafica utente
                    </MDTypography>
                </MDBox>
                <VediForm form={form} dati={this.props.anagrafica} salva={(e) => this.aggiornaAnagrafica(e)} />
            </React.Fragment>
        )
    }

    renderStatoAbbonamento() {
        return (
            <React.Fragment>
                <ElementoInformazioniAbbonamento scadenza='01/01/2023' titolare='a@b.com' statoAttivo='correttanebte attivato, perido di prova' />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        anagrafica: getUtenteLoggato(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaMioProfilo)
