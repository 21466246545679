import React from 'react'
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { Grid, Container } from '@mui/material'

import PaginaLanding from './pagine/paginaLanding'
import PaginaLogin from './pagine/paginaLogin'
import PaginaPasswordDimenticata from './pagine/paginaPasswordDimenticata'
import PaginaRegistrati from './pagine/paginaRegistrati'
import PaginaVantaggi from './pagine/paginaVantaggi'
import PaginaDiconoDiNoi from './pagine/paginaDiconoDiNoi'
import PaginaPricing from './pagine/paginaPricing'
import PaginaDimostrazioneGratuita from './pagine/paginaDimostrazioneGratuita'

import NavigazioneLanding from './componenti/navigazioneLanding'
import Footer from './componenti/footer'
import logo from 'assets/images/svgLogoPhysioBlu.svg'
import logoAifi from 'assets/images/logo_aifi.webp'
import { useEffect } from 'react'
import { store } from 'data/store'

const rotte = [
    {
        type: 'collapse',
        name: 'I vantaggi di Physio Archive',
        key: 'vantaggi',
        route: '/vantaggi',
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Abbonamento e convenzioni',
        key: 'pricing',
        route: '/abbonamento',
        nonAprire: false,
        nonAprire: true,
    },
    /* {
        type: 'collapse',
        name: 'Formazione',
        key: 'formazione',
        route: '/formazione',
        nonAprire: false,
        nonAprire: true,
    }, */
    /* {
        type: 'collapse',
        name: 'Dicono di noi',
        key: 'recensioni',
        route: '/dicono-di-noi',
        nonAprire: false,
        nonAprire: true,
    }, */
   /*  {
        type: 'collapse',
        name: 'Contatti',
        key: 'contatti',
        route: '/contatti',
        nonAprire: false,
        nonAprire: true,
    }, */
    {
        type: 'collapse',
        name: 'Accedi',
        key: 'accedi',
        route: '/logIn',
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Registrati',
        key: 'registrati',
        route: '/registrati',
        nonAprire: true,
        nonAprire: true,
    },
]

export default function routingLanding() {

    const { pathname } = useLocation()
    const navigate = useNavigate()

    useEffect(
        (e) => {
           
            store.dispatch({
                type: 'CAMBIA_URL',
                payload: window.location.href.split('/'),
            })
            if (window.location.href.split('/')[3] === 'paginaFatturePresidio') {
                window.dispatchEvent(new CustomEvent('cercaFatture'))
            }
            document.documentElement.scrollTop = 0
            document.scrollingElement.scrollTop = 0
        },
        [pathname],
    )

    return (
        <React.Fragment>
            <NavigazioneLanding rotte={rotte} />
            <Routes>
                <Route exact path={'/registrati'} element={<PaginaRegistrati navigate={useNavigate()} />} />
                <Route exact path={'/passwordDimenticata'} element={<PaginaPasswordDimenticata navigate={useNavigate()} />} />
                <Route exact path={'/login'} element={<PaginaLogin navigate={useNavigate()} />} />
                <Route exact path={'/vantaggi'} element={<PaginaVantaggi navigate={useNavigate()} />} />
                {/* <Route exact path={'/dicono-di-noi'} element={<PaginaDiconoDiNoi navigate={useNavigate()} />} /> */}
                <Route exact path={'/abbonamento'} element={<PaginaPricing navigate={useNavigate()} />} />
                <Route exact path={'/dimostrazioneGratuita'} element={<PaginaDimostrazioneGratuita navigate={useNavigate()} />} />
                <Route exact path={'/'} element={<PaginaLanding navigate={useNavigate()} />} />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>

            <MDBox display='flex' my={12} py={6} variant='gradient' bgColor='white' color="info" sx={{borderTop : "solid", borderBottom : "solid"}}>
                <Container>
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={12} md={8} lg={5}>
                            <Grid item container spacing={2} xs={12} md={12}>
                                <Grid item xs={12} md={6}>
                                    <MDBox width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
                                        <MDBox component='img' alt='logo' src={logo} width='100%' height='100px'></MDBox>
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MDBox width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
                                        <MDBox component='img' alt='logo' src={logoAifi} width='auto' height='100px'></MDBox>
                                    </MDBox>
                                </Grid>
                            </Grid>
                            <MDTypography variant='h5' color='info' fontWeight='bold' sx={{mt : 1}}>
                                Physio Archive, il gestionale creato da fisioterapisti per fisioterapisti
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ ml: 'auto' }}>
                            <MDBox width='12rem' ml='auto'>
                                <MDButton variant='gradient' color='warning' fullWidth sx={{ boxShadow: 'none' }} onClick={()=>navigate("/registrati")}>
                                    Apri la tua clinica virtuale
                                </MDButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Container>
            </MDBox>

            <Footer rotte={rotte} />
        </React.Fragment>
    )
}
