import React, { Component } from 'react'
import { connect } from 'react-redux'
import DefaultCell from '../layouts/ecommerce/products/product-page/components/DefaultCell'
import DataTable from '../examples/Tables/DataTable'
import { eventiCache } from '../mixing/eventiCache'
import { getListaEserciziFiltrati } from 'getter/getterEsercizi'
import MDTypography from 'components/MDTypography'
import List from '@mui/material/List'
import ElementoMostraEsercizioLibreria from './elementoMostraEsercizioLibreria'
import { Divider } from '@mui/material'

export class ElementoMostraEsercizi extends eventiCache(Component) {
    renderTabella() {
        return <DataTable table={this.getDati()} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} onChange={() => this.test()} />
    }

    render() {
        if (this.state.listaEserciziWindow.length === 0) {
            return <MDTypography color='text'>Nessun esercizio presente in libreria</MDTypography>
        }
        if (this.props.errore) {
            return <MDTypography color='error'>Nessun risultato</MDTypography>
        }
        return (
            <List>
                {this.props.lista.map((esercizio, index) => {
                    return (
                        <React.Fragment key={esercizio.id}>
                            <ElementoMostraEsercizioLibreria esercizio={esercizio} navigate={this.props.navigate} azione={this.props.azione} />
                            {index !== this.props.lista.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </List>
        )
    }

    getDati() {
        return {
            columns: [
                { Header: 'Nome', accessor: 'nome', width: '80%' },
                { Header: 'Tags', accessor: 'tags', width: '20%' },
            ],
            rows: this.props.lista.map((esercizio) => {
                return {
                    id: esercizio.id,
                    azione: (e) => this.test(e),
                    nome: <DefaultCell>{esercizio.contenuto.titolo}</DefaultCell>,
                    tags: <DefaultCell>{esercizio.contenuto.tags?.toString() || ''}</DefaultCell>,
                }
            }),
        }
    }
}

function stateChanged(state, ownProps) {
    const eserciziPaziente = ownProps.eserciziPaziente || []
    const dati = getListaEserciziFiltrati()
    let listaDaVedere = []
    dati.lista.forEach((esercizio) => {
        if (!eserciziPaziente.includes(esercizio.id)) {
            listaDaVedere.push(esercizio)
        }
    })
    return {
        lista: listaDaVedere,
        errore: dati.errore,
    }
}

const mapDispatchToProps = {}

export default connect(stateChanged, mapDispatchToProps)(ElementoMostraEsercizi)
