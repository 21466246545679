import React, { useState } from 'react'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { ref, getDownloadURL } from 'firebase/storage'

export default function elementoVediBodyChart(props) {
    const [url, setUrl] = useState('')

    let getUrl = async () => {
        let urlImmagine = await getDownloadURL(ref(storage,`noteManuali/${props.value.idNota}.png`))
        setUrl(urlImmagine)
    }

    const renderImmagine = () => {
        if (url === '') {
            return ''
        }
        return <img className='img-body-chart-output' src={url} alt='Smiley face' />
    }

    const renderSfondo = () => {
        if (props.value.sfondo === 'bianco') {
            return ''
        }
        if (props.value.sfondo === 'fronte') {
            return <img className='img-body-chart-output' src='/images/fronte.jpg' alt='Smiley face' />
        }
        if (props.value.sfondo === 'retro') {
            return <img className='img-body-chart-output' src='/images/retro.jpg' alt='Smiley face' />
        }
        if (props.value.sfondo === 'body') {
            return <img className='img-body-chart-output' src='/images/corpo.jpg' alt='Smiley face' />
        }
        if (props.value.sfondo === 'frontale') {
            return <img className='img-body-chart-output' src='/images/frontale.jpg' alt='Smiley face' />
        }
        if (props.value.sfondo === 'laterale') {
            return <img className='img-body-chart-output' src='/images/laterale.jpg' alt='Smiley face' />
        }
    }

    getUrl()

    return (
        <React.Fragment>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h5'>
                    Body chart
                </MDTypography>
            </MDBox>
            <MDBox ml='auto' mr='auto'>
                <div className='container-body-chart-output'>
                    {renderSfondo()}
                    {renderImmagine()}
                </div>
            </MDBox>
        </React.Fragment>
    )
}
