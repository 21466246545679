import { getPresidio } from 'data/strumenti'
import { ref, uploadBytes } from 'firebase/storage'
import { isScanFattuttura } from 'getter/getterFatture'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { APRI_POP_UP } from './APRI_POP_UP'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const SALVA_SCAN_FATTURA = async (fattura, immagine) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    const idPresidio = fattura.contenuto.idPresidio || getPresidio(getUtenteLoggato())

    const path = `fatture/${idPresidio}/${fattura.id}/scan-${fattura.contenuto.numFattura}.png`

    const data = `${fattura.contenuto.data.split('/')[2] - fattura.contenuto.data.split('/')[1]}`

    const datiServer = {
        idPaziente: fattura.contenuto.idPaziente,
        idPresidio: idPresidio,
        idFattura: fattura.id,
        dataFattura: data,
    }

    if (isScanFattuttura(fattura.contenuto)) {
        await uploadBytes(ref(storage, path), immagine)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'scannerizzazione salvata correttamente' } })
        return
    }
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    
    console.log('non ci sono andato');
    uploadBytes(ref(storage, path), immagine)
    await CHIAMA_SERVER('scanFattura', datiServer)
    APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'scannerizzazione salvata correttamente' } })
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
