import { Divider, List } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getListaFattureDipendente } from 'getter/getterDipendenti'
import { eventiCache } from 'mixing/eventiCache'
import React, { Component } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import { formattaDataDaFirebase } from 'data/strumenti'
import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getIdDipendente } from 'getter/getterDipendenti'
import { connect } from 'react-redux'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { ref, getDownloadURL } from 'firebase/storage'

export class elementoFattureDipendente extends eventiCache(Component) {
    constructor(props) {
        super(props)
        this.state.listaFatture = []
    }

    componentDidMount() {
        super.componentDidMount()
        this.getLista()
    }

    listaFattureDipendentiAggiornata() {
        this.getLista()
    }

    componentDidUpdate(vecchieProps, vecchioState) {
        if (vecchieProps.idDipendente !== this.props.idDipendente) {
            this.getLista()
        }

        if (vecchioState.listaFattureDipendentiWindow !== this.state.listaFattureDipendentiWindow) {
            this.getLista()
        }
        if (vecchioState.listaFatture !== this.state.listaFatture) {
            this.state.listaFatture.forEach((fattura, index) => {
                let path = `fatture/${this.props.idPresidio}/stripe/${fattura.contenuto.numFattura}.pdf`

                getDownloadURL(ref(storage, path)).then((url) => {
                    document.getElementById(`fattura-abbonamento-${index}`).href = url
                })
            })
        }
    }

    getLista() {
        const fatture = getListaFattureDipendente(this.props.idDipendente)
        this.setState({ listaFatture: fatture })
    }

    renderFatture() {
        if (this.state.listaFatture.length === 0) {
            return <MDTypography color='error'>Nessuna fattura presente</MDTypography>
        }
        return (
            <List>
                {this.state.listaFatture.map((fattura, index) => {
                    const id = `fattura-abbonamento-${index}`
                    return (
                        <MDBox key={id} component='a' target='blank' download id={id} sx={{ cursor: 'pointer' }}>
                            <ListItem>
                                <ListItemText primary={fattura.contenuto.numFattura} />
                                <ListItemIcon>
                                    <DownloadRoundedIcon color='dark' />
                                </ListItemIcon>
                            </ListItem>
                            <Divider />
                        </MDBox>
                    )
                })}
            </List>
        )
    }

    render() {
        return (
            <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    {/* <MenuBookRoundedIcon color='white' sx={{ mr: 1 }} /> */}
                    <MDTypography color={'white'} variant='h5'>
                        Fatture abbonamento
                    </MDTypography>
                </MDBox>
                {this.renderFatture()}
            </MDBox>
        )
    }
}

function mapStateToProps(state) {
    let idPresidio = ''
    if (getProfiloUtenteLoggato() === 'profilo-super-amministratore') {
        idPresidio = state.url[4]
    }
    if (getProfiloUtenteLoggato() !== 'profilo-super-amministratore') {
        idPresidio = getPresidio(getVeroUtenteLoggato())
    }
    return {
        idDipendente: getIdDipendente(),
        idPresidio: idPresidio,
    }
}

export default connect(mapStateToProps, null)(elementoFattureDipendente)
