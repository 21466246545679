import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import VediForm from 'formMaker/vediForm'
import { formNuovoEsercizioDaAssegnare } from 'formModel/formNuovoEsercizioDaAssegnare'
import { formDettagliEsercizi } from 'formModel/formDettagliEsercizi'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MDBox from 'components/MDBox'
import { DialogActions, Grid, Icon } from '@mui/material'
import ElementoAggiungiFile from 'componenti/elementoAggiungiFile'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpNuovoEsercizio(props) {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }

    const [dati, setDati] = useState({})
    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)
    const [listaImmagini, setListaImmagini] = useState([])
    const [step, setStep] = useState(0)
    const steps = ['Dati esercizio', 'Files esercizio']
    const test = (e) => {
        console.log("sto quasaaa");
        e.preventDefault()
    }

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
        })
    }, [])

    useEffect(() => {
        if (props.parametro.esercizio !== undefined) {
            console.log('eserciziooooo : ', props.parametro.esercizio)
            setDati(props.parametro.esercizio)
            setListaImmagini(props.parametro.listaImmagini)
        }
    }, [props.aperto])

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
        return;
        setDati({})
        setListaImmagini([])
        setStep(0)
        props.chiudi()
    }

    const salvaEsercizio = async () => {
        //console.log('ricordati di collegare l azione per salvare')
        const esercizio = Object.assign(dati, { listaImmagini: listaImmagini })
        await props.parametro.azione(esercizio)

        handleClose()
        setDati({})
        setStep(0)
        setListaImmagini([])
        if (props.parametro.noAlert) {
            return
        }
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Esercizio creato' } })
    }

    const getForm = () => {
        return props.parametro.daAssegnare ? formNuovoEsercizioDaAssegnare() : formDettagliEsercizi()
    }

    const avanti = (esercizio) => {
        setDati(esercizio)
        setStep(step + 1)
    }

    const eliminaImmagine = (index) => {
        const nuovaLista = [...listaImmagini]
        nuovaLista.splice(index, 1)
        setListaImmagini(nuovaLista)
    }

    const nuovaImmagine = (file, nome) => {
        const nuovaLista = [...listaImmagini]
        nuovaLista.push({ nome: nome, file: file })
        setListaImmagini(nuovaLista)
    }

    const renderListaFiles = () => {
        return listaImmagini.map((elemento, index) => {
            return (
                <Grid key={`file-${index}`} item container direction='row' sx={{ columnGap: '10px' }}>
                    <div className='centrato' style={{ cursor: 'pointer' }} onClick={() => eliminaImmagine(index)}>
                        <Icon color='error'>cancel</Icon>
                    </div>
                        <div className='contenitore-immagine-nome'>
                            {/* <MDTypography color='text'>{elemento.nome}</MDTypography> */}
                            <MDBox component='img' src={elemento.nome} objectFit='cover' mr={2} mb={2} sx={{ cursor: 'pointer' }}></MDBox>
                        </div>
                </Grid>
            )
        })
    }

    const renderBottoni = () => {
        if (step === 0) {
            return ''
        }
        return (
            <div className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='error' onClick={() => handleClose()}>
                    annulla
                </MDButton>
                <MDButton variant='contained' color='primary' onClick={() => setStep(step - 1)}>
                    indietro
                </MDButton>
                <MDButton variant='contained' color='success' onClick={() => salvaEsercizio()}>
                    salva
                </MDButton>
            </div>
        )
    }

    const renderFiles = () => {
        return (
            <React.Fragment>
                <MDTypography variant='h5'>Lista file :</MDTypography>
                <Grid container rowSpacing={2}>
                    {renderListaFiles()}
                </Grid>
                <MDBox mt={2}>
                    <ElementoAggiungiFile isEsercizio={true} salva={(file, nome) => nuovaImmagine(file, nome)} />
                </MDBox>
            </React.Fragment>
        )
    }

    const renderContenuto = () => {
        switch (step) {
            case 0:
                return <VediForm isPrimoStep={true} form={getForm()} dati={dati} annulla={() => handleClose()} salva={(e) => avanti(e)} vista='input' soloInput={true} />
            case 1:
                return renderFiles()
            default:
                break
        }
    }

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onBackdropClick={(e)=>test(e)} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>NUOVO ESERCIZIO</DialogTitle>
            <DialogContent dividers={true}>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MDBox mt={2}>{renderContenuto()}</MDBox>
            </DialogContent>
            <DialogActions>{renderBottoni()}</DialogActions>
        </Dialog>
    )
}
