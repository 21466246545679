import React from 'react'
import MDBox from 'components/MDBox'
import { Grid, Container, Divider } from '@mui/material'
import DefaultBackgroundCard from 'examples/Cards/BackgroundCards/DefaultBackgroundCard'
import ListItem from 'components/MDListItem'

import jsonFunzioniLanding from 'landing/jsons/jsonFunzioniLanding'
import bgImage1 from "assets/images/examples/color1.jpg";

export default function funzioniPrincipali() {
    const renderTesto = (titolo, testo) => {
        return <ListItem title={titolo}>{testo}</ListItem>
    }

    const renderImmagine = (immagine) => {
        //return <DefaultBackgroundCard image={bgImage1} label='' title='' description='' />

        return (
            <MDBox height={{ xs: '150px', lg: '300px' }} width={{ xs: '150px', lg: '500px' }}>
                <MDBox
                    className='immagine-landing'
                    height={'100%'}
                    width={'100%'}
                    component='img'
                    src={immagine}
                    borderRadius={'30px'}
                    border={'solid transparent 10px'}
                    /*  shadow="md" */
                />
            </MDBox>
        )
    }

    const renderFunzione = (elemento, index) => {
        if (index % 2 === 0) {
            return (
                <Grid key={elemento.titolo} container spacing={3} alignItems='center' sx={{ mt: index === 0 ? 0 : 0 }}>
                    <Grid item xs={12} md={4} sx={{ ml: 'auto' }}>
                        {renderImmagine(elemento.img)}
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ mr: 'auto', ml: { xs: 0, md: 6 } }}>
                        {renderTesto(elemento.titolo, elemento.descrizione)}
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid key={elemento.titolo} container spacing={3} alignItems='center' sx={{ mt: index === 0 ? 6 : 0 }}>
                <Grid item xs={12} md={5} sx={{ mr: 'auto', ml: { xs: 0, md: 6 } }}>
                    {renderTesto(elemento.titolo, elemento.descrizione)}
                </Grid>
                <Grid item xs={12} md={4} sx={{ ml: 'auto' }}>
                    {renderImmagine(elemento.img)}
                </Grid>
            </Grid>
        )
    }

    return (
        <MDBox component='section' py={12}>
            <Container>
                {jsonFunzioniLanding.map((sezione, index) => {
                    return (
                        <React.Fragment>
                            {renderFunzione(sezione, index)}
                            {index !== jsonFunzioniLanding.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </Container>
        </MDBox>
    )
}
