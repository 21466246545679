import { isEqual } from 'lodash-es'
import React, { Component } from 'react'

export default class elementoTestArticolare extends Component {
    constructor(props) {
        super(props)
        this.state = { larghezza: 0 }
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.settaDimContanier()
        })
        this.settaDimContanier()
        this.disegna()
    }

    componentDidUpdate(vecchieProps) {
        this.disegna()
    }

    getIdContainer() {
        if (this.props.isPopUp) {
            return `container-test-articolare-${this.props.id}-popup`
        }
        return `container-test-articolare-${this.props.id}`
    }

    settaDimContanier() {
        setTimeout(() => {
            const rawLarghezza = document.getElementById(this.getIdContainer()).getBoundingClientRect().width
            const larghezza = rawLarghezza < 300 ? rawLarghezza : 300
            if (this.state.larghezza !== larghezza) {
                let stringa = `${larghezza}px`
                document.getElementById(this.getIdContainer()).style.height = stringa
                this.setState({ larghezza: larghezza })
            }
        }, 10)
    }

    disegna() {
        let canvas = document.getElementById(`canvas-test-articolare-${this.props.id}`)
        if (canvas === null) {
            return
        }
        let angolo = this.calcolaAngolo(this.props.datiDisegno.origine, this.props.datiDisegno.zero)
        let raggio = this.calcolaRaggio(this.props.datiDisegno.origine, this.props.datiDisegno.zero)
        let arco = this.calcolaPunti(this.props.datiDisegno, raggio, angolo)
        let ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        let origine = {
            x: this.props.datiDisegno.origine.x,
            y: this.props.datiDisegno.origine.y,
            nome: '',
        }

        this.disegnaPunto(origine, ctx, 'red')

        arco.forEach((element) => {
            this.disegnaPunto(element, ctx, 'black')
        })

        this.disegnaLinea(origine, arco[0], ctx, 'black')
        this.disegnaLinea(origine, arco[arco.length - 1], ctx, 'black')
        if (this.props.valori.aiutato !== undefined) {
            if (this.props.valori.aiutato.max !== 0 && this.props.valori.aiutato.max !== undefined) {
                let aiutatoMassimo = this.calcolaPunto(this.props.datiDisegno.origine, this.calcolaSommaAngolo(angolo, this.props.valori.aiutato.max), raggio)
                this.disegnaPunto(aiutatoMassimo, ctx, 'blue')
                this.disegnaLinea(origine, aiutatoMassimo, ctx, 'blue')
            }
            if (this.props.valori.aiutato.min !== 0 && this.props.valori.aiutato.min !== undefined) {
                let aiutatoMinimo = this.calcolaPunto(this.props.datiDisegno.origine, this.calcolaSommaAngolo(angolo, this.props.valori.aiutato.min), raggio)
                this.disegnaPunto(aiutatoMinimo, ctx, 'blue')
                this.disegnaLinea(origine, aiutatoMinimo, ctx, 'blue')
            }
        }
        if (this.props.valori.solo !== undefined) {
            if (this.props.valori.solo.min !== 0 && this.props.valori.solo.min !== undefined) {
                let soloMinimo = this.calcolaPunto(this.props.datiDisegno.origine, this.calcolaSommaAngolo(angolo, this.props.valori.solo.min), raggio)
                this.disegnaPunto(soloMinimo, ctx, 'red')
                this.disegnaLinea(origine, soloMinimo, ctx, 'red')
            }
            if (this.props.valori.solo.max !== 0 && this.props.valori.solo.max !== undefined) {
                let soloMassimo = this.calcolaPunto(this.props.datiDisegno.origine, this.calcolaSommaAngolo(angolo, this.props.valori.solo.max), raggio)
                this.disegnaPunto(soloMassimo, ctx, 'red')
                this.disegnaLinea(origine, soloMassimo, ctx, 'red')
            }
        }
    }

    calcolaRaggio(origine, punto) {
        return Math.sqrt(Math.pow(punto.x - origine.x, 2) + Math.pow(punto.y - origine.y, 2))
    }
    calcolaAngolo(origine, punto) {
        return (Math.atan2(punto.x - origine.x, punto.y - origine.y) * 180) / Math.PI
    }
    calcolaPunti(datiDisegno, raggio, angolo) {
        let punti = []
        for (let index = datiDisegno.angoli.min; index <= datiDisegno.angoli.max; index += 10) {
            let nuovoAngolo
            if (datiDisegno.senso === 'orario') {
                nuovoAngolo = angolo - index
                punti.push(this.calcolaPunto(datiDisegno.origine, nuovoAngolo, raggio, index.toString()))
            } else {
                nuovoAngolo = angolo + index
                punti.push(this.calcolaPunto(datiDisegno.origine, nuovoAngolo, raggio, index.toString()))
            }
        }
        return punti
    }

    disegnaPunto(punto, ctx, colore) {
        ctx.beginPath()
        ctx.arc(punto.x, punto.y, 5, 0, 2 * Math.PI, false)
        ctx.fillStyle = colore
        ctx.fill()
        if (punto.nome !== undefined) {
            ctx.beginPath()
            ctx.strokeStyle = colore
            ctx.font = '20px Arial'
            ctx.fillText(punto.nome, punto.x + 10, punto.y)
        }
    }

    disegnaLinea(partenza, fine, ctx, colore) {
        ctx.beginPath()
        ctx.moveTo(partenza.x, partenza.y)
        ctx.lineTo(fine.x, fine.y)
        ctx.strokeStyle = colore
        ctx.lineWidth = 5
        ctx.stroke()
    }

    calcolaPunto(origine, angolo, raggio, nome) {
        return {
            x: this.calcolaX(origine, angolo, raggio),
            y: this.calcolaY(origine, angolo, raggio),
            nome: nome,
        }
    }
    calcolaX(origine, angolo, raggio) {
        return Math.floor(origine.x + Math.sin((angolo * Math.PI) / 180) * raggio)
    }
    calcolaY(origine, angolo, raggio) {
        return Math.floor(origine.y + Math.cos((angolo * Math.PI) / 180) * raggio)
    }

    calcolaSommaAngolo(angoloBase, angoloDaAggiungere) {
        if (this.props.datiDisegno.senso === 'orario') {
            return Number(angoloBase) - Number(angoloDaAggiungere)
        } else {
            return Number(angoloBase) + Number(angoloDaAggiungere)
        }
    }

    render() {
        if (this.props.datiDisegno === undefined) {
            return ''
        }

        if (Object.keys(this.props.datiDisegno).length === 0) {
            return ''
        }
        return (
            <div id={this.getIdContainer()} className='container-test-articolare'>
                <img id='immagine-test-articolare' src={`/articolari/${this.props.datiDisegno.immagine}`} alt='' />
                <canvas id={`canvas-test-articolare-${this.props.id}`} className='canvas-test-articolare' width='1000' height='1000'></canvas>
            </div>
        )
    }
}
