import { getIdPaziente } from './../../getter/getterPaziente'
import { getIdLesioneSelezionata, getIdSedutaSelezionata } from './../../getter/getterLesioni'
import { doc, updateDoc, deleteField } from 'firebase/firestore'

export const ELIMINA_SEDUTA = async () => {
    await updateDoc(doc(db, 'utenti', getIdPaziente(), 'lesioni', getIdLesioneSelezionata()), {
        [`sedute.${getIdSedutaSelezionata()}`]: deleteField(),
    })
    window.history.back()
    return
}
