import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Grid, Card, Divider } from '@mui/material'
import Container from '@mui/material/Container'
import bgImage from 'assets/images/immagine.webp'
import Carosello from 'landing/componenti/carosello'
import Pricing from 'landing/componenti/pricing'
import ConvenzioneAifi from 'landing/componenti/convenzioneAifi'
import MDButton from 'components/MDButton'

export default function paginaPricing(props) {
    return (
        <React.Fragment>
            <MDBox
                minHeight='50vh'
                width='100%'
                sx={({ functions: { rgba, linearGradient }, palette: { black } }) => ({
                    //backgroundImage: `${linearGradient(rgba(black.main, 0.7), rgba(black.main, 0.7))}, url(${bgImage})`,
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                })}>
                {/* <Container>
                    <MDBox
                        bgColor='white'
                        p={{ sx: 1, lg: 3 }}
                        borderRadius='section'
                        sx={({ breakpoints, borders: { borderRadius } }) => ({
                            [breakpoints.down('md')]: {
                                borderRadius: borderRadius['xxl'],
                            },
                            [breakpoints.down('sm')]: {
                                borderRadius: borderRadius['lg'],
                            },
                        })}>
                        <Grid container item xs={12} lg={8} flexDirection='column' justifyContent='center' alignItems='center' sx={{ mx: 'auto', textAlign: 'center' }}>
                            <MDTypography
                                variant='h2'
                                color='info'
                                mb={2}
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down('md')]: {
                                        fontSize: size['3xl'],
                                    },
                                    [breakpoints.down('sm')]: {
                                        fontSize: size['2xl'],
                                    },
                                })}>
                                Prova gratuita
                            </MDTypography>
                            <MDTypography
                                variant='subtitle1'
                                color='dark'
                                mb={2}
                                sx={({ breakpoints, typography: { size } }) => ({
                                    [breakpoints.down('md')]: {
                                        fontSize: size['xl'],
                                    },
                                    [breakpoints.down('sm')]: {
                                        fontSize: size['lg'],
                                    },
                                })}>
                                Registrati e ottieni 30 giorni di prova gratuita per i primi due fisioterapisti della tua clinica
                            </MDTypography>
                        </Grid>
                    </MDBox>
                </Container> */}
            </MDBox>

            <Card
                sx={{
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: 'saturate(200%) blur(30px)',
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    overflow: 'hidden',
                }}>
                <MDBox width='100%' pt={10} pb={10} bgColor='info' variant='gradient' color='white'>
                    <Grid container item xs={12} lg={8} flexDirection='column' justifyContent='center' alignItems='center' sx={{ mx: 'auto', textAlign: 'center' }}>
                        <MDTypography
                            variant='h1'
                            color='white'
                            mb={2}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['3xl'],
                                },
                                [breakpoints.down('sm')]: {
                                    fontSize: size['2xl'],
                                },
                            })}>
                            30 GIORNI DI PROVA GRATUITA
                        </MDTypography>
                        <MDTypography
                            variant='subtitle2'
                            color='white'
                            mb={2}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['lg'],
                                },
                                [breakpoints.down('sm')]: {
                                    fontSize: size['lg'],
                                },
                            })}>
                            Registrati e ottieni 30 giorni di prova gratuita per i primi due fisioterapisti della tua clinica
                        </MDTypography>
                        <MDButton variant='gradient' color='warning' sx={{ boxShadow: 'none' }} onClick={() => props.navigate('/registrati')}>
                            Apri la tua clinica virtuale
                        </MDButton>
                        <MDTypography variant='caption' color='dark' mt={2} sx={{ fontWeight: 'bold' }}>
                            Crea la tua clinica e prova gratuitamente Physio Archive per 30 giorni.
                        </MDTypography>
                    </Grid>
                </MDBox>

                <ConvenzioneAifi />
                <Divider />
                <MDBox textAlign="center">
                    <MDTypography variant="h3" color="info">I profili di Physio Archive</MDTypography>
                    <Carosello />
                </MDBox>
                <Divider />
                <Pricing />
            </Card>
        </React.Fragment>
    )
}
