import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

//inizio firebase config
const firebaseConfig = {"apiKey":"AIzaSyAKa1VszAX6j42fg7pwY46Ffn414NuBGBc","authDomain":"physio-archive.firebaseapp.com","databaseURL":"https://physio-archive.firebaseio.com","projectId":"physio-archive","storageBucket":"physio-archive.appspot.com","messagingSenderId":"861537953779","appId":"1:861537953779:web:dbef52af55ab493cb25486","measurementId":"G-1S611B6SY0"}
//fine firebase config

const app = initializeApp(firebaseConfig)
window.db = getFirestore(app)
window.storage = getStorage(app)
window.analytics = getAnalytics(app)
//window.appUtilizzata = 'Osteo'
window.appUtilizzata = 'Physio'
