const initialState = {

};

export const reducerConfigurazioneBaseFatture = (state = initialState, action) => {
      
    switch (action.type) {
        case 'LOG_OUT':
            return initialState;
        case 'LEGGI_CONFIGURAZIONE_BASE_FATTURE': 
            return Object.assign(action.payload)
        /* case "CAMBIA_URL":
           break */
        default:
            return state;
    }
};
