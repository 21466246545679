import { result } from 'lodash-es'
import { store } from './../store'
import { APRI_POP_UP } from './APRI_POP_UP'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const INVIA_CREDENZIALI = (id) => {
    //return new Promise((resolve, reject) => {
    
    APRI_POP_UP({ id: 'alert', parametro: { severity: 'info', testo: 'Invio mail in corso' } })

    CHIAMA_SERVER('inviaMail', { id: id, tipo: 'mailBenvenuto' }).then((result) => {
        console.log("resulttttttttt");
        console.log(result);

        console.log('resulttttttt : ', result)
        var status = result.message.status
        if (status === 'ok') {
            APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Mail inviata con successo' } })
        } else {
            console.log('errore')
            console.log(result)
            APRI_POP_UP({ id: 'alert', parametro: { severity: 'error', testo: 'Mail non inviata. Riprova' } })
        }
    })

    /*  var callSendMail = firebase.functions().httpsCallable('callSendMail')
    callSendMail({ id: id })
        .then((result) => {
            console.log('resulttttttt : ', result)
            var status = result.data.status
            if (status === 'ok') {
                APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Mail inviata con successo' } })
            } else {
                console.log('errore')
                console.log(result.data.error.response)
                APRI_POP_UP({ id: 'alert', parametro: { severity: 'error', testo: 'Mail non inviata. Riprova' } })
            }
        })
        .catch(() => {}) */
    // })
}
