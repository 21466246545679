import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import MDBox from 'components/MDBox'
import CircularProgress from '@mui/material/CircularProgress'
import { InputSlider } from 'fields/fieldSlider'
import Fab from '@mui/material/Fab'
import CloseIcon from '@mui/icons-material/Close'
import { Grid } from '@mui/material'
import { getDownloadURL, ref } from 'firebase/storage'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpVediImmagine(props) {
    const [url, setUrl] = useState('')
    const [valoreSlider, setValoreSlider] = useState(100)
    const [currentx, setCurrentX] = useState(0)
    const [currenty, setCurrentY] = useState(0)
    const [posizioneInizialeTrack, setPosizioneInizialeTrack] = useState({ x: 0, y: 0 })
    const [isPressed, setIsPressed] = useState(false)
    const [altezzaMassima, setAltezzaMassima] = useState(window.innerHeight - 70)

    const model = {
        id: 'nome',
        tipo: 'Text',
        associazione: 'nome',
        label: '',
        value: 100,
        details: {
            min: 100,
            max: 500,
        },
    }

    const handleClose = () => {
        props.chiudi()
    }

    const getUrl = async () => {
        if (props.parametro.contenuto.nome === undefined) {
            setUrl(props.parametro.contenuto.urlOriginale)
        }
        const nuovoUrl = await getDownloadURL(ref(storage, `immagini/${props.parametro.id}/originale.${props.parametro.contenuto.nome.split('.')[props.parametro.contenuto.nome.split('.').length - 1]}`))
        setUrl(nuovoUrl)
    }

    const salvaCoordinate = (x, y) => {
        setCurrentX(x)
        setCurrentY(y)
    }

    const mousedown = (e) => {
        e.preventDefault()
        setPosizioneInizialeTrack({ x: e.clientX, y: e.clientY })
        setIsPressed(true)
    }

    const touchStart = (e) => {
        e.preventDefault()
        setPosizioneInizialeTrack({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY })
        setIsPressed(true)
    }

    const mouseup = (e) => {
        setIsPressed(false)
        salvaCoordinate(e.clientX - posizioneInizialeTrack.x, e.clientY - posizioneInizialeTrack.y)
    }

    const touchEnd = (e) => {
        setIsPressed(false)
        salvaCoordinate(e.changedTouches[0].clientX - posizioneInizialeTrack.x, e.changedTouches[0].clientY - posizioneInizialeTrack.y)
    }
    const mousemove = (e) => {
        if (isPressed) {
            let traslazioneX = e.clientX - posizioneInizialeTrack.x
            let traslazioneY = e.clientY - posizioneInizialeTrack.y
            const zoomRatio = valoreSlider / 100
            let style = `transform: scale(${zoomRatio}) translate(${currentx + traslazioneX}px,${currenty + traslazioneY}px);`
            document.getElementById('immagine').style = `${style}`
        }
    }

    const touchMove = (e) => {
        if (isPressed) {
            let traslazioneX = e.changedTouches[0].clientX - posizioneInizialeTrack.x
            let traslazioneY = e.changedTouches[0].clientY - posizioneInizialeTrack.y
            const zoomRatio = valoreSlider / 100
            let style = `transform: scale(${zoomRatio}) translate(${currentx + traslazioneX}px,${currenty + traslazioneY}px);`
            document.getElementById('immagine').style = `${style}`
        }
    }

    useEffect(() => {
        const zoomRatio = valoreSlider / 100
        const style = `transform: scale(${zoomRatio}) translate(${currentx}px,${currenty}px`
        if (document.getElementById('immagine') !== null) {
            document.getElementById('immagine').style = `${style}`
        }
    }, [valoreSlider])

    useEffect(() => {
        if (props.aperto) {
            getUrl()
        }
        if (!props.aperto) {
            setUrl('')
        }
    }, [props.aperto])
    useEffect(() => {
        window.addEventListener('resize', () => {
            setAltezzaMassima(window.innerHeight - 70)
        })
    }, [])

    return (
        <Dialog fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogContent dividers={true} sx={{ padding: '0px' }}>
                <MDBox sx={{ overflow: 'hidden' }} display='grid' height='100%' width='100%' maxHeight='100%' gridTemplateRows='1fr 70px'>
                    <MDBox onMouseDown={(e) => mousedown(e)} onMouseUp={(e) => mouseup(e)} onMouseMove={(e) => mousemove(e)} onTouchStart={(e) => touchStart(e)} onTouchEnd={(e) => touchEnd(e)} onTouchMove={(e) => touchMove(e)} variant='gradient' display='flex' justifyContent='center' alignItems='center' height='100%' width='100%' bgColor='dark'>
                        {url === '' ? <CircularProgress color='white' size='3rem' /> : <MDBox id='immagine' maxHeight={`${altezzaMassima}px`} maxWidth='100%' component='img' src={url} objectFit='cover'></MDBox>}
                    </MDBox>

                    <MDBox sx={{ zIndex: 1000000, paddingRight: '10px' }} height='70px' width='100%' bgColor='white' mt={1} display='grid' gridTemplateColumns='1fr max-content' gap={2}>
                        <MDBox sx={{ paddingLeft: '45px', paddingRight: '15px' }} height='100%' width='100%' display='flex' justifyContent='center'>
                            <InputSlider model={model} value={valoreSlider} errore='' azione={(a, b) => setValoreSlider(b)} />
                        </MDBox>
                        <MDBox height='100%' width='100%' display='flex' justifyContent='center'>
                            <Fab color='primary' aria-label='add' onClick={() => handleClose()}>
                                <CloseIcon />
                            </Fab>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </DialogContent>
        </Dialog>
    )
}
