const initialState = {
    id: '',
    contenuto: {},
    credenziali: false,
    skeleton: true,
}

export const reducerPaziente = (state = initialState, action) => {
    let nuovoStato = Object.assign(state, {})
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_PAZIENTE':
            let daTornare = Object.assign(action.payload, { credenziali: nuovoStato.credenziali })
            nuovoStato.contenuto = action.payload.contenuto
            nuovoStato.id = action.payload.id
            daTornare.skeleton = false
            return daTornare
        case 'LEGGI_CREDENZIALI':
            let statoAggiornato = Object.assign(nuovoStato, { credenziali: action.payload })
            nuovoStato.credenziali = action.payload
            return statoAggiornato
        case 'LEGGI_LESIONI':
            return Object.assign(state, { skeleton: false })
        case 'SVUOTA_PAZIENTE':
            return {
                id: '',
                contenuto: {},
                credenziali: false,
                skeleton: true,
            }

        default:
            return state
    }
}
