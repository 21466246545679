import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'

export default function elementoMostraAgente(props) {
    const test = () => {}

    return (
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
            <ListItemIcon>
                <AccountCircleRoundedIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={`${props.agente.contenuto.cognome} ${props.agente.contenuto.nome}`} secondary={props.agente.id}/>
            <ListItemIcon>
                <ArrowForwardIosRoundedIcon color='dark' />
            </ListItemIcon>
        </ListItem>
    )
}
