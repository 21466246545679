import { getProfiloUtenteLoggato, getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { getPresidio, trasformaOggettoInArray } from './../strumenti'
import { onSnapshot, query, where, collection } from 'firebase/firestore'

export class watcherListaDipendentiPresidio extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.controllaProfilo()
    }

    update() {
        if (store.getState().url[3] === 'paginaInfoAbbonamentiClinica') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    controllaProfilo() {
        if (store.getState().statoUtente.utente.profilo === 'profilo-super-amministratore') {
            store.subscribe(() => {
                this.update()
            })
        } else {
            let presidio = getPresidio(getVeroUtenteLoggato())

            try {
                this.apriConnessione(presidio)
            } catch (error) {
                console.log(error)
            }
        }
    }

    apriConnessione(nuovoId) {
        const q = query(collection(db, 'utenti'), where('etichettaUniversale.presidio', '==', nuovoId), where('isPaziente', '==', false))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            var listaDottori = {}
            querySnapshot.forEach((doc) => {
                if (doc.data().profiloOriginale !== 'profilo-amministrazione-clinica') {
                    listaDottori[doc.id] = doc.data()
                }
                if (doc.data().profiloOriginale === 'profilo-amministrazione-clinica') {
                }
            })
            window.listaDipendenti = trasformaOggettoInArray(listaDottori)
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'dipendenti' }))
        })
    }
    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
