import { getUtenteLoggato, getVeroUtenteLoggato } from './getterUtenteLoggato'

const dottore = 'profilo-dottore'
const segreteria = 'profilo-segreteria'
const paziente = 'profilo-paziente'
const chiosco = 'profilo-chiosco'
const amministrazione = 'profilo-amministrazione-clinica'
const superAmministratore = 'profilo-super-amministratore'
const agente = 'profilo-agente'

const fisioterapista = 'fisioterapista'
const osteopata = 'osteopata'

const permessoProfessione = (autorizzati, professione, profilo) => {
    return autorizzati.includes(professione) && profilo === dottore
}

const permessiImpostazioniTs = (profilo, permessi) => {
    const autorizzati = [segreteria, amministrazione]
    if (autorizzati.includes(profilo)) {
        return true
    }
    return permessi.impostazioniTs || false
}
const permessiCalendarioDipendenti = (profilo, permessi) => {
    const autorizzati = [segreteria]
    if (autorizzati.includes(profilo)) {
        return true
    }
    return permessi.calendarioDipendenti || false
}
const permessiFatturePresidio = (profilo, permessi) => {
    if (profilo === segreteria) {
        return true
    }
    return permessi.fatturePresidio || false
}
const permessiListaPazienti = (profilo) => {
    const autorizzati = [dottore, segreteria]
    if (autorizzati.includes(profilo)) {
        return { input: true, output: true }
    }
    return { input: false, output: false }
}
const permessiLibreriaEsercizi = (profilo, professione) => {
    const autorizzatiInputProfili = []
    const autorizzatiInputProfessioni = [fisioterapista, osteopata]
    const autorizzatiOutputProfili = [paziente]
    const autorizzatiOutputProfessioni = [fisioterapista, osteopata]
    let input = false
    let output = false
    if (autorizzatiInputProfili.includes(profilo) || permessoProfessione(autorizzatiInputProfessioni, professione, profilo)) {
        input = true
    }
    if (autorizzatiOutputProfili.includes(profilo) || permessoProfessione(autorizzatiOutputProfessioni, professione, profilo)) {
        output = true
    }
    return { input, output }
}
const permessiDatiPresidio = (profilo, permessi) => {
    const autorizzatiOutput = [dottore, segreteria, paziente, chiosco, amministrazione]
    const autorizzatiInput = [amministrazione]
    let input = false
    let output = false
    if (autorizzatiOutput.includes(profilo)) {
        output = true
    }
    if (autorizzatiInput.includes(profilo) || permessi.amministrazione) {
        input = true
    }
    return { input, output }
}
const permessiDatiSingoloPaziente = (profilo) => {
    const autorizzati = [dottore, paziente]
    if (autorizzati.includes(profilo)) {
        return { input: true, output: true }
    }
    return { input: false, output: false }
}
const permessiAnagraficaSingoloPaziente = (profilo) => {
    const autorizzati = [dottore, paziente, segreteria]
    if (autorizzati.includes(profilo)) {
        return { input: true, output: true }
    }
    return { input: false, output: false }
}
const permessiDocumentiPaziente = (profilo) => {
    const autorizzatiOutput = [dottore, segreteria, paziente]
    const autorizzatiInput = [dottore, segreteria]
    let input = false
    let output = false
    if (autorizzatiOutput.includes(profilo)) {
        output = true
    }
    if (autorizzatiInput.includes(profilo)) {
        input = true
    }
    return { input, output }
}
const permessiLesioniPaziente = (profilo) => {
    const autorizzatiOutput = [dottore, paziente]
    const autorizzatiInput = [dottore]
    let input = false
    let output = false
    if (autorizzatiOutput.includes(profilo)) {
        output = true
    }
    if (autorizzatiInput.includes(profilo)) {
        input = true
    }
    return { input, output }
}
const permessiEserciziPaziente = (profilo, professione) => {
    const autorizzatiInputProfili = []
    const autorizzatiInputProfessioni = [fisioterapista, osteopata]
    const autorizzatiOutputProfili = [paziente]
    const autorizzatiOutputProfessioni = [fisioterapista, osteopata]
    let input = false
    let output = false
    if (autorizzatiInputProfili.includes(profilo) || permessoProfessione(autorizzatiInputProfessioni, professione, profilo)) {
        input = true
    }
    if (autorizzatiOutputProfili.includes(profilo) || permessoProfessione(autorizzatiOutputProfessioni, professione, profilo)) {
        output = true
    }
    return { input, output }
}
const permessiFatturePaziente = (profilo, permessi) => {
    if (permessi.fatturePresidio) {
        return { input: true, output: true }
    }
    const autorizzatiOutput = [segreteria, paziente]
    const autorizzatiInput = [segreteria]
    let input = false
    let output = false
    if (autorizzatiOutput.includes(profilo)) {
        output = true
    }
    if (autorizzatiInput.includes(profilo)) {
        input = true
    }
    return { input, output }
}
const permessiListaDipendenti = (profilo, permessi) => {
    const autorizzatiOutput = [dottore, segreteria, paziente, chiosco, amministrazione]
    const autorizzatiInput = [amministrazione]
    let input = false
    let output = false
    if (autorizzatiOutput.includes(profilo)) {
        output = true
    }
    if (autorizzatiInput.includes(profilo) || permessi.amministrazione) {
        input = true
    }
    return { input, output }
}
const permessiPresidi = (profilo) => {
    const autorizzati = [dottore, segreteria, paziente, superAmministratore]
    return autorizzati.includes(profilo)
}
const permessiTags = (profilo) => {
    const autorizzati = [dottore]
    return autorizzati.includes(profilo)
}
const permessiFattureDipendenti = (profilo, permessi) => {
    const autorizzati = [amministrazione, superAmministratore]
    return autorizzati.includes(profilo) || (permessi.amministrazione !== undefined ? permessi.amministrazione : false)
}

export const getPermessiUtente = (utente) => {
    if (utente === undefined) {
        utente = getVeroUtenteLoggato()
    }
    const profilo = utente.profilo
    const tipoProfilo = utente.tipoProfilo || 'fisioterapista'
    const permessi = utente.permessi || {}
    return {
        impostazioniTs: permessiImpostazioniTs(profilo, permessi),
        fatturePresidio: permessiFatturePresidio(profilo, permessi),
        calendarioDipendenti: permessiCalendarioDipendenti(profilo, permessi),
        listaPazienti: permessiListaPazienti(profilo),
        libreriaEsercizi: permessiLibreriaEsercizi(profilo, tipoProfilo),
        datiPresidio: permessiDatiPresidio(profilo, permessi),
        anagraficaSingoloPaziente: permessiAnagraficaSingoloPaziente(profilo),
        datiSingoloPaziente: permessiDatiSingoloPaziente(profilo),
        documentiPaziente: permessiDocumentiPaziente(profilo),
        lesioniPaziente: permessiLesioniPaziente(profilo),
        eserciziPaziente: permessiEserciziPaziente(profilo, tipoProfilo),
        fatturePaziente: permessiFatturePaziente(profilo, permessi),
        listaDipendenti: permessiListaDipendenti(profilo, permessi),
        listaPresidi: permessiPresidi(profilo),
        listaTags: permessiTags(profilo),
        listaFattureDipendenti: permessiFattureDipendenti(profilo, permessi),
        superAmministratore: profilo === superAmministratore,
        agente: profilo === agente,
        calendario: profilo === segreteria || profilo === dottore,
    }
}
