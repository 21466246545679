import { convertiStringaInData, differenzaDate, formattaDataDaFirebase, trasformaOggettoInArray } from './../data/strumenti'
import { store } from './../data/store'
import { getIdPaziente } from './getterPaziente'
import { getNomeDipendente } from './getterDipendenti'
import { getIdLesioneSelezionata, getLesioneDaId } from './getterLesioni'
import { getProfiloUtenteLoggato, getUtenteLoggato } from './getterUtenteLoggato'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export const formattaNomeElemento = (tipo) => {
    switch (tipo) {
        case 'test-articolare':
            return 'test articolare'
        case 'nota-manuale':
            return 'body chart'
        case 'variazione-diagnosi':
            return 'variazione diagnosi'
        case 'test':
            return 'test'
        case 'strategia':
            return 'strategia'
        case 'note':
            return 'note'
        case 'esercizio':
            return 'esercizio'
        case 'diagnosi':
            return 'diagnosi fisioterapica'
        case 'notaManuale':
            return 'body chart'
        case 'testArticolare':
            return 'test articolare'
        case 'fieldTestArticolare':
            return 'test articolare'
        case 'fieldStrategia':
            return 'strategia'
        case 'fieldDiagnosi':
            return 'diagnosi fisioterapica'
        case 'fieldNotaManuale':
            return 'body chart'
        case 'fieldNote':
            return 'note'
        case 'immagini':
            return 'immagini'
        default:
            return ''
    }
}

export const getListaSeduteGeneriche = () => {
    let sedutePassate = getSeduteDiario()
    if (getIdLesioneSelezionata() !== '') {
        sedutePassate = getSeduteLesioneSelezionata()
    }
    let sedute = trasformaOggettoInArray(sedutePassate)
    sedute.sort((a, b) => {
        let dataA = a.contenuto.etichettaUniversale.dataCreazione.seconds
        let dataB = b.contenuto.etichettaUniversale.dataCreazione.seconds
        return dataB - dataA
    })
    return sedute
}

export const getListaSedute = () => {
    
    const sedute = getListaSeduteGeneriche()
    const seduteDiario = sedute.map((seduta) => {
        return {
            id: seduta.id,
            idDottore: seduta.contenuto.etichettaUniversale.user,
            dottore: getNomeDipendente(seduta.contenuto.etichettaUniversale.user),
            nomeLesione: getLesioneDaId(seduta.contenuto.idLesione).titolo,
            data: formattaDataDaFirebase(seduta.contenuto.etichettaUniversale.dataCreazione),
            elementi: getElementiSeduta(seduta.contenuto.elementi),
            link: `paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${seduta.contenuto.idLesione}/paginaVediSeduta/${seduta.id}`,
        }
    })

    const seduteDashboard = seduteDiario.filter((sedute, index) => {
        return index < 3
    })

    if (store.getState().url[5] === 'dashboardPaziente') {
        return seduteDashboard
    }

    return seduteDiario
}

export const getElementiSeduta = (elementiPassati) => {
    let elementiAggiunti = []
    Object.keys(elementiPassati).forEach((chiave) => {
        let elemento = formattaNomeElemento(elementiPassati[chiave].tipo)
        let index = elementiAggiunti.findIndex((tipo) => {
            return tipo.split('x')[0].trim() === elemento
        })
        if (index === -1) {
            elementiAggiunti.push(elemento)
        } else {
            if (elementiAggiunti[index].split('x')[1] === undefined) {
                elementiAggiunti[index] = `${elemento} x2`
            } else {
                elementiAggiunti[index] = `${elemento} x${parseInt(elementiAggiunti[index].split('x')[1]) + 1}`
            }
        }
    })

    let elementi = elementiAggiunti.reduce((acc, elemento, index) => {
        acc += `${elemento}${index === elementiAggiunti.length - 1 ? ' ' : ', '}`
        return acc
    }, '')
    return elementi !== '' ? elementi : 'Nessun elemento'
}

export const getSedutaSelezionata = () => {
    return store.getState().reducerSedute?.sedutaSelezionata?.contenuto || {}
}

export const getElementiSedutaOrdinati = () => {
    const seduta = getSedutaSelezionata()
    const daIgnorare = ['esercizio', 'variazione-diagnosi', 'test', 'immagini', undefined]
    if (seduta.elementi === undefined) {
        return []
    }
    let elementi = []
    Object.keys(seduta.elementi).forEach((chiave) => {
        let elemento = seduta.elementi[chiave]
        elemento.id = chiave
        elemento.idSeduta = getIdSedutaSelezionata()
        if (!daIgnorare.includes(elemento.tipo)) {
            elementi.push(elemento)
        }
    })
    elementi = elementi.sort((a, b) => {
        if (a.tipo < b.tipo) {
            return -1
        }
        if (a.tipo > b.tipo) {
            return 1
        }
        return 0
    })
    return elementi
}

export const getIdSedutaSelezionata = () => {
    return store.getState().reducerSedute?.sedutaSelezionata?.idSeduta || {}
}

export const scegliForm = (tipo) => {
    switch (tipo) {
        case 'fieldNote':
            return {
                id: 'note',
                tipo: 'fieldNote',
                associazione: 'note',
                label: '',
                value: '',
                details: {
                    type: 'text',
                    costumCss: ['subModel'],
                    costumCssOutput: ['labelBoldSubModel'],
                },
            }
        case 'fieldDiagnosi':
            return {
                id: 'diagnosi',
                tipo: 'fieldDiagnosi',
                associazione: 'diagnosi',
                label: '',
                value: {},
                details: {
                    costumCss: ['subModel'] /* RIMETTERE SUB MODEL */,
                    costumCssOutput: ['labelBoldSubModel'],
                },
            }
        case 'fieldStrategia':
            return {
                id: 'strategia',
                tipo: 'fieldStrategia',
                associazione: 'strategia',
                label: '',
                value: {},
                details: {
                    costumCss: ['subModel'],
                    costumCssOutput: ['labelBoldSubModel'],
                },
            }
        case 'fieldTestArticolare':
            return {
                id: 'test-articolare',
                tipo: 'fieldTestArticolare',
                associazione: 'test-articolare',
                label: '',
                value: {
                    tipoTest: '',
                    aiutato: {},
                    solo: {},
                },
                details: {
                    costumCss: ['subModel'],
                    costumCssOutput: ['labelBoldSubModel'],
                },
            }
        case 'fieldNotaManuale':
            return {
                id: 'nota-manuale',
                tipo: 'fieldNotaManuale',
                associazione: 'nota-manuale',
                label: '',
                value: {},
                details: {
                    type: 'text',
                    costumCss: ['subModel'],
                    costumCssOutput: ['labelBoldSubModel'],
                },
            }
        default:
            break
    }
}

export const getRiassuntoSeduta = () => {
    let seduta = getSedutaSelezionata()
    if (seduta.etichettaUniversale === undefined) {
        return { dottore: '', data: '', elementi: '', nomeLesione: '' }
    }
    return {
        dottore: getNomeDipendente(seduta.etichettaUniversale.user),
        data: formattaDataDaFirebase(seduta.etichettaUniversale.dataCreazione),
        elementi: getElementiSeduta(seduta.elementi || {}),
        nomeLesione: getLesioneDaId(seduta.idLesione).titolo,
    }
}

export const filtraSedute = (lista, filtri) => {
    if (filtri === undefined) {
        filtri = { dataIniziale: '', dataFinale: '', dottore: '' }
    }
    let { dataIniziale, dataFinale, dottore } = filtri
    if (dataIniziale === '' && dataFinale === '' && dottore === '') {
        return {
            filtri: false,
            errore: false,
            lista: lista,
        }
    }
    let seduteFIltrate = lista.filter((seduta) => {
        let comparazioneDataIniziale = () => {
            if (dataIniziale === '') {
                return true
            }

            let datascelta = convertiStringaInData(dataIniziale)
            let dataSeduta = convertiStringaInData(seduta.data)
            return differenzaDate(dataSeduta, datascelta) >= 0
        }
        let comparazioneDataFinale = () => {
            if (dataFinale === '') {
                return true
            }

            let datascelta = convertiStringaInData(dataFinale)
            let dataSeduta = convertiStringaInData(seduta.data)
            return differenzaDate(datascelta, dataSeduta) >= 0
        }
        let comparazioneDottore = () => {
            if (dottore === '') {
                return true
            }
            return dottore === seduta.idDottore
        }
        return comparazioneDataIniziale() && comparazioneDataFinale() && comparazioneDottore()
    })
    return {
        filtri: true,
        errore: seduteFIltrate.length === 0,
        lista: seduteFIltrate,
    }
}

export const getSeduteLesioneSelezionata = () => {
    return store.getState().reducerSedute.seduteLesione.listaSedute
}
export const getSeduteDiario = () => {
    return store.getState().reducerSedute?.listaSedute || {}
}

export const getSedutaDaId = (id) => {
    return store.getState().reducerSedute.seduteLesione.listaSedute[id]
}

export const getElementiSedutaOrdinatiDaId = (id) => {
    const seduta = getSedutaDaId(id)
    if (seduta === undefined || seduta.elementi === undefined) {
        return []
    }
    const daIgnorare = ['esercizio', 'variazione-diagnosi', 'test', 'immagini', undefined]
    if (seduta.elementi === undefined) {
        return []
    }
    let elementi = []
    Object.keys(seduta.elementi).forEach((chiave) => {
        let elemento = seduta.elementi[chiave]
        elemento.id = chiave
        elemento.idSeduta = getIdSedutaSelezionata()
        if (!daIgnorare.includes(elemento.tipo)) {
            elementi.push(elemento)
        }
    })
    elementi = elementi.sort((a, b) => {
        if (a.tipo < b.tipo) {
            return -1
        }
        if (a.tipo > b.tipo) {
            return 1
        }
        return 0
    })
    return elementi
}

export const getElementiAutorizzatiSeduta = () => {
    const tipoDottore = getUtenteLoggato().tipoProfilo || 'fisioterapista'
    //const tipoDottore = getUtenteLoggato().tipoProfilo || 'osteopata'
    const profilo = getProfiloUtenteLoggato()

    const azione = (tipo) => {
        APRI_POP_UP({ id: 'elementoSeduta', parametro: tipo })
    }

    const elementi = {
        fieldNote: {
            color: 'primary',
            icon: 'add',
            testo: 'Nota',
            autorizzati: ['fisioterapista', 'osteopata'],
            azione: () => azione('fieldNote'),
        },
        fieldStrategia: {
            color: 'primary',
            icon: 'add',
            testo: 'Strategia',
            autorizzati: ['fisioterapista'],
            azione: () => azione('fieldStrategia'),
        },
        fieldDiagnosi: {
            color: 'primary',
            icon: 'add',
            testo: 'Diagnosi fisioterapica',
            autorizzati: ['fisioterapista'],
            azione: () => azione('fieldDiagnosi'),
        },
        fieldTestArticolare: {
            color: 'primary',
            icon: 'add',
            testo: 'Test articolare',
            autorizzati: ['fisioterapista'],
            azione: () => azione('fieldTestArticolare'),
        },
        fieldNotaManuale: {
            color: 'primary',
            icon: 'add',
            testo: 'Body chart',
            autorizzati: ['fisioterapista', 'osteopata'],
            azione: () => azione('fieldNotaManuale'),
        },
    }
    let azioni = []
    Object.keys(elementi).forEach((field) => {
        const elemento = Object.assign(elementi[field],{tipo : field})
        if (elemento.autorizzati.includes(tipoDottore) || profilo === 'profilo-paziente') {
            azioni.push(elemento)
        }
    })
    return azioni
}
