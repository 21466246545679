import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { getPresidio } from '../strumenti'
import { doc, deleteDoc } from 'firebase/firestore'

export const ELIMINA_ESERCIZIO = async (idEsercizio) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    let presidio = getPresidio(getUtenteLoggato())
    await deleteDoc(doc(db, 'esercizi', idEsercizio))
    await CHIAMA_SERVER(`cancellaEsercizio`, { presidio: presidio, idEsercizio: idEsercizio })
    window.history.back()
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
