import { store } from 'data/store'
import { formattaDataDaFirebase } from 'data/strumenti'
import { trasformaOggettoInArray } from 'data/strumenti'
import { newId } from 'data/strumenti'
import { getDocs, query, collection, where } from 'firebase/firestore'
import { getTitoloEsercizio } from './getterEsercizi'
import { getIdPaziente } from './getterPaziente'
import { getTipoProfiloUtenteLoggato } from './getterUtenteLoggato'

export const getTipoScheda = () => {
    switch (getTipoProfiloUtenteLoggato()) {
        case 'fisioterapista':
            return 'fisioterapica'
        default:
            return ''
    }
}

export const getStepsScheda = (tipoScheda) => {
    switch (tipoScheda) {
        case 'fisioterapica':
            return ['Dati generali', 'Esercizi']
        default:
            return []
    }
}

export const getIntestazioneScheda = (tipoScheda) => {
    switch (tipoScheda) {
        case 'fisioterapica':
            return 'Scheda fisioterapica'
        default:
            return ''
    }
}

export const inizializzaDatiScheda = (tipoScheda) => {
    switch (tipoScheda) {
        case 'fisioterapica':
            // return { listaEsercizi: [], listaEesrciziDaSalvare: [] }
            return []
        default:
            return {}
    }
}

const aggiornaSchedaEsercizi = (lista, dati) => {
    //console.log('guarda datiiiiiii : ', dati)
    //console.log('lista iniziale : ', lista)

    if (dati.elimina) {
        lista.splice(dati.index, 1)
        return lista
    }

    var esercizio = {}
    if (dati.isNuovoEsercizio) {
        esercizio.id = dati.index === undefined ? newId() : lista[dati.index].id
        esercizio.isNuovoEsercizio = true
        esercizio.titolo = dati.nome
        esercizio.tags = dati.tags
        esercizio.posologia = dati.commenti
        esercizio.descrizione = dati.testo
        esercizio.listaImmagini = dati.listaImmagini
    }
    if (!dati.isNuovoEsercizio) {
        esercizio.isNuovoEsercizio = false
        esercizio.titolo = dati.esercizio.contenuto.titolo
        esercizio.posologia = dati.posologia
        esercizio.id = dati.esercizio.id
    }
    if (dati.index === undefined) {
        lista.push(esercizio)
    }
    if (dati.index !== undefined) {
        lista[dati.index] = esercizio
    }
    console.log('lista finale : ', lista)
    return lista
}

export const aggiornaDatiScheda = (tipoScheda, lista, dati) => {
    switch (tipoScheda) {
        case 'fisioterapica':
            return aggiornaSchedaEsercizi(lista, dati)
        default:
            return []
    }
}

export const getListaSchedeReducer = () => {
    return store.getState().reducerSchedePaziente?.listaSchede || {}
}
export const getSkeletonListaSchede = () => {
    return store.getState().reducerSchedePaziente?.skeleton || true
}

const getDatiScheda = (scheda) => {
    switch (scheda.tipo) {
        case 'fisioterapica':
            return Object.keys(scheda.esercizi).map((idEsercizio) => {
                return {
                    id: idEsercizio,
                    isNuovoEsercizio: false,
                    titolo: getTitoloEsercizio(idEsercizio),
                    posologia: scheda.esercizi[idEsercizio].posologia,
                }
            })
            break

        default:
            break
    }
}

export const getListaSchede = () => {
    let lista = trasformaOggettoInArray(getListaSchedeReducer())
    lista.sort((a, b) => {
        let dataA = a.contenuto?.etichettaUniversale?.dataCreazione?.seconds
        let dataB = b.contenuto?.etichettaUniversale?.dataCreazione?.seconds
        return dataB - dataA
    })

    return lista.map((scheda, index) => {
        var toReturn = {}
        toReturn.id = scheda.id
        toReturn.titolo = getIntestazioneScheda(scheda.contenuto.tipo)
        toReturn.tipo = scheda.contenuto.tipo
        toReturn.datiGenerali = {
            inizio: formattaDataDaFirebase(scheda.contenuto.datiGenerali.inizio),
            fine: formattaDataDaFirebase(scheda.contenuto.datiGenerali.fine),
            note: scheda.contenuto.datiGenerali.note,
        }
        toReturn.etichettaUniversale = scheda.contenuto.etichettaUniversale
        toReturn.datiScheda = getDatiScheda(scheda.contenuto)
        return toReturn
    })
}

export const getSchedaDaId = (id) => {
    const listaSchede = getListaSchede()
    const index = listaSchede.findIndex((scheda) => {
        return scheda.id === id
    })
    if (index === -1) return {}
    return listaSchede[index]
}

export const getSchedeDashboard = () => {
    const listaSchede = getListaSchede()
    return listaSchede.filter((scheda, index) => {
        return index < 3
    })
}

export const getListaSchedeScadute = async () => {
    const idPaziente = getIdPaziente()
    var oggi = new Date()
    /* oggi.setHours(0)
    oggi.setMinutes(0)
    oggi.setSeconds(0) */
    oggi.setDate(oggi.getDate() - 1)
    const qSchede = query(collection(db, 'utenti', idPaziente, 'schede'), where('datiGenerali.fine', '<', oggi))
    //const qSchede = collection(db, 'utenti', idPaziente, 'schede')
    const listaDb = await getDocs(qSchede)

    var listaSchede = []
    listaDb.forEach((elemento) => {
        const idScheda = elemento.id
        const datiScheda = elemento.data()

        var toReturn = {}
        toReturn.id = idScheda
        toReturn.titolo = getIntestazioneScheda(datiScheda.tipo)
        toReturn.tipo = datiScheda.tipo
        toReturn.datiGenerali = {
            inizio: formattaDataDaFirebase(datiScheda.datiGenerali.inizio),
            fine: formattaDataDaFirebase(datiScheda.datiGenerali.fine),
            note: datiScheda.datiGenerali.note,
        }
        toReturn.etichettaUniversale = datiScheda.etichettaUniversale
        toReturn.datiScheda = getDatiScheda(datiScheda)
        listaSchede.push(toReturn)
    })

    listaSchede.sort((a, b) => {
        let dataA = a.etichettaUniversale?.dataCreazione?.seconds
        let dataB = b.etichettaUniversale?.dataCreazione?.seconds
        return dataB - dataA
    })

    return listaSchede
}

export const salvaListaEserciziScheda = (lista) => {
    var esercizi = []
    var listaImmagini = []

    lista.forEach((elemento) => {
        const esercizio = Object.assign({}, elemento)

        if (elemento.listaImmagini !== undefined) {
            delete esercizio.listaImmagini
            elemento.listaImmagini.forEach((datiImmagine) => {
                listaImmagini.push({ file: datiImmagine.file, id: elemento.id })
            })
        }

        esercizi.push(esercizio)
    })

    return { esercizi: esercizi, listaImmagini: listaImmagini }
}
