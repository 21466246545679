import { getIdPaziente } from '../../getter/getterPaziente'
import { doc, updateDoc } from 'firebase/firestore'

export const AGGIORNA_ASSEGNAZIONE_ESERCIZIO = (idEsercizio, commento) => {
    return new Promise((resolve, reject) => {
        updateDoc(doc(db, 'utenti', getIdPaziente()), {
            [`esercizi.${idEsercizio}.commenti`]: commento,
        }).then(() => {
            console.log('sono quaaaaaa')

            resolve()
        })
    })
}
