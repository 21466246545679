import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { LEGGI_SINGOLO_DOTTORE } from './../azioni/LEGGI_SINGOLO_DOTTORE'
import { LEGGI_CREDENZIALI } from './../azioni/LEGGI_CREDENZIALI'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherSingoloDottore extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        store.subscribe(() => {
            this.update()
        })
        window.addEventListener('apriPopUp', (e) => {
            if (e.detail.id === 'chiosco') {
                this.scegliAzioni(this.idDaGuardare, e.detail.parametro)
            }
        })
    }

    update() {
        if (store.getState().url[3] === 'paginaVediDottore') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
        if (store.getState().url[3] === 'paginaVediDipendente') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
        if (store.getState().url[3] === 'paginaVediSegreteria') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
        if (store.getState().url[3] === 'paginaVediChiosco') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
        if (store.getState().url[3] === 'paginaVediDatiBonifico') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
        if (store.getState().url[3] === 'paginaScegliMetodoDiPagamento') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    apriConnessione(nuovoId) {

        this.funzioneConnessione = onSnapshot(doc(db, 'utenti', nuovoId), (querySnapshot) => {
            var dottore = {}
            dottore.id = querySnapshot.id
            dottore.contenuto = querySnapshot.data()
            LEGGI_SINGOLO_DOTTORE(dottore)
        })

        this.funzioneLeggiCredenzialiAccesso = onSnapshot(doc(db, 'utenti', nuovoId, 'datiLogIn', 'dati'), (doc) => {
            LEGGI_CREDENZIALI(doc.data())
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
            this.funzioneLeggiCredenzialiAccesso()
        }
    }
}
