import { store } from 'data/store'
import { trasformaOggettoInArray } from 'data/strumenti'

export const getListaFeedback = () => {
    let lista = trasformaOggettoInArray(store.getState().reducerFeedback || {})
    lista.sort((a, b) => {
        let dataA = a.contenuto.data.seconds
        let dataB = b.contenuto.data.seconds
        return dataB - dataA
    })
    return lista
}
