import { store } from './../store'
import { newId } from './../strumenti'
import { makePdfConsensoInformato } from './../makePdfConsensoInformato'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { doc, writeBatch } from 'firebase/firestore'

export const SALVA_MODULO_CONSENSO_INFORMATO = (data, firmaUtente, firmaDottore) => {
    return new Promise((resolve, reject) => {
        let nuovoDocId = newId()
        let indexRef = doc(db, 'utenti', data.idNecessari.idPaziente, 'lesioni', data.idNecessari.idLesione)
        let pathInBucket = `documentazione/consensoInformato/${nuovoDocId}/consensoInformato.pdf`

        let uploadPdf = (pdf, path) => {
            return new Promise((resolve, reject) => {
                uploadBytes(ref(storage, path), pdf).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL)
                    })
                })
            })
        }

        makePdfConsensoInformato(data, firmaUtente, firmaDottore).then((pdf) => {
            return uploadPdf(pdf, pathInBucket).then(() => {
                const batch = writeBatch(db)
                batch.update(indexRef, {
                    [`sedute.${data.idNecessari.idSeduta}.elementi.${data.idNecessari.idStrategia}.datiDocConsensoInformato`]: { docId: nuovoDocId },
                })
                batch
                    .commit()
                    .then(() => {
                        resolve()
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        })
    })
}
