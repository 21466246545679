import { getPresidio } from 'data/strumenti'
import { newId } from 'data/strumenti'
import { creaEtichettaUniversale } from 'data/strumenti'
import { Timestamp } from 'firebase/firestore'
import { getIdPaziente } from 'getter/getterPaziente'
import { salvaListaEserciziScheda } from 'getter/getterSchede'
import { getTipoScheda } from 'getter/getterSchede'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { SALVA_FILE_ESERCIZIO } from './SALVA_FILE_ESERCIZIO'

export const CREA_SCHEDA = async (datiGenerali, datiScheda) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    var scheda = {}
    var listaImmagini = []

    scheda.etichettaUniversale = creaEtichettaUniversale(getPresidio(getUtenteLoggato()))
    scheda.idScheda = newId()
    scheda.idPaziente = getIdPaziente()
    scheda.tipo = getTipoScheda()
    scheda.datiGenerali = Object.assign({}, datiGenerali)
    scheda.datiGenerali.inizio = Timestamp.fromDate(datiGenerali.inizio)
    scheda.datiGenerali.fine = Timestamp.fromDate(datiGenerali.fine)

    switch (scheda.tipo) {
        case 'fisioterapica':
            var dati = salvaListaEserciziScheda(datiScheda)
            scheda.esercizi = dati.esercizi
            listaImmagini = dati.listaImmagini
            break
        default:
            break
    }

    await CHIAMA_SERVER('creaScheda', scheda)

    await Promise.all(
        listaImmagini.map((datiImmagine) => {
            console.log("datiiiiii : ", dati);
            return SALVA_FILE_ESERCIZIO(datiImmagine.file, datiImmagine.id)
        }),
    )

    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
