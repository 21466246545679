import { getProfiloUtenteLoggato, getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { store } from './../store' 
import { creaEtichettaUniversale, getPresidio } from './../strumenti'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const CREA_PAZIENTE = (datiCreaPaziente, newIdPaziente, datiCredenzialiPaziente, docId) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

    return new Promise((resolve, reject) => {
        let presidio = getPresidio(getUtenteLoggato())

        datiCreaPaziente.etichettaUniversale = creaEtichettaUniversale(presidio)
        datiCreaPaziente.profilo = 'profilo-paziente'
        datiCreaPaziente.isPaziente = true
        datiCreaPaziente.presidiAutorizzati = [presidio]

        /* if (getProfiloUtenteLoggato() === 'profilo-chiosco') {
            datiCreaPaziente.datiDocPrivacy = {
                docId: docId,
                status: 'done',
            }
        } */

        let credenziali = datiCredenzialiPaziente !== undefined && Object.keys(datiCredenzialiPaziente).length !== 0 ? datiCredenzialiPaziente : false

        let daPassare = {
            idUtente: newIdPaziente,
            datiUtente: datiCreaPaziente,
            credenziali: datiCredenzialiPaziente,
            idPresidio: presidio,
            profilo: 'profilo-paziente',
            inserisciCredenziali : credenziali
        }

        CHIAMA_SERVER(`creaUtente`, daPassare).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
