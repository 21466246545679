import { Card } from '@mui/material'
import React, { useState } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import VediForm from 'formMaker/vediForm'
import { formDatiGeneraliScheda } from 'formModel/formDatiGeneraliScheda'
import ElementoEserciziSuScheda from './elementoEserciziSuScheda'
import { useEffect } from 'react'
import { getTipoScheda } from 'getter/getterSchede'
import { getStepsScheda } from 'getter/getterSchede'
import { getIntestazioneScheda } from 'getter/getterSchede'
import { inizializzaDatiScheda } from 'getter/getterSchede'
import { aggiornaDatiScheda } from 'getter/getterSchede'
import { CREA_SCHEDA } from 'data/azioni/CREA_SCHEDA'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Divider } from '@mui/material'
import { connect } from 'react-redux'
import { getPermessiUtente } from 'getter/getterPermessi'
import { Icon } from '@mui/material'
import MDButton from 'components/MDButton'
import { getIdPaziente } from 'getter/getterPaziente'
import { MODIFICA_SCHEDA } from 'data/azioni/MODIFICA_SCHEDA'

export function elementoSchedaPaziente(props) {
    const [step, setStep] = useState(0)
    const [datiGenerali, setDatiGenerali] = useState({})
    const [datiScheda, setDatiScheda] = useState([])
    const [tipoScheda, setTipoScheda] = useState('')
    const [steps, setSteps] = useState([])
    const [aperto, setAperto] = useState(false)

    useEffect(() => {
        const tipo = getTipoScheda()
        setTipoScheda(tipo)
        setSteps(getStepsScheda(tipo))
        setDatiScheda(inizializzaDatiScheda(tipo))
    }, [])

    useEffect(() => {
        setDatiGenerali(props.scheda.datiGenerali || {})
        setDatiScheda(props.scheda.datiScheda || [])
    }, [props.scheda])

    const indietro = () => {
        setStep(step - 1)
    }

    const annulla = () => {
        props.navigate(`paginaVediPaziente/${getIdPaziente()}/eserciziPaziente`)
    }

    const aggiornaDati = (dati) => {
        const nuoviDati = aggiornaDatiScheda(tipoScheda, [...datiScheda], dati)
        setDatiScheda(nuoviDati)
    }

    const salvaScheda = async () => {
        if (props.modifica) {
            await MODIFICA_SCHEDA(datiGenerali,datiScheda)
        }
        if (!props.modifica) {
            await CREA_SCHEDA(datiGenerali, datiScheda)
        }
        props.navigate(`paginaVediPaziente/${getIdPaziente()}/eserciziPaziente`)
    }

    const aggiornaForm = (dati) => {
        if (new Date(dati.inizio.seconds * 1000).getDate() >= new Date(dati.fine.seconds * 1000).getDate()) {
            dati.fine = ''
        }
        setDatiGenerali(dati)
    }
    const vaiAvanti = (dati) => {
        setDatiGenerali(dati)
        setStep(step + 1)
    }

    const renderStepper = () => {
        if (!props.output) {
            return (
                <MDBox mx={2} mt={-5}>
                    <Stepper activeStep={step} alternativeLabel>
                        {steps.map((label, index) => (
                            <Step key={label} completed={index < step}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </MDBox>
            )
        }
        return ''
    }

    const renderForm = () => {
        return <VediForm annulla={() => annulla()} soloInput={!props.output} vista={props.output ? 'output' : 'input'} salva={(e) => vaiAvanti(e)} dati={datiGenerali} form={formDatiGeneraliScheda(datiGenerali)} formAggiornato={(e) => aggiornaForm(e)} isPrimoStep={!props.output} noBottoni={props.output} />
    }

    const renderDatiScheda = (tipo) => {
        switch (tipo) {
            case 'fisioterapica':
                return <ElementoEserciziSuScheda output={props.output} indietro={() => indietro()} annulla={() => annulla()} salva={() => salvaScheda()} aggiorna={(e) => aggiornaDati(e)} lista={datiScheda} />

            default:
                return 'Ciao'
        }
    }

    const renderContenuto = () => {
        if (!props.output) {
            switch (step) {
                case 0:
                    return renderForm()
                case 1:
                    return renderDatiScheda(tipoScheda)

                default:
                    break
            }
        }
        return ''
    }

    const renderIntestazione = () => {
        return (
            <MDTypography component='div' sx={{ ml: 2, mt: 2, pl: 2, mb: 2, borderBottom: 'solid' }} variant='h3' color='info'>
                {getIntestazioneScheda(tipoScheda)}
            </MDTypography>
        )
    }

    const renderBottone = () => {
        if (!props.permessi || props.scaduta) {
            return ''
        }
        return (
            <MDButton size='small' variant='text' color='primary' onClick={() => props.navigate(`paginaVediPaziente/${getIdPaziente()}/PaginaSchedaPaziente/${props.scheda.id}`)}>
                modifica
                <Icon sx={{ ml: 1 }}>edit</Icon>
            </MDButton>
        )
    }

    if (props.output) {
        return (
            <Accordion ml='auto' mr='auto' expanded={aperto} onChange={() => setAperto(!aperto)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                    <MDBox display='flex'>
                        <MDTypography color={'info'} variant='h5'>
                            {props.scheda.titolo}
                        </MDTypography>
                    </MDBox>
                </AccordionSummary>
                <AccordionDetails>
                    {renderBottone()}
                    {renderForm()}
                    <Divider />
                    {renderDatiScheda(props.scheda.tipo)}
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <Card sx={{ mt: 5, p: 2, mx: 'auto', width: '100%', maxWidth: '1000px' }}>
            {renderStepper()} {renderIntestazione()} {renderContenuto()}
        </Card>
    )
}

const mapStateToProps = (state) => ({
    permessi: getPermessiUtente().eserciziPaziente.input,
    modifica: state.url[6] !== 'crea',
})

export default connect(mapStateToProps, null)(elementoSchedaPaziente)
