export const formDiagnosi = () => {
    return {
        parts: [
            {
                id: 'testo',
                tipo: 'fieldTextArea',
                associazione: 'testo',
                label: 'Nota:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'tagLibero',
                tipo: 'fieldTextArea',
                associazione: 'tagLibero',
                label: 'Tag libero:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'tag',
                tipo: 'fieldSceltaMultipla',
                associazione: 'tag',
                details: {
                    scelte: ['Ipomobilità articolare', 'Controllo Del Movimento', 'Estensibilità miofasciale', 'Neurodinamica', 'Trigger points', 'Sensibilizzazione centrale', "Deficit dell'immagine corporea"],
                    complex: false,
                    costumCssOutput: ['labelBold'],
                    costumCss: ['tagModificatiSedute'],
                },
                label: 'Tag',
                value: [],
                validazione: 'testoRichiesto',
            },
        ],
    }
}
