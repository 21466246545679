import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import MDButton from 'components/MDButton'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MDBox from 'components/MDBox'
import VediForm from 'formMaker/vediForm'
import { formAppuntamento } from 'formModel/formAppuntamento'
import { SVUOTA_FILTRI_CERCA_PAZIENTE } from 'data/azioni/SVUOTA_FILTRI_CERCA_PAZIENTE'
import { getProfiloUtenteLoggato, getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { getPresidio } from 'data/strumenti'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { CREA_APPUNTAMENTO } from 'data/azioni/CREA_APPUNTAMENTO'
import { getAppuntamentoDaId } from 'getter/getterAppuntamenti'
import MDTypography from 'components/MDTypography'
import { getNomePazienteDaId } from 'getter/getterPaziente'
import { MODIFICA_APPUNTAMENTO } from 'data/azioni/MODIFICA_APPUNTAMENTO'
import { Icon } from '@mui/material'
import { ELIMINA_APPUNTAMENTO } from 'data/azioni/ELIMINA_APPUNTAMENTO'
import ElementoSelezionaPazienteAppuntamento from 'componenti/elementoSelezionaPazienteAppuntamento'
import { CHIAMA_SERVER } from 'data/azioni/CHIAMA_SERVER'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Link } from 'react-router-dom'
import { CHIUDI_POP_UP } from 'data/azioni/CHIUDI_POP_UP'
import { getPermessiUtente } from 'getter/getterPermessi'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpAppuntamento(props) {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }
    const [paziente, setPaziente] = useState({})
    const [dati, setDati] = useState({})
    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)
    const [modifica, setModifica] = useState(false)
    const [step, setStep] = useState(0)
    const steps = ['Seleziona paziente', 'Info appuntamento']

    const handleClose = () => {
        props.chiudi()
    }

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
            //window.innerWidth < breakpoints.values.sm ? setOpzioni(opzioniMobile) : setOpzioni(opzioniStandard)
        })
    }, [])

    useEffect(() => {
        setStep(0)
        setModifica(false)
        if (props.parametro.idEvento !== undefined) {
            setModifica(true)
        }
        let data = props.parametro.end
        if (props.aperto && props.parametro.idEvento === undefined) {
            let inizio = new Date(data)
            inizio.setHours(props.parametro.start.getHours(), props.parametro.start.getMinutes(), 0)
            let nuoviDati = {
                giorno: data,
                inizio: inizio,
                fine: props.parametro.end,
            }
            /* if (getProfiloUtenteLoggato() === 'profilo-dottore') {
                nuoviDati.idDottore = getIdUtenteLoggato()
            } */
            setDati(nuoviDati)
        }
    }, [props.aperto])

    const formattaDatiAppuntamento = (e) => {
        let inizio = new Date(e.giorno)
        let fine = new Date(e.giorno)
        inizio.setHours(e.inizio.getHours(), e.inizio.getMinutes(), 0)
        fine.setHours(e.fine.getHours(), e.fine.getMinutes(), 0)
        let dati = {
            data: `${e.giorno.getFullYear()}-${e.giorno.getMonth() + 1}`,
            giorno: e.giorno,
            inizio: inizio,
            fine: fine,
            descrizione: e.descrizione,
            idDottore: e.idDottore,
            idPresidio: getPresidio(getUtenteLoggato()),
        }
        if (props.parametro.idEvento !== undefined && e.idPaziente !== undefined) {
            dati.idPaziente = e.idPaziente
        }
        if (props.parametro.idEvento !== undefined && e.idPaziente === undefined) {
            dati.nomePaziente = e.nomePaziente
        }
        if (props.parametro.idEvento === undefined && paziente.id !== undefined) {
            dati.idPaziente = paziente.id
        }
        if (props.parametro.idEvento === undefined && paziente.id === undefined) {
            dati.nomePaziente = `${paziente.nome} ${paziente.cognome}`
        }
        return dati
    }

    const nuovoAppuntamento = async (e) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await CREA_APPUNTAMENTO(formattaDatiAppuntamento(e))
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        handleClose()
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata.' } })
    }

    const inviaPromemoria = async (e) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        const risposta = await CHIAMA_SERVER('inviaPromemoriaAppuntamento', { id: props.parametro.idEvento })
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        if (!risposta.errore) {
            APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata.' } })
        }
        if (risposta.errore) {
            APRI_POP_UP({ id: 'alert', parametro: { severity: 'error', testo: risposta.status } })
        }
    }

    const aggiornaAppuntamento = async (e) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await MODIFICA_APPUNTAMENTO(props.parametro.idEvento, formattaDatiAppuntamento(e))
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        handleClose()
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata.' } })
    }

    const cancellaAppuntamento = async () => {
        APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di voler eliminare l'appuntamento?`, azione: () => ELIMINA_APPUNTAMENTO(props.parametro.idEvento) } })
    }

    const pazienteSelezionato = (e) => {
        setPaziente(e)
        setStep(step + 1)
        SVUOTA_FILTRI_CERCA_PAZIENTE()
    }

    const vaiIndietro = (e) => {
        setPaziente({})
        setDati(e)
        setStep(step - 1)
    }

    const getLink = (appuntamento) =>{
        if (appuntamento.nomePaziente === undefined) {
            return `/paginaVediPaziente/${appuntamento.idPaziente}/dashboardPaziente`
        }
        return '/paginaCreaPaziente'
    }

    const renderCercaPaziente = () => {
        return <ElementoSelezionaPazienteAppuntamento annulla={() => handleClose()} pazienteSelezionato={(e) => pazienteSelezionato(e)} />
    }

    const getIdDottore = () => {
        if (getProfiloUtenteLoggato() === 'profilo-segreteria') {
            return props.parametro.dottore || ''
        }
        console.log('parametroooooo : ', props.parametro);
        return props.parametro.dottore === "" ? getIdUtenteLoggato() : props.parametro.dottore || ""
    } 

    const renderForm = () => {
        return <VediForm soloInput={true} form={formAppuntamento(getPermessiUtente(getVeroUtenteLoggato()).calendarioDipendenti, getIdDottore())} salva={(e) => nuovoAppuntamento(e)} dati={dati} vista='input' isUltimoStep={true} annulla={() => handleClose()} indietro={(e) => vaiIndietro(e)} />
    }

    const renderContenuto = () => {
        if (!props.aperto) {
            return ''
        }
        if (modifica) {
            const appuntamento = getAppuntamentoDaId(props.parametro.idEvento)
            //console.log('appuntamento : ', appuntamento)
            return (
                <React.Fragment>
                    <MDBox display='flex' flexDirection='row' justifyContent='space-between'>
                        <MDTypography color='dark' variant='subtitle2' fontWeight='medium'>
                            {appuntamento === undefined ? '' : appuntamento.nomePaziente || getNomePazienteDaId(appuntamento.idPaziente)}
                        </MDTypography>
                        <MDButton sx={{ height: '32px' }} size='small' variant='contained' color='primary' onClick={() => inviaPromemoria()}>
                            invia promemoria
                            <Icon sx={{ ml: 1 }}>send</Icon>
                        </MDButton>
                        <div style={{ display: 'flex', width: 'max-content', flexDirection: 'column', boxSizing: 'border-box', alignItems: 'end' }}>
                            <MDButton sx={{ mb: 1, width: '100%' }} size='small' variant='contained' color='error' onClick={() => cancellaAppuntamento()}>
                                <Icon sx={{ mr: 'auto' }}>delete</Icon>
                                elimina
                            </MDButton>
                            <MDButton
                                sx={{ width: '100%' }}
                                size='small'
                                variant='contained'
                                color='primary'
                                component={Link}
                                to={getLink(appuntamento)}
                                onClick={() => {
                                    CHIUDI_POP_UP()
                                }}>
                                {appuntamento.nomePaziente === undefined ? 'Vedi' : 'crea'} paziente
                                <ArrowForwardRoundedIcon sx={{ ml: 1 }}></ArrowForwardRoundedIcon>
                            </MDButton>
                        </div>
                    </MDBox>
                    <MDBox mt={2}>
                        <VediForm vista='input' annulla={() => handleClose()} form={formAppuntamento(getProfiloUtenteLoggato())} salva={(e) => aggiornaAppuntamento(e)} dati={appuntamento} />
                    </MDBox>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MDBox sx={{ margin: 'auto', marginTop: '35px' }} p={2}>
                    {step === 0 ? renderCercaPaziente() : renderForm()}
                </MDBox>
            </React.Fragment>
        )
    }

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>{modifica ? 'Modifica appuntamento' : 'Nuovo appuntamento'}</DialogTitle>
            <DialogContent dividers={true}>{renderContenuto()}</DialogContent>
        </Dialog>
    )
}
