import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Grid, Card } from '@mui/material'
import Container from '@mui/material/Container'
import bgImage from 'assets/images/immagine.webp'
import Features from 'landing/componenti/features'
import FunzioniPrincipali from 'landing/componenti/funzioniPrincipali'
import Footer from 'landing/componenti/footer'

export default function paginaVantaggi() {
    return (
        <React.Fragment>

            
            <MDBox
                minHeight='50vh'
                width='100%'
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                <Container>
                    <Grid container item xs={12} lg={8} flexDirection='column' justifyContent='center' alignItems='center' sx={{ mx: 'auto', textAlign: 'center' }}>
                        <MDTypography
                            variant='h2'
                            color='white'
                            mb={2}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['3xl'],
                                },
                            })}>
                             
                        </MDTypography>
                    </Grid>
                </Container>
            </MDBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: 'saturate(200%) blur(30px)',
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    overflow: 'hidden',
                }}>
                <Features />
               

                <MDBox component='section' py={0}>
                    <Container sx={{display :"flex", flexDirection : 'column', alignItems:"center"}}>
                        <MDTypography variant='h2' mb={1} color='info'>
                            Prova Gratuita
                        </MDTypography>

                        <MDTypography variant='body2' color='text'>
                            I primi due fisioterapisti della tua clinica avranno diritto a 30 giorni di prova prima di iniaziare a pagare Physio Archive
                        </MDTypography>
                    </Container>
                </MDBox>

                <FunzioniPrincipali />

                {/*  <Faq />
                <Features />
                <Contact /> */}
            </Card>

          

        </React.Fragment>
    )
}
