import React, { useState } from 'react'
import { InputBoolean } from 'fields/fieldBoolean'
import MDBox from 'components/MDBox'
import ElementoMostraPazienti from './../componenti/elementoMostraPazienti'
import ElementoCampiCercaPaziente from 'componenti/elementoCampiCercaPaziente'
import VediForm from 'formMaker/vediForm'
import MDButton from 'components/MDButton'

export default function elementoSelezionaPazienteAppuntamento(props) {
    const [esiste, setEsiste] = useState(true)
    const [paziente, setPaziente] = useState(true)

    const formNuovoPaziente = {
        parts: [
            {
                id: 'nome',
                tipo: 'fieldText',
                associazione: 'nome',
                label: 'Nome:',
                value: '',
                validazione: 'testoRichiesto',
            },
            {
                id: 'cognome',
                tipo: 'fieldText',
                associazione: 'cognome',
                label: 'Cognome:',
                value: '',
                validazione: 'testoRichiesto',
            },
        ],
    }

    const modelBoolean = {
        id: 'esiste',
        tipo: 'fieldBoolean',
        associazione: 'esiste',
        label: 'Paziente presente in archivio',
        value: false,
    }

    const salva = (e) => {
        setPaziente(e)
        props.pazienteSelezionato(e)
    }

    const renderContenuto = () => {
        if (esiste) {
            return (
                <React.Fragment>
                    <ElementoCampiCercaPaziente />
                    <MDBox sx={{ mt: 3 }}>
                        <ElementoMostraPazienti noCache={true} azione={(e) => props.pazienteSelezionato(e)} />
                    </MDBox>
                    <MDBox className='contenitore-bottoni-destra'>
                        <MDButton varian='contained' color='error' onClick={() => props.annulla()}>
                            annulla
                        </MDButton>
                    </MDBox>
                </React.Fragment>
            )
        }
        return <VediForm form={formNuovoPaziente} dati={paziente} isPrimoStep={true} vista='input' soloInput={true} annulla={() => props.annulla()} salva={(e) => salva(e)} />
    }

    return (
        <React.Fragment>
            <MDBox display='flex' flexDirection='column'>
                <InputBoolean
                    model={modelBoolean}
                    value={esiste}
                    errore={false}
                    azione={(a, b) => {
                        setEsiste(b)
                    }}
                />
                {renderContenuto()}
            </MDBox>
        </React.Fragment>
    )
}
