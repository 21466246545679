import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export default function elementoMostraChiosco(props) {

    const test = () => {
        APRI_POP_UP({id : 'chiosco', parametro : props.dipendente.id})
    }

    return (
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
            <ListItemIcon>
                <AccountCircleRoundedIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={`${props.dipendente.contenuto.cognome} ${props.dipendente.contenuto.nome}`} />
            <ListItemIcon>
                <ArrowForwardIosRoundedIcon color='dark' />
            </ListItemIcon>
        </ListItem>
    )
}
