import React from 'react'
import MDBox from 'components/MDBox'
import { Divider, Icon } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import colors from 'assets/theme/base/colors'

export default function elementoMostraTipologiaFormDaInserire(props) {
    const sfondo = props.isDottore || props.abilitato ? colors.info.main : colors.grey[500]
    const testi = props.isDottore || props.abilitato ? 'white' : 'dark'

    return (
        <MDBox className='centrato fletti-verticalmente' p={2} sx={{backgroundColor : sfondo}} variant='gradient' width='100%' height='max-content' borderRadius='lg' shadow='lg' coloredShadow='info'>
            <MDBox className='centrato fletti-orizzontalmente' color={testi} mb={2}>
                <Icon color={testi} sx={{ mr: 2 }}>
                    {props.tipologia.icona}
                </Icon>
                <MDTypography variant='h3' color={testi} fontWeight='medium'>
                    {props.tipologia.titolo}
                </MDTypography>
            </MDBox>
            <MDTypography color={testi} variant='subtitle2'>
                {props.tipologia.testo}
            </MDTypography>
            {/* <Divider light /> */}
            <MDBox className='contenitore-bottoni-destra'>
                {props.isDottore ? (
                    <MDButton color={testi} variant='text' size='small'>
                        {props.abilitato ? 'disabilita' : 'abilita'}
                    </MDButton>
                ) : null}

                <MDButton color={testi} variant='contained' size='small'>
                    vedi
                </MDButton>
            </MDBox>
        </MDBox>
    )
}
