import React from 'react'
import ElelementoMostraPazienti from './../componenti/elementoMostraPazienti'
import MDBox from 'components/MDBox'
import ElementoCampiCercaPaziente from 'componenti/elementoCampiCercaPaziente'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

export default function paginaCercaPaziente(props) {
    return (
        <React.Fragment>
            <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%', mt: 5 }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                <MDBox mt={-7} borderRadius='lg' shadow='lg' variant='gradient' bgColor={'info'} ml={'auto'} mr={'auto'} p={3} maxWidth={1230}>
                    {/*  <MDBox bgColor={'white'} p={2} borderRadius='lg'> */}
                    <ElementoCampiCercaPaziente testoBianco={true}/>
                    {/*  </MDBox> */}
                </MDBox>
                <MDBox mt={3}>
                    <ElelementoMostraPazienti navigate={props.navigate} />
                </MDBox>
            </MDBox>
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => props.navigate('paginaCreaPaziente')}>
                <AddIcon />
            </Fab>
        </React.Fragment>
    )
}
