import { getListaDottori } from 'getter/getterDipendenti'

export const formAppuntamento = (permesso, fisioterapista) => {
    let form = {
        costumCssInput: ['baseFormNoBackground'],
        parts: [
            {
                id: 'giorno',
                tipo: 'fieldData',
                associazione: 'giorno',
                label: 'Giorno:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    type: 'data',
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'inizio',
                tipo: 'fieldData',
                associazione: 'inizio',
                label: 'Ora inizio:',
                value: '',
                details: {
                    vediOra: true,
                    noCalendario: true,
                    type: 'data',
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'fine',
                tipo: 'fieldData',
                associazione: 'fine',
                label: 'Ora fine:',
                value: '',
                details: {
                    vediOra: true,
                    noCalendario: true,
                    type: 'data',
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'descrizione',
                tipo: 'fieldTextArea',
                associazione: 'descrizione',
                label: 'Descrizione:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                },
            },
        ],
    }

    if (permesso) {
        form.parts.push({
            id: 'idDottore',
            tipo: 'fieldSelect',
            associazione: 'idDottore',
            label: 'Fisioterapista :',
            value: fisioterapista,
            details: {
                grid: { lg: { input: 4, output: 4 } },
                toShow: 'nome',
                toSave: 'id',
                scelte: getListaDottori(),
                complex: true,
            },
            validazione: 'testoRichiestoSelect',
        })
    }

    return form
}
