import React from 'react'
import dizionarioFields from './../fields/dizionarioFields'
import { Grid } from '@mui/material'

export default function formInput(props) {
    let standard = 'test-form'
    if (props.isSubInput) {
        standard = 'test-form-no-padding'
    }

    const standardXs = 4
    const standardLg = 2
    return (
        <Grid component={props.isSubInput ? 'div' : 'form'} container spacing={3}>
            {props.form.parts.map((model, index) => {
                let valore = props.dati[model.id] || model.value
                let valoreXs = model.details?.grid?.xs?.input || standardXs
                let valoreLg = model.details?.grid?.lg?.input || standardLg
                return (
                    <Grid item xs={valoreXs * 3} lg={valoreLg * 3} key={index}>
                        {dizionarioFields[model.tipo].input(model, valore, props.azione, props.errori[index] || '', props.handlePending)}
                    </Grid>
                )
            })}
        </Grid>
    )
}
