import React, { useEffect } from 'react'
import Header from 'landing/componenti/header'
import { store } from './../../data/store'
import { Card, Divider, Icon } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'

// Material Kit 2 PRO React components
import MDBox from 'components/MDBox'

// Material Kit 2 PRO React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/SimpleInfoCard'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import RaisedBlogCard from 'examples/Cards/BlogCards/RaisedBlogCard'
import logoAifi from 'assets/images/logo_aifi.webp'
import logo from 'assets/images/multiDevice3.webp'
import logoPhysio from 'assets/images/logo.png'

export default function paginaLanding(props) {
    useEffect(() => {
        store.dispatch({
            type: 'CAMBIA_URL',
            payload: window.location.href.split('/'),
        })
    }, [])

    const testoMultiDevice = `Il gestionale è disponibile su tutti i principali dispositivi.
    Tutti i dati sono salvati su un server cloud sicuro.`
    const testoDimostrazione = `Richiedi una dimostrazione gratuita: scrivici il orario di preferenza via mail a physioarchive.info@gmail.com e fisseremo subito una sessione su misura per te`

    return (
        <>
            <Header navigate={props.navigate} />

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -25,
                    mb: 4,
                    minHeight: '300px',
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: 'saturate(200%) blur(30px)',
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    overflow: 'hidden',
                }}>
                <MDBox component='section' py={{ xs: 6, md: 8 }} alignItems='center' justifyContent='center' sx={{ flexDirection: 'column' }} flexDirection='column'>
                    <MDBox
                        p={5}
                        sx={({ breakpoints }) => ({
                            /* [breakpoints.down('md')]: {
                                fontSize: size['3xl'],
                            }, */
                            [breakpoints.down('sm')]: {
                                paddingLeft: '0px !important',
                                paddingRight: '0px !important',
                            },
                            flexDirection: 'column',
                            width: '100%',
                            maxWidth: '920px',
                            m: 'auto',
                        })}
                        bgColor='white'
                        variant='gradient'
                        textAlign='center'
                        lineHeight={1.25}
                        shadow='xl'
                        borderRadius='xxl'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'>
                        <MDTypography mb={4} color='info' variant='h3'>
                            Physio Archive: la tua clinica virtuale
                        </MDTypography>
                        <iframe width='100%' height={'100%'} className='video-landing' src={'https://www.youtube.com/embed/prWXsGJBS6M'} title='YouTube video player' frameBorder='0' allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
                        <MDTypography display='block' variant='body2' color='text' mt={2} ml={1} mr={1}>
                            Quanto tempo dedichi ogni giorno alla gestione burocratica del paziente, alle pratiche amministrative e agli aspetti legali della tua clinica fisioterapica? Per gestire il tutto al meglio, risparmiando tempo e minimizzando le possibilità di errore, puoi fare affidamento su Physio Archive, la piattaforma studiata da fisioterapisti per fisioterapisti per migliorare e facilitare la gestione della tua clinica. Il fisioterapista e osteopata Franco Biancofiore della clinica Motus di Torino ci spiega le principali funzioni e i benefit che ha ottenuto lavorando con Physio Archive, a vantaggio di tutto il team della clinica e, soprattutto, dei suoi pazienti.{' '}
                        </MDTypography>
                    </MDBox>
                </MDBox>

                <Divider />

                <MDBox component='section' py={{ xs: 6, md: 8 }}>
                    <Container>
                        <MDBox mx='auto' mb={5} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <MDTypography color='info' variant='h3'>
                                I vantaggi di Physio Archive
                            </MDTypography>
                        </MDBox>

                        <Grid container item xs={12} justifyContent='center'>
                            <Grid item xs={12} md={4} sx={{ ml: { xs: 0, md: 'auto' }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}>
                                <Stack spacing={{ xs: 4, md: 8 }}>
                                    <DefaultInfoCard icon='public' title='Da fisioterapisti per fisioterapisti' description={`${appUtilizzata} Archive nasce grazie all’esperienza diretta dei fisioterapisti: il gestionale infatti è cucito su misura per la categoria, progettato e realizzato per rispondere alle specifiche esigenze evidenziate dai tuoi colleghi, punto dopo punto.`} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ mr: { xs: 0, md: 'auto' }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}>
                                <Stack spacing={{ xs: 4, md: 8 }}>
                                    <DefaultInfoCard icon='payments' title='Un gestionale che cresce con te' description={`Per ${appUtilizzata} Archive non esiste la parola fine: il gestionale è in continua evoluzione ed è pensato per crescere insieme a te. Il nostro team è a tua disposizione per valutare l’integrazione di nuovi servizi e per prestarti assistenza nel momento del bisogno.`} />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} justifyContent='center' mt={2}>
                            <Grid item xs={12} md={4} sx={{ ml: { xs: 0, md: 'auto' }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}>
                                <Stack spacing={{ xs: 2, md: 2 }}>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Sistema Tessera Sanitaria
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Dati analitici
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Semplicità
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Precisione e comodità
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ mr: { xs: 0, md: 'auto' }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}>
                                <Stack spacing={{ xs: 2, md: 2 }}>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Documenti legali
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Completezza
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Sicurezza
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox display='flex' lineHeight={1.25}>
                                        <MDTypography variant='body1' color='info'>
                                            <Icon sx={{ fontWeight: 'bold' }}>done</Icon>
                                        </MDTypography>
                                        <MDBox pl={2}>
                                            <MDTypography variant='button' color='text' fontWeight='bold'>
                                                Accessibiltà illimitata
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </Stack>
                            </Grid>
                        </Grid>

                        <MDBox mx='auto' mt={5} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <MDButton color='info' variant='gradient' onClick={() => props.navigate('vantaggi')}>
                                SCOPRI DI PIÙ
                            </MDButton>
                        </MDBox>
                    </Container>
                </MDBox>

                <Divider />

                <MDBox pt={6} pb={3} px={3} sx={{ boxSizing: 'border-box' }}>
                    <Grid container spacing={{ xs: 5, md: 5 }} sx={{ boxSizing: 'border-box' }}>
                        <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                            <RaisedBlogCard
                                image={logoAifi}
                                title='Convenzionati AIFI'
                                description='Physio Archive collabora con L’Associazione Italiana di Fisioterapia. I soci AIFI godono di una riduzione sul prezzo di listino.'
                                action={{
                                    type: 'internal',
                                    route: '/pricing',
                                    color: 'info',
                                    label: 'Vai al pricing',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                            <RaisedBlogCard image={logoPhysio} title='Dimostrazione gratuita' description={testoDimostrazione} />
                        </Grid>
                        <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'center', justifyItems: 'center' }}>
                            <RaisedBlogCard
                                image={logo}
                                title='Multi device'
                                description={testoMultiDevice}
                                action={{
                                    type: 'internal',
                                    route: '/registrati',
                                    color: 'info',
                                    label: 'Apri la tua clinica virtuale',
                                }}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
            </Card>

            {/*  <VideoTutorial />

            {jsonFunzioniLanding.map((sezione, index) => {
                const elemento = Object.assign(sezione, { index })
                return <MostraElemenetoLanding sezione={elemento} key={index} />
            })}
            <ElementoVediDateTutorial />
            <AltreFeatures />
            <Testimonial />
            <Carosello />
            <Features />
            <MultiDevice />
            <Pricing /> */}
        </>
    )
}
