const partiSegreteria = [
    {
        id: 'amministrazione',
        tipo: 'fieldSelect',
        associazione: 'amministrazione',
        label: 'Gestione dipendenti:',
        value: false,
        details: {
            grid: { lg: { input: 4, output: 4 } },
            scelte: [
                { id: true, testo: 'SI' },
                { id: false, testo: 'NO' },
            ],
            complex: true,
            toShow: 'testo',
            toSave: 'id',
        },
    },
]

const partiDottore = [
    {
        id: 'amministrazione',
        tipo: 'fieldSelect',
        associazione: 'amministrazione',
        label: 'Gestione dipendenti:',
        value: false,
        details: {
            scelte: [
                { id: true, testo: 'SI' },
                { id: false, testo: 'NO' },
            ],
            complex: true,
            toShow: 'testo',
            toSave: 'id',
        },
    },
    {
        id: 'impostazioniTs',
        tipo: 'fieldSelect',
        associazione: 'impostazioniTs',
        label: 'Impostazioni Ts:',
        value: false,
        details: {
            scelte: [
                { id: true, testo: 'SI' },
                { id: false, testo: 'NO' },
            ],
            complex: true,
            toShow: 'testo',
            toSave: 'id',
        },
    },
    {
        id: 'fatturePresidio',
        tipo: 'fieldSelect',
        associazione: 'fatturePresidio',
        label: 'Gestione fatture:',
        value: false,
        details: {
            scelte: [
                { id: true, testo: 'SI' },
                { id: false, testo: 'NO' },
            ],
            complex: true,
            toShow: 'testo',
            toSave: 'id',
        },
    },
    {
        id: 'calendarioDipendenti',
        tipo: 'fieldSelect',
        associazione: 'calendarioDipendenti',
        label: 'Gestione calendario dipendenti:',
        value: false,
        details: {
            scelte: [
                { id: true, testo: 'SI' },
                { id: false, testo: 'NO' },
            ],
            complex: true,
            toShow: 'testo',
            toSave: 'id',
        },
    },
   /*  {
        id: 'fatturePaziente',
        tipo: 'fieldSelect',
        associazione: 'fatturePaziente',
        label: 'Gestione fatture pazienti:',
        value: false,
        details: {
            scelte: [
                { id: true, testo: 'SI' },
                { id: false, testo: 'NO' },
            ],
            complex: true,
            toShow: 'testo',
            toSave: 'id',
        },
    }, */
]

export const formPermessiDipendente = (profilo) => {
    return {
        costumCssInput: ['formCambiata'],
        parts: profilo === 'profilo-dottore' ? partiDottore : partiSegreteria,
    }
}
