import { getUtenteLoggato, getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../strumenti'
import { store } from './../store' 
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const SALVA_UTENZA_TS = (utenza, id) => {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        let daPassare = {
            idPresidio: getPresidio(getVeroUtenteLoggato()),
            idUtenza: id,
            utenza: utenza,
        }
        CHIAMA_SERVER(`salvaUtenzaTs`, daPassare).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
