/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import MuiLink from '@mui/material/Link'

// Material Kit 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function RaisedBlogCard({ image, title, description, action }) {
    const cardActionStyles = {
        display: 'flex',
        alignItems: 'center',

        '& .material-icons, .material-icons-round,': {
            transform: `translateX(2px)`,
            transition: 'transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)',
        },

        '&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round': {
            transform: `translateX(6px)`,
        },
    }

    return (
        <Card sx={{ maxWidth: '400px', height: '100%', display:'grid', gridTemplateRows : '1fr 1fr 60px' }}>
            <MDBox position='relative' borderRadius='lg' mx={2} mt={-3} height='200px'>
                <MDBox  borderRadius='lg' shadow='md' width='100%' position='relative' zIndex={1} height='200px' />
                <MDBox
                    borderRadius='lg'
                    shadow='md'
                    width='100%'
                    height='100%'
                    position='absolute'
                    left={0}
                    top={0}
                    sx={{
                        backgroundImage: `url(${image})`,
                        /*  transform: 'scale(0.94)',
                        filter: 'blur(12px)', */
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                />
            </MDBox>
            <MDBox p={3} mt={2}>
                <MDTypography display='inline' variant='h4' >
                    {title}
                </MDTypography>
                <MDBox mt={1} mb={3}>
                    <MDTypography variant='body2' component='p' color='text'>
                        {description}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox p={1} pl={3}>
                {action === undefined ? null : action.type === 'external' ? (
                    <MDTypography component={MuiLink} href={action.route} target='_blank' rel='noreferrer' variant='body2' fontWeight='regular' color={action.color ? action.color : 'dark'} sx={cardActionStyles}>
                        {action.label}
                        <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
                    </MDTypography>
                ) : (
                    <MDTypography component={Link} to={action.route} variant='body2' fontWeight='regular' color={action.color ? action.color : 'dark'} sx={cardActionStyles}>
                        {action.label}
                        <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
                    </MDTypography>
                )}
            </MDBox>
        </Card>
    )
}

// Typechecking props for the RaisedBlogCard
RaisedBlogCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    /* action: PropTypes.shape({
        type: PropTypes.oneOf(['external', 'internal']).isRequired,
        route: PropTypes.string.isRequired,
        color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
        label: PropTypes.string.isRequired,
    }).isRequired, */
}

export default RaisedBlogCard
