import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import { Grid, Icon } from '@mui/material'
import MDTypography from 'components/MDTypography'
import { copiaOggetto } from 'data/strumenti'
import { getPresidio } from 'data/strumenti'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { ref, getDownloadURL } from 'firebase/storage'
import { isScanFattuttura } from 'getter/getterFatture'

export default function popUpVediDocumentiFattura(props) {
    const handleClose = () => {
        props.chiudi()
    }

    const getLinkDaStorage = (path) => {
        return new Promise((resolve, reject) => {
            var pathReference = ref(storage, path)
            getDownloadURL(pathReference).then((url) => {
                resolve(url)
            })
        })
    }

    const getUrl = async () => {
        let idPresidio = getPresidio(getUtenteLoggato())
        document.getElementById('fattura').href = await getLinkDaStorage(`fatture/${idPresidio}/${props.parametro.id}/fattura_${props.parametro.contenuto.numFattura}.pdf`)

        if (document.getElementById('scan') !== null) {
            document.getElementById('scan').href = await getLinkDaStorage(`fatture/${idPresidio}/${props.parametro.id}/scan-${props.parametro.contenuto.numFattura}.png`)
        }

        Object.keys(props.parametro.contenuto.rimborsi).map((idRimborso, index) => {
            let rimborso = props.parametro.contenuto.rimborsi[idRimborso]
            let path = `fatture/${idPresidio}/${props.parametro.id}/rimborsi/nota_di_credito_${rimborso.numDocumento}.pdf`
            getLinkDaStorage(path).then((link) => {
                document.getElementById(`rimborso-${index}`).href = link
            })
        })
    }

    useEffect(() => {
        if (props.aperto) {
            setTimeout(() => {
                getUrl()
            }, 100)
        }
    }, [props.aperto])

    const getListaRimborsi = () => {
        if (props.parametro.contenuto.rimborsi === undefined) {
            return []
        }
        return Object.keys(props.parametro.contenuto.rimborsi)
    }

    const singoloDocumento = (oggetto) => {
        return (
            <Grid key={oggetto.id} container sx={{ marginBottom: '20px', cursor: 'pointer', backgroundColor: '#eaeaea' }} p={1} component='a' id={oggetto.id} target='blank' download spacing={1} borderRadius='lg'>
                <Grid container item xs={9} spacing={1}>
                    <Grid item xs={12} lg={12}>
                        <MDTypography variant='h5' fontWeight='bold'>
                            {oggetto.testo}
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <MDBox mb={1} lineHeight={0}>
                            <MDTypography variant='caption' fontWeight='medium'>
                                Totale :
                                <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                                    {oggetto.totale}
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <MDBox display='flex' justifyContent='center' alignItems='center' width='100%' height='100%'>
                        <Icon>download</Icon>
                    </MDBox>
                </Grid>
            </Grid>
        )
    }

    const renderScan = () => {
        if (isScanFattuttura(props.parametro.contenuto)) {
            return singoloDocumento({ id: 'scan', testo: `Scan fattura N: ${props.parametro.contenuto.numFattura}`, totale: props.parametro.contenuto.totale })
        }
        return ''
    }

    const renderDocumenti = () => {
        if (!props.aperto) {
            return ''
        }
        return (
            <React.Fragment>
                {singoloDocumento({ id: 'fattura', testo: `Fattura N: ${props.parametro.contenuto.numFattura}`, totale: props.parametro.contenuto.totale })}
                {renderScan()}

                {getListaRimborsi().map((idRimborso, index) => {
                    let rimborso = props.parametro.contenuto.rimborsi[idRimborso]
                    return singoloDocumento({ id: `rimborso-${index}`, testo: `Nota di credito N ${rimborso.numDocumento}`, totale: rimborso.totale })
                })}
            </React.Fragment>
        )
    }


    return (
        <Dialog open={props.aperto} onClose={() => handleClose(false)} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Documenti fattura</DialogTitle>
            <DialogContent dividers={true}>{renderDocumenti()}</DialogContent>
            <DialogActions>
                <MDButton color='primary' variant='text' onClick={() => handleClose()}>
                    chiudi
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
