import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { AGGIORNA_VERSIONI } from './../azioni/AGGIORNA_VERSIONI'
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore'

export class watcherVersioni extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    async apriConnessione() {
        let versioni = {}
        const q = query(collection(db, 'versione'), orderBy('data', 'desc'), limit(5))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                versioni[doc.id] = doc.data()
            })
            AGGIORNA_VERSIONI(versioni)
        })

    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            //this.funzioneConnessione();
        }
    }
}
