export const dataTestArticolari = [
    {
        tipo: 'ancaSxFlessione',
        immagine: 'ancaSxFlessione.jpg',
        senso: 'orario',
        origine: { x: 531, y: 701 },
        zero: { x: 20, y: 701 },
        angoli: { max: 140, min: 0 },
        nome: 'Anca sinistra Flessione',
    },
    {
        tipo: 'ancaDxFlessione',
        immagine: 'ancaDxFlessione.jpg',
        senso: 'antiorario',
        origine: { x: 469, y: 701 },
        zero: { x: 980, y: 701 },
        angoli: { max: 140, min: 0 },
        nome: 'Anca destra Flessione',
    },
    {
        tipo: 'ancaSxEstensione',
        immagine: 'ancaSxEstensione.jpg',
        senso: 'antiorario',
        origine: { x: 175, y: 645 },
        zero: { x: 980, y: 645 },
        angoli: { max: 40, min: 0 },
        nome: 'Anca sinistra Estensione',
    },
    {
        tipo: 'ancaDxEstensione',
        immagine: 'ancaDxEstensione.jpg',
        senso: 'orario',
        origine: { x: 825, y: 645 },
        zero: { x: 20, y: 645 },
        angoli: { max: 40, min: 0 },
        nome: 'Anca destra Estensione',
    },
    {
        tipo: 'ancaSxAbduzione',
        immagine: 'ancaSxAbduzione.jpg',
        senso: 'antiorario',
        origine: { x: 95, y: 698 },
        zero: { x: 960, y: 698 },
        angoli: { max: 50, min: 0 },
        nome: 'Anca sinistra Abduzione',
    },
    {
        tipo: 'ancaDxAbduzione',
        immagine: 'ancaDxAbduzione.jpg',
        senso: 'orario',
        origine: { x: 905, y: 698 },
        zero: { x: 40, y: 698 },
        angoli: { max: 50, min: 0 },
        nome: 'Anca destra Abduzione',
    },
    {
        tipo: 'ancaSxRotazione',
        immagine: 'ancaSxRotazione.jpg',
        senso: 'orario',
        origine: { x: 462, y: 840 },
        zero: { x: 462, y: 232 },
        angoli: { max: 60, min: -50 },
        nome: 'Anca sinistra Rotazione',
    },
    {
        tipo: 'ancaDxRotazione',
        immagine: 'ancaDxRotazione.jpg',
        senso: 'antiorario',
        origine: { x: 538, y: 840 },
        zero: { x: 538, y: 232 },
        angoli: { max: 60, min: -50 },
        nome: 'Anca destra Rotazione',
    },
    {
        tipo: 'cavigliaDxFlessoEstensione',
        immagine: 'cavigliaDxFlessoEstensione.jpg',
        senso: 'antiorario',
        origine: { x: 600, y: 662 },
        zero: { x: 433, y: 151 },
        angoli: { max: 60, min: -40 },
        nome: 'Caviglia destra Flesso Estensione',
    },
    {
        tipo: 'cavigliaSxFlessoEstensione',
        immagine: 'cavigliaSxFlessoEstensione.jpg',
        senso: 'orario',
        origine: { x: 400, y: 662 },
        zero: { x: 567, y: 151 },
        angoli: { max: 60, min: -40 },
        nome: 'Caviglia sinistra Flesso Estensione',
    },
    {
        tipo: 'ginocchioDxFlessione',
        immagine: 'ginocchioDxFlessione.jpg',
        senso: 'orario',
        origine: { x: 540, y: 710 },
        zero: { x: 60, y: 710 },
        angoli: { max: 150, min: 0 },
        nome: 'Ginocchio destro Flessione',
    },
    {
        tipo: 'ginocchioSxFlessione',
        immagine: 'ginocchioSxFlessione.jpg',
        senso: 'antiorario',
        origine: { x: 460, y: 710 },
        zero: { x: 940, y: 710 },
        angoli: { max: 150, min: 0 },
        nome: 'Ginocchio sinistro Flessione',
    },
    {
        tipo: 'spallaSxFlessoEstensione',
        immagine: 'spallaSxFlessoEstensione.jpg',
        senso: 'orario',
        origine: { x: 555, y: 482 },
        zero: { x: 555, y: 940 },
        angoli: { max: 180, min: -60 },
        nome: 'Spalla sinistra Flesso Estensione',
    },
    {
        tipo: 'spallaDxFlessoEstensione',
        immagine: 'spallaDxFlessoEstensione.jpg',
        senso: 'antiorario',
        origine: { x: 445, y: 482 },
        zero: { x: 445, y: 940 },
        angoli: { max: 180, min: -60 },
        nome: 'Spalla destra Flesso Estensione',
    },
    {
        tipo: 'spallaSxRotazione',
        immagine: 'spallaSxRotazione.jpg',
        senso: 'orario',
        origine: { x: 492, y: 576 },
        zero: { x: 492, y: 110 },
        angoli: { max: 90, min: -90 },
        nome: 'Spalla sinistra Rotazione',
    },
    {
        tipo: 'spallaDxRotazione',
        immagine: 'spallaDxRotazione.jpg',
        senso: 'antiorario',
        origine: { x: 508, y: 566 },
        zero: { x: 508, y: 110 },
        angoli: { max: 90, min: -90 },
        nome: 'Spalla destra Rotazione',
    },
    {
        tipo: 'spallaDxAbduzione',
        immagine: 'spallaDxAbduzione.jpg',
        senso: 'orario',
        origine: { x: 626, y: 505 },
        zero: { x: 626, y: 900 },
        angoli: { max: 180, min: 0 },
        nome: 'Spalla destra Abduzione',
    },
    {
        tipo: 'spallaSxAbduzione',
        immagine: 'spallaSxAbduzione.jpg',
        senso: 'antiorario',
        origine: { x: 374, y: 505 },
        zero: { x: 374, y: 900 },
        angoli: { max: 180, min: 0 },
        nome: 'Spalla sinistra Abduzione',
    },
    {
        tipo: 'gomitoDxFlessoEstensione',
        immagine: 'gomitoDxFlessoEstensione.jpg',
        senso: 'antiorario',
        origine: { x: 192, y: 480 },
        zero: { x: 192, y: 930 },
        angoli: { max: 180, min: 0 },
        nome: 'Gomito destro Flesso Estensione',
    },
    {
        tipo: 'gomitoSxFlessoEstensione',
        immagine: 'gomitoSxFlessoEstensione.jpg',
        senso: 'orario',
        origine: { x: 808, y: 480 },
        zero: { x: 808, y: 930 },
        angoli: { max: 180, min: 0 },
        nome: 'Gomito sinistro Flesso Estensione',
    },
    {
        tipo: 'manoDxPronoSupinazione',
        immagine: 'manoDxPronoSupinazione.jpg',
        senso: 'orario',
        origine: { x: 473, y: 616 },
        zero: { x: 473, y: 190 },
        angoli: { max: 90, min: -90 },
        nome: 'Mano destra PronoSupinazione',
    },
    {
        tipo: 'manoSxPronoSupinazione',
        immagine: 'manoSxPronoSupinazione.jpg',
        senso: 'antiorario',
        origine: { x: 527, y: 616 },
        zero: { x: 527, y: 190 },
        angoli: { max: 90, min: -90 },
        nome: 'Mano sinistra PronoSupinazione',
    },
    {
        tipo: 'polsoDxDeviazione',
        immagine: 'polsoDxDeviazione.jpg',
        senso: 'orario',
        origine: { x: 500, y: 745 },
        zero: { x: 500, y: 270 },
        angoli: { max: 90, min: -90 },
        nome: 'Polso destro Deviazione',
    },
    {
        tipo: 'polsoSxDeviazione',
        immagine: 'polsoSxDeviazione.jpg',
        senso: 'antiorario',
        origine: { x: 500, y: 745 },
        zero: { x: 500, y: 270 },
        angoli: { max: 90, min: -90 },
        nome: 'Polso sinistro Deviazione',
    },
    {
        tipo: 'polsoSxFlessoEstensione',
        immagine: 'polsoSxFlessoEstensione.jpg',
        senso: 'antiorario',
        origine: { x: 450, y: 483 },
        zero: { x: 895, y: 483 },
        angoli: { max: 90, min: -90 },
        nome: 'Polso sinistro Flesso Estensione',
    },
    {
        tipo: 'polsoDxFlessoEstensione',
        immagine: 'polsoDxFlessoEstensione.jpg',
        senso: 'orario',
        origine: { x: 550, y: 483 },
        zero: { x: 105, y: 483 },
        angoli: { max: 90, min: -90 },
        nome: 'Polso destro Flesso Estensione',
    },
]
