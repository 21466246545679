import { getIdLesioneSelezionata } from '../../getter/getterLesioni'
import { getIdPaziente } from '../../getter/getterPaziente'
import { getIdSedutaSelezionata } from '../../getter/getterSedute'
import { newId } from './../strumenti'
import { ref, uploadString  } from 'firebase/storage'
import { doc, updateDoc } from 'firebase/firestore'

export const SALVA_ELEMENTO_SEDUTA = (valore) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    let idUtente = getIdPaziente()
    let idLesione = getIdLesioneSelezionata()
    let idSeduta = getIdSedutaSelezionata()

    return new Promise((resolve, reject) => {
        let nuovoId = newId()
        if (valore.tipo !== 'fieldNotaManuale') {
            updateDoc(doc(db, 'utenti', idUtente, 'lesioni', idLesione), { [`sedute.${idSeduta}.elementi.${nuovoId}`]: valore }).then(() => {
                window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
                resolve()
            })
        } else {
            let valoreDaSalvare = {
                idNota: valore.idNota,
                tipo: valore.tipo,
                sfondo: valore.sfondo,
            }
            let storagePath = `noteManuali/${valore.idNota}.png`
            let dbRef = doc(db,'utenti', idUtente, 'lesioni', idLesione)

            uploadString(ref(storage, storagePath), valore.disegno, 'data_url')
                .then((snapshot) => {
                    return updateDoc(dbRef, { [`sedute.${idSeduta}.elementi.${nuovoId}`]: valoreDaSalvare })
                })
                .then(() => {
                    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
                    resolve()
                })
                .catch((err) => {})
        }
    })
}
