import { getLesioniMenu } from 'getter/getterLesioni'
import React from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { APRI_POP_UP } from './../data/azioni/APRI_POP_UP'
import { getPermessiUtente } from 'getter/getterPermessi'
import { Divider, Grid } from '@mui/material'
import List from '@mui/material/List'
import ElementoMostraDisfunzione from 'componenti/elementoMostraDisfunzione'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import { getSkeletonLesioni } from 'getter/getterLesioni'

export const paginaDisfunzioniPaziente = (props) => {
    const vediLesione = (link) => {
        props.navigate(link)
    }

    const renderFab = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => APRI_POP_UP({ id: 'disfunzione', parametro: { navigate: props.navigate, nome: '', modifica: false } })}>
                <AddIcon />
            </Fab>
        )
    }

    const renderDisfunzioni = () => {
        if (props.skeleton) {
            return <MDTypography color='info'>Caricamento disfunzioni in corso</MDTypography>
        }
        if (props.disfunzioni.length === 0) {
            return <MDTypography color='error'>Nessuna disfunzione presente</MDTypography>
        }
        return (
            <List>
                {props.disfunzioni.map((disfunzione, index) => {
                    return (
                        <React.Fragment key={disfunzione.id}>
                            <ElementoMostraDisfunzione disfunzione={disfunzione} navigate={props.navigate} />
                            {index !== props.disfunzioni.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </List>
        )
    }

    return (
        <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <FormatListBulletedRoundedIcon color='white' sx={{ mr: 1 }} />
                <MDTypography color={'white'} variant='h5'>
                    Disfunzioni paziente
                </MDTypography>
            </MDBox>
            <Grid container direction='column'>
                {renderDisfunzioni()}
            </Grid>
            {renderFab()}
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        disfunzioni: getLesioniMenu(),
        permessi: getPermessiUtente().lesioniPaziente.input,
        skeleton : getSkeletonLesioni()
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaDisfunzioniPaziente)
