import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Grid, Card } from '@mui/material'
import Container from '@mui/material/Container'
import bgImage from 'assets/images/immagine.webp'
import ElementoVediDateTutorial from 'componenti/elementoVediDateTutorial'

export default function paginaDimostrazioneGratuita() {
    return (
        <React.Fragment>
            <MDBox
                minHeight='50vh'
                width='100%'
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                <Container>
                    <Grid container item xs={12} lg={8} flexDirection='column' justifyContent='center' alignItems='center' sx={{ mx: 'auto', textAlign: 'center' }}>
                        <MDTypography
                            variant='h2'
                            color='white'
                            mb={2}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['3xl'],
                                },
                            })}></MDTypography>
                    </Grid>
                </Container>
            </MDBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: 'saturate(200%) blur(30px)',
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    overflow: 'hidden',
                }}>
                <MDBox component='section' py={0}><ElementoVediDateTutorial /></MDBox>
            </Card>
        </React.Fragment>
    )
}
