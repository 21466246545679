import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { creaEtichettaUniversale, getPresidio } from '../strumenti'
import { store } from './../store'
import { getStatoPresidio } from 'getter/getterPresidio'
import { AGGIORNA_STATO_POP_UP_GUIDA } from './AGGIORNA_STATO_POP_UP_GUIDA'
import { getStatoPresidioDaAprire } from 'getter/getterPresidio'
import { getTipoProfessionista } from 'getter/getterAppUtilizzata'

export const CREA_DIPENDENTE = (id, formCredenziali, formDati, permessi) => {
    let profilo = store.getState().url[4] === 'creaDottore' ? 'profilo-dottore' : 'profilo-segreteria'
    return new Promise((resolve, reject) => {
        let presidio = getPresidio(store.getState().statoUtente.utente)
        console.log('dati : ', formDati);

        formDati.isPaziente = false
        formDati.profilo = profilo
        formDati.etichettaUniversale = creaEtichettaUniversale(presidio)
        formDati.attivo = true
        formDati.pagato = false
        formDati.profiliAutorizzati = []
        formDati.profiloOriginale = profilo
        formDati.permessi = permessi

        if (profilo === 'profilo-dottore') {
            formDati.tipoProfilo = getTipoProfessionista()
        }

        let daPassare = { idUtente: id, datiUtente: formDati, credenziali: formCredenziali, profilo: profilo, idPresidio: presidio, inserisciCredenziali : true}

        CHIAMA_SERVER(`creaUtente`, daPassare).then(() => {
            if (getStatoPresidio() !== 4 && getStatoPresidioDaAprire()) {
                AGGIORNA_STATO_POP_UP_GUIDA(3, true).then(() => {
                    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
                    resolve()
                })
            } else {
                window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
                resolve()
            }
        })
    })
}
