import { newId } from 'data/strumenti'
import { formattaDataDaFirebase } from 'data/strumenti'
import { creaEtichettaUniversale } from 'data/strumenti'
import { doc, setDoc, Timestamp } from 'firebase/firestore'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const CREA_APPUNTAMENTO = async (appuntamento) => {
    appuntamento.etichettaUniversale = creaEtichettaUniversale(appuntamento.idPresidio)
    const id = newId()

   

    appuntamento.giorno = Timestamp.fromDate(appuntamento.giorno)
    appuntamento.inizio = Timestamp.fromDate(appuntamento.inizio)
    appuntamento.fine = Timestamp.fromDate(appuntamento.fine)
    console.log('appuntamento : ', appuntamento)
    //await setDoc(doc(db, 'appuntamenti', id), appuntamento)
    await CHIAMA_SERVER('creaAppuntamento', { id: id, datiAppuntamento: appuntamento })
    return
}
