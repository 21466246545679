import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { InputSelect } from 'fields/fieldSelect'
import { CHIAMA_SERVER } from 'data/azioni/CHIAMA_SERVER'
import { newId } from 'data/strumenti'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export const popUpInviaFattura = (props) => {
    const handleClose = () => {
        props.chiudi()
    }
    const baseForm = {
        id: 'utenza',
        tipo: 'fieldSelect',
        associazione: 'utenza',
        label: 'Seleziona utenza:',
        value: '',
        details: {
            toShow: 'nome',
            toSave: 'id',
            scelte: [],
            complex: true,
        },
    }
    const [model, setModel] = useState(baseForm)
    const [errore, setErrore] = useState('')
    const [utenza, setUtenza] = useState('')

    useEffect(
        (e) => {
            let nuovoForm = Object.assign({}, baseForm)
            nuovoForm.details.scelte = props.utenze
            if (props.utenze.length === 1) {
                setUtenza(props.utenze[0].id)
            }
            setModel(nuovoForm)
        },
        [props.utenze],
    )

    const invia = async () => {
        if (utenza === '') {
            setErrore('Selezionare un utenza')
            return
        }
        setErrore('')
        await CHIAMA_SERVER('inviaFattura', { idFattura: props.parametro, utenza: utenza, idChiamata: newId() })
        APRI_POP_UP({id : 'alert', parametro : { severity: 'info', testo: 'Invio fattura in corso' }})
    }

    const renderNoUtenze = (
        <MDTypography color='error' variant='string'>
            CREARE UN UTENZA PER INVIARE LE FATTURE AL SISTEMA TS
        </MDTypography>
    )

    const renderTitolo = model.details.scelte.length === 0 ? 'Errore' : 'Invia fattura'

    const renderSelect = <InputSelect model={model} value={utenza} errore={errore} azione={(a, b) => setUtenza(b)} />

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>{renderTitolo}</DialogTitle>
            <DialogContent dividers={true}>{model.details.scelte.length === 0 ? renderNoUtenze : renderSelect}</DialogContent>
            <DialogActions>
                <MDButton color='error' variant='text' onClick={handleClose}>
                    annulla
                </MDButton>
                <MDButton color='primary' variant='text' onClick={() => invia()}>
                    invia
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    return {
        utenze: state.reducerUtenzeTs || [],
    }
}

export default connect(mapStateToProps, null)(popUpInviaFattura)
