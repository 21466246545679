const initialState = {
    listaLesioni: {},
    lesioneDaVedere: {
        id: '',
        contenuto: {},
    },
    skeleton: true,
}

export const reducerLesioni = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_LESIONI':
            let nuovoStato = Object.assign(state, { listaLesioni: action.payload })
            nuovoStato.listaLesioni = action.payload
            nuovoStato.lesioneDaVedere.contenuto = getLesione(action.payload, nuovoStato.lesioneDaVedere.id)
            nuovoStato.skeleton = false
            return nuovoStato
        case 'CAMBIA_URL':
            let statoNuovo = Object.assign(state, {})
            let id = getId(action.payload)
            let lesione = getLesione(state.listaLesioni, id)
            statoNuovo.lesioneDaVedere = {
                id: id,
                contenuto: lesione,
            }

            return statoNuovo
        case 'SVUOTA_PAZIENTE':
            return Object.assign(state, { skeleton: true })
        default:
            return state
    }
}

let getId = (url) => {
    if (!url.includes('paginaVediLesione')) {
        return ''
    }
    //let parteUrl = url[3] === 'paginaVediLesione' ? 4 : 6
    let parteUrl = 6
    return url[parteUrl]
}

let getLesione = (lesioni, id) => {
    if (id === '') {
        return {}
    }

    return lesioni[id] === undefined ? {} : lesioni[id]
}
