export const formContatti = () => {
    return {
        costumCssInput: ['baseFormNoBackground'],
        parts: [
            {
                id: 'nome',
                tipo: 'fieldText',
                associazione: 'nome',
                label: 'Nome :',
                value: '',
                validazione: 'testoRichiesto',
            },
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'Email :',
                value: '',
                detils: { type: 'email' },
                validazione: 'testoRichiesto',
            },
            {
                id: 'cellulare',
                tipo: 'fieldText',
                associazione: 'cellulare',
                label: 'Cellulare :',
                value: '',
            },
            {
                id: 'testo',
                tipo: 'fieldTextArea',
                associazione: 'testo',
                label: 'Testo :',
                value: '',
                /* details: { grid: { lg: { input: 4 } } }, */
                validazione: 'testoRichiesto',
            },

            /* {
                id: 'stato',
                tipo: 'fieldSelect',
                associazione: 'stato',
                label: 'Stato fattura :',
                value: '',
                details: {
                    grid: { lg: { input: 4 } },
                    scelte: ['non inviata', 'inviata', 'errore', 'nota di credito'],
                    spazioVuoto: true,
                },
            }, */
        ],
    }
}
