import { store } from './../store'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'

export class userWatcher {
    constructor() {
        this.utente = {}
        this.leggi = false
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            switch (user) {
                case undefined:
                    this.dispatch(1, null, null)
                    break
                case null:
                    this.chiudiConnessioneDatiUtente()
                    this.dispatch(2, null, null)
                    break
                default:
                    this.dispatch(3, user.uid, null)
                    this.apriConnessioneDatiUtente(user.uid)
                    break
            }
        })
    }

    apriConnessioneDatiUtente(uid) {
        this.funzioneGuardaUtente = onSnapshot(doc(db, 'utenti', uid), (user) => {
            let controllo = this.isDipendente(user.data())
            if (controllo) {
                if (user.data().attivo && user.data().pagato) {
                    this.dispatch(4, uid, user.data())
                } else {
                    this.dispatch(5, uid, user.data())
                }
            }
            if (!controllo) {
                this.dispatch(4, uid, user.data())
            }
        })
    }

    isDipendente(dati) {
        let dipendenti = ['profilo-dottore', 'profilo-segreteria']
        return dipendenti.includes(dati.profilo)
    }

    chiudiConnessioneDatiUtente() {
        if (this.funzioneGuardaUtente !== undefined) {
            this.funzioneGuardaUtente()
        }
    }

    dispatch(stato, id, utente) {
        store.dispatch({
            type: 'CAMBIA_STATO',
            payload: { stato, id, utente },
        })
    }
}
