import { InputText } from 'fields/fieldText'
import React, { useState } from 'react'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getAuth, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'

export default function ElementoRiautenticazione(props) {
    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')
    const [errore, setErrore] = useState('')

    let formMail = {
        id: 'email',
        tipo: 'fieldText',
        associazione: 'email',
        label: 'mail',
        value: '',
        details: {
            type: 'text',
        },
    }

    let formPassword = {
        id: 'password',
        tipo: 'fieldText',
        associazione: 'password',
        label: 'password',
        value: '',
        details: {
            nascondiLabel: true,
            type: 'password',
        },
    }

    function aggiorna(campo, valore) {
        if (campo === 'email') {
            setMail(valore)
        }
        if (campo !== 'email') {
            setPassword(valore)
        }
    }

    async function riautentica() {
        var user = getAuth().currentUser
        var cred = EmailAuthProvider.credential(mail,password)
        try {
            await reauthenticateWithCredential(user, cred)
            setErrore('')
            props.avanti()
        } catch (error) {
            setErrore('Credenziali inserite errate')
        }
    }

    return (
        <React.Fragment>
            <MDBox lineHeight={0} ml={3} mt={1}>
                <MDTypography color={'text'} variant='subtitle1'>
                    Riautenticazione obbligatoria
                </MDTypography>
            </MDBox>
            <form className='test-form'>
                <InputText model={formMail} value={mail} azione={(a, b) => aggiorna(a, b)} errore={errore} />
                <InputText model={formPassword} value={password} azione={(a, b) => aggiorna(a, b)} errore={errore} />
            </form>
            <div className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='primary' onClick={() => riautentica()}>
                    avanti
                </MDButton>
            </div>
        </React.Fragment>
    )
}
