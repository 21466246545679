import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import { getLesioneSelezionata } from 'getter/getterLesioni'
import { getPaziente } from 'getter/getterPaziente'
import MDTypography from 'components/MDTypography'
import logo from 'assets/images/logo.png'
import { convertiDataInStringa } from 'data/strumenti'
import VediForm from 'formMaker/vediForm'
import { formInformazioniDisfunzioni } from 'formModel/formInformazioniDisfunzioni'
import { getInformazioniLesione } from 'getter/getterLesioni'
import { getListaSedute, getElementiSedutaOrdinatiDaId } from 'getter/getterSedute'
import ElementoVediNota from 'componenti/elementoVediNota'
import ElementoVediDiagnosi from 'componenti/elementoVediDiagnosi'
import ElementoVediTestArticolare from 'componenti/elementoVediTestArticolare'
import ElementoVediBodyChart from 'componenti/elementoVediBodyChart'
import ElementoVediStrategia from 'componenti/elementoVediStrategia'
import MDButton from 'components/MDButton'
import Backdrop from '@mui/material/Backdrop'
import { Icon } from '@mui/material'
import { SettingsBackupRestore } from '@mui/icons-material'
import { formUtente } from 'formModel/formUtente'
import { formStoriaClinicaPaziente } from 'formModel/formStoriaClinicaPaziente'
import { formAbitudiniPaziente } from 'formModel/formAbitudiniPaziente'

export const popUpPdfDisfunzione = (props) => {
    const [classi, setClassi] = useState('pop-up-pdf')
    const [classiFixed, setClassiFixed] = useState('parte-bianca-pop-up-pdf')

    const handleClose = () => {
        props.chiudi()
    }

    const stampa = () => {
        try {
            document.execCommand('print', false, null)
        } catch {
            window.print()
        }
    }

    useEffect(() => {
        if (props.aperto) {
            setClassi('pop-up-pdf pop-up-pdf-aperto')
            setClassiFixed('parte-bianca-pop-up-pdf pop-up-pdf-aperto')
        }
        if (!props.aperto) {
            setClassi('pop-up-pdf')
            setClassiFixed('parte-bianca-pop-up-pdf')
        }
    }, [props.aperto])

    let cambiaTipo = (tipo) => {
        switch (tipo) {
            case 'note':
                return 'fieldNote'
            case 'diagnosi':
                return 'fieldDiagnosi'
            case 'strategia':
                return 'fieldStrategia'
            case 'testArticolare':
                return 'fieldTestArticolare'
            case 'notaManuale':
                return 'fieldNotaManuale'
            case 'test-articolare':
                return 'fieldTestArticolare'
            case 'nota-manuale':
                return 'fieldNotaManuale'
            default:
                return tipo
        }
    }

    let renderElemento = (elemento) => {
        // return <div className='elemento-seduta-pdf'></div>

        switch (cambiaTipo(elemento.tipo)) {
            case 'fieldNote':
                return <ElementoVediNota value={elemento.testo} />
            case 'fieldDiagnosi':
                return <ElementoVediDiagnosi value={elemento} />
            case 'fieldTestArticolare':
                return <ElementoVediTestArticolare isPopUp={true} value={elemento} />
            case 'fieldNotaManuale':
                return <ElementoVediBodyChart value={elemento} />
            case 'fieldStrategia':
                return <ElementoVediStrategia noIcona={true} value={elemento} navigate={props.navigate} />

            default:
                return ''
        }
    }

    const renderDescrizione = () => {
        return (
            <MDBox display='flex' flexDirection='column' alignItems='flex-end' height='100%' justifyContent='center' minHeight='150px'>
                <MDBox mb={1} lineHeight={0} display='flex' flexDirection='row'>
                    <MDTypography variant='caption' color='text'>
                        Paziente :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                              {`${props.paziente.cognome} ${props.paziente.nome}`} 
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display='flex' flexDirection='row'>
                    <MDTypography variant='caption' color='text'>
                        Data :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                              {convertiDataInStringa(new Date())} 
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display='flex' flexDirection='row'>
                    <MDTypography variant='caption' color='text'>
                        Disfunzione :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                              {props.lesione.titolo} 
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display='flex' flexDirection='row'>
                    <MDTypography variant='caption' color='text'>
                        Data creazione disfunzione:
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                              {props.lesione.data} 
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0} display='flex' flexDirection='row'>
                    <MDTypography variant='caption' color='text'>
                        Fisioterapista :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                              {props.lesione.dottore} 
                        </MDTypography>
                    </MDTypography>
                </MDBox>
            </MDBox>
        )
    }

    const renderElementiSeduta = (seduta) => {
        const elementi = getElementiSedutaOrdinatiDaId(seduta.id)
        if (elementi.length === 0) {
            return <MDTypography color='text'>Nessun elemento inserito</MDTypography>
        }
        return elementi.map((elemento) => {
            return (
                <div key={elemento.id} className='elemento-seduta-pdf'>
                    {renderElemento(elemento)}
                </div>
            )
        })
    }

    const renderSeduta = (seduta) => {
        return (
            <MDBox key={seduta.id}>
                <MDTypography fontWeight='medium' color='dark' variant='h4'>
                    Seduta del : {seduta.data} svolta da {seduta.dottore}
                </MDTypography>

                {renderElementiSeduta(seduta)}
            </MDBox>
        )
    }

    const renderBottoni = () => {
        if (!props.aperto) {
            return ''
        }
        return (
            <MDBox className='chiudi-pop-up'>
                <MDButton variant='contained' color='primary' onClick={() => stampa()}>
                    pdf
                    <Icon sx={{ ml: 1 }}>download</Icon>
                </MDButton>
                <MDButton variant='contained' color='error' onClick={() => handleClose()}>
                    annulla
                    <Icon sx={{ ml: 1 }}>close</Icon>
                </MDButton>
            </MDBox>
        )
    }

    let form = Object.assign(formInformazioniDisfunzioni())
    form.parts[0].details.nascondiCorpo = true

    if (props.aperto) {
        return (
            <React.Fragment>
                <Backdrop onClick={() => handleClose()} open={props.aperto} sx={{ cursor: 'pointer', color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} />
                <MDBox className={classi} opacity={1}>
                    <MDBox maxWidth='1000px' sx={{ margin: 'auto', display: 'block !important' }}>
                        <MDBox className='flex-responsivo' justifyContent='space-between' aligItems='center'>
                            <MDBox component='img' src={logo} height='auto' width='300px'></MDBox>
                            {renderDescrizione()}
                        </MDBox>
                        {/* <MDBox mt={3} display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                            
                            <MDTypography color={'white'} variant='h5'>
                                Anagrafica paziente
                            </MDTypography>
                        </MDBox>
                        <VediForm skeleton={props.skeleton} form={formUtente('profilo-paziente')} dati={{}} noBottoni={true} />
                        <MDBox mt={3} display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                            
                            <MDTypography color={'white'} variant='h5'>
                                Storia clinica paziente
                            </MDTypography>
                        </MDBox>
                        <VediForm skeleton={props.skeleton} form={formStoriaClinicaPaziente()} dati={{}} noBottoni={true} />
                        <MDBox mt={3} display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                            
                            <MDTypography color={'white'} variant='h5'>
                                Abitudini paziente
                            </MDTypography>
                        </MDBox>
                        <VediForm skeleton={props.skeleton} form={formAbitudiniPaziente()} dati={{}} noBottoni={true} />
                        <MDBox mt={3} display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                            
                            <MDTypography color={'white'} variant='h5'>
                                Dati generali disfunzione
                            </MDTypography>
                        </MDBox> */}
                        <VediForm skeleton={props.skeleton} form={form} dati={props.informazioniDisfunzione} noBottoni={true} />
                        {props.sedute.map((seduta) => {
                            return renderSeduta(seduta)
                        })}
                    </MDBox>

                    {renderBottoni()}
                </MDBox>
            </React.Fragment>
        )
    }

    return ''
}

function mapStateToProps(state) {
    return {
        lesione: getLesioneSelezionata(),
        paziente: getPaziente(),
        informazioniDisfunzione: getInformazioniLesione(),
        sedute: getListaSedute(),
    }
}

export default connect(mapStateToProps, null)(popUpPdfDisfunzione)
