export const formCredenzialiPaziente = () => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'Email:',
                value: '',
                details: {
                    type: 'text',
                    grid : {lg : {input : 4, output : 4}}
                },
                validazione: 'emailGiaPresente',
            },
            {
                id: 'password',
                tipo: 'fieldText',
                associazione: 'password',
                label: 'Password:',
                value: '',
                details: {
                    type: 'password',
                    grid : {lg : {input : 4, output : 4}}
                },
                validazione: 'lunghezzaMinima',
            },
        ],
    }
}
