import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import DialogActions from '@mui/material/DialogActions'
import ElementoGeneraPdf from 'componenti/elementoGeneraPdf'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpFirmaDocumento(props) {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }
    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)
    const [tipoPdf, setTipoPdf] = useState('')

    const handleClose = () => {
        props.chiudi()
    }

    useEffect(()=>{
        setTipoPdf(props.parametro.isStrategia ? 'moduloConsensoInformato' : 'privacy')
    },[props.parametro])

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
            //window.innerWidth < breakpoints.values.sm ? setOpzioni(opzioniMobile) : setOpzioni(opzioniStandard)
        })
    }, [])

    const renderTitolo = props.parametro.isStrategia ? 'Firma consenso informato' : 'Firma privacy'

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>{renderTitolo}</DialogTitle>
            <DialogContent dividers={true}>
                <ElementoGeneraPdf isStrategia={props.parametro.isStrategia} strategia={props.parametro.strategia} tipoPdf={tipoPdf} annulla={() => handleClose()} />
            </DialogContent>
        </Dialog>
    )
}
