import { Grid, Divider } from '@mui/material'
import ElementoFattureDipendente from 'componenti/elementoFattureDipendente'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getInformazioniAbbonamenti } from 'getter/getterDipendenti'
import { eventiCache } from 'mixing/eventiCache'
import React, { Component } from 'react'

export default class paginaInfoAbbonamentiClinica extends eventiCache(Component) {
    constructor(props) {
        super(props)
        this.state.dottori = 0
        this.state.segreterie = 0
        this.state.spesaTotale = 0
        this.state.abbonamentiAttivi = 0
        this.state.rinnoviSospesi = 0
    }

    dipendentiAggiornati() {
        this.aggiorna()
    }

    componentDidMount() {
        super.componentDidMount()
        this.aggiorna()
    }

    aggiorna() {
        const dati = getInformazioniAbbonamenti()
        this.setState({
            dottori: dati.dottori,
            segreterie: dati.segreterie,
            spesaTotale: dati.totale,
            abbonamentiAttivi: dati.abbonamentiAttivi,
            rinnoviSospesi: dati.rinnoviSospesi,
        })
    }

    renderRiga(primaParte, secondaParte) {
        return (
            <React.Fragment>
                <Grid item container direction='row' justifyContent='space-between'>
                    <MDTypography variant="subtitle2" color='dark'>
                        {primaParte}
                    </MDTypography>
                    <MDTypography variant="subtitle2" fontWeight='medium' color='dark'>
                        {secondaParte}
                    </MDTypography>
                </Grid>
                <Divider />
            </React.Fragment>
        )
    }

    render() {
        return (
            <Grid container spacing={3}>
                <Grid container item xs={12} lg={8}>
                    <MDBox width='100%' height='100%' bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                        {this.renderRiga('Numero dipendenti : ', this.state.dottori + this.state.segreterie)}
                        {this.renderRiga('Numero dottori : ', this.state.dottori)}
                        {this.renderRiga('Numero segreterie : ', this.state.segreterie)}
                        {this.renderRiga('Numero abbonamenti attivi : ', this.state.abbonamentiAttivi)}
                        {this.renderRiga('Numero rinnovi disabilitati : ', this.state.rinnoviSospesi)}
                        {this.renderRiga('Numero pazienti clinica : ', this.state.listaPazientiWindow.length)}
                        {this.renderRiga('Guadagno totale : ', this.state.spesaTotale)}
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ElementoFattureDipendente />
                </Grid>
            </Grid>
        )
    }
}
