import { listaTipoSpesa } from 'data/configurazioneVoceFattura'

const subInputExtra = {
    costumCssInput: ['inColonna'],
    parts: [
        {
            id: 'marca',
            tipo: 'fieldBoolean',
            associazione: 'marca',
            label: 'Marca da bollo',
            value: false,
        },
        {
            id: 'forfetario',
            tipo: 'fieldBoolean',
            associazione: 'forfetario',
            label: 'Regime forfetario',
            value: false,
        },

        /* {
            id: 'pagamentoTracciato',
            tipo: 'fieldBoolean',
            associazione: 'pagamentoTracciato',
            label: 'Pagamento tracciato',
            value: false,
            details: {
                costumCss: ['lineaSeServe'],
            },
        }, */
        {
            id: 'flagOpposizione',
            tipo: 'fieldBoolean',
            associazione: 'flagOpposizione',
            label: 'opposizione',
            value: false,
        },
        {
            id: 'tipoDocumento',
            tipo: 'fieldSelect',
            associazione: 'tipoDocumento',
            label: 'Tipo documento',
            value: '',
            details: {
                scelte: [
                    { id: 'F', testo: 'Fattura' },
                    { id: 'D', testo: 'Documento fiscale' },
                ],
                complex: true,
                toShow: 'testo',
                toSave: 'id',
            },
        },
        {
            id: 'dispositivo',
            tipo: 'fieldText',
            associazione: 'dispositivo',
            label: 'Dispositivo :',
            value: 1,
            details: {
                type: 'number',
            },
        },
        /* {
            id: 'defaultPagamento',
            tipo: 'fieldSelect',
            associazione: 'defaultPagamento',
            label: 'Data pagamento :',
            value: '',
            details: {
                daVedere: ['testo'],
                daTornare: ['data'],
                lista: [
                    { data: 0, testo: 'Data emissione' },
                    { data: 3, testo: '3 giorni' },
                    { data: 7, testo: 'Una settimana' },
                    { data: 15, testo: '15 giorni' },
                    { data: 30, testo: 'un mese' },
                ],
                costumCssOutput: ['labelBold'],
            },
        }, */
        /* {
            id: 'pagamento',
            tipo: 'fieldDataPagamentoFatture',
            associazione: 'pagamento',
            label: 'Pagamento :',
            value: {},
            details: {
                costumCss: ['lineaSeServe'],
            },
        }, */
    ],
}
const subInputVoci = {
    costumCssInput: ['inColonna'],
    parts: [
        /*  {
            id: 'naturaIva',
            tipo: 'fieldSelect',
            associazione: 'naturaIva',
            label: 'Natura iva :',
            value: '',
            details: {
                lista: listaNaturaIva,
                dueRighe: true,
                daVedere: ['testo'],
                daTornare: ['id'],
                costumCssOutput: ['labelBold'],
                nascondiLabel: true,
            },
        },
        {
            id: 'aliquotaIva',
            tipo: 'fieldText',
            associazione: 'aliquotaIva',
            label: 'Aliquota iva :',
            value: 0,
            details: {
                type : 'number',
                costumCssOutput: ['labelBold'],
                nascondiLabel: true,
            },
        }, */
        {
            id: 'iva',
            tipo: 'fieldIvaVoceFattura',
            associazione: 'iva',
            label: 'Iva',
            value: {},
        },
        {
            id: 'tipoSpesa',
            tipo: 'fieldSelect',
            associazione: 'tipoSpesa',
            label: 'Tipo spesa :',
            value: '',
            details: {
                scelte: listaTipoSpesa,
                toShow: 'testo',
                toSave: 'id',
                complex: true,
            },
        },
    ],
}

export const formDefaultFatture = () => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'defaultVoci',
                tipo: 'fieldSubInput',
                associazione: 'defaultVoci',
                label: 'Voci:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputVoci,
                },
            },
            {
                id: 'extra',
                tipo: 'fieldSubInput',
                associazione: 'extra',
                label: 'Extra:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 } },
                    subModel: subInputExtra,
                },
            },
        ],
    }
}
