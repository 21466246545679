import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
import { doc, updateDoc } from 'firebase/firestore'

export const AGGIORNA_STATO_POP_UP_GUIDA = async (stato, daAprire) => {
    const nuovo = {
        stato: stato,
        daAprire: daAprire || false,
    }
    await updateDoc(doc(db, 'presidi', getPresidio(getVeroUtenteLoggato())), { popUpGuida: nuovo })
    return
}
