import React from 'react'
import { convertiDataInStringa } from 'data/strumenti'

export function renderModuloPrivacy(props) {
    return (
        <React.Fragment>
            <h2>Informativa trattamento dati personali</h2>
            {props.isTutore ? (
                <div>
                    Il sottoscritto{' '}
                    <b>
                        {props.datiTutore?.nomeTutore} {props.datiTutore?.cognomeTutore} (CF: {props.datiTutore?.cfTutore})
                    </b>
                    , tutore di{' '}
                    <b>
                        {props.datiPaziente?.nome} {props.datiPaziente?.cognome}
                    </b>{' '}
                    dichiara di essere stato preventivamente informato - ai sensi degli artt. 13 e 14 del Regolamento CE n. 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali acquisiti a trattati da <b>{props.presidio?.nome}</b> – che i dati personali da Lei forniti saranno oggetto di trattamento nel rispetto della normativa prevista dal suddetto Regolamento e che:
                </div>
            ) : (
                <div>
                    Il sottoscritto{' '}
                    <b>
                        {props.datiPaziente?.nome} {props.datiPaziente?.cognome}{' '}
                    </b>{' '}
                    dichiara di essere stato preventivamente informato - ai sensi degli artt. 13 e 14 del Regolamento CE n. 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali acquisiti a trattati da <b>{props.presidio?.nome}</b> – che i dati personali da Lei forniti saranno oggetto di trattamento nel rispetto della normativa prevista dal suddetto Regolamento e che:
                </div>
            )}
            <br />
            <ul>
                <li>
                    <b>Finalità del trattamento</b> – Il trattamento dei dati è finalizzato unicamente al corretto svolgimento delle attività necessarie per la prevenzione, la diagnosi, la cura, la riabilitazione o per le altre prestazioni da voi richieste, farmaceutiche e specialistiche.
                </li>
                <li>
                    <b>Modalità del trattamento dei dati personali</b> – Il trattamento è realizzato attraverso operazioni, effettuate con o senza l’ausilio di strumenti elettronici e consiste nella raccolta, registrazione, organizzazione, conservazione, consultazione, elaborazione, modificazione, selezione, estrazione, raffronto, utilizzo, interconnessione, blocco, comunicazione, cancellazione e distruzione di tali dati. Il trattamento è svolto dal titolare e dagli incaricati espressamente autorizzati dal titolare.
                </li>
                <li>
                    <b>Conferimento dei dati e rifiuto</b> – Il conferimento dei dati personali comuni, sensibili, genetici, relativi alla salute è necessario ai fini dello svolgimento delle attività necessarie per la prevenzione, la diagnosi, la cura, la riabilitazione o per le altre prestazioni da voi richieste, farmaceutiche e specialistiche. Il rifiuto dell’interessato di conferire i dati personali comporta l’impossibilità di svolgere la prestazione medica richiesta.
                </li>
                <li>
                    <b>Comunicazione dei dati</b> – I dati personali raccolti non saranno oggetto di diffusione salvo per le finalità previste dalla legge ed esclusivamente per le finalità del trattamento. I dati sensibili, genetici e quelli relativi alla salute saranno conosciuti solo ed esclusivamente da parte di personale sanitario della struttura.
                </li>
                <li>
                    <b>Conservazione dei dati</b> – I dati personali raccolti dalla <b>{props.presidio?.nome}</b> saranno conservati per il tempo strettamente necessario all’attività medica e, in ogni caso, per un periodo non superiore a dieci anni, sulla piattaforma Pyisio archive
                </li>
            </ul>
            <br />
            <div>
                Titolare del trattamento è: <b>{props.presidio?.nome}</b>
            </div>
            <div>Presto il pieno consenso al trattamento dei dati personali.</div>
            <br />
            <h4>Diritti dell’interessato.</h4>
            <div>In ottemperanza a quanto previsto dal Regolamento CEE 2016/679 Lei ha diritto di:</div>
            <ul>
                <li>chiedere al titolare del trattamento l&#39;accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati;</li>
                <li>ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti a un titolare del trattamento e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del trattamento cui li ha forniti;</li>
                <li>revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca;</li>
                <li>Proporre reclamo all’Autorità Garante.</li>
            </ul>
            <br />
            <div>
                I suddetti diritti possono essere esercitati altresì mediante comunicazione scritta da inoltrare al seguente indirizzo e-mail
                <b>{props.presidio?.mail}</b> ovvero tramite lettera raccomandata indirizzata al Titolare del Trattamento.
            </div>
            <div>
                <b>{props.presidio?.comune}</b> , il <b>{convertiDataInStringa(props.data)}</b>
            </div>
            <br />
        </React.Fragment>
    )
}
export function renderModuloConsensoinformato(props) {
    return (
        <React.Fragment>
            <h2>Consenso informato all'esecuzione di trattamenti fisioterapici</h2>
            {props.isTutore ? (
                <div>
                    Il sottoscritto{' '}
                    <b>
                        {props.datiTutore?.nomeTutore} {props.datiTutore?.cognomeTutore} (CF: {props.datiTutore?.cfTutore})
                    </b>
                    , tutore di{' '}
                    <b>
                        {props.datiPaziente?.nome} {props.datiPaziente?.cognome}
                    </b>
                    , nato a <b>{props.datiPaziente?.luogoNascita}</b> il <b>{props.datiPaziente?.dataNascita}</b> , residente in <b>{props.datiPaziente?.indirizzo}</b>, <b>{props.datiPaziente?.cap}</b> <b>{props.datiPaziente?.comune}</b>, <b>{props.datiPaziente?.provincia}</b>
                </div>
            ) : (
                <div>
                    Il sottoscritto{' '}
                    <b>
                        {props.datiPaziente?.nome} {props.datiPaziente?.cognome}{' '}
                    </b>{' '}
                    dichiara di essere stato preventivamente informato - ai sensi degli artt. 13 e 14 del Regolamento CE n. 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali acquisiti a trattati da <b>{props.presidio?.nome}</b> – che i dati personali da Lei forniti saranno oggetto di trattamento nel rispetto della normativa prevista dal suddetto Regolamento e che:
                </div>
            )}
            <br />
            <div>In questo modulo vengono riassunti i concetti a Lei già oralmente espressi nel corso della visita, precisandoli e definendoli nelle loro linee essenziali, in modo da avere anche il Suo assenso all'esecuzione delle terapie prescritte e concordate.</div>
            <br />
            <div>Descrizione dell'intervento</div>
            <b>{props.strategia?.descrizioneIntervento}</b>
            <br />
            <br />
            <div>Rischi assoluti</div>
            <div>
                Le terapie Fisiche Strumentali prevedono una Controindicazione Assoluta al trattamento per: <b>{props.strategia?.rischiAssoluti}</b>
            </div>
            <br />
            <div>In ragione di quanto precede ed al fne di evidenziare eventuali controindicazioni relative o assolute al trattamento fisioterapico, il paziente si obbliga ad informare preventivamente il professionista di qualsiasi stato morboso e/ o patologia acuta o cronica di cui è affetto, impegnandosi a rimettergli la documentazione comprovante le proprie condizioni di salute.</div>
            <br />
            <div>Rischi generici</div>
            <div>
                <b> {props.strategia?.sintomi} </b>
            </div>
            <br />
            <div>Rischi del mancato trattamento Il mancato trattamento può determinare un peggioramento della sintomatologia o della funzionalità lesa e/ o l'instaurarsi di patologie croniche.</div>
            <br />
            <div>Altre informazioni su richiesta del paziente:</div>
            <div>
                <b> {props.strategia?.testo} </b>
            </div>
            <br />
            <div>
                Io sottoscritto{' '}
                <b>
                    {props.datiPaziente?.nome} {props.datiPaziente?.cognome}
                </b>{' '}
                dichiaro di essere stato informato e di aver compreso lo scopo e la natura dell'intervento di riabilitazione indicato nel modulo in oggetto e di aver altresì consapevolezza dei rischi e delle complicazioni che potrebbero derivare o essere connesse al predetto intervento. Sono inoltre consapevole che senza la mia disponibilità nel seguire alcune regole comportamentali ed esercizi indicatemi dal fsioterapista, la terapia non può ritenersi stabile a lungo termine e prendo atto che non vi possano essere garanzie di successo senza la mia collaborazione. Presto pertanto il mio assenso all'intervento ed alle terapie che mi sono state illustrate e spiegate, sottoponendomi al trattamento riabilitativo per il periodo di tempo necessario allo svolgimento del programma terapeutico
                propostomi.
            </div>
            <div>
                <b>{props.presidio?.comune}</b> , il <b>{convertiDataInStringa(props.data)}</b>
            </div>
            <br />
        </React.Fragment>
    )
}
