import React, { useState } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import ElementoRiautenticazione from './elementoRiautenticazione'
import ElementoaAggiornaCredenziali from './elementoaAggiornaCredenziali'
import MDBox from 'components/MDBox'

export default function ElementoCambiaCredenziali(props) {
    let steps = ['Riautenticazione', `Modifica ${props.tipo === 'mail' ? "mail" : "password"}`]
    const [step, setStep] = useState(0)

    function reset() {
        setStep(0)
    }

    function cosaFare() {
        if (step === steps.length - 1) {
            reset()
        }
        setStep(step + 1)
    }

    let getStepContent = () => {
        switch (step) {
            case 1:
                return <ElementoaAggiornaCredenziali tipo={props.tipo} fine={() => reset()} />
            default:
                return <ElementoRiautenticazione avanti={() => setStep(step + 1)} />
        }
    }

    return (
        <React.Fragment>
            <MDBox mx={2} mt={-7}>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label,index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </MDBox>
            <MDBox mt={1}>
                <MDBox lineHeight={0} ml={3}>
                    <MDTypography color={'dark'} variant='h4'>
                        Modifica {props.tipo === 'mail' ? "mail" : "password"}
                    </MDTypography>
                </MDBox>
                {getStepContent()}
            </MDBox>

            {/* <div className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='primary' disabled={step === 0} onClick={() => setStep(step - 1)}>
                    indietro
                </MDButton>
                <MDButton variant='contained' color='primary' onClick={() => cosaFare()}>
                    {step === steps.length - 1 ? 'Fine' : 'avanti'}
                </MDButton>
            </div> */}
        </React.Fragment>
    )
}
