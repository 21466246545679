export const formRegistrazionePresidio = () => {
    return {
        costumCssInput: ['formCambiata'],
        parts: [
            {
                id: 'nome',
                tipo: 'fieldText',
                associazione: 'nome',
                label: 'Nome presidio:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'indirizzo',
                tipo: 'fieldText',
                associazione: 'indirizzo',
                label: 'Indirizzo:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'comune',
                tipo: 'fieldText',
                associazione: 'comune',
                label: 'Comune:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'CAP',
                tipo: 'fieldText',
                associazione: 'CAP',
                label: 'CAP:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'provincia',
                tipo: 'fieldText',
                associazione: 'provincia',
                label: 'Provincia:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'cf',
                tipo: 'fieldText',
                associazione: 'cf',
                label: 'Codice fiscale:',
                value: '',
                details: {
                    maiuscolo : true
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'Email:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'email',
            },
            {
                id: 'iban',
                tipo: 'fieldText',
                associazione: 'iban',
                label: 'IBAN:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'partiaIva',
                tipo: 'fieldText',
                associazione: 'partiaIva',
                label: 'Partita IVA:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'telefonoCellulare',
                tipo: 'fieldText',
                associazione: 'telefonoCellulare',
                label: 'Telefono cellulare:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'telefonoFisso',
                tipo: 'fieldText',
                associazione: 'telefonoFisso',
                label: 'Telefono fisso:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'web',
                tipo: 'fieldText',
                associazione: 'web',
                label: 'WEB:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                },
            },
            {
                id: 'accettazioneTerminiECondizioni',
                tipo: 'fieldAccettaTerminiECondizioni',
                associazione: 'accettazioneTerminiECondizioni',
                label: 'Accetto termini e condizioni:',
                value: '',
                details: {
                    testoLink: 'scopri di piu',
                    link: 'terminiECondizioni.html',
                    costumCssOutput: ['labelBold'],
                },
                validazione: 'controllaSeVero',
            },
        ],
    }
}
