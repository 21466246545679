import React, { useState } from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import FormControl from '@mui/material/FormControl'
import colors from 'assets/theme/base/colors'
import { OutputText } from './fieldText'

const elementoDaSalvare = (elemento, props) => {
    if (props.model.details.complex && props.model.details.toSave !== undefined) {
        return elemento[props.model.details.toSave]
    }
    return elemento
}
const elementoDaVedere = (elemento, props) => {
    if (props.model.details.complex) {
        return elemento[props.model.details.toShow]
    }
    return elemento
}

export function InputSceltaMultipla(props) {
    const [focus, setFocus] = useState(false)
    const getColore = () => {
        if (focus) {
            return colors.primary.main
        }
        return props.errore !== '' ? 'error' : '#d2d6da'
    }

    const aggiorna = (selezionato, index) => {
        let nuovaLista = [...props.value]
        if (selezionato) {
            nuovaLista.push(elementoDaSalvare(props.model.details.scelte[index], props))
        }
        if (!selezionato) {
            let indiceProps = props.value.findIndex((el) => {
                return elementoDaSalvare(props.model.details.scelte[index], props) === el
            })
            nuovaLista.splice(indiceProps, 1)
        }
        props.azione(props.model.id, nuovaLista)
    }

    

    return (
        <MDBox sx={{ border: 'solid 0.1px' }} color={getColore()} p={2} borderRadius='lg' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
            <MDBox mt={-5.3} bgColor='white' p={0.5} width={'max-content'}>
                <MDTypography gutterBottom color={getColore()} variant='caption'>
                    {props.model.label}
                </MDTypography>
            </MDBox>
            <FormControl error={props.errore !== ''} fullWidth>
                {props.model.details.scelte.map((scelta, index) => {
                    return <FormControlLabel control={<Checkbox color='primary' checked={props.value.includes(elementoDaSalvare(scelta, props))} onChange={(e, valore) => aggiorna(valore, index)} />} label={elementoDaVedere(scelta, props)} />
                })}
            </FormControl>
            <MDTypography color='error' variant='caption'>
                {props.errore}
            </MDTypography>
        </MDBox>
    )
}
export function OutputSceltaMultipla(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    const getDaVedere = (elemento) => {
        let index = props.model.details.scelte.findIndex((el) => {
            return elementoDaSalvare(el, props) === elemento
        })
        if (index === -1) {
            return elemento
        }
        return elementoDaVedere(props.model.details.scelte[index], props)
    }

    let valore = props.value.reduce((acc, el, index) => {
        let daVedere = getDaVedere(el)
        if (index === props.value.length - 1) {
            acc += daVedere
        }
        if (index !== props.value.length - 1) {
            acc += `${daVedere}, `
        }
        return acc
    }, '')

    return <OutputText skeleton={props.skeleton || false} model={model} value={valore} />
}
