const initialState = {}

export const reducerFormDaCompilarePaziente = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_FORM_DA_COMPILARE_PAZIENTE':
            var lista = [...action.payload]
            var formTest = []
            var altriForm = []
            lista.forEach((elemento) => {
                switch (elemento.contenuto.tipo) {
                    case 'test':
                        formTest.push(elemento)
                        break
                    default:
                        altriForm.push(elemento)
                        break
                }
            })
            return { formTest, altriForm }
        /* case "CAMBIA_URL":
           break */
        default:
            return state
    }
}
