import React, { useEffect, useState } from 'react'
import PhotoSizeSelectActualRoundedIcon from '@mui/icons-material/PhotoSizeSelectActualRounded'
import MDBox from 'components/MDBox'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { ref, getDownloadURL } from 'firebase/storage'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded'

export default function elementoMostraImmagine(props) {
    const [url, setUrl] = useState('')
    const [tipo, setTipo] = useState('image')

    const getTipo = () => {
        return props.immagine?.contenuto?.tipo || 'image'
    }

    const getUrl = async () => {
        setUrl('')
        const tipoFile = getTipo()
        setTipo(tipoFile)

        var path = `immagini/${props.immagine.id}/thumbNail.png`

        if (tipoFile === 'pdf') {
            path = `immagini/${props.immagine.id}/${props.immagine.contenuto.nome}`
        }
        if (props.immagine.contenuto.nome === undefined) {
            setUrl(props.immagine.contenuto.urlThumbNail)
        }
        let nuovoUrl = ''
        try {
            nuovoUrl = await getDownloadURL(ref(storage, path))
        } catch (error) {
            nuovoUrl = await getDownloadURL(ref(storage, `immagini/${props.immagine.id}/thumbNail.${props.immagine.contenuto.nome.split('.')[props.immagine.contenuto.nome.split('.').length - 1]}`))
        }
        setUrl(nuovoUrl)
    }

    useEffect(() => {
        getUrl()
    }, [props.immagine])

    const scarica = () => {
        window.open(url)
    }


    if (url === '') {
        return <PhotoSizeSelectActualRoundedIcon color='dark' />
    }

    

    

    if (tipo === 'pdf') {
        return (
            <Grid onClick={()=>scarica()} width='max-content' container direction='column' spacing={2} alignItems='center' justifyContent='center' sx={{ cursor: 'pointer' }} p={1} display='flex'>
                <Grid item>
                    <PictureAsPdfRoundedIcon color='dark' />
                </Grid>
                <Grid item>
                    <MDTypography color='text' variant='caption'>
                        {props.immagine.contenuto.nome}
                    </MDTypography>
                </Grid>
            </Grid>
        )
    }


    return <MDBox component='img' src={url} objectFit='cover' mr={2} mb={2} sx={{ cursor: 'pointer' }} onClick={() => APRI_POP_UP({ id: 'vedi-file', parametro: props.immagine })}></MDBox>
}
