import { eventiCache } from './../mixing/eventiCache'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DataTable from './../examples/Tables/DataTable'
import DefaultCell from './../layouts/ecommerce/products/product-page/components/DefaultCell'
import ProductCell from './../layouts/ecommerce/products/product-page/components/ProductCell'
import blackChair from 'assets/images/ecommerce/black-chair.jpeg'
import { getListaPazientiFiltrati } from 'getter/getterPaziente'
import CategoriesList from 'examples/Lists/CategoriesList'
import MDTypography from 'components/MDTypography'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import List from '@mui/material/List'
import ElementoMostraPaziente from './elementoMostraPaziente'
import { Divider } from '@mui/material'

export class ElementoMostraPazienti extends eventiCache(Component) {
    renderTabella() {
        return <DataTable table={this.getDati()} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} onChange={() => this.test()} />
    }

    render() {
        if (this.state.listaPazientiWindow.length === 0) {
            return <MDTypography color='text'>Nessun paziente registrato</MDTypography>
        }
        if (this.props.errore) {
            return <MDTypography color='error'>Nessun risultato</MDTypography>
        }
        return (
            <List>
                {this.props.lista.map((paziente, index) => {
                    return (
                        <React.Fragment key={paziente.id}>
                            <ElementoMostraPaziente paziente={paziente} navigate={this.props.navigate} azione={this.props.azione} />
                            {index !== this.props.lista.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </List>
        )
    }

    rendera() {
        const dati = this.props.lista.map((paziente) => {
            return {
                color: 'dark',
                icon: <AccountCircleIcon />,
                name: paziente.contenuto.cognome,
                description: (
                    <MDTypography variant='caption' color='text' fontWeight='medium'>
                        {`${paziente.contenuto.nome}  ${paziente.contenuto.anno}`}
                    </MDTypography>
                ),
                route: `/paginaVediPaziente/${paziente.id}/anagraficaPaziente`,
            }
        })
        return <CategoriesList title='Pazienti' categories={dati} />
    }

    test(id) {
        this.props.navigate(`/paginaVediPaziente/${id}/anagraficaPaziente`)
    }

    getDati() {
        return {
            columns: [
                { Header: 'Nome', accessor: 'Nome', width: '40%' },
                { Header: 'Cognome', accessor: 'Cognome', width: '40%' },
                { Header: 'Anno', accessor: 'Anno', align: 'center', width: '20%' },
                { Header: 'Anno2', accessor: 'Anno2', align: 'center', width: '20%' },
                { Header: 'An3o', accessor: 'Ann3', align: 'center', width: '20%' },
                { Header: 'Ann4o', accessor: 'Anno4', align: 'center', width: '20%' },
            ],
            rows: this.props.lista.map((paziente) => {
                return {
                    id: paziente.id,
                    azione: (e) => this.test(e),
                    Nome: <ProductCell image={blackChair} name={paziente.contenuto.nome}></ProductCell>,
                    Cognome: <DefaultCell>{paziente.contenuto.cognome}</DefaultCell>,
                    Anno: <DefaultCell>{paziente.contenuto.anno}</DefaultCell>,
                }
            }),
        }
    }
}

function stateChanged(state, ownProps) {
    let dati = getListaPazientiFiltrati(ownProps.noCache)
    let lista = dati.lista
    if (ownProps.lista !== undefined) {
        lista = ownProps.lista
    }
    return {
        lista: lista,
        errore: dati.errore,
    }
}

const mapDispatchToProps = {}

export default connect(stateChanged, mapDispatchToProps)(ElementoMostraPazienti)
