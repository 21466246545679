//Puo tenere conto di dati dentro il database

import { listaNaturaIva } from 'data/configurazioneVoceFattura'
import { store } from 'data/store'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'

/* import { store } from '../mathilda/data/store' */

/**
 *
 * @param {String} dato [e' il valore che ci arriva dal form in cui inseriamo
 * il tipo di validazione: testo richiesto]
 */
export const testoRichiesto = (dato) => {
    const discriminante = dato !== '' && dato !== undefined

    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci il dato')
        }
    })
}

export const formattazioneCf = (dato) => {
    return new Promise((resolve, reject) => {
        if (dato.length === 0) {
            reject('Campo obbligatorio')
        }
        if (dato.length !== 16) {
            reject('Lunghezza cf errata')
        }
        const lettere = [0, 1, 2, 3, 4, 5, 8, 11, 15]
        const numeri = [6, 7, 9, 10, 12, 13, 14]
        const checkLettere = lettere.map((posizione) => {
            const lettera = dato[posizione]
            return /^[a-zA-Z]+$/.test(lettera)
        })
        if (checkLettere.includes(false)) {
            reject('Formattazione cf errata')
        }
        const checkNumeri = numeri.map((posizione) => {
            const numero = dato[posizione]
            return !isNaN(numero)
        })
        if (checkNumeri.includes(false)) {
            reject('Formattazione cf errata')
        }
        resolve('tutto ok')
    })
}

export const numeroMaggioreDi0 = (dato) => {
    const discriminante = typeof dato === 'number' && dato > 0

    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Il dato deve essere maggiore di 0')
        }
    })
}
export const testoRichiestoSelect = (dato, model) => {
    return new Promise((resolve, reject) => {
        let scelte = model.details.scelte

        if (model.details.complex) {
            scelte = model.details.scelte.map((scelta) => {
                return scelta[model.details.toSave]
            })
        }
        const discriminante = scelte.includes(dato)

        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci il dato')
        }
    })
}
export const listaMaggioreDi0 = (dato, model) => {
    return new Promise((resolve, reject) => {
        const discriminante = dato.length > 0

        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci almeno un dato')
        }
    })
}
export const validazioneIvaFattura = (dato, model) => {
    return new Promise((resolve, reject) => {
        let discriminante = false

        if (dato.tipo === 'aliquotaIVA') {
            discriminante = dato.valore !== undefined && dato.valore !== '' && dato.valore > 0
        }
        if (dato.tipo !== 'aliquotaIVA') {
            let scelte = listaNaturaIva.map((scelta) => {
                return scelta.id
            })
            discriminante = scelte.includes(dato.valore)
        }

        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Dato non valido')
        }
    })
}
export const validazioneProprietarioUtenzaTS = (dato, model) => {
    return new Promise((resolve, reject) => {
        let discriminanteCf = dato.cfProprietario !== '' && dato.cfProprietario !== undefined
        let discriminanteCodiceRegione = true
        let discriminanteCodiceAsl = true
        let discriminanteeModelSSa = true
        if (dato.tipo === 'struttura') {
            discriminanteCodiceRegione = dato.codiceRegione !== '' && dato.codiceRegione !== undefined
            discriminanteCodiceAsl = dato.codiceAsl !== '' && dato.codiceAsl !== undefined
            discriminanteeModelSSa = dato.codiceSSA !== '' && dato.codiceSSA !== undefined
        }

        if (discriminanteCf && discriminanteCodiceRegione && discriminanteCodiceAsl && discriminanteeModelSSa) {
            resolve('')
        } else {
            reject('campo richiesto')
        }
    })
}

export const validazioneNumeroDocumento = (dato) => {
    return new Promise((resolve, reject) => {
        let numero = Number(dato.split('-')[0])
        let anno = Number(dato.split('-')[1])

        const annoPiuUno = new Date().getFullYear() + 1
        const discriminanteNumero = typeof numero === 'number' && numero > 0
        const discriminanteAnno = typeof anno === 'number' && anno > 0 && anno <= annoPiuUno

        if (discriminanteAnno && discriminanteNumero) {
            resolve('tutto ok')
        }
        if (!discriminanteNumero) {
            reject('Campo obbligatorio, maggiore di 0')
        }
        if (!discriminanteAnno) {
            reject(`Campo obbligatorio(valore massimo : ${annoPiuUno})`)
        }
    })
}

export const testoRichiestoSceltaMultipla = (dato, model) => {
    return new Promise((resolve, reject) => {
        const discriminante = dato.length !== 0

        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci il dato')
        }
    })
}

/**
 *
 * @param {Boolean} dato [e' il valore che ci arriva dal form
 * il tipo di validazione: valore == true]
 */
export const controllaSeVero = (dato) => {
    const discriminante = dato

    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Il servizio viene erogato solo previa accettazione dei temrmini e delle condizioni ')
        }
    })
}

export const lunghezzaMinima = (dato) => {
    return new Promise((resolve, reject) => {
        if (dato.length >= 6) {
            resolve()
        } else {
            reject('La password deve contenere almeno 6 caratteri')
        }
    })
}

const formattazioneMail = (elem) => {
    return new Promise((resolve, reject) => {
        let re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        if (!re.test(elem)) {
            reject('Formattazione indirizzo e-mail errata')
        } else {
            resolve()
        }
    })
}

const controllaMailDatabase = (elem) => {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(getAuth(), elem, 'falsa')
            .then(() => {
                reject('mail gia utilizzata')
            })
            .catch((err) => {
                if (err.code === 'auth/user-not-found') {
                    resolve()
                } else {
                    reject('mail gia utilizzata')
                }
            })
    })
}

export const email = (dato) => {
    return new Promise((resolve, reject) => {
        testoRichiesto(dato)
            .then(() => {
                return formattazioneMail(dato)
            })
            .then(() => {
                resolve('tutto ok')
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const emailGiaPresente = (dato) => {
    return new Promise((resolve, reject) => {
        testoRichiesto(dato)
            .then(() => {
                return formattazioneMail(dato)
            })
            .then(() => {
                return controllaMailDatabase(dato)
            })
            .then(() => {
                resolve('tutto ok')
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const controlloMailUtente = async (dato, model) => {
    return new Promise((resolve, reject) => {
        emailGiaPresente(dato)
            .then(() => {
                resolve('tutto ok')
            })
            .catch((error) => {
                getDoc(doc(db, 'utenti', model.details.idUtente, 'datiLogIn', 'dati')).then((credenziali) => {
                    if (credenziali.data().email === dato) {
                        resolve('tutto ok')
                    } else {
                        reject(error)
                    }
                })
            })
    })
}

export const cambioMail = (dato) => {
    return new Promise((resolve, reject) => {
        let mailAttuale = store.getState().reducerCredenzialiChiosco.email
        if (dato === mailAttuale) {
            resolve()
        } else {
            return testoRichiesto(dato)
                .then(() => {
                    return formattazioneMail(dato)
                })
                .then(() => {
                    return controllaMailDatabase(dato)
                })
                .then(() => {
                    resolve('tutto ok')
                })
                .catch((err) => {
                    reject(err)
                })
        }
    })
}

export const selezionaData = (dato) => {
    const discriminante = dato !== 'NaN/NaN/NaN'
    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Seleziona una data')
        }
    })
}

export const selezionaDottore = (dato) => {
    return new Promise((resolve, reject) => {
        let lista = window.listaDipendenti.map((dip) => {
            return dip.id
        })
        if (lista.includes(dato)) {
            resolve()
        } else {
            reject('Selezionare un dottore')
        }
    })
}

let checkIvaEsiste = (dato) => {
    return new Promise((resolve, reject) => {
        process.env.CORS_ORIGIN_ALLOW_ALL = false
        process.env.CORS_ALLOW_CREDENTIALS = true
        process.env.CORS_ORIGIN_WHITELIST = 'http://localhost:8081'
        const data = null
        const xhr = new XMLHttpRequest()
        //xhr.withCredentials = true
        xhr.addEventListener('readystatechange', function () {
            if (this.readyState === this.DONE) {
                try {
                    let risposta = JSON.parse(this.responseText)
                    if (risposta.error === undefined || risposta.error === null) {
                        window.dispatchEvent(new CustomEvent('partitaIvaRiconosciuta', { detail: risposta }))
                        resolve()
                    } else {
                        reject('partita iva non riconosciuta')
                    }
                } catch (error) {
                    reject('partita iva non riconosciuta')
                }
            }
        })

        xhr.open('GET', `https://europeanvat.altravia.com/companies/IT/${dato}`)
        xhr.setRequestHeader('Authorization', 'Bearer 61b2172559a8007d015f8197')

        xhr.send(data)
    })
}

let checkIvaUsata = (dato) => {
    return new Promise((resolve, reject) => {
        getDoc(doc(db, 'cache', 'partiteIvaPresidi')).then((documento) => {
            let lista = Object.keys(documento.data())
            if (lista.includes(dato)) {
                reject('Codice già utilizzato')
            } else {
                resolve()
            }
        })
    })
}

export const controlloPartitaIva = (dato) => {
    return new Promise((resolve, reject) => {
        return (
            testoRichiesto(dato)
                .then(() => {
                    return checkIvaUsata(dato)
                })
                /* .then(() => {
                return checkIvaEsiste(dato)
            }) */
                .then(() => {
                    resolve('tutto ok')
                })
                .catch((err) => {
                    reject(err)
                })
        )

        /* const options = {
            method: 'GET',
            url: `https://europeanvat.altravia.com/companies/IT/${dato}`,
            headers: { Authorization: 'Bearer 61b2172559a8007d015f8197' },
        }
        request(options, function (error, response, body) {
            if (error) {
                reject('partita iva non riconosciuta')
            }
            console.log('ivaaa : ', iva)
            console.log('sono qua')
            console.log(typeof body)
            console.log(body)
            try {
                let risposta = JSON.parse(response.body)
                resolve()
            } catch (error) {
                reject('partita iva non riconosciuta')
            }
        }) */
    })
}
