import { getListaDipendenti } from 'getter/getterDipendenti'
import { eventiCache } from 'mixing/eventiCache'
import React, { Component } from 'react'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import Grid from '@mui/material/Grid'
import { Divider, List } from '@mui/material'
import ElementoMostraDipendente from 'componenti/elementoMostraDipendente'
import ElementoMostraChiosco from 'componenti/elementoMostraChiosco'
import MDButton from 'components/MDButton'
import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getTestiPaginaDipendenti } from 'getter/getterAppUtilizzata'

export default class paginaVediDipendenti extends eventiCache(Component) {
    constructor(props) {
        super(props)
        this.state.listaDottori = []
        this.state.listaSegreteria = []
        this.state.listaChioschi = []
    }

    dipendentiAggiornati() {
        this.aggiorna()
    }

    aggiorna() {
        const dipendenti = getListaDipendenti()
        this.setState({
            listaDottori: dipendenti.dottori,
            listaSegreteria: dipendenti.segreteria,
            listaChioschi: dipendenti.chioschi,
        })
    }

    componentDidUpdate() {
        const presidio = getPresidio(getVeroUtenteLoggato())
        if (presidio === undefined || presidio === '') {
            this.props.navigate('/paginaInserimentoDati')
        }
    }

    componentDidMount() {
        const presidio = getPresidio(getVeroUtenteLoggato())
        if (presidio === undefined || presidio === '') {
            this.props.navigate('/paginaInserimentoDati')
        }
        super.componentDidMount()
        this.aggiorna()
    }

    renderListaDottori() {
        if (this.state.listaDottori.length === 0) {
            return <MDTypography color='text'>Nessun fisioterapista inserito</MDTypography>
        }
        return (
            <List>
                {this.state.listaDottori.map((dottore, index) => {
                    return (
                        <React.Fragment key={dottore.id}>
                            <ElementoMostraDipendente dipendente={dottore} navigate={this.props.navigate} />
                            {index !== this.state.listaDottori.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </List>
        )
    }

    renderDottori() {
        const dati = getTestiPaginaDipendenti()
        return (
            <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <FormatListBulletedRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        {dati.label}
                    </MDTypography>
                </MDBox>
                {this.renderListaDottori()}
                <MDButton sx={{ marginTop: '20px' }} color='primary' variant='contained' onClick={() => this.props.navigate('paginaCreaDipendente/creaDottore')}>
                    {dati.bottone}
                </MDButton>
            </MDBox>
        )
    }

    renderListaSegreterie() {
        if (this.state.listaSegreteria.length === 0) {
            return <MDTypography color='text'>Nessuna segreteria inserita</MDTypography>
        }
        return (
            <List>
                {this.state.listaSegreteria.map((segreteria, index) => {
                    return (
                        <React.Fragment key={segreteria.id}>
                            <ElementoMostraDipendente dipendente={segreteria} navigate={this.props.navigate} />
                            {index !== this.state.listaSegreteria.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </List>
        )
    }

    renderSegreterie() {
        return (
            <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <FormatListBulletedRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        Lista Segreterie
                    </MDTypography>
                </MDBox>
                {this.renderListaSegreterie()}
                <MDButton sx={{ marginTop: '20px' }} color='primary' variant='contained' onClick={() => this.props.navigate('paginaCreaDipendente/creaSegreteria')}>
                    crea nuova segreteria
                </MDButton>
            </MDBox>
        )
    }
    renderListaChioschi() {
        return (
            <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <FormatListBulletedRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        Lista Chioschi
                    </MDTypography>
                </MDBox>
                <List>
                    {this.state.listaChioschi.map((chiosco, index) => {
                        return (
                            <React.Fragment key={chiosco.id}>
                                <ElementoMostraChiosco dipendente={chiosco} />
                                {index !== this.state.listaChioschi.length - 1 ? <Divider /> : ''}
                            </React.Fragment>
                        )
                    })}
                </List>
            </MDBox>
        )
    }

    render() {
        return (
            <Grid container direction='column' gap={3}>
                {this.renderDottori()}

                {this.renderSegreterie()}

                {this.renderListaChioschi()}
            </Grid>
        )
    }
}
