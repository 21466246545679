const initialState = {

};

export const reducerPresidio = (state = initialState, action) => {
      
    switch (action.type) {
        case 'LOG_OUT':
            return initialState;
        case 'LEGGI_PRESIDIO': 
            return action.payload
        /* case "CAMBIA_URL":
           break */
        default:
            return state;
    }
};
