import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import FitnessCenterRoundedIcon from '@mui/icons-material/FitnessCenterRounded'

export default function elementoMostraEsercizioLibreria(props) {
    const test = () => {
        if (props.azione !== undefined) {
            props.azione(props.esercizio)
            return
        }
        props.navigate(`/vediEsercizio/${props.esercizio.id}`)
    }

    return (
        <ListItem sx={{ cursor: 'pointer' }} onClick={() => test()}>
            <ListItemIcon>
                <FitnessCenterRoundedIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={props.esercizio.contenuto.titolo} />
            <ListItemIcon>
                <ArrowForwardIosRoundedIcon color='dark' />
            </ListItemIcon>
        </ListItem>
    )
}
