import React, { useEffect, useState } from 'react'
import IllustrationLayout from 'layouts/authentication/components/IllustrationLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import bgImage from 'assets/images/svgLogoPhysioBianco.svg'
import { Link } from 'react-router-dom'
import { InputText } from 'fields/fieldText'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export default function paginaPasswordDimenticata(props) {
    const [mail, setMail] = useState('')
    const [errore, setErrore] = useState('')
    const [successo, setSuccesso] = useState('')

    const form = {
        id: 'mail',
        tipo: 'fieldText',
        associazione: 'mail',
        label: 'Email',
        value: '',
        details: {
            id : 'mail',
            type: 'email',
        },
    }

    const inviaMail = () => {
        setErrore('')
        setSuccesso('')
        var auth = getAuth()
        if (mail === '' || mail === null || mail === undefined) {
            setErrore('Inserisci un email')
            return
        }
        sendPasswordResetEmail(auth,mail)
            .then(() => {
                setSuccesso('Email inviata')
            })
            .catch((error) => {
                if ((error = 400)) {
                    setErrore('Errore: Email non riconosciuta')
                }
            })
    }

    useEffect((e) => {
        setTimeout(() => {
            document.getElementById('mail').addEventListener('keypress', (e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                    document.getElementById('invia').click()
                }
            })
        }, 1000)
    }, [])
    return (
        <IllustrationLayout title='Password dimenticata' description='Inserisci la tua mail per reimpostare la password' illustration={bgImage}>
            <MDBox component='form' role='form'>
                <MDBox mb={2}>
                    <InputText model={form} value={mail} errore='' azione={(a, b) => setMail(b)} />
                </MDBox>

                <MDTypography variant='caption' color='error' fontWeight='bold'>
                    {errore}
                </MDTypography>
                <MDTypography variant='caption' color='success' fontWeight='bold'>
                    {successo}
                </MDTypography>
                <MDBox display='flex' alignItems='center' ml={-1}></MDBox>
                <MDBox mt={4} mb={1}>
                    <MDButton id="invia" color='primary' size='large' fullWidth onClick={() => inviaMail()}>
                        invia mail
                    </MDButton>
                    <MDButton color='error' size='large' fullWidth variant='text' onClick={() => props.navigate('/login')}>
                        annulla
                    </MDButton>
                </MDBox>
            </MDBox>
        </IllustrationLayout>
    )
}
