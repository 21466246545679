import { store } from './../store'

export const CHIUDI_POP_UP = (oggettoEvento) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'CHIUDI_POP_UP',
            payload: oggettoEvento,
        })
        resolve()
    })
}
