import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { LEGGI_FEEDBACK } from './../azioni/LEGGI_FEEDBACK'
import { collection, query, where, onSnapshot } from 'firebase/firestore'

export class watcherFeedback extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione() {
        let feedback = {}

        const q = query(collection(db, 'feedBack'), where('finito', '==', false))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                feedback[doc.id] = doc.data()
            })
            LEGGI_FEEDBACK(feedback)
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione();
        }
    }
}
