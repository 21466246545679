import React from 'react'
import { InputText, OutputText } from './fieldText'
import { InputSelect, OutputSelect } from './fieldSelect'
import { InputAutocomplete, OutputAutoComplete } from './fieldAutocomplete'
import { InputTextArea, OutputTextArea } from './fieldTextArea'
import { InputData, OutputData } from './fieldData'
import { InputSceltaSingola } from './fieldSceltaSingola'
import { InputListaSemplice, OutputListaSemplice } from './fieldListaSemplice'
import { InputSubInput, OutputSubInput } from './fieldSubInput'
import { InputSlider, OutputSlider } from './fieldSlider'
import { InputAccettaTerminiECondizioni, OutputAccettaTerminiECondizioni } from './fieldAccettaTerminiECondizioni'
import { InputList, OutputList } from './fieldList'
import { InputSceltaMultipla, OutputSceltaMultipla } from './fieldSceltaMultipla'
import { InputSceltaBandierina, OutputSceltaBandierina } from './fieldSceltaBandierina'
import { InputTags, OutputTags } from './fieldTags'
import { InputGestioneDolori, OutputGestioneDolori } from './fieldGestioneDolori'
import { InputVoceFattura, OutputvaVoceFattura } from './fieldIvaVoceFattura'
import { InputBoolean, OutputBoolean } from './fieldBoolean'
import { InputDataPagamentoFatture, OutputDataPagamentoFatture } from './fieldDataPagamentoFatture'
import { InputNumeroDocumento, OutputNumeroDocumento } from './fieldNumeroDocumento'
import { InputProprietarioUtenzaTs, OutputProprietarioUtenzaTs } from './fieldProprietarioUtenzaTs'
import OutputFarmaciStoriaClinica, { InputFarmaciStoriaClinica } from './fieldFarmaciStoriaClinica'

export default {
    fieldText: {
        input: (model, value, azione, errore) => {
            return <InputText key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputText skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSelect: {
        input: (model, value, azione, errore) => {
            return <InputSelect key={model.id} model={model} value={value === undefined ? '' : value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSelect skeleton={skeleton || false} key={model.id} model={model} value={value === undefined ? '' : value} />
        },
    },
    fieldAutocomplete: {
        input: (model, value, azione, errore) => {
            return <InputAutocomplete key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputAutoComplete skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldTextArea: {
        input: (model, value, azione, errore) => {
            return <InputTextArea key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputTextArea skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldData: {
        input: (model, value, azione, errore) => {
            return <InputData key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputData skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSceltaSingola: {
        input: (model, value, azione, errore) => {
            return <InputSceltaSingola key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
    },
    fieldSubInput: {
        input: (model, value, azione, errore) => {
            return <InputSubInput key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSubInput skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldListaSemplice: {
        input: (model, value, azione, errore,handlePending) => {
            return <InputListaSemplice key={model.id} model={model} value={value || ''} azione={azione} handlePending={handlePending} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputListaSemplice skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSlider: {
        input: (model, value, azione, errore) => {
            return <InputSlider key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSlider skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldAccettaTerminiECondizioni: {
        input: (model, value, azione, errore) => {
            return <InputAccettaTerminiECondizioni key={model.id} model={model} value={value === undefined ? false : value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputAccettaTerminiECondizioni skeleton={skeleton || false} key={model.id} model={model} value={value === undefined ? false : value} />
        },
    },
    fieldList: {
        input: (model, value, azione, errore) => {
            return <InputList key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputList skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSceltaMultipla: {
        input: (model, value, azione, errore) => {
            return <InputSceltaMultipla key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSceltaMultipla skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSceltaBandierina: {
        input: (model, value, azione, errore) => {
            return <InputSceltaBandierina key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSceltaBandierina skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldTags: {
        input: (model, value, azione, errore) => {
            return <InputTags key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputTags skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldGestioneDolori: {
        input: (model, value, azione, errore) => {
            return <InputGestioneDolori key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputGestioneDolori skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldIvaVoceFattura: {
        input: (model, value, azione, errore) => {
            return <InputVoceFattura key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputvaVoceFattura skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldBoolean: {
        input: (model, value, azione, errore) => {
            return <InputBoolean key={model.id} model={model} value={value === undefined ? false : value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputBoolean skeleton={skeleton || false} key={model.id} model={model} value={value === undefined ? false : value} />
        },
    },
    fieldDataPagamentoFatture: {
        input: (model, value, azione, errore) => {
            return <InputDataPagamentoFatture key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputDataPagamentoFatture skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldNumeroDocumento: {
        input: (model, value, azione, errore) => {
            return <InputNumeroDocumento key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputNumeroDocumento skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldProprietarioUtenzaTs: {
        input: (model, value, azione, errore) => {
            return <InputProprietarioUtenzaTs key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputProprietarioUtenzaTs skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldFarmaciStoriaClinica: {
        input: (model, value, azione, errore) => {
            return <InputFarmaciStoriaClinica key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputFarmaciStoriaClinica skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
}
