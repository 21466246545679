import { store } from 'data/store'
import { convertiStringaInData } from 'data/strumenti'
import { Timestamp } from 'firebase/firestore'
import { getIdPaziente } from 'getter/getterPaziente'
import { salvaListaEserciziScheda } from 'getter/getterSchede'
import { getSchedaDaId } from 'getter/getterSchede'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { SALVA_FILE_ESERCIZIO } from './SALVA_FILE_ESERCIZIO'

export const MODIFICA_SCHEDA = async (datiGenerali, datiScheda) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    const schedaDb = getSchedaDaId(store.getState().url[6])
    var scheda = {}
    var listaImmagini = []
    scheda.idScheda = schedaDb.id
    scheda.idPaziente = getIdPaziente()

    let dataInizio = typeof datiGenerali.inizio === "string" ? convertiStringaInData(datiGenerali.inizio) : datiGenerali.inizio
    let dataFine = typeof datiGenerali.fine === "string" ? convertiStringaInData(datiGenerali.fine) : datiGenerali.fine
    
    scheda.etichettaUniversale = schedaDb.etichettaUniversale
    scheda.tipo = schedaDb.tipo
    scheda.datiGenerali = Object.assign({}, datiGenerali)
    scheda.datiGenerali.inizio = Timestamp.fromDate(dataInizio)
    scheda.datiGenerali.fine = Timestamp.fromDate(dataFine)
    switch (schedaDb.tipo) {
        case 'fisioterapica':
            var dati = salvaListaEserciziScheda(datiScheda)
            scheda.esercizi = dati.esercizi
            listaImmagini = dati.listaImmagini
            break
        default:
            break
    }

    await CHIAMA_SERVER('modificaScheda', scheda)

    await Promise.all(
        listaImmagini.map((datiImmagine) => {
            return SALVA_FILE_ESERCIZIO(datiImmagine.file, datiImmagine.id)
        }),
    )

    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
//07GgDBbEdn1Kyq4fL7wR