import MDBox from 'components/MDBox'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { formUtente } from 'formModel/formUtente'
import { formStoriaClinicaPaziente } from 'formModel/formStoriaClinicaPaziente'
import { formAbitudiniPaziente } from 'formModel/formAbitudiniPaziente'
import VediForm from 'formMaker/vediForm'
import { CREA_PAZIENTE } from 'data/azioni/CREA_PAZIENTE'
import { newId } from 'data/strumenti'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { CHIAMA_SERVER } from 'data/azioni/CHIAMA_SERVER'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'

export const paginaCreaPaziente = (props) => {
    const [step, setStep] = useState(0)
    const [datiUtente, setDatiUtente] = useState({})
    const [credenziali, setCredenziali] = useState({})

    const test = () => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        CHIAMA_SERVER('generaLinkCreaPaziente', { id: newId(), idUtente: getIdUtenteLoggato(), idPresidio: getPresidio(getVeroUtenteLoggato()) })
    }

    const salva = async (e) => {
        let utente = Object.assign(datiUtente)
        if (step === 2) {
            utente = Object.assign(utente, e)
        }
        const id = newId()
        await CREA_PAZIENTE(utente, id, credenziali)
        setStep(0)
        setDatiUtente({})
        setCredenziali({})
        if (props.profilo !== 'profilo-chiosco') {
            props.navigate(`/paginaVediPaziente/${id}/dashboardPaziente`)
        } else {
            APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Profilo creato' } })
        }
    }

    const vaiAvanti = async (e) => {
        if (step === props.steps.length - 1) {
            await salva(e)
            return
        }
        if (step < 3) {
            const nuovoStato = Object.assign(datiUtente, e)
            setDatiUtente(nuovoStato)
        }
        setStep(step + 1)
    }

    const vaiIndietro = (e) => {
        if (step < 3) {
            const nuovoStato = Object.assign(datiUtente, e)
            setDatiUtente(nuovoStato)
        }
        setStep(step - 1)
    }

    const getInfoForm = () => {
        switch (step) {
            case 0:
                return { annulla: () => props.navigate(''), form: formUtente('profilo-paziente'), isPrimoStep: props.steps.length !== 1, salva: (e) => vaiAvanti(e) }
            case 1:
                return { annulla: () => props.navigate(''), form: formStoriaClinicaPaziente(), isStepCentrale: true, salva: (e) => vaiAvanti(e), indietro: (e) => vaiIndietro(e) }
            case 2:
                return { annulla: () => props.navigate(''), form: formAbitudiniPaziente(), isStepCentrale: props.steps.length > 3, isUltimoStep: props.steps.length === 3, salva: (e) => vaiAvanti(e), indietro: (e) => vaiIndietro(e) }

            default:
                break
        }
    }

    const renderStep = () => {
        switch (step) {
            default:
                return <VediForm soloInput={true} dati={datiUtente} vista='input' {...getInfoForm()} />
        }
    }

    const renderStepper = () => {
        if (props.steps.length === 1) {
            return ''
        }
        return (
            <MDBox mx={2} mt={-7}>
                <Stepper activeStep={step} alternativeLabel >
                    {props.steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </MDBox>
        )
    }

    return (
        <>
            {/* <button onClick={test}>prova</button> */}
            <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%', mt: 5 }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                {renderStepper()}
                <MDBox mt={3}>{renderStep()}</MDBox>
            </MDBox>
        </>
    )
}

const getSteps = (profilo) => {
    switch (profilo) {
        case 'profilo-chiosco':
            return ['Anagrafica', 'Storia clinica', 'Abitudini']
        //return ['Anagrafica', 'Storia clinica', 'Abitudini', 'credenziali', 'firma']
        case 'profilo-segreteria':
            return ['Anagrafica']
        case 'profilo-dottore':
            return ['Anagrafica', 'Storia clinica', 'Abitudini']

        default:
            break
    }
}

function mapStateToProps(state) {
    const profilo = getProfiloUtenteLoggato()
    return {
        steps: getSteps(profilo),
        profilo: profilo,
    }
}

export default connect(mapStateToProps, null)(paginaCreaPaziente)
