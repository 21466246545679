/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React examples
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse'
import SidenavList from 'examples/Sidenav/SidenavList'
import SidenavItem from 'examples/Sidenav/SidenavItem'

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot'
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav'
import { connect } from 'react-redux'

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav } from 'context'
import { APRI_POP_UP_MODIFICHE_NON_SALVATE } from 'data/azioni/APRI_POP_UP_MODIFICHE_NON_SALVATE'

function Sidenav({ modificheNonSalvate, color, brand, brandName, routes, ...rest }) {
    const [openCollapse, setOpenCollapse] = useState(false)
    const [openNestedCollapse, setOpenNestedCollapse] = useState(false)
    const [controller, dispatch] = useMaterialUIController()
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller
    const location = useLocation()
    const { pathname } = location
    const collapseName = pathname.split('/').slice(1)
    const items = pathname.split('/').slice(1)
    const itemParentName = items[1]
    const itemName = items[items.length - 1]

    let textColor = 'white'

    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = 'dark'
    } else if (whiteSidenav && darkMode) {
        textColor = 'inherit'
    }

    const closeSidenav = () => setMiniSidenav(dispatch, true)

    useEffect(() => {
        setOpenCollapse(collapseName)
        setOpenNestedCollapse(itemParentName)
    }, [])

    const cambiaPagina = (e, link, azione) => {
        if (azione !== undefined) {
            e.preventDefault()
            azione()
        }
        if (modificheNonSalvate) {
            APRI_POP_UP_MODIFICHE_NON_SALVATE(link)
            e.preventDefault()
        }
    }

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200)
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav)
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav)
        }

        /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
        window.addEventListener('resize', handleMiniSidenav)

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleMiniSidenav)
    }, [dispatch, location])

    // Render all the nested collapse items from the routes.js
    const renderNestedCollapse = (collapse) => {
        const template = collapse.map(({ name, route, key, href }) =>
            href ? (
                <Link key={key} href={href} target='_blank' rel='noreferrer' sx={{ textDecoration: 'none' }}>
                    <SidenavItem name={name} nested />
                </Link>
            ) : (
                <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
                    <SidenavItem name={name} active={route === pathname} nested />
                </NavLink>
            ),
        )

        return template
    }
    // Render the all the collpases from the routes.js
    const renderCollapse = (collapses) =>
        collapses.map(({ name, collapse, route, href, key, azione, error }) => {
            let returnValue

            if (collapse) {
                returnValue = (
                    <SidenavItem key={key} color={color} name={name} active={key === itemParentName ? 'isParent' : false} open={openNestedCollapse === key} onClick={({ currentTarget }) => (openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root') ? setOpenNestedCollapse(false) : setOpenNestedCollapse(key))}>
                        {renderNestedCollapse(collapse)}
                    </SidenavItem>
                )
            } else {
                returnValue = href ? (
                    <Link href={href} key={key} target='_blank' rel='noreferrer' sx={{ textDecoration: 'none' }}>
                        <SidenavItem color={color} name={name} active={key === itemName} />
                    </Link>
                ) : (
                    <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
                        <SidenavItem
                            color={color}
                            error={error}
                            name={name}
                            active={key === itemName}
                            onClick={(e) => {
                                cambiaPagina(e, route, azione)
                            }}
                        />
                    </NavLink>
                )
            }
            return <SidenavList key={key}>{returnValue}</SidenavList>
        })

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route, azione, error, parteUrl }) => {
        let returnValue

        if (type === 'collapse') {
            if (href) {
                returnValue = (
                    <Link href={href} key={key} target='_blank' rel='noreferrer' sx={{ textDecoration: 'none' }}>
                        <SidenavCollapse name={name} icon={icon} active={key === collapseName[parteUrl]} noCollapse={noCollapse} />
                    </Link>
                )
            } else if (noCollapse && route) {
                returnValue = (
                    <NavLink to={route} key={key}>
                        <SidenavCollapse
                            name={name}
                            icon={icon}
                            color={color}
                            noCollapse={noCollapse}
                            active={key === collapseName[parteUrl]}
                            error={error}
                            onClick={(e) => {
                                cambiaPagina(e, route, azione)
                            }}>
                            {collapse ? renderCollapse(collapse) : null}
                        </SidenavCollapse>
                    </NavLink>
                )
            } else {
                returnValue = (
                    <SidenavCollapse error={error} key={key} name={name} icon={icon} active={key === collapseName[parteUrl]} open={openCollapse === key} onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}>
                        {collapse ? renderCollapse(collapse) : null}
                    </SidenavCollapse>
                )
            }
        } else if (type === 'title') {
            returnValue = (
                <MDTypography key={key} color={textColor} display='block' variant='caption' fontWeight='bold' textTransform='uppercase' pl={3} mt={2} mb={1} ml={1}>
                    {title}
                </MDTypography>
            )
        } else if (type === 'divider') {
            returnValue = <Divider key={key} light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
        }

        return returnValue
    })

    return (
        <SidenavRoot /* {...rest} */ variant='permanent' ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}>
            <MDBox className='menu-app' pt={3} pb={1} px={4} textAlign='center'>
                <MDBox display={{ xs: 'block', xl: 'none' }} color='white' position='absolute' top={0} right={0} p={1.625} onClick={closeSidenav} sx={{ cursor: 'pointer' }}>
                    <MDTypography variant='h6' color='white'>
                        <Icon sx={{ fontWeight: 'bold' }} color='white'>
                            close
                        </Icon>
                    </MDTypography>
                </MDBox>
                <MDBox component={NavLink} to='/' display='flex' alignItems='center'>
                    {brand && <MDBox component='img' src={brand} alt='Brand' height='4rem' width='4rem' />}
                    <MDBox width={!brandName && '100%'} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
                        <MDTypography component='h6' variant='button' fontWeight='medium' color={textColor} mt={2}>
                            {brandName}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
            <Divider light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
            <MDBox sx={{paddingBottom : '60px'}}>
                <List >{renderRoutes}</List>
            </MDBox>
        </SidenavRoot>
    )
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: 'info',
    brand: '',
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

function mapStateToProps(state) {
    return {
        modificheNonSalvate: state.reducerStatoModifica.modificheNonSalvate,
    }
}

export default connect(mapStateToProps, null)(Sidenav)
