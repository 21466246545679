import React from 'react'
import { connect } from 'react-redux'
import ElementoNavigazioneDisfunzione from 'componenti/elementoNavigazioneDisfunzione'
import { getSedutaSelezionata } from 'getter/getterSedute'
import ElementoVediNota from 'componenti/elementoVediNota'
import ElementoVediDiagnosi from 'componenti/elementoVediDiagnosi'
import ElementoVediTestArticolare from 'componenti/elementoVediTestArticolare'
import ElementoVediBodyChart from 'componenti/elementoVediBodyChart'
import ElementoVediStrategia from 'componenti/elementoVediStrategia'
import { Grid } from '@mui/material'
import { getIdSedutaSelezionata } from 'getter/getterLesioni'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getRiassuntoSeduta } from 'getter/getterSedute'
import Icon from '@mui/material/Icon'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import ElementoDialer from 'componenti/elementoDialer'
import { ELIMINA_SEDUTA } from 'data/azioni/ELIMINA_SEDUTA'
import { getPermessiUtente } from 'getter/getterPermessi'
import { getElementiSedutaOrdinati } from 'getter/getterSedute'
import { getSkeletonLesioni } from 'getter/getterLesioni'
import { getElementiAutorizzatiSeduta } from 'getter/getterSedute'

export function paginaVediSeduta(props) {
    let cambiaTipo = (tipo) => {
        switch (tipo) {
            case 'note':
                return 'fieldNote'
            case 'diagnosi':
                return 'fieldDiagnosi'
            case 'strategia':
                return 'fieldStrategia'
            case 'testArticolare':
                return 'fieldTestArticolare'
            case 'notaManuale':
                return 'fieldNotaManuale'
            case 'test-articolare':
                return 'fieldTestArticolare'
            case 'nota-manuale':
                return 'fieldNotaManuale'
            default:
                return tipo
        }
    }

    let renderElemento = (elemento) => {
        switch (cambiaTipo(elemento.tipo)) {
            case 'fieldNote':
                return <ElementoVediNota value={elemento.testo} />
            case 'fieldDiagnosi':
                return <ElementoVediDiagnosi value={elemento} />
            case 'fieldTestArticolare':
                return <ElementoVediTestArticolare value={elemento} />
            case 'fieldNotaManuale':
                return <ElementoVediBodyChart value={elemento} />
            case 'fieldStrategia':
                return <ElementoVediStrategia value={elemento} navigate={props.navigate} />

            default:
                return ''
        }
    }

    if (Object.keys(props.seduta).length === 0) return ''

    const elimina = () => {
        APRI_POP_UP({ id: 'elimina', parametro: { azione: () => ELIMINA_SEDUTA(), testo: 'Sei sicuro di voler eliminare la seduta?' } })
    }

    let renderElimina = () => {
        if (!props.elimina) {
            return ''
        }
        if (!props.permessi) {
            return ''
        }

        return (
            <MDBox
                onClick={() => {
                    elimina()
                }}
                sx={{ cursor: 'pointer' }}
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='3rem'
                height='3rem'
                bgColor='error'
                color='white'
                shadow='md'
                borderRadius='xl'
                ml='auto'
                mr={1}>
                <Icon fontSize='medium' color='inherit'>
                    delete
                </Icon>
            </MDBox>
        )
    }

    const riassuntoLesione = (
        <MDBox bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100'>
            {renderElimina()}
            <MDBox lineHeight={0} ml={2} mb={1}>
                <MDTypography color={'dark'} variant='h4'>
                    {props.infoSeduta.data}
                </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
                <MDTypography variant='caption' color='text'>
                    Elementi :
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                        {props.infoSeduta.elementi}
                    </MDTypography>
                </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
                <MDTypography variant='caption' color='text'>
                    Dottore :
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                        {props.infoSeduta.dottore}
                    </MDTypography>
                </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
                <MDTypography variant='caption' color='text'>
                    Disfunzione :
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                        {props.infoSeduta.nomeLesione}
                    </MDTypography>
                </MDTypography>
            </MDBox>
        </MDBox>
    )

    const dialer = () => {
        if (!props.permessi) {
            return ''
        }
        return <ElementoDialer azioni={props.azioni} vediModale={true} />
    }

    if (props.skeleton) {
        return (
            <MDBox sx={{ margin: 'auto', maxWidth: '1000px' }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} width='100%' height='100%'>
                <MDTypography color='info'>Caricamento in corso...</MDTypography>
            </MDBox>
        )
    }

    if (props.elimina) {
        return (
            <React.Fragment>
                <ElementoNavigazioneDisfunzione navigate={props.navigate} />

                {riassuntoLesione}
                {dialer()}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <ElementoNavigazioneDisfunzione navigate={props.navigate} />
            <MDBox className='contenitore-seduta'>
                {riassuntoLesione}
                {props.elementiSeduta.map((elemento, index) => {
                    /* const daVedere =
                        props.azioni.findIndex((azione) => {
                            return azione.tipo === elemento.tipo
                        }) !== -1
                    if (!daVedere) {
                        return ''
                    } */
                    return (
                        <MDBox key={`field-${index}`} sx={{ width: '100%', boxSizing: 'border-box' }} className='elemento-seduta' bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2} mr={2} mb={2} height='100%'>
                            {renderElemento(elemento)}
                        </MDBox>
                    )
                })}
                {dialer()}
            </MDBox>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    let seduta = getSedutaSelezionata()
    return {
        azioni: getElementiAutorizzatiSeduta(),
        seduta: seduta,
        elimina: seduta.elementi !== undefined ? Object.keys(seduta.elementi).length === 0 : true,
        infoSeduta: getRiassuntoSeduta(),
        permessi: getPermessiUtente().lesioniPaziente.input,
        elementiSeduta: getElementiSedutaOrdinati(),
        skeleton: getSkeletonLesioni(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediSeduta)
