import MDBox from 'components/MDBox'
import React, { useState } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import VediForm from 'formMaker/vediForm'
import { formCredenzialiPaziente } from 'formModel/formCredenzialiPaziente'
import { CREA_AGENTE } from 'data/azioni/CREA_AGENTE'

export default function paginaCreaAgente(props) {
    const [step, setStep] = useState(0)
    const [datiUtente, setDatiUtente] = useState({})
    const [credenziali, setCredenziali] = useState({})
    const steps = ['dati agente', 'credenziali']

    const formAgente = {
        parts: [
            {
                id: 'nome',
                tipo: 'fieldText',
                associazione: 'nome',
                label: 'Nome:',
                value: '',
                validazione: 'testoRichiesto',
            },
            {
                id: 'cognome',
                tipo: 'fieldText',
                associazione: 'cognome',
                label: 'Cognome:',
                value: '',
                validazione: 'testoRichiesto',
            },
        ],
    }

    const vaiAvanti = (e) => {
        setDatiUtente(e)
        setStep(step + 1)
    }
    const vaiIndietro = (e) => {
        setCredenziali(e)
        setStep(step - 1)
    }

    const salva = async (e) => {
        setCredenziali(e)
        await CREA_AGENTE(credenziali, datiUtente)
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Agente creato' } })
        props.navigate('/paginaListaAgenti')
    }

    const renderDatiAgente = () => {
        return <VediForm annulla={() => props.navigate('/paginaListaAgenti')} soloInput={true} vista='input' form={formAgente} dati={datiUtente} isPrimoStep={true} salva={(e) => vaiAvanti(e)} />
    }
    const renderCredenziali = () => {
        return <VediForm annulla={() => props.navigate('/paginaListaAgenti')} soloInput={true} vista='input' form={formCredenzialiPaziente()} dati={credenziali} isUltimoStep={true} salva={(e) => salva(e)} indietro={(e) => vaiIndietro(e)} />
    }

    return (
        <MDBox sx={{ margin: 'auto', maxWidth: '1000px', width: '100%', mt: 5 }} bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
            <MDBox mx={2} mt={-7}>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MDBox mt={2}>{step === 0 ? renderDatiAgente() : renderCredenziali()}</MDBox>
            </MDBox>
        </MDBox>
    )
}
