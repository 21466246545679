import { getPermessiUtente } from 'getter/getterPermessi'
import React from 'react'
import { connect } from 'react-redux'
import ElementoVediDateTutorial from 'componenti/elementoVediDateTutorial'
import MDBox from 'components/MDBox'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export const paginaTutorial = (props) => {
    const renderFab = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => APRI_POP_UP({ id: 'data-tutorial', parametro: {id : ''} })}>
                <AddIcon />
            </Fab>
        )
    }

    return (
        <MDBox width='100%' maxWidth='1000px' shadow='lg' borderRadius='lg' variant='gradient' opacity={1} mx='auto'>
            <ElementoVediDateTutorial />
            {renderFab()}
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        permessi: getPermessiUtente().superAmministratore,
    }
}

export default connect(mapStateToProps, null)(paginaTutorial)
