import { Grid } from '@mui/material'
import { AGGIORNA_FILTRI_CERCA_PAZIENTE } from 'data/azioni/AGGIORNA_FILTRI_CERCA_PAZIENTE'
import { InputText } from 'fields/fieldText'
import React, { useState } from 'react'
import { connect } from 'react-redux'

export const ElementoCampiCercaPaziente = (props) => {
    let modelCognome = {
        id: 'cognome',
        tipo: 'fieldText',
        associazione: 'cognome',
        label: 'Cognome',
        value: '',
        details: {
            variant: 'outlined',
            type: 'text',
        },
    }
    let modelNome = {
        id: 'nome',
        tipo: 'fieldText',
        associazione: 'nome',
        label: 'Nome',
        value: '',
        details: {
            variant: 'outlined',
            type: 'text',
        },
    }
    let modelAnno = {
        id: 'anno',
        tipo: 'fieldText',
        associazione: 'anno',
        label: 'Anno',
        value: '',
        details: {
            variant: 'outlined',
            type: 'text',
        },
    }

    const detailsBianco = {
        color: 'white',
        sx: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white !important',
            },
        },
        InputLabelProps: { style: { color: '#fff' } },
        testoBianco: true,
        variant: 'outlined',
        type: 'text',
    }

    if (props.testoBianco) {
        modelCognome.details = detailsBianco
        modelAnno.details = detailsBianco
        modelNome.details = detailsBianco
    }

    let aggiornaFiltri = (campo, valore) => {
        AGGIORNA_FILTRI_CERCA_PAZIENTE({ campo: campo, valore: valore })
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InputText model={modelCognome} value={props.cognome} azione={(a, b) => aggiornaFiltri(a, b)} errore={''} />
            </Grid>
            <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InputText model={modelNome} value={props.nome} azione={(a, b) => aggiornaFiltri(a, b)} errore={''} />
            </Grid>
            <Grid item xs={12} lg={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InputText model={modelAnno} value={props.anno} azione={(a, b) => aggiornaFiltri(a, b)} errore={''} />
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {
        nome: state.reducerFiltriCercaPaziente?.nome || '',
        cognome: state.reducerFiltriCercaPaziente?.cognome || '',
        anno: state.reducerFiltriCercaPaziente?.anno || '',
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ElementoCampiCercaPaziente)
