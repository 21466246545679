import React, { useEffect, useState } from 'react'
import ImgsViewer from 'react-images-viewer'

// @mui material components
import Stack from '@mui/material/Stack'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

import MDTypography from 'components/MDTypography'

export default function elementoVediFoto(props) {
    const [currentImage, setCurrentImage] = useState('')
    const [imgsViewer, setImgsViewer] = useState(false)
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0)
    const [listaImmagini, setListaImmagini] = useState([])

    useEffect(() => {
        setListaImmagini(
            props.immagini.map((immagine) => {
                return { src: immagine }
            }),
        )
        setCurrentImage(props.immagini[0] || '')
    }, [props.immagini])

    const handleSetCurrentImage = ({ currentTarget }) => {
        setCurrentImage(currentTarget.src)
        setImgsViewerCurrent(Number(currentTarget.id))
    }

    const openImgsViewer = () => setImgsViewer(true)
    const closeImgsViewer = () => setImgsViewer(false)
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1)
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1)

    const renderNoImmagini = () => {
        let colore = 'text'
        let testo = 'Nessun file caricato'
        if (props.skeleton) {
            colore = 'info'
            testo = 'Caricamento in corso'
        }
        return (
            <MDBox borderRadius='lg' width='100%' height='100%' justifyContent='center' alignItems='center' display='flex'>
                <MDTypography variant='h4' color={colore}>
                    {testo}
                </MDTypography>
            </MDBox>
        )
    }

    if (currentImage === '') {
        return renderNoImmagini()
    }

    return (
        <MDBox>
            <ImgsViewer imgs={listaImmagini} isOpen={imgsViewer} onClose={closeImgsViewer} currImg={imgsViewerCurrent} onClickPrev={imgsViewerPrev} onClickNext={imgsViewerNext} backdropCloseable />

            <MDBox sx={{ cursor: 'pointer' }} component='img' src={currentImage} alt='Product Image' shadow='lg' borderRadius='lg' width='100%' onClick={openImgsViewer} />

            <MDBox mt={2} pt={1}>
                <Stack direction='row' spacing={3} sx={{ overflowX: 'auto' }}>
                    {props.immagini.map((immagine, index) => {
                        return <MDBox key={`immagine-${index}`} component='img' id={index} src={immagine} alt={`small image ${index}`} borderRadius='lg' shadow='md' width='100%' height='5rem' minHeight='5rem' sx={{ cursor: 'pointer', objectFit: 'cover' }} onClick={handleSetCurrentImage} />
                    })}
                </Stack>
            </MDBox>
        </MDBox>
    )
}
