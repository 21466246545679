import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { formUtente } from 'formModel/formUtente'
import { getPaziente, getSkeleton } from 'getter/getterPaziente'
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded'
import FormOutput from 'formMaker/formOutput'
import { rimuoviOggettiDaUnArrayDiOggetti } from 'data/strumenti'
import { Divider, Grid, Icon } from '@mui/material'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { getRiassuntoUtente } from 'getter/getterUtente'
import { getPermessiUtente } from 'getter/getterPermessi'
import { getIdPaziente } from 'getter/getterPaziente'
import { INVIA_CREDENZIALI } from 'data/azioni/INVIA_CREDENZIALI'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { REVOCA_ACCESSO } from 'data/azioni/REVOCA_ACCESSO'
import ElementoVediSedute from 'componenti/elementoVediSedute'
import { getLesioniDashboard } from 'getter/getterLesioni'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import ElementoMostraDisfunzione from 'componenti/elementoMostraDisfunzione'
import { getEserciziDashboard } from 'getter/getterPaziente'
import { getFattureDashboard } from 'getter/getterPaziente'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { getSkeletonLesioni } from 'getter/getterLesioni'
import MDButton from 'components/MDButton'
import { CREA_ESERCIZIO_DA_ASSEGNARE } from 'data/azioni/CREA_ESERCIZIO_DA_ASSEGNARE'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import { ref, getDownloadURL } from 'firebase/storage'
import { getPrimeTreImmagini } from 'getter/getterLesioni'
import ElementoMostraImmagine from 'componenti/elementoMostraImmagine'
import ElementoAggiungiFile from 'componenti/elementoAggiungiFile'
import {getSchedeDashboard, getSkeletonListaSchede } from 'getter/getterSchede'

export const paginaDashboardPaziente = (props) => {
    const [url, setUrl] = useState('')

    const getUrl = () => {
        let path = `documentazione/privacy/${getIdPaziente()}/privacy.pdf`

        return new Promise((resolve, reject) => {
            getDownloadURL(ref(storage, path)).then((nuovoUrl) => {
                setUrl(nuovoUrl)
            })
        })
    }

    const opzioni = {
        scaricaPrivacy: () => {
            window.open(url)
        },
        firmaPrivacy: () => {
            APRI_POP_UP({ id: 'firma-documento', parametro: { isStrategia: false } })
        },
        inviaCredenziali: () => {
            INVIA_CREDENZIALI(props.idPaziente)
        },
        revocaAccesso: () => {
            APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di voler revocare l'accesso al paziente?`, azione: () => REVOCA_ACCESSO(props.idPaziente) } })
        },
        generaCredenziali: () => {
            APRI_POP_UP({ id: 'generaCredenziali', parametro: '' })
        },
    }

    useEffect(() => {
        if (props.privacy) {
            getUrl()
        }
    }, [props.privacy])

    const renderAnagrafica = () => {
        let form = formUtente('profilo-paziente')
        form.parts = rimuoviOggettiDaUnArrayDiOggetti(form.parts, ['indirizzo', 'provincia', 'cap', 'luogoNascita', 'email', 'covid', 'provenienza'], 'id')

        return (
            <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5} sx={{ cursor: 'pointer' }} onClick={() => props.navigate(`/paginaVediPaziente/${props.idPaziente}/anagraficaPaziente`)}>
                    <MenuBookRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        Riassunto anagrafica
                    </MDTypography>
                    <ArrowForwardIosRoundedIcon color='white' sx={{ ml: 3 }} />
                </MDBox>
                <FormOutput skeleton={props.skeletonDatiPaziente} form={form} dati={props.dati} />
            </MDBox>
        )
    }

    const renderOpzioni = () => {
        return (
            <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <SettingsRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        Opzioni
                    </MDTypography>
                </MDBox>
                <MDBox>
                    {props.opzioni.map(({ icon, label, azione, errore }, key) => {
                        const iteMDey = `item-${key}`

                        return (
                            <MDBox
                                onClick={() => {
                                    if (azione !== '') {
                                        opzioni[azione]()
                                    }
                                }}
                                sx={{ cursor: 'pointer' }}
                                key={iteMDey}
                                pt={key === 0 ? 0 : 1}>
                                <MDTypography
                                    variant='button'
                                    color={azione === '' ? 'error' : 'dark'}
                                    fontWeight='regular'
                                    textTransform='capitalize'
                                    sx={({ borders: { borderRadius }, functions: { pxToRem }, palette: { light }, transitions }) => ({
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: borderRadius.md,
                                        padding: `${pxToRem(10)} ${pxToRem(16)}`,
                                        transition: transitions.create('background-color', {
                                            easing: transitions.easing.easeInOut,
                                            duration: transitions.duration.shorter,
                                        }),

                                        '&:hover': {
                                            backgroundColor: light.main,
                                        },
                                    })}>
                                    <MDBox mr={1.5} lineHeight={1} color={azione === '' ? 'error' : 'dark'}>
                                        <Icon fontSize='small'>{icon}</Icon>
                                    </MDBox>
                                    {label}
                                    {errore ? (
                                        <MDBox ml={1.5} lineHeight={1} color={'error'}>
                                            <PriorityHighRoundedIcon fontSize='small' />
                                        </MDBox>
                                    ) : (
                                        ''
                                    )}
                                </MDTypography>
                            </MDBox>
                        )
                    })}
                </MDBox>
            </MDBox>
        )
    }

    const renderElementoLista = (testo) => {
        return (
            <React.Fragment key={testo}>
                <ListItem key={testo} color='primary'>
                    <ListItemText primary={testo} />
                </ListItem>
                <Divider />
            </React.Fragment>
        )
    }

    const renderListaDisfunzioni = () => {
        if (props.skeletonLesioni) {
            return <MDTypography color='info'>Caricamento disfunzioni in corso... </MDTypography>
        }
        if (props.disfunzioni.length === 0) {
            return <MDTypography color='text'>Nessuna disfunzione presente </MDTypography>
        }
        return (
            <List>
                {props.disfunzioni.map((disfunzione) => {
                    return (
                        <React.Fragment key={disfunzione.id}>
                            <ElementoMostraDisfunzione navigate={props.navigate} disfunzione={disfunzione} />
                            <Divider />
                        </React.Fragment>
                    )
                })}
            </List>
        )
    }

    const renderDisfunzioni = () => {
        if (!props.permessiDati) {
            return ''
        }
        return (
            <Grid item xs={12} lg={8}>
                <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto' className='box-dashboard'>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5} sx={{ cursor: 'pointer' }} onClick={() => props.navigate(`/paginaVediPaziente/${props.idPaziente}/disfunzioniPaziente`)}>
                        <MDTypography color={'white'} variant='h5'>
                            Ultime disfunzioni
                        </MDTypography>
                        <ArrowForwardIosRoundedIcon color='white' sx={{ ml: 3 }} />
                    </MDBox>
                    {renderListaDisfunzioni()}
                    {props.permessiInput ? (
                        <MDBox className='contenitore-bottoni-destra-verticale'>
                            <MDButton variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'disfunzione', parametro: { navigate: props.navigate, nome: '', modifica: false } })}>
                                nuova disfunzione
                            </MDButton>
                        </MDBox>
                    ) : (
                        ''
                    )}
                </MDBox>
            </Grid>
        )
    }

    const renderListaEsercizi = () => {
        if (props.skeletonSchede) {
            return <MDTypography color='info'>Caricamento schede in corso... </MDTypography>
        }
        if (props.schede.length === 0) {
            return <MDTypography color='text'>Nessuna scheda assegnata </MDTypography>
        }
        return (
            <List>
                {props.schede.map((scheda) => {
                    return renderElementoLista(scheda.titolo)
                })}
            </List>
        )
    }

    const renderEsercizi = () => {
        if (!props.permessiDati) {
            return ''
        }
        return (
            <Grid item xs={12} lg={8}>
                <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto' className='box-dashboard'>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5} sx={{ cursor: 'pointer' }} onClick={() => props.navigate(`/paginaVediPaziente/${props.idPaziente}/eserciziPaziente`)}>
                        <MDTypography color={'white'} variant='h5'>
                            Ultime schede assegnate
                        </MDTypography>
                        <ArrowForwardIosRoundedIcon color='white' sx={{ ml: 3 }} />
                    </MDBox>
                    {renderListaEsercizi()}
                    {props.permessiInput ? (
                        <MDBox className='contenitore-bottoni-destra' gridAutoFlow={{ xs: 'row', md: 'column' }}>
                            <MDButton variant='contained' color='primary' onClick={() => props.navigate(`paginaVediPaziente/${getIdPaziente()}/PaginaSchedaPaziente/crea`) } >
                                nuova scheda
                            </MDButton>
                            {/* <MDButton variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'nuovoEsercizio', parametro: { daAssegnare: true, azione: (e) => CREA_ESERCIZIO_DA_ASSEGNARE(e) } })}>
                                nuova esercizio
                            </MDButton>
                            <MDButton variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'assegna-esercizio', parametro: '' })}>
                                assegna esercizio da libreria
                            </MDButton> */}
                        </MDBox>
                    ) : (
                        ''
                    )}
                </MDBox>
            </Grid>
        )
    }

    const renderListaFatture = () => {
        if (props.skeletonDatiPaziente) {
            return <MDTypography color='info'>Caricamento fatture in corso... </MDTypography>
        }
        if (props.fatture.length === 0) {
            return <MDTypography color='text'>Nessuna fattura </MDTypography>
        }
        return (
            <List>
                {props.fatture.map((fattura) => {
                    return renderElementoLista(fattura.contenuto.numFattura)
                })}
            </List>
        )
    }

    const renderFatture = () => {
        if (!props.permessiFatture) {
            return ''
        }
        return (
            <Grid item xs={12} lg={4}>
                <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto' className='box-dashboard'>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5} sx={{ cursor: 'pointer' }} onClick={() => props.navigate(`/paginaVediPaziente/${props.idPaziente}/fatturePaziente`)}>
                        <MDTypography color={'white'} variant='h5'>
                            Ultime fatture
                        </MDTypography>
                        <ArrowForwardIosRoundedIcon color='white' sx={{ ml: 3 }} />
                    </MDBox>
                    {renderListaFatture()}
                    {props.permessiInput ? (
                        <MDBox className='contenitore-bottoni-destra-verticale'>
                            <MDButton variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'fattura', parametro: { idFattura: '', idPaziente: getIdPaziente() } })}>
                                nuova fattura
                            </MDButton>
                        </MDBox>
                    ) : (
                        ''
                    )}
                </MDBox>
            </Grid>
        )
    }

    const renderSedute = () => {
        if (!props.permessiDati) {
            return ''
        }
        return (
            <Grid item xs={12} lg={4}>
                <ElementoVediSedute vediBottone={true} titolo='Ultime sedute' navigate={props.navigate} />
            </Grid>
        )
    }

    const renderListaFiles = () => {
        if (props.skeletonLesioni) {
            return <MDTypography color='info'>Caricamento files in corso... </MDTypography>
        }
        if (props.immagini.length === 0) {
            return <MDTypography color='text'>Nessun file presente </MDTypography>
        }
        return (
            <div className='flex-a-capo' >
                {props.immagini.map((immagine, index) => {
                    return (
                        <div className='contenitore-immagine-nome' key={`immagine-${index}`}>
                            <MDTypography textTransform='capitalize' variant='caption' color='info' fontWeight='medium'>
                                {immagine.contenuto.disfunzione}
                            </MDTypography>
                            <ElementoMostraImmagine immagine={immagine} />
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderFiles = () => {
        if (!props.permessiDati) {
            return ''
        }
        return (
            <Grid item xs={12} lg={12}>
                <MDBox display='flex' flexDirection='column'>
                    <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto' className='box-dashboard'>
                        <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5} sx={{ cursor: 'pointer' }} onClick={() => props.navigate(`/paginaVediPaziente/${props.idPaziente}/filesPaziente`)}>
                            <MDTypography color={'white'} variant='h5'>
                                Ultimi files
                            </MDTypography>
                            <ArrowForwardIosRoundedIcon color='white' sx={{ ml: 3 }} />
                        </MDBox>
                        {renderListaFiles()}
                        {props.permessiInput ? <ElementoAggiungiFile isFileDisfunzione={true} isDashboard={true} /> : ''}
                    </MDBox>
                </MDBox>
            </Grid>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
                {renderAnagrafica()}
            </Grid>
            <Grid item xs={12} lg={4}>
                {renderOpzioni()}
            </Grid>

            {renderSedute()}
            {renderDisfunzioni()}
            {renderEsercizi()}
            {renderFatture()}
            {renderFiles()}
        </Grid>
    )
}

function mapStateToProps(state) {
    const utente = getRiassuntoUtente()
    const permessi = getPermessiUtente().documentiPaziente.input

    let items = []
    if (utente.privacyFirmata) {
        items.push({ icon: 'download', label: 'Scarica privacy', azione: 'scaricaPrivacy' })
    }
    if (!utente.privacyFirmata) {
        if (permessi) {
            items.push({ icon: 'edit', label: 'Firma privacy', azione: 'firmaPrivacy', errore: true })
        }
        if (!permessi) {
            items.push({ icon: 'error', label: 'Privacy non firmata', azione: '' })
        }
    }
    if (permessi) {
        if (utente.accesso) {
            items.push({ icon: 'send', label: 'Invia credenziali', azione: 'inviaCredenziali' })
            items.push({ icon: 'delete', label: 'Revoca accesso', azione: 'revocaAccesso' })
        }
        if (!utente.accesso) {
            items.push({ icon: 'add', label: 'Genera credenziali', azione: 'generaCredenziali' })
        }
    }

    let listaFatture = []
    const listaPermessi = getPermessiUtente()
    const permessiFatture = listaPermessi.fatturePaziente.output
    if (permessiFatture) {
        listaFatture = getFattureDashboard()
    }


    return {
        dati: getPaziente(),
        opzioni: items,
        privacy: utente.privacyFirmata,
        idPaziente: utente.id,
        disfunzioni: getLesioniDashboard(),
        permessiFatture: permessiFatture,
        permessiDati: listaPermessi.datiSingoloPaziente.output,
        fatture: listaFatture,
        schede: getSchedeDashboard(),
        skeletonDatiPaziente: getSkeleton(),
        skeletonLesioni: getSkeletonLesioni(),
        skeletonSchede: state.reducerSchedePaziente?.skeleton,
        permessiInput: permessi,
        immagini: getPrimeTreImmagini(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaDashboardPaziente)
