import { baseWatcher } from './baseWatcher'
import { LEGGI_CONTROLLO_AIFI } from './../azioni/LEGGI_CONTROLLO_AIFI'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherControlloAifi extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        window.addEventListener('osserveRichiestaAifi', (e) => this.apriConnessione(e.detail))
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'richiesteAifi', nuovoId), (documento) => {
            if (!documento.data().inAttesa) {
                LEGGI_CONTROLLO_AIFI({ valido: documento.data().isValido, id: documento.id })
                this.chiudiConnessione()
            }
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
