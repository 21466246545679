const initialState = {
    listaSedute: {},
    seduteLesione: {
        idLesione: '',
        listaSedute: {},
    },
    sedutaSelezionata: {
        idSeduta: '',
        contenuto: {},
    },
}

export const reducerSedute = (state = initialState, action) => {
    let nuovoStato = Object.assign(state, {})
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_LESIONI':
            let sedute = getSedute(action.payload)
            nuovoStato.sedutaSelezionata.contenuto = getSedutaSelezionata(sedute, state.sedutaSelezionata.idSeduta)
            nuovoStato.seduteLesione.listaSedute = getSeduteLesione(sedute, state.seduteLesione.idLesione)
            nuovoStato.listaSedute = sedute
            return nuovoStato
        case 'CAMBIA_URL':
            let idLesione = getIdLesione(action.payload)
            let idSeduta = getIdSeduta(action.payload)
            let seduteLesione = getSeduteLesione(state.listaSedute, idLesione)
            let sedutaSelezionata = getSedutaSelezionata(state.listaSedute, idSeduta)
            nuovoStato.seduteLesione = { idLesione: idLesione, listaSedute: seduteLesione }
            nuovoStato.sedutaSelezionata = { idSeduta: idSeduta, contenuto: sedutaSelezionata }
            return nuovoStato
        default:
            return state
    }
}

let getSedute = (lesioni) => {
    let sedute = {}
    Object.keys(lesioni).forEach((idLesione) => {
        let lesione = lesioni[idLesione]
        if (lesione.sedute !== undefined) {
            Object.keys(lesione.sedute).forEach((idSeduta) => {
                let seduta = Object.assign(lesione.sedute[idSeduta], { idLesione: idLesione })
                sedute[idSeduta] = seduta
            })
        }
    })
    return sedute
}

let getIdLesione = (url) => {
    if (!url.includes('paginaVediLesione')) {
        return ''
    }
    //let parteUrl = url[3] === 'paginaVediLesione' ? 4 : 6
    let parteUrl = 6
    return url[parteUrl]
}

let getIdSeduta = (url) => {
    if (!url.includes('paginaVediSeduta') && !url.includes('paginaFirmaConsensoInformato')) {
        return ''
    }
    //let parteUrl = url[3] === 'paginaVediLesione' ? 6 : 8
    let parteUrl = 8
    return url[parteUrl]
}

let getSeduteLesione = (sedute, id) => {
    if (id === '') {
        return {}
    }
    let seduteLesione = {}
    Object.keys(sedute).forEach((idSeduta) => {
        let seduta = sedute[idSeduta]
        if (seduta.idLesione === id) {
            seduteLesione[idSeduta] = seduta
        }
    })
    return seduteLesione
}

let getSedutaSelezionata = (sedute, id) => {
    if (id === '') {
        return {}
    }
    return sedute[id] === undefined ? {} : sedute[id]
}
