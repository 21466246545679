import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'
import { InputText, OutputText } from './fieldText'

export function InputNumeroDocumento(props) {
    const aggiornaValore = (posizione, valore) => {
        let nuovoValore = ''
        if (posizione === 0) {
            nuovoValore = `${valore}-${props.value.split('-')[1]}`
        }
        if (posizione === 1) {
            nuovoValore = `${props.value.split('-')[0]}-${valore}`
        }
        props.azione(props.model.id, nuovoValore)
    }

    const getErroreNumero = () => {
        if (props.errore === 'Campo obbligatorio, maggiore di 0') {
            return props.errore
        }
        return ''
    }
    const getErroreAnno = () => {
        if (props.errore === 'Campo obbligatorio, maggiore di 0') {
            return ''
        }
        return props.errore
    }

    const modelNumero = {
        id: 'numero',
        tipo: 'fieldText',
        associazione: 'numero',
        label: 'numero documento',
        value: '',
        details: { type: 'number' },
    }
    const modelAnno = {
        id: 'anno',
        tipo: 'fieldText',
        associazione: 'anno',
        label: 'anno documento',
        value: '',
        details: { type: 'number' },
    }

    return (
        <MDBox display='flex'>
            <InputText model={modelNumero} value={Number(props.value.split('-')[0])} errore={getErroreNumero()} azione={(a, b) => aggiornaValore(0, b)} />
            <MDBox display='flex' justifyContent='center' alignItems='center' height="44.13px">
                <MDTypography fontWeight='bold'>-</MDTypography>
            </MDBox>
            <InputText model={modelAnno} value={Number(props.value.split('-')[1])} errore={getErroreAnno()} azione={(a, b) => aggiornaValore(1, b)} />
        </MDBox>
    )
}
export function OutputNumeroDocumento(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    return <OutputText skeleton={props.skeleton || false} model={model} value={props.value} />
}
