export const listaTipoSpesa = [
    { id: 'TK', testo: 'TK- Ticket (Quota fissa e/o Differenza con il prezzo di riferimento. Franchigia. Pronto Soccorso e accesso diretto)' },
    { id: 'FC', testo: 'FC- Farmaco, anche omeopatico. Dispositivi medici CE' },
    { id: 'FV', testo: 'FV- Farmaco per uso veterinario' },
    { id: 'AD', testo: 'AD- Acquisto o affitto di dispositivo medico CE' },
    { id: 'AS', testo: 'AS- Spese sanitarie relative ad ECG, spirometria, Holter pressorio e cardiaco, test per glicemia, colesterolo e trigliceridi o misurazione della pressione sanguigna, prestazione previste dalla farmacia dei servizi e simili sanguigna) ' },
    { id: 'SR', testo: 'SR- prestazioni sanitarie: assistenza specialistica ambulatoriale esclusi interventi dichirurgia estetica; visita medica generica e specialistica o prestazioni diagnostiche estrumentali; prestazione chirurgica ad esclusione della chirurgia estetica;certificazione medica; ricoveri ospedalieri ricollegabili ad interventi chirurgici o adegenza, ad esclusione della chirurgia estetica, al netto del comfort' },
    { id: 'CT', testo: 'CT- Cure Termali' },
    { id: 'PI', testo: 'PI- protesica e integrativa' },
    { id: 'IC', testo: 'IC- Prestazioni di chirurgia estetica e di medicina estetica (ambulatoriale o ospedaliera) ' },
    { id: 'SP', testo: 'SP- Prestazioni sanitarie' },
    { id: 'SV', testo: 'SV- Spese veterinarie' },
    { id: 'AA', testo: 'AA- Altre spese' },
]
export const listaNaturaIva = [
    { id: 'N1', testo: 'N1- escluse ex art. 15' },
    { id: 'N2', testo: 'N2- non soggette' },
    { id: 'N2.1', testo: 'N2.1- non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72' },
    { id: 'N2.2', testo: 'N2.2- non soggette – altri casi' },
    { id: 'N3', testo: 'N3- non imponibili' },
    { id: 'N3.1', testo: 'N3.1- non imponibili – esportazioni' },
    { id: 'N3.2', testo: 'N3.2- non imponibili – cessioni intracomunitarie' },
    { id: 'N3.3', testo: 'N3.3- non imponibili – cessioni verso San Marino' },
    { id: 'N3.4', testo: 'N3.4- non imponibili – operazioni assimilate alle cessioni all’esportazione' },
    { id: 'N3.5', testo: 'N3.5- non imponibili – a seguito di dichiarazioni d’intento' },
    { id: 'N3.6', testo: 'N3.6- non imponibili – altre operazioni che non concorrono alla formazione del plafond' },
    { id: 'N4', testo: 'N4- esenti' },
    { id: 'N5', testo: 'N5- regime del margine / IVA non esposta in fattura' },
    { id: 'N6', testo: 'N6- inversione contabile' },
    { id: 'N6.1', testo: 'N6.1- inversione contabile – cessione di rottami e altri materiali di recupero' },
    { id: 'N6.2', testo: 'N6.2- inversione contabile – cessione di oro e argento puro' },
    { id: 'N6.3', testo: 'N6.3- inversione contabile – subappalto nel settore edile' },
    { id: 'N6.4', testo: 'N6.4- inversione contabile – cessione di fabbricati' },
    { id: 'N6.5', testo: 'N6.5- inversione contabile – cessione di telefoni cellulari' },
    { id: 'N6.6', testo: 'N6.6- inversione contabile – cessione di prodotti elettronici' },
    { id: 'N6.7', testo: 'N6.7- inversione contabile – prestazioni comparto edile e settori connessi' },
    { id: 'N6.8', testo: 'N6.8- inversione contabile – operazioni settore energetico' },
    { id: 'N6.9', testo: 'N6.9- inversione contabile – altri casi' },
    { id: 'N7', testo: 'N7- IVA assolta in altro stato UE' },
]
