import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React context
export const elementoInformazioniAbbonamento = ({ scadenza, titolare, statoAttivo }) => (
    <MDBox width='100%' display='flex' flexDirection='column' >
        <MDBox lineHeight={0} ml={3} mb={2}>
            <MDTypography color={'dark'} variant='h4'>
                Stato abbonamento
            </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
            <MDTypography variant='caption' color='text'>
                Data scadenza abbonamento :&nbsp;&nbsp;&nbsp;
                <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                    {scadenza}
                </MDTypography>
            </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
            <MDTypography variant='caption' color='text'>
                Titolare sottoscrizione:&nbsp;&nbsp;&nbsp;
                <MDTypography variant='caption' fontWeight='medium'>
                    {titolare}
                </MDTypography>
            </MDTypography>
        </MDBox>
        <MDTypography variant='caption' color='text'>
            Attivo:&nbsp;&nbsp;&nbsp;
            <MDTypography variant='caption' fontWeight='medium'>
                {statoAttivo}
            </MDTypography>
        </MDTypography>
    </MDBox>
)



const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoInformazioniAbbonamento)
