import { getRiassuntoUtenteAmministrazioneClinica } from 'getter/getterUtente'
import React from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import MDButton from 'components/MDButton'
import { isAttivo } from 'getter/getterDipendenti'
import { isPagato } from 'getter/getterDipendenti'
import { isProvaGratuita } from 'getter/getterDipendenti'
import { getFineAbbonamento } from 'getter/getterDipendenti'
import { isSospeso } from 'getter/getterDipendenti'
import { isPagamentoFallito } from 'getter/getterDipendenti'
import { INVIA_CREDENZIALI } from 'data/azioni/INVIA_CREDENZIALI'
import { MODIFICA_STATO_ATTIVO_DIPENDENTE } from 'data/azioni/MODIFICA_STATO_ATTIVO_DIPENDENTE'
import { ATTIVA_ABBONAMENTO } from 'data/azioni/ATTIVA_ABBONAMENTO'
import { MODIFICA_RINNOVO_SOTTOSCRIZIONE } from 'data/azioni/MODIFICA_RINNOVO_SOTTOSCRIZIONE'
import { RISOLVI_PAGAMENTO_FALLITO } from 'data/azioni/RISOLVI_PAGAMENTO_FALLITO'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export const elementoGestioneAbbonamento = (props) => {
    const pagato = isPagato(props.utente.id)

    const renderErrore = () => {
        if (!isPagamentoFallito(props.utente.id)) {
            return ''
        }
        return (
            <Grid item container direction='row' justifyContent='center' spacing={2}>
                <MDTypography fontWeight='medium' color='error'>
                    Errore nel pagamento
                </MDTypography>
                <MDButton size='small' variant='contained' color='primary' onClick={() => RISOLVI_PAGAMENTO_FALLITO()}>
                    paga ora
                </MDButton>
            </Grid>
        )
    }

    const renderInfoAbbonamento = () => {
        if (!pagato) {
            return ''
        }

        const abbonamentoSospeso = isSospeso(props.utente.id)
        return (
            <React.Fragment>
                <Grid item container direction='row' justifyContent='space-between'>
                    <MDTypography fontWeight='medium' color='dark'>
                        Prova gratuita :
                    </MDTypography>
                    <MDTypography fontWeight='medium' color='dark'>
                        {isProvaGratuita(props.utente.id) ? 'In corso' : 'No'}
                    </MDTypography>
                </Grid>
                <Grid item container direction='row' justifyContent='space-between'>
                    <MDTypography fontWeight='medium' color='dark'>
                        Fine periodo :
                    </MDTypography>
                    <MDTypography fontWeight='medium' color='dark'>
                        {getFineAbbonamento(props.utente.id)}
                    </MDTypography>
                </Grid>
                <Grid item container direction='row' justifyContent='space-between'>
                    <Grid item>
                        <MDTypography fontWeight='medium' color='dark'>
                            Rinnovo automatico :
                        </MDTypography>
                    </Grid>
                    <Grid item>
                        <Grid container direction='column' spacing={2}>
                            <MDTypography align='right' fontWeight='medium' color={abbonamentoSospeso ? 'error' : 'success'}>
                                {abbonamentoSospeso ? 'Non attivo' : 'Attivo'}
                            </MDTypography>
                            <MDButton size='small' variant='contained' color='primary' onClick={() => MODIFICA_RINNOVO_SOTTOSCRIZIONE(props.utente.id)}>
                                {abbonamentoSospeso ? 'attiva' : 'disattiva'}
                            </MDButton>
                        </Grid>
                    </Grid>
                </Grid>
                {renderErrore()}
            </React.Fragment>
        )
    }

    return (
        <MDBox width='100%' height='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg'>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h5'>
                    Gestione dipendente
                </MDTypography>
            </MDBox>
            <Grid container spacing={3} direction='column'>
                <Grid item container direction='row' justifyContent='space-between'>
                    <MDTypography fontWeight='medium' color='dark'>{`${props.utente.nome} ${props.utente.cognome}`}</MDTypography>
                    <MDBox className='fletti-verticalmente'>
                        <MDButton size='small' variant='contained' color='primary' onClick={() => INVIA_CREDENZIALI(props.utente.id)}>
                            Invia credenziali
                        </MDButton>
                        <MDButton sx={{mt : 1}} size='small' variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'generaCredenziali', parametro: { id: props.utente.id, controlloMailUtente: true } })}>
                            Nuove credenziali
                        </MDButton>
                    </MDBox>
                </Grid>
                <Grid item container direction='row' justifyContent='space-between'>
                    <MDTypography fontWeight='medium' color='dark'>
                        {isAttivo(props.utente.id) ? 'Accesso abilitato' : 'Accesso disabilitato'}
                    </MDTypography>
                    <MDButton size='small' variant='contained' color='primary' onClick={() => MODIFICA_STATO_ATTIVO_DIPENDENTE(props.utente.id)}>
                        {isAttivo(props.utente.id) ? 'Disabilita' : 'abilita'}
                    </MDButton>
                </Grid>
                <Grid item container direction='row' justifyContent='space-between'>
                    <Grid item container direction='row' justifyContent='space-between'>
                        <MDTypography fontWeight='medium' color={pagato ? 'success' : 'error'}>
                            {pagato ? 'Pagato' : 'Non pagato'}
                        </MDTypography>
                        {pagato ? (
                            ''
                        ) : (
                            <MDButton size='small' variant='contained' color='primary' onClick={() => ATTIVA_ABBONAMENTO(props.utente.id)}>
                                paga ora
                            </MDButton>
                        )}
                    </Grid>
                </Grid>
                {renderInfoAbbonamento()}
            </Grid>
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        utente: getRiassuntoUtenteAmministrazioneClinica(),
    }
}

export default connect(mapStateToProps, null)(elementoGestioneAbbonamento)
