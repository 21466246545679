import illustrazioneTS from 'assets/images/landing/listaFatture.webp'
import TS from 'assets/images/landing/TS.webp'
import doc from 'assets/images/landing/doc.svg'
import stats from 'assets/images/landing/stats.svg'
import checklist from 'assets/images/landing/checklist.svg'
import hand from 'assets/images/landing/hand.svg'
import documentiLegali from 'assets/images/landing/documentiLegali3.webp'
import telefonoDatiAnalitici from 'assets/images/landing/telefonoDatiAnalitici2.webp'
import semplicita02 from 'assets/images/landing/semplicita02.webp'
import completezza from 'assets/images/landing/completezza.webp'
import { getAppUtilizzata } from 'getter/getterAppUtilizzata'

const jsonFunzioniLanding = [
    {
        img: illustrazioneTS,
        titolo: 'Sistema Tessera Sanitaria',
        descrizione: "Per ridurre al minimo la burocrazia, Physio Archive include la nuova importante funzionalità che ti consente di gestire le tue fatture in dialogo diretto con il Sistema Tessera Sanitaria: ogni volta che emetti, modifichi o elimini una fattura, i dati saranno automaticamente trasmessi all'Agenzia delle Entrate, senza richiederti altro tempo.",
        svg: TS,
        app: ['physio'],
    },
    {
        img: documentiLegali,
        titolo: 'Documenti legali',
        descrizione: 'Su Physio Archive puoi firmare, salvare e rintracciare documenti dal valore legale sostituendo in toto i documenti su carta: qualche esempio? L’informativa della privacy GDPR, e i documenti relativi ai trattamenti dei pazienti e i pagamenti.',
        svg: doc,
        app: ['osteo', 'physio'],
    },
    {
        img: telefonoDatiAnalitici,
        titolo: 'Dati analitici',
        descrizione: 'La quantità non basta senza la qualità: per ogni seduta puoi registrare le impostazioni del trattamento, gli esercizi impostati in studio e quelli da svolgere a casa o caricare file multimediali, il tutto supportato dalle illustrazioni e dalle scale di valutazione di Physio Archive.',
        svg: stats,
        app: ['osteo', 'physio'],
    },
    {
        img: completezza,
        titolo: 'Completezza',
        descrizione: 'Anagrafiche pazienti, cartelle cliniche, documenti amministrativi, informative privacy, pagamenti e dati delle sedute: tutto ciò che avviene all’interno del tuo studio è gestito da un unico gestionale, in ogni piccolo dettaglio.',
        svg: checklist,
        app: ['osteo', 'physio'],
    },
    {
        img: semplicita02,
        titolo: 'Semplicità',
        descrizione: 'Usare Physio Archive è semplice, per te come per i tuoi pazienti: l’interfaccia intuitiva consente un utilizzo chiaro e veloce del gestionale, guidandoti passo dopo passo in ogni momento della tua giornata lavorativa.',
        svg: hand,
        app: ['osteo', 'physio'],
    },
]

export default jsonFunzioniLanding.filter((elemento) => {
    return elemento.app.includes(getAppUtilizzata())
})
