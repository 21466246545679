import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Icon from '@mui/material/Icon'
import breakpoints from 'assets/theme/base/breakpoints'
import { getLinkSingolaLesione } from 'getter/getterLesioni'
import { getIdLesioneSelezionata } from 'getter/getterLesioni'
import { getTabDisfunzioneSelezionata } from 'getter/getterLesioni'
import MDBox from 'components/MDBox'
import { APRI_POP_UP_MODIFICHE_NON_SALVATE } from 'data/azioni/APRI_POP_UP_MODIFICHE_NON_SALVATE'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import MDButton from 'components/MDButton'
import { isNavigazioneDisunzioneDaVedere } from 'getter/getterLesioni'

export const elementoNavigazioneDisfunzione = (props) => {
    const [tabsOrientation, setTabsOrientation] = useState('horizontal')
    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm ? setTabsOrientation('vertical') : setTabsOrientation('horizontal')
        }

        /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
        window.addEventListener('resize', handleTabsOrientation)

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleTabsOrientation)
    }, [tabsOrientation])

    const valoreCambiato = (e, valore) => {
        if (props.modificheNonSalvate) {
            APRI_POP_UP_MODIFICHE_NON_SALVATE(props.link[valore].link)
            return
        }
        props.navigate(props.link[valore].link)
    }

    if (!props.vediNavigazione) {
        return ''
    }

    return (
        <MDBox borderRadius='lg' shadow='lg' bgColor='white' p={2} mb={2} display='flex' justifyContent='space-between' flexDirection={{ xs: 'column', md: 'row' }}>
            <Tabs orientation={tabsOrientation} value={props.selezionato} onChange={(e, valore) => valoreCambiato(e, valore)}>
                {props.link.map((link, index) => {
                    return <Tab label={link.testo} key={index} />
                })}
            </Tabs>
            <MDButton size='small' variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'pdf-disfunzione', parametro: '' })}>
                scarica pdf
                <Icon sx={{ ml: 1 }}>download</Icon>
            </MDButton>
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        vediNavigazione: isNavigazioneDisunzioneDaVedere(),
        link: getLinkSingolaLesione(getIdLesioneSelezionata()),
        selezionato: getTabDisfunzioneSelezionata(),
        modificheNonSalvate: state.reducerStatoModifica.modificheNonSalvate,
    }
}

export default connect(mapStateToProps, null)(elementoNavigazioneDisfunzione)
