import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import VediForm from 'formMaker/vediForm'
import { formCredenzialiPaziente } from 'formModel/formCredenzialiPaziente'
import { GENERA_CREDENZIALI } from 'data/azioni/GENERA_CREDENZIALI'
import { getIdPaziente } from 'getter/getterPaziente'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { AGGIORNA_AUTENTICAZIONE_UTENTE } from 'data/azioni/AGGIORNA_AUTENTICAZIONE_UTENTE'

export default function popUpGeneraCredenziali(props) {
    const [form, setForm] = useState(formCredenzialiPaziente())

    useEffect(() => {
        if (props.aperto && props.parametro.controlloMailUtente) {
            var nuovoForm = Object.assign({}, formCredenzialiPaziente())
            nuovoForm.parts[0].details.idUtente = props.parametro.id
            nuovoForm.parts[0].validazione = 'controlloMailUtente'
            setForm(nuovoForm)
        }
    }, [props.aperto])

    const handleClose = () => {
        props.chiudi()
    }

    const scegliAzione = async (e) => {
        if (props.parametro.id === undefined || props.parametro === null || props.parametro.id === "") {
            return await GENERA_CREDENZIALI(e, getIdPaziente())
        }
        return await AGGIORNA_AUTENTICAZIONE_UTENTE(props.parametro.id, e)
    }

    const salva = async (e) => {
       
        await scegliAzione(e)
        handleClose()
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Credenziali generate' } })
    }

    //console.log('form : ', form)

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Genera credenziali</DialogTitle>
            <DialogContent dividers={true}>
                <VediForm vista='input' form={form} dati={{}} annulla={() => handleClose()} salva={(e) => salva(e)} />
            </DialogContent>
        </Dialog>
    )
}
