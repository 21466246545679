import React, { useEffect, useState } from 'react'
import IllustrationLayout from 'layouts/authentication/components/IllustrationLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import bgImage from 'assets/images/svgLogoPhysioBianco.svg'
import { Link } from 'react-router-dom'
import { InputText } from 'fields/fieldText'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import * as validator from './../../fields/validator'
import { CREA_AMMINISTRAZIONE_CLINICA } from 'data/azioni/CREA_AMMINISTRAZIONE_CLINICA'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export default function paginaRegistrati(props) {
    const [step, setStep] = useState(0)
    const steps = ['Partita iva', 'crededenziali']
    const [partitaIva, setPartitaIva] = useState('')
    const [dati, setDati] = useState({})
    const [errore, setErrore] = useState('')

    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')
    const [confermaPassword, setConfermaPassword] = useState('')
    const [erroreMail, setErroremail] = useState('')
    const [errorePassword, setErrorePassword] = useState('')
    const [erroreConfermaPassword, setErroreConfermaPassword] = useState('')

    const form = {
        parts: [
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'Email:',
                value: '',
                details: {
                    type: 'text',
                    costumCssOutput: ['labelBold'],
                    nascondiLabel: true,
                },
                validazione: 'emailGiaPresente',
            },
            {
                id: 'password',
                tipo: 'fieldText',
                associazione: 'password',
                label: 'Password:',
                value: '',
                details: {
                    type: 'password',
                    costumCssOutput: ['labelBold'],
                    nascondiLabel: true,
                },
                validazione: 'lunghezzaMinima',
            },
            {
                id: 'confermaPassword',
                tipo: 'fieldText',
                associazione: 'confermaPassword',
                label: 'Conferma password:',
                value: '',
                details: {
                    type: 'password',
                    costumCssOutput: ['labelBold'],
                    nascondiLabel: true,
                },
            },
        ],
    }

    const formIva = {
        id: 'partiaIva',
        tipo: 'fieldText',
        associazione: 'partiaIva',
        label: 'partita iva :',
        value: '',
        details: {
            type: 'text',
            costumCssOutput: ['labelBold'],
        },
        validazione: 'controlloPartitaIva',
    }

    useEffect((e) => {
        window.addEventListener('partitaIvaRiconosciuta', (e) => {
            console.log('eventooooooo')
            const nuoviDati = {
                indirizzo: e.detail.data.company_address,
                nome: e.detail.data.company_name,
                partiaIva: e.detail.data.vat_number,
            }
            setDati(nuoviDati)
        })
        APRI_POP_UP({ id: 'guida', parametro: '' })
    }, [])

    const validazioneConfermaPassword = () => {
        return new Promise((resolve, reject) => {
            const discriminante = confermaPassword === password
            if (discriminante) {
                resolve()
            } else {
                reject('Le password inserite non coincidono')
            }
        })
    }

    const login = () => {
        const auth = getAuth()
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            signInWithEmailAndPassword(auth, mail, password)
        }, 4000)
    }

    const salva = async () => {
        let errroeValidazione = false
        setErroremail('')
        setErrorePassword('')
        setErroreConfermaPassword('')
        try {
            await validator[form.parts[0].validazione](mail)
        } catch (error) {
            errroeValidazione = true
            setErroremail(error)
        }
        try {
            await validator[form.parts[1].validazione](password)
        } catch (error) {
            errroeValidazione = true
            setErrorePassword(error)
        }
        try {
            await validazioneConfermaPassword()
        } catch (error) {
            errroeValidazione = true
            setErroreConfermaPassword(error)
        }

        if (!errroeValidazione) {
            let datiDaPassare = Object.assign(dati, { email: mail, password: password })
            await CREA_AMMINISTRAZIONE_CLINICA(datiDaPassare)
            APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Profilo creato', preLogin: true } })
            //props.navigate('/login')
            login()
        }
    }

    const renderBottoni = () => {
        /*  if (step === 0) {
            return (
                <React.Fragment>
                    <MDButton color='error' onClick={() => props.navigate('/')}>
                        annulla
                    </MDButton>
                    <MDButton color='primary' onClick={() => validaIva()}>
                        avanti
                    </MDButton>
                </React.Fragment>
            )
        } */
        return (
            <React.Fragment>
                <MDButton color='error' onClick={() => props.navigate('/')}>
                    annulla
                </MDButton>
                {/* <MDButton color='primary' onClick={() => setStep(step - 1)}>
                    indietro
                </MDButton> */}
                <MDButton color='success' onClick={() => salva()}>
                    salva
                </MDButton>
            </React.Fragment>
        )
    }

    const validaIva = async () => {
        setErrore('')
        setDati({})
        try {
            await validator[formIva.validazione](partitaIva)
            setStep(step + 1)
        } catch (error) {
            setErrore(error)
        }
    }

    const renderIva = () => {
        return <InputText model={formIva} value={partitaIva} errore={errore} azione={(a, b) => setPartitaIva(b)} />
    }

    const renderCredenziali = () => {
        return (
            <form>
                <MDBox mb={2}>
                    <InputText model={form.parts[0]} value={mail} errore={erroreMail} azione={(a, b) => setMail(b)} />
                </MDBox>
                <MDBox mb={2}>
                    <InputText model={form.parts[1]} value={password} errore={errorePassword} azione={(a, b) => setPassword(b)} />
                </MDBox>
                <MDBox mb={2}>
                    <InputText model={form.parts[2]} value={confermaPassword} errore={erroreConfermaPassword} azione={(a, b) => setConfermaPassword(b)} />
                </MDBox>
            </form>
        )
    }

    return (
        <IllustrationLayout title='Registrati' description='Inserisci i dati richiesti per creare il tuo profilo di amministrazione.' illustration={bgImage}>
            <MDBox>
                {/*  <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MDBox mt={2}>
                    {step === 0 ? renderIva() : renderCredenziali()}
                    <div className='contenitore-bottoni-destra'>{renderBottoni()}</div>
                </MDBox> */}
                {renderCredenziali()}
                <div className='contenitore-bottoni-destra'>{renderBottoni()}</div>
            </MDBox>
            {/*  <MDBox width='100%' mt={2}>
                <video width='100%' height='auto' src='https://www.youtube.com/embed/oCGDJeTJfw0'></video>
                <iframe width='100%' height='auto' src='https://www.youtube.com/embed/oCGDJeTJfw0' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen={true}></iframe>
            </MDBox> */}
        </IllustrationLayout>
    )
}
//11654180014
