import { getFatturePaziente } from 'getter/getterPaziente'
import React from 'react'
import { connect } from 'react-redux'
import { Divider, Grid, List } from '@mui/material'
import ElementoMostraFattura from 'componenti/elementoMostraFattura'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { getIdPaziente } from 'getter/getterPaziente'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import { getPermessiUtente } from 'getter/getterPermessi'
import ElementoMostraFatturaPaziente from 'componenti/elementoMostraFatturaPaziente'
import { getSkeleton } from 'getter/getterPaziente'

export const paginaFatturePaziente = (props) => {
    const renderFab = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => APRI_POP_UP({ id: 'fattura', parametro: { idFattura: '', idPaziente: getIdPaziente() } })}>
                <AddIcon />
            </Fab>
        )
    }

    const renderFatture = () => {
        if (props.skeleton) {
            return <MDTypography color='info'>Caricamento fatture in corso...</MDTypography>
        }
        if (props.lista.length === 0) {
            return <MDTypography color='error'>Nessuna fattura creata</MDTypography>
        }
        if (!props.permessi) {
            return (
                <List>
                    {props.lista.map((fattura, index) => {
                        if (fattura.contenuto.stato === 'creata') {
                            return ''
                        }
                        return (
                            <React.Fragment key={fattura.id}>
                                <Grid key={fattura.id} item>
                                    <ElementoMostraFatturaPaziente fattura={fattura} />
                                </Grid>
                                {index !== props.lista.length - 1 ? <Divider /> : ''}
                            </React.Fragment>
                        )
                    })}
                </List>
            )
        }
        return (
            <Grid container direction='column'>
                {renderFab()}
                {props.lista.map((fattura, index) => {
                    return (
                        <React.Fragment key={fattura.id}>
                            <Grid key={fattura.id} item>
                                <ElementoMostraFattura fattura={fattura} index={index}/>
                            </Grid>
                            {index !== props.lista.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </Grid>
        )
    }

    return (
        <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <CreditCardRoundedIcon color='white' sx={{ mr: 1 }} />
                <MDTypography color={'white'} variant='h5'>
                    Fatture paziente
                </MDTypography>
            </MDBox>
            {renderFatture()}
            {renderFab()}
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        lista: getFatturePaziente(),
        permessi: getPermessiUtente().fatturePaziente.input,
        skeleton : getSkeleton()
    }
}

export default connect(mapStateToProps, null)(paginaFatturePaziente)
