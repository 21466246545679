import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { OutputList } from './fieldList'
import { getFarmaciPaziente } from 'getter/getterPaziente'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import FormOutput from 'formMaker/formOutput'
import MDTypography from 'components/MDTypography'
import DataTable from './../examples/Tables/DataTable'
import DefaultCell from './../layouts/ecommerce/products/product-page/components/DefaultCell'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove'
import colors from 'assets/theme/base/colors'
import { APRI_POP_UP_FIELD_LIST } from 'data/azioni/POP_UP_FIELD_LIST'

const subInputFarmaciUtilizzati = {
    costumCssInput: ['formCambiata'],
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome farmaco',
            value: '',
            details: {
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'dataFine',
            tipo: 'fieldData',
            associazione: 'dataFine',
            label: 'Data fine',
            value: '',
            details: {},
        },
        {
            id: 'disfunzione',
            tipo: 'fieldText',
            associazione: 'disfunzione',
            label: 'Disfunzione',
            value: '',
            details: {
                noInput: true,
                costumCssOutput: ['labelBold'],
            },
            validazione: 'testoRichiesto',
        },
    ],
}

const model = {
    id: 'farmaciUtilizzati',
    tipo: 'fieldList',
    associazione: 'farmaciUtilizzati',
    label: 'Farmaci Utilizzati:',
    value: [],
    details: {
        grid: { lg: { input: 4, output: 4 } },
        costumCssOutput: ['formOrizzontale', 'labelBold'],
        subModel: subInputFarmaciUtilizzati,
    },
}

export const InputFarmaciStoriaClinica = (props) => {
    const [focus, setFocus] = useState(false)
    const [listaFarmaciLesioni, setListaFarmaciLesioni] = useState([])

    useEffect(() => {
        setListaFarmaciLesioni(getFarmaciPaziente())
    }, [])

    const getColore = () => {
        if (focus) {
            return colors.primary.main
        }
        return props.errore !== '' ? colors.error.main : colors.inputBorderColor
    }

    const getSubmodel = () => {
        var submodel = Object.assign({}, subInputFarmaciUtilizzati)
        submodel.parts = [submodel.parts[0], submodel.parts[1]]
        return submodel
    }

    const popUpChiuso = (dati, indice) => {
        let nuovaLista = [...props.value]
        if (indice === -1) {
            nuovaLista.push(dati)
            props.azione(props.model.id, nuovaLista)
            return
        }
        nuovaLista[indice] = dati
        props.azione(props.model.id, nuovaLista)
        return
    }

    let modifica = (indice, valore) => {
        if (indice === -1) {
            APRI_POP_UP_FIELD_LIST({ index: indice, model: getSubmodel(), dati: {}, chiuso: popUpChiuso, titolo: 'Aggiungi elemento' })
            return
        }
        let nuovoStato = getSubmodel().parts.reduce((acc, model) => {
            acc[model.id] = valore[model.id]

            return acc
        }, {})
        APRI_POP_UP_FIELD_LIST({ index: indice, model: getSubmodel(), dati: nuovoStato, chiuso: popUpChiuso, titolo: 'Aggiungi elemento' })
    }

    let elimina = (indice) => {
        let nuovaLista = [...props.value]
        nuovaLista.splice(indice, 1)
        props.azione(props.model.id, nuovaLista)
    }

    let fab = (indice, valore) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6} lg={6}>
                    <Fab color='primary' size='small' aria-label='modifica' onClick={() => modifica(indice, valore)}>
                        <EditIcon />
                    </Fab>
                </Grid>
                <Grid item xs={6} lg={6}>
                    <Fab color='primary' size='small' aria-label='cancella' onClick={() => elimina(indice)}>
                        <RemoveIcon />
                    </Fab>
                </Grid>
            </Grid>
        )
    }

    let colonneTabella = subInputFarmaciUtilizzati.parts.reduce(
        (acc, model) => {
            acc.push({ Header: model.label, accessor: model.label })
            return acc
        },
        [{ Header: 'azioni', accessor: 'azioni' }],
    )

    const creaRigheTabella = (lista, azioni) => {
        return lista.map((valore, indiceValori) => {
            return colonneTabella.reduce((acc, colonna) => {
                if (colonna.Header === 'azioni') {
                    acc[colonna.Header] = <DefaultCell>{azioni ? fab(indiceValori, valore) : ''}</DefaultCell>
                }
                if (colonna.Header !== 'azioni') {
                    let indiceModel = subInputFarmaciUtilizzati.parts.findIndex((el) => {
                        return el.label === colonna.Header
                    })
                    let model = subInputFarmaciUtilizzati.parts[indiceModel]
                    let valoreDaVedere = { [model.id]: valore[model.id] }
                    model.details.noLabel = true
                    acc[colonna.Header] = (
                        <DefaultCell>
                            <FormOutput form={{ parts: [model] }} dati={valoreDaVedere} isSubInput={true} />
                        </DefaultCell>
                    )
                }
                return acc
            }, {})
        })
    }

    let righeTabella = creaRigheTabella(props.value, true).concat(creaRigheTabella(listaFarmaciLesioni, false))

    return (
        <MDBox sx={{ border: 'solid 0.1px', color: getColore() }} p={2} borderRadius='lg' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
            <MDBox mt={-5.3} bgColor='white' p={0.5} width={'max-content'}>
                <MDTypography gutterBottom variant='caption' sx={{ color: getColore() }}>
                    {props.model.label}
                </MDTypography>
            </MDBox>
            {/* <PopUpFieldList index={index} model={props.model.details.subModel} aperto={aperto} dati={dati} chiuso={(dati, aggiungi) => popUpChiuso(dati, aggiungi)} titolo={'Aggiungi elemento'} /> */}

            <DataTable table={{ columns: colonneTabella, rows: righeTabella }} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} />
            <div className='contenitore-bottoni-destra'>
                <MDButton color='primary' onClick={() => modifica(-1)}>
                    aggiungi
                </MDButton>
            </div>
            <MDTypography variant='caption' color='error'>
                {props.errore}
            </MDTypography>
        </MDBox>
    )
}

export const OutputFarmaciStoriaClinica = (props) => {
    const [listaFarmaci, setListaFarmaci] = useState([])

    const aggiornaLista = () => {
        const nuovaLista = [...props.value].concat([...props.farmaci])
        setListaFarmaci(nuovaLista)
    }

    useEffect(() => {
        aggiornaLista()
    }, [props.value, props.farmaci])

    return <OutputList value={listaFarmaci} model={model} />
}

function mapStateToProps(state) {
    return {
        farmaci: getFarmaciPaziente(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OutputFarmaciStoriaClinica)
