import { store } from './../store'

export const APRI_POP_UP_MODIFICHE_NON_SALVATE = (link) => {
    return new Promise((resolve, reject) => {
        
        store.dispatch({
            type: 'APRI_POP_UP_MODIFICHE_NON_SALVATE',
            payload: link,
        })
        resolve()
    })
}
