import { getUtenteLoggato } from '../getter/getterUtenteLoggato'
import { getPresidio } from './strumenti'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { ref, getDownloadURL } from 'firebase/storage'
import { doc, getDoc } from 'firebase/firestore'

pdfMake.vfs = pdfFonts.pdfMake.vfs

let creaFooter = (presidio) => {
    let footerDaRitornare = {
        layout: 'noBorders',
        table: {
            body: [
                [
                    {
                        margin: [5, 3, 5, 3],
                        text: `Pagamento effettuabile anche al seguente IBAN: ${presidio.iban} intestato a ${presidio.nome}`,
                    },
                ],
            ],
        },
        style: 'footer',
    }

    return footerDaRitornare
}

let paziente = (paziente) => {
    return [paziente.nome, paziente.indirizzo, paziente.cf]
}

const bold = (testo) => {
    return { text: testo, style: 'parolaBold' }
}

let creaStile = () => {
    return {
        header: {
            fontSize: 9,
            bold: true,
            margin: [0, -70, 0, 15],
            alignment: 'right',
        },
        testo: {
            fontSize: 9,
            margin: [0, 0, 0, 7],
            alignment: 'justify',
        },
        testo2: {
            fontSize: 11,
            margin: [0, 0, 0, 7],
            alignment: 'justify',
        },
        testoDestra: {
            fontSize: 9,
            margin: [0, 0, 0, 7],
            alignment: 'right',
        },
        testoSinistra: {
            fontSize: 9,
            margin: [0, 0, 0, 7],
            alignment: 'left',
        },
        footer: {
            fontSize: 9,
            alignment: 'center',
            margin: [10, 20, 10, 20],
            bold: true,
        },
    }
}

let footerSecondaPagina = (presidio) => {
    return {
        text: `${bold(presidio.nome).text}-${presidio.indirizzo}-${presidio.comune} (${presidio.provincia}) ${presidio.CAP}-${presidio.telefonoFisso}-${presidio.telefonoCellulare}
        ${presidio.web}-${presidio.mail}-${presidio.partiaIva}-${presidio.cf}`,
        style: 'testo',
    }
}

let generaintestazione = (presidio) => {
    return {
        text: `${presidio.nome}
        ${presidio.indirizzo} - ${presidio.comune} (${presidio.provincia}) - ${presidio.CAP}
        Tel. ${presidio.telefonoFisso}  Cell. ${presidio.telefonoCellulare}
        e-mail: ${presidio.mail}  Web: ${presidio.web}
        Partita Iva: ${presidio.partiaIva}             C.F.:  ${presidio.cf}`,
        style: 'header',
    }
}

let generaInfo = (paziente, dati) => {
    return {
        margin: [0, 100, 0, 0],
        headerRows: 1,
        table: {
            widths: ['auto', 'auto', '*'],
            body: [
                ['Nota di credito n.', 'data', 'Paziente'],
                [dati.numero, dati.data, [`${paziente.nome} ${paziente.cognome}`, `Residenza: ${paziente.indirizzo}`, `Comune: ${paziente.comune}`, `CAP: ${paziente.cap}`, `Codice Fiscale: ${paziente.cf}`]],
            ],
        },
        layout: 'lightHorizontalLines',
    }
}

let voce = (testo, importo, posizione) => {
    return [
        {
            text: testo,
            style: posizione,
        },
        {
            text: `${importo.toFixed(2)} €`,
            style: 'testoDestra',
        },
    ]
}

let generaFattura = (vociFattura, fattura, marca) => {
    let voci = vociFattura.map((elem, index) => {
        let testo = `a parziale storno fattura N. ${fattura.datiFattura.numFattura} del ${fattura.datiFattura.date} per errata fatturazione `
        return voce(testo, elem.importo, 'testoSinistra')
    })
    voci.unshift(['Descrizione', 'Importo'])
    let totale = vociFattura.reduce((acc, elem) => {
        return acc + elem.importo
    }, 0)

    /* if (marca) {
        voci.push(voce('Totale Voci', totale, 'testoDestra'))
        voci.push(voce('Marca da Bollo', 2, 'testoDestra'))
        voci.push(voce('Totale Fattura', totale + 2, 'testoDestra'))
    } else {
        voci.push(voce('Totale Fattura', totale, 'testoDestra'))
    } */
    voci.push(voce('Totale Fattura', totale, 'testoDestra'))
    return {
        margin: [0, 100, 0, 0],
        headerRows: 1,
        table: {
            widths: ['*', 'auto'],
            body: voci,
        },
        layout: 'lightHorizontalLines',
    }
}

let getUrl = async (logo) => {
    let path = `loghi/${getPresidio(getUtenteLoggato())}/logo.jpg`
    if (!logo) {
        path = `loghi/physio/logoDue.png`
    }
    let url = getDownloadURL(ref(storage, path))
    return url
}

let getBase64ImageFromURL = async (url) => {
    return new Promise((resolve, reject) => {
        var img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = () => {
            var canvas = document.getElementById('canvas')
            canvas.width = img.width
            canvas.height = img.height
            var ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            var dataURL = canvas.toDataURL('image/jpg')
            resolve(dataURL)
        }

        img.src = url
    })
}

let getImmagine = async (presidio) => {
    return {
        image: await getBase64ImageFromURL(await getUrl(presidio.logo || false)),
        fit: [100, 100],
    }
}

export const generaPdfNota = async (rimborso, presidio, fattura) => {
    let paziente = await getPaziente(fattura.idPaziente)
    let docDefinition = {
        content: [await getImmagine(presidio), generaintestazione(presidio), generaInfo(paziente, rimborso), generaFattura(rimborso.voci, fattura), creaFooter(presidio)],
        styles: creaStile(),
    }

    let blob = await prendiBlob(docDefinition)

    return blob
}

let getPaziente = async (id) => {
    let paziente = await getDoc(doc(db, 'utenti', id))
    return paziente.data()
}

let prendiBlob = (docDefinition) => {
    return new Promise((resolve, reject) => {
        pdfMake.createPdf(docDefinition).getBlob((blob) => {
            resolve(blob)
        })
    })
}
