import { doc, updateDoc, Timestamp } from 'firebase/firestore'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const MODIFICA_APPUNTAMENTO = async (id, dati) => {
    //await updateDoc(doc(db, 'appuntamenti', id), dati)
    dati.giorno = Timestamp.fromDate(dati.giorno)
    dati.inizio = Timestamp.fromDate(dati.inizio)
    dati.fine = Timestamp.fromDate(dati.fine)
    await CHIAMA_SERVER('modificaAppuntamento', { id: id, datiAppuntamento: dati })
    return
}
