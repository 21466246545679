import { Grid, Icon, Skeleton } from '@mui/material'
import React from 'react'
import MDBox from 'components/MDBox'
import VediForm from 'formMaker/vediForm'
import { connect } from 'react-redux'
import { AGGIORNA_DATI_ESERCIZIO } from 'data/azioni/AGGIORNA_DATI_ESERCIZIO'
import MDTypography from 'components/MDTypography'
import { ELIMINA_ESERCIZIO } from 'data/azioni/ELIMINA_ESERCIZIO'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import ElementoMostraPazienti from 'componenti/elementoMostraPazienti'
import Card from '@mui/material/Card'
import ElementoVediFoto from 'componenti/elementoVediFoto'
import ElementoAggiungiFile from 'componenti/elementoAggiungiFile'
import { getEsercizioSelezionato } from 'getter/getterEsercizi'
import { getIdEsercizioSelezionato } from 'getter/getterEsercizi'
import { isEsercizioDaEliminare } from 'getter/getterEsercizi'
import { getListaUtilizzi } from 'getter/getterEsercizi'
import { getListaFileEsercizio } from 'getter/getterEsercizi'
import { useEffect } from 'react'
import { useState } from 'react'
import { ref, getDownloadURL } from 'firebase/storage'
import { getSkeletonEsercizioSelezionato } from 'getter/getterEsercizi'
import { getPermessiUtente } from 'getter/getterPermessi'
import { getFormPaginaEsercizio } from 'getter/getterEsercizi'
import { SALVA_FILE_ESERCIZIO } from 'data/azioni/SALVA_FILE_ESERCIZIO'

export function paginaVediEsercizio(props) {
    const [listaUrl, setListaUrl] = useState([])

    const getUrl = async (file) => {
        const path = `esercizi/${props.idEsercizio}/${file.id}.png`
        const url = await getDownloadURL(ref(storage, path))
        return url
    }

    useEffect(async () => {
        const nuoviUrl = await Promise.all(
            props.listaFile.map((file) => {
                return getUrl(file)
            }),
        )
        setListaUrl(nuoviUrl)
    }, [props.listaFile])

    const eliminaEsercizio = () => {
        APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di voler eliminare l'esercizio?`, azione: () => ELIMINA_ESERCIZIO(props.idEsercizio) } })
    }

    const renderElimina = () => {
        return (
            <React.Fragment>
                {/* <MDBox onClick={() => eliminaEsercizio()} sx={{ cursor: 'pointer' }} display='flex' justifyContent='center' alignItems='center' width='3rem' height='3rem' bgColor='error' color='white' shadow='md' borderRadius='xl' ml='auto' mr={1}>
                    <Icon>delete</Icon>
                </MDBox> */}
                <MDBox lineHeight={0} ml={2} display='flex' flexDirection='row'>
                    <MDTypography color={'dark'} variant='h4'>
                        Esercizio non assegnato.
                    </MDTypography>
                    <MDTypography sx={{ cursor: 'pointer' }} ml={2} variant='h4' onClick={() => eliminaEsercizio()} color='error' fontWeight='medium'>
                        Elimina
                    </MDTypography>
                </MDBox>
            </React.Fragment>
        )
    }

    const renderListaPazienti = () => {
        return <ElementoMostraPazienti lista={props.listaPazienti} navigate={props.navigate} />
    }

    const salva = async (e) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        await AGGIORNA_DATI_ESERCIZIO(props.idEsercizio, e)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    }

    const salvaImmagine = async (file, nome) => {
        await SALVA_FILE_ESERCIZIO(file, getIdEsercizioSelezionato())
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'File Caricato' } })
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        APRI_POP_UP
    }

    const renderSkeleton = () => {
        return <Skeleton animation='wave' height='80px' />
    }

    const renderPazienti = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <MDBox mt={8} mb={2}>
                <MDBox mb={1} ml={2}>
                    <MDTypography variant='h5' fontWeight='medium' mb={2}>
                        Pazienti con esercizio assegnato
                    </MDTypography>
                </MDBox>
                {props.skeleton ? renderSkeleton() : props.possoEliminare ? renderElimina() : renderListaPazienti()}
            </MDBox>
        )
    }

    const form = getFormPaginaEsercizio()

    return (
        <MDBox py={3} sx={{ height: '100%' }}>
            <Card sx={{ overflow: 'visible', height: '100%' }}>
                <MDBox p={3} sx={{ height: '100%' }}>
                    <Grid container spacing={3} sx={{ height: '100%' }}>
                        <Grid item xs={12} lg={6} xl={5} sx={{ height: '100%' }}>
                            <MDBox mb={3} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                <MDTypography variant='h5' fontWeight='medium' mr={2}>
                                    Lista Immagini
                                </MDTypography>
                                {props.permessi ? <ElementoAggiungiFile isEsercizio={true} salva={(file, nome) => salvaImmagine(file, nome)} /> : ''}
                            </MDBox>
                            <ElementoVediFoto immagini={listaUrl} skeleton={props.skeleton} />
                        </Grid>
                        <Grid item xs={12} lg={5} sx={{ mx: 'auto', height: '100%' }}>
                            <MDBox mb={3} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                <MDTypography variant='h5' fontWeight='medium'>
                                    Dettagli esercizio
                                </MDTypography>
                            </MDBox>
                            <MDBox borderRadius='lg' p={2} sx={{ display: 'flex', height: '100%', width: '100%' }}>
                                <VediForm noBottoni={!props.permessi} skeleton={props.skeleton} form={form} dati={props.esercizio} salva={(e) => salva(e)} />
                            </MDBox>
                        </Grid>
                    </Grid>

                    {renderPazienti()}
                </MDBox>
            </Card>
        </MDBox>
    )

    return (
        <Grid container direction='column' justifyContent='space-between' alignItems='stretch' spacing={3}>
            <Grid item>
                <MDBox bgColor='white' borderRadius='lg' shadow='lg' p={2}>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                        <MDTypography color={'white'} variant='h5'>
                            Dettagli esercizio
                        </MDTypography>
                    </MDBox>
                    <VediForm form={formDettagliEsercizi()} dati={props.esercizio} salva={(e) => salva(e)} />
                </MDBox>
            </Grid>
            <Grid item>
                <MDBox bgColor='white' borderRadius='lg' shadow='lg' p={2}>
                    {props.esercizio.utilizzi === undefined || Object.keys(props.esercizio.utilizzi).length === 0 ? renderElimina() : renderListaPazienti()}
                </MDBox>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    const permessi = getPermessiUtente().libreriaEsercizi.input
    let listaPazienti = []
    if (permessi) {
        listaPazienti = getListaUtilizzi()
    }

    return {
        listaFile: getListaFileEsercizio(),
        esercizio: getEsercizioSelezionato(),
        idEsercizio: getIdEsercizioSelezionato(),
        possoEliminare: isEsercizioDaEliminare(),
        listaPazienti: listaPazienti,
        skeleton: getSkeletonEsercizioSelezionato(),
        permessi: permessi,
    }
}

export default connect(mapStateToProps, null)(paginaVediEsercizio)
