import { trasformaArrayInOggetto } from 'data/strumenti'
import { trasformaOggettoInArray } from 'data/strumenti'
import { getNomePazienteDaId } from './getterPaziente'

const getTuttiAppuntamenti = () => {
    var appuntamenti = []

    trasformaOggettoInArray(window.appuntamenti).forEach((mese) => {
        mese.contenuto.forEach((appuntamento) => {
            appuntamenti.push(appuntamento)
        })
    })

    return appuntamenti
}

export const getAppuntamenti = () => {
    return getTuttiAppuntamenti().map((appuntamento) => {
        return {
            title: appuntamento.contenuto.nomePaziente || getNomePazienteDaId(appuntamento.contenuto.idPaziente),
            allDay: false,
            start: appuntamento.contenuto.inizio.toDate(),
            end: appuntamento.contenuto.fine.toDate(),
            color: 'default',
            descrizione: appuntamento.contenuto.descrizione,
            id: appuntamento.id,
            idDottore: appuntamento.contenuto.idDottore,
        }
    })
}

export const getAppuntamentoDaId = (id) => {
    console.log('idddddddddd : ', id);
    console.log('wwww : ',  trasformaArrayInOggetto(getTuttiAppuntamenti()));
    return trasformaArrayInOggetto(getTuttiAppuntamenti())[id]
}

export const getAppuntamentiFiltrati = (id) => {
    return getAppuntamenti().filter((appuntamento) => {
        return appuntamento.idDottore === id
    })
}
