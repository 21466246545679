import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getDipendente, getProfiloDipendente, isAttivo } from '../../getter/getterDipendenti'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const MODIFICA_STATO_ATTIVO_DIPENDENTE = (id) => {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))

        let daPassare = {
            idUtente: id,
            datiUtente: Object.assign(getDipendente(), { attivo: !isAttivo(id) }),
            profilo: getProfiloDipendente(),
            idPresidio : getPresidio(getVeroUtenteLoggato())
        }

        CHIAMA_SERVER(`aggiornaUtente`, daPassare).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
