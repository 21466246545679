import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import breakpoints from 'assets/theme/base/breakpoints'
import ElementoChartDolori from 'componenti/elementoChartDolori'
import MDButton from 'components/MDButton'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MDBox from 'components/MDBox'
import VediForm from 'formMaker/vediForm'
import ElementoDisegnaDolore from 'componenti/elementoDisegnaDolore'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpAggiungiDolore(props) {
    const form = {
        parts: [
            {
                id: 'tipologia',
                tipo: 'fieldSceltaMultipla',
                associazione: 'tipologia',
                label: 'tipologia:',
                value: [],
                details: {
                    scelte: ['meccanico', 'infiammatorio', 'neurogenico', 'centrale'],
                    costumCss: ['tagModificatiSedute'],
                    complex: false,
                },
            },
            {
                id: 'intensita',
                tipo: 'fieldSlider',
                associazione: 'intensita',
                label: 'Intensità',
                value: 0,
                details: {
                    vediInput: true,
                    min: 0,
                    max: 10,
                },
            },
        ],
    }
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }
    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)
    const [pointer, setPointer] = useState({})
    const [distretto, setDistretto] = useState('')
    const [dati, setDati] = useState({})
    const [step, setStep] = useState(0)
    const [datiImmagine, setDatiImmagine] = useState([])
    const steps = ['Seleziona distretto', 'Disegna dolore', 'Informazioni dolore']

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
            //window.innerWidth < breakpoints.values.sm ? setOpzioni(opzioniMobile) : setOpzioni(opzioniStandard)
        })
    }, [])

    useEffect(() => {
        setPointer(props.pointer)
        setDistretto(props.distretto)
    }, [props.pointer])

    useEffect(() => {
        if (Object.keys(props.dolore).length !== 0 && props.aperto) {
            setPointer(props.dolore.pointer)
            setDistretto(props.dolore.distretto)
            setDatiImmagine(props.dolore.listaDisegni || [])
            setDati({ intensita: props.dolore.intensita, tipologia: props.dolore.tipologia })
        }
        if (!props.aperto) {
            setStep(0)
        }
    }, [props.aperto])

    const handleClose = () => {
        props.chiudi()
    }

    const stepIndietro = (e) => {
        setDati(e)
        setStep(step - 1)
    }

    const salvaDolore = (e) => {
        const dolore = {
            pointer: pointer,
            intensita: e.intensita,
            distretto: distretto,
            tipologia: e.tipologia,
            listaDisegni: datiImmagine,
        }
        props.salva(dolore)
        setPointer({})
        setDistretto('')
        setDati({})
        setDatiImmagine([])
        setStep(0)
    }

    const aggiornaDatiImmagine = (dati) => {
        setDatiImmagine(dati)
        setStep(step + 1)
    }
    const indietroDisegno = (dati) => {
        setDatiImmagine(dati)
        setStep(step - 1)
    }

    const renderDati = () => {
        return <VediForm form={form} dati={dati} vista='input' isUltimoStep={true} annulla={() => props.chiudi()} indietro={(e) => stepIndietro(e)} salva={(e) => salvaDolore(e)} />
    }

    const renderDistretto = () => {
        return (
            <div className='fletti-verticalmente'>
                <ElementoChartDolori pointer={pointer} input={true} pointerGiaInseriti={[]} aggiornaDistretto={(e) => setDistretto(e)} aggiornaPointer={(e) => setPointer(e)} />
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='error' onClick={() => props.chiudi()}>
                        annulla
                    </MDButton>
                    <MDButton variant='contained' disabled={distretto === ''} color='primary' onClick={() => setStep(step + 1)}>
                        avanti
                    </MDButton>
                </div>
            </div>
        )
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return renderDistretto()
            case 1:
                return <ElementoDisegnaDolore datiImmagine={datiImmagine} indietro={(e)=> indietroDisegno(e)} chiudi={() => props.chiudi()} salva={(e) => aggiornaDatiImmagine(e)} />
            case 2:
                return renderDati()
            default:
                break
        }
    }

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Nuovo dolore</DialogTitle>
            <DialogContent dividers={true}>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label} completed={index < step}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <MDBox sx={{ margin: 'auto', marginTop: '35px' }} p={2}>
                    {renderStep()}
                </MDBox>
            </DialogContent>
        </Dialog>
    )
}
