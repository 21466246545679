import React from 'react'
import Dialog from '@mui/material/Dialog'
import { connect } from 'react-redux'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import { formCredenzialiPaziente } from 'formModel/formCredenzialiPaziente'
import { isAttivo } from 'getter/getterDipendenti'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import VediForm from 'formMaker/vediForm'
import { MODIFICA_STATO_ATTIVO_DIPENDENTE } from 'data/azioni/MODIFICA_STATO_ATTIVO_DIPENDENTE'
import { CHIAMA_SERVER } from 'data/azioni/CHIAMA_SERVER'

export function popUpGestioneChiosco(props) {
    const handleClose = () => {
        props.chiudi()
    }

    const salva = async (e) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        const daPassare = { idUtente: props.parametro, credenziali: e }
        await CHIAMA_SERVER(`aggiornaCredenzialiUtente`, daPassare)
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    }

    let model = formCredenzialiPaziente()
    model.parts[0].validazione = 'cambioMail'

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Gestione chiosco</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={3} direction='column'>
                    <Grid item container direction='row' justifyContent='space-between'>
                        <Grid item>
                            <MDTypography color='dark' variant='caption'>
                                {props.attivo ? 'Accesso abilitato' : 'Accesso disabilitato'}
                            </MDTypography>
                        </Grid>
                        <Grid item>
                            <MDButton color='primary' size='small' variant='contained' onClick={() => MODIFICA_STATO_ATTIVO_DIPENDENTE(props.parametro)}>
                                {props.attivo ? 'disabilita' : 'abilita'}
                            </MDButton>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <VediForm form={model} dati={props.dati} salva={(e) => salva(e)} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

function mapStateToProps(state, ownProps) {
    let attivo = false
    if (ownProps.parametro !== '') {
        attivo = isAttivo(ownProps.parametro)
    }
    return {
        dati: state.reducerCredenzialiChiosco,
        attivo: attivo,
    }
}

export default connect(mapStateToProps, null)(popUpGestioneChiosco)
