import { getPresidio } from 'data/strumenti'
import { getFiltriFatture } from 'getter/getterFatture'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { ref, getDownloadURL } from 'firebase/storage'

export const OTTIENI_ZIP_FATTURE = () => {
    return new Promise((resolve, reject) => {
        let { mesi, anno } = getFiltriFatture()
        const presidio = getPresidio(getVeroUtenteLoggato())
        const documento = `${anno}-${mesi}`
        //console.log('documentoooo : ', documento)

        CHIAMA_SERVER('ottieniZipFatture', { idPresidio: presidio, documento: documento }).then(() => {
            console.log('arrivo qua')
            const pathZip = `fatture/${presidio}/zip/fatture_${documento}.zip`
            console.log(pathZip)
            getDownloadURL(ref(storage, pathZip)).then((url) => {
                /* console.log('lho scaricato')
                console.log(url)
                window.open(url, '_blank') */

                var link = document.createElement('a')
                link.download = documento
                link.id = 'daRimuovere'
                link.href = url
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                resolve()
            })
        })
    })
}
