import React, { useEffect } from 'react'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import CloseIcon from '@mui/icons-material/Close'
import { CHIUDI_POP_UP } from 'data/azioni/CHIUDI_POP_UP'
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'

export default function ElementoAlert(props) {
    useEffect(() => {
        if (props.aperto) {
            setTimeout(() => {
                if (props.aperto) {
                    
                    CHIUDI_POP_UP()
                }
            }, 3000)
        }
    }, [props.aperto])

    const getIcona = () =>{
        switch (props.parametro.severity) {
            case 'success':
                return 'check'
            case 'info':
                return 'notifications'
            case 'warning':
                return 'star'
            case 'error':
                return 'warning'
        
            default:
                return 'check'
        }
    }

    if(!props.aperto){
        return ''
    }

    return (
        <MDSnackbar
            color={props.parametro.severity}
            icon={getIcona()}
            title="Physio archive"
            anchorOrigin={{ vertical: 'bottom', horizontal: props.parametro.preLogin ? 'left' : 'right' }}
            content={props.parametro.testo}
            dateTime=""
            open={props.aperto}
            onClose={()=>CHIUDI_POP_UP()}
            close={()=>CHIUDI_POP_UP()}
            
        />
    )










    return (
        <div className='alert'>
            <Collapse in={props.aperto}>
                <Alert
                    variant='filled'
                    severity={props.parametro.severity}
                    action={
                        <IconButton
                            aria-label='close'
                            color='inherit'
                            size='small'
                            onClick={() => {
                                props.chiudi()
                            }}>
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}>
                    {props.parametro.testo}
                </Alert>
            </Collapse>
        </div>
    )
}
