import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { LEGGI_ESERCIZIO } from './../azioni/LEGGI_ESERCIZIO'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherEsercizio extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        store.subscribe(() => {
            this.update()
        })
        this.update()
    }

    update() {
        if (store.getState().url[3] === 'vediEsercizio') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    apriConnessione(nuovoId) {
        store.dispatch({
            type: 'SVUOTA_REDUCER_ESERCIZIO',
        })
        this.funzioneConnessione = onSnapshot(doc(db, 'esercizi', nuovoId), (documento) => {
            LEGGI_ESERCIZIO(documento.data())
        })

      
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
