import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActions } from '@mui/material'
import { dataTestArticolari } from 'data/dataTestArticolari'
import MDBox from 'components/MDBox'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import MDTypography from 'components/MDTypography'
import ElementoTestArticolare from './elementoTestArticolare'
import { InputSlider } from 'fields/fieldSlider'
import MDButton from 'components/MDButton'

let modelSoloMax = {
    id: 'soloMax',
    tipo: 'fieldSlider',
    associazione: 'soloMax',
    label: 'AUTONOMO MAX :',
    value: '',
    details: {
        vediInput: true,
    },
}
let modelSoloMin = {
    id: 'soloMin',
    tipo: 'fieldSlider',
    associazione: 'soloMin',
    label: 'AUTONOMO MIN :',
    value: '',
    details: {
        vediInput: true,
    },
}
let modelAiutatoMax = {
    id: 'aiutatoMax',
    tipo: 'fieldSlider',
    associazione: 'aiutatoMax',
    label: 'ASSISTITO MAX:',
    value: '',
    details: {
        vediInput: true,
    },
}
let modelAiutatoMin = {
    id: 'aiutatoMin',
    tipo: 'fieldSlider',
    associazione: 'aiutatoMin',
    label: 'ASSISTITO MIN :',
    value: '',
    details: {
        vediInput: true,
    },
}

export function ElementoAssegnaTestArticolare(props) {
    let steps = ['Seleziona test', 'Test selezionato']
    const [step, setStep] = useState(0)
    const [testScelto, setTestScelto] = useState({})
    const [valori, setValori] = useState({})

    const isMinimoSottoZero = (test) => {
        if (step === 0) {
            return false
        }
        return test.angoli?.min < 0
    }

    const selezionato = (test) => {
        setTestScelto(test)
        setStep(step + 1)
        let valori = {}
        if (isMinimoSottoZero(test)) {
            valori = {
                solo: { min: 0, max: 0 },
                aiutato: { min: 0, max: 0 },
            }
        } else {
            valori = {
                solo: { max: 0 },
                aiutato: { max: 0 },
            }
        }
        modelSoloMax.details.min = test.angoli.min
        modelSoloMin.details.min = test.angoli.min
        modelAiutatoMax.details.min = test.angoli.min
        modelAiutatoMin.details.min = test.angoli.min
        modelSoloMax.details.max = test.angoli.max
        modelSoloMin.details.max = test.angoli.max
        modelAiutatoMax.details.max = test.angoli.max
        modelAiutatoMin.details.max = test.angoli.max
        setValori(valori)
    }

    let scelta = (
        <div className='scroll-verticale flex-a-capo'>
            {dataTestArticolari.map((test, index) => {
                return (
                    <MDBox mb={2} key={index}>
                        <Card sx={{ maxWidth: 345, mr : 2, mt : 2, height : '100%' }} mr={2} mb={2 }>
                            <CardMedia component='img' height='140' image={`/articolari/${test.immagine}`} />
                            <CardContent>
                                <Typography gutterBottom variant='h5' component='div'>
                                    {test.nome}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <MDButton size='small' onClick={() => selezionato(test)}>
                                    Seleziona
                                </MDButton>
                            </CardActions>
                        </Card>
                    </MDBox>
                )
            })}
        </div>
    )

    const aggiornaSoloMax = (valore) => {
        let nuovoValore = Object.assign({}, valori)
        nuovoValore.solo.max = valore
        setValori(nuovoValore)
    }
    const aggiornaSoloMin = (valore) => {
        let nuovoValore = Object.assign({}, valori)
        nuovoValore.solo.min = valore
        setValori(nuovoValore)
    }
    const aggiornaAiutatoMax = (valore) => {
        let nuovoValore = Object.assign({}, valori)
        nuovoValore.aiutato.max = valore
        setValori(nuovoValore)
    }
    const aggiornaAiutatoMin = (valore) => {
        let nuovoValore = Object.assign({}, valori)
        nuovoValore.aiutato.min = valore
        setValori(nuovoValore)
    }

    const campiExtra = () => {
        if (!isMinimoSottoZero(testScelto)) {
            return
        }
        return (
            <React.Fragment>
                <InputSlider model={modelSoloMin} value={valori.solo?.min || 0} azione={(a, b) => aggiornaSoloMin(b)} />
                <InputSlider model={modelAiutatoMin} value={valori.aiutato?.min || 0} azione={(a, b) => aggiornaAiutatoMin(b)} />
            </React.Fragment>
        )
    }

    let test = (
        <div className='fletti-verticalmente'>
            <div className='test-articolare'>
                <ElementoTestArticolare id='input' datiDisegno={testScelto} valori={valori} />
            </div>
            <form className='fletti-verticalmente'>
                <InputSlider model={modelSoloMax} value={valori.solo?.max || 0} azione={(a, b) => aggiornaSoloMax(b)} />
                <InputSlider model={modelAiutatoMax} value={valori.aiutato?.max || 0} azione={(a, b) => aggiornaAiutatoMax(b)} />
                {campiExtra()}
            </form>
        </div>
    )

    const salva = () => {
        let risultati = Object.assign(valori)
        if (risultati.aiutato.min === undefined) {
            risultati.aiutato.min = 0
            risultati.solo.min = 0
        }
        let daRestituire = {
            risultati: risultati,
            tipo: 'fieldTestArticolare',
            tipoTest: testScelto.tipo,
        }
        props.salva(daRestituire)
    }

    let renderBottoni = () => {
        if (step === 0) {
            return (
                <MDButton color='error' onClick={() => props.chiudi()}>
                    annulla
                </MDButton>
            )
        }
        return (
            <React.Fragment>
                <MDButton color='error' onClick={() => props.chiudi()}>
                    annulla
                </MDButton>
                <MDButton color='primary' onClick={() => setStep(step - 1)}>
                    indietro
                </MDButton>
                <MDButton color='success' onClick={() => salva()}>
                    salva
                </MDButton>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label} completed={index < step}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <MDBox mt={1} sx={{ margin: 'auto' }}>
                <div className='griglia-sopra-sotto'>
                    {step === 0 ? scelta : test}
                    <div className='contenitore-bottoni-destra'>{renderBottoni()}</div>
                </div>
            </MDBox>
        </React.Fragment>
    )
}
