const initialState = {
    listaSchede: {},
    skeleton: true,
}

export const reducerSchedePaziente = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_SCHEDE':
            let nuovoStato = Object.assign(state, { listaLesioni: action.payload })
            nuovoStato.listaSchede = action.payload
            nuovoStato.skeleton = false
            return nuovoStato
        case 'SVUOTA_PAZIENTE':
            return Object.assign(state, { skeleton: true })
        default:
            return state
    }
}

