export const formDatiGeneraliScheda = (dati) => {
    var giorno = dati.inizio === undefined || dati.inizio === '' ? new Date() : dati.inizio
    //giorno.setDate(giorno.getDate() + 1)
    return {
        costumCssInput: ['baseFormNoBackground'],
        parts: [
            {
                id: 'inizio',
                tipo: 'fieldData',
                associazione: 'inizio',
                label: 'Inizio :',
                value: new Date(),
                details: { minDate: giorno },
                validazione: 'testoRichiesto',
            },
            {
                id: 'fine',
                tipo: 'fieldData',
                associazione: 'fine',
                label: 'Fine :',
                value: '',
                details: { minDate: giorno },
                validazione: 'testoRichiesto',
            },

            {
                id: 'note',
                tipo: 'fieldTextArea',
                associazione: 'note',
                label: 'Note :',
                value: '',
                details: { grid: { lg: { input: 4, output: 4 } } },
                //validazione: 'testoRichiesto',
            },
        ],
    }
}
