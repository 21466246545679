import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Slide from '@mui/material/Slide'
import MDBox from 'components/MDBox'
import breakpoints from 'assets/theme/base/breakpoints'
import { ElementoAssegnaTestArticolare } from 'componenti/elementoAssegnaTestArticolare'
import ElementoAssegnaStrategia from 'componenti/elementoAssegnaStrategia'
import ElementoAssegnaDiagnosi from 'componenti/elementoAssegnaDiagnosi'
import ElementoAssegnaNota from 'componenti/elementoAssegnaNota'
import ElementoAssegnaBodyChart from 'componenti/elementoAssegnaBodyChart'
import { SALVA_ELEMENTO_SEDUTA } from 'data/azioni/SALVA_ELEMENTO_SEDUTA'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import {formattaNomeElemento} from 'getter/getterSedute'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpAssegnaElementoSeduta(props) {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }

    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if(window.innerWidth < breakpoints.values.sm){
                setOpzioni(opzioniMobile)
            }else{
                setOpzioni(opzioniStandard)
            }
            //window.innerWidth < breakpoints.values.sm ? setOpzioni(opzioniMobile) : setOpzioni(opzioniStandard)
        })
    }, [])

    const handleClose = () => {
        props.chiudi()
    }

    const salva = async (valore) => {
        await SALVA_ELEMENTO_SEDUTA(valore)
        const testo = `${formattaNomeElemento(props.parametro)} aggiunto alla seduta`
        props.chiudi()
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: testo} })
    }

    const renderField = () => {
        switch (props.parametro) {
            case 'fieldTestArticolare':
                return <ElementoAssegnaTestArticolare chiudi={handleClose} salva={(valore) => salva(valore)} />
            case 'fieldStrategia':
                return <ElementoAssegnaStrategia chiudi={handleClose} salva={(valore) => salva(valore)} />
            case 'fieldDiagnosi':
                return <ElementoAssegnaDiagnosi chiudi={handleClose} salva={(valore) => salva(valore)} />
            case 'fieldNote':
                return <ElementoAssegnaNota chiudi={handleClose} salva={(valore) => salva(valore)} />
            case 'fieldNotaManuale':
                return <ElementoAssegnaBodyChart chiudi={handleClose} salva={(valore) => salva(valore)} />

            default:
                return ''
        }
    }

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>ASSEGNA ELEMENTO A SEDUTA</DialogTitle>
            <DialogContent dividers={true}>
                <MDBox p={3}>{renderField()}</MDBox>
            </DialogContent>
        </Dialog>
    )
}
