import React, { useState } from 'react'
import { connect } from 'react-redux'
import FormOutput from 'formMaker/formOutput'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { cloneDeep } from 'lodash-es'
import { formStrategia } from 'formModel/formStrategia'
import Icon from '@mui/material/Icon'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { copiaOggetto } from 'data/strumenti'
import { getPermessiUtente } from 'getter/getterPermessi'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import { ref, getDownloadURL } from 'firebase/storage'

export function elementoVediStrategia(props) {
    const [url, setUrl] = useState('')

    const getUrl = async () => {
        let path = `documentazione/consensoInformato/${props.value.datiDocConsensoInformato.docId}/consensoInformato.pdf`
        let urlPdf = await getDownloadURL(ref(storage,path))
        setUrl(urlPdf)
    }

    let form = cloneDeep(formStrategia())
    form.parts.splice(2, 1)

    let tag = props.value.tag.map((el) => {
        return el
    })
    if (props.value.tagLibero !== undefined && props.value.tagLibero !== null && props.value.tagLibero !== '') {
        tag.push(props.value.tagLibero)
    }
    let valore = Object.assign(props.value, { tag: tag })

    let daFirmare = props.value.datiDocConsensoInformato === undefined

    const cosaFare = () => {
        if (!daFirmare) {
            window.open(url)
            return
        }
        APRI_POP_UP({ id: 'firma-documento', parametro: { isStrategia: true, strategia: { info: copiaOggetto(props.value, ['descrizioneIntervento', 'rischiAssoluti', 'sintomi', 'testo']), id: { idStrategia: props.value.id, idSeduta: props.value.idSeduta } } } })
    }

    const renderBox = (icona) => {
        return (
            <MDBox onClick={() => cosaFare()} sx={{ cursor: 'pointer' }} display='flex' justifyContent='center' alignItems='center' width='3rem' height='3rem' bgColor='primary' color='white' shadow='md' borderRadius='xl' ml='auto' mr={1}>
                {icona}
            </MDBox>
        )
    }

    const renderIconaDownload = () => {
        if (url === '') return ''

        return (
            <Icon fontSize='medium' color='inherit'>
                download
            </Icon>
        )
    }

    const renderDaFirmre = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <MDBox display='flex' flexDirection='row' justifyContent='end' alignItems='center'>
                <PriorityHighRoundedIcon color='error' mr={1} />
                <MDBox color='white' onClick={() => cosaFare()} sx={{ cursor: 'pointer' }} display='flex' justifyContent='center' alignItems='end' width='3rem' height='3rem' bgColor='primary' shadow='md' borderRadius='xl'>
                    <svg width='70%' height='70%' fill='white' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M18.7125 8.60556C18.1222 7.99583 18.1556 7.04028 18.7611 6.45L24.9569 0.441667C25.2597 0.148611 25.6486 0 26.0333 0C26.425 0 26.8153 0.152778 27.1111 0.459722L18.7125 8.60556ZM8.52639 23.9125C8.39861 24.0361 8.33333 24.2014 8.33333 24.3667C8.33333 24.7139 8.61667 25 8.96667 25C9.125 25 9.28472 24.9417 9.40694 24.8222L10.4472 23.8097L9.56806 22.9014L8.52639 23.9125ZM17.3181 12.2111L13.7569 15.6625C11.8431 17.5181 10.8667 19.3153 9.96528 21.825L11.5375 23.4486C14.0722 22.625 15.8986 21.7056 17.8125 19.85L21.3722 16.3972L17.3181 12.2111ZM27.7028 1.57361L18.5306 10.4694L23.15 15.2389L32.3222 6.34583C32.9958 5.69444 33.3333 4.82778 33.3333 3.95972C33.3333 1.05972 29.8194 -0.479167 27.7028 1.57361ZM0 25H5.55556V23.6111H0V25Z' fill='white' />
                    </svg>
                </MDBox>
            </MDBox>
        )
    }

    const renderIcona = () => {
        if (props.noIcona) {
            return ''
        }
        if (daFirmare) {
            return renderDaFirmre()
        }
        return renderBox(renderIconaDownload())
    }

    if (!daFirmare) getUrl()

    return (
        <React.Fragment>
            {renderIcona()}
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h5'>
                    Strategia
                </MDTypography>
            </MDBox>
            <FormOutput form={form} dati={valore} />
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        permessi: getPermessiUtente().lesioniPaziente.input,
    }
}

export default connect(mapStateToProps, null)(elementoVediStrategia)
