import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import { InputText } from './../fields/fieldText'
import { AGGIORNA_TITOLO_LESIONE } from './../data/azioni/AGGIORNA_TITOLO_LESIONE'
import { CREA_LESIONE } from './../data/azioni/CREA_LESIONE'
import { getIdPaziente } from './../getter/getterPaziente'

export default function popUpNuovaDisfunzione(props) {
    const [nome, setNome] = useState('')
    const [errore, setErrore] = useState('')

    useEffect(() => {
        if (props.aperto) {
            setNome(props.parametro.nome)
        }
    }, [props.aperto])

    const handleClose = (paginaCambiata) => {
        props.chiudi()
    }

    const salva = async () => {
        if (nome === '') {
            setErrore('Inserire un nome')
            return
        }
        if (props.parametro.modifica) {
            await AGGIORNA_TITOLO_LESIONE(nome)
            handleClose()
            return
        }
        const idLesione = await CREA_LESIONE(nome)
        props.parametro.navigate(`paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${idLesione}/paginaHomeDisfunzione`)
        handleClose()
    }

    const model = {
        id: 'titolo',
        tipo: 'fieldText',
        associazione: 'titolo',
        label: 'Nome',
        value: '',
    }

    return (
        <Dialog open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>{props.parametro.modifica ? 'Modifica nome disfunzione' : 'Nuova disfunzione'}</DialogTitle>
            <DialogContent dividers={true}>
                <InputText model={model} value={nome} errore={errore} azione={(a, b) => setNome(b)} />
            </DialogContent>
            <DialogActions>
                <MDButton color='error' variant='text' onClick={() => handleClose()}>
                    annulla
                </MDButton>

                <MDButton color='success' variant='text' onClick={() => salva()}>
                    salva
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
