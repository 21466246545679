import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
//inizio pagine
import PaginaCercaPaziente from './pagine/paginaCercaPaziente'
import PaginaConfigurazioneTesseraSanitaria from './pagine/paginaConfigurazioneTesseraSanitaria'
import PaginaCreaDipendente from './pagine/paginaCreaDipendente'
import PaginaCreaPaziente from './pagine/paginaCreaPaziente'
import PaginaDiarioClinico from './pagine/paginaDiarioClinico'
import PaginaDisfunzioniPaziente from './pagine/paginaDisfunzioniPaziente'
import PaginaEserciziPaziente from './pagine/paginaEserciziPaziente'
import PaginaFatturePaziente from './pagine/paginaFatturePaziente'
import PaginaFatturePresidio from './pagine/paginaFatturePresidio'
import PaginaFeedback from './pagine/paginaFeedback'
import PaginaFilesDisfunzione from './pagine/paginaFilesDisfunzione'
import PaginaHomeDisfunzione from './pagine/paginaHomeDisfunzione'
import PaginaInformazioniDisfunzione from './pagine/paginaInformazioniDisfunzione'
import PaginaInserimentoDati from './pagine/paginaInserimentoDati'
import PaginaLibreriaEsercizi from './pagine/paginaLibreriaEsercizi'
import PaginaMioProfilo from './pagine/paginaMioProfilo'
import PaginaFilesPaziente from './pagine/paginaFilesPaziente'
import PaginaSceltaMenuChiosco from './pagine/paginaSceltaMenuChiosco'
import PaginaSeduteDisfunzione from './pagine/paginaSeduteDisfunzione'
import PaginaStoriaClinica from './pagine/paginaStoriaClinica'
import PaginaUtenzeTesseraSanitaria from './pagine/paginaUtenzeTesseraSanitaria'
import PaginaVediAbitudini from './pagine/paginaVediAbitudini'
import PaginaVediAnagrafica from './pagine/paginaVediAnagrafica'
import PaginaDashboardPaziente from './pagine/paginaDashboardPaziente'
import PaginaVediDipendente from './pagine/paginaVediDipendente'
import PaginaVediDipendenti from './pagine/paginaVediDipendenti'
import PaginaVediSeduta from './pagine/paginaVediSeduta'
import PaginaVediVersione from './pagine/paginaVediVersione'
import PaginaVediEsercizio from './pagine/paginaVediEsercizio'
import PaginaPresidiSuperAmministratore from './pagine/paginaPresidiSuperAmministratore'
import PaginaInfoAbbonamentiClinica from './pagine/paginaInfoAbbonamentiClinica'
import PaginaListaAgenti from './pagine/paginaListaAgenti'
import PaginaCreaAgente from './pagine/paginaCreaAgente'
import PaginaTutorial from './pagine/paginaTutorial'
import PaginaVediFeedback from './pagine/paginaVediFeedback'
import PaginaCalendario from './pagine/paginaCalendario'
import PaginaSchedaPaziente from './pagine/paginaSchedaPaziente'
import PaginaDoloriDisfunzione from './pagine/paginaDoloriDisfunzione'
import PaginaFormDaCompilare from 'pagine/paginaFormDaCompilare'
import { getPermessiUtente } from 'getter/getterPermessi'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getPresidio } from 'data/strumenti'
import { getVeroUtenteLoggato } from 'getter/getterUtenteLoggato'
//fine pagine

export function Routing(props) {
    const rotteFatturePresidio = () => {
        if (!props.permessi.fatturePresidio) {
            return ''
        }
        return <Route exact path={'/paginaFatturePresidio'} element={<PaginaFatturePresidio />} />
    }

    const rotteImpostazioniTs = () => {
        if (!props.permessi.impostazioniTs) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path={'/paginaConfigurazioneTs'} element={<PaginaConfigurazioneTesseraSanitaria />} />
                <Route exact path={'/paginaUtenzeTs'} element={<PaginaUtenzeTesseraSanitaria />} />
            </React.Fragment>
        )
    }

    const rotteEsercizi = () => {
        if (!props.permessi.libreriaEsercizi.output) {
            return ''
        }
        if (props.profilo === 'profilo-paziente') {
            return (
                <React.Fragment>
                    <Route exact path={'/vediEsercizio/:id'} element={<PaginaVediEsercizio navigate={useNavigate()} />} />
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Route exact path={'/vediEsercizio/:id'} element={<PaginaVediEsercizio navigate={useNavigate()} />} />
                <Route exact path={'/paginaLibreriaEsercizi'} element={<PaginaLibreriaEsercizi />} />
            </React.Fragment>
        )
    }

    const rotteListaPazienti = () => {
        if (props.profilo === 'profilo-chiosco') {
            return <Route exact path={'/paginaCreaPaziente'} element={<PaginaCreaPaziente navigate={useNavigate()} />} />
        }
        if (!props.permessi.listaPazienti.output) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path={'/paginaCercaPaziente'} element={<PaginaCercaPaziente navigate={useNavigate()} />} />
                <Route exact path={'/paginaCreaPaziente'} element={<PaginaCreaPaziente navigate={useNavigate()} />} />
            </React.Fragment>
        )
    }

    const anagraficaPaziente = () => {
        if (!props.permessi.anagraficaSingoloPaziente.output) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path='/paginaVediPaziente/:id/dashboardPaziente' element={<PaginaDashboardPaziente navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/anagraficaPaziente' element={<PaginaVediAnagrafica />} />
            </React.Fragment>
        )
    }

    const datiPaziente = () => {
        if (!props.permessi.datiSingoloPaziente.output) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path='/paginaVediPaziente/:id/storiaClinica' element={<PaginaStoriaClinica navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/abitudiniPaziente' element={<PaginaVediAbitudini navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/filesPaziente' element={<PaginaFilesPaziente navigate={useNavigate()} />} />
            </React.Fragment>
        )
    }

    const eserciziPaziente = () => {
        if (!props.permessi.eserciziPaziente.output) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path='/paginaVediPaziente/:id/eserciziPaziente' element={<PaginaEserciziPaziente navigate={useNavigate()} />} />
                <Route exact path={'/paginaVediPaziente/:id/PaginaSchedaPaziente/:id'} element={<PaginaSchedaPaziente navigate={useNavigate()} />} />
                {/* <Route exact path={'/paginaVediPaziente/:id/paginaFormDaCompilare'} element={<PaginaFormDaCompilare navigate={useNavigate()} />} /> */}
            </React.Fragment>
        )
    }

    const fatturePaziente = () => {
        if (!props.permessi.fatturePaziente.output) {
            return ''
        }
        return <Route exact path='/paginaVediPaziente/:id/fatturePaziente' element={<PaginaFatturePaziente />} />
    }

    const rotteLesioni = () => {
        if (!props.permessi.lesioniPaziente.output) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path='/paginaVediPaziente/:id/diarioClinico' element={<PaginaDiarioClinico navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/disfunzioniPaziente' element={<PaginaDisfunzioniPaziente navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/paginaVediLesione/:id/paginaHomeDisfunzione' element={<PaginaHomeDisfunzione navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/paginaVediLesione/:id/paginaFilesDisfunzione' element={<PaginaFilesDisfunzione navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/paginaVediLesione/:id/paginaInformazioniDisfunzione' element={<PaginaInformazioniDisfunzione navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/paginaVediLesione/:id/paginaDoloriDisfunzione' element={<PaginaDoloriDisfunzione navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/paginaVediLesione/:id/paginaSeduteDisfunzione' element={<PaginaSeduteDisfunzione navigate={useNavigate()} />} />
                <Route exact path='/paginaVediPaziente/:id/paginaVediLesione/:id/paginaVediSeduta/:id' element={<PaginaVediSeduta navigate={useNavigate()} />} />
            </React.Fragment>
        )
    }

    const pagineDipendenti = () => {
        if (!props.permessi.listaDipendenti.input) {
            return ''
        }

        return (
            <React.Fragment>
                <Route exact path='/paginaVediDipendenti' element={<PaginaVediDipendenti navigate={useNavigate()} />} />
                <Route exact path='/paginaVediDipendente/:id' element={<PaginaVediDipendente navigate={useNavigate()} />} />
                <Route exact path='/paginaCreaDipendente/:id' element={<PaginaCreaDipendente navigate={useNavigate()} />} />
                <Route exact path='/paginaInserimentoDati' element={<PaginaInserimentoDati navigate={useNavigate()} />} />
            </React.Fragment>
        )
    }

    const rotteSuperAmministratore = () => {
        if (!props.permessi.superAmministratore) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path='/paginaListaPresidi' element={<PaginaPresidiSuperAmministratore navigate={useNavigate()} />} />
                <Route exact path='/paginaVediFeedback' element={<PaginaVediFeedback navigate={useNavigate()} />} />
                <Route exact path='/paginaListaAgenti' element={<PaginaListaAgenti navigate={useNavigate()} />} />
                <Route exact path='/paginaCreaAgente' element={<PaginaCreaAgente navigate={useNavigate()} />} />
                <Route exact path='/paginaInfoAbbonamentiClinica/:id' element={<PaginaInfoAbbonamentiClinica navigate={useNavigate()} />} />
            </React.Fragment>
        )
    }
    const rotteAgente = () => {
        if (!props.permessi.agente) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path='/paginaListaPresidi' element={<PaginaPresidiSuperAmministratore navigate={useNavigate()} />} />
            </React.Fragment>
        )
    }
    const rotteCalendario = () => {
        if (!props.permessi.calendario) {
            return ''
        }
        return (
            <React.Fragment>
                <Route exact path='/paginaCalendario' element={<PaginaCalendario navigate={useNavigate()} />} />
            </React.Fragment>
        )
    }

    const getPaginaPrincipale = () => {
        switch (props.profilo) {
            case 'profilo-dottore':
                return '/paginaCercaPaziente'
            case 'profilo-segreteria':
                return '/paginaCercaPaziente'
            case 'profilo-chiosco':
                return '/paginaCreaPaziente'
            case 'profilo-paziente':
                return `/paginaVediPaziente/${getIdUtenteLoggato()}/dashboardPaziente`
            case 'profilo-super-amministratore':
                return `/paginaListaPresidi`
            case 'profilo-agente':
                return `/paginaListaPresidi`
            case 'profilo-amministrazione-clinica':
                if (props.presidio === '') {
                    return '/paginaInserimentoDati'
                }
                return `/paginaVediDipendenti`
            default:
                break
        }
    }

    return (
        <Routes>
            <Route exact path={'/paginaMioProfilo'} element={<PaginaMioProfilo />} />
            <Route exact path={'/paginaFeedBack'} element={<PaginaFeedback />} />
            <Route exact path={'/PaginaVediVersione'} element={<PaginaVediVersione />} />
            <Route exact path={'/paginaTutorial'} element={<PaginaTutorial />} />

            {pagineDipendenti()}
            {rotteFatturePresidio()}
            {rotteImpostazioniTs()}
            {rotteEsercizi()}
            {rotteListaPazienti()}
            {anagraficaPaziente()}
            {datiPaziente()}
            {eserciziPaziente()}
            {fatturePaziente()}
            {rotteLesioni()}
            {rotteSuperAmministratore()}
            {rotteAgente()}
            {rotteCalendario()}

            <Route path='*' element={<Navigate to={getPaginaPrincipale()} />} />
        </Routes>
    )
}

function mapStateToProps(state) {
    return {
        permessi: getPermessiUtente(),
        profilo: getProfiloUtenteLoggato(),
        presidio: getPresidio(getVeroUtenteLoggato()),
    }
}

export default connect(mapStateToProps, null)(Routing)
