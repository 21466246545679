const initialState = {}

export const reducerFeedback = (state = initialState, action) => {
      
    switch (action.type) {
        case 'LEGGI_FEEDBACK': 
            return action.payload
        default:
            return state;
    }
};
