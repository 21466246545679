import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import MDBox from 'components/MDBox'
import breakpoints from 'assets/theme/base/breakpoints'
import { formUtenzaTs } from 'formModel/formUtenzaTs'
import { SALVA_UTENZA_TS } from 'data/azioni/SALVA_UTENZA_TS'
import { newId } from 'data/strumenti'
import VediForm from 'formMaker/vediForm'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='left' ref={ref} {...props} />
})

export default function popUpUtenzaTs(props) {
    const opzioniStandard = { right: '0', left: '45%', borderRadius: '12px' }
    const opzioniMobile = { right: '0', left: '0', borderRadius: '12px' }

    const [opzioni, setOpzioni] = useState(window.innerWidth < breakpoints.values.sm ? opzioniMobile : opzioniStandard)

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            if (window.innerWidth < breakpoints.values.sm) {
                setOpzioni(opzioniMobile)
            } else {
                setOpzioni(opzioniStandard)
            }
            //window.innerWidth < breakpoints.values.sm ? setOpzioni(opzioniMobile) : setOpzioni(opzioniStandard)
        })
    }, [])

    const handleClose = () => {
        props.chiudi()
    }

    const salva = async (valore) => {
        let id = props.parametro === '' ? newId() : props.parametro
        await SALVA_UTENZA_TS(valore, id)
        props.chiudi()
    }

    return (
        <Dialog sx={opzioni} fullScreen TransitionComponent={Transition} open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>UTENZA TS</DialogTitle>
            <DialogContent dividers={true}>
                <VediForm form={formUtenzaTs()} dati={{}} vista='input' salva={(e) => salva(e)} annulla={()=>handleClose()} />
            </DialogContent>
        </Dialog>
    )
}
