import { baseWatcher } from './baseWatcher'
import { trasformaOggettoInArray } from './../strumenti'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherCachePresidi extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'cachePresidi', 'cache'), (querySnapshot) => {
            window.listaPresidi = trasformaOggettoInArray(querySnapshot.data())
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'presidi' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
