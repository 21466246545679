import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { getPresidio, trasformaOggettoInArray } from './../strumenti'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherListaPazienti extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.controllaProfilo()
    }

    update() {
        if (store.getState().url[3] === 'paginaInfoAbbonamentiClinica') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    controllaProfilo() {
        if (store.getState().statoUtente.utente.profilo === 'profilo-super-amministratore') {
            store.subscribe(() => {
                this.update()
            })
            this.update()
        } else {
            let presidio = getPresidio(store.getState().statoUtente.utente)

            this.apriConnessione(presidio)
        }
    }

    apriConnessione(nuovoId) {
        let presidio = nuovoId
        var listaPazienti = []

        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', presidio, 'cache', 'pazienti'), (querySnapshot) => {
            listaPazienti = trasformaOggettoInArray(querySnapshot.data())
            let index = listaPazienti.findIndex((el) => {
                return el.id === 'idFalso'
            })
            if (index !== -1) {
                listaPazienti.splice(index, 1)
            }
            window.listaPazienti = listaPazienti
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'pazienti' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
