import { getDipendenteDaId } from '../../getter/getterDipendenti'
import { getDatiPresidio } from '../../getter/getterPresidio'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../strumenti'
import { store } from './../store'
import { generaPdf } from './../makePdfFattura'
import { generaPdfNota } from './../makePdfNotaDiCredito'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getDoc, doc, writeBatch } from 'firebase/firestore'

export const SALVA_PDF_FATTURA = async (idFattura, fattura, rimborso) => {
    let idPresidio = getPresidio(getUtenteLoggato())
    let presidio = getDatiPresidio()

    if (fattura === undefined) {
        let fatturaDb = await getDoc(doc(db, 'fatture', idFattura))
        fattura = fatturaDb.data()
    }
    let dipendente = getDipendenteDaId(fattura.idDottore)
    let path = `fatture/${idPresidio}/${idFattura}/fattura_${fattura.datiFattura.numFattura}.pdf`

    let pdf = {}
    if (rimborso === undefined) {
        pdf = await generaPdf(fattura, presidio, dipendente.contenuto)
    }
    ;('')
    if (rimborso !== undefined) {
        pdf = await generaPdfNota(rimborso, presidio, fattura)
        path = `fatture/${idPresidio}/${idFattura}/rimborsi/nota_di_credito_${rimborso.numero}.pdf`
    }

    await uploadBytes(ref(storage, path), pdf)

    console.log('ho finito : ', path)
    return
}
