import React from 'react'
import TextField from '@mui/material/TextField'
import { OutputText } from './fieldText'

export function InputTextArea(props) {
    return (
        <TextField
            fullWidth
            color={props.model.details?.color || 'primary'}
            variant={props.model.details?.variant || 'outlined'}
            id={props.model.id}
            value={props.value}
            error={props.errore !== ''}
            placeholder={props.model.details?.placeHolder || ''}
            type={props.model.details?.type}
            classes={props.model.details?.classi}
            helperText={props.errore}
            onChange={(e, valore) => {
                props.azione(props.model.id, e.target.value)
            }}
            label={props.model.label}
            multiline
        />
    )
}

export function OutputTextArea(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    return <OutputText skeleton={props.skeleton || false} model={model} value={props.value} />
}
