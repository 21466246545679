import { AGGIORNA_CACHE } from './AGGIORNA_CACHE'
import { getPresidio } from '../strumenti'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { doc, updateDoc } from 'firebase/firestore'

export const AGGIORNA_DATI_ESERCIZIO = async (idEsercizio, esercizio) => {
    let esercizioCache = { [`${idEsercizio}`]: {titolo : esercizio.nome, tags : esercizio.tags} }
    await updateDoc(doc(db, 'esercizi', idEsercizio), esercizio)
    await AGGIORNA_CACHE(esercizioCache, 'esercizi', getPresidio(getUtenteLoggato()))
    return
}
