import React from 'react'
import { connect } from 'react-redux'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { formUtente } from 'formModel/formUtente'
import { getPaziente, getSkeleton } from 'getter/getterPaziente'
import VediForm from 'formMaker/vediForm'
import { AGGIORNA_DATI_UTENTE } from 'data/azioni/AGGIORNA_DATI_UTENTE'
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded'

export const paginaVediAnagrafica = (props) => {
    let form = formUtente('profilo-paziente')
    const aggiornaAnagrafica = async (e) => {
        return await AGGIORNA_DATI_UTENTE(e)
    }

    const renderForm = () => {
        if (props.skeleton) {
            return <Skeleton animation='wave' variant='rectangular' width={'100%'} height={'400px'} />
        }
        return <VediForm form={form} dati={props.dati} dialer={true} salva={(e) => aggiornaAnagrafica(e)} />
    }

    return (
        <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
            {/* <MDBox lineHeight={0} ml={2}>
                <MDTypography color={'dark'} variant='h4'>
                    Anagrafica Paziente
                </MDTypography>
            </MDBox> */}
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MenuBookRoundedIcon color='white' sx={{ mr: 1 }} />
                <MDTypography color={'white'} variant='h5'>
                    Anagrafica paziente
                </MDTypography>
            </MDBox>
            <VediForm skeleton={props.skeleton} form={form} dati={props.dati} dialer={true} salva={(e) => aggiornaAnagrafica(e)} />
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        dati: getPaziente(),
        skeleton: getSkeleton(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediAnagrafica)
