import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { listaNaturaIva } from 'data/configurazioneVoceFattura'
import { InputSelect, OutputSelect } from './fieldSelect'
import { InputText, OutputText } from './fieldText'
import MDBox from 'components/MDBox'

const modelNaturaIva = {
    id: 'naturaIva',
    tipo: 'fieldselect',
    associazione: 'naturaIva',
    label: 'Natura iva :',
    value: '',
    details: {
        scelte: listaNaturaIva,
        toShow: 'testo',
        toSave: 'id',
        complex: true,
    },
}

const modelAliquotaIva = {
    id: 'aliquotaIva',
    tipo: 'fieldText',
    associazione: 'aliquotaIva',
    label: 'Aliquota IVA',
    value: 0,
    details: {
        type: 'number',
        min : 0,
        max : 22
    },
}

export function InputVoceFattura(props) {
    const getValoreTab = () => {
        return props.value.tipo === 'naturaIVA' ? 0 : 1
    }

    const valoreTabCambiato = (valore) => {
        props.azione(props.model.id, Object.assign(props.value, { tipo: valore === 0 ? 'naturaIVA' : 'aliquotaIVA' }))
    }
    const aggiornaValore = (valore) => {
        props.azione(props.model.id, Object.assign(props.value, { valore: valore }))
    }

    const renderValore = () => {
        if (getValoreTab() === 0) {
            return <InputSelect model={modelNaturaIva} value={props.value.valore} errore={props.errore} azione={(a, b) => aggiornaValore(b)} />
        }
        return <InputText model={modelAliquotaIva} value={props.value.valore} errore={props.errore} azione={(a, b) => aggiornaValore(b)} />
    }

    return (
        <React.Fragment>
            <Tabs orientation='horizontal' value={getValoreTab()} onChange={(e, valore) => valoreTabCambiato(valore)}>
                <Tab label='Natura IVA' />
                <Tab label='Aliquota IVA' />
            </Tabs>
            <MDBox mt={1}>{renderValore()}</MDBox>
        </React.Fragment>
    )
}

export function OutputvaVoceFattura(props) {
    let model = Object.assign(modelNaturaIva)

    if (props.model.details?.noLabel) {
        const modelNoLabel = {
            id: props.model.id,
            tipo: 'fieldText',
            associazione: props.model.associazione,
            label: props.model.label,
            value: props.model.value || '',
            details: props.model.details || {},
        }

        return <OutputText skeleton={props.skeleton || false} model={modelNoLabel} value={props.value.valore} />
    }

    if (props.value.tipo === 'aliquotaIva') {
        model = Object.assign(modelAliquotaIva)
        model.details.noLabel = props.model.details?.noLabel || false
        return <OutputText model={model} value={props.value.valore} />
    }
    model.details.noLabel = props.model.details?.noLabel || false

    return <OutputSelect skeleton={props.skeleton || false} model={model} value={props.value.valore} />
}
