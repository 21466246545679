import React, { useState } from 'react'
import { listaBandiere } from './../data/listaBandiere'
import ElementoBandiera from 'componenti/elementoBandiera'
import { InputText, OutputText } from './fieldText'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import colors from 'assets/theme/base/colors'
import { Skeleton } from '@mui/material'

const modelDescrizione = {
    id: 'descrizione',
    tipo: 'fieldTextArea',
    associazione: 'descrizione',
    label: 'Descrizione:',
    value: '',
}

export function InputSceltaBandierina(props) {
    const [focus, setFocus] = useState(false)
    const getColore = () => {
        if (focus) {
            return colors.primary.main
        }
        return props.errore !== '' ? colors.error.main : colors.inputBorderColor
    }
    const vediDescrizione = () => {
        return props.value.id.length !== 0
    }

    const aggiornaValore = (nuovoValore) => {
        props.azione(props.model.id, nuovoValore)
    }

    const aggiornaBandiere = (bandiera, daAggiungere) => {
        let nuovaLista = [...props.value.id]
        if (daAggiungere) {
            nuovaLista.push(bandiera)
        }
        if (!daAggiungere) {
            let index = nuovaLista.indexOf(bandiera)
            nuovaLista.splice(index, 1)
        }
        aggiornaValore(Object.assign(props.value, { id: nuovaLista }))
    }

    const rimuoviBandiere = () => {
        aggiornaValore(Object.assign(props.value, { id: [] }))
    }

    const aggiornaDescrizione = (valore) => {
        aggiornaValore(Object.assign(props.value, { descrizione: valore }))
    }

    const descrizione = <InputText model={modelDescrizione} value={props.value.descrizione} errore='' azione={(a, b) => aggiornaDescrizione(b)} />

    return (
        <MDBox sx={{ border: 'solid 0.1px', color : getColore() }} p={2} borderRadius='lg' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
            <MDBox mt={-5.3} bgColor='white' p={0.5} width={'max-content'}>
                <MDTypography gutterBottom sx={{color : getColore()}}  variant='caption'>
                    {props.model.label}
                </MDTypography>
            </MDBox>
            <div className='fletti-orizzontalmente'>
                {listaBandiere.map((bandiera) => {
                    let isSelezionato = props.value.id.includes(bandiera.id)
                    return <ElementoBandiera key={bandiera.id} bandiera={bandiera} isSelezionato={isSelezionato} azione={() => aggiornaBandiere(bandiera.id, !isSelezionato)} />
                })}
                <ElementoBandiera key={'rimuovi'} rimuovi={true} azione={() => rimuoviBandiere()} />
            </div>
            {vediDescrizione() ? descrizione : ''}
        </MDBox>
    )
}
export function OutputSceltaBandierina(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    if (props.skeleton) {
        return (
            <div className='flestti-verticalmente'>
                <MDTypography color={'text'} variant='subtitle2'>
                    {props.model.label}
                </MDTypography>

                <Skeleton animation='wave' height='100px' />
            </div>
        )
    }

    if (props.value.id.length === 0) {
        return <OutputText model={model} value={'Nessuna bandiera selezionata'} />
    }

    return (
        <div className='flestti-verticalmente'>
            <MDTypography color={'text'} variant='subtitle2'>
                {props.model.label}
            </MDTypography>
            <div className='fletti-orizzontalmente'>
                {props.value.id.map((bandieraSelezionata) => {
                    let index = listaBandiere.findIndex((el) => {
                        return el.id === bandieraSelezionata
                    })
                    let bandiera = listaBandiere[index]
                    return <ElementoBandiera key={bandiera.id} bandiera={bandiera} isSelezionato={true} />
                })}
            </div>
            <OutputText skeleton={props.skeleton || false} model={modelDescrizione} value={props.value.descrizione} />
        </div>
    )
}
