import React from 'react'
import ElementoNavigazioneDisfunzione from 'componenti/elementoNavigazioneDisfunzione'
import VediForm from 'formMaker/vediForm'
import { connect } from 'react-redux'
import { getInformazioniLesione } from 'getter/getterLesioni'
import MDBox from 'components/MDBox'
import { formInformazioniDisfunzioni } from 'formModel/formInformazioniDisfunzioni'
import { AGGIORNA_INFORMAZIONI_DISFUNZIONE } from './../data/azioni/AGGIORNA_INFORMAZIONI_DISFUNZIONE'
import { getPermessiUtente } from 'getter/getterPermessi'
import { getSkeletonLesioni } from 'getter/getterLesioni'

export function paginaInformazioniDisfunzione(props) {
    let form = formInformazioniDisfunzioni()

    const salva = async (e) => {
        await AGGIORNA_INFORMAZIONI_DISFUNZIONE(e)
        return
    }

    return (
        <React.Fragment>
            <ElementoNavigazioneDisfunzione navigate={props.navigate} />
            <MDBox color='info' bgColor='white' variant='gradient' borderRadius='lg' shadow='lg' opacity={1} p={2}>
                <div id='giovanni-porcu' style={{ overflow: 'hidden' }}>
                    <VediForm skeleton={props.skeleton} form={form} dati={props.dati} dialer={true} noBottoni={!props.permessi} salva={(e) => salva(e)} />
                </div>
            </MDBox>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        dati: getInformazioniLesione(),
        permessi: getPermessiUtente().lesioniPaziente.input,
        skeleton : getSkeletonLesioni()
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaInformazioniDisfunzione)
