import { getVeroUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio, trasformaOggettoInArray } from '../strumenti'
import { baseWatcher } from './baseWatcher'
import { store } from 'data/store'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherFattureDipendenti extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.controllaProfilo()
    }

    update() {
        if (store.getState().url[3] === 'paginaInfoAbbonamentiClinica') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    controllaProfilo() {
        if (store.getState().statoUtente.utente.profilo === 'profilo-super-amministratore') {
            store.subscribe(() => {
                this.update()
            })
        } else {
            let presidio = getPresidio(getVeroUtenteLoggato())

            try {
                this.apriConnessione(presidio)
            } catch (error) {
                console.log(error)
            }
        }
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', nuovoId, 'cache', 'fattureStripe'), (documento) => {
            window.listaFattureDipendenti = documento.exists() ? trasformaOggettoInArray(documento.data()) : []
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'fattureDipendenti' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
