import React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export function InputAccettaTerminiECondizioni(props) {
    return (
        <Grid container spacing={0.3}>
            <Grid item container xs={12} lg={12} spacing={2}>
                <Grid item xs={8} lg={8}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={props.value} onChange={(e, valore) => props.azione(props.model.id, valore)} />} label={props.model.label} />
                    </FormGroup>
                </Grid>
                <Grid item xs={4} lg={4}>
                    <MDButton color={'primary'} variant="text" onClick={() => APRI_POP_UP({ id: 'accettaTerminiECondizioni', parametro: {} })}>
                        {props.model.details.testoLink}
                    </MDButton>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
                <MDTypography color={'error'} variant='caption'>
                    {props.errore}
                </MDTypography>
            </Grid>
        </Grid>
    )
}
export function OutputAccettaTerminiECondizioni(props) {
    return <div>fieldAccettaTerminiECondizioni</div>
}
