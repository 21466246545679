import React from 'react'
import VediForm from 'formMaker/vediForm'
import { formStrategia } from 'formModel/formStrategia'

export default function elementoAssegnaStrategia(props) {
    
    const annulla = () => {
        props.chiudi()
    }

    const salva = (dati) => {
        dati.tipo = 'fieldStrategia'
        props.salva(dati)
    }

    return <VediForm form={formStrategia()} dati={{}} annulla={()=>annulla()} salva={(dati)=>salva(dati)} vista='input'/>

}
