import { getIdPaziente } from '../../getter/getterPaziente'
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { formInformazioniDisfunzioni } from './../../formModel/formInformazioniDisfunzioni'
import { creaEtichettaUniversale, getPresidio, newId } from './../strumenti'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const CREA_LESIONE = (titolo) => {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        let lesione = {
            titolo: titolo,
            informazioni: formInformazioniDisfunzioni().parts.reduce((acc, el) => {
                acc[el.associazione] = el.value
                return acc
            }, {}),
            etichettaUniversale: creaEtichettaUniversale(getPresidio(getUtenteLoggato())),
        }

        let idLesione = newId()

        let daPassare = {
            idPaziente: getIdPaziente(),
            idLesione: idLesione,
            datiLesione: lesione,
        }

        CHIAMA_SERVER(`creaLesione`, daPassare).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve(idLesione)
        })
    })
}
