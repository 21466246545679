import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { AGGIORNA_CACHE } from './AGGIORNA_CACHE'
import { newId, creaEtichettaUniversale, getPresidio } from './../strumenti'
import { doc, setDoc } from 'firebase/firestore'
import { SALVA_FILE_ESERCIZIO } from './SALVA_FILE_ESERCIZIO'

export const CREA_ESERCIZIO = async (esercizio) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    let newIdEsercizio = newId()
    let presidio = getPresidio(getUtenteLoggato())

    const listaImmagini = esercizio.listaImmagini
    delete esercizio.listaImmagini

    console.log("esercizio : ", esercizio);
    esercizio.etichettaUniversale = creaEtichettaUniversale(presidio)
    await setDoc(doc(db, 'esercizi', newIdEsercizio), esercizio)

    var datiPerCache = {
        [newIdEsercizio]: {
            titolo: esercizio.nome,
            utilizzi: {},
            tags: esercizio.tags,
        },
    }
    await AGGIORNA_CACHE(datiPerCache, 'esercizi', presidio)

    await Promise.all(
        listaImmagini.map((datiImmagine) => {
            return SALVA_FILE_ESERCIZIO(datiImmagine.file, newIdEsercizio)
        }),
    )

    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
