import { getIdPaziente } from '../../getter/getterPaziente'
import { store } from './../store'
import { APRI_POP_UP } from './APRI_POP_UP'
import { doc, updateDoc } from 'firebase/firestore'

export const AGGIORNA_DATI_PAZIENTE = async (dati) => {
    await updateDoc(doc(db, 'utenti', getIdPaziente()), dati)
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata' } })
    return
}
