const initialState = {
    id: '',
    contenuto: {},
}

export const reducerSingoloDottore = (state = initialState, action) => {
    let nuovoStato = Object.assign(state, {})
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'LEGGI_SINGOLO_DOTTORE':
            nuovoStato.contenuto = action.payload.contenuto
            nuovoStato.id = action.payload.id
            return nuovoStato
        /* case "CAMBIA_URL":
           break */
        default:
            return state
    }
}
