import React from 'react'
import ElementoNavigazioneDisfunzione from 'componenti/elementoNavigazioneDisfunzione'
import ElementoVediSedute from 'componenti/elementoVediSedute'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { CREA_SEDUTA } from 'data/azioni/CREA_SEDUTA'
import { getIdPaziente } from 'getter/getterPaziente'
import { getIdLesioneSelezionata } from 'getter/getterLesioni'
import { connect } from 'react-redux'
import { getPermessiUtente } from 'getter/getterPermessi'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export function paginaSeduteDisfunzione(props) {
    const nuovaSeduta = async () => {
        let idSeduta = await CREA_SEDUTA()
        APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Nuova seduta creata' } })
        props.navigate(`paginaVediPaziente/${getIdPaziente()}/paginaVediLesione/${getIdLesioneSelezionata()}/paginaVediSeduta/${idSeduta}`)
    }

    const renderFab = () => {
        if (!props.permessi) {
            return ''
        }
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => nuovaSeduta()}>
                <AddIcon />
            </Fab>
        )
    }

    return (
        <React.Fragment>
            {renderFab()}
            <ElementoNavigazioneDisfunzione navigate={props.navigate} />
            <ElementoVediSedute navigate={props.navigate} />
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        permessi: getPermessiUtente().lesioniPaziente.input,
    }
}

export default connect(mapStateToProps, null)(paginaSeduteDisfunzione)
