import React from 'react'
import { connect } from 'react-redux'
import TimelineList from 'examples/Timeline/TimelineList'
import TimelineItem from 'examples/Timeline/TimelineItem'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getListaSedute } from 'getter/getterSedute'
import MedicalServicesRoundedIcon from '@mui/icons-material/MedicalServicesRounded'
import { getIdPaziente } from 'getter/getterPaziente'
import { getSkeletonLesioni } from 'getter/getterLesioni'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { getPermessiUtente } from 'getter/getterPermessi'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export const elementoVediSedute = (props) => {
    const schiacciato = (seduta) => {
        props.navigate(seduta.link)
    }

    let descrizione = (seduta) => {
        return (
            <MDBox>
                <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant='caption' color='text'>
                        Elementi :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                            {seduta.elementi}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
                <MDBox mb={1} lineHeight={0}>
                    <MDTypography variant='caption' color='text'>
                        Disunzione :
                        <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize'>
                            {seduta.nomeLesione}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
            </MDBox>
        )
    }

    const cosaFare = () => {
        if (props.vediBottone) {
            props.navigate(`/paginaVediPaziente/${props.idPaziente}/diarioClinico`)
        }
        return
    }

    const getTitolo = () => {
        if (props.link) {
            return renderTitoloLink()
        }
        return (
            <MDBox onClick={() => cosaFare()} sx={{ cursor: props.vediBottone ? 'pointer' : '' }} display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                {props.icona !== undefined ? props.icona : <MedicalServicesRoundedIcon color='white' sx={{ mr: 1 }} />}
                <MDTypography color={'white'} variant='h5'>
                    {props.titolo === '' || props.titolo === undefined ? 'Lista sedute' : props.titolo}
                </MDTypography>
                {props.vediBottone ? <ArrowForwardIosRoundedIcon color='white' sx={{ ml: 2 }} /> : ''}
            </MDBox>
        )
    }

    const renderBottone = () => {
        if (props.vediBottone === undefined || !props.vediBottone) {
            return ''
        }
        if (!props.permessi) {
            return ''
        }

        return (
            <MDBox className='contenitore-bottoni-destra-verticale'>
                <MDButton variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'nuova-seduta', parametro: { navigate: props.navigate } })}>
                    nuova seduta
                </MDButton>
            </MDBox>
        )
    }

    const renderSedute = () => {
        return (
            <MDBox className="box-dashboard" sx={{ maxWidth: '1000px' }} width='100%' ml='auto' mr='auto' height='100%' shadow='lg' borderRadius='lg' p={2} variant='gradient' bgColor='white' opacity={1}>
                {getTitolo()}
                <TimelineList title=''>
                    {props.sedute.map((seduta, index) => {
                        return (
                            <MDBox key={`seduta-${index}`} display='grid' gridTemplateColumns='1fr max-content' sx={{ cursor: 'pointer' }} onClick={() => schiacciato(seduta)}>
                                <TimelineItem color='primary' icon={'notifications'} title={seduta.data} dateTime={seduta.dottore} description={descrizione(seduta)} badges={['design']} />
                                <MDBox display='flex' height='100%' mr={1} width='50px' justifyContent='center' alignItems='center'>
                                    <ArrowForwardIosRoundedIcon />
                                </MDBox>
                            </MDBox>
                        )
                    })}
                </TimelineList>
                {renderBottone()}
            </MDBox>
        )
    }

    const renderNoSedute = () => {
        return (
            <MDBox className="box-dashboard" sx={{ maxWidth: '1000px' }} width='100%' ml='auto' mr='auto' shadow='lg' borderRadius='lg' p={2} variant='gradient' bgColor='white' opacity={1} height='100%'>
                {getTitolo()}
                <MDTypography color='text'>Nessuna seduta presente</MDTypography>

                {renderBottone()}
            </MDBox>
        )
    }

    const renderCaricamento = () => {
        return (
            <MDBox sx={{ maxWidth: '1000px' }} width='100%' ml='auto' mr='auto' shadow='lg' borderRadius='lg' p={2} variant='gradient' bgColor='white' opacity={1} height='100%'>
                <React.Fragment>
                    {getTitolo()}
                    <MDTypography color='info'>Caricamento sedute in corso...</MDTypography>
                </React.Fragment>
            </MDBox>
        )
    }

    return props.caricamento ? renderCaricamento() : props.sedute.length === 0 ? renderNoSedute() : renderSedute()
}

function mapStateToProps(state) {
    return {
        sedute: getListaSedute(),
        idPaziente: getIdPaziente(),
        caricamento: getSkeletonLesioni(),
        permessi: getPermessiUtente().lesioniPaziente.input,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoVediSedute)
