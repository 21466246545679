import MDTypography from 'components/MDTypography'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import React from 'react'
import { svgBandiera, svgChiudiAvanzato, svgSpiegazioni } from './../svgImporter'

export default function elementoBandiera(props) {
    const getSelezionato = () => {
        if (props.isSelezionato) {
            return 'selezionato'
        }

        return 'non-selezionato'
    }

    const schiacciato = () => {
        if (props.azione !== undefined) {
            props.azione()
        }
    }

    const cancella = (
        <div className='contenitore-bandiera manina' onClick={() => schiacciato()}>
            <div className={`bandiera ${getSelezionato()}`}>{svgChiudiAvanzato()}</div>
            <div className='centrato'>
                <MDTypography variant='string'>Rimuovi</MDTypography>
            </div>
        </div>
    )

    const info = (
        <div className='centrato'>
            <div className='info-bandiera' onClick={() => APRI_POP_UP({ id: 'descrizioneBandiera', parametro: props.bandiera })}>
                {svgSpiegazioni()}
            </div>
        </div>
    )

    const bandiera = () => {
        return (
            <div className='contenitore-bandiera'>
                <div className={`bandiera ${props.bandiera.colore} ${getSelezionato()}`} onClick={() => schiacciato()}>
                    {svgBandiera()}
                </div>
                {props.nascondiInfo ? '' : info}
            </div>
        )
    }

    return props.rimuovi ? cancella : bandiera()
}
