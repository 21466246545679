const initialState = {
    titolo: '',
    tags: [],
}

export const reducerPopUpFieldList = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return initialState
        case 'APRI_POP_UP_FIELD_LIST':
            return { aperto: true, parametro: action.payload }
        case 'CHIUDI_POP_UP_FIELD_LIST':
            return { aperto: false, parametro: {} }

        default:
            return state
    }
}
