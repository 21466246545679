import { baseWatcher } from './baseWatcher'
import { LEGGI_CREDENZIALI_CHIOSCO } from './../azioni/LEGGI_CREDENZIALI_CHIOSCO'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherCredenzialiChiosco extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        window.addEventListener('apriPopUp', (e) => {
            if (e.detail.id === 'chiosco') {
                this.update(e.detail.parametro)
            }
        })
    }

    update(nuovoId) {
        this.apriConnessione(nuovoId)
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'utenti', nuovoId, 'datiLogIn', 'dati'), (documento) => {
            LEGGI_CREDENZIALI_CHIOSCO(documento.data())
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
