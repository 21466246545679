import { Divider, Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import MDButton from 'components/MDButton'
import ElementoMostraEsercizioAssegnato from 'componenti/elementoMostraEsercizioAssegnato'
import { useNavigate } from 'react-router-dom'

export default function elementoEserciziSuScheda(props) {
    /*  const test = [
        { titolo: 'titolo 1', posologia: 'posologia 1' },
        { titolo: 'titolo 2', posologia: 'posologia 2' },
        { titolo: 'titolo 3', posologia: 'posologia 3' },
        { titolo: 'titolo 4', posologia: 'posologia 4' },
        { titolo: 'titolo 5', posologia: 'posologia 5' },
    ]

    const [lista, setLista] = useState(test) */

    const navigate = useNavigate()

    const esercizioLibreriaModificato = (posologia, index) => {
        const elemento = props.lista[index]
        var esercizio = {
            posologia: posologia,
            index: index,
            isNuovoEsercizio : false,
            esercizio : {id : elemento.id, contenuto : {titolo : elemento.titolo}},
        }
        //Object.assign(props.lista[index], { posologia: posologia, index: index })
        console.log("esercizio : ", esercizio);
        props.aggiorna(esercizio)
    }

    const modifica = (index) => {
        const elemento = props.lista[index]
        let esercizio = {}
        if (elemento.isNuovoEsercizio) {
            esercizio = {
                nome: elemento.titolo,
                testo: elemento.descrizione,
                commenti: elemento.posologia,
                tags: elemento.tags,
            }
            APRI_POP_UP({ id: 'nuovoEsercizio', parametro: { esercizio: esercizio, listaImmagini : elemento.listaImmagini || [], noAlert: true, daAssegnare: true, azione: (e) => props.aggiorna(Object.assign({ index: index, isNuovoEsercizio: true }, e)) } })
            return
        }

        APRI_POP_UP({ id: 'posologia', parametro: { commento: elemento.posologia, noAlert: true, azione: (e) => esercizioLibreriaModificato(e, index) } })
    }

    const elimina = (index) => {
        APRI_POP_UP({ id: 'elimina', parametro: { noAlert: true, testo: "Sei sicuro di voler rimuovere l'esercizio dalla scheda?", azione: () => props.aggiorna({ elimina: true, index: index }) } })
    }

    const renderRiga = (riga, index, isUltima) => {
        const titolo = isUltima ? '' : riga.titolo
        const posologia = isUltima ? '' : riga.posologia
        return (
            <React.Fragment>
                <Grid mb={1} p={1} container spacing={3}>
                    <Grid item xs={3} lg={2}>
                        <MDBox height='100%' width='min-content' display='flex' sx={{ display: 'flex', flexDirection: 'row' }} justifyContent='center' alignItems='center'>
                            <MDBox height='100%' width='min-content' display='flex' sx={{ cursor: 'pointer' }} justifyContent='center' alignItems='center' onClick={() => modifica(index)}>
                                <EditRoundedIcon />
                            </MDBox>
                            <MDBox height='100%' width='min-content' display='flex' sx={{ cursor: 'pointer' }} justifyContent='center' alignItems='center' onClick={() => elimina(index)}>
                                <RemoveCircleOutlineRoundedIcon />
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item container xs={9} lg={10} spacing={2}>
                        <Grid xs={12} lg={6}>
                            <MDBox height='100%' width='100%' display='flex' justifyContent='start'>
                                <MDTypography>{titolo}</MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid xs={12} lg={6}>
                            <MDBox height='100%' width='100%' display='flex' justifyContent='end'>
                                <MDTypography>{posologia}</MDTypography>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
            </React.Fragment>
        )
    }

    const renderListaEsercizi = () => {
        return props.lista.map((riga, index) => {
            return renderRiga(riga, index, false)
        })
    }

    const renderEsercizi = () => {
        return props.lista.map((elemento, index) => {
            const esercizio = {
                commento: elemento.posologia,
                isScheda: true,
                idEsercizio: elemento.id,
                titolo: elemento.titolo,
                isNuovoEsercizio : elemento.isNuovoEsercizio
            }
            return (
                <React.Fragment key={`assegnazione-${index}`}>
                    <Grid key={esercizio.idEsercizio} item>
                        <ElementoMostraEsercizioAssegnato noBottoni={props.output} esercizio={esercizio} navigate={navigate} elimina={() => elimina(index)} modifica={() => modifica(index)} />
                    </Grid>
                    {index !== props.lista.length - 1 ? <Divider /> : ''}
                </React.Fragment>
            )
        })
    }

    const renderBottoni = () => {
        if (props.output) {
            return ''
        }
        return (
            <React.Fragment>
                <MDBox className='contenitore-bottoni-destra'>
                    <MDBox className='contenitore-bottoni-destra' gridAutoFlow={{ xs: 'row', md: 'column' }}>
                        <MDButton variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'nuovoEsercizio', parametro: { noAlert: true, daAssegnare: true, azione: (e) => props.aggiorna(Object.assign({ isNuovoEsercizio: true }, e)) } })}>
                            nuovo esercizio
                        </MDButton>
                        <MDButton variant='contained' color='primary' onClick={() => APRI_POP_UP({ id: 'assegna-esercizio', parametro: { noAlert: true, azione: (e) => props.aggiorna(Object.assign({ isNuovoEsercizio: false }, e)) } })}>
                            assegna esercizio da libreria
                        </MDButton>
                    </MDBox>
                </MDBox>
                <MDBox className='contenitore-bottoni-destra'>
                    <MDBox className='contenitore-bottoni-destra' gridAutoFlow={{ xs: 'row', md: 'column' }}>
                        <MDButton variant='contained' color='error' onClick={() => props.annulla()}>
                            annulla
                        </MDButton>
                        <MDButton variant='contained' color='primary' onClick={() => props.indietro()}>
                            indietro
                        </MDButton>
                        <MDButton variant='contained' color='success' onClick={() => props.salva()}>
                            salva
                        </MDButton>
                    </MDBox>
                </MDBox>
            </React.Fragment>
        )
    }

    return (
        <MDBox>
            {renderEsercizi()} {renderBottoni()}
        </MDBox>
    )
}
