import { getUtenteLoggato } from './../getter/getterUtenteLoggato'
import { getPresidio } from './strumenti'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { ref, getDownloadURL } from 'firebase/storage'
import { doc, getDoc } from 'firebase/firestore'

pdfMake.vfs = pdfFonts.pdfMake.vfs

let creaFooter = (presidio) => {
    let footerDaRitornare = {
        layout: 'noBorders',
        table: {
            body: [
                [
                    {
                        margin: [5, 3, 5, 3],
                        text: `Pagamento effettuabile anche al seguente IBAN: ${presidio.iban} intestato a ${presidio.nome}`,
                    },
                ],
            ],
        },
        style: 'footer',
    }

    return footerDaRitornare
}

let paziente = (paziente) => {
    return [paziente.nome, paziente.indirizzo, paziente.cf]
}

const bold = (testo) => {
    return { text: testo, style: 'parolaBold' }
}

let creaStile = () => {
    return {
        header: {
            fontSize: 9,
            bold: true,
            margin: [0, -70, 0, 15],
            alignment: 'right',
        },
        testo: {
            fontSize: 9,
            margin: [0, 0, 0, 7],
            alignment: 'justify',
        },
        testo2: {
            fontSize: 11,
            margin: [0, 0, 0, 7],
            alignment: 'justify',
        },
        testoDestra: {
            fontSize: 9,
            margin: [0, 0, 0, 7],
            alignment: 'right',
        },
        testoSinistra: {
            fontSize: 9,
            margin: [0, 0, 0, 7],
            alignment: 'left',
        },
        footer: {
            fontSize: 9,
            alignment: 'center',
            margin: [10, 20, 10, 20],
            bold: true,
        },
    }
}

let footerSecondaPagina = (presidio) => {
    return {
        text: `${bold(presidio.nome).text}-${presidio.indirizzo}-${presidio.comune} (${presidio.provincia}) ${presidio.CAP}-${presidio.telefonoFisso}-${presidio.telefonoCellulare}
        ${presidio.web}-${presidio.mail}-${presidio.partiaIva}-${presidio.cf}`,
        style: 'testo',
    }
}

let generaintestazione = (presidio) => {
    return {
        text: `${presidio.nome}
        ${presidio.indirizzo} - ${presidio.comune} (${presidio.provincia}) - ${presidio.CAP}
        Tel. ${presidio.telefonoFisso}  Cell. ${presidio.telefonoCellulare}
        e-mail: ${presidio.mail}  Web: ${presidio.web}
        Partita Iva: ${presidio.partiaIva}             C.F.:  ${presidio.cf}`,
        style: 'header',
    }
}
let generaInfo = (paziente, fattura) => {
    return {
        margin: [0, 100, 0, 0],
        headerRows: 1,
        table: {
            widths: ['auto', 'auto', '*'],
            body: [
                ['Fattura n.', 'Data', 'Paziente'],
                [fattura.numFattura, fattura.date, [`${paziente.nome} ${paziente.cognome}`, `Residenza: ${paziente.indirizzo}`, `Comune: ${paziente.comune}`, `CAP: ${paziente.cap}`, `Codice Fiscale: ${paziente.cf}`]],
            ],
        },
        layout: 'lightHorizontalLines',
    }
}

let voce = (testo, importo, posizione) => {
    return [
        {
            text: testo,
            style: posizione,
        },
        {
            text: `${importo.toFixed(2)} €`,
            style: 'testoDestra',
        },
    ]
}

let generaFattura = (vociFattura, marca) => {
    let voci = vociFattura.map((elem, index) => {
        return voce(elem.descrizione, elem.importo, 'testoSinistra')
    })
    voci.unshift(['Descrizione', 'Importo'])
    let totale = vociFattura.reduce((acc, elem) => {
        return acc + elem.importo
    }, 0)

    if (marca) {
        voci.push(voce('Totale Voci', totale, 'testoDestra'))
        voci.push(voce('Marca da Bollo', 2, 'testoDestra'))
        voci.push(voce('Totale Fattura', totale + 2, 'testoDestra'))
    } else {
        voci.push(voce('Totale Fattura', totale, 'testoDestra'))
    }
    return {
        margin: [0, 100, 0, 0],
        headerRows: 1,
        table: {
            widths: ['*', 'auto'],
            body: voci,
        },
        layout: 'lightHorizontalLines',
    }
}

let isSpesaSanitaria = (voci) => {
    return voci
        .map((singolaVoce) => {
            return singolaVoce.tipoSpesa === 'SP'
        })
        .includes(true)
}

let secondaParte = (data, paziente, dottore) => {
    let voci = data.voci.reduce((acc, voce, index) => {
        if (index !== data.voci.length - 1) {
            return acc + voce.descrizione + ','
        } else {
            return acc + voce.descrizione
        }
    }, '')

    let testo = `I trattamenti sono stati eseguiti dal Dottore ${dottore.cognome} ${dottore.nome} - Fisioterapista laureato
    a ${dottore.laurea}.`
    if (dottore.genere === 'Maschio' || dottore.genere === 'maschio') {
        testo = `I trattamenti sono stati eseguiti dal Dottore ${dottore.cognome} ${dottore.nome} - Fisioterapista laureato
        a ${dottore.laurea}.`
    }

    const testoForfetario = `Operazione in franchigia da IVA ai sensi dell’art.1 co. 54 – 88 della Legge 23.12.2014 n. 190
    Compenso non soggetto a ritenuta d’acconto in quanto assoggettato ad imposta sostitutiva ex art. 1 co. 67 della Legge 23.12.2014 n. 190`
    const testoMarca = `
    Imposta da Bollo assolta sull'originale della fattura - ai sensi dell'art. 15 DPR 633/72
    
    `

    return {
        text: `
        
        ${isSpesaSanitaria(data.voci) ? "Prestazione Sanitaria esente Iva ai sensi dell'articolo 10 DPR 633/72" : ''}
        
        ${testo}
        ${data.extra.forfetario ? testoForfetario : ''}
        ${!data.extra.marca ? testoMarca : ''}
        Metodo di pagamento : ${data.extra.pagamentoTracciato}.`,
        style: 'testo2',
    }

    if (dottore.genere === 'Maschio' || dottore.genere === 'maschio') {
        return {
            text: `
        ${isSpesaSanitaria(data.voci) ? "Prestazione Sanitaria esente Iva ai sensi dell'articolo 10 DPR 633/72" : ''}

        RIFERIMENTO FATTURA N. ${data.datiFattura.numFattura} DEL ${data.datiFattura.date}

        Si dichiara che 
        ${`${paziente.nome} ${paziente.cognome}`}
        Residenza:${paziente.indirizzo}
        Codice Fiscale: ${paziente.cf}
        ha effettuato ${voci} presso la nostra Clinica.
        I trattamenti sono stati eseguiti dal Dottore ${dottore.cognome} ${dottore.nome} - Fisioterapista laureato
        a ${dottore.laurea}.
        
        Metodo di pagamento : ${data.extra.pagamentoTracciato}.`,
            style: 'testo2',
        }
    }

    return {
        text: `
        
        ${isSpesaSanitaria(data.voci) ? "Prestazione Sanitaria esente Iva ai sensi dell'articolo 10 DPR 633/72" : ''}
        
        RIFERIMENTO FATTURA N. ${data.datiFattura.numFattura} DEL ${data.datiFattura.date}

        Si dichiara che 
        ${paziente.nome}
        Residenza:${paziente.indirizzo}
        Codice Fiscale: ${paziente.cf}
        ha effettuato ${voci} presso la nostra Clinica.
        I trattamenti sono stati eseguiti dalla Dott.ssa ${dottore.cognome} ${dottore.nome} - Fisioterapista laureata
        a ${dottore.laurea}.
        
        Metodo di pagamento : ${data.extra.pagamentoTracciato}.`,
        style: 'testo2',
    }
}

let getUrl = async (logo) => {
    let path = `loghi/${getPresidio(getUtenteLoggato())}/logo.jpg`
    if (!logo) {
        path = `loghi/physio/logoDue.png`
    }
    let url = getDownloadURL(ref(storage, path))
    return url
}

let getBase64ImageFromURL = async (url) => {
    return new Promise((resolve, reject) => {
        var img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = () => {
            var canvas = document.getElementById('canvas')
            canvas.width = img.width
            canvas.height = img.height
            var ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            var dataURL = canvas.toDataURL('image/jpg')
            resolve(dataURL)
        }

        img.src = url
    })
}

let getImmagine = async (presidio) => {
    return {
        image: await getBase64ImageFromURL(await getUrl(presidio.logo || false)),
        fit: [100, 100],
    }
}

export const generaPdf = async (data, presidio, dottore) => {
    let paziente = await getPaziente(data.idPaziente)
    let docDefinition = {
        content: [await getImmagine(presidio), generaintestazione(presidio), generaInfo(paziente, data.datiFattura), generaFattura(data.voci, data.extra.marca), secondaParte(data, paziente, dottore), creaFooter(presidio)],
        styles: creaStile(),
    }

    let blob = await prendiBlob(docDefinition)
    return blob
}

let getImmaginePresidio = async () => {}

let getPaziente = async (id) => {
    let paziente = await getDoc(doc(db, 'utenti', id))
    return paziente.data()
}

let prendiBlob = (docDefinition) => {
    return new Promise((resolve, reject) => {
        pdfMake.createPdf(docDefinition).getBlob((blob) => {
            resolve(blob)
        })
    })
}
