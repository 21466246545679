import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { getPresidio, trasformaOggettoInArray } from './../strumenti'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherListaEsercizi extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione(nuovoId) {
        let presidio = getPresidio(store.getState().statoUtente.utente)
        var listaEsercizi = []

        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', presidio, 'cache', 'esercizi'), (querySnapshot) => {
            listaEsercizi = trasformaOggettoInArray(querySnapshot.data())
            let index = listaEsercizi.findIndex((esercizio) => {
                return esercizio.id === 'idFalso'
            })
            if (index !== -1) {
                listaEsercizi.splice(index, 1)
            }
            window.listaEsercizi = listaEsercizi
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'esercizi' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
