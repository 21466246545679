import { getStatoPresidio } from 'getter/getterPresidio'
import { isPopUpGuidaDaAprire } from 'getter/getterPresidio'
import { store } from '../store'
import { AGGIORNA_STATO_POP_UP_GUIDA } from './AGGIORNA_STATO_POP_UP_GUIDA'
export const LOG_OUT = () => {
    return new Promise((resolve, reject) => {
        if (isPopUpGuidaDaAprire() && getStatoPresidio() === 4) {
            AGGIORNA_STATO_POP_UP_GUIDA(4, false).then(() => {
                store.dispatch({
                    type: 'LOG_OUT',
                })
                resolve()
            })
        } else {
            store.dispatch({
                type: 'LOG_OUT',
            })
            resolve()
        }
    })
}
