import { store } from './../store'

export const AGGIORNA_FILTRI_CERCA_PAZIENTE = (nomeParametro) => {

    return new Promise((resolve, reject) => {
        store.dispatch({
            type: "AGGIORNA_FILTRI_CERCA_PAZIENTE",
            payload: nomeParametro
        })
        resolve()
    });

}
    