import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { getPresidio } from '../strumenti'
import { baseWatcher } from './baseWatcher'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherCacheFatturePresidio extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione(nuovoId) {
        this.funzioneConnessione = onSnapshot(doc(db, 'presidi', getPresidio(getUtenteLoggato()), 'cache', 'fatture'), (querySnapshot) => {
            if (querySnapshot.exists()) {
                window.cacheFatture = querySnapshot.data()
                window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'cacheFatture' }))
            }
        })

    
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
