import MDBox from 'components/MDBox'
import React from 'react'
import MDTypography from 'components/MDTypography'
import ElementoTestArticolare from './elementoTestArticolare'
import { dataTestArticolari } from 'data/dataTestArticolari'
import FormOutput from 'formMaker/formOutput'
import Grid from '@mui/material/Grid'

export default function elementoVediTestArticolare(props) {
    let index = dataTestArticolari.findIndex((el) => {
        return el.tipo === props.value.tipoTest
    })
    let testArticolare = dataTestArticolari[index]

    let model = () => {
        if (testArticolare.angoli.min < 0) {
            return {
                costumCssInput: ['formCambiata'],
                parts: [
                    {
                        id: 'soloMax',
                        tipo: 'fieldSlider',
                        associazione: 'soloMax',
                        label: 'AUTONOMO MAX:',
                        value: '',
                        details: {
                            type: 'text',
                            costumCssOutput: ['labelBold'],
                        },
                        validazione: 'testoRichiesto',
                    },
                    {
                        id: 'aiutatoMax',
                        tipo: 'fieldSlider',
                        associazione: 'aiutatoMax',
                        label: 'ASSISTITO MAX:',
                        value: '',
                        details: {
                            type: 'text',
                            costumCssOutput: ['labelBold'],
                        },
                        validazione: 'testoRichiesto',
                    },
                    {
                        id: 'soloMin',
                        tipo: 'fieldSlider',
                        associazione: 'soloMin',
                        label: 'AUTONOMO MIN:',
                        value: '',
                        details: {
                            scelte: ['Maschio', 'Femmina'],
                            complex: false,
                            costumCssOutput: ['labelBold'],
                        },
                        validazione: 'testoRichiesto',
                    },
                    {
                        id: 'assistitoMin',
                        tipo: 'fieldSlider',
                        associazione: 'assistitoMin',
                        label: 'ASSISTITO MIN:',
                        value: '',
                        details: {
                            type: 'text',
                            costumCssOutput: ['labelBold'],
                        },
                        validazione: 'testoRichiesto',
                    },
                ],
            }
        }
        return {
            costumCssInput: ['formCambiata'],
            parts: [
                {
                    id: 'soloMax',
                    tipo: 'fieldSlider',
                    associazione: 'soloMax',
                    label: 'AUTONOMO MAX:',
                    value: '',
                    details: {
                        type: 'text',
                        costumCssOutput: ['labelBold'],
                    },
                    validazione: 'testoRichiesto',
                },
                {
                    id: 'aiutatoMax',
                    tipo: 'fieldSlider',
                    associazione: 'aiutatoMax',
                    label: 'ASSISTITO MAX:',
                    value: '',
                    details: {
                        type: 'text',
                        costumCssOutput: ['labelBold'],
                    },
                    validazione: 'testoRichiesto',
                },
            ],
        }
    }

    let dati = {
        soloMax: `${props.value.risultati.solo.max}`,
        aiutatoMax: `${props.value.risultati.aiutato.max}`,
        soloMin: `${props.value.risultati.solo.min}`,
        assistitoMin: `${props.value.risultati.aiutato.min}`,
    }

    return (
        <React.Fragment>
            <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                <MDTypography color={'white'} variant='h5'>
                   {testArticolare.nome}
                </MDTypography>
            </MDBox>
            <MDBox>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <ElementoTestArticolare isPopUp={props.isPopUp} id={props.value.id} valori={props.value.risultati} datiDisegno={testArticolare} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FormOutput form={model()} dati={dati} />
                    </Grid>
                </Grid>
            </MDBox>
        </React.Fragment>
    )
}
