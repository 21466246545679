import { store } from './../store' 
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const GENERA_CREDENZIALI = (credenziali, id) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    return new Promise((resolve, reject) => {
        CHIAMA_SERVER(`generaCredenziali`, { idUtente: id, credenziali: credenziali }).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
