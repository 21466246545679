import { store } from './../store' 
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const AGGIORNA_CACHE = (datiCache, nomeDocumento, presidio) => {
    return new Promise((resolve, reject) => {
        let daChiamare = nomeDocumento === 'cache' ? 'aggiornaCachePresidi' : 'aggiornaCacheUtenti'
        CHIAMA_SERVER(`aggiornaCacheUtenti`, { datiCache: datiCache, documento: nomeDocumento, presidio: presidio }).then(() => {
            resolve()
        })
    })
}
