
import { getUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { store } from './../store' 

import { convertiStringaInData, creaEtichettaUniversale, getPresidio, newId } from './../strumenti'
import { APRI_POP_UP } from './APRI_POP_UP'
import { SALVA_PDF_FATTURA } from './SALVA_PDF_FATTURA'
import { CHIAMA_SERVER } from './CHIAMA_SERVER'

export const SALVA_FATTURA = async (valori, idFattura, modifica, idPaziente) => {
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
    
    //let idPaziente = getIdPaziente()
    let idPresidio = getPresidio(getUtenteLoggato())
    let numFattura = Number(valori.datiFattura.numFattura.split('-')[0]) + 1

    if (!modifica) {
        valori.stato = 'creata'
        valori.etichettaUniversale = creaEtichettaUniversale(idPresidio)
    }
    let totale = valori.voci.reduce(
        (acc, elemento) => {
            acc += Number(elemento.importo)
            return acc
        },
        valori.extra.marca ? 2 : 0,
    )
    valori.totale = totale
    let data = convertiStringaInData(valori.datiFattura.date)
    let oggetto = {
        idPaziente: idPaziente,
        idPresidio: idPresidio,
        idFattura: idFattura,
        fattura: valori,
        numFattura: numFattura,
        dataFattura: `${data.getFullYear()}-${data.getMonth() + 1}`,
        modifica: modifica,
    }

    if (modifica) {
        let dataVecchia = convertiStringaInData(store.getState().reducerFattura.datiFattura.date)
        let nuovaDataFattura = `${data.getFullYear()}-${data.getMonth() + 1}`
        let vecchiaDataFattura = `${dataVecchia.getFullYear()}-${dataVecchia.getMonth() + 1}`

        if (nuovaDataFattura !== vecchiaDataFattura) {
            oggetto.vecchioTotale = store.getState().reducerFattura.totale
            oggetto.vecchiaDataFattura = vecchiaDataFattura
        }
        if (nuovaDataFattura === vecchiaDataFattura) {
            oggetto.differenza = valori.totale - store.getState().reducerFattura.totale
        }
    }
    if (valori.stato !== 'creata') {
        console.log('creo pdf')
        await SALVA_PDF_FATTURA(idFattura, valori)
    }
    if (valori.stato === 'inviata') {
        console.log('gia inviataaaaaaaaa')
        await CHIAMA_SERVER(`modificaFattura`, { idFattura: idFattura, idChiamata: newId(), fattura: valori, vecchiaData: store.getState().reducerFattura.datiFattura.date })
        APRI_POP_UP({ id: 'alert', parametro: {severity : 'info', testo : 'Richiesta modifica fattura inviata'} })
    }
    if (valori.stato !== 'inviata') {
        console.log('non inviataaaa : ', oggetto)
        await CHIAMA_SERVER(`salvaFattura`, oggetto)
    }
    window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
    return
}
