import { store } from 'data/store'
import { trasformaOggettoInArray } from 'data/strumenti'
import { baseWatcher } from './baseWatcher'
import { collection, query, onSnapshot } from 'firebase/firestore'

export class watcherListaPresidiAgente extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione() {
        const q = query(collection(db, 'utenti'), where('profilo', '==', 'profilo-amministrazione-clinica'), where('etichettaUniversale.user', '==', store.getState().statoUtente.id))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            var listaPresidi = {}
            querySnapshot.forEach((doc) => {
                listaPresidi[doc.id] = doc.data()
            })
            window.listaAmministrazioni = trasformaOggettoInArray(listaPresidi)
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'amministrazioni' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
