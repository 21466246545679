import { getPresidio } from 'data/strumenti'
import { store } from './../data/store'
import { getIdUtenteLoggato, getProfiloUtenteLoggato, getVeroUtenteLoggato } from './getterUtenteLoggato'

export const getDatiPresidio = () => {
    return store.getState().reducerPresidio || {}
}

export const getListaAmministrazioni = () => {
    let lista = [...window.listaAmministrazioni]
    lista.sort((a, b) => {
        let dataA = a.contenuto?.etichettaUniversale?.dataCreazione?.seconds
        let dataB = b.contenuto?.etichettaUniversale?.dataCreazione?.seconds
        return dataB - dataA
    })
    return lista
}

export const getStatoPresidio = () => {
    const idUtente = getIdUtenteLoggato()
    if (idUtente === '') {
        return 0
    }
    const idPresidio = getPresidio(getVeroUtenteLoggato())
    if (idPresidio === '') {
        return 1
    }
    return getDatiPresidio().popUpGuida?.stato || 2
}

export const getStatoPresidioDaAprire = () => {
    return getDatiPresidio().popUpGuida?.daAprire || false
}

export const isPopUpGuidaDaAprire = () => {
    const daAprire = getDatiPresidio().popUpGuida?.daAprire || false
    const isAmministrazione = getProfiloUtenteLoggato() === 'profilo-amministrazione-clinica'
    return daAprire && isAmministrazione
}
