import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'

export function InputSceltaSingola(props) {
    let getValoreDaVedere = (valore) => {
        if (props.model.details.complex) {
            console.log('okkkkkkkkk : ', valore[props.model.details.toShow]);
            return valore[props.model.details.toShow]
        }
        return valore
    }

    let getValoreDaRitornare = (valore) => {
        if (props.model.details.toSave !== undefined && props.model.details.toSave !== '') {
            return valore[props.model.details.toSave]
        }
        return valore
    }

    let getValoreSelezionato = () => {
        let index = props.model.details.scelte.findIndex((el) => {
            return getValoreDaRitornare(el) === props.value
        })
        if (index === -1) {
            return props.model.details.complex ? { [props.model.details.toShow]: '' } : ''
        }
        return props.model.details.scelte[index]
    }

    return (
        <FormControl error={props.errore !== ''}>
            <FormLabel id='radio-label'>{props.model.label}</FormLabel>
            <RadioGroup
                aria-labelledby='radio-label'
                row
                value={props.value}
                onChange={(e, valore) => {
                    props.azione(props.model.id, valore)
                }}>
                {props.model.details.scelte.map((scelta,index) => {
                    return <FormControlLabel key={index} value={getValoreDaRitornare(scelta)} control={<Radio />} label={getValoreDaVedere(scelta)} />
                })}
            </RadioGroup>
            <FormHelperText>{props.errore}</FormHelperText>
        </FormControl>
    )
}
