import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import breakpoints from 'assets/theme/base/breakpoints'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import colors from 'assets/theme-dark/base/colors'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Fab from '@mui/material/Fab'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { getStatoPresidio } from 'getter/getterPresidio'
import { isPopUpGuidaDaAprire } from 'getter/getterPresidio'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'

export const popUpGuida = (props) => {
    const opzioniStandard = { right: '10%', left: '10%', bottom: '10%', top: '10%' }
    const opzioniMobile = {}
    const [selezionato, setSelezionato] = useState(0)

    const checkMisure = () => {
        if (window.innerWidth < breakpoints.values.sm) {
            return opzioniMobile
        } else {
            return opzioniStandard
        }
    }

    const [opzioni, setOpzioni] = useState(checkMisure())
    const steps = [
        { titolo: 'Crea la tua amministrazione', testo: 'Inserisci le credenziali per creare il tuo profilo di amministrazione.' },
        { titolo: 'Inserisci i dati del tuo presidio', testo: 'Compila i campi con i dati richiesti per terminare la configurazione del tuo profilo di amministrazione.' },
        { titolo: 'Inserisci un dipendente', testo: 'Il tuo profilo di amministrazione è stato creato. Da qui puoi gestire i dipendenti della tua clinica e i relativi abbonamenti. Per iniziare ad usare Physio Archive crea un profilo fisioterapista o segreteria (premendo i relativi bottoni) e segui i passaggi successivi.' },
        { titolo: 'Inserisci i dati di fatturazione', testo: 'Una volta creato il primo Profilo Dipendente, premere sul tasto “Crea abbonamento” per iniziare la tua prova gratuita (disponibile solo per i primi due Profili fisioterapisti generati).' },
        { titolo: 'Inizia a usare Physio Archive', testo: 'Hai completato tutti i passaggi, ora esci dal profilo di amministrazione (premere su “Mio profilo” e poi “Esci”) e accedi con le credenziali inserite per il dipendente creato per usare Physio Archive.' },
    ]

    const handleClose = () => {
        props.chiudi()
    }

    useEffect(() => {
        window.addEventListener('resize', (e) => {
            setOpzioni(checkMisure())
        })
    }, [])

    useEffect(() => {
        setSelezionato(props.statoAttuale)
    }, [props.statoAttuale])

    const renderTest = () => {
        return (
            <Stepper
                sx={{
                    backgroundColor: 'transparent !important', // circle color (COMPLETED)
                    background: 'transparent !important', // circle color (COMPLETED)
                    boxShadow: 'none !important',
                }}
                selezionato={0}
                orientation='vertical'>
                {steps.map((label, index) => (
                    <Step key={label} completed={index < 0}>
                        <StepLabel sx={{ color: 'red' }}>{label.titolo}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        )
    }

    const renderStep = (step, index) => {
        let colore = colors['inputBorderColor']
        let coloreTesto = 'text'
        if (index < props.statoAttuale) {
            colore = colors['success'].main
            coloreTesto = 'success'
        }
        if (index === selezionato) {
            colore = colors['info'].main
            coloreTesto = 'info'
        }
        return (
            <MDBox key={`step-${index}`} width='100%' display='flex' flexDirection='column'>
                {index === 0 ? '' : <MDBox sx={{ backgroundColor: colore }} width='0.3rem' height='60px' ml='0.6rem' borderRadius='0.15rem' mt='-2px'></MDBox>}

                <MDBox width='100%' display='flex' flexDirection='row'>
                    <MDBox sx={{ backgroundColor: colore }} height='1.5rem' width='1.5rem' borderRadius='50%'></MDBox>
                    <MDBox sx={{ backgroundColor: colore }} height='0.3rem' width='30px' borderRadius='0.15rem' mt='0.6rem' ml='-2px' mr={1}></MDBox>
                    <MDTypography variant='subtitle2' color={coloreTesto}>
                        {step.titolo}
                    </MDTypography>
                </MDBox>
            </MDBox>
        )
    }

    return (
        <Dialog sx={opzioni} fullScreen open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>Guida Physio archive</DialogTitle>
            <DialogContent dividers={true}>
                <MDBox height='100%' width='100%' sx={{ gridTemplateColumns: '50% 50%' }} display={{ md: 'flex', xs: 'none' }}>
                    <MDBox height='100%' width='100%' sx={{ color: colors['inputBorderColor'], borderRight: '1px solid' }}>
                        {steps.map((step, index) => {
                            return renderStep(step, index)
                        })}
                    </MDBox>
                    <MDBox height='100%' width='100%' p={2}>
                        <MDBox mr={0} ml='auto' mb={2}>
                            <MDTypography variant='h3' color='dark'>
                                {steps[selezionato].titolo}
                            </MDTypography>
                        </MDBox>
                        <MDTypography color='text'>{steps[selezionato].testo}</MDTypography>
                        <MDBox className='contenitore-bottoni-destra'>
                            <Fab color='primary' size='small' disabled={selezionato === 0} onClick={() => setSelezionato(selezionato - 1)}>
                                <ArrowBackIosRoundedIcon />
                            </Fab>
                            <Fab color='primary' size='small' disabled={selezionato === steps.length - 1} onClick={() => setSelezionato(selezionato + 1)}>
                                <ArrowForwardIosRoundedIcon />
                            </Fab>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <MDBox height='100%' width='100%' sx={{ gridTemplateColumns: '50% 50%' }} display={{ xs: 'flex', md: 'none' }} flexDirection='column'>
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 50,
                            pl: 2,
                            bgcolor: 'background.default',
                        }}>
                        <MDTypography>{steps[selezionato].titolo}</MDTypography>
                    </Paper>
                    <MDBox sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>{steps[selezionato].testo}</MDBox>
                    <MobileStepper
                        variant='text'
                        steps={steps.length}
                        position='static'
                        activeStep={selezionato}
                        nextButton={
                            <MDButton size='small' onClick={() => setSelezionato(selezionato + 1)} disabled={selezionato === steps.length - 1}>
                                avanti
                                <KeyboardArrowRight />
                            </MDButton>
                        }
                        backButton={
                            <MDButton size='small' onClick={() => setSelezionato(selezionato - 1)} disabled={selezionato === 0}>
                                <KeyboardArrowLeft />
                                indietro
                            </MDButton>
                        }
                    />
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton color='primary' variant='contained' onClick={() => handleClose(false)}>
                    ok, ho capito
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    if (state.storico[0] === 'LEGGI_PRESIDIO' && isPopUpGuidaDaAprire()) {
        APRI_POP_UP({ id: 'guida', parametro: '' })
    }
    return {
        statoAttuale: getStatoPresidio(),
    }
}

export default connect(mapStateToProps, null)(popUpGuida)
