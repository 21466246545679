import React from 'react'
import { connect } from 'react-redux'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { Grid, Icon } from '@mui/material'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { ELIMINA_ASSEGNAZIONE_ESERCIZIO } from 'data/azioni/ELIMINA_ASSEGNAZIONE_ESERCIZIO'
import { getPermessiUtente } from 'getter/getterPermessi'

export function elementoMostraEsercizioAssegnato(props) {
    const renderBottoni = () => {
        if (!props.permessi || props.noBottoni) {
            return (
                <Grid item container xs={4} lg={4} spacing={1} direction='column' justifyContent='center' alignItems='end'>
                    <Grid item>
                        <MDButton size='small' variant='text' color='primary' onClick={() => props.navigate(`/vediEsercizio/${props.esercizio.idEsercizio}`)}>
                            <Icon sx={{ mr: 1 }}>book</Icon>
                            vedi
                        </MDButton>
                    </Grid>
                </Grid>
            )
        }
        if (props.esercizio.isScheda) {
            return (
                <Grid item container xs={4} lg={4} spacing={1} direction='column' justifyContent='center' alignItems='end'>
                    <Grid item>
                        <MDButton size='small' variant='text' color='error' onClick={() => props.elimina()}>
                            <Icon sx={{ mr: 1 }}>delete</Icon>
                            elimina
                        </MDButton>
                    </Grid>
                    <Grid item>
                        <MDButton size='small' variant='text' color='primary' onClick={() => props.modifica()}>
                            <Icon sx={{ mr: 1 }}>edit</Icon>
                            {props.esercizio.isNuovoEsercizio ? 'modifica' : 'posologia'}
                        </MDButton>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid item container xs={4} lg={4} spacing={1} direction='column' justifyContent='center' alignItems='end'>
                <Grid item>
                    <MDButton size='small' variant='text' color='error' onClick={() => APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di voler eliminare l'assegnazione dell'esercizio? `, azione: () => ELIMINA_ASSEGNAZIONE_ESERCIZIO(props.esercizio) } })}>
                        <Icon sx={{ mr: 1 }}>delete</Icon>
                        elimina
                    </MDButton>
                </Grid>
                <Grid item>
                    <MDButton size='small' variant='text' color='primary' onClick={() => props.navigate(`vediEsercizio/${props.esercizio.idEsercizio}`)}>
                        <Icon sx={{ mr: 1 }}>book</Icon>
                        vedi
                    </MDButton>
                </Grid>
                <Grid item>
                    <MDButton size='small' variant='text' color='primary' onClick={() => APRI_POP_UP({ id: 'posologia', parametro: props.esercizio })}>
                        <Icon sx={{ mr: 1 }}>edit</Icon>
                        posologia
                    </MDButton>
                </Grid>
            </Grid>
        )
    }

    return (
        <MDBox bgColor='white' borderRadius='lg' p={2} height='100%' width='100%'>
            <Grid container spacing={1}>
                <Grid item container xs={true} lg={true}>
                    <Grid item xs={12}>
                        <MDTypography variant='h4' fontWeight='bold'>
                            {props.esercizio.titolo}
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant='caption' color='text'>
                            {props.esercizio.commento}
                        </MDTypography>
                    </Grid>
                </Grid>
                {renderBottoni()}
            </Grid>
        </MDBox>
    )
}

function mapStateToProps(state) {
    return {
        permessi: getPermessiUtente().eserciziPaziente.input,
    }
}

export default connect(mapStateToProps, null)(elementoMostraEsercizioAssegnato)
