import { getProfiloUtenteLoggato } from '../../getter/getterUtenteLoggato'
import { newId } from '../strumenti'
import { store } from './../store'
import { makePdfPrivacy } from './../makePdfPrivacy'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { doc, writeBatch } from 'firebase/firestore'

export const SALVA_PDF_PRIVACY = (data, firma) => {
    let pathInBucket = `documentazione/privacy/${data.idNecessari.idPaziente}/privacy.pdf`

    let nuovoDocId = newId()
    let indexRef = doc(db, 'utenti', data.idNecessari.idPaziente)

    return new Promise((resolve, reject) => {
        makePdfPrivacy(data, firma).then((pdf) => {
            return uploadBytes(ref(storage, pathInBucket), pdf).then((snapshot) => {
                var batch = writeBatch(db)

                if (getProfiloUtenteLoggato() !== 'profilo-chiosco') {
                    batch.update(indexRef, {
                        [`datiDocPrivacy.docId`]: nuovoDocId,
                        ['datiDocPrivacy.status']: 'done',
                    })
                }

                batch.commit().then(() => {})
                resolve(nuovoDocId)
            })
        })
    })
}
