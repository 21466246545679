import FormInput from 'formMaker/formInput'
import FormOutput from 'formMaker/formOutput'
import React, { useEffect } from 'react'

export function InputSubInput(props) {
    let stato = {}
    props.model.details.subModel.parts.forEach((model) => {
        stato[model.id] = props.value[model.id] || model.value
    })

    function aggiorna(campo, valore) {
        let nuovoStato = Object.assign(stato)
        nuovoStato[campo] = valore
        props.azione(props.model.id, nuovoStato)
    }

    return <FormInput form={props.model.details.subModel} dati={stato} azione={(a, b) => aggiorna(a, b)} errori={props.errore} isSubInput={true} />
}

export function OutputSubInput(props) {
    let stato = {}
    props.model.details.subModel.parts.forEach((model) => {
        stato[model.id] = props.value[model.id] || model.value
    })
    return <FormOutput skeleton={props.skeleton || false} form={props.model.details.subModel} dati={stato} isSubInput={true} />
}
