export const formFIltraFatture = (anni, dottori) => {
    return {
        costumCssInput: ['baseFormNoBackground'],
        parts: [
            {
                id: 'anno',
                tipo: 'fieldSelect',
                associazione: 'anno',
                label: 'Anno :',
                value: [],
                details: {
                    grid: { lg: { input: 4 } },
                    scelte: anni,
                    complex: false,
                },
            },
            {
                id: 'mesi',
                tipo: 'fieldSelect',
                associazione: 'mesi',
                label: 'Mesi :',
                value: [],
                details: {
                    grid: { lg: { input: 4 } },
                    nascondiLabel: true,
                    scelte: [
                        { id: 1, nome: 'Gennaio' },
                        { id: 2, nome: 'Febbraio' },
                        { id: 3, nome: 'Marzo' },
                        { id: 4, nome: 'Aprile' },
                        { id: 5, nome: 'Maggio' },
                        { id: 6, nome: 'Giugno' },
                        { id: 7, nome: 'Luglio' },
                        { id: 8, nome: 'Agosto' },
                        { id: 9, nome: 'Settembre' },
                        { id: 10, nome: 'Ottobre' },
                        { id: 11, nome: 'Novembre' },
                        { id: 12, nome: 'Dicembre' },
                    ],
                    complex: true,
                    toShow: 'nome',
                    toSave: 'id',
                },
            },
            /* {
                id: 'idDottore',
                tipo: 'fieldSelect',
                associazione: 'idDottore',
                label: 'Dottore:',
                value: '',
                details: {
                    grid : {lg:{input : 4}},
                    nascondiLabel: true,
                    daVedere: ['nome'],
                    daTornare: ['id'],
                    lista: dottori,
                    costumCssOutput: ['labelBold'],
                    spazioVuoto: true,
                },
            }, */
            {
                id: 'stato',
                tipo: 'fieldSelect',
                associazione: 'stato',
                label: 'Stato fattura :',
                value: '',
                details: {
                    grid: { lg: { input: 4 } },
                    scelte: ['non inviata', 'inviata', 'errore', 'nota di credito'],
                    spazioVuoto: true,
                },
            },
        ],
    }
}
