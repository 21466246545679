const initialState = {
    dataIniziale : "",
    dataFinale : "",
    dottore : "",
};

export const reducerFiltriSedute = (state = initialState, action) => {
    
    switch (action.type) {
        case 'LOG_OUT':
            return initialState;
        case 'AGGIORNA_FILTRI_SEDUTE':
            return action.payload
        /* case "CAMBIA_URL":
           break */
        default:
            return state;
    }
};
