import React from 'react'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ElementoMostraImmagine from 'componenti/elementoMostraImmagine'

export default function elementoVediListaFiles(props) {
    const renderImmagini = (elemento) => {
        return (
            <div className='flex-a-capo'>
                {elemento.contenuto.immagini.map((immagine, index) => {
                    return (
                        <div className='contenitore-immagine-nome' key={`${elemento.id}-${index}`} >
                            {props.vediNomeDisfunzione ? (
                                <MDTypography textTransform='capitalize' variant='caption' color='info' fontWeight='medium'>
                                    {immagine.contenuto.disfunzione}
                                </MDTypography>
                            ) : (
                                ''
                            )}

                            <ElementoMostraImmagine immagine={immagine} />
                        </div>
                    )
                })}
            </div>
        )
    }

    return props.immagini.map((elemento) => {
        return (
            <Grid item key={elemento.id}>
                <MDBox variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' mt={4}>
                    <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={2} mr={'auto'}>
                        <MDTypography color={'white'} variant='h5'>
                            {elemento.id}
                        </MDTypography>
                    </MDBox>
                    {renderImmagini(elemento)}
                </MDBox>
            </Grid>
        )
    })
}
