import { CHIAMA_SERVER } from './CHIAMA_SERVER'
import { creaEtichettaUniversale, getPresidio, newId } from '../strumenti'
import { store } from './../store'

export const CREA_AGENTE = (formCredenziali, formDati) => {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
        let presidio = ''
        const id = newId()
        const profilo = 'profilo-agente'
        formDati.isPaziente = false
        formDati.profilo = profilo
        formDati.etichettaUniversale = creaEtichettaUniversale(presidio)
        formDati.attivo = true
        formDati.profiloOriginale = profilo

        let daPassare = { idUtente: id, datiUtente: formDati, credenziali: formCredenziali, profilo: profilo, idPresidio: presidio }

        CHIAMA_SERVER(`creaUtente`, daPassare).then(() => {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
            resolve()
        })
    })
}
