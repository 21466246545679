import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { LEGGI_PRESIDIO } from './../azioni/LEGGI_PRESIDIO'
import { getPresidio } from './../strumenti'
import { doc, onSnapshot } from 'firebase/firestore'

export class watcherPresidio extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione(nuovoId) {
        let presidio = getPresidio(store.getState().statoUtente.utente)
        if (presidio !== '' && presidio !== undefined) {
            this.funzioneConnessione = onSnapshot(doc(db, 'presidi', presidio), (documento) => {
                LEGGI_PRESIDIO(documento.data())
            })
        }
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
