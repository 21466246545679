import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getEserciziPaziente, getIdPaziente, getSkeleton } from './../getter/getterPaziente'
import { Divider, Grid } from '@mui/material'
import { eventiCache } from 'mixing/eventiCache'
import { isEqual } from 'lodash-es'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import ElementoDialer from 'componenti/elementoDialer'
import { CREA_ESERCIZIO_DA_ASSEGNARE } from 'data/azioni/CREA_ESERCIZIO_DA_ASSEGNARE'
import ElementoMostraEsercizioAssegnato from 'componenti/elementoMostraEsercizioAssegnato'
import MDBox from 'components/MDBox'
import { getPermessiUtente } from 'getter/getterPermessi'
import MDTypography from 'components/MDTypography'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FitnessCenterRoundedIcon from '@mui/icons-material/FitnessCenterRounded'
import { getListaSchede, getListaSchedeScadute } from 'getter/getterSchede'
import ElementoSchedaPaziente from 'componenti/elementoSchedaPaziente'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

class paginaEserciziPaziente extends eventiCache(Component) {
    constructor(props) {
        super(props)
        this.state = { lista: props.listaEsercizi, aperto: false, selezionato: 0, schedeScadute: [] }
    }

    async valoreCambiato(e, valore) {
        this.setState({ selezionato: valore })
        if (valore === 1) {
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: true }))
            const nuoveSchedeScadute = await getListaSchedeScadute()
            this.setState({ schedeScadute: nuoveSchedeScadute })
            window.dispatchEvent(new CustomEvent('statoSpinner', { detail: false }))
        }
    }

    eserciziAggiornati() {
        this.setState({ lista: getEserciziPaziente() })
    }

    componentDidUpdate(vecchieProps) {
        if (!isEqual(vecchieProps.listaEsercizi, this.props.listaEsercizi)) {
            this.setState({ lista: this.props.listaEsercizi })
        }
    }

    render() {
        return (
            <React.Fragment>
                <MDBox borderRadius='lg' shadow='lg' bgColor='white' p={2} mb={2} display='flex' justifyContent='space-between' flexDirection={{ xs: 'column', md: 'row' }}>
                    <Tabs orientation={'horizontal'} value={this.state.selezionato} onChange={(e, valore) => this.valoreCambiato(e, valore)}>
                        <Tab label={'SCHEDE ATTIVE'} key={'tab-0'} />
                        <Tab label={'SCHEDE SCADUTE'} key={'tab-1'} />
                    </Tabs>
                </MDBox>
                <MDBox mx='auto' sx={{ maxWidth: '1000px' }}>
                    {this.state.selezionato === 0 ? this.renderSchedeAttive() : this.renderSchedeScadute()}
                </MDBox>
            </React.Fragment>
        )
    }

    renderSchedeScadute() {
        return this.renderSchede()
    }

    renderSchedeAttive() {
        return (
            <React.Fragment>
                {this.renderEsercizi()}
                {this.renderSchede()}
                {this.renderDialer()}
            </React.Fragment>
        )
    }

    renderSchede() {
        
        if (this.props.skeletonSchede) {
            return (
                <MDBox borderRadius='lg' shadow='lg' bgColor='white' p={2} display='flex' justifyContent='space-between' >
                    <MDTypography color='info'>Caricamento schede in corso</MDTypography>
                </MDBox>
            )
        }
        if (this.props.listaSchede.length === 0 && this.state.selezionato !== 1) {
            return (
                <MDBox borderRadius='lg' shadow='lg' bgColor='white' p={2} display='flex' justifyContent='space-between' >
                    <MDTypography color='text'>Nessuna scheda assegnata</MDTypography>
                </MDBox>
            )
        }
        if (this.state.schedeScadute.length === 0 && this.state.selezionato === 1) {
            return (
                <MDBox borderRadius='lg' shadow='lg' bgColor='white' p={2} display='flex' justifyContent='space-between' >
                    <MDTypography color='text'>Nessuna scheda scaduta</MDTypography>
                </MDBox>
            )
        }

        var lista = [...this.props.listaSchede]
        if (this.state.selezionato === 1) {
            lista = [...this.state.schedeScadute]
        }

        return lista.map((scheda) => {
            return (
                <MDBox key={scheda.id} mt={2}>
                    <ElementoSchedaPaziente navigate={this.props.navigate} scheda={scheda} output={true} scaduta={this.state.selezionato === 1} />
                </MDBox>
            )
        })
    }

    renderDialer() {
        if (!this.props.permessi) {
            return ''
        }
        /* const azioni = [
            { color: 'primary', icon: 'add', testo: 'Nuovo esercizio da libreria', azione: () => APRI_POP_UP({ id: 'assegna-esercizio', parametro: '' }) },
            { color: 'primary', icon: 'add', testo: 'Nuovo esercizio', azione: () => APRI_POP_UP({ id: 'nuovoEsercizio', parametro: { daAssegnare: true, azione: (e) => CREA_ESERCIZIO_DA_ASSEGNARE(e) } }) },
        ] */
        //return <ElementoDialer azioni={azioni} vediModale={true} />
        return (
            <Fab sx={{ zIndex: 100, position: 'fixed', bottom: 16, right: 16 }} color='primary' aria-label='add' onClick={() => this.props.navigate(`paginaVediPaziente/${getIdPaziente()}/PaginaSchedaPaziente/crea`)}>
                <AddIcon />
            </Fab>
        )
    }

    renderListaEsercizi() {
        /* if (this.props.skeleton) {
            return <MDTypography color='info'>Caricamento esercizi in corso</MDTypography>
        } */
       
        return (
            <Grid container direction='column'>
                {this.state.lista.map((esercizio, index) => {
                
                    return (
                        <React.Fragment key={`assegnazione-${index}`}>
                            <Grid key={esercizio.id} item>
                                <ElementoMostraEsercizioAssegnato esercizio={esercizio} navigate={this.props.navigate} />
                            </Grid>
                            {index !== this.state.lista.length - 1 ? <Divider /> : ''}
                        </React.Fragment>
                    )
                })}
            </Grid>
        )
    }

    renderEsercizi() {
        if (this.state.lista.length === 0) {
            return ""
            return <MDTypography color='error'>Nessun esercizio assegnato</MDTypography>
        }
        return (
            <MDBox TransitionProps={{ unmountOnExit: true }} sx={{ maxWidth: '1000px' }} width='100%' ml='auto' mr='auto'>
                <Accordion ml='auto' mr='auto' expanded={this.state.aperto} onChange={() => this.setState({ aperto: !this.state.aperto })}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                        <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                            <FitnessCenterRoundedIcon color='white' sx={{ mr: 1 }} />
                            <MDTypography color={'white'} variant='h5'>
                                Esercizi assegnati
                            </MDTypography>
                        </MDBox>
                    </AccordionSummary>
                    <AccordionDetails>{this.renderListaEsercizi()}</AccordionDetails>
                </Accordion>
            </MDBox>
        )

        return (
            <MDBox sx={{ maxWidth: '1000px' }} width='100%' variant='gradient' shadow='lg' opacity={1} p={2} borderRadius='lg' ml='auto' mr='auto'>
                <MDBox display='flex' variant='gradient' bgColor='info' justifyContent='center' alignItems='center' width='max-content' height='max-content' shadow='md' borderRadius='md' mb={3} p={1.5}>
                    <FitnessCenterRoundedIcon color='white' sx={{ mr: 1 }} />
                    <MDTypography color={'white'} variant='h5'>
                        Esercizi assegnati
                    </MDTypography>
                </MDBox>
                {this.renderListaEsercizi()}
            </MDBox>
        )
    }
}

function mapStateToProps(state) {
    return {
        listaEsercizi: getEserciziPaziente(),
        permessi: getPermessiUtente().eserciziPaziente.input,
        skeleton: getSkeleton(),
        skeletonSchede: state.reducerSchedePaziente?.skeleton,
        listaSchede: getListaSchede(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaEserciziPaziente)
